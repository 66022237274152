import { Button, Card, Offcanvas } from "react-bootstrap"
import { MdArrowBack } from "react-icons/md"

import styles from './editorCaption.module.scss';

const EditorCaption = (props: any) => {
    return (
        <Offcanvas.Header>
            <Offcanvas.Title>
                <div className={styles.caption}>
                    <Button variant='default' onClick={props.onHide}>
                        <MdArrowBack />
                    </Button>
                    {props.icon ? (
                        <Card className={styles.editorIcon}>
                            {props.icon}
                        </Card>
                    ) : null}
                    <div>
                        {props.caption}
                    </div>
                </div>
            </Offcanvas.Title>
        </Offcanvas.Header>
    )
}
export default EditorCaption;