import React from 'react';
import { useQuery } from '@tanstack/react-query';

import Node from './node';
import * as designFlowService from '../../../services/designFlow';
import { BackendFlow } from '../../../store/graphSlice';

const JumpToNode: React.FC<any> = props => {
    const flowQuery = useQuery({
        queryKey: ['designFlow', props.data.jumpTo.flowId],
        queryFn: () => props.data.jumpTo.flowId ? designFlowService.loadFlowElements(props.data.jumpTo.flowId) : null
    });

    if (flowQuery.isLoading) {
        return (
            <Node {...props} label='Jump to' content={(
                <div className='dimmed'>
                    Loading...
                </div>
            )} />
        )
    }

    if (flowQuery.error) {
        return (
            <Node {...props} label='Jump to' content={(
                <div className='dimmed'>
                    Failed to load jumpTo details!
                </div>
            )} />
        )
    }

    const block: BackendFlow = flowQuery.data.flowElements.find((element: BackendFlow) => element.uid === props.data.jumpTo.blockId)

    return (
        <div>
            <Node {...props} label='Jump To' content={(
                <span className='dimmed'>
                    <b>Flow: </b> <a href={'/bots/flow/' + flowQuery.data.uid} target='_blank'>{flowQuery.data.flowName}</a> <br />
                    {/* <b>Block: </b> {block ? block.elementData?.type : null} <br /> */}
                    <b>Block ID: </b> {block ? block.uid : null}
                </span>
            )} />
        </div>
    );
}

export default JumpToNode;