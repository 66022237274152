// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

// interface MessagesStatusItem {
//   messagesDate: string;
//   sendmessages: number;
//   deliveryMessages: number;
//   readMessages: number;
//   errorMessages: number;
// }

// const StatusChart: React.FC<{ data: MessagesStatusItem[] }> = ({ data }) => {
//   const lineColors = ["#ffc107", "#007bff", "#28a745", "#f50202"];

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     return date.toLocaleString("default", { month: "short" }) + " " + date.getDate().toString().padStart(2, "0");
//   };

//   const CircleLegend = (props: any) => {
//     const { payload } = props;
//     return (
//       <div className="d-flex statusMargin">
//         {payload.map((entry: any, index: number) => (
//           <span
//             key={`item-${index}`}
//             style={{ display: "inline-block", marginRight: 15 }}
//           >
//             <svg width={10} height={10}>
//               <circle cx={5} cy={5} r={5} fill={entry.color} />
//             </svg>
//             <span style={{ marginLeft: 5 }}>{entry.value}</span>
//           </span>
//         ))}
//       </div>
//     );
//   };

//   const formatYAxis = (tickItem: number) => {
//     return tickItem.toString();
//   };

//   const getUniqueTicks = () => {
//     const maxYValue = Math.max(
//       ...data.flatMap((item) => [item.messagesDate, item.deliveryMessages, item.sendmessages, item.readMessages, item.errorMessages])
//     );
//     const step = Math.ceil(maxYValue / 5); // 5 steps mean 6 ticks including 0 and the top value
//     const ticks = [];
//     for (let i = 0; i <= maxYValue; i += step) {
//       ticks.push(i);
//     }
//     if (ticks[ticks.length - 1] !== maxYValue) {
//       ticks.push(maxYValue); 
//     }
//     return ticks;
//   };

//   return (
//     <div style={{ width: "max-content" }}>
//       <LineChart
//         width={600}
//         height={300}
//         data={data}
//         margin={{
//           top: 20,
//           right: 30,
//           left: 20,
//           bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="messagesDate" tickFormatter={formatDate} />
//         <YAxis />
//         <Tooltip
//       labelFormatter={formatDate}
//       formatter={(value, name, entry) => {
//         if (entry && entry.dataKey) {
//           let label = '';
//           switch (entry.dataKey) {
//             case 'sendmessages':
//               label = 'Sent';
//               break;
//             case 'deliveryMessages':
//               label = 'Delivered';
//               break;
//             case 'readMessages':
//               label = 'Read';
//               break;
//             case 'errorMessages':
//               label = 'Error';
//               break;
//             default:
//               break;
//             }
//             return [value, label];
//           }
//           return [value];
//           }}
//         />
//         <Legend content={<CircleLegend />} />
//         <Line
//           dataKey="sendmessages"
//           stroke={lineColors[0]}
//           type="monotone"
//           name="Sent"
//           dot={false} 
//         />
//         <Line
//           dataKey="deliveryMessages"
//           stroke={lineColors[1]}
//           type="monotone"
//           name="Delivered"
//           dot={false}
//         />
//         <Line
//           dataKey="readMessages"
//           stroke={lineColors[2]}
//           type="monotone"
//           name="Read"
//           dot={false} 
//         />
//         <Line
//           dataKey="errorMessages"
//           stroke={lineColors[3]}
//           type="monotone"
//           name="Error"
//           dot={false} 
//         />
//       </LineChart>
//       <small className="d-flex statusContent">Days Vs Messages Status</small>
//     </div>
//   );
// };

// export default StatusChart;
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

interface MessagesStatusItem {
  messagesDate: string;
  deliveryMessages: number;
  sendmessages: number;
  readMessages: number;
  errorMessages: number;
}

const StatusChart: React.FC<{ data: MessagesStatusItem[] }> = ({ data }) => {
  const lineColors = ["#ffc107", "#007bff", "#28a745", "#f50202"];

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("default", { month: "short" }) + " " + date.getDate().toString().padStart(2, "0");
  };

  const CircleLegend = (props: any) => {
    const { payload } = props;
    return (
      <div className="d-flex statusMargin">
        {payload.map((entry: any, index: number) => (
          <span
            key={`item-${index}`}
            style={{ display: "inline-block", marginRight: 15 }}
          >
            <svg width={10} height={10}>
              <circle cx={5} cy={5} r={5} fill={entry.color} />
            </svg>
            <span style={{ marginLeft: 5 }}>{entry.value}</span>
          </span>
        ))}
      </div>
    );
  };

  const formatYAxis = (tickItem: number) => {
    return tickItem.toString();
  };

  const getUniqueTicks = () => {
    let maxYValue = Math.max(
      ...data.flatMap((item) => [item.deliveryMessages, item.sendmessages, item.readMessages, item.errorMessages])
    );
  
    const minYValue = 0;
    const minValue = 4;
  
    if (maxYValue < minValue) {
      maxYValue = minValue;
    }
    const roundUpValue = (value: any) => {
      if (value <= 10) return Math.ceil(value / 5) * 5;
      if (value <= 100) return Math.ceil(value / 10) * 10;
      if (value <= 1000) return Math.ceil(value / 100) * 100;
      return Math.ceil(value / 1000) * 1000;
    };
    maxYValue = roundUpValue(maxYValue);
    const step = Math.ceil(maxYValue / 4);
    const ticks = [];
    for (let i = minYValue; i <= maxYValue; i += step) {
      ticks.push(i);
    }
    if (ticks[ticks.length - 1] !== maxYValue) {
      ticks.push(maxYValue);
    }
  
    return ticks;
  };
  
  

  return (
    <div style={{ width: "max-content" }}>
      <LineChart
        width={600}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="messagesDate" tickFormatter={formatDate} />
        <YAxis />
        <Tooltip
          labelFormatter={formatDate}
          formatter={(value, name, entry) => {
            if (entry && entry.dataKey) {
              let label = '';
              switch (entry.dataKey) {
                case 'sendmessages':
                  label = 'Sent';
                  break;
                case 'deliveryMessages':
                  label = 'Delivered';
                  break;
                case 'readMessages':
                  label = 'Read';
                  break;
                case 'errorMessages':
                  label = 'Error';
                  break;
                default:
                  break;
              }
              return [value, label];
            }
            return [value];
          }}
        />
        <Legend content={<CircleLegend />} />
        <Line
          dataKey="sendmessages"
          stroke={lineColors[0]}
          type="monotone"
          name="Sent"
          dot={false} 
        />
        <Line
          dataKey="deliveryMessages"
          stroke={lineColors[1]}
          type="monotone"
          name="Delivered"
          dot={false}
        />
        <Line
          dataKey="readMessages"
          stroke={lineColors[2]}
          type="monotone"
          name="Read"
          dot={false} 
        />
        <Line
          dataKey="errorMessages"
          stroke={lineColors[3]}
          type="monotone"
          name="Error"
          dot={false} 
        />
      </LineChart>
      <small className="d-flex statusContent">Days Vs Messages Status</small>
    </div>
  );
};

export default StatusChart;

