import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';

import * as flowServices from '../../../services/flows';
import Node from './node';
import { useAppSelector } from '../../../hooks';
import { SOMETHING_WENT_WRONG } from '../../../../constants/errorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { jumpToIcon } from '../../../icons';
import { Badge } from 'reactstrap';
import { MdTitle } from 'react-icons/md';

type Props = any;

function Flow(props: Props) {
    const channelUid = useAppSelector(state => state.meta.channelUid);
    const flowsQuery = useQuery({
        queryKey: ['channel-flows'],
        queryFn: () => flowServices.getFlowsOfChannel(channelUid || ''),
        enabled: false
    })
    useEffect(() => {
        if (channelUid && !flowsQuery.isFetchedAfterMount) {
            flowsQuery.refetch();
        }
    }, [channelUid])

    let content = (
        <div className='d-flex align-item-center'>
            <Spinner size='sm' />
            <div className='text-muted ml-2 mt-1'>
                Loading...
            </div>
        </div>
    );

    if (flowsQuery.isFetched && !flowsQuery.isError) {
        const myFlow = flowsQuery?.data?.find(f => f?.id === props.data.flowData.flowId);
        if (myFlow) {
            content = (
                <>
                {props.data.storageVariable ? (
                    <Badge bg='secondary' style={{ fontSize: 6.5 }}>
                        <MdTitle />
                        {props.data.storageVariable.name}
                    </Badge>
                ) : null}
                <div className='d-flex align-item-center'>
                    <img src={jumpToIcon} width={14} />
                    <div className='ml-2 mt-1'>
                        {myFlow.name}
                    </div>
                </div>
                </>
            )
        } else {
            content = (
                <div className='invalid-feedback d-block'>
                    <FontAwesomeIcon icon={faWarning} className='mr-1' />
                    Flow not found!
                </div>
            )
        }
    } else if (flowsQuery.isError) {
        <div className='invalid-feedback d-block'>
            <FontAwesomeIcon icon={faWarning} className='mr-1' />
            {SOMETHING_WENT_WRONG}
        </div>
    }

    return (
        <Node {...props} label='Ask Flow' content={content} />
    );
}

export default Flow