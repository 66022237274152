import React, { useMemo, useState } from 'react';
import { Row, Col, InputGroup, FormControl, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormikErrors, useFormikContext } from 'formik';
import { BsTrash } from 'react-icons/bs';

import styles from './optionsInput.module.css';
import { uuidv4 } from '../../../../../utils/uuid';
import { PiMinusCircleFill } from 'react-icons/pi';
import { MdAdd } from 'react-icons/md';

export interface IListOption {
    id: string;
    title: string;
    order: number;
    keyword: string[];
}

interface Props {
    options: IListOption[];
    error: any;
    isInvalid: boolean;
    isValid: boolean;
    touched: any;
    BodyText: string;
    values: any;
}

function isFormikErrorsOfIOption(error: any): error is FormikErrors<IListOption> {
    return error && typeof error.title === 'string';
}


const OptionsInput: React.FC<Props> = ({ options, touched, error, BodyText, values }) => {
    const formik = useFormikContext();
    let [prevLengths, setPrevLengths] = useState(0);
    const [newOptions, setNewOptions] = useState(true);
    const [oldIndex, setOldIndex] = useState(0);
    const handleOptionTextChange = (index: number, text: string) => {
        const updatedOptions = [...options];
        updatedOptions[index] = {
            ...updatedOptions[index],
            keyword: [...updatedOptions[index].keyword],
            title: text
        };
        updatedOptions[index].keyword[1] = text; 
        if(oldIndex != index)
        {
            prevLengths=updatedOptions[index].keyword[1].length-1;
            formik.setFieldValue('options', updatedOptions);
            BodyText = BodyText.slice(0, BodyText.length - 2);
            formik.setFieldValue('text', BodyText);
        }
        if(newOptions)
        {
            setPrevLengths(prevLengths+4);
        }
        BodyText += " ";
        formik.setFieldValue('options', updatedOptions);

        if (updatedOptions[index].keyword[1].length > prevLengths) {
            formik.setFieldValue('text', BodyText);
        }

        else {
            BodyText = BodyText.slice(0, BodyText.length - ((prevLengths-updatedOptions[index].keyword[1].length)+1));
            formik.setFieldValue('text', BodyText);
        }
        setPrevLengths(updatedOptions[index].keyword[1].length);
        setNewOptions(false);
        setOldIndex(index); 
    };

    const handleDelete = (index: number) => {
        const updatedOptions = [...options];
        BodyText = BodyText.slice(0, BodyText.length -(updatedOptions[index].keyword[1].length+4) );
        updatedOptions.splice(index, 1);
        formik.setFieldValue('text', BodyText);
        formik.setFieldValue('options', updatedOptions);
    };

    const handleAddOption = () => {
        const id = uuidv4();
        const updatedOptions = [
            ...options, {
                id,
                order: options.length + 1,
                title: '',
                keyword: [`${options.length + 1}`, '']
            }
        ];
        formik.setFieldValue('options', updatedOptions);
        BodyText +="    ";
        formik.setFieldValue('text', BodyText);
        setNewOptions(true);
        setPrevLengths(0);
    };

    const handleAddKeyword = (id: string) => {
        const updatedOptions: IListOption[] = JSON.parse(JSON.stringify(options));
        const index = updatedOptions.findIndex(option => option.id === id);
        if (index >= 0) {
            updatedOptions[index].keyword.push('');
        }
        formik.setFieldValue('options', updatedOptions);
    }

    const setKeywordValue = (id: string, index: number, value: string) => {
        const updatedOptions: IListOption[] = JSON.parse(JSON.stringify(options));
        const arrayIndex = updatedOptions.findIndex(option => option.id === id);
        if (index >= 0) {
            updatedOptions[arrayIndex].keyword[index] = value;
        }
        formik.setFieldValue('options', updatedOptions);

    }

    const handleDeleteKeyword = (id: string, index: number) => {
        const updatedOptions: IListOption[] = JSON.parse(JSON.stringify(options));
        const arrayIndex = updatedOptions.findIndex(option => option.id === id);
        if (index >= 0) {
            updatedOptions[arrayIndex].keyword.splice(index, 1);
        }
        formik.setFieldValue('options', updatedOptions);
    }

    return (
        <div>
            {options.map((question, parentIndex) => {
                const isError: boolean = (error && isFormikErrorsOfIOption(error[parentIndex]) && error[parentIndex].title) ? true : false;
                const isTouched: boolean = (touched && touched[parentIndex] && touched[parentIndex].title) ? true : false;
                return (
                    <div className={styles.optionBox} key={`keyword-${question.id}-${parentIndex}`} >
                        <Row className={styles.questionRow}>
                            <Col xs={1} style={{ alignItems: 'center' }}>
                                <Button disabled={parentIndex == 0 && options.length <= 1} variant='default' size='sm' className='minusRemoveBtn' onClick={() => handleDelete(parentIndex)}>
                                    <PiMinusCircleFill />
                                </Button>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <FormControl
                                        as='input'
                                        placeholder='Enter option text'
                                        name={`options[${parentIndex}].title`}
                                        value={question.title}
                                        isInvalid={isError && isTouched}
                                        isValid={!isError && isTouched}
                                        onChange={(e: any) => handleOptionTextChange(parentIndex, e.target.value)}
                                        disabled={BodyText.length >= 4092}
                                    />
                                    {BodyText.length >= 4092 && (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            Total characters limit reached.
                                        </div>
                                    )}

                                    {(error && isError && isTouched) && (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {error[parentIndex].title}
                                        </div>
                                    )}
                                </InputGroup>
                                {question.keyword.map((keyword, index) => {
                                    const isListItemError = error && error[parentIndex] && error[parentIndex].keyword && error[parentIndex].keyword[index];
                                    const isListItemTouched = touched && touched[parentIndex] && touched[parentIndex].keyword && touched[parentIndex].keyword[index];
                                    const keywordValid: boolean = (isListItemError && error[parentIndex].keyword[index]) ? true : false;
                                    const keywordTouched: boolean = (isListItemTouched && touched[parentIndex].keyword[index]) ? true : false;
                                    return (
                                        <div key={`keyword-item-${question.id}-${index}`} style={{ marginTop: '5px', display: index < 2 ? 'none' : 'block' }} >
                                            <Row className={styles.questionRow}>
                                                <Col xs={1}>
                                                    {!(index < 2) ? (
                                                        <Button variant='default' size='sm' className='minusRemoveBtn' onClick={() => handleDeleteKeyword(question.id, index)}>
                                                            <PiMinusCircleFill />
                                                        </Button>
                                                    ) : " "}
                                                </Col>
                                                <Col xs={10}>
                                                    <FormControl
                                                        placeholder='Enter the Keyword'
                                                        value={keyword}
                                                        disabled={index < 2}
                                                        name={`options[${parentIndex}].keyword[${index}]`}
                                                        onChange={(e) => setKeywordValue(question.id, index, e.target.value)}
                                                        style={{ marginBottom: '5px' }}
                                                        isInvalid={keywordValid && keywordTouched}
                                                        isValid={!keywordValid && keywordTouched}
                                                    />
                                                    {(error && keywordValid && keywordTouched) && (
                                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                                            {error[parentIndex].keyword[index]}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                        <div className='mt-3'>
                            {question.keyword.length < 12 ? (
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Add new Keyword</Tooltip>}
                                >
                                    <Button size="sm" className="ml-5 sendButton" onClick={() => handleAddKeyword(question.id)}>
                                        + Add Keyword
                                    </Button>
                                </OverlayTrigger>
                            ) : null}
                        </div>
                    </div>
                );
            })}
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Add New Option Text</Tooltip>}
            >
                <Button onClick={handleAddOption} size='sm' className='addButton ml-3'><MdAdd /></Button>
            </OverlayTrigger>
            {(touched && error && typeof error === 'string') ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {error}
                </div>
            ) : null}
        </div>
    );
};

export default OptionsInput;