import { Formik, Field, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, FormGroup, Label } from 'reactstrap';
import ReactSelect from 'react-select';
import { useState } from 'react';
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css'


const validationSchema = Yup.object().shape({
    duration: Yup.string(),
    day: Yup.string().required('Day is required'),
    time: Yup.string()
        .required('Time is required')
        .test(
            'is-future-time',
            'Time must be in the future for Today',
            (value, context) => {
                const selectedDay = context.parent.day;
                const now = new Date();
                const [hours, minutes] = value?.split(':').map(Number) || [];

                if (selectedDay === 'Today' && value) {
                    const selectedTime = new Date();
                    selectedTime.setHours(hours, minutes, 0);
                    return selectedTime > now;
                }
                return true; // If not "Today," skip this validation
            }
        ),
});

interface FailedMessagesFormProps {
    onChange: (value: number) => void;
    onClose: () => void;
}


const calculateDuration = (day: string, time: string) => {
    const now = new Date();
    const [hours, minutes] = time.split(':').map(Number);

    const targetTime = new Date();
    targetTime.setHours(hours, minutes, 0, 0);

    if (day === 'Today') {
        return targetTime.getTime() > now.getTime()
            ? targetTime.getTime() - now.getTime()
            : 0; // Return 0 if the selected time is in the past
    } else if (day === 'Tomorrow') {
        const tomorrowTime = new Date(targetTime.getTime() + 24 * 60 * 60 * 1000);
        return tomorrowTime.getTime() - now.getTime();
    }

    return 0;
};

const FailedMessagesForm: React.FC<FailedMessagesFormProps> = ({ onChange, onClose }) => {
    const [days, setDays] = useState([{ label: "Today", value: "Today" }, { label: "Tomorrow", value: "Tomorrow" }]);

    return (
        <Formik
            initialValues={{ duration: '', day: '', time: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const duration = calculateDuration(values?.day, values?.time);
                onChange(Number(duration));
            }}
        >
            {({ values, errors, touched, setFieldValue }) => (
                <Form >
                    <FormGroup className="mt-3">
                        <div className="center-content">
                            <div>
                                <div className="label-select-container">
                                    <Label> Day <span className="required"></span></Label>
                                    <div style={{marginLeft: '8px'}}>
                                    <ReactSelect
                                        className="react-select-container ml-5"
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                                            menu: base => ({ ...base, width: 120 }),
                                            control: base => ({ ...base, width: 120 }),
                                        }}
                                        value={days?.find((day: any) => day?.value === values?.day)}
                                        options={days}
                                        onChange={(selected: any) => {
                                            setFieldValue('day', selected?.value);
                                        }}
                                        placeholder="Select Day"
                                    />
                                    </div>
                                </div>
                                {touched.day && errors.day && (<div className="text-danger leftAlign">{errors.day}</div>)}
                            </div>


                            <FormGroup className="mt-3">
                                <Label>Time <span className="required"></span></Label>
                                    <TimePicker
                                        onChange={(selected: any) => { setFieldValue('time', selected ?? "") }}
                                        value={values?.time}
                                        format="h:mm a"
                                        disableClock={true}
                                        className="ml-5"
                                        clearIcon={values?.time ? undefined : null}
                                    />
                                {touched.time && errors.time && (<div className="text-danger">{errors.time}</div>)}
                            </FormGroup>
                        </div>


                        <div className="d-flex  justify-content-center mt-4">
                            <Button
                                variant=""
                                type="button"
                                className={`cancelButton`}
                                onClick={() => onClose()}
                            >
                                Cancel
                            </Button>

                            <Button type="submit" className="sendButton ml-2">
                                Send
                            </Button>
                        </div>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    );
};

export default FailedMessagesForm;
