import styles from './errorFallback.module.css';

const ErrorFallback: React.FC = () => {
    return (
        <div className={styles.errorFallback}>
            <i className="fa fa-exclamation-triangle"></i>
            <h3>Something went wrong!</h3>
            <p>Try again later!</p>
        </div>
    );
}

export default ErrorFallback;