import React, { useEffect } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { Provider } from 'react-redux';
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './styles/styles.scss';
import Bots from './pages/bots';
import Flows from './pages/flows';
import DesignFlow from './pages/designFlow';
import store from './store';
import mainStore from '../redux/store/store';
import { useAppDispatch, useAppSelector } from './hooks';
import { setBusinessId, setBusinessUid, setChannelUid, setChannels, setMemberUid, setProfile, setRoleRules, setNumberMasking } from './store/metaSlice';


const MetaHOC = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch();
  const currentChannelUId = useAppSelector((state) => state.meta.channelUid);
  const currentBusinessUid = useAppSelector((state) => state.meta.businessUid);
  const currentChannelData = useAppSelector((state) => state.meta.channels);
  const currentMemberUid = useAppSelector((state) => state.meta.memberUid);
  const currentProfile = useAppSelector((state) => state.meta.profile);
  const currentBusinessId = useAppSelector((state) => state.meta.businessId);
  useEffect(() => {
    const syncMetadata = () => {
      const channelUId = mainStore.getState().cartreducer.channelUid.value;
      const businessUid = mainStore.getState().cartreducer.business?.business?.uid;
      const businessId = mainStore.getState().cartreducer.business?.business?.id;
      const channelData = mainStore.getState().cartreducer.channelData;
      const memberUid = mainStore.getState().cartreducer.business?.uid;
      const profileData =  mainStore.getState().cartreducer.business;
      const roleRules = mainStore.getState().cartreducer.roleRules;
      const numberMasking = mainStore.getState().cartreducer.numberMasking;
      if (roleRules) {
        dispatch(setRoleRules(roleRules));
      }
      if (numberMasking) {
        dispatch(setNumberMasking(numberMasking));
      }
      if (channelUId && channelUId !== currentChannelUId) {
        dispatch(setChannelUid(channelUId));
      }
      if (businessUid && businessUid !== currentBusinessUid) {
        dispatch(setBusinessUid(businessUid));
      }
      if(businessId && businessId !== currentBusinessId) {
        dispatch(setBusinessId(businessId));
      }
      if (memberUid && memberUid !== currentMemberUid) {
        dispatch(setMemberUid(memberUid));
      }
      if (currentChannelData === null || (JSON.stringify(currentChannelData) !== JSON.stringify(channelData))) {
        dispatch(setChannels(channelData));
      }
      if(profileData && profileData !== currentProfile){
        dispatch(setProfile(profileData));
      }
    }
    syncMetadata();
    const unsubscribe = mainStore.subscribe(() => {
      syncMetadata();
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return children;
}

const BotFlows: React.FC = () => {
  return (
    <Provider store={mainStore}>
      <Provider store={store}>
        <MetaHOC>
          <Routes>
            <Route path='/' element={<Bots />} />
            <Route path='/:botId' element={<Flows />} />
            <Route path='/flow/:flowId' element={<ReactFlowProvider><DesignFlow /></ReactFlowProvider>} />
          </Routes>
          {/* <ReactQueryDevtools buttonPosition='bottom-left' initialIsOpen={false} /> */}
        </MetaHOC>
      </Provider>
    </Provider>
  )
}

export default BotFlows
