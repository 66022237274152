import { ListGroup } from 'react-bootstrap';
import { MdOutlineMusicVideo, MdOutlineVideocam } from 'react-icons/md';
import { BsImages } from 'react-icons/bs';
import excelIcon from '../../../../assets/img/excel.png';
import pdfIcon from '../../../../assets/img/pdf.png';
import Node from './node';
import OptionItem from './optionItem';
import classes from './sendButtonOption.module.css';
import removeVariables from '../../../utils/removeVariables';

function SendButtonOption(props: any) {
   
    const url = props.data.media ? props.data.media.url : removeVariables(props.data.mediaUrl).trim();
    return (
        <Node {...props} showRight={false} label='Ask Buttons' content={(
            <div>
                {props.data.header.headerType !== "text" && props.data.header.headerType !== "none" ?
                    <>
                        <span className='mr-1'>
                            {props.data.header.headerType === 'image' ? <BsImages /> : null}
                            {props.data.header.headerType === 'video' ? <MdOutlineVideocam /> : null}
                            {props.data.header.headerType === 'document' ? (
                                /\.(xls|xlsx)$/i.test(url) ? (
                                    <img src={excelIcon} alt='' style={{ width: 8 }} />
                                ) : (/\.(pdf)$/i.test(url)) ? (
                                    <img src={pdfIcon} alt='' style={{ width: 8 }} />
                                ) : <MdOutlineMusicVideo />
                            ) : null}
                        </span>
                        {props.data.media ? props.data.media.fileName : props.data.header.headerType}

                    </> : null}
                {props.data.header && props.data.header.headerType && props.data.header.headerType === 'text' ? (
                    <span className={classes.header}>
                        {props.data.headerText ? props.data.headerText : null}
                    </span>
                ) : null}
                <div>
                {props.data.bodyText? (
            <div style={{ cursor: 'pointer' }}>
                {props.data.bodyText.substring(0, 23)}
                {props.data.bodyText.length > 23 ? '...' : null}
            </div>
        ) : null}                </div>
                <ListGroup as='ol' className={classes.list}>
                    {props.data.dynamic ? (
                        <i className='text-muted'>(Dynamic Options)</i>
                    ) : props.data.options.map((option: any, i: number) => {
                        return (
                            <OptionItem
                                key={option.uid}
                                id={`send_reply_buttons/${i + 1}`}
                                optionText={option.optionText}
                                readOnly={props.readOnly}
                                isOption={true}
                            />
                        );
                    })}
                </ListGroup>
                {props.data.footerText? (
            <div style={{ cursor: 'pointer' }}>
                {props.data.footerText.substring(0, 23)}
                {props.data.footerText.length > 23 ? '...' : null}
            </div>
        ) : null}  
            </div>
        )} />
    );
}

export default SendButtonOption;