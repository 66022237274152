import React, { useState, useEffect, useRef } from "react";
import styles from "./dashboard.module.scss";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Input,
  PopoverBody,
  Label,
} from "reactstrap";
import { ticketsDashBoard } from "../../../services/ticketService";
import { useSelector } from "react-redux";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Dropdown,
  Button,
  Spinner,
  OverlayTrigger,
  Overlay,
  Popover,
  Form,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import { BsCalendar } from "react-icons/bs";
import Select, { components } from "react-select";
import { Field } from "formik";
import {
  getAllTeam,
  getAllMembers,
  getTeamBasedOnMember
} from "../../../services/contactMemberService";
import { sliceText } from "../../../common/sliceText";
import TooltipReference from "../../../common/tooltip";
import LineCharts from "./Charts/LineCharts";
import BarCharts from "./Charts/BarCharts";
import ScatterCharts from "./Charts/ScatterCharts";
import MemberList from "./MemberList";
import getAdjustedDateRange from "../../../utils/getAdjustedDateRange";
import { Item } from "react-contexify";
import TeamList from "./TeamList";
import { getTicketFields } from "../../../services/ticketService";
import TypeList from "./TypeList";
import SidebarChart from "./Charts/SideBarCharts";
import { ErrorImage } from "../../../common/ErrorMessage";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { RiTeamLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import { GoPerson } from "react-icons/go";
const moment = extendMoment(originalMoment);

interface DashboardData {
  firstResponseoverdue: number;
  resolutionoverdue: number;
  unassignedTicket: number;
  firstresposeAndResolutionTimes: any[]; // Specify the correct type instead of any if possible
  ticketsTotalCounts: any[]; // Specify the correct type instead of any if possible
  averageTime: any[]; // Specify the correct type instead of any if possible
  ticketStatusAndPriority: any[];
  ticketTypesReport: any[];
  // Specify the correct type instead of any if possible
}

interface CardInfoProps {
  title: string;
  dataKey: keyof DashboardData;
  index: number;
  // This ensures the dataKey is a valid key of DashboardData
}

enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}

const countColor = [
  "#007EC3",
  "#DFCD24",
  "#6EC77C",
  "#A7A9B2",
  "#21a144",
  "#21a144",
  "#3364fa",
  "#4aa6fc",
  "#3364fa",
  "#4aa6fc",
  "#4aa6fc",
  "#4aa6fc",
];

type Props = {};
const Dashboard = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    firstResponseoverdue: 0,
    resolutionoverdue: 0,
    unassignedTicket: 0,
    firstresposeAndResolutionTimes: [],
    ticketsTotalCounts: [
      {
        open: 0,
        pending: 0,
        closed: 0,
      },
    ],
    averageTime: [],
    ticketStatusAndPriority: [],
    ticketTypesReport: [],
  });
  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const business = useSelector((state: any) => state.cartreducer.business);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [teamList, setTeamList] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isType, setIsType] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [typeList, setList] = useState([]);
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<
    { id: string; label: string }[]
  >([]);

  // const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());

  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const datepickertarget = useRef(null);
  const filtertarget = useRef(null);
  const targetMember = useRef(null);
  const targetType = useRef(null);

  const currentDate = new Date();
  const today = moment().clone().startOf("day");
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );

  const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(
    dateRangeValue.start,
    dateRangeValue.end
  );
  // console.log('dateRange',dateRangeValue)

  const [currentData, setCurrentData] = useState({
    startTime: adjustedStart.valueOf(),
    endTime: adjustedEnd.valueOf(),
    teamUid: [],
    memberUid: [],
    types: [],
  });
  console.log(
    "currentData",
    currentData,
    adjustedStart.valueOf(),
    adjustedEnd.valueOf()
  );

  const updateTeamUid = (newTeamUid: any) => {
    setCurrentData((prevData) => ({
      ...prevData,
      teamUid: Array.from(newTeamUid),
    }));
  };

  const updateMemberUid = (newMemberUid: any) => {
    setCurrentData((prevData) => ({
      ...prevData,
      memberUid: Array.from(newMemberUid),
    }));
  };

  const fetchData = async () => {
    const dashbarod = await ticketsDashBoard(channelUid, currentData);
    if (dashbarod) {
      setIsLoading(false);
      setDashboardData(dashbarod);
    }
  };
  const fetchTeam = async () => {
    const listPayload = {
      businessUid: businessId,
      page: 0,
      limit: 1000,
    };
         if (
        business?.role?.name === "OWNER" ||
        business?.role?.name === "ADMIN"
      ) {
        
        const response = await getAllTeam(listPayload);
        const memberResponse = await getAllMembers(businessId, 100, 0);
        if (response) {
          const data = response?.data;
          setTeamList(data);
        }
        if (memberResponse) {
          const data = memberResponse?.dataDTO;
          setMemberList(data);
        }
      } else {
        getTeamsForMember(business?.uid);
      }
    const ticketResponse = await getTicketFields(channelUid);
    if (ticketResponse) {
      const data = ticketResponse?.data;
      setList(data);
    }
  };

  const getTeamsForMember = async (memberUid: string) => {
    try {
      const payload = { memberUid: [memberUid] };
      const teamResponse = await getTeamBasedOnMember(payload);
      setTeamList(teamResponse);
    } catch {
      setTeamList([]);
    }
  };

  

  useEffect(() => {
    if (channelUid) {
      fetchData();
      fetchTeam();
    }
  }, [channelUid]);
  const prevDataRef = useRef(currentData);

  useEffect(() => {
    // Compare the current data with the previous data
    if (JSON.stringify(prevDataRef.current) !== JSON.stringify(currentData)) {
      fetchData();
    }
    // Update the ref to the current data
    prevDataRef.current = currentData;
  }, [currentData]);

  useEffect(() => {
    setCurrentData((item: any) => ({
      ...item,
      startTime: adjustedStart.valueOf(),
      endTime: adjustedEnd.valueOf(),
    }));
  }, [adjustedStart, adjustedEnd]);

  const getStartDate = (
    subtractDays: number,
    unit: moment.unitOfTime.DurationConstructor = "days"
  ) => {
    return today.clone().subtract(subtractDays, unit).startOf(unit);
  };

  const changeDateFilterHandler = (event: any) => {
    const filter = event.target.value as DateFilterType;

    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(
          moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisWeek:
        setDateRangeValue(
          moment.range(today.clone().startOf("week"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousWeek:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "week"),
            getStartDate(1, "week").endOf("week")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisMonth:
        setDateRangeValue(
          moment.range(today.clone().startOf("month"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousMonth:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;

      default:
        break;
    }
    // setCurrentData((item: any) => ({ ...item, startTime: adjustedStart.valueOf(),endTime: adjustedEnd.valueOf() }));
    setDateFilter(filter);
  };

  const CardInfo = ({ title, dataKey, index }: CardInfoProps) => (
    <Col md={3}>
      <Card>
        <CardBody className="text-center">
          <div className="font-weight-bold" style={{ color: "#808290" }}>
            {title}
          </div>
          <div className="fs-2 fw-bold" style={{ color: countColor[index] }}>
            {dashboardData?.[dataKey]}
          </div>
        </CardBody>
      </Card>
    </Col>
  );

  const formatTimeDetailed = (totalSeconds: number) => {
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return { days, hours, minutes, seconds };
  };

  // React component to display time
  const TimeDisplay = ({ averageFirstResponse }: any) => {
    const { days, hours, minutes, seconds } =
      formatTimeDetailed(averageFirstResponse);
    return (
      <>
        {days}d {hours}h {minutes}m {seconds}s
      </>
    );
  };

  const AverageResolution = ({ averageResolution }: any) => {
    const { days, hours, minutes, seconds } =
      formatTimeDetailed(averageResolution);
    return (
      <>
        {days}d {hours}h {minutes}m {seconds}s
      </>
    );
  };

  const handleCloseMemberList = () => {
    setIsMember(false);
  };

  const handleApply = (selected: { id: string; label: string }[]) => {
    console.log("Selected items:", selected);
    setSelectedItems(selected);
    const selectedLabels = selected.map((item) => item.label);
    setCurrentData((prevData: any) => ({
      ...prevData,
      types: selectedLabels,
    }));
    setIsType(false);
  };
  const handleCancel = () => {
    setIsType(false);
    // Handle cancellation (e.g., close modal)
  };
  const defaultCounts = { open: 0, pending: 0, closed: 0 };
  const countsTicket = dashboardData?.ticketsTotalCounts?.[0] || defaultCounts;

  const TicketCard = ({
    status,
    count,
    color,
  }: {
    status: string;
    count: number;
    color: string;
  }) => (
    <Col md={3}>
      <Card>
        <CardBody className="text-center">
          <div className="font-weight-bold" style={{ color: "#808290" }}>
            {status}
          </div>
          <div className="fs-2 fw-bold" style={{ color }}>
            {count}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
  return (
    <div className={styles.dashboardContainer}>
      <Row className="pb-2 pt-2">
        <Col md="3">
          <div className={styles.header}>Ticketing Dashboard</div>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="date-filter d-flex ">
            <div>
              <Button
                ref={targetType}
                onClick={() => {
                  setIsType((current) => !current);
                  setShowFilter(false);
                  setIsMember(false);
                  // setCurrentData((item: any) => ({ ...item, teamUid: [] }));
                  // setShowFilter(false)
                }}
                // className={`${currentData?.memberUid?.length > 0 ? styles.TeamBg : ''} me-2`}
                style={{
                  border: "1px solid #dee2e6",
                  width: "10rem",
                  color: "#808080",
                }}
                className={`${
                  selectedItems?.length > 0 ? styles.TeamBg : ""
                } me-2`}
                variant=""
              >
                <VscTypeHierarchySub /> Type
              </Button>
              <Overlay
                target={targetType.current}
                show={isType}
                placement="bottom"
              >
                <Popover
                  style={{
                    maxHeight: "35%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    margin: "-5px",
                    padding: "0px",
                  }}
                >
                  <Popover.Body style={{ paddingTop: "0px" }}>
                    <TypeList
                      typeList={typeList}
                      onApply={handleApply}
                      selectedItems={selectedItems}
                      onCancel={handleCancel}
                    />
                  </Popover.Body>
                </Popover>
              </Overlay>
              {(business?.role?.name === "OWNER" ||
                business?.role?.name === "ADMIN" )&& (
                  <Button
                    ref={targetMember}
                    onClick={() => {
                      setIsMember((current) => !current);
                      setCurrentData((item: any) => ({ ...item, teamUid: [] }));
                      setShowFilter(false);
                      setIsType(false);
                    }}
                    className={`${
                      currentData?.memberUid?.length > 0 ? styles.TeamBg : ""
                    } me-2`}
                    style={{
                      border: "1px solid #dee2e6",
                      width: "10rem",
                      color: "#808080",
                    }}
                    variant=""
                  >
                    <GoPerson />
                    Members
                  </Button>
                )}
              <Overlay
                target={targetMember.current}
                show={isMember}
                placement="bottom"
              >
                <Popover
                  style={{
                    maxHeight: "35%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    margin: "-5px",
                    padding: "0px",
                  }}
                >
                  <Popover.Body style={{ paddingTop: "0px" }}>
                    <MemberList
                      memberList={memberList}
                      selectedMembers={currentData?.memberUid}
                      updateMemberUid={updateMemberUid}
                      onClose={handleCloseMemberList}
                    />
                  </Popover.Body>
                </Popover>
              </Overlay>
              <Button
                ref={filtertarget}
                onClick={() => {
                  setShowFilter((current) => !current);
                  setCurrentData((item: any) => ({ ...item, memberUid: [] }));
                  setIsMember(false);
                  setIsType(false);
                }}
                //   className="filter"
                className={currentData?.teamUid.length > 0 ? styles.TeamBg : ""}
                style={{
                  border: "1px solid #dee2e6",
                  width: "10rem",
                  color: "#808080",
                }}
                variant=""
              >
                <GrGroup /> All Teams
              </Button>
              <Overlay
                target={filtertarget.current}
                show={showFilter}
                placement="bottom"
              >
                <Popover
                  style={{
                    maxHeight: "35%",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    margin: "-5px",
                    padding: "0px",
                  }}
                >
                  <Popover.Body style={{ paddingTop: "0px" }}>
                    <TeamList
                      filters={teamList}
                      selectedTeam={currentData?.teamUid}
                      setFilters={updateTeamUid}
                      onClose={() => setShowFilter(false)}
                    />
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
            <div className="date-filter-selector me-2 ms-2">
              <Input
                className={`dateFilter-dateFilter`}
                type="select"
                size={1}
                value={dateFilter}
                style={{
                  fontFamily: '"Public Sans", sans-serif',
                  fontSize: "14px",
                  padding: "8px 25px",
                }}
                onChange={changeDateFilterHandler}
              >
                <option value={DateFilterType.Past7Days}>Past 7 days</option>
                <option value={DateFilterType.Past30Days}>Past 30 days</option>
                <option value={DateFilterType.Past90Days}>Past 90 days</option>
                <option value={DateFilterType.ThisWeek}>This week</option>
                <option value={DateFilterType.PreviousWeek}>
                  Previous week
                </option>
                <option value={DateFilterType.ThisMonth}>This month</option>
                <option value={DateFilterType.PreviousMonth}>
                  Previous month
                </option>
                <option value={DateFilterType.CustomRange}>Custom range</option>
              </Input>
            </div>
            <div className="date-range-picker">
              <Overlay
                target={datepickertarget.current}
                show={showDatePicker}
                placement="bottom"
                rootClose={true}
                onHide={() => setShowDatePicker(false)}
              >
                <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                  <DateRangePicker
                    value={dateRangeValue}
                    minDate={currentDate}
                    onSelect={(value: any) => {
                      setDateRangeValue(
                        moment.range(
                          moment(value.start).startOf("day"),
                          moment(value.end).endOf("day")
                        )
                      );
                      // setCurrentData((item: any) => ({ ...item, startTime: adjustedStart.valueOf(),endTime: adjustedEnd.valueOf() }));
                      setDateFilter(DateFilterType.CustomRange);
                      setShowDatePicker(false);
                      // campaignFilterQuery.refetch();
                    }}
                    maximumDate={today.endOf("day")}
                    singleDateRange={true}
                  />
                </Popover>
              </Overlay>
              <div
                className={`form-control calenderInput-alignDatepicker`}
                ref={datepickertarget}
                onClick={() => {
                  setShowDatePicker((current) => !current);
                  setIsMember(false);
                  setShowFilter(false);
                }}
              >
                <BsCalendar />
                <span className="value ml-2">
                  {dateRangeValue.start.format("DD/MM/YYYY")}
                  {" - "}
                  {dateRangeValue.end.format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {isLoading ? (
        <div className="centerItemsWithWidth">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {
            <>
              <Row>
                {/* {dashboardData?.ticketsTotalCounts?.length === 0&&(
                <>
                <Col md={3}>
                  <Card>
                    <CardBody className="text-center">
                      <div>Open</div>
                      <div
                        className="fs-2 fw-bold"
                        style={{ color: countColor[1] }}
                      >
                        0
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody className="text-center">
                      <div>Pending</div>
                      <div
                        className="fs-2 fw-bold"
                        style={{ color: countColor[2] }}
                      >
                     0
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody className="text-center">
                      <div>Closed</div>
                      <div
                        className="fs-2 fw-bold"
                        style={{ color: countColor[3] }}
                      >
                       0
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
              )}
                {dashboardData?.ticketsTotalCounts?.map((item: any) => {
                  return (
                    <>
                      <Col md={3}>
                        <Card>
                          <CardBody className="text-center">
                            <div>Open</div>
                            <div
                              className="fs-2 fw-bold"
                              style={{ color: countColor[1] }}
                            >
                              {item.open}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={3}>
                        <Card>
                          <CardBody className="text-center">
                            <div>Pending</div>
                            <div
                              className="fs-2 fw-bold"
                              style={{ color: countColor[2] }}
                            >
                              {item.pending}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={3}>
                        <Card>
                          <CardBody className="text-center">
                            <div>Closed</div>
                            <div
                              className="fs-2 fw-bold"
                              style={{ color: countColor[3] }}
                            >
                              {item.closed}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  );
                })} */}

                <TicketCard
                  status="Open"
                  count={countsTicket.open}
                  color={countColor[0]}
                />
                <TicketCard
                  status="Pending"
                  count={countsTicket.pending}
                  color={countColor[1]}
                />
                <TicketCard
                  status="Closed"
                  count={countsTicket.closed}
                  color={countColor[2]}
                />
                <CardInfo
                  title="Unassigned"
                  dataKey="unassignedTicket"
                  index={3}
                />
              </Row>
              {/* <Row>
                <CardInfo
                  title="First Response Due"
                  dataKey="firstResponseoverdue"
                  index={0}
                />
                <CardInfo
                  title="Resolution Overdue"
                  dataKey="resolutionoverdue"
                  index={1}
                />
                  {dashboardData?.averageTime?.map((item) => {
                  return (
                    <>
                      <Col md={3}>
                        <Card>
                          <CardBody className="text-center">
                            <div>Average Resolution</div>
                            <div
                              className="fs-3 pb-2 fw-bold"
                              style={{ color: countColor[1] }}
                            >
                              <AverageResolution averageResolution={item.averageResolution} />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  );
                })}
                {dashboardData?.averageTime?.map((item) => {
                  return (
                    <>
                      <Col md={3}>
                        <Card>
                          <CardBody className="text-center">
                            <div>Average First Response</div>
                            <div
                              className="fs-3 pb-2 fw-bold"
                              style={{ color: countColor[1] }}
                            >
                              <TimeDisplay averageFirstResponse={item.averageFirstResponse} />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  );
                })}
              </Row>
              */}
              <Row>
                {/* <Col>
                <div>
                    Number of tickets violating First response time and
                    Resolution time
                  </div>
                  { dashboardData?.firstresposeAndResolutionTimes.length > 0 ? 
                  <LineCharts
                    firstresposeAndResolutionTimes={
                      dashboardData?.firstresposeAndResolutionTimes
                    }
                  />:
                    <ErrorImage />
                  }
                </Col> */}
                <Col>
                  <div>Ticket Created by Priority</div>
                  {dashboardData?.ticketStatusAndPriority?.length > 0 ? (
                    <BarCharts
                      ticketStatusAndPriority={
                        dashboardData?.ticketStatusAndPriority
                      }
                    />
                  ) : (
                    <ErrorImage />
                  )}
                </Col>
                <Col>
                  <div>New tickets vs Closed tickets</div>
                  {dashboardData?.ticketStatusAndPriority?.length > 0 ? (
                    <ScatterCharts
                      ticketStatusAndPriority={
                        dashboardData?.ticketStatusAndPriority
                      }
                    />
                  ) : (
                    <ErrorImage />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>Type Based Performance</div>
                  {dashboardData?.ticketTypesReport?.length > 0 ? (
                    <SidebarChart
                      ticketTypesReport={dashboardData?.ticketTypesReport}
                    />
                  ) : (
                    <ErrorImage />
                  )}
                </Col>
                <Col></Col>
              </Row>
              {/* <Row>
                <Col>
                  <div>New tickets vs Closed tickets</div>
                </Col>
              </Row> */}
              {/* <Row>
                <Col>
                  <ScatterCharts
                    ticketStatusAndPriority={
                      dashboardData?.ticketStatusAndPriority
                    }
                  />
                </Col>
              </Row> */}
              {/* <Row>
                <Col>
                  <div>Agent Performance</div>
                </Col>
              </Row> */}
              {/* <Row>
                <Col>
                <SidebarChart
                ticketTypesReport = {dashboardData?.ticketTypesReport}
                />
                </Col>
              </Row> */}
            </>
          }
        </>
      )}
    </div>
  );
};

export default Dashboard;
