import { useEffect, useRef, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import EditorCaption from '../editorCaption';
import BodyInput from '../bodyInput';
import { useElmentEditor } from '../hooks';
import { IEditorProps } from '../types';
import ReactSelect from 'react-select';
import { useAppSelector } from '../../../../hooks';
import { metaGetAll, metaProduct, getCatalogueCollections } from '../../../../../services/catalogueService';
import Creatable from 'react-select/creatable';
import styles from '../actions/updateConversationTag.module.scss';
import { LuShoppingCart } from 'react-icons/lu';
import { Label } from 'reactstrap';
import classes from './sendProducts.module.scss';
import Select from 'react-select/dist/declarations/src/Select';


interface productType {
    id: string;
    name: string;
    retailerId: string;
    imageUrl: string;
}

interface FormData {
    bodyText: string;
    headerText: string;
    footerText: string | null;
    catalogueData: {
        catalogueId: string;
        catalogueType: string;
        sections: [
            {
                title: string;
                products: productType[];
            }
        ] | null;
        catalogueCollection: {
            id: string;
            name: string;
            catalogueId: string;
        } | null;
    }
    ownFlowOrder: boolean;
    selectAllProducts: boolean;
};

function SendProductEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        bodyText: '',
        headerText: '',
        footerText: null,
        catalogueData: {
            catalogueId: '',
            catalogueType: 'product_list',
            sections: [
                {
                    title: '',
                    products: [

                    ]
                }
            ],
            catalogueCollection: {
                id: '',
                name: '',
                catalogueId: ''
            }
        },
        ownFlowOrder: true,
        selectAllProducts: false
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_product',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        bodyText: yup.string().required('Message Text is required').test('is-double-space', 'Message Text is required', value => value !== '  '),
        headerText: yup.string().required('Header is required'),
        footerText: yup.string().nullable(),
        ownFlowOrder: yup.string().nullable(),
        selectAllProducts: yup.boolean(),
        catalogueData: yup.object().shape({
            catalogueId: yup.string().required('Catalogue is required'),
            catalogueType: yup.string().required('Catalogue Type is required'),
            sections: yup.array().when(['$ownFlowOrder', '$selectAllProducts'], {
                is: (ownFlowOrder: any, selectAllProducts: any) => ownFlowOrder && !selectAllProducts, 
                then: yup.array().of( // then validate sections
                    yup.object().shape({
                        title: yup.string().nullable(),
                        products: yup.array().of(
                            yup.object().shape({
                                id: yup.string().required('Product is required'),
                            })
                        ).min(1, 'At least one product is required').required('At least one product is required')
                    })
                ),
                otherwise: yup.array().nullable() // otherwise allow sections to be null
            }),
            catalogueCollection: yup.object().when('$ownFlowOrder', {
                is: false, // when ownFlowOrder is not null
                then: yup.object().shape({ id: yup.string().required('Collection is required') }).required('collection'),
                otherwise: yup.object().nullable() // otherwise allow catalogueCollection to be null
            })
        })
    });

    const [products, setProducts] = useState([{ label: "", value: "", catalogId: "" }]);
    const [catalogueProducts, setCatalogueProducts] = useState([]);
    const [filterProducts, setFilterProducts] = useState([{ label: "", value: "", catalogId: "" }]);
    const [options, setOptions] = useState([{ label: "", value: "" }]);
    const [collections, setCollections] = useState([{ label: "", value: "", catalogueId: "" }]);
    const [isProduct, setIsProduct] = useState(true);
    const [isCollection, setIsCollection] = useState(false);
    const channelId = useAppSelector(state => state.meta.channelUid);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const debounceTimeout = useRef<number | null>(null);
    const currentPage = useRef(0);
    const [totalCount, setTotalCount] = useState(10);
    const [allProducts, setAllProducts] = useState<any[]>();
    const [rendering, setRendering] = useState(false);
    const [scrollProducts, setScrollProducts] = useState<any[]>([]);

    const getCatalogue = async (channelId: string) => {
        const response = await metaGetAll(channelId);
        if (response) {
            const data = response.data;
            const transformedOptions = data.map((item: any) => ({
                label: item.name,
                value: item.id
            }));
            setOptions(transformedOptions);
            return data;
        } else {
            return [];
        }
    }

    const getCollections = async (channelId: string): Promise<any> => {
        const response = await getCatalogueCollections(channelId);
        if (response) {
            const data = response.data;
            const collection = data?.map((Item: any) => ({
                label: Item.name,
                value: Item.id,
                catalogueId: Item.catalogueId
            }));
            setCollections(collection);
            return data;
        } else {
            return [];
        }
    }


    const getCatalogueProducts = async (search: string, page: number, limit: number) => {
        setIsLoading(true);
        const listPayload = {
            channelId: channelId,
            page: page,
            limit: limit,
            search: search
        };
        const response = await metaProduct(listPayload);
        if (response) {
            const { catalogueProduct } = response.data;
            setCatalogueProducts(catalogueProduct);
            setFilterProducts(catalogueProduct.map((item: any) => ({ label: item.name, value: item.id, catalogId: item.catalogId })));
            setProducts(catalogueProduct.map((item: any) => ({ label: item.name, value: item.id, catalogId: item.catalogId })));
            setTotalCount(response.data?.count);
            if (response?.data?.count <= 10) {
                setHasMore(false);
            }
            else {
                setHasMore(true);
            }
            setIsLoading(false);
        }
        else {
            setHasMore(false);
            setIsLoading(false);
            return [];
        }
    }

    const setAllProductsValues = async () => {
        setIsLoading(true);
        setRendering(true);
        const listPayload = {
            channelId: channelId,
            page: 0,
            limit: totalCount,
            search: ''
        };
        const response = await metaProduct(listPayload);
        if (response) {
            const { catalogueProduct } = response.data;
            setAllProducts(catalogueProduct);
            setTotalCount(response.data?.count);
            if (response?.data?.count <= 10) {
                setHasMore(false);
            }
            else {
                setHasMore(true);
            }
            setIsLoading(false);
            setRendering(false);
        }
        else {
            setHasMore(false);
            setIsLoading(false);
            setRendering(false);
            return [];
        }
    }


    useEffect(() => {
        getCatalogue(channelId ?? '');
    }, [channelId]);

    useEffect(() => {
        getCatalogueProducts('', 0, 10);
    }, [channelId]);


    useEffect(() => {
        getCollections(channelId ?? '');
    }, [channelId]);

    const handleInputChange = (inputValue: any) => {
        setSearchTerm(inputValue);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = window.setTimeout(() => {
            if (hasMore) {
                getCatalogueProducts(inputValue, currentPage.current, 10);
            }
        }, 500);
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);

                useEffect(() => {
                    setIsProduct(values.ownFlowOrder);
                    setIsCollection(!values.ownFlowOrder);
                }, [values.ownFlowOrder]);

                useEffect(() => {
                    setProducts(filterProducts?.filter((product: any) => product.catalogId === values.catalogueData.catalogueId));
                }, [values.catalogueData.catalogueId])

                const handleSubmission = () => {

                    if(values?.selectAllProducts) {
                        setFieldValue('catalogueData.sections[0].products', allProducts);
                    }

                    if (isProduct) {
                        setFieldValue('catalogueData.catalogueCollection', null);
                        setFieldValue('ownFlowOrder', true);
                    }
                    else {
                        setFieldValue('catalogueData.sections', null);
                        setFieldValue('ownFlowOrder', false);
                    }
                }

                useEffect(() => {
                    if(!values?.selectAllProducts) {
                        setFieldValue(`catalogueData.sections[` + 0 + `].products`, []);
                    }
                    if(values?.selectAllProducts) {
                        setAllProductsValues();
                    }
                }, [values?.selectAllProducts])

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Send Products' icon={<LuShoppingCart color="#5d8ca6" />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <Form.Label>Header <span className='required'></span></Form.Label>
                                <Form.Control
                                    name='headerText'
                                    placeholder='Enter the header text'
                                    value={values.headerText ?? ""}
                                    isInvalid={(touched.headerText && errors.headerText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.headerText && !errors.headerText}
                                    maxLength={60}
                                />
                                <ErrorMessage
                                    name="headerText"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='bodyText'
                                    value={values.bodyText}
                                    isInvalid={(touched.bodyText && errors.bodyText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.bodyText && !errors.bodyText}
                                    error={errors.bodyText}
                                    label={<>Message Text<span className="required"></span></>}
                                    placeholder='  Write your message here...'
                                    limit={4096}
                                />
                                <ErrorMessage
                                    name="bodyText"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Catalogue <span className='required'></span>
                                    <div><small>Please select a catalogue to add products or collection </small></div>
                                </Form.Label>
                                <ReactSelect
                                    name='catalogueData.catalogueId'
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                        menu: base => ({ ...base, width: 420 })
                                    }}
                                    options={options}
                                    value={(() => {
                                        if (values.catalogueData.catalogueId) {
                                            const catalogue = options.find(option => option?.value === values.catalogueData.catalogueId);
                                            if (catalogue) {
                                                return {
                                                    label: catalogue?.label,
                                                    value: catalogue?.value
                                                }
                                            }
                                        }
                                        return ''
                                    })()}
                                    onChange={(selectedOption: any) => {
                                        setFieldValue('catalogueData.catalogueId', selectedOption?.value);
                                        const product = products?.filter((product: any) => product.catalogId === selectedOption?.value);
                                        if (selectedOption.value !== values.catalogueData.catalogueId && product.length === 0) {
                                            setFieldValue('catalogueData.sections[0].products', []);
                                        }
                                    }}
                                    placeholder='Select Catalogue...'
                                />
                                <ErrorMessage
                                    name="catalogueData.catalogueId"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>

                            {values.catalogueData.catalogueId && (
                                <div className={classes.background}>
                                    <Form.Group className='mb-3'>
                                        <div className={classes.radio}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                checked={isProduct}
                                                onChange={() => {
                                                    setIsProduct(true);
                                                    setIsCollection(false);
                                                    setFieldValue('ownFlowOrder', true);
                                                    if (!values.catalogueData.sections) {
                                                        setFieldValue('catalogueData.sections', [{ title: '', products: [] }]);
                                                    }
                                                }}
                                            />
                                            <Label className={classes.productLabel}>Send Products</Label>

                                            <Form.Check
                                                inline
                                                name='dynamic'
                                                type='radio'
                                                checked={isCollection}
                                                onChange={() => {
                                                    setIsProduct(false);
                                                    setIsCollection(true);
                                                    setFieldValue('ownFlowOrder', false);
                                                    if (!values.catalogueData.catalogueCollection) {
                                                        setFieldValue('catalogueData.catalogueCollection', { id: '', name: '', catalogueId: '' });
                                                    }
                                                }}
                                            />
                                            <Label className={classes.productLabel} >Send Collections</Label>
                                        </div>
                                    </Form.Group>


                                    {isProduct && (
                                        <>
                                            <Form.Group className='mb-3'>
                                                <Form.Check
                                                    type="switch"
                                                    label="Select All Products"
                                                    name="selectAllProducts"
                                                    checked={values?.selectAllProducts}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>

                                            <Form.Group className='mb-3'>
                                                <Form.Label>Products <span className="required"></span></Form.Label>
                                                <ReactSelect
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                                        menu: base => ({ ...base, width: 400 })
                                                    }}
                                                    maxMenuHeight={150}
                                                    menuPlacement={scrollProducts?.length > 3 ? 'bottom' : 'top'}
                                                    options={products}
                                                    isClearable={true}
                                                    onChange={(selectedProduct: any) => {
                                                        if (values.catalogueData.sections) {
                                                            const updatedProducts = [
                                                                ...values.catalogueData?.sections[0]?.products.filter(item => item.id !== selectedProduct?.value)
                                                            ];

                                                            const selectedProductToAdd = catalogueProducts?.find((item: any) => item.id === selectedProduct?.value);
                                                            if (selectedProductToAdd) {
                                                                updatedProducts.push(selectedProductToAdd);
                                                            }
                                                            setFieldValue(`catalogueData.sections[` + 0 + `].products`, updatedProducts);
                                                            setScrollProducts(updatedProducts);
                                                        }
                                                        else {
                                                            setFieldValue('catalogueData.sections', [
                                                                {
                                                                    title: '',
                                                                    products: [
                                                                    ]
                                                                }
                                                            ])
                                                            setScrollProducts([]);
                                                        }
                                                    }}
                                                    onMenuScrollToBottom={() => {
                                                        if (!isLoading && hasMore) {
                                                            setPage((prevPage) => prevPage + 1);
                                                            getCatalogueProducts(searchTerm, page + 1, 10);
                                                        }
                                                        else {
                                                            setPage(0);
                                                        }
                                                    }}
                                                    onInputChange={handleInputChange}
                                                    isDisabled={values?.selectAllProducts}
                                                />
                                                <ErrorMessage
                                                    name="catalogueData.sections[0].products"
                                                    component="div"
                                                    className="text-danger pt-1"
                                                />
                                            </Form.Group>
                                            <div className={`${styles.tagsContainer} ${classes.tagsContainer}`} id="product">
                                                { !values?.selectAllProducts && values.catalogueData?.sections && values.catalogueData?.sections[0]?.products.map(product => {
                                                    return (
                                                        <div className={`${styles.contactTag} ${classes.contactTag}`} key={product.id}>
                                                            {product.name}
                                                            <button
                                                                type='button'
                                                                onClick={() => {
                                                                    if (values.catalogueData?.sections) {
                                                                        const updatedProducts = [
                                                                            ...values.catalogueData?.sections[0]?.products?.filter(p => p.id !== product.id)
                                                                        ];
                                                                        setFieldValue(`catalogueData.sections[` + 0 + `].products`, updatedProducts);
                                                                    }
                                                                }}
                                                            >&times;</button>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    )}


                                    {isCollection && (
                                        <>
                                            <Form.Group className='mb-4'>
                                                <Form.Label>Collections<span className='required'></span></Form.Label>
                                                <ReactSelect
                                                    name="catalogueData.catalogueCollection"
                                                    options={collections}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                                        menu: base => ({ ...base, width: 400 })
                                                    }}
                                                    onChange={(collection: any) => {
                                                        const data = collections?.find(option => option?.value === collection?.value);
                                                        const selectedCollection = { id: data?.value, name: data?.label, catalogueId: data?.catalogueId };
                                                        setFieldValue('catalogueData.catalogueCollection', selectedCollection);
                                                    }}
                                                    value={(() => {
                                                        if (values.catalogueData?.catalogueCollection) {
                                                            const collection = collections?.find(option => option?.value === values.catalogueData?.catalogueCollection?.id);
                                                            if (collection) {
                                                                return {
                                                                    label: collection?.label,
                                                                    value: collection?.value,
                                                                    catalogueId: collection?.catalogueId
                                                                }
                                                            }
                                                        }
                                                        return ''
                                                    })()}
                                                />
                                                <ErrorMessage
                                                    name="catalogueData.catalogueCollection.id"
                                                    component="div"
                                                    className="text-danger pt-1"
                                                />
                                            </Form.Group>
                                            <div style={{ height: "2px" }}></div>
                                        </>
                                    )}
                                </div>
                            )}

                            <Form.Group className='mb-4'>
                                <Form.Label>Footer <span className='dimmed'>(Optional)</span></Form.Label>
                                <Form.Control
                                    name='footerText'
                                    placeholder='Enter the footer text'
                                    value={values.footerText ?? ""}
                                    isInvalid={(touched.footerText && errors.footerText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.footerText && !errors.footerText}
                                    maxLength={60}
                                />
                            </Form.Group>

                        </Offcanvas.Body>

                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' onClick={handleSubmission} disabled={props?.isInvalidAccess || rendering}>
                               {rendering ? "Loading.." : "Save"}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SendProductEditor;