import axios from '../../utils/axios';
import { MetaData } from '../components/designFlow/editor/integration/integrationConnection';
import { IActionsField } from '../entity/integration/actionsField';
import { IIntegrationApp } from '../entity/integration/integrationApp';
import { BackendFlow } from '../store/graphSlice';

// import axios from "axios";
// const apiUrl = 'http://183.83.189.151:43251/api';

export const loadFlowElements = async (flowId: string) => {
    try {
        const response = await axios.get(`bot/flow/${flowId}/getElements`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const modifyElements = async (flowId: string, flowBody: BackendFlow[]) => {
    try {
        const response = await axios.post(`bot/flow/${flowId}/modifyElements`, flowBody);
        const data: BackendFlow[] = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const publishFlow = async (flowId: string, jumpToUids: string[]) => {
    return axios.post(`bot/flow/${flowId}/activate`, {
        jumpToUids
    });
}

export const deleteFlow = async (flowId: string, elementId: number, elementUid: string) => {
    return axios.delete(`bot/flow/${elementUid}/deleteElement`);
}

export const apiCall = async (data: any) => {
    return axios.post(`bot/apiCall`, data);
}

export interface IIntegrationConnection {
    id: number,
    name: string,
    app: IIntegrationApp
    token?: string,
    metaData?: MetaData
}

export const getIntegrationConnections = async (app: any | null, businessId: number, scope: string = 'COM') => {
    try {
        const response = await axios.post(`integration/getConnections`, {
            app,
            businessId,
            scope
        });
        const data: IIntegrationConnection[] = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const deleteConnection = (connectionId: number) => {
    return axios.delete('integration/connection/' + connectionId + '/deleteConnection')
}

/**
 * Zoho CRM  
 */
interface IZohoCrmModule {
    id: number,
    label: string,
    fields: IActionsField[]
}

export const getZohoModules = async (connectionId: number, moduleId: number | null = null) => {
    try {
        const response = await axios.post('integration/connection/' + connectionId + '/feature/getModules', { moduleId });

        let data: IZohoCrmModule[] | IActionsField[];
        if (moduleId === null) {
            data = response.data as IZohoCrmModule[];
        } else {
            data = response.data as IActionsField[];
        }

        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export interface IPaymentMethod {
    id: number,
    name: string,
    method: string
}

export const getPaymentMethods = async (channelUid: string) => {
    try {
        const response = await axios.get<IPaymentMethod[]>('paymentConfig/' + channelUid + '/getAll');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export interface IDigitalProduct {
    count: number,
    digitalItem: [ {
    id: number | null,
    uid: string | null,
    name: string,
    amount: string | number,
    quantity: number
    saleAmount: number| string | null
    }
    ]
}


export const getDigitalProducts = async (businessUid: string, request: any) => {
    try {
        const response = await axios.post<IDigitalProduct>(`/digital/${businessUid}/getAll`, request);
        return response.data.digitalItem;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getDigitalCount = async (businessUid: string, request: any) => {
    try {
        const response = await axios.post<IDigitalProduct>(`/digital/${businessUid}/getAll`, request);
        return response.data.count;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const updateIntegrationConnection = async (connectionId: number, responseBody: any) => {
    try {
        const response = await axios.patch<IIntegrationConnection>('integration/connection/' + connectionId + '/updateConnection', responseBody);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}