import React, { useState, useEffect } from "react";
import {
  CardBody,
  Button,
  Col,
  Label,
  Row,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import style from "../TicketFields/ticketFields.module.scss";
import { FaGripVertical, FaTrash } from "react-icons/fa";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { patchTicketField } from "../../../services/ticketService";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Item, getItemIcons } from "../Types";
import { RxDragHandleDots2 } from "react-icons/rx";

interface Status {
  id: number;
  label: string;
  slaPolicy: boolean;
  position: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string | null;
}

interface Props {
  name: string;
  index: number; // Index of the form
  disable: boolean[]; // Individual disable state
  setDisable: (value: boolean[]) => void; // Function to update the array of disable states
  toggler: boolean[]; // Individual toggle state
  setToggler: (value: boolean[]) => void; // Function to update the array of toggle states
  formValue: Item[]; // Array of form values
  setFormValue: (value: ((prevItems: Item[]) => Item[]) | Item[]) => void; // Function to update the form values
  setCallDb: (value: boolean) => void; // Function to update the callDb state
  newlyAddedItemId: string | null; // Id of the newly added item
  setNewlyAddedItemId: (value: string | null) => void; // Function to update the id of the newly added item
  isDefault: boolean; // Boolean to check if the field is default
  setSubmittedItemId: (value: string | null) => void; // Function to update the submitted item id
}

const StatusForm: React.FC<Props> = (props) => {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [statuses, setStatuses] = useState<Status[]>(
    props.formValue[props.index].options
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData?.uid
  );

  const handleClose = () => {
    const componentToggler = [...props.toggler];
    componentToggler[props.index] = false;
    props.setToggler(componentToggler);

    const formDisablerArr = [...props.disable];

    // Set all values in the formDisablerArr array to false
    const allFalseArr = formDisablerArr.map(() => false);
    props.setDisable(allFalseArr);

    // If the item is newly added, remove it from the dynamic list
    if (props.formValue[props.index].id === props.newlyAddedItemId) {
      const updatedItems = props.formValue.filter(
        (item, idx) => idx !== props.index
      );
      props.setFormValue(updatedItems);
      props.setNewlyAddedItemId(null); // Reset the newly added item ID
    }
  };

  useEffect(() => {
    setStatuses(props.formValue[props.index].options);
  }, [props.formValue[props.index].options]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    const updatedStatuses = Array.from(statuses);
    const [removed] = updatedStatuses.splice(source.index, 1);
    updatedStatuses.splice(destination.index, 0, removed);

    // Update the position property based on the new order
    const reorderedStatuses = updatedStatuses.map((status, index) => ({
      ...status,
      position: index + 1,
      updatedAt: new Date().toISOString(),
    }));

    setStatuses(reorderedStatuses);
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string().required("Label is required").test('unique-label', 'Duplicate label not allowed', function (value) {
      const labels = props.formValue.map(item => item.label);
      const labelIndex = labels.indexOf(value || '');
      return labelIndex === -1 || labelIndex === props.index;
    }),
  });

  return (
    <div
      className={style.fieldForm}
      onMouseEnter={() => setHoverIndex(props.index)}
      onMouseLeave={() => setHoverIndex(null)}
    >
      {!props.toggler[props.index] ? (
        <>
          <div
            id={
              props.disable[props.index]
                ? style.fieldDisable
                : style.fieldEnable
            }
            onClick={() => {
              const componentToggler = [...props.toggler];
              componentToggler[props.index] = true;
              props.setToggler(componentToggler);

              const formDisablerArr = [...props.disable];

              // Set all values in the formDisablerArr array to true
              const allTrueArr = formDisablerArr.map(() => true);
              allTrueArr[props.index] = false;
              props.setDisable(allTrueArr);

              props.setCallDb(false);
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <RxDragHandleDots2
                    size={20}
                    opacity={hoverIndex === props.index ? 1 : 0.3}
                  />
                </div>
                <div>{getItemIcons(props.name)}</div>
                <div className="ms-2">{props.name.charAt(0).toUpperCase() + props.name.slice(1)}</div>
              </div>
              <div>
                {props.isDefault && (
                  <div className={style.defaultField}>
                    <div className="text-muted">Default</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Formik
          initialValues={{
            label: props.formValue[props.index].label,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const payload = props.formValue.map((item: any, idx: number) => {
              if (idx === props.index) {
                const payLoadId = typeof item.id === "string" ? 0 : item.id;

                let formattedType = props.name;
                formattedType = formattedType.replace(/^./, (match) =>
                  match.toLowerCase()
                );

                const now = new Date();
                const isoString = now.toISOString();

                return {
                  ...item,
                  id: payLoadId,
                  name: props.name,
                  label: values.label,
                  position: item.position,
                  type: formattedType,
                  isDefault: item.isDefault,
                  deletedAt: null,
                  createdAt: item.createdAt,
                  updatedAt: isoString,
                  options: statuses,
                  deleted: item.deleted,
                  dropDown: true,
                };
              }
              return item;
            });

            patchTicketField(channelUid, payload)
              .then((res) => {
                if (res === "SUCCESS") {
                  props.setCallDb(true);
                  props.setSubmittedItemId(
                    props.formValue[props.index].id.toString()
                  );
                  setTimeout(() => {
                    props.setSubmittedItemId(null);
                  }, 1000);
                }
              })
              .catch((err) => {
                console.log(err);
              });

            handleClose();
          }}
        >
          {({ isSubmitting, values, initialValues }) => {
            useEffect(() => {
              const hasChanges =
                JSON.stringify(values) !== JSON.stringify(initialValues);
              setUnsavedChanges(hasChanges);
            }, [values, initialValues]);

            const handleCancelClick = () => {
              if (unsavedChanges) {
                setModalOpen(true);
              } else {
                handleClose();
                props.setCallDb(false);
              }
            };

            return (
              <Form>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div>{getItemIcons(props.name)}</div>
                    <div className="ms-2">
                      <div className="m-0">{props.name.charAt(0).toUpperCase() + props.name.slice(1)}</div>
                    </div>
                  </div>
                  {props.isDefault && (
                    <div className={style.defaultField}>
                      <div className="text-muted">Default</div>
                    </div>
                  )}
                </div>
                <hr />
                <CardBody>
                  <div
                    className="d-flex flex-column mt-2"
                    style={{ width: "50%" }}
                  >
                    <label htmlFor={`label`}>Label</label>
                    <Field name={`label`} type="text" disabled />
                    <ErrorMessage
                      className="text-danger pt-1"
                      name="label"
                      component="div"
                    />
                  </div>

                  <Label className="mt-4">DROPDOWN CHOICES</Label>
                  <div className={style.statusDropDownTable}>
                    <div className={style.tableHeader}>
                      <Row>
                        <Col xs="1"></Col>
                        <Col xs="3">
                          <Label>Label</Label>
                        </Col>
                        {/* <Col xs="2">
                          <Label>SLA timer</Label>
                        </Col>
                        <Col xs="1"></Col> */}
                      </Row>
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="statusList">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {statuses.map((status, index) => (
                              <Draggable
                                key={status.id.toString()}
                                draggableId={status.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <FormGroup key={status.id}>
                                      <Row>
                                        <Col
                                          xs="1"
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          <RxDragHandleDots2 size={20} />
                                        </Col>
                                        <Col xs="3">
                                          <Input
                                            type="text"
                                            value={status.label}
                                            onChange={(e) => {
                                              const updatedStatuses = [
                                                ...statuses,
                                              ];
                                              updatedStatuses[index].label =
                                                e.target.value;
                                              setStatuses(updatedStatuses);
                                            }}
                                            disabled
                                          />
                                        </Col>
                                        <Col
                                          xs="2"
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          {status.label === "Pending" && (
                                            <Input
                                              type="switch"
                                              checked={status.slaPolicy}
                                              onChange={(e) => {
                                                const updatedStatuses = [
                                                  ...statuses,
                                                ];
                                                updatedStatuses[
                                                  index
                                                ].slaPolicy = e.target.checked;
                                                setStatuses(updatedStatuses);
                                              }}
                                            />
                                          )}
                                        </Col>
                                        <Col
                                          xs="1"
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          {/* <Button color="link" disabled>
                                            <FaTrash color="#cdcdcd" />
                                          </Button> */}
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </CardBody>
                <div className="d-flex justify-content-end align-items-center mt-3">
                  <div className={style.cardFooter}>
                    <button
                      type="button"
                      className={style.cancelbutton}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                    <Button
                      type="submit"
                      className="sendButton"
                      disabled={isSubmitting}
                    >
                      Save field
                    </Button>
                  </div>
                </div>

                <Modal
                  isOpen={modalOpen}
                  toggle={() => setModalOpen(!modalOpen)}
                >
                  <ModalBody>
                    <h6 className="mt-2 mb-3">Unsaved changes</h6>

                    <div className={style.modalText}>
                      Your data will be lost. Are you sure you want to continue?
                    </div>
                  </ModalBody>
                  <ModalFooter className="p-1">
                    <button
                      className={style.cancelbutton}
                      onClick={() => setModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={style.discardButton}
                      onClick={() => {
                        setModalOpen(false);
                        handleClose();
                        props.setCallDb(false);
                      }}
                    >
                      Discard
                    </button>
                  </ModalFooter>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default StatusForm;
