import axios from '../../utils/axios';
import { IKeyword } from '../components/ui/keywordInput';
// import axios from "axios";
import MediaDevicesainStore from '../../redux/store/store';

// const apiUrl = 'http://183.83.189.151:43251/api';

export interface FlowData {
    id: number;
    uid?: string;
    nameInput: string;
    flowType: string;
    flowInstances: IKeyword[];
}

export const getFlows = async (botId: string, searchQuery: string = '') => {
    try {
        const response = await axios.post(`bot/flow/getAll`, {
            uid: botId,
            limit: 100,
            page: 0,
            search: searchQuery === '' ? null : searchQuery
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getFlowsOfChannel = async (channelId: string) => {

    interface FlowsOfChannel {
        count: number,
        whatsappFlow: [
            {
                id: string;
                name: string;
                version: string;
                status: string;
                meta: null | any;
                templateName: null | string;
                createdAt: string;
                updatedAt: string;
                firstScreen: string | null;
            }
        ]
    }

    try {
        const response = await axios.post<FlowsOfChannel>(`flow/getAll`, {
            channelId,
            limit: 1000,
            page: 0,
        });
        const filteredAndMappedWhatsappFlow = response.data?.whatsappFlow
        ?.filter(flow => flow.status === 'DRAFT' || flow.status === 'PUBLISHED')
        ?.map(flow => ({
            id: flow.id,
            name: flow.name,
            version: flow.version,
            status: flow.status,
            meta: flow.meta,
            templateName: flow.templateName,
            createdAt: flow.createdAt,
            updatedAt: flow.updatedAt,
            firstScreen: flow.firstScreen,
        }));

       return filteredAndMappedWhatsappFlow;
    } catch (error) {
        console.error(error);
        return [];
    }
}


export const getFlowsV2 = async (botId: string, searchQuery: string = '') => {
    try {
        const response = await axios.post(`bot/flow/getAllv2`, {
            uid: botId,
            limit: 10,
            page: 0,
            search: searchQuery === '' ? null : searchQuery
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const createFlow = (botId: string, data: any) => {
    const { nameInput,welcomeTrigger, flowType, flowInstances } = data;

    const state = MediaDevicesainStore.getState();
    const memberUid = state.cartreducer.business?.uid;

    return axios.post(`bot/flow/create`, {
        flowName: nameInput,
        flowType: flowType,
        welcomeTrigger:welcomeTrigger,
        flowInstances,
        status: '0',
        botUid: botId,
        memberUid
    });
}

export const updateFlow = (data: any) => {
    return axios.patch(`bot/${data.uid}/flow/update`, data);
}

export const deleteFlow = (flowId: string) => {
    return axios.delete(`bot/${flowId}/flow/delete`);
}

export const copyFlow = (botUid: string, flowUid: string, memberUid: string) => {
    return axios.post(`bot/copyBotFlow`, {
        flowUid,
        botUid,
        memberUid
    });
}

export const getAppointmentFlowsOfChannel = async (channelId: string) => {

    interface FlowsOfChannel {
        count: number,
        whatsappFlow: [
            {
                id: string;
                name: string;
                version: string;
                status: string;
                meta: null | any;
                templateName: null | string;
                createdAt: string;
                updatedAt: string;
                firstScreen: string | null;
            }
        ]
    }

    try {
        const response = await axios.post<FlowsOfChannel>(`flow/getAll`, {
            channelId,
            limit: 1000,
            page: 0,
        });
        const filteredAndMappedWhatsappFlow = response.data?.whatsappFlow
        ?.filter(flow => ((flow.status === 'DRAFT' || flow.status === 'PUBLISHED') && flow.templateName === 'caly_appoinment'))
        ?.map(flow => ({
            id: flow.id,
            name: flow.name,
            version: flow.version,
            status: flow.status,
            meta: flow.meta,
            templateName: flow.templateName,
            createdAt: flow.createdAt,
            updatedAt: flow.updatedAt,
            firstScreen: flow.firstScreen,
        }));

       return filteredAndMappedWhatsappFlow;
    } catch (error) {
        console.error(error);
        return [];
    }
}