import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import styles from './conversationFile.module.scss';

interface ConversationFileProps {
    fileName: string;
    fileSize: string;
    url: string;
};

export default function ConversationFile({ fileName, fileSize, url }: ConversationFileProps) {
    return (
        <div className={styles.filePreview}>
            <div className={styles.thumbnail}>
                <svg width="30" height="30" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.3691 9.00594L17.2441 2.88094C17.1627 2.79971 17.0662 2.7353 16.96 2.69138C16.8538 2.64747 16.7399 2.62491 16.625 2.625H6.125C5.66087 2.625 5.21575 2.80937 4.88756 3.13756C4.55937 3.46575 4.375 3.91087 4.375 4.375V23.625C4.375 24.0891 4.55937 24.5342 4.88756 24.8624C5.21575 25.1906 5.66087 25.375 6.125 25.375H21.875C22.3391 25.375 22.7842 25.1906 23.1124 24.8624C23.4406 24.5342 23.625 24.0891 23.625 23.625V9.625C23.6251 9.51006 23.6025 9.39623 23.5586 9.29001C23.5147 9.18379 23.4503 9.08726 23.3691 9.00594ZM17.5 5.61203L20.638 8.75H17.5V5.61203ZM21.875 23.625H6.125V4.375H15.75V9.625C15.75 9.85706 15.8422 10.0796 16.0063 10.2437C16.1704 10.4078 16.3929 10.5 16.625 10.5H21.875V23.625Z" fill="#666E7D" />
                </svg>
                <span className={styles.extension}>
                    {fileName?.split('.')[fileName.split('.').length - 1]}
                </span>
            </div>
            <div className={styles.data}>
                <span className={styles.caption}>{fileName}</span>
                <span className={styles.fileSize}>{fileSize}</span>
            </div>
            <a
                href={url}
                download={fileName}
                target='_blank'
                className={styles.download}
            >
                <FontAwesomeIcon icon={faDownload} />
            </a>
        </div>
    )
}
