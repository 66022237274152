import React, { useState, useRef, useEffect } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import 'jsoneditor-react/es/editor.min.css'; // Import the CSS file for jsoneditor
import style from './jsonEditor.module.css';


export default function JSONEditorComponent({setData, initialValue, setError}) {
  const [jsonData, setJsonData] = useState({}); 
  const editorRef = useRef(null);

  const saveJsonData = (value) => { 
     setData(value);
  }

  useEffect(() => {
    setData(initialValue);
  }, [initialValue])

  const handleEditorChange = (newValue) => {
    setJsonData(newValue);
    saveJsonData(newValue);
  };

  const handleValidationError = (errors) => {
    setError(errors.length > 0);
  };
  

  return (
    <div>
      <Editor
        key={JSON.stringify(initialValue)} 
        ref={editorRef}
        value={initialValue !== null ? initialValue : jsonData}
        mode={Editor.modes.code}
        onChange={handleEditorChange} 
        htmlElementProps={{ className: style.jsonStyle }}
        onValidationError={handleValidationError} 
      />
    </div>
  );
}
