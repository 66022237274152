import axios from '../utils/axios';
import { toast, sweetalert } from '../common/alert';
import responseFlow from '../components/WhatsappFlow/FlowResponse';

export const getAll = (businessUid: string,cataloguePayload:any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(`digital/${businessUid}/getAll`,cataloguePayload).then(res => {
            resolve(res);
        }).catch((err: any) => {    
            console.log("Something went wrong");
        })
    });
};
export const getAllCatalogue = async(businessUid: string,cataloguePayload:any) => {
    try {
       const response =   await axios.post(`digital/${businessUid}/getAll`,cataloguePayload);
       return response.data;
    }
    catch (error) {
        console.error('Error:', error);
    }
};


export const createLog = async (businessUid: string, payload: any): Promise<any> => {
    try {
        const res = await axios.post(`digital/${businessUid}/create`, payload);
        toast('success', 'Created successfully');
        return res.data;
    } catch (err:any) {
        toast('error', err.response.data.message);
        throw err;
    }
};

export const updateLog = (digitalGoodUid: string, payload: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.patch(`digital/${digitalGoodUid}/update`, payload).then(res => {
            resolve(res);
            toast('success', "Updated Successfully")
        }).catch((err: any) => {
            toast("error", err.response.data.message)
            console.log("Something went wrong")
        })
    });
};

export const deleteLog = (digitalGoodUid: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        sweetalert(
            'warning', 
            'Are you sure you want to delete?', 
            () => {
        axios.delete(`digital/${digitalGoodUid}/delete`).then(res => {
            toast('success', 'Deleted successfully');
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response.data.message);
        })
    },
    () => {
      
    }
    );
    });
};

export const metaGetAll = (channelUid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(`meta/getCatalogue?uid=${channelUid}`).then(res => {
            resolve(res);
        }).catch((err: any) => {
            console.log("Something went wrong");
        })
    });
};
export const getCollections = (channelUid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(`meta/${channelUid}/getCatalogueCollections`).then(res => {
            resolve(res);
        }).catch((err: any) => {
            console.log("Something went wrong");
        })
    });
};
// export const metaProduct = (channelUid: string): Promise<any> => {
//     return new Promise((resolve, reject) => {
//         axios.get(`meta/getCatalogueProdects?uid=${channelUid}`).then(res => {
//             resolve(res);
//         }).catch((err: any) => {
//             console.log("Something went wrong");
//         })
//     });
// };

export const metaProduct = async (productPayload:any) => {
    try {
        const res = await axios.post(`meta/getCatalogueProdects`,productPayload);
        return res;
    } catch (err) {
        console.log("Something went wrong");
        throw err;
    }
};
export const metaSync = (channelUid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`meta/syncCatalogue?uid=${channelUid}`).then(res => {
            resolve(res);
        }).catch((err: any) => {
            console.log("Something went wrong");
        })
    });
};

export const connectFB = (channelId: string, code: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`meta/save/${channelId}/accessToken?code=${code}`).then(res => {
            resolve(res);
        }).catch((err: any) => {
            console.log("Something went wrong");
        })
    });
};

export const getCatalogueCollections = async (channelUid: string) => {
    try {
        const res = await axios.get(`meta/${channelUid}/getCatalogueCollections`);
        return res;
    } catch (err) {
        console.log("Something went wrong");
        throw err;
    }
}