interface SelectBtnOption {
    value: string;
    label: string;
  }
interface SelectIndustry {
  value: string;
  label: string;
}
export const employList: SelectBtnOption[] = [
    { value: "1-10", label: "1-10" },
    { value: "11-20", label: "11-20" },
    { value: "21-30", label:"21-30" },
    { value: "31-40", label: "31-40" },
    { value: "41-50", label: "41-50" },
    { value: "61-60", label: "61-60" },
    { value: "61-70", label: "61-70" },
    { value: "71-80", label: "71-80" },
    { value: "81-90", label:"81-90" },
    { value: "91-100", label: "91-100" },
    { value: "101-150", label: "101-150" },
    { value: "151-200", label: "151-200" }
  ];

  export const industryList: SelectIndustry[] = [
    {value: "Arts & Crafts", label: "Arts & Crafts"},
    {value:'Advertising, Media & Marketing Services',label:'Advertising, Media & Marketing Services'},
    {value:'Automobile & Auto Parts',label:'Automobile & Auto Parts'},
    {value:'BabyChildrenProducts',label:'Baby & Children Products'},
    {value:'BeautyPersonalCare',label:'Beauty & Personal Care'},
    {value:'BusinessEnterprise',label:'Business & Enterprise'},
    {value:'E-commerceMarketplace',label:'E-commerce Marketplace'},
    {value:'EducationTraining',label:'Education & Training'},
    {value:'ElectronicsAppliances',label:'Electronics & Appliances'},
    {value:'Entertainment',label:'Entertainment'},
    {value:'EventPlanningManagement',label:'Event Planning & Management'},
    {value:'FashionAccessories',label:'Fashion Accessories'},
    {value:'FoodBeverages',label:'Food & Beverages'},
    {value:'GiftingItems',label:'Gifting Items'},
    {value:'GroceriesConvenience',label:'Groceries & Convenience'},
    {value:'HerbalAyurvedicProducts',label:'Herbal & Ayurvedic Products'},
    {value:'HomeOfficeFurnishing',label:'Home & Office Furnishing'},
    {value:'HotelsClubsRestaurantsHospitality',label:'Hotels, Clubs, Restaurants & Hospitality'},
    {value:'HRRecruitmentServices',label:'HR & Recruitment Services'},
    {value:'InformationTechnology',label:'Information Technology'},
    {value:'InsuranceFinanceBanking',label:'Insurance, Finance & Banking'},
    {value:'ManufacturingIndustrial Products',label:'Manufacturing & Industrial Products'},
    {value:'MedicalHealthcare',label:'Medical & Healthcare'},
    {value:'NGONon-ProfitOrganisation',label:'NGO & Non-Profit Organisation'},
    {value:'PropertyRealEstate',label:'Property & Real Estate'},
    {value:'ShoppingRetail',label:'Shopping & Retail'},
    {value:'SportsFitness',label:'Sports & Fitness'},
    {value:'TravelTourism',label:'Travel & Tourism'},
    {value:'Others',label:'Others'},
    ]