export const currencies = [
    { "value": "Afghanistan-Afghani", "label": "Afghanistan-Afghani" },
    { "value": "Albania-Lek", "label": "Albania-Lek" },
    { "value": "Algeria-Dinar", "label": "Algeria-Dinar" },
    { "value": "American Samoa-Dollar", "label": "American Samoa-Dollar" },
    { "value": "Andorra-Euro", "label": "Andorra-Euro" },
    { "value": "Angola-Kwanza", "label": "Angola-Kwanza" },
    { "value": "Anguilla-Dollar", "label": "Anguilla-Dollar" },
    { "value": "Antigua and Barbuda-Dollar", "label": "Antigua and Barbuda-Dollar" },
    { "value": "Argentina-Peso", "label": "Argentina-Peso" },
    { "value": "Armenia-Dram", "label": "Armenia-Dram" },
    { "value": "Aruba-Guilder", "label": "Aruba-Guilder" },
    { "value": "Australia-Dollar", "label": "Australia-Dollar" },
    { "value": "Austria-Euro", "label": "Austria-Euro" },
    { "value": "Azerbaijan-Manat", "label": "Azerbaijan-Manat" },
    { "value": "Bahamas-Dollar", "label": "Bahamas-Dollar" },
    { "value": "Bahrain-Dinar", "label": "Bahrain-Dinar" },
    { "value": "Bangladesh-Taka", "label": "Bangladesh-Taka" },
    { "value": "Barbados-Dollar", "label": "Barbados-Dollar" },
    { "value": "Belarus-Ruble", "label": "Belarus-Ruble" },
    { "value": "Belgium-Euro", "label": "Belgium-Euro" },
    { "value": "Belize-Dollar", "label": "Belize-Dollar" },
    { "value": "Benin-Franc", "label": "Benin-Franc" },
    { "value": "Bermuda-Dollar", "label": "Bermuda-Dollar" },
    { "value": "Bhutan-Ngultrum", "label": "Bhutan-Ngultrum" },
    { "value": "Bolivia-Boliviano", "label": "Bolivia-Boliviano" },
    { "value": "Bosnia and Herzegovina-Marka", "label": "Bosnia and Herzegovina-Marka" },
    { "value": "Botswana-Pula", "label": "Botswana-Pula" },
    { "value": "Brazil-Real", "label": "Brazil-Real" },
    { "value": "Brunei-Dollar", "label": "Brunei-Dollar" },
    { "value": "Bulgaria-Lev", "label": "Bulgaria-Lev" },
    { "value": "Burkina Faso-Franc", "label": "Burkina Faso-Franc" },
    { "value": "Burundi-Franc", "label": "Burundi-Franc" },
    { "value": "Cambodia-Riels", "label": "Cambodia-Riels" },
    { "value": "Cameroon-Franc", "label": "Cameroon-Franc" },
    { "value": "Canada-Dollar", "label": "Canada-Dollar" },
    { "value": "Cape Verde-Escudo", "label": "Cape Verde-Escudo" },
    { "value": "Cayman Islands-Dollar", "label": "Cayman Islands-Dollar" },
    { "value": "Central African Republic-Franc", "label": "Central African Republic-Franc" },
    { "value": "Chad-Franc", "label": "Chad-Franc" },
    { "value": "Chile-Peso", "label": "Chile-Peso" },
    { "value": "China-Yuan Renminbi", "label": "China-Yuan Renminbi" },
    { "value": "Colombia-Peso", "label": "Colombia-Peso" },
    { "value": "Comoros-Franc", "label": "Comoros-Franc" },
    { "value": "Republic of the Congo-Franc", "label": "Republic of the Congo-Franc" },
    { "value": "DR Congo-Franc", "label": "DR Congo-Franc" },
    { "value": "Cook Islands-Dollar", "label": "Cook Islands-Dollar" },
    { "value": "Costa Rica-Colon", "label": "Costa Rica-Colon" },
    { "value": "Ivory Coast-Franc", "label": "Ivory Coast-Franc" },
    { "value": "Croatia-Kuna", "label": "Croatia-Kuna" },
    { "value": "Cuba-Peso", "label": "Cuba-Peso" },
    { "value": "Cyprus-Euro", "label": "Cyprus-Euro" },
    { "value": "Czech Republic-Koruna", "label": "Czech Republic-Koruna" },
    { "value": "Denmark-Krone", "label": "Denmark-Krone" },
    { "value": "Djibouti-Franc", "label": "Djibouti-Franc" },
    { "value": "Dominica-Dollar", "label": "Dominica-Dollar" },
    { "value": "Dominican Republic-Peso", "label": "Dominican Republic-Peso" },
    { "value": "Ecuador-Dollar", "label": "Ecuador-Dollar" },
    { "value": "Egypt-Pound", "label": "Egypt-Pound" },
    { "value": "El Salvador-Dollar", "label": "El Salvador-Dollar" },
    { "value": "Equatorial Guinea-Franc", "label": "Equatorial Guinea-Franc" },
    { "value": "Eritrea-Nakfa", "label": "Eritrea-Nakfa" },
    { "value": "Estonia-Euro", "label": "Estonia-Euro" },
    { "value": "Ethiopia-Birr", "label": "Ethiopia-Birr" },
    { "value": "Falkland Islands-Pound", "label": "Falkland Islands-Pound" },
    { "value": "Faroe Islands-Krone", "label": "Faroe Islands-Krone" },
    { "value": "Fiji-Dollar", "label": "Fiji-Dollar" },
    { "value": "Finland-Euro", "label": "Finland-Euro" },
    { "value": "France-Euro", "label": "France-Euro" },
    { "value": "French Guiana-undefined", "label": "French Guiana-undefined" },
    { "value": "French Polynesia-Franc", "label": "French Polynesia-Franc" },
    { "value": "Gabon-Franc", "label": "Gabon-Franc" },
    { "value": "Gambia-Dalasi", "label": "Gambia-Dalasi" },
    { "value": "Georgia-Lari", "label": "Georgia-Lari" },
    { "value": "Germany-Euro", "label": "Germany-Euro" },
    { "value": "Ghana-Cedi", "label": "Ghana-Cedi" },
    { "value": "Gibraltar-Pound", "label": "Gibraltar-Pound" },
    { "value": "Greece-Euro", "label": "Greece-Euro" },
    { "value": "Greenland-Krone", "label": "Greenland-Krone" },
    { "value": "Grenada-Dollar", "label": "Grenada-Dollar" },
    { "value": "Guadeloupe-undefined", "label": "Guadeloupe-undefined" },
    { "value": "Guam-Dollar", "label": "Guam-Dollar" },
    { "value": "Guatemala-Quetzal", "label": "Guatemala-Quetzal" },
    { "value": "Guinea-Franc", "label": "Guinea-Franc" },
    { "value": "Guinea-Bissau-Franc", "label": "Guinea-Bissau-Franc" },
    { "value": "Guyana-Dollar", "label": "Guyana-Dollar" },
    { "value": "Haiti-Gourde", "label": "Haiti-Gourde" },
    { "value": "Vatican City-Euro", "label": "Vatican City-Euro" },
    { "value": "Honduras-Lempira", "label": "Honduras-Lempira" },
    { "value": "Hong Kong-Dollar", "label": "Hong Kong-Dollar" },
    { "value": "Hungary-Forint", "label": "Hungary-Forint" },
    { "value": "Iceland-Krona", "label": "Iceland-Krona" },
    { "value": "India-Rupee", "label": "India-Rupee" },
    { "value": "Indonesia-Rupiah", "label": "Indonesia-Rupiah" },
    { "value": "Iran-Rial", "label": "Iran-Rial" },
    { "value": "Iraq-Dinar", "label": "Iraq-Dinar" },
    { "value": "Ireland-Euro", "label": "Ireland-Euro" },
    { "value": "Israel-Shekel", "label": "Israel-Shekel" },
    { "value": "Italy-Euro", "label": "Italy-Euro" },
    { "value": "Jamaica-Dollar", "label": "Jamaica-Dollar" },
    { "value": "Japan-Yen", "label": "Japan-Yen" },
    { "value": "Jordan-Dinar", "label": "Jordan-Dinar" },
    { "value": "Kazakhstan-Tenge", "label": "Kazakhstan-Tenge" },
    { "value": "Kenya-Shilling", "label": "Kenya-Shilling" },
    { "value": "Kiribati-Dollar", "label": "Kiribati-Dollar" },
    { "value": "North Korea-Won", "label": "North Korea-Won" },
    { "value": "South Korea-Won", "label": "South Korea-Won" },
    { "value": "Kuwait-Dinar", "label": "Kuwait-Dinar" },
    { "value": "Kyrgyzstan-Som", "label": "Kyrgyzstan-Som" },
    { "value": "Laos-Kip", "label": "Laos-Kip" },
    { "value": "Latvia-Euro", "label": "Latvia-Euro" }
]
