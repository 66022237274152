import React, { useEffect, useRef, useState } from 'react';

interface NewLineInputProps {
    className?: string;
    placeholder?: string;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

const NewLineInput: React.FC<NewLineInputProps> = ({ className, placeholder, value, setValue }) => {
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const [cursorPosition, setCursorPosition] = useState<number>(0);

    const updateCursorPosition = () => {
        const element = inputRef.current;
        if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
            setCursorPosition(element.selectionStart ?? cursorPosition);
        }
    };

    const handleKeyPress = (e: any) => {
        if ((e.shiftKey || e.altKey) && e.key === 'Enter') {
            e.preventDefault();

            const newPosition = cursorPosition + 1;
            setValue((prevText) => {
                return prevText.slice(0, cursorPosition) + '\n' + prevText.slice(cursorPosition);
            });
            setCursorPosition(newPosition);
        }
    };


    useEffect(() => {
        const element = inputRef.current;
        if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
            element.focus();
            element.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, [value, cursorPosition]);

    const lineCount = value.split("\n").length;

    return lineCount <= 1 ? (
        <input
            ref={inputRef as React.RefObject<HTMLInputElement>}
            className={`form-control ${className}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => { setValue(e.target.value); updateCursorPosition(); }}
            onKeyUp={handleKeyPress}
        />
    ) : (
        <textarea
            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            style={{ resize: "none" }}
            rows={lineCount}
            className={`form-control ${className}`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => { setValue(e.target.value); updateCursorPosition(); }}
            onKeyUp={handleKeyPress}
        />
    );
};


export default NewLineInput;
