import React, { useEffect, useState, useRef,useCallback } from "react";
import { Button, Col, Row, Form as FormBootstrap } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RightSidebar from "../../common/rightSidebar";
import {
  Formik,
  Field,
  ErrorMessage,
  Form,
  FieldProps,
  FormikProps,
  FormikHelpers,
} from "formik";
import { Label } from "reactstrap";
import * as Yup from "yup";
import {
  createCompany,
  editCompany,
  updateCompany,
  deleteCompany,
  getAll,
} from "../../services/companyService";
import { toast } from "../../common/alert";
import { useSelector } from "react-redux";
import { sweetalert } from "../../common/alert";
import SearchInput from "../../common/searchComponent";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import Loader from "../../shade/Loaders/smallLoader";
import BizTable  from "../../common/BizTable";
import Select from 'react-select';
import { getAllTeam, getTeam } from "../../services/contactMemberService"


type Props = {};

interface FormValues {
  team: any;
  uid: string;
  name: string;
  description: string;
  webSite: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Company name is required"),
  webSite: Yup.string().nullable().required("Website is required").matches(
    /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
    'Please enter a valid website, domain, or email'
  ),
});

const Companies = (props: Props) => {
  const initialCompanyData = {
    name: "",
    description: "",
    webSite: "",
    team:null,
  };
  const [companyTitle, setCompanyTitle] = useState<string>();
  const [isCompanyAdd, isSetCompanyAdd] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companyData, setCompanyData] = useState(initialCompanyData);
  const [companyCount, setCompanyCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const [loading, setLoading] = useState(true);
  const [teamList,setTeamList] = useState([]);
  const currentPage = useRef(0);
  const paginationLimit = useRef(10);

  const navigate = useNavigate();
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

  useEffect(() => {  
    if(roleRules) {
      const isInvalidAccess = roleRules.canViewContacts === true && roleRules.canManageContactsAndDeleteContactList === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);

  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault(); // Prevent space key from having its default behavior
    }
  };


  const getCompany = async () => {
    // setLoading(true);
    const current = currentPage.current;
    const limit = paginationLimit.current;
    const page = current > 0 ? current - 1 : 0; 

    const listPayload = {
      businessUid: businessId,
      page: searchTerm ? 0 : page,
      limit: limit,
      search: searchTerm
    };
    const response = await getAll(listPayload);
    const {data,count} = response;
   const countData =   searchTerm ? data?.length : count;
    setCompanyCount(countData);
    if (response?.data) {
      setLoading(false);
      setCompanyList(response.data);
    }
  };

  
  // useEffect(() => {
  //   if (businessId || searchTerm) {
  //     getCompany();
  //   }
  // }, [businessId,searchTerm]);
  const getTeams =async()=>{
    const listPayload = {
      businessUid: businessId,
      page:  0,
      limit: 1000
    };
    const response = await getAllTeam(listPayload);
    if(response){
      const data = response?.data;
      setTeamList(data);
    }
  }
  useEffect(()=>{
    if(businessId){
      getTeams()
    }
  },[businessId])

  const totalPages = Math.ceil(companyCount / paginationLimit.current);
  
  // const pageChange = (page: number, sizePerPage: number) => {
  //   if (sizePerPage !== paginationLimit.current) {
  //     paginationLimit.current = sizePerPage;
  //     currentPage.current = 0; // Typically, when page size changes, you reset to page 1
  //   } else if (page !== currentPage.current) {
  //     currentPage.current = page;
  //   }
  //   getCompany();
  // };

  // const options = {
  //   headerAlign: "center",
  //   noDataText: "No records found",
  //   sortIndicator: true,
  //   hidePageListOnlyOnePage: true,
  //   clearSearch: false,
  //   alwaysShowAllBtns: true,
  //   withFirstAndLast: true,
  //   sizePerPage: paginationLimit.current,
  //   onPageChange: pageChange,
  //   pagination: false,
  //   sizePerPageList: [10, 25, 50, 100, { text: "All", value: companyCount }],
  //   paginationPosition: "bottom",
  // };

  const handleClickEdit = async (cell: any, rowData: any) => {
    const { uid } = rowData;
    const response = await editCompany(uid);
    if (response) {
      isSetCompanyAdd(true);
      setCompanyTitle("Edit Company");
      setCompanyData(response);
    }
  };

  const dropCompany = async (cell: any, rowData: any) => {
    sweetalert(
      "warning",
      " Are you sure you want to delete this company?",
      async () => {
        if (rowData?.uid) {
          const response = await deleteCompany(rowData?.uid);
          if (response) {
            // getCompany();
            fetchData(0,20,'',0)
            toast("success", "Company deleted successfully");
          }
        }
      },
      () => {}
    );
  };
    
  const handleClick =(uid:string)=>{
    navigate(`/companies/${uid}`)
  }

  const importCompany = ()=>{
    navigate(`import`)
  }

  const contactDataFormat = (cell: any, rowData: any) => {
    if(cell && cell > 0){
      const {uid} = rowData
      return (
        <div onClick={()=>handleClick(uid)} style={{cursor:'pointer',color:'#1264bd'}}>
          {cell} {/* Displaying the length or other formatted data */}
        </div>
      );
    }
    else{
      return (
        <div>
          {cell}
        </div>
      );
    }
  }
  const teamData = (cell:any,rowData:any)=>{
    console.log('Cell',cell,rowData)
    if(cell){
      const {name} = cell;
      return (
        <div >
          {name} {/* Displaying the length or other formatted data */}
        </div>
      );
    }
    else{
      return(<>-</>)
    }
  }


  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  };

  const exportToExcel = (data: any[][], filename: string) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };
  
  const exportExcel =async () => {
    const headers = ["Name","Website","Team","Description",];
  
    const listPayload = {
      businessUid: businessId,
      page: 0,
      limit: companyCount,
      search: searchTerm
    };
    const response = await getAll(listPayload);
    if(response){
      const dataToExport = response?.data?.map((company: any) => [
        company.name,
        company.webSite,
        company.team?.name,
        company.description
      ]);
      const data = [headers, ...dataToExport];
      exportToExcel(data, 'company_data');
    }
    // Ensure that companyList is typed properly somewhere in your component or context
  
  };

  const importExcel = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as string[][];
  
          const companies: any[] = [];
          for (let i = 1; i < json.length; i++) {
            const name = json[i][0] as string;
            const description = json[i][1] as string;
            const webSite = json[i][2] as string;
  
            try {
              const validatedCompany = await validationSchema.validate({
                name,
                description,
                webSite
              }, { abortEarly: false });
  
              companies.push(validatedCompany as any);
            } catch (validationError) {
              reject(`Validation error on row ${i + 1}: ${validationError}`);
              return;
            }
          }
  
          resolve(companies);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  };
  
  
  
  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    // const current = currentPage;
    // const limit = paginationLimit.current;
    // const page = currentPage > 0 ? currentPage - 1 : 0; 
    // console.log('current',currentPage,page)
  
    const listPayload = {
      businessUid: businessId,
      page: searchTerm ? 0 : currentPage,
      limit: pageSize,
      search: searchTerm
    };

    const response = await getAll(listPayload);
    const {data,count} = response;
    const countData =   searchTerm ? data?.length : count;
    setCompanyCount(countData);
    if (response?.data) {
      setLoading(false);
      setCompanyList(response.data);
      return {data:response.data,totalPages:totalPages}
    }
    else{
      return {data:[],totalPages:0}
    }
  }, [businessId, paginationLimit, searchTerm, totalPages]);
 
  const handleSubmit = async (
    values: any,
    { setSubmitting }: any
  ) => {
    let response;
    const isUpdate = !!values.uid; // Check if there's an ID indicating an update

    const payload = {
      businessUid: businessId,
      ...values,
    };
    const updatePayload = {
      ...companyData,
      ...values,
      businessUid: businessId, // Fallback to businessId if not in values
    };

    try {
      if (isUpdate) {
        response = await updateCompany(values?.uid, updatePayload);
      } else {
        response = await createCompany(payload);
      }

      if (response) {
        // getCompany();
        fetchData(0,20,'',0)
        toast(
          "success",
          `Company ${isUpdate ? "updated" : "added"} successfully`
        );
        setSubmitting(false);
        // Assuming `isSetCompanyAdd` is a state setter function to control form visibility or reset
        isSetCompanyAdd(false); // You might need to adjust this based on actual use
      }
    } catch (error) {
      console.error(error);
      toast("error", `Failed to ${isUpdate ? "update" : "add"} company`);
      setSubmitting(false);
      // Optionally, handle error specifics (e.g., setting form field errors)
    }
  };

 const  columns = [
  {
    Header: (
      <input
        type="checkbox"
        hidden={true}
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
      />
    ),
    accessor: "checkbox",
    // Cell: this.checkboxCellRenderer,
    sticky: "left",
  },
    {
      Header: "Company Name",
      accessor: "name",
      sticky: "left",
    },
    // {
    //   Header: "Mobile Number",
    //   accessor: "mobileNumber",
    //   // Cell: ({ cell, row }: any) => this.mobileData(cell.value, row.original),
    // },
    {
      Header: "Website URL",
      accessor: "webSite",
      // Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
      grid: "third-column",
    },
    {
      Header: "Team",
      accessor: "team",
      Cell: ({ cell, row }: any) => teamData(cell.value, row.original),
    },
    {
      Header: "Contacts",
      accessor: "contactCount",
      Cell: ({ cell, row }: any) => contactDataFormat(cell.value, row.original),
    },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ cell, row }: any) => (
      <div className={`${isInvalidAccess ? "notAllowed" : ""}`} >
        <svg
          width="16"
          height="16"
          onClick={() => handleClickEdit(cell.value, row.original)}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${isInvalidAccess ? "disabledState" : ""}`}
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          width="16"
          height="16"
          className={` ms-4 ${isInvalidAccess ? "disabledState" : ""}`}
          onClick={() => dropCompany(cell.value, row.original)}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H3.33333H14"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66602 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33398 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      ),
      grid: "col-md-1",
    },
  ] as any;

  return (
    // <div>
    //  {loading ?
    //   <>
    //   <Loader />
    //   </>
    //   :
      <> 
      <h5 className="pt-3">All Companies</h5>
      <Row className="d-flex align-items-center">
        <Col md="4" className="p-0 mt-3">
          <SearchInput component="company" onSearchChange={onSearchChange} 
          placeHolder="Search Company"
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Row>
            {/* <Col className="pe-0">
              <Button variant="" type="button"  className="clearSelection " 
              onClick={importCompany}>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-1"
                >
                  <path
                    d="M11.25 13L3.75 13M7.5 3L7.5 10.5M7.5 10.5L5.3125 8.3125M7.5 10.5L9.6875 8.3125"
                    stroke="#423B3B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Import
              </Button>
            </Col> */}
            <Col className="pe-0">
              <Button
                variant=""
                type="button"
                className="clearSelection "
                  onClick={exportExcel}
              >
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-1"
                >
                  <path
                    d="M3.75 3H11.25M7.5 13V5.5M7.5 5.5L9.6875 7.6875M7.5 5.5L5.3125 7.6875"
                    stroke="#423B3B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Export
              </Button>
            </Col>
            <Col className={`${isInvalidAccess ? "notAllowed" : ""}`} >
              <Button
                className="sendButton"
                onClick={() => {
                  isSetCompanyAdd(true);
                  setCompanyTitle("Add Company");
                  setCompanyData(initialCompanyData);
                }}
                disabled={isInvalidAccess}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add Company
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col>
        <div>
                    {companyCount > 0 ? 'Companies' : 'Company'} :{" "}
                    <span style={{ color: "#007EC3" }}>
                        {companyCount}
                    </span>
                  </div>
        </Col>
      </Row>
      {isCompanyAdd && (
        <RightSidebar
          title={companyTitle}
          handleCancel={() => isSetCompanyAdd(false)}
          width="30vw"
        >
          <Formik
            initialValues={companyData as any}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="centered-form-temp">
                <FormBootstrap.Group className="pt-2">
                  <Label>
                    Company Name <span className="required"></span>
                  </Label>
                  <Field
                    onKeyDown={handleKeyDown}
                    type="text"
                    className="form-control"
                    name="name"
                    maxLength={30}
                    placeholder="Enter your Company name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger pt-1"
                  />
                </FormBootstrap.Group>
                <FormBootstrap.Group className="pt-2">
                  <Label>
                    Website URL <span className="required"></span>
                  </Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="webSite"
                    onKeyDown={handleKeyDown}
                    maxLength={40}
                    placeholder="Enter website URL"
                  />
                  <ErrorMessage
                    name="webSite"
                    component="div"
                    className="text-danger pt-1"
                  />
                </FormBootstrap.Group>
               
                <FormBootstrap.Group className="pt-2">
                  <Label>Description </Label>
                  <Field name="description">
                    {({ field }: FieldProps & { form: FormikProps<any> }) => (
                      <textarea
                        // ref={textareaRef}
                        {...field}
                        id="description"
                        onChange={(event: any) => {
                          const { name, value } = event.target;
                          setFieldValue(name, value);
                          // adjustTextareaHeight();
                        }}
                        onKeyDown={handleKeyDown}
                        className="form-control p-2 addressTxt"
                        placeholder="Write something that describes this company"
                        rows={3}
                      />
                    )}
                  </Field>
                </FormBootstrap.Group>
                <FormBootstrap.Group className="pt-2">
                  <Label>Team</Label>
                        <Select
                          name="team"
                          options={
                            teamList &&
                            (teamList?.map((team: any) => ({
                              value: team,
                              label: team.name,
                            })) as [])
                          }
                          isClearable={true}
                          // className={`industry ${values.role.type === "MEMBER" ? 'disabled-data':''}`}
                         value={values?.team ? {label:values?.team.name,value:values?.team} : null}
                          onChange={(selectedOption: any) => {
                            setFieldValue("team", selectedOption ? selectedOption.value : null);
                          }}
                       placeholder='Select Team'
                        />
                    </FormBootstrap.Group>
                <FormBootstrap.Group className="pt-2">
                  <Label>Notes </Label>
                  <Field name="note">
                    {({ field }: FieldProps & { form: FormikProps<any> }) => (
                      <textarea
                        // ref={textareaRef}
                        {...field}
                        id="note"
                        onChange={(event: any) => {
                          const { name, value } = event.target;
                          setFieldValue(name, value);
                          // adjustTextareaHeight();
                        }}
                        className="form-control p-2 addressTxt"
                        placeholder="Add note for reference"
                        rows={2}
                      />
                    )}
                  </Field>
                </FormBootstrap.Group>
                <FormBootstrap.Group className="addCancelBtn">
                  <Button
                    variant=""
                    type="button"
                    className="cancelButton me-2"
                    onClick={() => isSetCompanyAdd(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant=""
                    type="submit"
                    className="sendButton"
                    disabled={isSubmitting}
                  >
                    {companyTitle === 'Add Company' ? 'Add Company':'Update'}
                  </Button>
                </FormBootstrap.Group>
              </Form>
            )}
          </Formik>
        </RightSidebar>
      )}
      {/* <BootstrapTable
        data={companyList}
        // selectRow={selectRowProp}
        // search
        pagination
        version="4"
        striped
        hover
        options={options as any}
        remote
        fetchInfo={{
            dataTotalSize: paginationLimit.current * totalPages,
        }}
        containerStyle={{ textAlign: "left" }}
      >
        <TableHeaderColumn dataField="id" isKey hidden></TableHeaderColumn>
        <TableHeaderColumn dataField="name" dataAlign="left" width="30%">
          Company Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="webSite"
          dataAlign="left"
          width="30%"
        >
          Website URL
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="contactCount"
          dataFormat={contactDataFormat}
          dataAlign="center"
          width="20%"
        >
         <div className="d-flex justify-content-center">No:&nbsp;<div style={{textTransform:'lowercase'}}>of</div>&nbsp;Contacts</div>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="edit"
          dataFormat={renderEditColumn}
          dataAlign="end"
          width="70"
        >
          Actions
        </TableHeaderColumn>
      </BootstrapTable> */}
      <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={companyCount}
              refetchData={companyList}
              tableName='COMPANIES'
            /> 
      </>  
      // }
    // </div>
  );
};

export default Companies;
