import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../hooks';
import { jumpToIcon } from '../../../../icons';
import Node from '../node';
import * as flowServices from '../../../../services/flows';


type Props = any;

function AppointmentFlow(props: Props) {
    const channelUid = useAppSelector(state => state.meta.channelUid);
    const flowsQuery = useQuery({
        queryKey: ['appointment-flows'],
        queryFn: () => flowServices.getAppointmentFlowsOfChannel(channelUid || ''),
        enabled: false
    })
    useEffect(() => {
        if (channelUid && !flowsQuery.isFetchedAfterMount) {
            flowsQuery.refetch();
        }
    }, [channelUid])

    let content = (
        <div className='d-flex align-item-center'>
        <img src={jumpToIcon} width={14} />
        <div className='ml-2 mt-1'>
            Appointment Flow
        </div>
    </div>
    );

    if (flowsQuery.isFetched && !flowsQuery.isError) {
        const myFlow = flowsQuery?.data?.find((f: any) => f?.id === props.data.flowData.flowId);
        if (myFlow) {
            content = (
                <div className='d-flex align-item-center'>
                    <img src={jumpToIcon} width={14} />
                    <div className='ml-2 mt-1'>
                        {myFlow.name}
                    </div>
                </div>
            )
        } 
    }
    return (
        <Node {...props} label='Appointment Flow' content={content} />
    );
}

export default AppointmentFlow;