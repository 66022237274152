import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { BsFileEarmarkText } from 'react-icons/bs';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import BodyInput from '../bodyInput';
import VariableInputLabel from '../variableInputLabel';
import { SelectVariable } from '../setVariable';
import EditorCaption from '../editorCaption';
import AdvancedSettings from './advancedSettings';

interface FormData {
    text: string;
    errorMessage: string;
    endFlow: boolean;
    attempt: number;
    storageVariable: { name: string, type: 'TEXT' }
};

const AskFileEditor: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [formData, setFormData] = useState<FormData>({
        text: '',
        attempt: 1,
        endFlow: true,
        errorMessage: '',
        storageVariable: {
            name: '',
            type: 'TEXT'
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'ask_type/ask_file',
        data: formData
    }, props);
    useEffect(() => {
        init((data: any) => {
            let newData = { ...data };
            if (!newData.storageVariable) {
                newData.storageVariable = {
                    name: '',
                    type: 'TEXT'
                };
            }
            setFormData(newData);
        });
    }, []);

    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        text: yup.string().required('Question Text is a required field').test('is-double-space', 'Question Text is a required field', value => value !== '  '),
        errorMessage: yup.string().notRequired(),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        storageVariable: storageVariableSchema
    });


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        {!props.create ? (
                            <EditorCaption
                                onHide={props.onClose}
                                caption='Ask File'
                                icon={<BsFileEarmarkText />}
                            />
                        ) : null}
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='text'
                                    value={values.text}
                                    isInvalid={(touched.text && errors.text) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.text && !errors.text}
                                    error={errors.text}
                                    label={<>Question Text<span className="required"></span></>}
                                    placeholder='Hey, How can you help'
                                    limit={4096}
                                />
                                {(touched.text && errors.text) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.text}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <AdvancedSettings />
                            </Form.Group>
                            <VariableInputLabel
                                error={(
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.storageVariable?.name}
                                    </Form.Control.Feedback>
                                )}>
                                <SelectVariable
                                    placeholder="Create or Select variable"
                                    name="storageVariable"
                                    value={values.storageVariable.name}
                                    onChange={handleChange}
                                />
                            </VariableInputLabel>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AskFileEditor;