import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button,FormControl } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Label } from 'reactstrap';
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import { SelectVariable } from './setVariable';
import AddVariable from './addVariable';
import EditorCaption from './editorCaption';
import { replaceIcon } from '../../../icons';

interface FormData {
    source: string;
    match: string;
    replaceWith: string;
    storageVariable: { name: string, type: 'TEXT' }
};

const ReplaceText: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [formData, setFormData] = useState<FormData>({
        source: '',
        match: '',
        replaceWith: '',
        storageVariable: {
            name: '',
            type: 'TEXT'
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'replaceText',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    
    const storageVariableSchema = yup.object().shape({
        name: yup.string().required('Variable is required'),
    });
    const schema = yup.object().shape({
        source: yup.string().required('Input is a required'),
        match: yup.string().required('Match Text is required'),
        replaceWith: yup.string(),
        storageVariable: storageVariableSchema
    });


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Replace Text' icon={<img style={{ width: 16 }} alt='' src={replaceIcon} />} />
                        <Offcanvas.Body>
                        <Form.Group>
                                <Label>Input<span className='required'></span></Label>
                                <FormControl
                                    name='source'
                                    as='input'
                                    value={values.source}
                                    isInvalid={(touched?.source && errors?.source) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched?.source && !errors?.source}
                                    placeholder="Enter the source text here"
                                />
                                {(touched?.source && errors?.source) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors?.source}
                                    </div>
                                ) : null}
                                <AddVariable static product fieldName={`source`} fieldValue={values?.source} handleChange={(value: string) => setFieldValue('source', value)} />
                            </Form.Group>
                           
                           
                            <Form.Group>
                                <Label>Match Text<span className='required'></span></Label>
                                <FormControl
                                    name='match'
                                    as='input'
                                    value={values.match}
                                    isInvalid={(touched?.match && errors?.match) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched?.match && !errors?.match}
                                    placeholder="Enter the match text here"
                                />
                               {(touched?.match && errors?.match) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors?.match}
                                    </div>
                                ) : null}
                                  <AddVariable static product fieldName={`match`} fieldValue={values?.match} handleChange={(value: string) => setFieldValue('match', value)} />
                            </Form.Group>
                            
                            <Form.Group>
                                <Label>Replace Text</Label>
                                <FormControl
                                    name='replaceWith'
                                    as='input'
                                    value={values.replaceWith}
                                    isInvalid={(touched?.replaceWith && errors?.replaceWith) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched?.replaceWith && !errors?.replaceWith}
                                    placeholder="Enter the replace text here"
                                />
                                <AddVariable static product fieldName={`replaceWith`} fieldValue={values?.replaceWith} handleChange={(value: string) => setFieldValue('replaceWith', value)} />
                            </Form.Group>
                                    
                            <Label>Select or Create variable to store response<span className='required'></span></Label>
                                <SelectVariable
                                    placeholder="Create or Select variable"
                                    name="storageVariable"
                                    value={values.storageVariable.name}
                                    onChange={handleChange}
                                    type="TEXT"
                                />
                                {(touched?.storageVariable && errors?.storageVariable) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors?.storageVariable.name}
                                    </div>
                                ) : null}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default ReplaceText;