import React from 'react';
import { Handle, Position } from 'reactflow';
import { ListGroup } from 'react-bootstrap';
import Node from './node';
import styles from './askType/askKeyword.module.css';
import listStyles from './sendList/listItem.module.css';
import { uuidv4 } from '../../../utils/uuid';

const SwitchCondition: React.FC<any> = props => {
    return (
        <Node {...props} label='Switch' showRight={false} content={(
            <div>
                {props.data.switchOperation.input? (
                            <div style={{ cursor: 'pointer' }}>
                                {props.data.switchOperation.input.substring(0, 20)}
                                {props.data.switchOperation.input > 20 ? '...' : null}
                            </div>
                        ) : null}
                <ListGroup as='ol' className={listStyles.listitem}>
                    {props.data.switchOperation.list.map((option: any, i: number) => {
                        return (
                            <ListGroup.Item as='li' className={[styles.item, listStyles.item].join("  ")} key={uuidv4()}>
 {option.title? (
                            <div style={{ cursor: 'pointer' }}>
                                {option.title.substring(0, 20)}
                                {option.title > 20 ? '...' : null}
                            </div>
                        ) : null}<Handle id={`switchCondition/${i + 1}`} type='source' position={Position.Right} isConnectable />
                            </ListGroup.Item>
                        );
                    })}
                    <ListGroup.Item as='li' className={[styles.item, listStyles.item].join("  ")}>
                        Default
                        <Handle id={`switchCondition/default`} type='source' position={Position.Right} isConnectable />
                    </ListGroup.Item>
                </ListGroup>
                {props.data.footerText ? (
                    <span className={styles.footer}>{props.data.footerText}</span>
                ) : null}
            </div>
        )} />
    );
}

export default SwitchCondition;