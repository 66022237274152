import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTrashCan, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faAnglesRight, faBell, faCalendar, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import styles from './followupReminder.module.scss';

type TimeInputProps = {
    value: Date;
    onChange: (date: Date) => void;
};

const TimeInput: React.FC<TimeInputProps> = ({ value, onChange }) => {
    const getInitialTimeValues = (date: Date) => {
        const hours = date?.getHours();
        return {
            hours: hours % 12 || 12,
            minutes: date?.getMinutes(),
            amPm: hours >= 12 ? 'PM' : 'AM'
        };
    };

    const initialTimeValues = getInitialTimeValues(value);
    const [hours, setHours] = useState(initialTimeValues.hours);
    const [minutes, setMinutes] = useState(initialTimeValues.minutes);
    const [amPm, setAmPm] = useState(initialTimeValues.amPm);

    const updateDateValue = (newHours: number, newMinutes: number, newAmPm: string) => {
        const updatedDate = new Date(value);
        const hours24 = newAmPm === 'PM' ? (newHours % 12) + 12 : newHours % 12;
        updatedDate.setHours(hours24, newMinutes);
        onChange(updatedDate);
    };

    const handleHoursChange = (newHours: number) => {
        if (newHours.toString().length < 3 && newHours <= 12) { // Use strict inequality
            setHours(newHours);
            updateDateValue(newHours, minutes, amPm);
        }
    };
    

    const handleMinutesChange = (newMinutes: number) => {
        if (newMinutes.toString().length < 3 && newMinutes <= 59) { // Use strict inequality
        setMinutes(newMinutes);
        updateDateValue(hours, newMinutes, amPm);
        }
    };

    const handleAmPmChange = (newAmPm: string) => {
        setAmPm(newAmPm);
        updateDateValue(hours, minutes, newAmPm);
    };

    return (
        <div className='d-flex align-items-center mb-3'>
            <div className='mr-3'>Time:</div>
            <div className={[styles.timeInput, 'form-control'].join(' ')}>
                <input
                    type="text"
                    value={hours}
                    onChange={(e) => handleHoursChange(parseInt(e.target.value) || 0)}
                    min={1}
                    max={12}
                    maxLength={2}
                />
                <span className={styles.seperator}>:</span>
                <input
                    type="text"
                    value={minutes}
                    onChange={(e) => handleMinutesChange(parseInt(e.target.value) || 0)}
                    min={0}
                    max={59}
                    maxLength={2}
                />
                <select
                    value={amPm}
                    onChange={(e) => handleAmPmChange(e.target.value)}
                >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                </select>
            </div>
        </div>
    );
};

interface FollowupReminderProps {
    conversation: any;
    setFollowupReminder: (conv: any, reminderDuration: number) => void;
}

type IDropdownState = "DROPDOWN" | "CALENDAR" | "EDIT";

const FollowupReminder: React.FC<FollowupReminderProps> = ({
    conversation,
    setFollowupReminder
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownState, setDropdownState] = useState<null | IDropdownState>(null);
    const [date, setDate] = useState<any>(null);
    useEffect(() => {
        if (conversation.reminder) {
            setDropdownState("EDIT");
        }
        else if (showDropdown && !dropdownState || !showDropdown) {
            setDropdownState("DROPDOWN");
            if (!showDropdown) setDate(null);
        }
    }, [conversation, showDropdown]);

    const setFollowupReminderHandler = () => {
        const currentTimeStamp = new Date().getTime();
        const timeStampWith5MinsAdded = currentTimeStamp + 300000;
        const timeStamp = date !== null ?  new Date(date).getTime() : timeStampWith5MinsAdded;
        // add 5 minutes (300,000 ms)
        setFollowupReminder(conversation, timeStamp);
        setDropdownState("EDIT");
      }

    let dropdown;

    switch (dropdownState) {
        case "EDIT":
            dropdown = (
                <DropdownMenu className={styles.editDropdown}>
                    <h3>Follow-up reminder</h3>
                    <div className={styles.followupItem}>
                        <FontAwesomeIcon color='#FF7342' icon={faBell} />
                        <span className={styles.timestamp}>
                            {moment(conversation.reminderTime).format('DD MMM | hh:mm a')}
                        </span>
                        <div className={styles.action}>
                            <Button
                                color='link'
                                id="EditFollowupTooltip"
                                onClick={() => setDropdownState("DROPDOWN")}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="EditFollowupTooltip"
                                style={{ zIndex: 1100 }}
                            >
                                Edit Follow-up
                            </UncontrolledTooltip>
                            <Button
                                color='link'
                                id='DeleteFollowupTooltip'
                                onClick={() => {
                                    setFollowupReminder(conversation, 0);
                                    setShowDropdown(false)
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashCan} />
                            </Button>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="DeleteFollowupTooltip"
                                style={{ zIndex: 1100 }}
                            >
                                Cancel Follow-up
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </DropdownMenu>
            )   
            break
        case "DROPDOWN":
            dropdown = (
                <DropdownMenu className={styles.dropdownMenu}>
                    <h3>Set Follow-up reminder</h3>
                    <DropdownItem
                        className={styles.dropdownMenu_item}
                        onClick={() => setFollowupReminder(conversation, moment().add(2, 'hour').toDate().getTime())}
                    >
                        <div className={styles.label}>
                            <FontAwesomeIcon className="mr-2" icon={faClock} />
                            Later today
                        </div>
                        <span className={styles.timestampPreview}>
                            {moment().add(2, 'hour').format('hh:mm A')}
                        </span>
                    </DropdownItem>
                    <DropdownItem
                        className={styles.dropdownMenu_item}
                        onClick={() => setFollowupReminder(conversation, moment().add(1, 'days').toDate().getTime())}
                    >
                        <div className={styles.label}>
                            <FontAwesomeIcon className="mr-2" icon={faChevronRight} />
                            Tomorrow
                        </div>
                        <span className={styles.timestampPreview}>
                            {moment().add(1, 'days').format('ddd, hh:mm A')}
                        </span>
                    </DropdownItem>
                    <DropdownItem
                        className={styles.dropdownMenu_item}
                        onClick={() => setFollowupReminder(conversation, moment().add(1, 'weeks').toDate().getTime())}
                    >
                        <div className={styles.label}>
                            <FontAwesomeIcon className="mr-2" icon={faAnglesRight} />
                            Next Week
                        </div>
                        <span className={styles.timestampPreview}>
                            {moment().add(1, 'weeks').format('ddd, hh:mm A')}
                        </span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <Button
                        className={styles.dropdownMenu_item}
                        onClick={() => setDropdownState("CALENDAR")}
                    >
                        <div className={styles.label}>
                            <FontAwesomeIcon className="mr-2" icon={faCalendar} />
                            Pick a date & time
                        </div>
                    </Button>
                </DropdownMenu>
            )
            break;
        case "CALENDAR":
            const today = new Date();
  const nextDate = new Date(today);
  nextDate.setDate(today.getDate());
            dropdown = (
                <DropdownMenu className={styles.calenderMenu}>
                    <Calendar
                        onChange={setDate}
                        value={date}
                        minDate={nextDate}
                    />
                    <div className={styles.container}>
                        {date ? (
                            <TimeInput
                                onChange={setDate}
                                value={new Date(date)}
                            />
                        ) : null}
                        <div className={styles.action}>
                            <div>
                                <Button
                                    onClick={() => setDropdownState("DROPDOWN")}
                                    className="cancelButton"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className='ml-2 sendButton'
                                    // color='primary'
                                    onClick={() => setFollowupReminderHandler()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </DropdownMenu>
            );
            break;
        default:
            dropdown = null;
            break;
    }

    return (
        <div>
        <Dropdown
            isOpen={showDropdown}
            toggle={() => setShowDropdown(current => !current)}
        >
            <DropdownToggle
                id="FollowUpTooltip"
                color='link'
                className='btn btn-link contextMenu'
            >
                <img
                    width={20}
                    style={{paddingTop: '4px',  cursor: 'default'}}
                    alt='Follow-up'
                    src={`${process.env.PUBLIC_URL}/images/conversation/${conversation.reminder ? 'followup-reminder-on.svg' : 'followup-reminder-off.svg'} `}
                />
            </DropdownToggle>
            {!showDropdown && (
                <UncontrolledTooltip
                    placement="bottom"
                    target="FollowUpTooltip"
                    onClick={() => setDropdownState("CALENDAR")}
                >
                    {conversation.reminder ? `You have Follow-up reminder at ${moment(conversation.reminderTime).format('YYYY-MM-DD hh:mm A')}` : 'No Follow-up reminder'}
                </UncontrolledTooltip>
            )}
            {dropdown}
        </Dropdown>
        </div>
    )
    
}

export default FollowupReminder;