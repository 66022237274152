import React, { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import styles from './designFlow.module.scss';
import moment from 'moment';
import TooltipReference from '../../common/tooltip';
import { sliceText } from '../../common/sliceText';
import { getFlows } from '../services/flows';
import Modall from '../../common/modall';
import BizTable from '../../common/BizTable';
import { PiWarningCircleBold } from 'react-icons/pi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import MediaDevicesainStore from '../../redux/store/store';
import { createLibraryBot } from '../services/bots';
import { toast } from '../../common/alert';
import { Navigate, useNavigate } from 'react-router-dom';
interface Bot {
    uid: string;
    name: string;
    [key: string]: any;
}
interface botRes {
    [key: string]: any;
}

const BotLibrary = ({ bots, allBots, refetch, isInvalidAccess }: { bots: Bot[], allBots: Bot[], refetch: () => void, isInvalidAccess: boolean }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [botFlows, setbotFlows] = useState([]);
    const [selectedlibrarybot, setselectedlibrarybot] = useState<Bot | null>(null);
    const [selectedbot, setselectedbot] = useState<Bot | null>(null);
    const selectedFlowUids = useSelector(
        (state: any) => state.tableReducer.selectedUids
    );
    const state = MediaDevicesainStore.getState();
    const memUid = state.cartreducer.business?.uid;
    const businessUid = state.cartreducer.business?.business?.uid;
    const channelUid = state.cartreducer.channelUid?.value;
    const noOfBots = state.cartreducer.business?.business?.plan?.bots;
    const [showGoToFlow, setShowGoToFlow] = useState(false);
    const navigate = useNavigate();
    const [botres, setbotres] = useState<botRes | null>(null);
    const [loading, setLoader] = useState(false);

    useEffect(() => {
        //if (selectedFlowUids.BOTLIBRARY0?.length)
        setShowGoToFlow(false);
    }, [selectedFlowUids])

    const getSelectedBot = async (bot: Bot) => {
        setIsModalOpen(true);
        setselectedlibrarybot(bot);
        setShowGoToFlow(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getFlowsBasedOnbotUid = useCallback(async (pageIndex: number,
        pageSize: number,
        searchText: string | null,
        currentPage: number): Promise<{ data: any; totalPages: number }> => {
        if (selectedlibrarybot) {
            const res = await getFlows(selectedlibrarybot.uid);
            if (res) {
                setbotFlows(res);
                return { data: res, totalPages: res.count };
            } else {
                setbotFlows([]);
                return { data: [], totalPages: 0 };
            }
        }
        return { data: [], totalPages: 0 };
    }, [selectedlibrarybot]);

    const generateInstanceText = (flowInstances: { payload: string }[]): string => {
        let instances: string = flowInstances.map((flowInstance: any) => `${flowInstance.keyword}, `).join('');
        instances = instances.substring(0, instances.length - 2);
        return instances;
    };

    const KeywordsData = (cell: string, row: any) => {
        const { flowInstances } = row;
        const allInstances = generateInstanceText(flowInstances);
        let previewInstances = generateInstanceText(flowInstances.slice(0, 2));
        !previewInstances && (previewInstances = "Order based flow");
        return (
            <>
                <span style={{ color: '#FFA523', fontSize: 20 }} className='me-2'>
                    <PiWarningCircleBold />
                </span>
                <span className="mt-2">
                    {previewInstances}
                </span>
                {flowInstances?.length > 2 ? (
                    <>
                        <span className="dimmed">...</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{allInstances}</Tooltip>}
                            trigger="hover"
                        >
                            <Button variant='link' style={{ padding: 0 }}>
                                <MdAdd size={12} color='#000' />
                            </Button>
                        </OverlayTrigger>
                    </>
                ) : null}
            </>
        );
    };

    const columns = [
        // {
        //     id: "flowSelection",
        //     Header: ({ getToggleAllRowsSelectedProps }: { getToggleAllRowsSelectedProps: any }) => (
        //         <div>
        //             <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        //         </div>
        //     ),
        //     Cell: ({ row }: { row: any }) => (
        //         <div>
        //             <input type="checkbox" {...row.getToggleRowSelectedProps()} />
        //         </div>
        //     ),
        //     accessor: "checkbox",
        // },
        {
            id: "selection",
            Header: ({
                getToggleAllRowsSelectedProps,
            }: {
                getToggleAllRowsSelectedProps: any;
            }) => (
                <div>
                    <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
                </div>
            ),
            Cell: ({ row }: { row: any }) => (
                <div>
                    <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                </div>
            ),
            accessor: "checkbox",
        },
        {
            Header: "Flow Name",
            accessor: "flowName",
            Cell: ({ cell, row }: any) => (
                <div>
                    <TooltipReference placement='right' content={cell.value} tooltipId={`botflowname-${row.id}`}>
                        <span className='pl-1'>{sliceText(cell.value, 12)}</span>
                    </TooltipReference>
                </div>
            ),
        },
        {
            Header: "Keywords",
            accessor: "flowInstances",
            Cell: ({ cell, row }: any) => KeywordsData(cell.value, row.original),
        },
    ];

    const botOptions = [
        { value: "create", label: "Create New Bot", isSpecial: true },
        ...allBots
            .filter((bot) => bot.uid !== selectedlibrarybot?.uid)
            .map((bot) => ({ value: bot.uid, label: bot.name }))
    ];

    const handleBotSelect = async (selectedOption: any) => {
        setselectedbot(selectedOption);
        setShowGoToFlow(false);
    };

    const deployNewBot = async (gotoFlow: boolean) => {
        if (loading) return;

        setLoader(true);

        try {
            if (gotoFlow) {
                navigate(`${process.env.PUBLIC_URL}/bots/${botres?.uid}`);
            } else {
                const flowuids = selectedFlowUids?.BOTLIBRARY0?.map((f: any) => f.uid);

                const payload: any = {
                    memberUid: memUid,
                    libraryBotUid: selectedlibrarybot?.uid,
                    flowUids: flowuids,
                };

                if (selectedbot?.value === 'create') {
                    payload.businessUid = businessUid;
                    payload.channelUid = channelUid;

                    const botres = await createLibraryBot(payload);
                    if (botres) {
                        setbotres(botres);
                        if (allBots?.length >= noOfBots) {
                            toast('error', `You cannot add more than ${noOfBots} bots`);
                            setShowGoToFlow(false);
                        } else {
                            toast('success', 'Bot Created Successfully');
                            refetch();
                            setShowGoToFlow(true);
                        }
                    }
                } else {
                    payload.targetBotUid = selectedbot?.value;

                    const botres = await createLibraryBot(payload);
                    if (botres) {
                        setbotres(botres);
                        toast('success', 'Bot Updated Successfully');
                        refetch();
                        setShowGoToFlow(true);
                    }
                }
            }
        } catch (error) {
            console.error(error);
            toast('error', 'An error occurred. Please try again.');
        } finally {
            setLoader(false);
        }
    };

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.data.isSpecial ? '#007ec3' : 'black',
            backgroundColor: state.isSelected || state.isFocused ? '#eaeaea' : 'white',
            ':hover': {
                backgroundColor: '#eaeaea'
            },
            cursor: 'pointer'
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
        }),
        control: (base: any) => ({
            ...base,
            width: 205,
        }),
        menu: (base: any) => ({
            ...base,
            width: 205,
        }),
    };

    return (
        <>
            {bots.length > 0 ? (
                <>
                    <Row className='mt-2'>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <div className={styles.botlibraryList}>
                                        <div className={`${styles.libraryHeaderColor}`}>Bot Name</div>
                                        {/* <div><b>Description</b></div> */}
                                        <div className={`ml-3 ${styles.libraryHeaderColor}`}>Created At</div>
                                        <div className={`ml-3 ${styles.libraryHeaderColor}`}>Action</div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {bots.map((bot) => (
                            <Col key={bot.id} md="12">
                                <Card>
                                    <CardBody>
                                        <div className={styles.botlibraryList}>
                                            <div>
                                                <TooltipReference placement='right' content={bot.name} tooltipId={`botname-${bot.id}`}>
                                                    <span>{sliceText(bot.name, 30)}</span>
                                                </TooltipReference>
                                            </div>
                                            {/* <div>
                                                {bot.description ? (
                                                    <TooltipReference placement='right' content={bot.description} tooltipId={`botDescription-${bot.id}`}>
                                                        <span className='ml-3'>{sliceText(bot.description, 30)}</span>
                                                    </TooltipReference>
                                                ) : <span className='ml-3'>-</span>}
                                            </div> */}
                                            <div>{moment(bot.createdAt).format("DD MMM hh.mm A")}</div>
                                            <div className={`${isInvalidAccess ? "notAllowed" : ""}`} ><Button className='btn btn-sm sendButton w-25' onClick={() => getSelectedBot(bot)} disabled={isInvalidAccess}>
                                                Use</Button></div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Modall isOpen={isModalOpen} onClose={handleCloseModal} title={selectedlibrarybot?.name} size='lg'>
                        <div className='mt-2'>
                            <Button
                                className={`sendButton ${styles.implementBot}`}
                                onClick={() => {
                                    if (!loading) {
                                        deployNewBot(showGoToFlow);
                                    }
                                }}
                                disabled={loading || (!showGoToFlow && (!selectedbot || !selectedFlowUids?.BOTLIBRARY0?.length))}
                            >
                                {loading ? 'Wait...' : showGoToFlow ? 'Go To Flow' : 'Implement'}
                                {loading && (<div className="inviteloader float-right"></div>)}
                            </Button>
                            <Select
                                menuPortalTarget={document.body}
                                options={botOptions}
                                onChange={handleBotSelect}
                                placeholder="Select or Create a bot"
                                className="float-right mb-3"
                                styles={customStyles}
                            />
                            <BizTable
                                columns={columns}
                                fetchData={getFlowsBasedOnbotUid}
                                counts={0}
                                refetchData={botFlows}
                                tableName="BOTLIBRARY"
                            />
                        </div>
                    </Modall>
                </>
            ) : (
                <div className='centerItemsWithWidth'>No Bots Found</div>
            )}
        </>
    );
};

export default BotLibrary;
