import React, { useState, useEffect, useCallback } from 'react';
import { metaGetAll, metaProduct, metaSync } from '../../services/catalogueService';
import { useSelector } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './Catalogue.scss';
import { Button, Nav, NavItem, NavLink, } from 'reactstrap';
import { ProductLog } from './ProductLog';
import {ConnectPage} from './FacebookConnect';
import { FacebookLogin } from'../../common/FBCatalogueBtn';
import BizTable from '../../common/BizTable';
import SearchInput from "../../common/searchComponent";
import { Collections } from './Collection';
import { ErrorImage } from '../../common/ErrorMessage';
import { toast } from '../../common/alert';
import TooltipReference from "../../common/tooltip";


const CatalogueMeta = () => {
    const [alldata, setAlldata] = useState<any>([]);
    const [isRotating, setIsRotating] = useState(false);
    const [activeTab, setTab] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const channel :any = useSelector ((state: any) => state.cartreducer.channelUid);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const [wholeData, setWholeData] = useState([]);
    const isFreeTrail = useSelector((state: any) => state.cartreducer.business?.business?.freeTrial)? true : false;
   
  
    const columns = [
      {
        Header: (
          <input
          type="checkbox"
          hidden = {true}
          // checked={this.state.allChecked}
          // onChange={this.toggleAllCheckboxes}
        />
        ),
        accessor: "checkbox",
        // Cell: checkboxCellRenderer,
        sticky: "left",
      },
      {
        Header: "Catalogue Id",
        accessor: "id",
        sticky: "left",
        Cell: ({ cell, row }: any) => cell.value ? cell.value : '-',
      },
      {
        Header: "Catalogue Name",
        accessor: "name",
        // Cell: ({ cell, row }: any) => mobileData(cell.value, row.original),
      },
      // {
      //   Header: "Website URL",
      //   accessor: "webSite",
      //   // Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
      //   grid: "third-column",
      // },
      {
        Header: "Catalogue Vertical",
        accessor: "vertical",
        // Cell: ({ cell, row }: any) => tagsData(cell.value, row.original),
      },
      {
        Header: "No. Of Products",
        accessor: "productCount",
        // Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }: any) => {
          // return cell?.value ? cell.value : '-';
          return <div> <span className='new'>Connected</span> </div>
        },
        // Cell: ({ cell, row }: any) => contactDataFormat(cell.value, row.original),
      }
    ] as any;
  

    
  const fetchData = useCallback(
    async (
      pageIndex: number,
      pageSize: number,
      searchText: string,
      currentPage: number
    ): Promise<{ data: any; totalPages: number }> => {
      // const listPayload = {
      //   uid: businessId,
      //   page: searchTerm ? 0 : currentPage,
      //   limit: pageSize,
      //   search: searchTerm,
      // };

      // const response = await ContactsGetAll(listPayload as any);
      if(channel?.value){
      const response = await  metaGetAll(channel?.value);
      if (response) {
        const data = response.data;
        setAlldata(data);
        setWholeData(data);
        setIsLoading(false);
        
        return { data: data, totalPages: 100 };
      }else {
        return { data: [], totalPages: 0 };
      }
      } else {
        return { data: [], totalPages: 0 };
      }
    },
    [channel?.value]
  );

  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm?.trim() : null;
    setSearchTerm(searchTermCheck);
    if (searchTermCheck) {
      const filtered = wholeData.filter((item :any)=> 
        item.name.toLowerCase().includes(searchTermCheck.toLowerCase())
      );
      setAlldata(filtered);
    } else {
      setAlldata(wholeData);
      // fetchData(0, 10, '', 0); 
    }
    
  };


    const getAllData = async () => {
      setIsLoading(true);
        try {
          const response = await  metaGetAll(channel?.value);
          const data = response.data;
          setAlldata(data);
          setIsLoading(false);
        } catch (error) {
          console.error('Error:', error); 
          setIsLoading(false);
        }
    };

    const handleSync = async () => {
        try {
          await metaSync(channel?.value);
          getAllData();
        } catch (error) {
          console.error('Error:', error); 
        }
    };

    // useEffect(() => {
    //   if(activeTab === '1'){
    //     getAllData();
    //   }
    // }, [activeTab, channel]);

    return(
      <>
    {!alldata? <ConnectPage /> :
        <div className='catalogue-container'>
            <h5 className='mt-3'>Facebook Catalogue</h5>
            <Nav tabs >
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setTab("1")}
                >
                  Catalogue
                </NavLink>
              </NavItem>
              <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => setTab("2")}
            >
              Products
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : ''}
              onClick={() => setTab("3")}
            >
              Collections
            </NavLink>
          </NavItem>

          </Nav>
          {/* <hr className='hrline'/> */}
        {activeTab === "1"?
          <div>
              <div className='row pt-3 pb-2'>
              <div className='col-md-3'>
            <SearchInput
              component="catalogue"
              onSearchChange={onSearchChange}
              placeHolder={"Search Catalogue"}
            />
            </div>
            <div className='col'></div>
          <div className='col-md-3 d-flex justify-content-end'>
          <Button
            className="refreshIconHistory me-2  mb-2"
            onClick={() => {
              setIsRotating(true);
              handleSync();
              setTimeout(()=>{toast("success", "Catalogue synced successfully");
              },1000)
            }}
          >
            <svg
              className={isRotating ? "rotate" : "reload"}
              onAnimationEnd={() => {
                setIsRotating(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_2402_33684)">
                <path
                  d="M19.166 3.33325V8.33325H14.166"
                  stroke="#666E7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.833984 16.6667V11.6667H5.83398"
                  stroke="#666E7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.92565 7.49998C3.34829 6.30564 4.0666 5.23782 5.01354 4.39616C5.96048 3.55451 7.10521 2.96645 8.34089 2.68686C9.57657 2.40727 10.8629 2.44527 12.08 2.79729C13.297 3.14932 14.405 3.80391 15.3007 4.69998L19.1673 8.33331M0.833984 11.6666L4.70065 15.3C5.59627 16.1961 6.7043 16.8506 7.92133 17.2027C9.13836 17.5547 10.4247 17.5927 11.6604 17.3131C12.8961 17.0335 14.0408 16.4455 14.9878 15.6038C15.9347 14.7621 16.653 13.6943 17.0757 12.5"
                  stroke="#666E7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2402_33684">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Button>
          {/* {isFreeTrail? 
                <TooltipReference
                placement="right"
                content="Unable to login on trial account"
                tooltipId="buttons"
              >
               <div className={!isFreeTrail ? 'disabled' : ''}><FacebookLogin freeTrial={isFreeTrail}/></div>
                </TooltipReference> : */}
             <div><FacebookLogin freeTrial={false}/></div>
          </div>
          </div>
          {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
        <div>
            {/* <BootstrapTable data={alldata}
            search
            pagination={alldata?.length > 10}
            version="4"
            striped
            hover
            containerStyle={{ textAlign: "left" }}>
                <TableHeaderColumn dataField="id" isKey hidden> # </TableHeaderColumn>
                <TableHeaderColumn  dataField="id" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Catalogue Id</b></TableHeaderColumn>
                <TableHeaderColumn  dataField="name"  columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Catalogue Name</b></TableHeaderColumn>
                <TableHeaderColumn  dataField="vertical" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Catalogue Type</b></TableHeaderColumn>
                <TableHeaderColumn  dataField="productCount" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Products</b></TableHeaderColumn>
                <TableHeaderColumn  dataField="status" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Status</b></TableHeaderColumn>
            </BootstrapTable> */}
             <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={alldata.length}
              refetchData={alldata}
              tableName='METACATALOGUE'
            />
        </div>
        )}
        </div>
        : activeTab === "2"? <ProductLog /> : <Collections/>}
        </div>
      }
      </>
    )
}


export default CatalogueMeta;