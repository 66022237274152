import React, { useEffect, useState, useRef } from 'react'
import useOnScreen from '@custom-react-hooks/use-on-screen';
import { useClickAway } from "@uidotdev/usehooks";

import styles from './conversationFilterSelect.module.scss'

export interface IItem {
    id: number,
    value: string,
    label: string
};


interface LoadMoreProps {
    loading: boolean;
    onLoad: () => void;
    children?: React.ReactNode
}

const useIntersectionObserver = (options: any) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [options]); // You might need to serialize options or ensure stable reference

    return { ref, isIntersecting };
};

export const LoadMore: React.FC<LoadMoreProps> = ({ loading, onLoad, children }) => {
    const { ref, isIntersecting } = useOnScreen({ threshold: 0 }, false);
    useEffect(() => {
        if (isIntersecting && !loading) {
            onLoad();
        }
    }, [isIntersecting]);
    // const { ref, isIntersecting } = useIntersectionObserver({ threshold: 0 });
    // const [hasIntersected, setHasIntersected] = useState(false);


    // console.log('isIntersecting && loading && !hasIntersected',isIntersecting, loading , hasIntersected)
    // useEffect(() => {
    //     // Only trigger onLoad after the first intersection
    //     if (isIntersecting && !loading && !hasIntersected) {
    //         onLoad();
    //         setHasIntersected(true); // Prevent further onLoad calls
    //     }
    // }, [isIntersecting, loading, onLoad]);

    return (
        <div
            ref={ref as any}
            style={{ visibility: loading ? "visible" : "hidden", padding: 0 }}
        >
            {children ? children : (
                <div className={styles.suggestion_message}>
                    Loading...
                </div>
            )}
        </div>
    );
}

interface ConversationFilterSelectProps {
    creatable?: boolean;
    options: IItem[];
    onChange: (item: IItem) => void;
    onLoad: () => any;
    onCreate?: (n: string) => void;
    onSearch: (q: string) => Promise<any>;
    shouldInfiniteLoad: boolean;
};


const ConversationFilterSelect: React.FC<ConversationFilterSelectProps> = ({
    onChange,
    onLoad,
    onSearch,
    shouldInfiniteLoad,
    creatable,
    onCreate,
    options
}) => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [showSuggestion, setShowSuggestion] = useState(false);
    const menuRef = useClickAway(() => {
        setShowSuggestion(false);
    });
    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            if (search.trim() !== "")
                onSearch(search);
        }, 500);

        return () => clearTimeout(timer);
    }, [search]);
    useEffect(() => {
        if (loading) setLoading(false);
    }, [options]);

    const loadMoreHandler = () => {
        onLoad();
    }

    const createHandler = (tagName: string) => {
        if (onCreate) onCreate(tagName);
    }

    return (
        <div className={styles.wrapper}>
            <input
                placeholder='Search...'
                value={search}
                onChange={event => setSearch(event.target.value)}
                className={['form-control', styles.selectInput].join(" ")}
                onFocus={() => setShowSuggestion(true)}
                onClick={() => setShowSuggestion(true)}
            />
            {showSuggestion ? (
                <div
                    ref={menuRef as any}
                    className={styles.suggestion}
                    onClick={() => setShowSuggestion(false)}
                >
                    {search.trim() === "" ? (
                        <div className={styles.suggestion_message}>
                            Search to select
                        </div>
                    ) : loading ? (
                        <div className={styles.suggestion_message}>
                            Loading...
                        </div>
                    ) : options.length > 0 ? (
                        <>
                            {options.map(itm => (
                                <div
                                    key={itm.id}
                                    className={styles.suggestion_item}
                                    onClick={() => onChange(itm)}
                                >
                                    {itm.label}
                                </div>
                            ))}
                            {shouldInfiniteLoad ? (
                                <LoadMore
                                    onLoad={loadMoreHandler}
                                    loading={false}
                                />
                            ) : null}
                        </>
                    ) : creatable ? (
                        <div
                            className={styles.suggestion_item}
                            onClick={() => createHandler(search.trim())}
                        >
                            + Create "{search.trim()}"
                        </div>
                    ) : options?.length === 0 ?
                        <div className={styles.suggestion_message}>
                            No results found
                        </div> : null}
                </div>
            ) : null}
        </div>
    )
}

ConversationFilterSelect.defaultProps = {
    creatable: false
};

export default ConversationFilterSelect;