import Node from '../node';

function TriggerSequence(props: any) {
    return (
        <Node {...props} label='Trigger Sequence' content={(
            <>
                The sequence can be triggered by a user action or by a bot action.
            </>
        )} />
    );
}

export default TriggerSequence;