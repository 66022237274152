import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import ValidationSettings from './validationSettings';
import SaveAddressSettings from './saveAddressSettings';
import { isValidRegex } from './shared';
import EditorCaption from '../editorCaption';
import BodyInput from '../bodyInput';
import VariableInputLabel from '../variableInputLabel';
import { SelectVariable } from '../setVariable';
import AdvancedSettings from './advancedSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

interface FormData {
    text: string;
    errorMessage: string;
    endFlow: boolean;
    attempt: number;
    validation: { minimum: string, maximum: string, regex: string };
    storageVariable: { name: string, type: 'TEXT' };
    addressParam:{ 
        name: string,
        phone_number: string,
        in_pin_code:string,
        floor_number:string,
         building_name:string,
        address:string,
        landmark_area:string,
         city:string}
};

const AskAddressEditor: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [formData, setFormData] = useState<FormData>({
        text: '',
        attempt: 1,
        endFlow: true,
        errorMessage: "",
        validation: {
            minimum: '',
            maximum: '',
            regex: ''
        },
        addressParam:{
            name: '',
            phone_number: '',
            in_pin_code:'',
            floor_number:'',
            building_name:'',
            address:'',
            landmark_area:'',
            city:''

        },
        storageVariable: {
            name: '',
            type: 'TEXT'
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'ask_address',
        data: formData
    }, props);
    useEffect(() => {
        init((data: any) => {
            let newData = { ...data };
            let newValidation = {
                minimum: '',
                maximum: '',
                regex: ''
            };
            let newaddressParam={
                name: '',
                phone_number: '',
                in_pin_code:'',
                floor_number:'',
                building_name:'',
                address:'',
                landmark_area:'',
                city:''
            };
            if (newData.validation) {
                if ('minimum' in newData.validation && newData.validation.minimum) {
                    newValidation.minimum = `${newData.validation.minimum}`;
                }
                if ('maximum' in newData.validation && newData.validation.maximum) {
                    newValidation.maximum = `${newData.validation.maximum}`;
                }
                if ('regex' in newData.validation && newData.validation.regex) {
                    newValidation.regex = newData.validation.regex;
                }
            }
            if(newData.addressParam){
                if('name' in newData.addressParam && newData.addressParam.name){
                    newaddressParam.name=`${newData.addressParam.name}`
                }
                if('phone_number' in newData.addressParam  && newData.addressParam.phone_number){
                    newaddressParam.phone_number=`${newData.addressParam.phone_number}`
                }
                if('in_pin_code' in newData.addressParam  && newData.addressParam.in_pin_code){
                    newaddressParam.in_pin_code=`${newData.addressParam.in_pin_code}`
                }
                if('floor_number' in newData.addressParam  && newData.addressParam.floor_number){
                    newaddressParam.floor_number=`${newData.addressParam.floor_number}`
                }
                if('building_name' in newData.addressParam && newData.addressParam .building_name){
                    newaddressParam.building_name=`${newData.addressParam.building_name}`
                }
                if('address' in newData.addressParam && newData.addressParam .address){
                    newaddressParam.address=`${newData.addressParam .address}`
                }
                if('landmark_area' in newData.addressParam && newData.addressParam .landmark_area){
                    newaddressParam.landmark_area=`${newData.addressParam.landmark_area}`
                }
                
                if('city' in newData.addressParam  && newData.addressParam .city){
                    newaddressParam.city=`${newData.addressParam .city}`
                }
                
            }
            if (!newData.storageVariable) {
                newData.storageVariable = {
                    name: '',
                    type: 'TEXT'
                };
            }
            newData.validation = newValidation;
            newData.addressParam=newaddressParam;
            setFormData(newData);
        });
    }, []);

    const validationSchema = yup.object().shape({
        minimum: yup.string().matches(/^$|^[0-9]+$/, 'Minimum should be a number')
            .test('minimum-less-than-maximum', 'Minimum should be less than maximum', function (value) {
                const maximumValue = this.parent.maximum;
                if (!value || !maximumValue) return true;
                return parseInt(value, 10) < parseInt(maximumValue, 10);
            }),
        maximum: yup.string().matches(/^$|^[0-9]+$/, 'Maximum should be a number')
            .test('maximum-greater-than-minimum', 'Maximum should be greater than minimum', function (value) {
                const minimumValue = this.parent.minimum;
                if (!value || !minimumValue) return true;
                return parseInt(value, 10) > parseInt(minimumValue, 10);
            }),
        regex: yup.string().test({
            name: 'isValidRegex',
            test(value: string | undefined): boolean {
                if (value) {
                    if (value === '') return true;
                    return isValidRegex(value);
                }
                return true;
            },
            message: 'Regex is invalid'
        }),
    });
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        text: yup.string().required('Question Text is a required field').test('is-double-space', 'Question Text is a required field', value => value !== '  '),
        errorMessage: yup.string().notRequired(),
        attempt: yup.number().min(0, 'Attempt should be minimum 0').required('Attempt is a required'),
        validation: validationSchema,
        endFlow: yup.boolean().required("Action on Invalid Response required"),
        storageVariable: storageVariableSchema,
        addressParam: yup.object({
            phone_number: yup.string()
                .matches(/^[0-9]+$/, "Phone number must be numeric"),
            in_pin_code: yup.string()
                .matches(/^[0-9]+$/, "Pin code must be numeric"),
            
        }),
    });

    type ValidationObject = {
        minimum: string;
        maximum: string;
        regex: string;
    };
    type AddressParamObject={
        name: string,
        phone_number: string,
    in_pin_code:string,
    floor_number:string,
    building_name:string,
    address:string,
    landmark_area:string,
    city:string

    }
    function isValidation(validation?: ValidationObject | null): boolean {
        if (!validation) {
            return false;
        }
        return Object.values(validation).some(value => value !== '');
    }

    function isAddressParam( addressParam?:AddressParamObject | null):boolean{
        if(! addressParam){
            return false;
        }
        return Object.values(addressParam).some(value => value !== '');

    }


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Ask Address' icon={<FontAwesomeIcon icon={faAddressCard} color={"#5d8ca6"}/>}  />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='text'
                                    value={values.text}
                                    isInvalid={(touched.text && errors.text) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.text && !errors.text}
                                    label={<>Question Text<span className="required"></span></>}
                                    placeholder='What do you think?'
                                    error={errors.text}
                                    limit={1024}
                                />
                                {(touched.text && errors.text) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.text}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <ValidationSettings
                                    initiallyOpen={isValidation(values.validation)}
                                    onCancel={() => {
                                        setFieldValue('validation', {
                                            minimum: '',
                                            maximum: '',
                                            regex: ''
                                        });
                                    }}
                                >
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Minimum characters</Form.Label>
                                        <Form.Control
                                            name='validation.minimum'
                                            type='number'
                                            value={values.validation.minimum}
                                            isInvalid={touched.validation?.minimum && errors.validation?.minimum ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.validation?.minimum && !errors.validation?.minimum}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.validation?.minimum}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Maximum characters</Form.Label>
                                        <Form.Control
                                            name='validation.maximum'
                                            type='number'
                                            value={values.validation.maximum}
                                            isInvalid={touched.validation?.maximum && errors.validation?.maximum ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.validation?.maximum && !errors.validation?.maximum}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.validation?.maximum}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Regex</Form.Label>
                                        <Form.Control
                                            name='validation.regex'
                                            value={values.validation.regex}
                                            isInvalid={touched.validation?.regex && errors.validation?.regex ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.validation?.regex && !errors.validation?.regex}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.validation?.regex}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </ValidationSettings>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <AdvancedSettings />
                                <SaveAddressSettings
                                    initiallyOpen={isAddressParam(values.addressParam)}
                                    onCancel={() => {
                                        setFieldValue('addressParam', {
                                            name: '',
                                            phone_number: '',
                                           in_pin_code:'',
                                            floor_number:'',
                                            building_name:'',
                                            address:'',
                                            landmark_area:'',
                                            city:''
                                        });
                                    }}
                                >
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Name{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                            name='addressParam.name'
                                            value={values.addressParam.name}
                                            isInvalid={touched.addressParam?.name && errors.addressParam?.name ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.addressParam?.name && !errors.addressParam?.name}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.addressParam?.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Phone Number{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                          type="tel"

                                            name='addressParam.phone_number'
                                            value={values.addressParam.phone_number}
                                            isInvalid={touched.addressParam?.phone_number && errors.addressParam?.phone_number ? true : false}
                                            onChange={handleChange}
                                            isValid={touched. addressParam?.phone_number && !errors. addressParam?.phone_number}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors. addressParam?.phone_number}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Pincode{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                             name='addressParam.in_pin_code'
                                             value={values.addressParam.in_pin_code}
                                             isInvalid={touched.addressParam?.in_pin_code && errors.addressParam?.in_pin_code ? true : false}
                                             onChange={handleChange}
                                             isValid={touched.addressParam?.in_pin_code && !errors.addressParam?.in_pin_code}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.addressParam?.in_pin_code}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Floor Number{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                             name='addressParam.floor_number'
                                             value={values.addressParam.floor_number}
                                             isInvalid={touched.addressParam?.floor_number && errors.addressParam?.floor_number ? true : false}
                                             onChange={handleChange}
                                             isValid={touched.addressParam?.floor_number && !errors.addressParam?.floor_number}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.addressParam?.floor_number}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Building name{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                           name='addressParam.building_name'
                                           value={values.addressParam.building_name}
                                           isInvalid={touched.addressParam?.building_name && errors.addressParam?.building_name ? true : false}
                                           onChange={handleChange}
                                           isValid={touched. addressParam?.building_name && !errors. addressParam?.building_name}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.addressParam?.building_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Address{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                            name='addressParam.address'
                                            value={values.addressParam.address}
                                            isInvalid={touched.addressParam?.address && errors.addressParam?.address ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.addressParam?.address && !errors.addressParam?.address}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.addressParam?.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Landmark/Area{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                            name='addressParam.landmark_area'
                                            value={values.addressParam.landmark_area}
                                            isInvalid={touched.addressParam?.address && errors.addressParam?.address ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.addressParam?.address && !errors.addressParam?.address}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors. addressParam?.landmark_area}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>City{<span className='dimmed'>(Optional)</span>}</Form.Label>
                                        <Form.Control
                                           name='addressParam.city'
                                           value={values.addressParam.city}
                                           isInvalid={touched.addressParam?.city && errors.addressParam?.city ? true : false}
                                           onChange={handleChange}
                                           isValid={touched.addressParam?.city && !errors.addressParam?.city}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.addressParam?.city}
                                        </Form.Control.Feedback>
                                    </Form.Group>



                                </SaveAddressSettings>
                                </Form.Group>

                            <VariableInputLabel
                                error={(
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.storageVariable?.name}
                                    </Form.Control.Feedback>
                                )}>
                                <SelectVariable 
                                    placeholder="Create or Select variable"
                                    name="storageVariable"
                                    value={values.storageVariable.name}
                                    onChange={handleChange}
                                    type="TEXT"
                                />
                            </VariableInputLabel>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AskAddressEditor;