import React from 'react';
import { Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';

import styles from './optionsInput.module.css';
import { uuidv4 } from '../../../utils/uuid';
import { MdAdd } from 'react-icons/md';
import { PiMinusCircleFill } from 'react-icons/pi';

export interface IOption {
    id: string | null;
    uid: string;
    optionText: string;
    order: number;
}


interface Props {
    options: IOption[];
    placeholder?: string;
    addLabel?: string;
    isInvalid: boolean;
    isValid: boolean;
    error: any; // Type: string | string[] | FormikErrors<IOption>[] | undefined
    touched: FormikTouched<IOption>[] | undefined;
}

function isFormikErrorsOfIOption(error: any): error is FormikErrors<IOption> {
    return error && typeof error.optionText === 'string';
}

const OptionsInput: React.FC<Props> = ({ options, placeholder, addLabel, ...otherProps }) => {
    const formik = useFormikContext();
    const handleOptionTextChange = (index: number, text: string) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions[index] = { ...newOptions[index], optionText: text };
        formik.setFieldValue('options', newOptions);
    };

    const handleDelete = (index: number) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions.splice(index, 1);
        formik.setFieldValue('options', newOptions);
    };

    const handleAddOption = () => {
        if (options.length < 3) {
            const uid = uuidv4();
            const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
            newOptions.push({ uid, id: null, optionText: '', order: newOptions.length + 1 });
            formik.setFieldValue('options', newOptions);
        }
    };

    return (
        <div>
            {options.map((question, index) => {
                const isError: boolean = (otherProps.error && isFormikErrorsOfIOption(otherProps.error[index]) && otherProps.error[index].optionText) ? true : false;
                const isTouched: boolean = (otherProps.touched && otherProps.touched[index] && otherProps.touched[index].optionText) ? true : false;
                return (
                    <div className={styles.optionBox} key={question.uid} style={{position:"relative"}}>
                        {question.id ? (
                            <>ID: {question.id}</>
                        ) : null}
                        <Row className={styles.questionRow}>
                            <Col xs={1}>
                                <Button disabled={options.length < 2 && index < 1} variant='default' size='sm' className={[styles.removeBtn, 'minusRemoveBtn'].join(" ")} onClick={() => handleDelete(index)}>
                                    <PiMinusCircleFill />
                                </Button>
                            </Col>
                            <Col xs={10}>
                                <InputGroup>
                                    <FormControl
                                        name={`options[${index}].optionText`}
                                        placeholder={placeholder}
                                        value={question.optionText}
                                        isInvalid={isError && isTouched}
                                        isValid={!isError && isTouched}
                                        onChange={(e) => handleOptionTextChange(index, e.target.value)}
                                        maxLength={20}
                                    />
                                      <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        bottom: -20,
                                        fontSize: '.8rem',
                                        fontWeight: 400,
                                        color: '#A3A3A3',
                                        padding: '1px 3px'
                                    }}>
                                        {question.optionText.length}/20
                                    </div>

                                    {(otherProps.error && isError && isTouched) && (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {otherProps.error[index].optionText}
                                        </div>
                                    )}
                                </InputGroup>
                            </Col>
                        </Row>

                    </div>
                );
            })}
            {options.length < 3 ? (
                <Button onClick={handleAddOption} size='sm' className='addButton ml-2'><MdAdd /></Button>
            ) : null}
            {(otherProps.touched && otherProps.error && typeof otherProps.error === 'string') ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {otherProps.error}
                </div>
            ) : null}
        </div>
    );
};

export default OptionsInput;
