import React from 'react';


interface NoDataFallbackProps {
    caption?: string;
    message?: any;
    component?:string;
}

const NoDataFallback: React.FC<NoDataFallbackProps> = ({ message, caption, component }) => {
    return (
        <div className="text-center" style={{ userSelect: "none", padding: '30 0', height: component === 'conversationFilter' ? '70vh': '' }}>
            <svg width="65px" height="65px" viewBox="0 -0.5 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" className="si-glyph si-glyph-folder-error mb-3">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(1.000000, 2.000000)" fill="#A5A5A5">
                        <path d="M7.35,3 L5.788,0.042 L2.021,0.042 L2.021,1.063 L0.023,1.063 L0.023,10.976 L1.043,10.976 L1.045,11.976 L15.947,11.976 L15.968,3 L7.35,3 L7.35,3 Z M10.918,9.109 L10.09,9.938 L8.512,8.361 L6.934,9.938 L6.104,9.109 L7.682,7.531 L6.104,5.953 L6.934,5.125 L8.512,6.701 L10.088,5.125 L10.918,5.953 L9.34,7.531 L10.918,9.109 L10.918,9.109 Z" className="si-glyph-fill">

                        </path>
                        <path d="M13.964,1.982 L13.964,1.042 L8.024,1.042 L8.354,1.982 L13.964,1.982 Z" className="si-glyph-fill">

                        </path>
                    </g>
                </g>
            </svg>
            <h4 className="dimmed">{caption}</h4>
            <div className="dimmed">{message}</div>
        </div>
    );
}

NoDataFallback.defaultProps = {
    caption: '',
    message: 'No data found'
};

export default NoDataFallback;
