import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sliceText } from "../../common/sliceText";
import moment from "moment/moment";
import campaign_icon from "../../assets/img/svgs/campaign_icon.svg";
import boticon from "../../assets/img/svgs/bot.svg";
import ConversationFile from "./conversationFile";
import { getTextBasedOnSubType, renderMessage } from "../../common/commonfns";
import SingleTemplate from "../Template/singleTemplate";
import {
  faList,
  faInfoCircle,
  faUpRightFromSquare,
  faLocationDot,
  faExclamationCircle,
  faCheckCircle,
  faReply,
  faSmile,
  faEdit,
  faChevronDown,
  faChevronUp,
  faRepeat,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import ReplyMessageComponent from "./replyMessage";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import CommonPopover from "../../common/commonPopover";
import { Link } from "react-router-dom";
import Modall from "../../common/modall";
import { saveAs } from "file-saver";
import axios from "../../utils/axios";
import TooltipReference from "../../common/tooltip";
import {
  Button,
  Col,
  FormGroup,
  Label,
  ModalFooter,
  Popover,
  PopoverBody,
  Row,
  Spinner,
} from "reactstrap";
import Picker from "@emoji-mart/react";
import customData from "@emoji-mart/data";
import { IoCart, IoCopyOutline } from "react-icons/io5";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Select from "react-select";
import { useSelector } from "react-redux";
import RightSidebar from "../../common/rightSidebar";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from "formik";
import Avatar from "react-avatar";
import * as Yup from "yup";
import { updateOrder } from "../../services/orderService";
import { getAll } from "../../services/paymentService";
import { getBots } from "../../botFlows/services/bots";
import cart from "../../assets/img/cartIcon.png";
import defaultImg from "../../assets/img/imageThumb.png";
import AxiosInstance from "../../utils/axios";
import { toast } from "../../common/alert";
import * as mediaService from "../../botFlows/services/media";
import { BsFillTrashFill } from "react-icons/bs";
import styles from "../../botFlows/components/designFlow/editor/catalogue/sendOrderDetails.module.scss";
import ValidationSettings from "../../botFlows/components/designFlow/editor/askType/validationSettings";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import MapLoader from "../../common/MapLoader";
interface messageProps {
  messages: any[];
  selectedConversation: any;
  selectedMember: any;
  mappedMemberData: any;
  isFetching: any;
  closePopover?: any;
  popopen?: any;
  onReplySelected: (message: any, name: any, direction: any) => void;
  onEmojiSelected: (messageId: any, emoji: any) => void;
  socket: any;
  allBots: any;
  scrollMessages: boolean;
  isInvalidAccess?: boolean;
  allpayment?: any;
  setmessage: (errmsg: any) => void;
}
interface IconVisibilityState {
  [key: number]: boolean;
}

interface SelectBtnOption {
  value: string;
  label: string;
}

const orderStatus = [
  { value: "order_request", label: "new" },
  { value: "pending", label: "pending" },
  { value: "processing", label: "processing" },
  // {value: "failed", label: "failed"},
  { value: "partially_shipped", label: "partially shipped" },
  { value: "shipped", label: "shipped" },
  { value: "completed", label: "completed" },
  { value: "canceled", label: "canceled" },
];

const paymentStatus = [
  { value: "new_payment", label: "new" },
  { value: "payment_initiated", label: "payment initiated" },
  { value: "paid", label: "paid" },
  { value: "canceled", label: "canceled" },
  { value: "failed", label: "failed" },
];

const time = [
  { value: "Minutes", label: "Minutes" },
  { value: "Hours", label: "Hours" },
];

var popoverEmoji = false;

export const handleScrollEmoji = () => {
  popoverEmoji = false;
};

const Messages: React.FC<messageProps> = ({
  messages,
  selectedConversation,
  selectedMember,
  mappedMemberData,
  isFetching,
  onReplySelected,
  onEmojiSelected,
  socket,
  allBots,
  isInvalidAccess,
  allpayment,
  setmessage,
  ...props
}) => {
  const [errorPopover, setErrorPopover] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modals, setModals] = useState(messages.map(() => false)); // initialize all modals to be closed
  const [viewCartModals, setViewCartModals] = useState(
    messages.map(() => false)
  );
  const [selectedCartDetails, setSelectedCartDetails] = useState(
    messages.map(() => false)
  );

  const [unSupportedModals, setUnsupportedModals] = useState(
    messages.map(() => false)
  );

  const channel: any = useSelector(
    (state: any) => state.cartreducer.channelUid
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCaption, setSelectedCaption] = useState(null);
  const [loader, setLoader] = useState(false);
  const [messLoader, setMessLoader] = useState(false);
  const [media, setMedia] = useState<any>([]);

  //order popover
  const [selectedStatus, setStatus] = React.useState<SelectBtnOption | null>(
    null
  );
  const [selectedpayment, setPayment] = React.useState<SelectBtnOption | null>(
    null
  );
  const [payMethod, setPayMethod] = React.useState<SelectBtnOption | null>(
    null
  );
  const [selectedRow, setRow] = useState<any>([]);
  // const [allpayment, setpayment] = useState<any>([]);
  const [headCount, setHeadCount] = useState(0);
  const [charCount, setCount] = useState(0);
  const [order, setOrder] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [footerCount, setFooterCount] = useState(0);
  const [pay, setPay] = useState<boolean>(false);
  const [bodyMsg, setBody] = useState<any>([]);
  const [taxCount, setTax] = useState(0);
  const [shipCount, setShip] = useState(0);
  const [discountCount, setDiscount] = useState(0);
  const [nameCount, setName] = useState(0);
  const [isTaxVisible, setIsTaxVisible] = useState(false);
  const [isShippingVisible, setIsShippingVisible] = useState(false);
  const [isDiscountVisible, setIsDiscountVisible] = useState(false);
  const [isOpen, setValidateOpen] = useState(false);

  var paymentObj: SelectBtnOption[] = [];
  paymentObj = allpayment?.map((item: any) => ({
    value: item.uid,
    label:
      item.method === "upi"
        ? "UPI VPA ID"
        : item.method === "payu"
          ? "PayU"
          : "Razorpay",
  }));
  const [iconVisibility, setIconVisibility] = useState<IconVisibilityState>({});
  const business = useSelector((state: any) => state.cartreducer.business);
  const accountExpiry = moment(business?.plan?.expireAt).diff(moment(), "days") < 0 ? true : false;
  const [copiedUid, setCopiedUid] = useState(null);

  const [emojiPopupOpen, setEmojiPopupOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState({
    message: null,
    index: null,
    name: null,
    direction: null,
  });

  // const [popoverOpen, setPopoverOpen] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);
  const [popovertarget, setpopovertarget] = useState<null | HTMLButtonElement>(
    null
  );
  const [botData, setBotData] = useState<any>([]);
  const [reviewModal, setReview] = useState(messages.map(() => false));
  const [productMsg, setProductMsg] = useState(messages.map(() => false));
  const [continueBtn, setContinue] = useState(messages.map(() => false));
  const [isExpanded, setIsExpanded] = useState(messages.map(() => false));
  const [isCaption, setCaption] = useState(messages.map(() => false));
  const [disabledRetries, setDisabledRetries] = useState<Record<number, boolean>>({});

  const toggleReadMore = (index: any) => {
    const newModal_s = [...isExpanded];
    newModal_s[index] = !newModal_s[index];
    setIsExpanded(newModal_s);
  };

  const toggleVideoCaption = (index: any) => {
    const newModal_s = [...isCaption];
    newModal_s[index] = !newModal_s[index];
    setCaption(newModal_s);
  };

  const handleClosePopover = () => {
    popoverEmoji = false;
    setpopovertarget(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (popoverEmoji) {
        handleClosePopover();
      }
    };

    const handleMouseOver = (event: any) => {
      if (
        popoverEmoji &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target)
      ) {
        handleClosePopover();
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mouseover", handleMouseOver);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mouseover", handleMouseOver);
    };
  }, [popoverEmoji]);

  const defaultCatalogueImg =
    "https://th.bing.com/th/id/OIP.DbvmhORvHTusBsAinhz6AQAAAA?rs=1&pid=ImgDetMain";

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file: File) => {
    const allowedSize: number = 5;
    if (file.size > allowedSize * 1024 * 1024) {
      toast(
        "error",
        "File size too large!" +
        "Please select a file smaller than " +
        allowedSize +
        "MB."
      );
      return;
    }
    const formData = new FormData();
    formData.append("content", file);
    try {
      const response = await AxiosInstance.post(
        "media/saveMessageMedia?uid=" + channel?.channelAllData?.uid,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMedia(response.data);
    } catch (error) {
      console.error(error);
      toast("error", "Failed to upload!");
    }
  };

  const removeFileHandler = async (media: any) => {
    try {
      await mediaService.deleteMedia(media);
      setMedia(null);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleTaxForm = (value: string) => {
    value === "tax" && setIsTaxVisible(!isTaxVisible);
    value === "shipping" && setIsShippingVisible(!isShippingVisible);
    value === "discount" && setIsDiscountVisible(!isDiscountVisible);
  };

  const rupeesToPaisa = (cell: any) => {
    return <span className="fontchange">{`₹${(cell / 100).toFixed(2)}`}</span>;
  };

  const getselected = (value: any, status: string) => {
    let obj;
    if (status === "order") {
      obj = orderStatus.find((obj: any) => obj.value === value.toString());
    } else {
      obj = paymentStatus.find((obj: any) => obj.value === value.toString());
    }
    return obj;
  };

  const handleRowClick = (row: any) => {
    const status = getselected(row.status, "order") || { value: "", label: "" };
    setStatus(status);
    const paystatus = getselected(row.paymentStatus, "pay") || {
      value: "",
      label: "",
    };
    setPayment(paystatus);
    setHeadCount(0);
    setCount(0);
    setFooterCount(0);
    setPay(false);
    setOrder(false);
    setRow(row);
    setMedia(null);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  const handlePayment = (event: any) => {
    setPayment(event);
  };

  const handleStatus = (event: any) => {
    setStatus(event);
  };

  const toggleModal = (index: number) => {
    // error message
    const newModals = [...modals];
    newModals[index] = !newModals[index];
    setModals(newModals);
  };
  const toggleViewCartModal = (index: number) => {
    // close cart details
    const newModal_s = [...viewCartModals];
    newModal_s[index] = !newModal_s[index];
    setViewCartModals(newModal_s);
  };

  const toggleUnSupportedModal = (index: number) => {
    // close cart details
    const newModal_s = [...unSupportedModals];
    newModal_s[index] = !newModal_s[index];
    setUnsupportedModals(newModal_s);
  };

  const reviewOrder = (index: number) => {
    const newModal_s = [...reviewModal];
    newModal_s[index] = !newModal_s[index];
    setReview(newModal_s);
  };

  const productReview = (index: number) => {
    const newModal_s = [...productMsg];
    newModal_s[index] = !newModal_s[index];
    setProductMsg(newModal_s);
  };

  const handleCancel = (index: number) => {
    setSelectedCartDetails((prevRightBar) => {
      const newRightBar = [...prevRightBar];
      newRightBar[index] = false;
      return newRightBar;
    });
    setMedia(null);
  };

  const handleImageClick = (imageUrl: any, caption: any) => {
    setSelectedImage(imageUrl);
    setSelectedCaption(caption);
    setModalShow(true);
  };

  const toggleErrorPopover = () => {
    setErrorPopover(!errorPopover);
    setTimeout(() => {
      setErrorPopover(false);
    }, 2000);
  };
  const handleButtonClick = (event: any) => {
    setpopovertarget(event.currentTarget);
    // setPopoverOpen(true);
    popoverEmoji = true;
  };

  const handleEmojiSelect = (e: any, message: any) => {
    // setPopoverOpen(false);
    popoverEmoji = false;
    setpopovertarget(null);
    const dataToSend = {
      reaction: { message_id: message, emoji: e },
      businessId: business.business.uid,
    };
    socket.emit("reactionMessage", dataToSend);
    onEmojiSelected(message, e);
  };
  const handleMouseEnter = (index: any) => {
    setIconVisibility((prev) => ({ ...prev, [index]: true }));

    setTimeout(() => {
      setIconVisibility((prev) => ({ ...prev, [index]: false }));
    }, 3000);
  };
  const handleMouseLeave = (index: any) => {
    setTimeout(() => {
      setIconVisibility((prev) => ({ ...prev, [index]: false }));
    }, 3000);
  };
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (
      popoverEmoji &&
      popovertarget &&
      popovertarget !== target &&
      !popovertarget.contains(target) &&
      pickerRef.current &&
      !pickerRef.current.contains(target)
    ) {
      // setPopoverOpen(false);
      popoverEmoji = false;
      setpopovertarget(null);
    }
  };
  const handleReply = (
    event: any,
    message: any,
    index: any,
    name: any,
    direction: any
  ) => {
    setpopovertarget(event.currentTarget);
    onReplySelected(message, name, direction);
    setSelectedMessage({ message, index, name, direction });
  };

  // const getAllpayment = async () => {
  //   try {
  //     if (channel?.channelAllData?.uid) {
  //       const response = await getAll(channel?.channelAllData?.uid);
  //       const data = response.data;
  //       setpayment(data);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const closeModal = () => {
    setModalShow(false);
  };
  const handleMapClick = (lat: any, long: any) => {
    const url = `https://www.google.co.in/maps/place/${lat},${long}`;
    window.open(url, "_blank");
  };

  const handleCopy = (uid: any) => {
    navigator.clipboard
      .writeText(uid)
      .then(() => {
        setCopiedUid(uid);
      })
      .catch((error) => {
        console.error("Failed to copy UID:", error);
      });
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [bodyMsg]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popovertarget, popoverEmoji]);

  // useEffect(() => {
  //   getAllpayment();
  // }, []);

  useEffect(() => {
    if (messages.length > 0 && props.scrollMessages) {
      setMessLoader(true);

      const timeout = setTimeout(() => {
        setMessLoader(false);
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [messages, props.scrollMessages]);

  const validation = Yup.object().shape({
    paymentobj: Yup.object()
      .nullable()
      .required("Payment provider is required"),
    duration: Yup.number().min(5),
    body: Yup.string()
      .required("Message is required")
      .max(1024, "Message must not exceed 1024 characters"),
    header: Yup.string().max(60, "Header must not exceed 60 characters"),
    footer: Yup.string().max(60, "Footer must not exceed 60 characters"),
    taxDesc: Yup.string().max(60, "Description must not exceed 60 characters"),
    shipDec: Yup.string().max(60, "Decription must not exceed 60 characters"),
    discountDesc: Yup.string().max(
      60,
      "Description must not exceed 60 characters"
    ),
    discountName: Yup.string().max(
      60,
      "description must not exceed 60 characters"
    ),
  });

  const setContinueButton = (index: any, status: boolean) => {
    const newModal_s = [...continueBtn];
    newModal_s[index] = status;
    setContinue(newModal_s);
  };

  const handleSubmit = async (values: any, index: number) => {
    const payload = {
      channel_id: channel.value,
      reference_id: selectedRow.orderId,
      status: selectedStatus?.value,
      description: selectedRow.description ? selectedRow.description : "",
      paymentStatus: selectedpayment?.value,
      paymentConfigUid: payMethod?.value,
      expiringDuration: values.duration
        ? values.time.label === "Hours"
          ? values.duration * 3600000
          : values.duration * 60 * 1000
        : 5 * 60 * 1000,
      bodyText: values.body,
      headerText: values.header ? values.header : null,
      footerText: values.footer ? values.footer : null,
      header: media
        ? {
          headerType: "image",
        }
        : null,
      mediaDto: media ? media : null,
      tax: {
        amount: values.taxAmt ? values.taxAmt * 100 : null,
        description: values.taxDesc ? values.taxDesc : null,
      },
      discount: {
        amount: values.discountAmt ? values.discountAmt * 100 : null,
        description: values.discountDesc ? values.discountDesc : null,
        discountProgramName: values.discountName ? values.discountName : null,
      },
      shipping: {
        amount: values.shipAmt ? values.shipAmt * 100 : null,
        description: values.shipDec ? values.shipDec : null,
      },
    };
    // await updateOrder(payload);
    try {
      await updateOrder(payload);
      setContinueButton(index, true);
    } catch (error) {
      setContinueButton(index, false);
      console.error("Error updating order:", error);
    }
    handleCancel(index);
  };

  // const getBotList = async () => {
  //   try {
  //     const response = await  getBots('');
  //     setBotData(response);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };
  // useEffect(() => {
  //     getBotList();
  // }, []);

  // const getBotName = (id:any)=>{
  //   let botOptions = botData.map((item: any) => ({
  //     value: item.id.toString(),
  //     label: item.name,
  //   }));

  //   let name = botOptions.find((obj: any) => obj.value === id.toString());
  //   return name?.label? name?.label : null;
  // }

  const handleRetryClick = (index: number, message: any) => {
    setDisabledRetries((prev) => ({ ...prev, [index]: true }));
    setmessage(message);
    setTimeout(() => {
      setDisabledRetries((prev) => ({ ...prev, [index]: false }));
    }, 5000);
  };

  return (
    <>
      {messLoader && (
        <div className="d-flex justify-content-center">
          <Spinner color="dark">Loading...</Spinner>
        </div>
      )}
      {messages.map((m, index) => {
        let botButtonMsgs: any, renderedButtons;
        if (
          m.messageType === "component" ||
          (m.messageType === "interactive" &&
            m.componentMessage?.subType === "nfm_reply")
        ) {
          botButtonMsgs = m.componentMessage;
          if (botButtonMsgs?.action) {
            const numButtons = Array.isArray(botButtonMsgs?.action[0])
              ? botButtonMsgs?.action[0]?.length
              : botButtonMsgs?.action?.length;
            renderedButtons = Array.isArray(botButtonMsgs?.action[0])
              ? botButtonMsgs?.action[0]?.map((btn, innerindex) => (
                <span
                  key={innerindex}
                  title={btn.text}
                  className={`pt-2 mt-1 text-center singleTempBtns ${m.status == -1
                    ? "failedMsgBg"
                    : "convbuttons"
                    } 
                ${numButtons === 1
                      ? "col-12 mb-2"
                      : innerindex === 2
                        ? "col-12"
                        : "singleTempBtnWidth"
                    } ${numButtons === 3 && innerindex === 0
                      ? "mr-2"
                      : numButtons === 2 && innerindex === 0
                        ? "mr-2"
                        : ""
                    } 
                   ${numButtons === 3 &&
                      innerindex === 2
                      ? "mb-1"
                      : ""
                    }`}
                >
                  {numButtons === 1 && innerindex === 0
                    ? sliceText(btn?.buttonMessage?.title, 30)
                    : numButtons === 3 && innerindex === 2
                      ? sliceText(btn?.buttonMessage?.title, 30)
                      : sliceText(btn?.buttonMessage?.title, 30)}

                  {/* {sliceText(btn.text, 14)} */}
                </span>
              ))
              : botButtonMsgs?.action?.map((btn: any, innerindex: number) => (
                <span
                  key={innerindex}
                  title={btn.text}
                  className={`pt-2 mt-1 text-center singleTempBtns ${m.status == -1
                    ? "failedMsgBg"
                    : "convbuttons"
                    } 
                ${numButtons === 1
                      ? "col-12 mb-2"
                      : innerindex === 2
                        ? "col-12"
                        : "singleTempBtnWidth"
                    } ${numButtons === 3 && innerindex === 0
                      ? "mr-2"
                      : numButtons === 2 && innerindex === 0
                        ? "mr-2"
                        : ""
                    } 
                    ${numButtons === 3 &&
                      innerindex === 2
                      ? "mb-1"
                      : ""
                    }`}
                >
                  {numButtons === 1 && innerindex === 0
                    ? sliceText(btn?.buttonMessage?.title, 30)
                    : numButtons === 3 && innerindex === 2
                      ? sliceText(btn?.buttonMessage?.title, 30)
                      : sliceText(btn?.buttonMessage?.title, 30)}

                  {/* {sliceText(btn.text, 14)} */}
                </span>
              ));
            {
              numButtons > 2 && (
                <span
                  className={`pt-2 mt-1 text-center singleTempBtns ${m.status == -1
                    ? "failedMsgBg"
                    : "convbuttons"
                    } singleTempBtnWidth`}
                >
                  <FontAwesomeIcon
                    icon={faList}
                    widths={80}
                    className="me-2"
                  ></FontAwesomeIcon>
                  Explore More
                </span>
              )
            }
          }
        }
        let dayTimeline = null;
        let createdTime = null;

        if (index > 0) {
          if (
            new Date(messages[index - 1].createdAt).toDateString() !==
            new Date(m.createdAt).toDateString()
          ) {
            dayTimeline = (
              <div className="timestampBlock">
                {moment(m.createdAt).isSame(moment(), "day")
                  ? "Today"
                  : moment(m.createdAt).isSame(
                    moment().subtract(1, "day"),
                    "day"
                  )
                    ? "Yesterday"
                    : moment(m.createdAt).format("D MMM")}
              </div>
            );
          }
        } else {
          dayTimeline = (
            <div className="timestampBlock">
              {moment(m.createdAt).isSame(moment(), "day")
                ? "Today"
                : moment(m.createdAt).isSame(moment().subtract(1, "day"), "day")
                  ? "Yesterday"
                  : moment(m.createdAt).format("D MMM")}
            </div>
          );
        }

        function convertFilesize(filesizeBytes: number): string {
          if (filesizeBytes < 1024) {
            return `${filesizeBytes} bytes`;
          } else if (filesizeBytes < 1024 * 1024) {
            return `${(filesizeBytes / 1024).toFixed(1)} kB`;
          } else if (filesizeBytes < 1024 * 1024 * 1024) {
            return `${(filesizeBytes / (1024 * 1024)).toFixed(1)} MB`;
          } else {
            return `${(filesizeBytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
          }
        }

        createdTime = (
          <>
            <span className="timeTopMargin createdTime">
              {m.assignId &&
                m.assignTo !== 1 &&
                m.assignTo !== 4 &&
                m.assignTo !== 5 &&
                (index === 0 ? (
                  <TooltipReference
                    placement="bottom"
                    content={mappedMemberData.get(m.assignId) || ""}
                    tooltipId={`reminderName`}
                  >
                    <span
                      className="reminderName"
                    // title={mappedMemberData.get(m.assignId) || ""}
                    >
                      {mappedMemberData
                        .get(m.assignId)
                        ?.slice(0, 1)
                        .toUpperCase()}
                    </span>
                  </TooltipReference>
                ) : messages[index - 1]?.assignId !== m.assignId ? (
                  <TooltipReference
                    placement="bottom"
                    content={mappedMemberData.get(m.assignId) || ""}
                    tooltipId={`reminderName`}
                  >
                    <span
                      className="reminderName"
                    // title={mappedMemberData.get(m.assignId) || ""}
                    >
                      {mappedMemberData
                        .get(m.assignId)
                        ?.slice(0, 1)
                        .toUpperCase()}
                    </span>
                  </TooltipReference>
                ) : (
                  ""
                ))}
            </span>
            {m.direction === 1 && !m.note && (
              <span className="mt-2 createdTime">
                {m.status === 1 && (
                  <span className="pl-1 statusDesign">&#x23F0;</span>
                )}
                {m.status === 2 && (
                  <span className="pl-1 statusDesign">&#x2713;</span>
                )}
                {m.status === 3 && (
                  <span className="pl-1 doubleTick statusDesign">
                    &#x2713;&#x2713;
                  </span>
                )}
                {m.status === 4 && (
                  <span className="pl-1 deliveredTick doubleTick statusDesign">
                    &#x2713;&#x2713;
                  </span>
                )}
                {m.assignTo === 4 && (
                  <span className="ml-1 mb-2">
                    <img src={campaign_icon} height={18.5} width={18.5} />
                  </span>
                )}
                {m.assignTo === 5 && (
                  <span className="ml-1 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="side-menu__icon"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" />
                    </svg>
                  </span>
                )}
                {m.assignTo === 1 && (
                  <span>
                    <img
                      src={boticon}
                      alt="botimg"
                      width={18}
                      height={18}
                      className="ml-1 mb-1"
                    />
                    <span
                      className="botName"
                      title={allBots.get(m.assignId) || ""}
                    >
                      {m.assignId
                        ? allBots.get(m.assignId)?.slice(0, 1).toUpperCase()
                        : ""}
                    </span>
                  </span>
                )}
              </span>
            )}
            <span className="ml-2 timeTopMargin createdTime">
              {moment(m.createdAt).format("hh:mm A")}
            </span>
          </>
        );

        let action, items, itemsName, advanceOption;
        if (botButtonMsgs?.subType === "order_details") {
          action = JSON.parse(botButtonMsgs?.action);
          // console.log("act",action)
          advanceOption = action?.parameters?.order;
          items = action?.parameters?.order?.items;
          // console.log("ad", items)
          itemsName = items.map((e: any) => e.name).join(", ");
        }

        let section;
        if (botButtonMsgs?.subType === "product") {
          section = JSON.parse(botButtonMsgs?.action)?.sections;
        }

        const getOrderQuantity = (referenId: any) => {
          let Id;
          messages.map((m, index) => {
            if (m.componentMessage?.subType === "order_details") {
              if (
                referenId ===
                JSON.parse(m.componentMessage.action)?.parameters?.reference_id
              ) {
                Id = JSON.parse(m.componentMessage.action)
                  ?.parameters?.order?.items.map((e: any) => {
                    return e.quantity;
                  })
                  .reduce(
                    (accumulator: number, currentValue: number) =>
                      accumulator + currentValue,
                    0
                  );
              }
            }
          });
          return Id ? Id : "";
        };

        // const getTotal = (orderData: any) => {
        //   const { subtotal, tax, discount, shipping } = orderData;
        //   const subtotalAmount = subtotal.value / 100;
        //   const taxAmount = (tax?.value ?? 0) / 100;
        //   const discountAmount = (discount?.value ?? 0) / 100;
        //   const shippingAmount = (shipping?.value ?? 0) / 100;

        //   const totalAmount = subtotalAmount + taxAmount + shippingAmount - discountAmount;
        //   return totalAmount;
        // }
        const numberMask = localStorage.getItem('numberMask') === 'true';

        const componentMessage = (
          <>
            {botButtonMsgs?.subType === "button_message" ||
              botButtonMsgs?.subType === "list_message" ||
              botButtonMsgs?.subType === "flow" ||
              botButtonMsgs?.subType === "nfm_reply" ||
              botButtonMsgs?.subType === "order_details" ||
              botButtonMsgs?.subType === "product" ||
              m.messageType === "payment" ? (
              <>
                <div
                  className={`p-2 ${m.status === -1 && m.messageType !== "payment"
                    ? "failedMsgBg"
                    : "convBackground compMsgWidth"
                    }`}
                >
                  {botButtonMsgs?.subType !== "order_details" &&
                    botButtonMsgs?.subType !== "product" &&
                    m.messageType !== "payment" && (
                      <>
                        <div className="pl-1 pt-1">
                          {botButtonMsgs?.headerText}
                        </div>
                        <div className="pl-1 pt-1">{botButtonMsgs?.bodyText}</div>
                        <div className="pl-1 pt-1">
                          {botButtonMsgs?.footerText}
                        </div>
                      </>
                    )}

                  {botButtonMsgs?.subType === "order_details" && (
                    <>
                      <div className="paymentMessage">
                        <div className="gray mb-2">
                          {/* <span className=" m-0"> */}
                          <span className="small m-0">
                            {"ORDER" +
                              " " +
                              "#" +
                              action?.parameters?.reference_id}
                          </span>
                          {m.orderMessage?.order?.paymentStatus === 'paid' ? <span className="float-right mr-2"><span><FontAwesomeIcon icon={faCircleCheck} className="text-success mr-1" /></span>{m.orderMessage?.order?.paymentStatus}</span> : ''}
                          {/* </span> */}
                          <p className="line m-0">
                            -----------------------------------------------
                          </p>
                          <div className="d-flex">
                            <div>
                              {botButtonMsgs?.media?.url && (
                                <img
                                  src={botButtonMsgs?.media?.url}
                                  className="mr-2"
                                  alt="img"
                                  width={40}
                                  height={40}
                                ></img>
                              )}
                            </div>
                            <div>
                              <b className="m-0">{itemsName}</b>
                              {items.length === 1 ? (
                                <p className="m-0">
                                  Quantity{" "}
                                  {items.reduce(
                                    (accumulator: number, currentValue: any) => {
                                      return accumulator + currentValue.quantity;
                                    },
                                    0
                                  )}
                                </p>
                              ) : (
                                <p className="m-0">

                                  {items.reduce(
                                    (accumulator: number, currentValue: any) => {
                                      return accumulator + currentValue.quantity;
                                    },
                                    0
                                  )} {" "}
                                  items
                                </p>
                                // <p className="m-0">{items.length} items</p>
                              )}
                            </div>
                          </div>
                          {/* <p className="m-0">Quantity {items.length}</p> */}
                          {/* <p className="m-0">Quantity {items.reduce(
                                (accumulator: number, currentValue: any) => {
                                  return accumulator + currentValue.quantity;
                                },
                                0
                              )}</p> */}
                          <p className="line m-0">
                            ----------------------------------------------
                          </p>
                          <span>Total</span>
                          <span className="float-right mr-2 total">
                            ₹{action?.parameters?.total_amount?.value / 100}
                          </span>
                        </div>
                        <p className="m-0">{botButtonMsgs?.bodyText}</p>
                        <small>{botButtonMsgs?.footerText}</small>
                        <hr></hr>
                        <p
                          className="pay mb-1 cursor-pointer"
                          onClick={() => reviewOrder(index)}
                        >
                          Review and Pay
                        </p>
                        <hr></hr>
                        <p className="pay mb-1">Pay Now</p>
                      </div>
                      <Modall
                        isOpen={reviewModal[index]}
                        onClose={() => reviewOrder(index)}
                        size="md"
                        component={`revieworder`}
                        title={
                          <>
                            {channel?.label}
                            <span className="text-muted small ml-2">
                              (
                              {"OrderId" +
                                " " +
                                "#" +
                                action?.parameters?.reference_id}
                              )
                            </span>
                            {m.orderMessage?.order?.status === 'canceled' ? (
                              <span className="cancelledButton ml-2">
                                Canceled
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        }
                      >
                        <div>
                          <div className="mb-2">
                            {items.map((e: any) => (
                              <Row key={e.id} className="mb-1">
                                {botButtonMsgs?.media?.url &&
                                  items?.length === 1 ? (
                                  <Col md="1">
                                    {" "}
                                    <img
                                      src={botButtonMsgs?.media?.url}
                                      className="mr-2"
                                      alt="img"
                                      width={40}
                                      height={40}
                                    ></img>
                                  </Col>
                                ) : (
                                  <Col md="1">
                                    <img
                                      src={cart}
                                      width={40}
                                      height={40}
                                      alt="cart"
                                    />
                                  </Col>
                                )}
                                <Col className="ml-2 col-8">
                                  <b className="m-0 text-muted">{e?.name}</b>
                                  <p className="text-muted small">
                                    Quantity {" " + e.quantity}
                                  </p>
                                </Col>
                                <Col className="">
                                  <p className="nofont advanceValue m-0 ">
                                    ₹
                                    {e?.sale_amount
                                      ? (e?.sale_amount?.value / 100) * e.quantity
                                      : (e?.amount?.value / 100) * e.quantity}
                                  </p>
                                  <p className="nofont small advanceValue m-0 text-decoration-line-through">
                                    {e?.sale_amount
                                      ? "₹" +
                                      (e?.amount?.value / 100) * e.quantity
                                      : ""}
                                  </p>
                                </Col>
                              </Row>
                            ))}
                          </div>

                          <hr className="mb-2" />
                          <Row>
                            <Col className="bold">Subtotal</Col>
                            <Col className="advanceValue h-100">
                              ₹{advanceOption?.subtotal?.value / 100}
                            </Col>
                          </Row>
                          {advanceOption?.discount?.value ? (
                            <Row className="mt-1">
                              <Col className="bold">
                                Discount
                                {advanceOption?.discount?.description
                                  ? "(" +
                                  (advanceOption?.discount?.description?.length > 40 ? advanceOption?.discount?.description?.slice(0, 39) + '...' : advanceOption?.discount?.description) +
                                  ")"
                                  : ""}
                              </Col>
                              <Col className="advanceValue h-100">
                                - ₹{advanceOption?.discount?.value / 100}
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {advanceOption?.shipping?.value ? (
                            <Row className="mt-1">
                              <Col className="bold">
                                Shipping
                                {advanceOption?.shipping?.description
                                  ? "(" +
                                  (advanceOption?.shipping?.description?.length > 40 ? advanceOption?.shipping?.description?.slice(0, 39) + '...' : advanceOption?.shipping?.description) +
                                  ")"
                                  : ""}
                              </Col>
                              <Col className="advanceValue h-100">
                                ₹{advanceOption?.shipping?.value / 100}
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          {advanceOption?.tax?.value ? (
                            <Row className="mt-1">
                              <Col className="bold">
                                Tax
                                {advanceOption?.tax?.description
                                  ? "(" + (advanceOption?.tax?.description?.length > 40 ? advanceOption?.tax?.description?.slice(0, 39) + '...' : advanceOption?.tax?.description) + ")"
                                  : ""}
                              </Col>
                              <Col className="advanceValue h-100">
                                ₹{advanceOption?.tax?.value / 100}
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                          <hr className="mt-2" />
                          <Row className="mt-2">
                            <Col className="bold">Total</Col>
                            <Col className="advanceValue h-100">
                              ₹{action?.parameters?.total_amount?.value / 100}
                            </Col>
                          </Row>
                        </div>
                      </Modall>
                    </>
                  )}
                  {botButtonMsgs?.subType === "product" && (
                    <>
                      <div className="paymentMessage">
                        <div className="gray mb-2">
                          <div className="d-flex">
                            <div>
                              {section ? (
                                section[0]?.products[0]?.imageUrl ? (
                                  <img
                                    src={section[0]?.products[0]?.imageUrl}
                                    className="mr-2"
                                    alt="product"
                                    width={40}
                                    height={40}
                                  ></img>
                                ) : (
                                  <img
                                    src={cart}
                                    width={40}
                                    height={40}
                                    alt="cart"
                                  />
                                )
                              ) : (
                                <img
                                  src={cart}
                                  width={40}
                                  height={40}
                                  alt="cart"
                                />
                              )}
                            </div>
                            <div>
                              <b className="m-0">
                                {section ? section[0]?.title : ""}
                              </b>
                              <p className="m-0">
                                {section ? section[0]?.products?.length : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                        <p
                          className="pay mb-1"
                          onClick={() => productReview(index)}
                        >
                          View items
                        </p>
                      </div>
                      <Modall
                        isOpen={productMsg[index]}
                        onClose={() => productReview(index)}
                        size="md"
                        component={`revieworder`}
                        title={
                          <>
                            {channel?.label}
                            {/* <span className="text-muted small ml-2">
                            Products
                          </span> */}
                          </>
                        }
                      >
                        <div>
                          {section && (
                            <div className="mb-2">
                              {section[0]?.products.map((e: any) => (
                                <Row key={e.id} className="mb-1">
                                  {e ? (
                                    <Col md="1">
                                      {" "}
                                      <img
                                        src={e?.imageUrl}
                                        className="mr-2"
                                        alt="img"
                                        width={40}
                                        height={40}
                                      ></img>
                                    </Col>
                                  ) : (
                                    <Col md="1">
                                      <img
                                        src={cart}
                                        width={40}
                                        height={40}
                                        alt="cart"
                                      />
                                    </Col>
                                  )}
                                  <Col className="ml-2 col-8">
                                    <b className="m-0 text-muted">{e?.name}</b>
                                    <p className="m-0 text-muted small">
                                      {e?.description}
                                    </p>
                                  </Col>
                                  <Col>
                                    <p className="nofont advanceValue m-0 ">
                                      {e?.price}
                                    </p>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          )}
                        </div>
                      </Modall>
                    </>
                  )}
                  {m.paymentMessage && (
                    <div>
                      <div className="replyMsgBg small">
                        <img
                          src={boticon}
                          alt="botimg"
                          width={15}
                          height={15}
                          className="mr-1 mb-1"
                        />
                        bot
                        <div className="m-0">
                          Quantity{" "}
                          {getOrderQuantity(m.paymentMessage.referenceId)}
                          {/* <span className="mr-1 nofont float-right"> ₹{(m.paymentMessage?.totalAmount/100).toFixed(2)}
                    </span> */}
                        </div>
                      </div>
                      <b className="mr-1 nofont">
                        ₹{m.paymentMessage?.totalAmount / 100}
                      </b>
                      <span>
                        {m.paymentMessage?.status === "failed" ? (
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="text-danger"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success"
                          />
                        )}
                      </span>
                      <br />
                      <p className="m-0">
                        Your payment of{" "}
                        <span className="nofont">
                          ₹{m.paymentMessage?.totalAmount / 100}
                        </span>{" "}
                        {m.paymentMessage?.status === "failed"
                          ? m.paymentMessage?.status
                          : "received"}
                      </p>
                    </div>
                  )}
                  {(botButtonMsgs?.subType === "list_message" ||
                    botButtonMsgs?.subType === "nfm_reply" || botButtonMsgs?.subType === 'flow') && (
                      <>
                        <hr className="mt-2 mb-2" />
                        <div className="centerItems">
                          {botButtonMsgs?.messageName === "cta_url" ? (
                            <Link
                              to={
                                /^https?:\/\//.test(botButtonMsgs?.action[0]?.parameters?.url)
                                  ? botButtonMsgs?.action[0]?.parameters?.url
                                  : `http://${botButtonMsgs?.action[0]?.parameters?.url}`
                              }
                              target="_blank"
                              className="listButtons"
                            >
                              <FontAwesomeIcon icon={faUpRightFromSquare} />
                              <span className="pl-1">
                                {botButtonMsgs?.action[0]?.parameters?.display_text}
                              </span>
                            </Link>
                          ) : botButtonMsgs?.messageName === "address_message" ? (
                            <span className="listButtons">Provide Address</span>
                          ) : botButtonMsgs?.messageName ===
                            "location_request_message" ? (
                            <>
                              <FontAwesomeIcon
                                icon={faLocationDot}
                                className="listButtons"
                              />
                              <span className="pl-1 listButtons">
                                Send Location
                              </span>
                            </>
                          ) : botButtonMsgs?.subType === 'list_message' ? (
                            <FontAwesomeIcon
                              icon={faList}
                              className="listButtons"
                            />
                          ) : (
                            <div className="listButtons">
                              Flow Message
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  {m?.errorMessages && m?.errorMessages?.title}
                </div>
                <div>
                  {(botButtonMsgs?.subType === "button_message" ||
                    botButtonMsgs?.subType === "flow") &&
                    <div className="replyBtnsColor pl-2 pr-2">
                      {renderedButtons}
                    </div>
                  }
                </div>
              </>
            ) : (
              <SingleTemplate
                singleTempData={botButtonMsgs}
                component="conversation"
                selectedConversation={selectedConversation}
                status={m.status}
              />
            )}
          </>
        );
        const downloadImage = async (urls: any) => {
          setLoader(true);
          try {
            const response = await fetch(urls);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "downloaded_image.png";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setLoader(false);
          } catch (error) {
            setLoader(false);
            console.error("Error downloading image:", error);
          }
        };
        const messgs = (
          <>
            {m?.reactionMessages &&
              m.reactionMessages.length > 0 &&
              m.reactionMessages[0]?.emoji ? (
              <>
                {/* {m.reactionMessages.map((reaction: any, index: any) => (
                  <div
                    key={index}
                    className={`emoji-icon ${m.messageType === "component" ? "component-reaction" : ""
                      } ${index > 0 ? "ml-4" : ""}`}
                  >
                    {reaction.emoji}
                  </div>
                ))} */}
                {m?.messageType && (
                  <div
                    className={`${m?.messageType === "media"
                      ? "emoji-icon-img"
                      : "emoji-icon"
                      } ${m?.messageType === "component" &&
                        (m?.componentMessage?.media || m.errorMessages)
                        ? "component-reaction"
                        : m?.messageType === "component" &&
                          !m?.componentMessage?.media
                          ? "emoji-icon-notImg"
                          : ""
                      }`}
                  >
                    {m?.reactionMessages
                      ?.map((reaction: any) => reaction.emoji)
                      .join("") + " "}
                  </div>
                )}
              </>
            ) : (
              m?.reactionMessages[1]?.emoji || ""
            )}
            {m.messageType === "media" ? (
              m?.media?.mediaType?.includes("image") ? (
                <>
                  <div>
                    <img
                      className="convMediaImg"
                      src={m.media.url}
                      alt="messageImg"
                      onClick={() =>
                        handleImageClick(m.media.url, m.media.caption)
                      }
                    />
                    <div key={index}>
                      {m.media.caption && (
                        <div className="adjustCaption">
                          {m.media.caption.length < 400 || isExpanded[index]
                            ? m.media.caption
                            : `${m.media.caption.slice(0, 400)}... `}
                          {m.media.caption.length > 400 && (
                            <span
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() => toggleReadMore(index)}
                            >
                              {isExpanded[index] ? " Read less" : " Read more"}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedImage && modalShow && (
                    <Modall
                      isOpen={modalShow}
                      onClose={closeModal}
                      size="md"
                      component="msginput"
                      title={
                        loader ? (
                          <p className="small mb-0 loadertxt">Downloading...</p>
                        ) : (
                          <div
                            onClick={() => downloadImage(selectedImage)}
                            className="cursor-pointer downloadBtn mt-0"
                          >
                            <TooltipReference
                              placement="bottom"
                              content="Download"
                              tooltipId="download"
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 1024 1024"
                                aria-hidden="true"
                                focusable="false"
                                height="17"
                                width="17"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                              </svg>
                            </TooltipReference>
                          </div>
                        )
                      }
                    >
                      <img
                        src={selectedImage}
                        alt="modalImg"
                        height={430}
                        width={465}
                      />
                    </Modall>
                  )}
                </>
              ) : m.media?.mediaType?.includes("video") ? (
                <div>
                  <video
                    src={m.media.url}
                    className="convMediaImg"
                    controls
                  ></video>
                  <div key={index}>
                    {m.media.caption && (
                      <div className="adjustCaption">
                        {m.media.caption.length < 400 || isCaption[index]
                          ? m.media.caption
                          : `${m.media.caption.slice(0, 400)}... `}
                        {m.media.caption.length > 400 && (
                          <span
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => toggleVideoCaption(index)}
                          >
                            {isCaption[index] ? " Read less" : " Read more"}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : m.media?.mediaType?.includes("application/pdf") || m.media?.mediaType?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                || m.media?.mediaType?.includes('text/plain') || m.media?.mediaType?.includes('application/vnd.ms-powerpoint') || m.media?.mediaType?.includes('application/msword') || m.media?.mediaType?.includes('application/vnd.ms-excel')
                || m.media?.mediaType?.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || m.media?.mediaType?.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation') ? (
                <div>
                  <ConversationFile
                    fileName={m.media.originalName}
                    fileSize={convertFilesize(m.media.fileSize)}
                    url={m.media.url}
                  />
                  {m.media.caption && (
                    <div className="pl-1" title={m.media.caption}>
                      {m.media.caption}
                    </div>
                  )}
                </div>
              ) : m.media?.mediaType?.includes("audio") ? (
                <div>
                  <audio controls src={m.media.url} className="audioMsg" />
                </div>
              ) : (
                ""
              )
            ) : m.messageType === "text" ||
              m.messageType === "interactive" ||
              m.messageType === "button" ||
              m.messageType === "Reminder" ||
              m.messageType === "contacts" ? (
              <>
                {m?.contactMessage && (
                  <div className="m-0">
                    <div className="d-flex">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="circle-user"
                        className="svg-inline--fa fa-circle-user contacticon mr-1 h4 ml-1 mb-0"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"
                        ></path>
                      </svg>
                      <p className="inviteBtn">
                        {m?.contactMessage?.formattedName}
                      </p>
                    </div>
                    <hr />
                    <p className="inviteBtn mt-1 mb-0">Invite</p>
                  </div>
                )}
                {botButtonMsgs?.subType === "nfm_reply" ? (
                  <div>
                    {componentMessage}
                    {createdTime}
                  </div>
                ) : (
                  <div>
                    {m.textMessage?.text
                      ? renderMessage(
                        "",
                        m.textMessage?.text,
                        "convMessages",
                        m.status
                      )
                      : ""}
                    {createdTime}
                  </div>
                )}
              </>
            ) : m.messageType === "request_welcome" ? (
              <div className="visitedSite">
                <span>
                  <FontAwesomeIcon icon={faWhatsapp} className="ml-1" />
                </span>
                <span className="pl-1">Visited WhatsApp Business</span>
                {createdTime}
              </div>
            ) : m.messageType === "location" ? (
              <MapLoader>
                <div id="send-msg-map">
                  <GoogleMap
                    id="msg-map"
                    mapContainerStyle={{ width: "250px", height: "225px" }}
                    zoom={12}
                    center={{
                      lat: m.locationMessage.latitude,
                      lng: m.locationMessage.longitude,
                    }}
                    onClick={() =>
                      handleMapClick(
                        m.locationMessage.latitude,
                        m.locationMessage.longitude
                      )
                    }
                  >
                    <Marker
                      position={{
                        lat: m.locationMessage.latitude,
                        lng: m.locationMessage.longitude,
                      }}
                    />
                  </GoogleMap>
                </div>
              </MapLoader>
            ) : m.messageType === "component" || m.messageType === "payment" ? (
              componentMessage
            ) : m.messageType === "order" ? (
              <div className="selected_business_catalogue">
                {/* Right Side Bar for displaying added cart */}
                {selectedCartDetails[index] && (
                  <RightSidebar
                    handleCancel={() => handleCancel(index)}
                    title="Order Details"
                    width="425px"
                  >
                    <Formik
                      initialValues={{
                        order: selectedStatus?.label,
                        payment: selectedpayment?.label,
                        paymentobj: null,
                        header: "",
                        body: "",
                        footer: "",
                        duration: 5,
                        time: time[0],
                        taxAmt: null,
                        taxDesc: "",
                        shipAmt: null,
                        shipDec: "",
                        discountAmt: null,
                      }}
                      validationSchema={
                        selectedRow?.status === "order_request" &&
                          selectedStatus?.label === "pending" &&
                          paymentObj?.length > 0
                          ? validation
                          : ""
                      }
                      onSubmit={(e) => handleSubmit(e, index)}
                    >
                      {({ values, setFieldValue }) => (
                        <Form className="pl-3 pr-3 mt-3 ml-1">
                          <div className="orderDetail">
                            <Row>
                              <Col md="4">
                                <p>Order By</p>
                              </Col>
                              <Col md="8" className="d-flex">
                                {selectedRow.contact?.name ? (
                                  <Avatar
                                    fgColor="#fff"
                                    name={selectedRow.contact?.name}
                                    round
                                    size="27"
                                    textSizeRatio={3}
                                  />
                                ) : (
                                  ""
                                )}
                                <div className="ml-1 contact">
                                  <p className="m-0 text-dark">
                                    {selectedRow.contact?.name
                                      ? selectedRow.contact?.name
                                      : "-"}
                                  </p>
                                  <p className={`${numberMask ? "numberMasking_Blur" : ''} mt-0`}>
                                    {selectedRow.contact?.mobileNumber
                                      ? "+" +
                                      selectedRow.contact?.countryCode +
                                      " " +
                                      selectedRow.contact?.mobileNumber.slice(
                                        selectedRow.contact?.countryCode
                                          ?.length
                                      )
                                      : ""}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <p>Order From</p>
                              </Col>
                              <Col md="8" className="d-flex">
                                {channel?.label ? (
                                  <Avatar
                                    fgColor="#fff"
                                    name={channel?.label}
                                    round
                                    size="27"
                                    textSizeRatio={3}
                                  />
                                ) : (
                                  ""
                                )}
                                <div className="ml-1 contact">
                                  <p className="m-0 text-dark">
                                    {channel?.label ? channel?.label : "-"}
                                  </p>
                                  <p className="mt-0">
                                    OrderId {"  "}{" "}
                                    {selectedRow?.orderId
                                      ? "#" + selectedRow?.orderId
                                      : "-"}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4">
                                <p>Order Date</p>
                              </Col>
                              <Col md="8">
                                <p>
                                  {moment(selectedRow?.createdAt).format(
                                    "DD MMM YYYY, h:mm A"
                                  )}
                                </p>
                              </Col>
                            </Row>
                            {/* <Row>
                            <Col md='4'><p>Cart Id</p></Col>
                            <Col md='8'><p>{selectedRow?.orderId? selectedRow?.orderId : '-'}</p></Col>
                        </Row> */}
                            <Row>
                              <Col md="4">
                                <p>Reason</p>
                              </Col>
                              <Col md="8">
                                <p>
                                  {selectedRow.reason
                                    ? selectedRow.reason
                                    : "-"}
                                </p>
                              </Col>
                            </Row>
                            <hr />
                            {selectedRow.orderItems.map(
                              (ele: any, ind: any) => (
                                <>
                                  <div
                                    key={ind}
                                    className="d-flex orderRow mt-1 col-12 mr-3"
                                  >
                                    <div className="col-2">
                                      {ele.catalogueProduct?.imageUrl ? (
                                        <img
                                          src={ele.catalogueProduct?.imageUrl}
                                          alt="Product"
                                          width="40"
                                          height="40"
                                        />
                                      ) : (
                                        <img
                                          src={defaultImg}
                                          width={40}
                                          height={40}
                                          className="rouded"
                                        />
                                      )}{" "}
                                    </div>
                                    <div className="mr-5 col-7">
                                      <p className="text-dark mb-1">
                                        {ele?.catalogueProduct?.name}
                                      </p>
                                      <p className="mb-2 text-muted small">
                                        Quantity {ele?.quantity}
                                      </p>
                                    </div>
                                    <div className="col-3">
                                      <p className="mt-1">
                                        <span className="fontchange">
                                          {ele.catalogueProduct?.price
                                            ? ele.catalogueProduct?.price
                                            : "-"}</span>
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                            <hr />

                            <Row className="mt-3">
                              <Col md="4">
                                <p>Order Status</p>
                              </Col>
                              <Col md="8">
                                <p>
                                  {selectedStatus?.label
                                    ? selectedStatus?.label
                                      ?.charAt(0)
                                      .toUpperCase() +
                                    selectedStatus?.label?.slice(1)
                                    : "-"}
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    onClick={() => {
                                      if (
                                        selectedpayment?.label === "paid" ||
                                        selectedRow.paymentStatus === "paid" ||
                                        selectedStatus?.label === "new" ||
                                        selectedRow.status === "order_request"
                                      ) {
                                        setOrder(!order);
                                      }
                                    }}
                                    className={`${selectedpayment?.label === "paid" ||
                                      selectedRow.paymentStatus === "paid" ||
                                      selectedStatus?.label === "new" ||
                                      selectedRow.status === "order_request"
                                      ? "text-secondary"
                                      : "disableblue"
                                      } ml-2`}
                                  />
                                </p>
                              </Col>
                            </Row>
                            <div className="d-flex">
                              {order && (
                                <FormGroup className="pt-1 pb-1 ml-0 w-50">
                                  <Label htmlFor="order">
                                    Update Order Status
                                  </Label>
                                  <Field name="order">
                                    {({ field, form }: any) => {
                                      const selectedIndex =
                                        orderStatus.findIndex(
                                          (option) =>
                                            option.value === selectedRow?.status
                                        );
                                      return (
                                        <Select
                                          // options={orderStatus}
                                          options={orderStatus.map(
                                            (option, index) => ({
                                              ...option,
                                              // isDisabled:
                                              //   selectedRow?.status ===
                                              //   "order_request"
                                              //     ? index !== 1
                                              //     : selectedRow?.status ===
                                              //         "completed" ||
                                              //       selectedRow?.status ===
                                              //         "canceled"
                                              //     ? index !== selectedIndex
                                              //     : index <= selectedIndex,
                                              isDisabled:
                                                selectedRow?.status ===
                                                  "order_request"
                                                  ? index !== 1
                                                  : selectedRow?.status ===
                                                    "completed" ||
                                                    selectedRow?.status ===
                                                    "canceled"
                                                    ? index !== selectedIndex
                                                    : selectedRow?.status ===
                                                      "processing"
                                                      ? index !== 1 &&
                                                      index <= selectedIndex
                                                      : selectedRow?.status ===
                                                        "partially_shipped"
                                                        ? index === 0 &&
                                                        index <= selectedIndex
                                                        : selectedRow?.status ===
                                                          "shipped"
                                                          ? index === 0 &&
                                                          index <= selectedIndex
                                                          : index <= selectedIndex,
                                            })
                                          )}
                                          value={selectedStatus}
                                          onChange={(event) => {
                                            setFieldValue("order", event);
                                            handleStatus(event);
                                          }}
                                        />
                                      );
                                    }}
                                  </Field>
                                </FormGroup>
                              )}
                              {selectedRow?.status === "order_request" &&
                                selectedStatus?.label === "pending" &&
                                paymentObj.length > 0 && (
                                  <FormGroup className="pb-1 pt-1 ml-2 w-50">
                                    <Label>
                                      Configure Payment
                                      <span className="required"></span>
                                    </Label>
                                    <Field name="paymentobj">
                                      {({ field, form }: any) => (
                                        <Select
                                          options={paymentObj}
                                          value={values.paymentobj}
                                          onChange={(event) => {
                                            setFieldValue("paymentobj", event);
                                            setPayMethod(event);
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <ErrorMessage
                                      name="paymentobj"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </FormGroup>
                                )}
                            </div>
                            {selectedRow?.status === "order_request" &&
                              selectedStatus?.label === "pending" &&
                              paymentObj.length > 0 && (
                                <>
                                  <div>
                                    <FormGroup>
                                      <Label className="m-0">
                                        Header (optional){" "}
                                        <span className="small">
                                          Max file size: 5 MB
                                        </span>
                                      </Label>
                                      <p className="m-0 small">
                                        File type allowed : image/jpeg,image/png
                                      </p>
                                      {!media ? (
                                        <Field name="file">
                                          {({ field }: any) => (
                                            <div>
                                              <Label className="uploadFiles col-3">
                                                <input
                                                  type="file"
                                                  className="hideFileInput"
                                                  accept=".jpeg,.jpg,.png"
                                                  onChange={(event) => {
                                                    const file =
                                                      event.target.files;
                                                    field.onChange(event);
                                                    onFileChange(event);
                                                  }}
                                                />{" "}
                                                Upload File
                                              </Label>
                                            </div>
                                          )}
                                        </Field>
                                      ) : (
                                        <div>
                                          <img
                                            src={media.url}
                                            alt="img"
                                            width="80"
                                            height="80"
                                          ></img>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              removeFileHandler(media);
                                              setFieldValue("media", null);
                                            }}
                                            className="bg-white border-0 ml-3"
                                          >
                                            <BsFillTrashFill className="bg-white text-secondary h6" />
                                          </button>
                                        </div>
                                      )}
                                    </FormGroup>
                                    {/* <FormGroup>
                                      <Label> Header </Label>
                                      <Field
                                        type="text"
                                        name="header"
                                        id="header"
                                        placeholder="Enter header text"
                                        className="form-control"
                                        onChange={(event: any) => {
                                          let text = event.target.value;
                                          setFieldValue("header", text);
                                          setHeadCount(text.length);
                                        }}
                                      />
                                      <div className="float-right text-muted small">
                                        {headCount} / 60
                                      </div>
                                      <ErrorMessage
                                        name="header"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </FormGroup> */}
                                    <FormGroup>
                                      <Label>
                                        {" "}
                                        Message Body{" "}
                                        <span className="required"></span>
                                      </Label>
                                      {/* <Field type="text" name="body" id="body" placeholder="Enter message" className="form-control H-50" /> */}
                                      <Field name="messageBody">
                                        {({
                                          field,
                                        }: FieldProps & {
                                          form: FormikProps<any>;
                                        }) => (
                                          <>
                                            <div>
                                              <textarea
                                                ref={textareaRef}
                                                {...field}
                                                placeholder="Enter message"
                                                onChange={(event) => {
                                                  let text = event.target.value;
                                                  setFieldValue("body", text);
                                                  setBody(text);
                                                  setCount(text.length);
                                                }}
                                                className="form-control"
                                              />
                                            </div>
                                            <div className="float-right text-muted small">
                                              {charCount} / 1024
                                            </div>
                                          </>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="body"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label> Footer </Label>
                                      <Field
                                        type="text"
                                        name="footer"
                                        id="footer"
                                        placeholder="Enter footer text"
                                        className="form-control"
                                        onChange={(event: any) => {
                                          let text = event.target.value;
                                          setFieldValue("footer", text);
                                          setFooterCount(text.length);
                                        }}
                                      />
                                      <div className="float-right text-muted small">
                                        {footerCount} / 60
                                      </div>
                                      <ErrorMessage
                                        name="footer"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Label>Expiring Duration</Label>
                                      <div className="d-flex exduration p-1">
                                        <Field name="time">
                                          {({ field, form }: any) => (
                                            <Select
                                              options={time}
                                              value={values.time}
                                              className="col-6 mr-1"
                                              onChange={(event) => {
                                                setFieldValue("time", event);
                                              }}
                                            />
                                          )}
                                        </Field>
                                        <Field
                                          type="text"
                                          name="duration"
                                          id="duration"
                                          placeholder="Enter duration"
                                          className="form-control col-5"
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            let value = e.target.value.replace(
                                              /[^0-9.]/g,
                                              ""
                                            );
                                            value = value.replace(
                                              /^(\d*\.\d*)\..*$/,
                                              "$1"
                                            );
                                            setFieldValue("duration", value);
                                          }}
                                        />
                                      </div>
                                      <ErrorMessage
                                        name="duration"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </FormGroup>
                                    <div className="advancecard mb-3">
                                      <ValidationSettings
                                        initiallyOpen={isOpen}
                                        onCancel={() => {
                                          setValidateOpen(false);
                                        }}
                                        caption="Advanced (optional)"
                                        component="salesinbox"
                                      >
                                        <FormGroup className="mb-3">
                                          <div>
                                            <div className={styles.dropdown}>
                                              <button
                                                type="button"
                                                className={styles.dropbtn}
                                                onClick={() => {
                                                  setIsTaxVisible(
                                                    !isTaxVisible
                                                  );
                                                }}
                                              >
                                                Tax{" "}
                                                <span
                                                  className={styles.taxArrow}
                                                >
                                                  {isTaxVisible ? (
                                                    <FontAwesomeIcon
                                                      icon={faChevronDown}
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faChevronUp}
                                                    />
                                                  )}
                                                </span>
                                              </button>
                                              {isTaxVisible && (
                                                <div
                                                  className={
                                                    styles.dropdownContent
                                                  }
                                                >
                                                  <FormGroup>
                                                    <p className="labelSize">
                                                      Tax Amount
                                                    </p>
                                                    <Field
                                                      type="text"
                                                      id="taxAmt"
                                                      className="form-control"
                                                      name="taxAmt"
                                                      onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                      ) => {
                                                        let value =
                                                          e.target.value.replace(
                                                            /[^0-9.]/g,
                                                            ""
                                                          );
                                                        value = value.replace(
                                                          /^(\d*\.\d*)\..*$/,
                                                          "$1"
                                                        );
                                                        setFieldValue(
                                                          "taxAmt",
                                                          value
                                                        );
                                                      }}
                                                      placeholder="Enter tax amount"
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <p className="labelSize">
                                                      Tax Description
                                                    </p>
                                                    <Field
                                                      type="text"
                                                      id="taxDesc"
                                                      className="form-control"
                                                      name="taxDesc"
                                                      onChange={(
                                                        event: any
                                                      ) => {
                                                        let text =
                                                          event.target.value;
                                                        setFieldValue(
                                                          "taxDesc",
                                                          text
                                                        );
                                                        setTax(text.length);
                                                      }}
                                                      disabled={
                                                        !values.taxAmt ||
                                                        values.taxAmt <= 0
                                                      }
                                                      placeholder="Enter tax description"
                                                    />
                                                    <div className="float-right text-muted small">
                                                      {taxCount} / 60
                                                    </div>
                                                    <ErrorMessage
                                                      name="taxDesc"
                                                      component="div"
                                                      className="text-danger"
                                                    />
                                                  </FormGroup>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                          <div>
                                            <div className={styles.dropdown}>
                                              <button
                                                type="button"
                                                className={styles.dropbtn}
                                                onClick={() =>
                                                  toggleTaxForm("shipping")
                                                }
                                              >
                                                Shipping{" "}
                                                <span className={styles.arrow}>
                                                  {isShippingVisible ? (
                                                    <FontAwesomeIcon
                                                      icon={faChevronDown}
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faChevronUp}
                                                    />
                                                  )}
                                                </span>
                                              </button>
                                              {isShippingVisible && (
                                                <div
                                                  className={
                                                    styles.dropdownContent
                                                  }
                                                >
                                                  <FormGroup>
                                                    <p className="labelSize">
                                                      Shipping Amount
                                                    </p>
                                                    <Field
                                                      type="text"
                                                      id="shipAmt"
                                                      className="form-control"
                                                      name="shipAmt"
                                                      onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                      ) => {
                                                        let value =
                                                          e.target.value.replace(
                                                            /[^0-9.]/g,
                                                            ""
                                                          );
                                                        value = value.replace(
                                                          /^(\d*\.\d*)\..*$/,
                                                          "$1"
                                                        );
                                                        setFieldValue(
                                                          "shipAmt",
                                                          value
                                                        );
                                                      }}
                                                      placeholder="Enter shipping amount"
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <p className="labelSize">
                                                      Shipping Description
                                                    </p>
                                                    <Field
                                                      type="text"
                                                      id="shipDec"
                                                      className="form-control"
                                                      name="shipDec"
                                                      onChange={(
                                                        event: any
                                                      ) => {
                                                        let text =
                                                          event.target.value;
                                                        setFieldValue(
                                                          "shipDec",
                                                          text
                                                        );
                                                        setShip(text.length);
                                                      }}
                                                      disabled={
                                                        !values.shipAmt ||
                                                        values.shipAmt <= 0
                                                      }
                                                      placeholder="Enter shipping description"
                                                    />
                                                    <div className="float-right text-muted small">
                                                      {shipCount} / 60
                                                    </div>
                                                    <ErrorMessage
                                                      name="shipDec"
                                                      component="div"
                                                      className="text-danger"
                                                    />
                                                  </FormGroup>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                          <div>
                                            <div className={styles.dropdown}>
                                              <button
                                                type="button"
                                                className={styles.dropbtn}
                                                onClick={() =>
                                                  toggleTaxForm("discount")
                                                }
                                              >
                                                Discount{" "}
                                                <span className={styles.arrow}>
                                                  {isDiscountVisible ? (
                                                    <FontAwesomeIcon
                                                      icon={faChevronDown}
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faChevronUp}
                                                    />
                                                  )}
                                                </span>
                                              </button>
                                              {isDiscountVisible && (
                                                <div
                                                  className={
                                                    styles.dropdownContent
                                                  }
                                                >
                                                  <FormGroup>
                                                    <p className="labelSize">
                                                      Discount Amount
                                                    </p>
                                                    <Field
                                                      type="text"
                                                      id="dicountAmt"
                                                      className="form-control"
                                                      name="discountAmt"
                                                      onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                      ) => {
                                                        let value =
                                                          e.target.value.replace(
                                                            /[^0-9.]/g,
                                                            ""
                                                          );
                                                        value = value.replace(
                                                          /^(\d*\.\d*)\..*$/,
                                                          "$1"
                                                        );
                                                        setFieldValue(
                                                          "discountAmt",
                                                          value
                                                        );
                                                      }}
                                                      placeholder="Enter discount amount"
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <p className="labelSize">
                                                      Discount Description
                                                    </p>
                                                    <Field
                                                      type="text"
                                                      id="discountDesc"
                                                      className="form-control"
                                                      name="discountDesc"
                                                      onChange={(
                                                        event: any
                                                      ) => {
                                                        let text =
                                                          event.target.value;
                                                        setFieldValue(
                                                          "discountDesc",
                                                          text
                                                        );
                                                        setDiscount(
                                                          text.length
                                                        );
                                                      }}
                                                      disabled={
                                                        !values.discountAmt ||
                                                        values.discountAmt <= 0
                                                      }
                                                      placeholder="Enter discount description"
                                                    />
                                                    <div className="float-right text-muted small">
                                                      {discountCount} / 60
                                                    </div>
                                                    <ErrorMessage
                                                      name="discountDesc"
                                                      component="div"
                                                      className="text-danger"
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <p className="labelSize">
                                                      Discount Program Name
                                                    </p>
                                                    <Field
                                                      type="text"
                                                      id="discountName"
                                                      className="form-control"
                                                      name="discountName"
                                                      onChange={(
                                                        event: any
                                                      ) => {
                                                        let text =
                                                          event.target.value;
                                                        setFieldValue(
                                                          "discountName",
                                                          text
                                                        );
                                                        setName(text.length);
                                                      }}
                                                      disabled={
                                                        !values.discountAmt ||
                                                        values.discountAmt <= 0
                                                      }
                                                      placeholder="Enter discount name"
                                                    />
                                                    <div className="float-right text-muted small">
                                                      {nameCount} / 60
                                                    </div>
                                                    <ErrorMessage
                                                      name="discountName"
                                                      component="div"
                                                      className="text-danger"
                                                    />
                                                  </FormGroup>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </FormGroup>
                                      </ValidationSettings>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                          {/* <hr /> */}

                          {selectedRow?.status !== "order_request" ? (
                            <>
                              <hr />
                              <div className="mt-3">
                                <b>Payment Details</b>
                                <Row className="mt-3">
                                  <Col md="5">
                                    <p>Payment Status :</p>
                                  </Col>
                                  <Col md="7">
                                    <p>
                                      {selectedpayment?.label
                                        ? selectedpayment?.label
                                          ?.charAt(0)
                                          .toUpperCase() +
                                        selectedpayment?.label?.slice(1)
                                        : ""}
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => {
                                          if (
                                            selectedRow?.status !==
                                            "canceled" &&
                                            selectedStatus?.label !== "canceled"
                                          ) {
                                            setPay(!pay);
                                          }
                                        }}
                                        className={`ml-2 ${selectedRow?.status === "canceled" ||
                                          selectedStatus?.label === "canceled"
                                          ? "disableblue"
                                          : "text-secondary"
                                          }`}
                                      />
                                    </p>
                                  </Col>
                                </Row>
                                {pay && (
                                  <FormGroup className="pt-1 pb-1 ml-0 w-50">
                                    <Label htmlFor="payment">
                                      Update payment status{" "}
                                      {selectedRow?.paymentStatus === "paid" ? (
                                        <FontAwesomeIcon
                                          icon={faInfoCircle}
                                          className="text-muted ml-1 mb-0"
                                          title="Payment received, status cannot change"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Label>
                                    <Field name="payment">
                                      {({ field, form }: any) => (
                                        <Select
                                          // options={paymentStatus}
                                          options={paymentStatus.map(
                                            (option, index) => ({
                                              ...option,
                                              isDisabled:
                                                selectedRow?.paymentStatus ===
                                                  "paid"
                                                  ? index !== 2
                                                  : "",
                                            })
                                          )}
                                          value={selectedpayment}
                                          onChange={(event) => {
                                            setFieldValue("payment", event);
                                            handlePayment(event);
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </FormGroup>
                                )}
                                <Row>
                                  <Col md="5">
                                    <p>Paid Amount :</p>
                                  </Col>
                                  <Col md="7">
                                    <p>
                                      {selectedRow.paidAmount
                                        ? rupeesToPaisa(selectedRow.paidAmount)
                                        : "-"}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="5">
                                    <p>Payment Method :</p>
                                  </Col>
                                  <Col md="7">
                                    <p>{selectedRow.paymentConfig?.method}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="5">
                                    <p>Transaction Id :</p>
                                  </Col>
                                  <Col md="7">-</Col>
                                </Row>
                              </div>{" "}
                            </>
                          ) : (
                            ""
                          )}
                          <div className="d-flex float-right">
                            <Button
                              type="button"
                              className="cancelButton closeBtn"
                              onClick={() => handleCancel(index)}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="sendButton closeBtn"
                              // disabled={selectedRow?.status === selectedStatus?.label}
                              disabled={
                                selectedStatus?.value === selectedRow.status &&
                                selectedpayment?.value ===
                                selectedRow.paymentStatus
                              }
                            >
                              Apply
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </RightSidebar>
                )}

                <div className="display-cart">
                  <Modall
                    isOpen={viewCartModals[index]}
                    onClose={() => toggleViewCartModal(index)}
                    size="md"
                    component={`errMess`}
                    title={"Your Sent Cart"}
                  >
                    <Row>
                      <>
                        <Col lg="12" className="fw-bold">
                          <h6 className="d-flex">
                            <div className="me-1">
                              {m.orderMessage?.order?.orderItems.reduce(
                                (accumulator: number, currentValue: any) => {
                                  return accumulator + currentValue.quantity;
                                },
                                0
                              )}
                            </div>
                            <div>
                              {m.orderMessage?.order?.orderItems.reduce(
                                (accumulator: number, currentValue: any) => {
                                  return accumulator + currentValue.quantity;
                                },
                                0
                              ) === 1
                                ? "item"
                                : "items"}
                            </div>
                          </h6>
                        </Col>
                        {m?.orderMessage?.order?.orderItems?.map(
                          (item: any) => (
                            <>
                              <Col
                                lg="8"
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  marginTop: "6px",
                                  marginBottom: "6px",
                                }}
                              >
                                <div className="d-flex">
                                  <div className="me-1">
                                    {
                                      <img
                                        src={
                                          item.type === "digitalItems"
                                            ? defaultCatalogueImg
                                            : item.catalogueProduct.imageUrl
                                        }
                                        alt=""
                                        style={{
                                          height: "2.5rem",
                                          width: "2.7rem",
                                          borderRadius: "8px",
                                        }}
                                      />
                                    }
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {item.type === "digitalItems"
                                      ? item.digitalItem.name
                                      : item.catalogueProduct.name}
                                  </div>
                                </div>
                              </Col>
                              <Col
                                lg="4"
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  marginTop: "6px",
                                  marginBottom: "6px",
                                }}
                              >
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <div className="d-flex justify-content-end align-items-center text-muted">
                                      <LiaRupeeSignSolid />
                                      {item.type === "digitalItems"
                                        ? (
                                          (item.digitalItem.amount / 100) *
                                          item.quantity
                                        ).toLocaleString("en-IN")
                                        : (
                                          (item.amount / 100) *
                                          item.quantity
                                        ).toLocaleString("en-IN")}
                                    </div>
                                    <div>Quantity {item.quantity}</div>
                                  </div>
                                </div>
                              </Col>
                            </>
                          )
                        )}

                        <Col lg="6" className="mt-2">
                          <div>
                            <div>Subtotal</div>
                            <small>
                              {moment(m.orderMessage?.order?.createdAt).format(
                                "DD MMMM YYYY [at] h:mm A"
                              )}
                            </small>
                          </div>
                        </Col>
                        <Col lg="6" className="mt-2">
                          <div className="d-flex justify-content-end align-items-center h-100">
                            <LiaRupeeSignSolid />
                            {m?.orderMessage?.order?.orderItems?.reduce((acc: number, item: any) => {
                              if (item?.type === "digitalItems") {
                                return acc + (item?.digitalItem?.amount / 100) * item?.quantity;
                              }
                              return acc + (item?.amount / 100) * item?.quantity;
                            }, 0).toLocaleString("en-IN")}
                            (estimated)
                          </div>
                        </Col>
                      </>
                    </Row>
                    {m.orderMessage?.order?.status === "order_request" &&
                      !continueBtn[index] && (
                        <ModalFooter className="p-0">
                          <div className="err_btn">
                            <button
                              onClick={() => {
                                setViewCartModals((prevModals) => {
                                  const newModal = [...prevModals];
                                  newModal[index] = false;
                                  return newModal;
                                });
                                setSelectedCartDetails((prevRightBar) => {
                                  const newRightBar = [...prevRightBar];
                                  newRightBar[index] = true;
                                  return newRightBar;
                                });

                                handleRowClick(m.orderMessage.order);
                              }}
                              style={{ color: "white" }}
                            >
                              Continue
                            </button>
                          </div>
                        </ModalFooter>
                      )}
                  </Modall>
                </div>
                <div>
                  <div className="business_cart">
                    <div className="cart_img">
                      {m.orderMessage?.order?.orderItems[0]?.type ===
                        "digitalItems" ? (
                        <img src={defaultCatalogueImg} alt="" />
                      ) : (
                        <img
                          src={
                            m.orderMessage?.order?.orderItems[0]
                              ?.catalogueProduct?.imageUrl
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div className="cart_items ms-1 mt-0">
                      <div className="mt-0">
                        <div className="no_of_items d-flex">
                          <div className="me-1">
                            <IoCart />
                          </div>
                          <div className="me-1">
                            {m.orderMessage?.order?.orderItems.reduce(
                              (accumulator: number, currentValue: any) => {
                                return accumulator + currentValue.quantity;
                              },
                              0
                            )}
                          </div>
                          <div>
                            {m.orderMessage?.order?.orderItems.reduce(
                              (accumulator: number, currentValue: any) => {
                                return accumulator + currentValue.quantity;
                              },
                              0
                            ) === 1
                              ? "item"
                              : "items"}
                          </div>
                        </div>
                        <div className="estimated_total">
                          <LiaRupeeSignSolid />
                          {/* {m.orderMessage?.order?.totalAmount
                            ? (
                              m.orderMessage?.order?.totalAmount / 100
                            ).toLocaleString("en-IN")
                            : 0}{" "} */}
                          {m?.orderMessage?.order?.orderItems?.reduce((acc: number, item: any) => {
                            if (item?.type === "digitalItems") {
                              return acc + (item?.digitalItem?.amount / 100) * item?.quantity;
                            }
                            return acc + (item?.amount / 100) * item?.quantity;
                          }, 0).toLocaleString("en-IN")}

                          (estimated total)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="break mt-3">
                    <hr></hr>
                  </div>
                  <div
                    className="view"
                    onClick={() => {
                      setViewCartModals((prevModals) => {
                        const newModals = [...prevModals];
                        newModals[index] = true;
                        return newModals;
                      });
                    }}
                  >
                    View sent cart
                  </div>
                </div>
              </div>
            ) : null}
            <div></div>
          </>
        );
        try {
          return (
            <>
              {dayTimeline}
              <div>
                {m.messageType === "unsupported" ? (
                  <div className="d-flex">
                    <div
                      className="unSupportedMsgReason"
                      onClick={() => {
                        setUnsupportedModals((prevModals) => {
                          const newModals = [...prevModals];
                          newModals[index] = true;
                          return newModals;
                        });
                      }}
                    >
                      <TooltipReference
                        placement="right"
                        // content="Meta Error. Click to know more"
                        content={`Error code: ${m.errorCode}. Click to know more`}
                        tooltipId={`errMsgReason${index}`}
                        colorVariant="Danger" // Use colorVariant prop
                      >
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          // color="red"
                          className="mr-1"
                        />
                      </TooltipReference>
                    </div>
                    <div className={styles.unsupportedMessage}>
                      <div className="unsupportedText">
                        Unsupported message type
                      </div>
                    </div>

                    <div className="unsupportedMessage">
                      <Modall
                        isOpen={unSupportedModals[index]}
                        onClose={() => toggleUnSupportedModal(index)}
                        size="md"
                        component={`unsupportedMessage`}
                        title={"Message Info"}
                      >
                        <Row>
                          <Col
                            md={6}
                            style={{
                              marginTop: "6px",
                              marginBottom: "6px",
                            }}
                          >
                            Error Code
                          </Col>
                          <Col
                            md={6}
                            style={{
                              marginTop: "6px",
                              marginBottom: "6px",
                            }}
                          >
                            : {m.errorCode}
                          </Col>
                          <Col
                            md={6}
                            style={{
                              marginTop: "6px",
                              marginBottom: "6px",
                            }}
                          >
                            Error Message
                          </Col>
                          <Col
                            md={6}
                            style={{
                              marginTop: "6px",
                              marginBottom: "6px",
                            }}
                          >
                            : {m.errorMessage}
                          </Col>
                        </Row>
                        <ModalFooter className="p-0">
                          <div className="err_btn">
                            <button>
                              <a
                                href="https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/"
                                target="_blank"
                              >
                                Learn more
                              </a>
                            </button>
                          </div>
                        </ModalFooter>
                      </Modall>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                style={{
                  cursor: "default",
                  display: m.messageType === "unsupported" ? "none" : "block",
                }}
                // ${m.status === -1 ? "failedMsgBg" : ""} -- removed from classname 
                className={`messageContainer ${m?.reactionMessages.length ? "mb-3" : ""
                  } ${m.messageType === "payment" ? "" : "messagesDesign"} ${m.direction === 0
                    ? m.messageType === "payment"
                      ? "incomingMsg bg-transparent"
                      : "incomingMsg"
                    : "outgoingMsg"
                  } ${m.note
                    ? "notesBgsent"
                    : m.messageType === "component"
                      ? "templateBg"
                      : ""
                  }
                    ${index !== 0 ? "mt-2" : ""} ${m.messageType === "text" ||
                    m.messageType === "interactive" ||
                    m.messageType === "button" ||
                    m.messageType === "Reminder"
                    ? "textMsgLength"
                    : ""
                  } 
                 
                                    ${m.messageType === "component"
                    ? "tempWidth"
                    : ""
                  } ${index === messages.length - 1 ? "mb-2" : ""
                  }`}
              >
                {m.direction === 1 && m.status === -1 && (
                  <>
                    <Modall
                      isOpen={modals[index]}
                      onClose={() => toggleModal(index)}
                      size="md"
                      component={`errMess`}
                      title={"Message info"}
                    >
                      <Row className="err_mess_row">
                        {m.componentMessage?.type === "TEMPLATE" && (
                          <>
                            {/* <Col lg="4">Message Source</Col>
                            <Col lg="1">:</Col>
                            <Col lg="7">
                              <div className="failed-message">
                                {selectedMember.value === null ? (
                                  <>-</>
                                ) : (
                                  <>
                                    <div className="failed-avatar">
                                      {selectedMember.label.charAt(0)}
                                    </div>
                                    <div className="failed-label">
                                      {selectedMember.label}
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col> */}
                            <Col lg="4">Message Template</Col>
                            <Col lg="1">:</Col>
                            <Col lg="7">
                              <div className="me-2">
                                {m.componentMessage.messageName}
                              </div>
                              <div>
                                {m.componentMessage.messageName && (
                                  <TooltipReference
                                    placement="bottom"
                                    content={
                                      copiedUid ===
                                        m.componentMessage.messageName
                                        ? "Copied"
                                        : `Copy template name`
                                    }
                                    tooltipId={`copy-to-clipboard_${m.componentMessage.messageName}`}
                                  >
                                    <IoCopyOutline
                                      className="copy-icon me-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleCopy(
                                          m.componentMessage.messageName
                                        )
                                      }
                                    />
                                  </TooltipReference>
                                )}
                              </div>
                            </Col>
                          </>
                        )}

                        <Col lg="4">Message Status</Col>
                        <Col lg="1">:</Col>
                        <Col lg="7">
                          <div className="mess_status">Error</div>
                        </Col>
                        <Col lg="4">Error Code</Col>
                        <Col lg="1">:</Col>
                        <Col lg="7">{m.errorCode}</Col>
                        <Col lg="4">Error Message</Col>
                        <Col lg="1">:</Col>
                        <Col lg="7">{m.errorMessage}</Col>
                      </Row>
                      <ModalFooter className="p-0">
                        <div className="err_btn">
                          <button>
                            <a
                              href="https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/"
                              target="_blank"
                            >
                              Learn more
                            </a>
                          </button>
                        </div>
                      </ModalFooter>
                    </Modall>

                    <div
                      className="errMsgReason"
                      // title={m.errorCode + " : " + m.errorMessage}
                      onClick={() => {
                        setModals((prevModals) => {
                          const newModals = [...prevModals];
                          newModals[index] = true;
                          return newModals;
                        });
                      }}
                    >
                      <TooltipReference
                        placement="left"
                        // content="Meta Error. Click to know more"
                        content={`Error code: ${m.errorCode}. Click to know more`}
                        tooltipId={`errMsgReason${index}`}
                        colorVariant="Danger" // Use colorVariant prop
                      >
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          className="mr-1"
                        />
                      </TooltipReference>
                      {m.errorCode === 131049 &&
                        <TooltipReference placement="left" content={isInvalidAccess ? "You don't have access to retry this message" :
                          accountExpiry ? "Your account is temporarily suspended so you can't retry this message" :
                            'Error in sending the message. Please click Retry'}
                          tooltipId={`retryError-${index}`}>
                          <FontAwesomeIcon icon={faRedo} className="retryErrMMsg"
                            onClick={(event) => {
                              if (!accountExpiry && !isInvalidAccess) {
                                event.stopPropagation();
                                !disabledRetries[index] && handleRetryClick(index, m);
                              }
                            }}
                            style={{
                              pointerEvents: disabledRetries[index] || accountExpiry || isInvalidAccess ? "none" : "auto",
                              opacity: disabledRetries[index] || accountExpiry || isInvalidAccess ? 0.5 : 1,
                            }} />
                        </TooltipReference>
                      }
                    </div>
                  </>
                )}
                {m.replyMessageId ? (
                  <>
                    <ReplyMessageComponent
                      replyMessageId={m.replyMessageId}
                      message={m}
                      selectedConversation={selectedConversation}
                      allBots={allBots}
                    />
                    {messgs}
                    {m.messageType !== "request_welcome" &&
                      m.status !== -1 &&
                      selectedConversation?.remainingTime > 0 &&
                      !selectedConversation?.contact?.isDeleted && (
                        <div
                          className={
                            m.direction == 0
                              ? "reaction-icons"
                              : "reaction-icons-left"
                          }
                          style={{ cursor: isInvalidAccess ? "not-allowed" : "auto" }}
                        >
                          <div
                            className={`reaction-icon ${isInvalidAccess ? "disabledState" : ""}  ${iconVisibility[index]
                              ? "reactionIconsVisible"
                              : ""
                              }`}
                          >
                            <FontAwesomeIcon
                              id={`popover${index}`}
                              icon={faReply}
                              className="mt-1"
                              color="#90939F"
                              onClick={(e) =>
                                handleReply(
                                  e,
                                  m.messageType === "location"
                                    ? "location"
                                    : m.messageType === "media" &&
                                      m.media?.mediaType?.includes("image")
                                      ? "photo"
                                      : m.messageType === "media" &&
                                        m.media?.mediaType.includes("video")
                                        ? "video"
                                        : m.messageType === "component"
                                          ? getTextBasedOnSubType(
                                            m.componentMessage?.subType
                                          )
                                          : m.textMessage?.text,
                                  index,
                                  m.conversation.contact?.name,
                                  m.messageId
                                )
                              }
                            />
                            <button
                              id={`pop${index}`}
                              type="button"
                              className="hideBorder ml-1  bg-transparent"
                              onClick={(e) => {
                                handleButtonClick(e);
                              }}
                            >
                              <FontAwesomeIcon icon={faSmile} color="#90939F" />
                            </button>
                            <div>
                              <Popover
                                isOpen={
                                  popoverEmoji &&
                                  popovertarget ==
                                  document.getElementById(`pop${index}`)
                                }
                                target={popovertarget || `pop${index}`}
                                toggle={() => {
                                  // setPopoverOpen(false);
                                  popoverEmoji = false;
                                  setpopovertarget(null);
                                }}
                                placement="left"
                                className="emoji-popover"
                              // style={{ width: "100px", height: "100px", marginRight: '60px' }}
                              >
                                <PopoverBody>
                                  <div ref={pickerRef}>
                                    {" "}
                                    {/* wrap Picker with a div and assign the ref here  */}
                                    <Picker
                                      data={customData}
                                      onEmojiSelect={(e: any) => {
                                        ((currentMessageId) => {
                                          handleEmojiSelect(
                                            e.native,
                                            currentMessageId
                                          );
                                        })(m.messageId);
                                      }}
                                    />
                                  </div>
                                </PopoverBody>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                ) : (
                  <div className="messageContainer">
                    {messgs}
                    {m.messageType !== "request_welcome" &&
                      m.status !== -1 &&
                      selectedConversation?.remainingTime > 0 &&
                      !selectedConversation?.contact?.isDeleted && (
                        <div
                          className={
                            m.direction == 0
                              ? "reaction-icons"
                              : "reaction-icons-left"
                          }
                          style={{ cursor: isInvalidAccess ? "not-allowed" : "auto" }}
                        >
                          <div
                            className={`reaction-icon ${isInvalidAccess ? "disabledState" : ""} ${iconVisibility[index]
                              ? "reactionIconsVisible"
                              : ""
                              }`}
                          >
                            <FontAwesomeIcon
                              id={`popover${index}`}
                              icon={faReply}
                              className="mt-1"
                              color="#90939F"
                              onClick={(e) =>
                                handleReply(
                                  e,
                                  m.messageType === "location"
                                    ? "location"
                                    : m.messageType === "media" &&
                                      m.media?.mediaType?.includes("image")
                                      ? "photo"
                                      : m.messageType === "media" &&
                                        m.media?.mediaType.includes("video")
                                        ? "video"
                                        : m.messageType === "component"
                                          ? getTextBasedOnSubType(
                                            m.componentMessage?.subType
                                          )
                                          : m.textMessage?.text,
                                  index,
                                  m.conversation.contact?.name,
                                  m.messageId
                                )
                              }
                            />
                            <button
                              id={`pop${index}`}
                              type="button"
                              className="hideBorder ml-1  bg-transparent"
                              onClick={(e) => {
                                handleButtonClick(e);
                              }}
                            >
                              <FontAwesomeIcon icon={faSmile} color="#90939F" />
                            </button>
                            <div>
                              <Popover
                                isOpen={
                                  popoverEmoji &&
                                  popovertarget ==
                                  document.getElementById(`pop${index}`)
                                }
                                target={popovertarget || `pop${index}`}
                                toggle={() => {
                                  // setPopoverOpen(false);
                                  popoverEmoji = false;
                                  setpopovertarget(null);
                                }}
                                placement="left"
                                className="emoji-popover"
                              >
                                <PopoverBody>
                                  <div ref={pickerRef}>
                                    {" "}
                                    {/* wrap Picker with a div and assign the ref here  */}
                                    <Picker
                                      data={customData}
                                      onEmojiSelect={(e: any) => {
                                        ((currentMessageId) => {
                                          handleEmojiSelect(
                                            e.native,
                                            currentMessageId
                                          );
                                        })(m.messageId);
                                      }}
                                    />
                                  </div>
                                </PopoverBody>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      )}
                    {/* {m.reactionMessages[?  (
                                                
                                                // {m.reactionMessages[0].emoji}
                                                
                                            ):''

                                            } */}
                  </div>
                )}

                {m.messageType !== "text" &&
                  m.messageType !== "interactive" &&
                  m.messageType !== "button" &&
                  m.messageType !== "Reminder" &&
                  m.messageType !== "request_welcome" &&
                  m.messageType !== "contacts" && (
                    <div className="createdTime">
                      <span>{moment(m.createdAt).format("hh:mm A")}</span>
                      {m.direction === 1 && !m.note && (
                        <>
                          {m.status === 1 && (
                            <span className="pl-1 statusDesign">&#x23F0;</span>
                          )}
                          {m.status === 2 && (
                            <span className="pl-1 statusDesign">&#x2713;</span>
                          )}
                          {m.status === 3 && (
                            <span className="pl-1 doubleTick statusDesign">
                              &#x2713;&#x2713;
                            </span>
                          )}
                          {m.status === 4 && (
                            <span className="pl-1 deliveredTick doubleTick statusDesign">
                              &#x2713;&#x2713;
                            </span>
                          )}
                          {m.assignTo === 4 && (
                            <span className="ml-1 mb-2">
                              <img
                                src={campaign_icon}
                                height={18.5}
                                width={18.5}
                              />
                            </span>
                          )}
                          {m.assignTo === 5 && (
                            <span title="Sequence">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="side-menu__icon"
                                height="1.5em"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
                                  fill="#6a6b6c"
                                />
                              </svg>
                            </span>
                          )}
                          {m.assignTo === 1 && (
                            <span>
                              <img
                                src={boticon}
                                alt="botimg"
                                width={18}
                                height={18}
                                className="ml-1 mb-1"
                              />
                              <span
                                className="botName"
                                title={allBots.get(m.assignId) || ""}
                              >
                                {m.assignId
                                  ? allBots
                                    .get(m.assignId)
                                    ?.slice(0, 1)
                                    .toUpperCase()
                                  : // getBotName(m.assignId)?.slice(0, 1).toUpperCase()
                                  ""}
                              </span>
                            </span>
                          )}
                          {m.assignId &&
                            m.assignTo !== 1 &&
                            m.assignTo !== 4 &&
                            m.assignTo !== 5 &&
                            (index === 0 ? (
                              <TooltipReference
                                placement="bottom"
                                content={mappedMemberData.get(m.assignId) || ""}
                                tooltipId={`reminderName`}
                              >
                                <span
                                  className="reminderName"
                                // title={mappedMemberData.get(m.assignId) || ""}
                                >
                                  {mappedMemberData
                                    .get(m.assignId)
                                    ?.slice(0, 1)
                                    .toUpperCase()}
                                </span>
                              </TooltipReference>
                            ) : messages[index - 1]?.assignId !== m.assignId ? (
                              <TooltipReference
                                placement="bottom"
                                content={mappedMemberData.get(m.assignId) || ""}
                                tooltipId={`reminderName`}
                              >
                                <span
                                  className="reminderName"
                                // title={mappedMemberData.get(m.assignId) || ""}
                                >
                                  {mappedMemberData
                                    .get(m.assignId)
                                    ?.slice(0, 1)
                                    .toUpperCase()}
                                </span>
                              </TooltipReference>
                            ) : (
                              ""
                            ))}
                        </>
                      )}
                    </div>
                  )}
              </div>
              {/* {
                    (index === 0? 
                        <span className="reminderName" title={mappedMemberData.get(m.assignId) || ''}>
                        {mappedMemberData.get(m.assignId)?.slice(0, 1).toUpperCase()}
                    </span> :
                        (messages[index - 1]?.assignId !== m.assignId?
                        <span className="reminderName" title={mappedMemberData.get(m.assignId) || ''}>
                        {mappedMemberData.get(m.assignId)?.slice(0, 1).toUpperCase()}
                    </span> : '')
                    )
                } */}
            </>
          );
        } catch (err) {
          return <div>This message is not viewable here.</div>;
        }
      })}
    </>
  );
};

export default Messages;
