import React from 'react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-date-picker'
import { useFormikContext } from 'formik'
import moment from 'moment'

import styles from './dateInput.module.scss'

type Props = {
    name: string,
    value: string,
    minToday?: boolean
    restrictPastDates?: boolean
}

function DateInput({
    name,
    value,
    minToday,
    restrictPastDates
}: Props) {
    const formik = useFormikContext();
    const isPastDate = moment(value).isBefore(new Date());

    return (
        <div className={['form-control', styles.calenderInput].join(' ')}>
            <FontAwesomeIcon icon={faCalendar} className='mr-2' />
            <DatePicker
                dayPlaceholder='DD'
                monthPlaceholder='MM'
                yearPlaceholder='YYYY'
                onChange={date => {
                    if (date instanceof Date) {
                        formik.setFieldValue(
                            name,
                            moment(date).format('YYYY-MM-DD')
                        )
                    } else {
                        formik.setFieldValue(name, '')
                    }
                }}
                calendarIcon={false}
                value={value.trim() !== ''
                    ? new Date(value)
                    : null}
                    minDate={restrictPastDates && isPastDate ? undefined : (minToday ? new Date() : undefined)}    
                />
        </div>
    )
}

DateInput.defaultProps = { minToday: false }

export default DateInput