/**
 * Keyword input component used in flowInstances for create/update flow. 
 */

import React, { useEffect, useState } from 'react';
import { Button, Collapse, Form } from 'react-bootstrap';
import { FormikErrors, useFormikContext } from 'formik';

import styles from './keywordInput.module.css';
import { MdAdd, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { uuidv4 } from '../../utils/uuid';
import { BsTrash } from 'react-icons/bs';

export interface IKeyword {
    uid: string;
    keyword: string;
    payload: string;
    caseMatch: boolean;
    regexMatch: boolean;
}

interface Props {
    value: IKeyword[];
    onChange: React.Dispatch<React.SetStateAction<IKeyword[]>>;
    isInvalid: boolean;
    isValid: boolean;
    error: any;
    touched: any;
}

const KeywordInput: React.FC<Props> = ({ value, onChange, touched, ...otherProps }) => {
    const formik = useFormikContext();
    const [advance, setShowAdvance] = useState<{ [uid: string]: boolean }>({});
    useEffect(() => {
        const updatedShowAdvance: { [uid: string]: boolean } = {}
        value.forEach(instance => {
            updatedShowAdvance[instance.uid] = instance.caseMatch ||
                instance.regexMatch ||
                instance.payload.trim() !== "";
        });
        setShowAdvance(updatedShowAdvance);
    }, []);

    function handleInputChange(index: number, key: string, value: any) {
        formik.setFieldValue(`flowInstances[${index}].${key}`, value);
    }

    function handleCreateKeyword() {
        formik.setFieldValue('flowInstances', [...value, {
            uid: uuidv4(),
            keyword: '',
            payload: '',
            caseMatch: false,
            regexMatch: false,
        }]);
    }

    function handleDeleteKeyword(id: string) {
        formik.setFieldValue('flowInstances', value.filter(keyword => keyword.uid !== id));
    }

    return (
        <div className={styles.container}>
            <div className={styles.labelRow}>
                <Form.Label>Keywords to Trigger Bot Flows</Form.Label>
                <Button onClick={handleCreateKeyword} size='sm' className='addButton'><MdAdd /></Button>
            </div>
            {value.map((instance, index) => {
                function getIsError(key: string) {
                    return (
                        otherProps.error &&
                        !(otherProps.error instanceof String) &&
                        otherProps.error.length >= index &&
                        otherProps.error[index] &&
                        key in otherProps.error[index]
                    );
                }
                function getIsTouched(key: string) {
                    return touched
                        ? touched.length >= index &&
                        touched[index] &&
                        key in touched[index] &&
                        touched[index][key]
                        : false;
                }

                const showAdvance = instance.uid in advance && advance[instance.uid];
                const keywordHasAsterisk = instance.keyword.includes("*"); 

                const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    const newKeyword = event.target.value;

                    if (newKeyword.includes("*")) {
                        handleInputChange(index, 'caseMatch', false);
                        handleInputChange(index, 'regexMatch', false);
                    }

                    handleInputChange(index, 'keyword', newKeyword);
                };

                return (
                    <div key={instance.uid} className={styles.keywordItem}>
                        <div className={styles.labelRow}>
                            <Form.Label>
                                Keyword <span className="required"></span>
                            </Form.Label>
                            <Button
                                disabled={index === 0 && value.length <= 1}
                                onClick={() => handleDeleteKeyword(`${instance.uid}`)}
                                size="sm"
                                className="deleteSmallButton"
                            >
                                <BsTrash />
                            </Button>
                        </div>
                        <Form.Control
                            type="text"
                            placeholder="Enter your Keywords"
                            value={instance.keyword}
                            onChange={handleKeywordChange}
                            isValid={getIsTouched("keyword") && !getIsError("keyword")}
                            isInvalid={getIsTouched("keyword") && getIsError("keyword")}
                        />
                        {getIsTouched("keyword") && getIsError("keyword") ? (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                                {otherProps.error[index].keyword}
                            </div>
                        ) : null}

                        <div className={styles.advanceOptions}>
                            <div
                                className={styles.labelRow}
                                onClick={() => {
                                    setShowAdvance((currentActive) => {
                                        let updatedActive = { ...currentActive };
                                        updatedActive[instance.uid] = !showAdvance;
                                        return updatedActive;
                                    });
                                }}
                            >
                                <span className={styles.advancedName}>Advanced Option</span>
                                <span className={styles.arrow}>
                                    {showAdvance ? (
                                        <MdOutlineKeyboardArrowUp />
                                    ) : (
                                        <MdOutlineKeyboardArrowDown />
                                    )}
                                </span>
                            </div>
                        </div>

                        <Collapse in={showAdvance}>
                            <div className={styles.checkboxesRow}>
                                <div className={styles.formCheck}>
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        onChange={(event) =>
                                            handleInputChange(index, "caseMatch", event.target.checked)
                                        }
                                        checked={instance.caseMatch}
                                        disabled={keywordHasAsterisk} 
                                    />
                                    <label>Case Match</label>
                                </div>
                                <div className={styles.formCheck}>
                                    <Form.Check
                                        inline
                                        type="checkbox"
                                        onChange={(event) =>
                                            handleInputChange(index, "regexMatch", event.target.checked)
                                        }
                                        checked={instance.regexMatch}
                                        disabled={keywordHasAsterisk} 
                                    />
                                    <label>Regex Match</label>
                                </div>
                            </div>
                        </Collapse>

                        <Collapse in={showAdvance} dimension="height">
                            <Form.Group className="mt-2">
                                <Form.Label>
                                    Payload <span className="dimmed">(Optional)</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your Payload"
                                    value={instance.payload}
                                    onChange={(event) => {
                                        handleInputChange(index, "payload", event.target.value);
                                    }}
                                    isValid={getIsTouched("payload") && !getIsError("payload")}
                                    isInvalid={getIsTouched("payload") && getIsError("payload")}
                                />
                                {getIsTouched("payload") && getIsError("payload") ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {otherProps.error[index].payload}
                                    </div>
                                ) : null}
                            </Form.Group>
                        </Collapse>
                    </div>
                );
            })}

            {(otherProps.error && otherProps.error instanceof String) && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {otherProps.error}
                </div>
            )}
        </div>
    );
};

export default KeywordInput;
