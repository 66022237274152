export const ContactIcon = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M16 20V18.3333C16 17.4493 15.6348 16.6014 14.9846 15.9763C14.3345 15.3512 13.4528 15 12.5333 15H6.46667C5.54725 15 4.66549 15.3512 4.01536 15.9763C3.36524 16.6014 3 17.4493 3 18.3333V20"
          stroke="#666E7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.5 11C11.433 11 13 9.433 13 7.5C13 5.567 11.433 4 9.5 4C7.567 4 6 5.567 6 7.5C6 9.433 7.567 11 9.5 11Z"
          stroke="#666E7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19 8V14"
          stroke="#666E7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22 11H16"
          stroke="#666E7D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  