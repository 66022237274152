import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./modall.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: any;
  size?: string;
  // height?: string;
  component?: string;
  style?: any;
  closeOnOutsideClick?: boolean;
  isCreateLink?: boolean;
  createdLink?: (link: boolean) => void;
}

const Modall: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  size,
  component,
  style,
  closeOnOutsideClick = true,
  isCreateLink,
  createdLink
}) => {
  const handleBackdropClick = (e: any) => {
    if (!closeOnOutsideClick) {
      e.stopPropagation();
    } else {
      onClose();
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        fullscreen={size === "modal-xxl" || size === "modal-xl" ? true : false}
        size={size === "modal-xxl" || size === "modal-xl" ? "" : size}
        isOpen={isOpen}
        toggle={onClose}
        backdrop={closeOnOutsideClick ? true : "static"}
        className={`${component === 'uploadFiles' ? 'modal-l' : ''}`}
        centered={component === 'uploadFiles'}
      // className={`${size === 'modal-xxl' ? 'modal-xxl' : size === 'modal-xl' ? 'modal-xl' : ''}`}
      >
        <div>
          {component !== "conversation" &&
            component !== "initiateConversation" && (
              <div className="modal-header">
                <h6 className="title">{title}</h6>
                {isCreateLink && (
                  <div className="d-flex justify-content-end">
                    <button className="sendButton p-2 w-100" onClick={() => createdLink?.(true)} >Create Link</button>
                  </div>
                )}
                <div
                  className="close-btn cursor-pointer d-flex align-items-center"
                  onClick={handleCloseModal}
                >
                  {title === "Select Template" && (
                    <div className="limitMsg1 me-4">
                      <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                      <span>
                        For newly approved templates, please initiate campaign
                        after 30 minutes.
                      </span>
                    </div>
                  )}
                  {title === "Map values to variables" && (
                    <div className="warningLabel warningwidth me-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="21"
                        viewBox="0 0 17 18"
                        fill="none"
                      >
                        <path
                          d="M10.8459 3.33333L14.1654 6.65281V11.3472L10.8459 14.6667H6.1515L2.83203 11.3472V6.65281L6.1515 3.33333H10.8459ZM10.352 4.52632H6.6454L4.02501 7.1467V10.8533L6.6454 13.4731H10.352L12.9724 10.8527V7.1467L10.352 4.52632ZM7.90161 10.7895H9.09459V11.9825H7.90161V10.7895ZM7.90161 6.01754H9.09459V9.59649H7.90161V6.01754Z"
                          fill="#ff865c"
                        ></path>
                      </svg>
                      <span className="text-primary pr-2">Warning</span>
                      <span>
                        Sending messages to opted-out contacts may lower your
                        quality.
                      </span>
                    </div>
                  )}
                  <FontAwesomeIcon icon={faTimes} aria-hidden="true" />
                </div>
              </div>
            )}
          <ModalBody
            className={`${component !== "ADDTAG_CONTACT" && component !== "conversation"
              ? "modalBody"
              : ""
              } ${component === "ADDTAG_CONTACT" && "pt-0"}`}
          >
            {children}
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default Modall;
