import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import axios from "../../utils/axios";
import { toast } from "../../common/alert";
// import pako from "pako";
import JSZip from "jszip";
import { Formik, Field, ErrorMessage, Form } from "formik";
import FlagInput from "../../common/flagInput";
import * as Yup from "yup";
import { useNavigate,useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface props {}

const ChatBackUp = (props: props) => {
const [userNumber, setUserNumber] = useState<string | undefined>();

  const data = async (payload: any) => {
    try {
      const response = await axios.post(
        `conversation/GF9IXECH/importMessages?userMobNo=${userNumber}`,
        payload
      );
    } catch (error: any) {
      toast("error", error?.response?.data.message);
    }
  };

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const zipData = event.target.result;
      JSZip.loadAsync(zipData).then((zip) => {
        zip.forEach((relativePath, file) => {
          if (!file.dir) {
            file.async("string").then((content:any) => {
                const parsedContent = JSON.parse(content);
            //     const userToUserList = parsedContent?.filter((message:any) => {
            //         if(userNumber === message.UserPhone || userNumber === message.ToUserPhone){
            //             return true;
            //         }      
            //         return false;
            //     });
            //     console.log('userToUserList',userToUserList)
            // if(userToUserList){
                data(content);
            // }
              // Do something with the content, like displaying it in the UI
            });
          }
        });
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const [country_Code, setFlagCode] = useState<string | undefined>("+91");
  const navigate = useNavigate()
  const location = useLocation();

  const codeChanges = (e: any) => {
    setFlagCode(e);
  };

  const queryParams = new URLSearchParams(location.search);
  const userMobNo = queryParams.get("userMobNo");

  useEffect(()=>{
    if(userMobNo){
        setUserNumber(userMobNo)
    }
  },[userMobNo])


  return (
    <div className="mt-2">
        <h5>{userMobNo &&(<FontAwesomeIcon icon={faArrowLeft} className="me-2" onClick={()=>navigate('')} />)} <span>Chat Backup</span></h5>
      <Formik
        initialValues={{ mobileNumber: "" }}
        validationSchema={Yup.object().shape({
            mobileNumber: Yup.string()
                .required("Mobile Number is required")
                .min(7, "Mobile Number must be at least 7 numbers"),
        })}
        onSubmit={(values, { setSubmitting }) => {
            navigate(`?userMobNo=${values.mobileNumber}`)
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className="centered-form">
            {userMobNo ? (
             <input type="file" accept=".zip" onChange={handleFileInputChange} /> 
            ):(
                <>
            <div className="pb-2">
            <div className="input-group inputBorder ">
              <div className="input-group-prepend col-md-2 ms-3">
                <FlagInput
                  countryCode={country_Code}
                  codeChange={codeChanges}
                />
              </div>
              <Field
                type="text"
                name="mobileNumber"
                id="mobileNumber"
                placeholder="Enter your mobile number"
                className="form-control"
                style={{border:'none'}}
                //   value={values.mobileNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let value = e.target.value.replace(/[^0-9]/g, "");
                  if (value?.length <= 13) {
                    setFieldValue("mobileNumber", value);
                  }
                }}
              />
            </div>
              <ErrorMessage name="mobileNumber" component="div" className="text-danger error_alert" />
            </div>
                <div className="d-flex justify-content-end">
                        <Button className="sendButton" type="submit" >
                        Chat BackUp
                        </Button>
                </div>
                </>
                )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChatBackUp;
