import { useEffect } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { FaRobot, FaTeamspeak } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";

import Node from './node';
import * as botService from "../../../services/bots";
import { useAppSelector } from "../../../hooks";
import AxiosInstance from "../../../../utils/axios";
import { Fa42Group, FaPeopleGroup } from "react-icons/fa6";
import styles from './node.module.scss';

function AssignConversation(props: any) {
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const memberQuery = useQuery({
        queryKey: ['members'],
        enabled: false,
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await AxiosInstance.post('/member/getMembers', {
                    businessUid,
                    page: 0,
                    limit: 10,
                    status: ["ACTIVE"]
                });
                return response.data;
            } catch (error) {
                console.error(error);
                return [];
            }
        }
    });
    const botsQuery = useQuery({
        queryKey: ['bots', "?q="],
        queryFn: () => botService.getBots(""),
        enabled: false
    });
    useEffect(() => {
        switch (props.data.conversation?.type) {
            case "member":
                memberQuery.refetch();
                break;
            case "bot":
                botsQuery.refetch();
                break;
        }
    }, []);

    let content;

    switch (props.data?.conversation?.type) {
        case "member":
            if (memberQuery.isLoading) {
                content = <span className="dimmed">Loading...</span>;
                break;
            } else if (memberQuery.isError) {
                content = <span className={`dimmmed ${styles.node}`}>Failed to fetch the member details</span>;
                break;
            }
            const currentMember = memberQuery.data.dataDTO.find((member: any) => member.id === Number(props.data.conversation.id));
            content = (
                <>
                    <BsFillPersonFill size={10} />
                    <span className={`ml-1 ${styles.node}`} >Assign to {currentMember?.name}</span>
                </>
            );
            break;
        case "bot":
            if (botsQuery.isLoading) {
                content = <span className="dimmed">Loading...</span>;
                break;
            } else if (botsQuery.isError) {
                content = <span className={`dimmmed ${styles.node}`}>Failed to fetch the bot details</span>;
                break;
            }
            const currentBot = botsQuery.data.bots.find((bot: any) => bot.id === props.data.conversation.id);
            content = (
                <>
                    <FaRobot size={10} />
                    <span className={`ml-1 ${styles.node}`}>Assign to {currentBot.name}</span>
                </>
            );
            break;
            case "team":
                content = (
                    <>
                    <FaPeopleGroup size={10} />
                    <span className={`ml-1 ${styles.node}`}>Assign to Team</span>
                    </>
                );
                break;    
        default:
            content = (
                <>
                    <BsFillPersonFill size={10} />
                    <span className="ml-1">Assign to Contact Owner</span>
                </>
            );
            break;
    }

    return <Node  {...props} label='Assign Conversation' content={<div  className={styles.node}>{content}</div>} />;
}

export default AssignConversation;