import React from 'react'
import { useFormikContext } from 'formik'

import ActionsField, { InputType } from '../../../../entity/integration/actionsField'
import { Form, FormControl } from 'react-bootstrap'
import VariableInputLabel from '../variableInputLabel'
import { SelectVariable } from '../setVariable'
import { Label } from 'reactstrap'
type Props = {
    actionsFields: ActionsField[]
}

function ActionsFieldRenderer({ actionsFields }: Props) {
    const { errors, touched, values, handleChange, setFieldValue } = useFormikContext<any>()

    return (
        <>
            {actionsFields
                .filter(field => {
                    if (typeof field.display === 'object' && values.integrationData.payload) {
                        switch (field.display.condition) {
                            case 'EQUALS':
                                return values.integrationData.payload[field.display.field] === field.display.value;
                            default:
                                return Boolean(field.display);
                        }
                    }
                    return Boolean(field.display);
                })
                .map(field => {
                    const isError = Boolean(
                        (errors.integrationData as any) &&
                        (errors.integrationData as any).payload &&
                        typeof (errors.integrationData as any).payload === 'object'
                        && field.id in (errors.integrationData as any).payload
                        && Boolean((errors.integrationData as any).payload[field.id]))
                    const isTouched = Boolean((touched.integrationData as any)
                        && 'payload' in (touched.integrationData as any)
                        && typeof (touched.integrationData as any).payload === 'object'
                        && field.id in (touched.integrationData as any).payload
                        && Boolean((touched.integrationData as any).payload[field.id]))

                    let isRequired = Boolean(field.required);

                    if (typeof field.required === 'object' && values.integrationData.payload) {
                        switch (field.required.condition) {
                            case 'EQUALS':
                                isRequired = values.integrationData.payload[field.required.field] === field.required.value;
                                break;
                            default:
                                isRequired = Boolean(field.required);
                                break;
                        }
                    }


                    const formLabel = (
                        <Form.Label>
                            {field.label}
                            {isRequired ? (
                                <span className='required'></span>
                            ) : (
                                <span className='text-muted ml-1'>(Optional)</span>
                            )}
                        </Form.Label>
                    );

                    switch (field.type) {
                        case InputType.YesNo:
                            return (
                                <Form.Group
                                    key={field.id}
                                    className='my-2'
                                >
                                    {formLabel}
                                    <Form.Select
                                        value={values.integrationData.payload ? values.integrationData.payload[field.id] ? 1 : 0 : 0}
                                        onChange={event => setFieldValue(String('integrationData.payload.' + field.id), event.target.value === '1')}
                                    >
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </Form.Select>
                                    {isTouched && isError ? (
                                        <div className='invalid-feedback d-block'>
                                            {String((errors.integrationData as any).payload[field.id])}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            );
                        case InputType.Dropdown:
                            return (
                                <Form.Group
                                    key={field.id}
                                    className='my-2'
                                >
                                    {formLabel}
                                    <Form.Select
                                        value={values.integrationData.payload ? values.integrationData.payload[field.id] : ''}
                                        onChange={event => setFieldValue(String('integrationData.payload.' + field.id), event.target.value)}
                                    >
                                        {field.dropdownOption ? field.dropdownOption.map(option => {
                                            return (
                                                <option value={option.value} disabled={option.disabled}>{option.label}</option>
                                            );
                                        }) : null}
                                    </Form.Select>
                                    {isTouched && isError ? (
                                        <div className='invalid-feedback d-block'>
                                            {String((errors.integrationData as any).payload[field.id])}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            );
                        case InputType.PaymentId:
                            console.log("Rendering Variable, current value:", values.storageVariable?.name);
                            return (
                                <>
                                    <VariableInputLabel
                                        required
                                         label={field.label}
                                        error={(
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.storageVariable ? String((errors.storageVariable as any).name) : null}
                                            </Form.Control.Feedback>
                                        )}>
                                        <SelectVariable
                                            placeholder={field.label}
                                            name={field.id}
                                            value={values?.storageVariable?.paymentId || ''}
                                            onChange={(selectedValue) => {
                                        
                                                setFieldValue('storageVariable.paymentId', selectedValue);
                                                handleChange('storageVariable.paymentId')(selectedValue);
                                            }}                                          type='OBJECT'
                                            paymentId={values?.storageVariable?.paymentId || ''}// Add null check here
                                            />
                                    </VariableInputLabel>
                                    {touched.storageVariable && errors.storageVariable ? (
                                        <div className='invalid-feedback d-block'>Payment ID is required</div>
                                    ) : null}
                                </>
                            );
                            case InputType.Variable:
                            return (
                                <>
                                    <VariableInputLabel
                                        required
                                        error={(
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.storageVariable ? String((errors.storageVariable as any).name) : null}
                                            </Form.Control.Feedback>
                                        )}>
                                        <SelectVariable
                                            placeholder={field.label}
                                            name={field.id}
                                            value={values.storageVariable?.name || ''}
                                            onChange={(selectedValue) => {
                                                setFieldValue('storageVariable.name', selectedValue);
                                                handleChange('storageVariable.name')(selectedValue);
                                            }}
                                            type='OBJECT'
                                            paymentId={values?.storageVariable?.paymentId || ''}
                                        />
                                    </VariableInputLabel>
                                    {touched.storageVariable && errors.storageVariable ? (
                                        <div className='invalid-feedback d-block'>Save response variable is required</div>
                                    ) : null}
                                </>
                            );
                        default:
                            return (
                                <Form.Group
                                    key={field.id}
                                    className='my-2'
                                >
                                    <Label>{field.label}</Label>
                                    <FormControl
                                        name={'integrationData.payload.' + field.id}
                                        required={isRequired}
                                        as='input'
                                        value={values.integrationData.payload ? values.integrationData.payload[field.id] : ''}
                                        isInvalid={isTouched && isError}
                                        isValid={isTouched && !isError}
                                        onChange={handleChange}
                                    />
                                    {isTouched && isError ? (
                                        <div className='invalid-feedback d-block'>
                                            {String((errors.integrationData as any).payload[field.id])}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            )
                    }
                })}
        </>
    )
}

export default ActionsFieldRenderer