import React, { ReactNode } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsInfoCircleFill } from 'react-icons/bs';

interface VariableInputLabelProps {
    children: ReactNode;
    label?: string;
    error?: ReactNode;
    required?: boolean;
};

const VariableInputLabel: React.FC<VariableInputLabelProps> = ({ children, error, label, required }) => {
    return (
        <Form.Group className='mb-3'>
            <Form.Label>
                {label} {required ? <span className='required'></span> : <span className='dimmed'>(Optional)</span>}
                <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip>Variable name should start with alphabet; Cannot have special characters except underscore (_)</Tooltip>}
                >
                    <Button size='sm' variant='default' style={{ color: 'rgba(152, 152, 152, 1)' }}>
                        <BsInfoCircleFill />
                    </Button>
                </OverlayTrigger>
            </Form.Label>
            {children}
            {error ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {error}
                </div>
            ) : null}
        </Form.Group>
    );
}

VariableInputLabel.defaultProps = {
    label: 'Save response in the variable',
    required: false
};

export default VariableInputLabel;