import React, { createContext, useContext, useState } from 'react';

interface DataContextProps {
    data: Record<string, any> | any;
    setWhatsAppData: React.Dispatch<React.SetStateAction<Record<string, any> | any>>;
    clearData: () => void;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const useWhtasappData = () => {
    const context = useContext(DataContext);
    if (!context) throw new Error('useData must be used within a DataProvider');
    return context;
};

export const WhatsappDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [data, setWhatsAppData] = useState<Record<string, any> | any>({});
    const clearData = () => setWhatsAppData({});

    return (
        <DataContext.Provider value={{ data, setWhatsAppData, clearData }}>
            {children}
        </DataContext.Provider>
    );
};

