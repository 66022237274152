import { Store, compose, createStore } from "redux";
import rootReducer from "../reducers/main";

let store: Store<any>;

if (process.env.NODE_ENV === "development") {
    const composeEnhancers =
        typeof window !== 'undefined' &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name: "Main store",
                actionsBlacklist: ['REDUX_STORAGE_SAVE']
            })
            : compose;
    store = createStore(
        rootReducer,
        composeEnhancers()
    );
} else {
    store = createStore(rootReducer);
}

export default store;
