import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ListGroup, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import styles from './sendOrderDetails.module.scss'
import Node from '../node'
import { IDigitalProduct, getDigitalProducts } from '../../../../services/designFlow'
import { useAppSelector } from '../../../../hooks'
import { SOMETHING_WENT_WRONG } from '../../../../../constants/errorMessage'
import classes from '../delay.module.css';
import { Handle } from 'reactflow'
import { Position } from 'reactflow'

function SendOrderDetails(props: any) {
  const businessUid = useAppSelector(state => state.meta.businessUid)
  const query = useQuery({
    queryKey: ['digital-products'],
    queryFn: () => getDigitalProducts(businessUid || '', {
      "search":null,
      "page": 0,
      "limit": 10
      }),
    enabled: false
  })
  useEffect(() => {
    if (businessUid && !query.isFetchedAfterMount && !query.data) {
      query.refetch()
    }
  }, [businessUid])

  let content = <Spinner />

  if (!query.isLoading && !query.isError) {
    content = (
      <>
      <div>
      <ListGroup>
          <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
              <div>
                  <span ></span>
                  <span className={classes.caption}>On Success</span>
                  <Handle
                      className={classes.dot}
                      id='send_OrderDetails/success'
                      type='source'
                      position={Position.Right}
                      isConnectable
                  />
              </div>
          </ListGroup.Item>
          <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
              <div>
                  <span className={classes.caption}>On Fail</span>
                  <Handle
                      className={classes.dot}
                      id='send_OrderDetails/failed'
                      type='source'
                      position={Position.Right}
                      isConnectable
                  />
              </div>
          </ListGroup.Item>
      </ListGroup>
  </div>
      </>
    )
  } else if (query.isError) {
    content = (
      <span className='text-danger'>
        <FontAwesomeIcon icon={faWarning} className='mr-1' />
        {SOMETHING_WENT_WRONG}
      </span>
    )
  }

  return (
    <Node {...props} label='Send Payments' content={content} />
  )
}

export default SendOrderDetails