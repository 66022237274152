import { AnyARecord } from "dns";
import { toast } from "../common/alert";
import axios from "../utils/axios";


export const createOTP = async (channelUid: any, requestBody: any) => {
    try {
        const response = await axios.post(`otpConfiguration/${channelUid}/createOptConfiguration`, requestBody);
        toast('success', "OTP Configuration created Successfully")
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};

export const updateOTP = async (channelUid: any, uid: any, requestBody: any) => {
    try {
        console.log(channelUid, uid, requestBody);
        const response = await axios.put(`otpConfiguration/${channelUid}/${uid}/update`, requestBody);
        toast('success', "OTP Configuration updated Successfully")
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};

export const deleteOTP = async (channelUid: any, uid: string) => {
    try {
        const response = await axios.delete(`otpConfiguration/${channelUid}/${uid}/delete`);
        toast('success', "OTP Configuration deleted Successfully")
        return response.data;
    } catch (error: any) {
        uid && toast("error", error.message);
    }
};


export const getAllOTP = async (channelUid: any) => {
    try {
        const response = await axios.get(`otpConfiguration/${channelUid}/getAll`);
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};





