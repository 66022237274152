import React from 'react';
import { ConnectButton } from '360dialog-connect-button';
import { useNavigate } from 'react-router-dom';
import { createChannel } from '../services/dashboardService';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from './alert';

interface connectBtnProps {
    component?: string;
}

const ChannelConnect: React.FC<connectBtnProps> = ({ component }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let isCreateChannelInProgress = false;

    const handleConnect = async (obj: any) => {
        if (!isCreateChannelInProgress) {
            isCreateChannelInProgress = true;
            try {
                const storedBusinessId = localStorage.getItem('businessId');
                if (storedBusinessId !== null) {
                    const payload = {
                        "uid": storedBusinessId,
                        "client": obj.client,
                        "channelId": obj.channels,
                        "revoked": obj?.revokedChannels ? obj.revokedChannels : []
                    };

                    const res = await createChannel(payload);
                    if (component !== 'dashboard')
                        navigate(`${process.env.PUBLIC_URL}/dashboard`);
                    window.location.reload();
                }
            } catch (err: any) {
                toast('error', err.response.data.message);
            } finally {
                isCreateChannelInProgress = false;
            }
        }
    };

    return (
        <ConnectButton
            callback={handleConnect}
            queryParameters={{
                plan_selection: 'basic', redirect_url: window.location.hostname ===
                    "app.bizmagnets.ai" ? 'https://app.bizmagnets.ai/dashboard' : 'https://bizmagnet-stage.vamosys.com/dashboard'
            }}
            partnerId={"Nwc03TPA"}
            className={`${component === "notification" ? 'chDropdown subBtn' : component !== 'channelDropdown' ? `sendButton mt-2 ${component === 'channelProfile' ? 'w-100' : ''}
            ${component === 'dashboard' || component === 'whatsappChannel' ? 'w-50' : ''} ${component === 'trialDashboard' ? 'w-25' : ''}` :
                'chDropdown'}`}
            label={
                <span>
                    {component === 'channelProfile' && <FontAwesomeIcon icon={faPlus} className="mr-2" />}
                    {component === 'notification' || component === 'dashboard' || component === 'trialDashboard' ? "Connect Your Own Number" : 'Create New Channel'}
                </span>
            }
            type="button"
        />
    );
};

export default ChannelConnect;
