import { useRef, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { Popover, PopoverBody, Tooltip } from "reactstrap";
import "../main.scss";

const VariableInputComponent = ({
    id,
    label,
    options,
    input,
    setInput,
    required,
  }: {
    id: number | string;
    label: string;
    options: any;
    input: string | undefined;
    setInput?: React.Dispatch<React.SetStateAction<string>>;
    required: boolean;
  }) => {

    const [toolTip, setToolTip] = useState(false);
    const [popOver, setPopover] = useState(false);
    
    // Refs for the popover and button
    const popoverRef = useRef<HTMLDivElement | null>(null); // Ref for the DOM element inside Popover
    const buttonRef = useRef<HTMLButtonElement | null>(null); // Ref for the button
    
    const [error, setError] = useState(false);

    // Function to handle the popover toggle
    const togglePopover = (id: number | string) => {
        setPopover(!popOver);
    };

    const handleOptionClick = (value: string, id: number | string) => {
        if(setInput){
            setInput((prev: any) => prev + value);
            setPopover(false);
        }
    };

    return (
      <div key={id} className="template-form mb-4 me-2" style={{width: '14rem'}}>
        <div className="d-flex">
          <div className="p-1">{label}</div>
          {required && <span style={{ color: "red" }}>*</span>}
          {typeof id === "string" && (
            <div id={`webHooktooltip-${id}`} className="p-1">
              <BsInfoCircle size={12} />
              <Tooltip
                placement="top"
                isOpen={toolTip}
                target={`webHooktooltip-${id}`}
                toggle={() => { setToolTip(!toolTip)}}
              >
                {id === "Recipient-Name"
                  ? "This is used as name while creating new contact in BizMagnets"
                  : "Messages will be sent to this number. Valid formats are +919009090090 or 9009090090"}
              </Tooltip>
            </div>
          )}
        </div>
        <input
          key={id}
          type="text"
          className={`form-control mb-1 ${error ? "is-invalid" : ""}`} // Add error styling
          value={input || ""}
          onChange={(e: any) => {
            if(setInput){
                setInput(e.target.value);
            }
            setError(e.target.value.trim().length === 0)
          }}
          placeholder={`Enter value for {{${label}}}`}
        />
        <div className="d-flex align-items-center">
          {error && (
            <small style={{ color: "red" }}>
              {input && input.length > 0 ? "" : "Add variable"}
            </small>
          )}
        </div>
        <button
          id={`addVariableBtn-${id}`}
          ref={buttonRef}
          type="button"
          className="add-variables-btn"
          onClick={() => togglePopover(id)} // Open/Close popover for the clicked input
        >
          Add Variable
        </button>
        <Popover
          href={popoverRef} // Reference to Popover component
          placement="bottom"
          target={`addVariableBtn-${id}`}
          isOpen={popOver}
          toggle={() => togglePopover(id)} // Toggle the popover when clicked
        >
          <PopoverBody className="p-2">
            <div className="popup-div">
              {options?.map((option: any) => (
                <div
                  key={option.value}
                  className={option.value ? "popup-addVariable" : "fw-bold"}
                  color="link"
                  onClick={() => handleOptionClick(option.value, id)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  };

  export default VariableInputComponent;