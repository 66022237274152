import { configureStore } from '@reduxjs/toolkit';

import graphSlice from './graphSlice';
import metaSlice from './metaSlice';
import tableReducer from '../../redux/reducers/tableReducer';

const store = configureStore({
  reducer: {
    graph: graphSlice,
    meta: metaSlice,
    tableReducer : tableReducer as any,
  },
  devTools: {
    name: 'Bot flows store'
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
