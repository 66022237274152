import React, {
    useState,
    useRef,
    useEffect,
} from "react";
import {
    Formik,
    Form,
    Field,
    ErrorMessage,
    FieldProps,
    FormikProps,
} from "formik";
import {
    FormGroup,
    Label,
    Button,
} from "reactstrap";
import TooltipReference from "../../common/tooltip";
import {
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import customData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { toast } from "../../common/alert";
import Select from "react-select";
// import { customStyles } from "../../common/react-select-style";
import * as Yup from "yup";
import axios from "../../utils/axios";
import FlagInput from "../../common/flagInput";
import { useSelector } from 'react-redux';
import AxiosInstance from "../../utils/axios";
interface Variable {
    name: string;
    id: string;
    value: string;
}

interface CarouselModalValues {
    carouselBody: string;
    sampleVariables: Variable[];
    carouselVariables: any;
    content: ''
    // phoneChecked: boolean;
    phoneInput1: string;
    phoneInput2: string;
    // webUrlChecked: boolean;
    webUrlDropdown: string;
    websiteName: string;
    webUrl: string;
    variable1: string;
    buttonOne: string;
    // phoneCheckedTwo: boolean;
    phoneInputTwo1: string;
    phoneInputTwo2: string;
    // webUrlCheckedTwo: boolean;
    webUrlDropdownTwo: string;
    websiteNameTwo: string;
    webUrlTwo: string;
    variable2: string;
    buttonTwo: string;
}

interface CarouselProps {
    value: any;
    // setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    type: string;
    handleAddItem: (allMedia: any[], values: any, variables: any, countryCode: any) => void;
    restrictText: (
        event: any,
        setFieldValue: (field: string, value: any) => void,
        resTill: number
    ) => void;
    closeModal: any;
    images: any;
    temValues: any;
    item: any;
    edit: boolean;
    countryArr: any;
}

interface SelectBtnOption {
    value: string;
    label: string;
}

const webUrlOptions: SelectBtnOption[] = [
    { value: "staticUrl", label: "Static URL" },
    { value: "dynamicUrl", label: "Dynamic URL" },
];
const webUrlOptions1: SelectBtnOption[] = [
    { value: "staticUrl", label: "Static URL" },
    { value: "dynamicUrl", label: "Dynamic URL" },
];
interface FileWithPreview {
    file: File;
    imgUrl: string;
}

export const Carousel: React.FC<CarouselProps> = ({ value, handleChange, type, handleAddItem, restrictText, closeModal, images, temValues, item, edit, countryArr }) => {
    const [characterCount, setCharacterCount] = useState(0);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [counter, setCounter] = useState(1);
    const [mouseSelectedText, setMouseSelectedText] = useState("");
    const [popovertarget, setpopovertarget] = useState<null | HTMLButtonElement>(
        null
    );
    const [popoverOpen, setPopoverOpen] = useState(false);
    const pickerRef = useRef<HTMLDivElement>(null);
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const [charphone, setCharphone] = useState(0);
    const [charnum, setCountnum] = useState(0);
    const [charphoneTwo, setCharphoneTwo] = useState(0);
    const [charnumTwo, setCountnumTwo] = useState(0);
    const [urlCount, setUrlCount] = useState(0);
    const [weburlCount, setwebCount] = useState(0);
    const [urlCountTwo, setUrlCountTwo] = useState(0);
    const [weburlCountTwo, setwebCountTwo] = useState(0);
    const [media, setMedia] = useState<any>([]);
    const [allMedia, setAllMedia] = useState<any>(images ? images : []);
    const [template, setTemplate] = useState<any>([]);
    const [editValue, setEditValue] = useState<any>([]);
    const [code, setCode] = useState<any>(item?.countryCode ? item?.countryCode?.code1 ? item?.countryCode?.code1 : item?.countryCode[item?.index]?.code1 : '+91');
    const [code2, setCode2] = useState<any>(item?.countryCode ? item?.countryCode?.code2 ? item?.countryCode?.code2 : item?.countryCode[item?.index]?.code2 : '+91');
    const [codeArr, setCountryCode] = useState<any>([]);
    const [allVariables, setVariables] = useState<any>([]);
    const [varCount, setVarCount] = useState(0);
    const [varCountTwo, setVarCountTwo] = useState(0);
    const [quickCount1, setQuickCount1] = useState(0);
    const [quickCount2, setQuickCount2] = useState(0);
    const [allTemplate, setAllTemplate] = useState<any>(temValues ? temValues : []);
    const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
    var mediaObj: any = [];

    useEffect(() => {
        setCountryCode(countryArr ?? []);
    }, [countryArr]);

    useEffect(() => {
        setCharacterCount(item?.card?.components[1]?.text ? item?.card?.components[1]?.text.length : 0);
        setQuickCount1(item?.card?.components[2]?.buttons[0]?.type === 'QUICK_REPLY' ? (item?.card?.components[2]?.buttons[0]?.text)?.length : 0);
        setQuickCount2(item?.card?.components[2]?.buttons[1]?.type === 'QUICK_REPLY' ? (item?.card?.components[2]?.buttons[1]?.text)?.length : 0);
        setCharphone(item?.card?.components[2]?.buttons[0]?.type === 'PHONE_NUMBER' ? item?.card?.components[2]?.buttons[0]?.text?.length : 0);
        setCountnum(item?.card?.components[2]?.buttons[0]?.type === 'PHONE_NUMBER' ? (item?.card?.components[2]?.buttons[0]?.phone_number?.slice(code ? code?.length : 0))?.length : 0);
        setCharphoneTwo(item?.card?.components[2]?.buttons[1]?.type === 'PHONE_NUMBER' ? item?.card?.components[2]?.buttons[1]?.text?.length : 0);
        setCountnumTwo(item?.card?.components[2]?.buttons[1]?.type === 'PHONE_NUMBER' ? (item?.card?.components[2]?.buttons[1]?.phone_number?.slice(code2 ? code2?.length : 0))?.length : 0);
        setVarCount(item?.card?.components[2]?.buttons[0]?.example ? (item?.card?.components[2]?.buttons[0]?.example[0])?.length : 0);
        setVarCountTwo(item?.card?.components[2]?.buttons[1]?.example ? (item?.card?.components[2]?.buttons[1]?.example[0])?.length : 0);
        setUrlCount(item?.card?.components[2]?.buttons[0]?.type === 'URL' ? item?.card?.components[2]?.buttons[0]?.text?.length : 0);
        setwebCount(item?.card?.components[2]?.buttons[0]?.type === 'URL' ? item?.card?.components[2]?.buttons[0]?.url?.length : 0);
        setUrlCountTwo(item?.card?.components[2]?.buttons[1]?.type === 'URL' ? item?.card?.components[2]?.buttons[1]?.text?.length : 0);
        setwebCountTwo(item?.card?.components[2]?.buttons[1]?.type === 'URL' ? item?.card?.components[2]?.buttons[1]?.url?.length : 0);

    }, [item])


    const combineArrays = (buttonValue: any, buttonName: any) => {
        return buttonValue.map((keyItem: any, index: number) => ({
            id: keyItem?.value,
            name: keyItem?.value,
            value: buttonName[index]
        }));
    };

    const handleMouseUp = () => {
        const selection = window.getSelection();
        if (selection) {
            const selectedText = selection.toString();
            if (selectedText) {
                setMouseSelectedText(selectedText);
            }
        }
    };
    const extractedString = (text: string) => {
        const regex = /{{([^{}]+)}}/g;
        const matches = regex.exec(text);
        const extractedString =
            matches && matches.length > 1 ? matches[1].trim() : "";
        return extractedString;
    };

    const handleVariableClick = (formValue: any, variable: any, setFieldValue: any) => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        const start = textarea.selectionStart !== null ? textarea.selectionStart : 0;
        const end = textarea.selectionEnd !== null ? textarea.selectionEnd : 0;

        const textBeforeCursor = formValue.carouselBody.slice(0, start);
        const textAfterCursor = formValue.carouselBody.slice(end);

        const newMessageBody = `${textBeforeCursor}{{${variable}}}${textAfterCursor}`;
        formValue.carouselBody = newMessageBody;
        setFieldValue('carouselBody', newMessageBody)

        const variables = [...formValue.sampleVariables];
        if (variable !== "Custom") {
            variables.push({ name: variable, id: variable, value: "" });
        } else {
            const newVariable = {
                id: `variable ${counter}`,
                name: `variable ${counter}`,
                value: "",
            };
            variables.push(newVariable);
            setCounter(counter + 1);
        }
        // formValue.sampleVariables = variables;
        setFieldValue('sampleVariables', variables)
    };

    const handleTextareaChange = (event: any, field: any, setFieldValue: any, values: any) => {
        let text = event.target.value;
        text = text.replace(/\n{3,}/g, "\n\n");
        setCharacterCount(text.length);
        setFieldValue("carouselBody", text);

        const regex = /{{([^{}]+)}}/g;
        const matches = text.match(regex);
        const newVariables = matches
            ? matches.map((match: any, index: any) => {
                const id = extractedString(match);
                const name = extractedString(match);
                const value = values?.sampleVariables[index]?.value || "";
                return { id, name, value };
            })
            : [];
        setFieldValue("sampleVariables", newVariables);
        if (!newVariables.length) {
            setFieldValue("carouselVariables", "");
        }
    };

    const handleTextareaPaste = (event: any, field: any, setFieldValue: any, values: any) => {
        event.preventDefault();
        let pastedText = event.clipboardData.getData("text");
        pastedText = pastedText.replace(/\n{3,}/g, "\n\n");

        const textarea = textareaRef.current;
        if (textarea) {
            const { selectionStart, selectionEnd } = textarea;
            const currentValue = field.value || "";
            const newValue =
                currentValue.substring(0, selectionStart) +
                pastedText +
                currentValue.substring(selectionEnd);
                const regex = /{{([^{}]+)}}/g;

        const matches = newValue.match(regex);
        const newVariables = matches
            ? matches.map((match: any, index: any) => {
                const id = extractedString(match);
                const name = extractedString(match);
                const value = values?.sampleVariables[index]?.value || "";
                return { id, name, value };
            })
            : [];
        setFieldValue("sampleVariables", newVariables);
        if (!newVariables.length) {
            setFieldValue("carouselVariables", "");
        }

            setFieldValue("carouselBody", newValue);
            setCharacterCount(newValue.length);
        }
    };

    const handleSampleVariable = (index: any, value: any, setFieldValue: any, values: any) => {
        const updatedSampleVariables = [...values.sampleVariables];
        updatedSampleVariables[index].value = value;
        setFieldValue("sampleVariables", updatedSampleVariables);
    };

    const handleFormatClick = (carouselBody: any, formatType: string) => {
        let selectedText;

        switch (formatType) {
            case "bold":
                selectedText = `${mouseSelectedText
                    ? carouselBody.replace(
                        mouseSelectedText,
                        "*" + mouseSelectedText + "*"
                    )
                    : carouselBody + "**"
                    }`;
                break;
            case "italic":
                selectedText = `${mouseSelectedText
                    ? carouselBody.replace(
                        mouseSelectedText,
                        "_" + mouseSelectedText + "_"
                    )
                    : carouselBody + "_"
                    }`;
                break;
            case "strikethrough":
                selectedText = `${mouseSelectedText
                    ? carouselBody.replace(
                        mouseSelectedText,
                        "~" + mouseSelectedText + "~"
                    )
                    : carouselBody + "~~"
                    }`;
                break;
            default:
                break;
        }
        setMouseSelectedText("");
        carouselBody = selectedText;
        // setFieldValue("carouselBody", selectedText);
    };

    const handleEmojiSelect = (carouselBody: any, e: any) => {
        const selectionStart = textareaRef.current!.selectionStart;
        const selectionEnd = textareaRef.current!.selectionEnd;
        const before = carouselBody.substring(0, selectionStart);
        const after = carouselBody.substring(
            selectionEnd,
            carouselBody.length
        );
        const updatedValue = before + e.native + after;
        carouselBody = updatedValue
        // setFieldValue("carouselBody", updatedValue);
        setTimeout(() => {
            textareaRef.current!.selectionStart = textareaRef.current!.selectionEnd =
                selectionStart + e.native.length;
        }, 0);
        setPopoverOpen(false);
        setpopovertarget(null);
    };

    // const handleSampleVariable = (index: number, values: any) => {
    //     const updatedSampleVariables = [...values];
    //     if (values.length < 25) {
    //         updatedSampleVariables[index].values = values;
    //         values.sampleVariables = updatedSampleVariables;
    //         // setFieldValue("sampleVariables", updatedSampleVariables);
    //     }
    // };

    // const uploadFile = async (file: any, setFieldValue: any) => {
    //     const allowedSize: number = value?.carouselType === 'image carousel' ? 5 : 10;

    //     // Check if file size exceeds the allowed limit
    //     if (file.size > allowedSize * 1024 * 1024) {
    //         setFiles([]);
    //         setFieldValue("content", '');
    //         toast(
    //             "error",
    //             `File size too large! Please select a file smaller than ${allowedSize}MB.`
    //         );
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append("content", file);

    //     try {
    //         const response = await AxiosInstance.post(
    //             `media/saveMessageMedia?uid=${channel?.channelAllData?.uid}`,
    //             formData,
    //             {
    //                 headers: {
    //                     Accept: "application/json",
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             }
    //         );

    //         const newMedia = response?.data;

    //         // Check if newMedia is valid before updating state
    //         if (newMedia) {
    //             mediaObj = newMedia;
    //             setMedia(newMedia)
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         setFiles([]);
    //         setFieldValue("content", '');
    //         toast("error", "Failed to upload!");
    //     }
    // };
    const [loading, setLoading] = useState(false);

    const uploadFile = async (file: any, setFieldValue: any) => {
        const allowedSize: number = value?.carouselType === 'image carousel' ? 2 : 16;

        if (file.size > allowedSize * 1024 * 1024) {
            setFiles([]);
            setFieldValue("content", '');
            toast(
                "error",
                `File size too large! Please select a file smaller than ${allowedSize}MB.`
            );
            return;
        }

        const formData = new FormData();
        formData.append("content", file);

        setLoading(true);

        try {
            // const response = await AxiosInstance.post(
            //     `media/saveMessageMedia?uid=${channel?.channelAllData?.uid}`,
            //     formData,
            //     {
            //         headers: {
            //             Accept: "application/json",
            //             "Content-Type": "multipart/form-data",
            //         },
            //     }
            // );

            // const newMedia = response?.data;

            // if (newMedia) {
            //     setMedia((prevMedia: any) => ({ ...prevMedia, ...newMedia })); // Adjust based on your state structure
            // }
            const sendRequest = async (retryCount: number = 0): Promise<any> => {
                try {
                    const response = await AxiosInstance.post(
                        `media/saveMessageMedia?uid=${channel?.channelAllData?.uid}`,
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );

                    return response?.data;
                } catch (error) {
                    if (retryCount < 3) { // Retry up to 3 times
                        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retrying
                        return sendRequest(retryCount + 1);
                    } else {
                        throw error;
                    }
                }
            };

            const newMedia = await sendRequest();

            if (newMedia && Object.keys(newMedia).length > 0) {
                setMedia((prevMedia: any) => ({ ...prevMedia, ...newMedia }));
            } else {
                toast("error", "Failed to receive media data.");
            }
        } catch (error) {
            console.error(error);
            setFiles([]);
            setFieldValue("content", '');
            toast("error", "Failed to upload!");
        } finally {
            setLoading(false);
        }
    };


    const handleFileChange = (
        files: FileList | null, setFieldValue: any
    ) => {
        const allowedSize: number = value?.carouselType === 'image carousel' ? 2 : 16;
        if (files) {
            const updatedFiles: {
                imgUrl: string;
                imgName: string;
                image: File;
                type: string;
                size: number;
            }[] = [];

            if (files) {
                uploadFile(files ? files[0] : null, setFieldValue);
            }
            if (media && media.length >= 0) {
                for (let i = 0; i < files.length; i++) {
                    const newFile = files[i];
                    const imgUrl = URL.createObjectURL(newFile);
                    // setFieldValue("content", imgUrl)
                    updatedFiles.push({
                        imgUrl: imgUrl,
                        imgName: newFile.name,
                        image: newFile,
                        type: newFile.type,
                        size: newFile.size,
                    });
                }
                const uploadedFile = updatedFiles[0];
                const filesWithPreview = updatedFiles.map(file => ({
                    file: file.image,
                    imgUrl: file.imgUrl,
                }));
                if (value?.carouselType === "image carousel") {
                    if (
                        uploadedFile.type === "image/jpeg" ||
                        uploadedFile.type === "image/png" ||
                        uploadedFile.type === "image/jpg"
                    ) {
                        if (files[0]?.size <= allowedSize * 1024 * 1024) {
                        setFieldValue("content", updatedFiles[0]?.imgUrl)
                        setFiles(filesWithPreview)
                        }else {
                            setFieldValue("content", '')
                            setFiles([]); 
                        }
                    } else {
                        setFieldValue("content", '')
                        toast("error", "Invalid file format");
                    }
                } else if (value?.carouselType === "video carousel") {
                    if (
                        uploadedFile.type === "video/mp4" ||
                        uploadedFile.type === "video/3gpp"
                    ) {
                        if (files[0]?.size <= allowedSize * 1024 * 1024) {
                        setFieldValue("content", updatedFiles[0]?.imgUrl)
                        setFiles(filesWithPreview)
                        }else{
                            setFieldValue("content", '')
                            setFiles([]); 
                        }
                    } else {
                        setFieldValue("content", '')
                        toast("error", "Invalid file format");
                    }
                }
            }

        }
    };

    const renderBodyMessage = (text: string, rowData: any) => {
        const replacedSentence = text?.replace(/{{(\d+)}}/g, (match, index) => {
            const wordIndex = parseInt(index, 10) - 1;
            return rowData?.bodyColumns
                ? "{{" + rowData.bodyColumns[wordIndex]?.value + "}}"
                : match;
        });
        return replacedSentence;
    };


    const initialValues: CarouselModalValues = {
        carouselBody: item ? item?.card?.components[1]?.text : '',
        sampleVariables: item?.card?.components[1]?.example?.body_text && item?.variable ? combineArrays(item?.variable, item?.card?.components[1]?.example?.body_text[0]) : [],
        carouselVariables: "",
        content: item ? item?.card?.components[0]?.example?.header_handle[0] : '',
        // phoneChecked: false,
        phoneInput1: item ? item?.card?.components[2]?.buttons[0]?.type === 'PHONE_NUMBER' ? item?.card?.components[2]?.buttons[0]?.text : '' : '',
        phoneInput2: item ? item?.card?.components[2]?.buttons[0]?.type === 'PHONE_NUMBER' ? item?.card?.components[2]?.buttons[0]?.phone_number?.slice(code ? code?.length : 0) : '' : '',
        // webUrlChecked: false,
        webUrlDropdown: item ? item?.card?.components[2]?.buttons[0]?.type === 'URL' ? item?.card?.components[2]?.buttons[0]?.example ? "dynamicUrl" : "staticUrl" : '' : '',//dropdown
        websiteName: item ? item?.card?.components[2]?.buttons[0]?.type === 'URL' ? item?.card?.components[2]?.buttons[0]?.text : '' : '',
        webUrl: item ? item?.card?.components[2]?.buttons[0]?.type === 'URL' ? item?.card?.components[2]?.buttons[0]?.url : '' : '',
        variable1: item ? item?.card?.components[2]?.buttons[0]?.example ? item?.card?.components[2]?.buttons[0]?.example[0] : '' : '',
        buttonOne: item ? item?.card?.components[2]?.buttons[0]?.type === 'QUICK_REPLY' ? item?.card?.components[2]?.buttons[0]?.text : '' : '',
        // phoneCheckedTwo: false,
        phoneInputTwo1: item ? item?.card?.components[2]?.buttons[1]?.type === 'PHONE_NUMBER' ? item?.card?.components[2]?.buttons[1]?.text : '' : '',
        phoneInputTwo2: item ? item?.card?.components[2]?.buttons[1]?.type === 'PHONE_NUMBER' ? item?.card?.components[2]?.buttons[1]?.phone_number?.slice(code2 ? code2?.length : 0) : '' : '',
        // webUrlCheckedTwo: false,
        webUrlDropdownTwo: item ? item?.card?.components[2]?.buttons[1]?.type === 'URL' ? item?.card?.components[2]?.buttons[1]?.example ? "dynamicUrl" : "staticUrl" : '' : '',//dropdown
        websiteNameTwo: item ? item?.card?.components[2]?.buttons[1]?.type === 'URL' ? item?.card?.components[2]?.buttons[1]?.text : '' : '',
        webUrlTwo: item ? item?.card?.components[2]?.buttons[1]?.type === 'URL' ? item?.card?.components[2]?.buttons[1]?.url : '' : '',
        variable2: item ? item?.card?.components[2]?.buttons[1]?.example ? item?.card?.components[2]?.buttons[1]?.example[0] : '' : '',
        buttonTwo: item ? item?.card?.components[2]?.buttons[1]?.type === 'QUICK_REPLY' ? item?.card?.components[2]?.buttons[1]?.text : '' : ''
    }

    const getValidationSchema = (value: { buttonOne: string; buttonTwo: string }) => {
        const validationSchema: any = {
            carouselBody: Yup.string().required('Card Body is required').max(160, "Card body should not exceed 160 characters"),
            sampleVariables: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required('Variable name is required'),
                    value: Yup.string().required('Sample value is required'),
                })
            ),
            content: Yup.mixed().required("File is required"),
            phoneInput1: Yup.string(),
            phoneInput2: Yup.string(),
            webUrlDropdown: Yup.string(),
            websiteName: Yup.string(),
            webUrl: Yup.string(),
            phoneInputTwo1: Yup.string(),
            phoneInputTwo2: Yup.string(),
            webUrlDropdownTwo: Yup.string(),
            websiteNameTwo: Yup.string(),
            webUrlTwo: Yup.string(),
            variable1: Yup.string(),
            variable2: Yup.string(),
        };

        if (value.buttonOne === 'quickReply') {
            validationSchema.buttonOne = Yup.string().required('Button text is required');
        } else if (value.buttonOne === 'mobileNumber') {
            validationSchema.phoneInput1 = Yup.string().required('Button text is required');
            validationSchema.phoneInput2 = Yup.string().required('Mobile number is required').min(7, 'Mobile Number must be atleast 7 numbers');
        } else if (value.buttonOne === 'weburl') {
            validationSchema.webUrlDropdown = Yup.string().required('Web URL Dropdown is required');
            validationSchema.websiteName = Yup.string().required('Website Name is required');
            validationSchema.webUrl = Yup.string().when(["webUrlDropdown"], {
                is: (webUrlDropdown: string) =>
                    webUrlDropdown === "dynamicUrl",
                then: Yup.string()
                    .required("Website Url is required")
                    .matches(
                        /^https:\/\/[a-zA-Z]+\..*\{\{1\}\}$/,
                        "Please enter a valid dynamic url"
                    ),
                otherwise: Yup.string().when(["webUrlDropdown"], {
                    is: (webUrlDropdown: string) =>
                        webUrlDropdown === "staticUrl",
                    then: Yup.string()
                        .required("Website Url is required")
                        .matches(
                            /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
                            "Please enter a valid static url"
                        )
                        .test(
                            "no-template-variable",
                            "Please enter a valid static url",
                            function (value) {
                                return !(value && value.includes("{{1}}"));
                            }
                        ),
                }),
            });
            validationSchema.variable1 = Yup.string().when(["webUrlDropdown"], {
                is: (webUrlDropdownTwo: string) =>
                    webUrlDropdownTwo === "dynamicUrl",
                then: Yup.string()
                    .required("Variable Url is required")
            })
        }

        if (value.buttonTwo === 'quickReply') {
            validationSchema.buttonTwo = Yup.string().required('Button text is required');
        } else if (value.buttonTwo === 'mobileNumber') {
            validationSchema.phoneInputTwo1 = Yup.string().required('Button text is required');
            validationSchema.phoneInputTwo2 = Yup.string().required('Mobile number is required').min(7, 'Mobile Number must be at least 7 numbers');
        } else if (value.buttonTwo === 'weburl') {
            validationSchema.webUrlDropdownTwo = Yup.string().required('Web URL Dropdown is required');
            validationSchema.websiteNameTwo = Yup.string().required('Website Name is required');
            validationSchema.webUrlTwo = Yup.string().when(["webUrlDropdownTwo"], {
                is: (webUrlDropdownTwo: string) =>
                    webUrlDropdownTwo === "dynamicUrl",
                then: Yup.string()
                    .required("Website Url is required")
                    .matches(
                        /^https:\/\/[a-zA-Z]+\..*\{\{1\}\}$/,
                        "Please enter a valid dynamic url"
                    ),
                otherwise: Yup.string().when(["webUrlDropdownTwo"], {
                    is: (webUrlDropdownTwo: string) =>
                        webUrlDropdownTwo === "staticUrl",
                    then: Yup.string()
                        .required("Website Url is required")
                        .matches(
                            /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
                            "Please enter a valid static url"
                        )
                        .test(
                            "no-template-variable",
                            "Please enter a valid static url",
                            function (value) {
                                return !(value && value.includes("{{1}}"));
                            }
                        ),
                }),
            });
            validationSchema.variable2 = Yup.string().when(["webUrlDropdownTwo"], {
                is: (webUrlDropdownTwo: string) =>
                    webUrlDropdownTwo === "dynamicUrl",
                then: Yup.string()
                    .required("Variable Url is required")
            })
        }

        return Yup.object().shape(validationSchema);
    };


    const handleSubmit = (values: any) => {
        // const codeValue = {code1: code? code : '+91', code2: code2? code2 : '+91'}
        const codeValue = { code1: code || '+91', code2: code2 || '+91' };

        const array = [values];
        const mediaUrl = media?.url ? media.url : item?.card?.components[0]?.example?.header_handle[0];
        const sampleVariable = values?.sampleVariables?.map((variable: any) => variable.value);
        const sendMedia = media ? media : '';

        const cards = array.map((e: any, ind: number) => {
            // Initialize the buttons array
            let buttons = [];

            // Handle buttonOne
            if (value.buttonOne === 'quickReply') {
                buttons.push({
                    type: "QUICK_REPLY",
                    text: e.buttonOne,
                });
            } else if (value.buttonOne === 'mobileNumber') {
                buttons.push({
                    type: "PHONE_NUMBER",
                    text: e.phoneInput1,
                    phone_number: code + e.phoneInput2
                });
            } else if (value.buttonOne === 'weburl') {
                if (values?.webUrlDropdown === 'staticUrl') {
                    buttons.push({
                        type: "URL",
                        text: e.websiteName,
                        url: e.webUrl,
                    });
                } else {
                    buttons.push({
                        type: "URL",
                        text: e.websiteName,
                        url: e.webUrl,
                        example: [
                            e.variable1
                        ]
                    });
                }
            }

            // Handle buttonTwo
            if (value.buttonTwo === 'quickReply') {
                buttons.push({
                    type: "QUICK_REPLY",
                    text: e.buttonTwo,
                });
            } else if (value.buttonTwo === 'mobileNumber') {
                buttons.push({
                    type: "PHONE_NUMBER",
                    text: e.phoneInputTwo1,
                    phone_number: code2 + e.phoneInputTwo2
                });
            } else if (value.buttonTwo === 'weburl') {
                if (values?.webUrlDropdownTwo === 'staticUrl') {
                    buttons.push({
                        type: "URL",
                        text: e.websiteNameTwo,
                        url: e.webUrlTwo,
                    });
                } else {
                    buttons.push({
                        type: "URL",
                        text: e.websiteNameTwo,
                        url: e.webUrlTwo,
                        example: [
                            e.variable2
                        ]
                    });
                }
            }

            const bodyComponent = {
                type: "BODY",
                text: e.carouselBody,
                ...(sampleVariable && sampleVariable.length > 0 ? {
                    example: {
                        body_text: [sampleVariable]
                    }
                } : {})
            };


            return {
                components: [
                    {
                        type: "HEADER",
                        format: value.carouselType === 'image carousel' ? 'IMAGE' : 'VIDEO',
                        example: {
                            header_handle: [mediaUrl]

                        }
                    },
                    {
                        ...bodyComponent
                    },
                    {
                        type: "BUTTONS",
                        buttons: buttons
                    }
                ]
            };
        });

        if (edit) {
            if (item.card) {
                const buttons = values?.sampleVariables.map((e: any, index: number) => ({
                    key: index + 1,
                    value: e.name
                }));
               const updatedAllMedia = allMedia?.map((items: any, index: number) =>
                    index === item?.index? sendMedia && sendMedia?.url ? sendMedia : items : items 
                );
                 const updateCode = codeArr?.map((subArray: any[], index: number) => {
                    return index === item?.index ? codeValue : subArray;
                });

                const updatedAllTemplate = allTemplate.map((items: any, index: number) =>
                    index === item?.index ? { id: index, index: index, card: cards[0], variable: buttons, countryCode: updateCode } : items
                );

                const updateVariables = allVariables.map((item: any, index: number) => {
                    return index === item?.index ? buttons : item;
                });

                setCountryCode(updateCode);
                setVariables(updateVariables);
                setAllMedia(updatedAllMedia);
                setAllTemplate(updatedAllTemplate);
                handleAddItem(updatedAllMedia, updatedAllTemplate, updateVariables, updateCode);
            }
        } else {
            let buttons = values?.sampleVariables?.map((e: any, index: number) => ({
                key: index + 1,
                value: e.name
            }));

            const updateCode = [...codeArr, codeValue];
            const updatedVariable = [...allVariables, buttons];
            const updatedAllMedia = [...allMedia, media];
            const updatedAllTemplate = [...allTemplate, { id: allTemplate.length, index: allTemplate.length, card: cards[0], variable: buttons, countryCode: updateCode }];
            setVariables(updatedVariable);
            setAllMedia(updatedAllMedia);
            setAllTemplate(updatedAllTemplate);
            setCountryCode(updateCode);

            handleAddItem(updatedAllMedia, updatedAllTemplate, updatedVariable, updateCode);
        }

        setMedia('');
        closeModal();
    };

    const customSelect = {
        control: (provided: any, state: any) => ({
          ...provided,
          // zIndex: 1000,
          backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
          caretColor: 'transparent',
          height: "40px",
          width: "200px",
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
        }),
        singleValue: (provided: any, state: any) => ({
          ...provided,
          color: state.isDisabled ? 'black' : provided.color,
        }),
        menu: (provided: any) => ({
          ...provided,
          width: "95%",
  
        }),
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 9999,  // Ensures the portal menu renders on top
        }),
      };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(value)}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {({ values, setFieldValue, handleChange }) => (
                <Form>
                    <div>
                        <h6 className="pt-2">Card Header<span className="required" /></h6>
                        <div className="headerbox col-5 p-2">
                            {files.length > 0 || item ? (
                                (files.length > 0 ?
                                    files.map((file: any, index) => (
                                        <div
                                            className={`col-3 ${value?.carouselType === "image carousel"
                                                ? "positionImage"
                                                : "positionVideo"
                                                }`}
                                        >
                                            {value?.carouselType === "image carousel" && (
                                               <> <img
                                                    key={index}
                                                    id="target"
                                                    src={file.imgUrl}
                                                    width={200}
                                                    height={150}
                                                    alt="oldimage"
                                                />
                                                {/* <span className="h6 mt-2">{file?.file?.name}</span> */}
                                                </>
                                            )}
                                            {value?.carouselType === "video carousel" && (
                                                <video
                                                    key={index}
                                                    src={file.imgUrl}
                                                    controls
                                                    width={300}
                                                    height={200}
                                                />
                                            )}
                                        </div>
                                    )) :
                                    <div
                                        className={`col-3 ${value?.carouselType === "image carousel"
                                            ? "positionImage"
                                            : "positionVideo"
                                            }`}
                                    >
                                        {value?.carouselType === "image carousel" && (
                                            <><img
                                                // key={index}
                                                id="target"
                                                src={item?.card ? item?.card?.components[0]?.example.header_handle[0] : item[1]}
                                                width={200}
                                                height={150}
                                                alt="oldimage"
                                            />
                                            {/* <p>{file?.name}</p> */}
                                            </>
                                        )}
                                        {value?.carouselType === "video carousel" && (
                                            <video
                                                // key={index}
                                                src={item?.card ? item?.card?.components[0]?.example.header_handle[0] : item[1]}
                                                controls
                                                width={300}
                                                height={200}
                                            />
                                        )}
                                    </div>

                                )
                            ) :
                                <>
                                    {value?.carouselType === "image carousel" && (
                                        <p>
                                            Accepted file types : .JPEG, .JPG, .PNG
                                            within 2MB size
                                        </p>
                                    )}
                                    {value?.carouselType === "video carousel" && (
                                        <p>
                                            Accepted file types : .mp4, .3gpp within
                                            16MB size
                                        </p>
                                    )}
                                </>
                            }
                            {value?.carouselType === "image carousel" ?
                                <>
                                    <label className="uploadFiles1 mt-2 float right">
                                        <span> {values?.content? 'Change image' : 'Upload image'}
                                            <input
                                                type="file"
                                                className="hideFileInput"
                                                accept=".jpeg,.jpg,.png"
                                                onChange={(event) =>
                                                    handleFileChange(
                                                        event.target.files, setFieldValue
                                                    )
                                                }
                                            /> </span> </label></> :
                                <>
                                    <label className="uploadFiles1   float-right">
                                        <span>  {values?.content? 'Change video' : 'Upload video' }
                                            <input
                                                type="file"
                                                className="hideFileInput"
                                                accept=".mp4,.3gpp"
                                                onChange={(event) =>
                                                    handleFileChange(
                                                        event.target.files, setFieldValue
                                                    )
                                                }
                                            />
                                        </span>
                                    </label>
                                </>}
                        </div>
                        <ErrorMessage
                            name="content"
                            component="div"
                            className="text-danger pt-1"
                        />
                        <div>
                            <h6 className="pt-2">
                                Card Body
                                <span className="required" />
                            </h6>
                            <div className={`headerbox `}>
                                <div className="bodyOptions">
                                    <Label className="mr-2 mt-1">
                                        Variables{" "}
                                        <TooltipReference
                                            placement="right"
                                            icon={faInfoCircle}
                                            content="This is text that you specify in the API that will be personalised to the customer, such as their name or invoice number."
                                            tooltipId="sampleVariables"
                                        />{" "}
                                        :
                                    </Label>
                                    <div className="btn-group">
                                        <Button
                                            type="button"
                                            onClick={() => handleVariableClick(values, "Name", setFieldValue)}
                                            className="btn-sm mr-2"
                                        >
                                            Name
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => handleVariableClick(values, "Email", setFieldValue)}
                                            className="btn-sm mr-2"
                                        >
                                            Email
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => handleVariableClick(values, "Phone", setFieldValue)}
                                            className="btn-sm mr-2"
                                        >
                                            Phone
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => handleVariableClick(values, "Custom", setFieldValue)}
                                            className="btn-sm"
                                            disabled={type === "view"}
                                        >
                                            Custom
                                        </Button>
                                    </div>
                                </div>
                                <div className="seperationLine"></div>
                                <FormGroup>
                                    <Field name="carouselBody">
                                        {({ field }: FieldProps & { form: FormikProps<any> }) => (
                                            <div>
                                                <textarea
                                                    ref={textareaRef}
                                                    {...field}
                                                    placeholder="Hey {{Name}}! 👋 
                            Explore the {{Brand_Name}} collection for a touch of brilliance. Get started now: Shop Here 🚀🌟"
                                                    maxLength={160}
                                                    className={`form-control tempInputs mt-2 iconFontSize`}
                                                    onMouseUp={handleMouseUp}
                                                    onChange={(event) => {
                                                        handleTextareaChange(event, field, setFieldValue, values);
                                                    }}
                                                    onPaste={(event) => {
                                                        handleTextareaPaste(event, field, setFieldValue, values);
                                                    }}
                                                    disabled={type === "view"}
                                                />
                                                <div className="float-right text-muted small">
                                                    {characterCount} / 160
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                </FormGroup>
                                {/* <div className="seperationLine"></div>
                                <div className="mt-2">
                                    <button
                                        type="button"
                                        className="hideBorder mr-2"
                                        onClick={() => handleFormatClick(values.carouselBody, "bold")}
                                        disabled={type === "view"}
                                    >
                                        <strong>B</strong>
                                    </button>
                                    <button
                                        type="button"
                                        className="hideBorder mr-2"
                                        onClick={() => handleFormatClick(values.carouselBody, "italic")}
                                        disabled={type === "view"}
                                    >
                                        <em>I</em>
                                    </button>
                                    <button
                                        type="button"
                                        className="hideBorder mr-2"
                                        onClick={() => handleFormatClick(values.carouselBody, "strikethrough")}
                                        disabled={type === "view"}
                                    >
                                        <del>S</del>
                                    </button>
                                    <button
                                        id="popemoji"
                                        type="button"
                                        className="hideBorder"
                                        onClick={(event) => {
                                            setPopoverOpen(true);
                                            setpopovertarget(event.currentTarget);
                                        }}
                                        disabled={type === "view"}
                                    >
                                        <FontAwesomeIcon
                                            icon={faFaceSmile}
                                            id="convAddEmoji"
                                            className="text-black"
                                            color="#90939F"
                                            fontSize={14}
                                        />
                                    </button>
                                    <div ref={pickerRef}>
                                        <Popover
                                            isOpen={popoverOpen}
                                            target="popemoji"
                                            toggle={() => {
                                                setPopoverOpen(false);
                                                setpopovertarget(null);
                                            }}
                                            placement="right"
                                            className="emoji-popover"
                                        >
                                            <PopoverBody>
                                                <div ref={pickerRef}>
                                                    <Picker
                                                        data={customData}
                                                        onEmojiSelect={(e: any) => {
                                                            handleEmojiSelect(values.carouselBody, e);
                                                        }}
                                                    />
                                                </div>
                                            </PopoverBody>
                                        </Popover>
                                    </div>
                                </div> */}
                            </div>
                            <ErrorMessage
                                name="carouselBody"
                                component="div"
                                className="text-danger pt-1"
                            />
                            {values?.sampleVariables?.length > 0 && (
                                <div className="dragHeader">
                                    Please declare the values for variables to get approve from whatsapp team
                                </div>
                            )}
                            {values?.sampleVariables && values?.sampleVariables?.map(
                                (
                                    variable: { name: string; id: string; value: string },
                                    ind: number
                                ) => (
                                    <div key={ind} className="bodyOptions mt-2">
                                        <FormGroup>
                                            {ind === 0 ? <Label>Variable Name</Label> : ""}
                                            <Field
                                                type="text"
                                                name={`carouselvariableName_${ind}`}
                                                value={variable.name}
                                                className="form-control tempInputsBorder mt-2 formcontrol"
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup className="seperateSample">
                                            {ind === 0 ? (
                                                <Label>
                                                    Sample Value<span className="required"></span>
                                                </Label>
                                            ) : (
                                                ""
                                            )}
                                            <Field
                                                type={
                                                    variable.name === "Name"
                                                        ? "text"
                                                        : variable.name === "Email"
                                                            ? "email"
                                                            : variable.name === "Phone"
                                                                ? "number"
                                                                : "text"
                                                }
                                                name={`caraouselsampleValue_${ind}`}
                                                placeholder={
                                                    variable.name === "Name"
                                                        ? "Ex: David, Priya"
                                                        : variable.name === "Email"
                                                            ? "Ex: hello@gmail.com"
                                                            : variable.name === "Phone"
                                                                ? "Ex: 9876556423"
                                                                : `Content for {{${variable.name}}}`
                                                }
                                                className="form-control tempInputsBorder mt-2 formcontrol"
                                                value={variable.value}
                                                onChange={(e: any) => {
                                                    handleSampleVariable(ind, e.target.value, setFieldValue, values);
                                                }}
                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                    e.currentTarget.blur()
                                                }
                                                disabled={type === "view"}
                                            />
                                        </FormGroup>
                                    </div>
                                )
                            )}
                            <ErrorMessage
                                name="sampleVariables"
                                component="div"
                                className="text-danger pt-1"
                            />
                        </div>

                        <div className="headerbox">
                            <div>
                                {value.buttonOne === 'mobileNumber' ?
                                    <div className="actionBtns mt-2">
                                        <FormGroup>
                                            <Label className="mt-2">
                                                <span className="mr-2">Phone<span className="required" /></span>
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className="mr-3">
                                            <Field
                                                type="text"
                                                id="phoneInput1"
                                                name="phoneInput1"
                                                style={{
                                                    backgroundColor: "transparent",
                                                }}
                                                placeholder="Ex: Call us"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    restrictText(event, setFieldValue, 20);
                                                    setCharphone(event.target.value.length);
                                                }}
                                                className="form-control formcontrol tempInputsBorder"
                                                disabled={type === "view"}
                                            />
                                            <span className="float-right small text-muted">
                                                {charphone} / 20
                                            </span>
                                            <ErrorMessage
                                                name="phoneInput1"
                                                component="div"
                                                className="text-danger pt-1"
                                            />
                                        </FormGroup>
                                        <div className="form-group mobile-login-flag col-md-6.5 ml-1">
                                            <div className="input-group ">
                                                <div className="input-group-prepend ml-2">
                                                    <FlagInput
                                                        countryCode={code}
                                                        codeChange={setCode}

                                                    />
                                                    {/* <p>Code 1: {code}</p>
                                             <p>Code 2: {code2}</p> */}
                                                    {/* {type === 'view'  || type === 'edit' || type === 'create' ? '+1' : '+91'} */}
                                                </div>
                                                <Field
                                                    type="text"
                                                    name="phoneInput2"
                                                    id="phoneInput2"
                                                    style={{
                                                        backgroundColor: "transparent",
                                                    }}
                                                    className="form-control  formcontrol"
                                                    placeholder="Ex: 9988776655"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = e.target.value.replace(/[^\d]/g, "");
                                                        if (value.length < 27) {
                                                            setFieldValue("phoneInput2", value);
                                                            setCountnum(value.length);
                                                        }
                                                    }}
                                                    disabled={
                                                        type === "view" ||
                                                        values.phoneInput1 === ""
                                                    }
                                                />
                                            </div>
                                            <span className="float-right small text-muted">
                                                {charnum} / 26
                                            </span>

                                            <ErrorMessage
                                                name="phoneInput2"
                                                component="div"
                                                className="text-danger pt-1"
                                            />
                                        </div> </div> :
                                    value.buttonOne === 'weburl' ?

                                        <div>
                                            <div className="headerOptions urlBtns mt-3">
                                                <FormGroup>
                                                    <Label className="mt-2">
                                                        <span className="mr-4">URL<span className="required" /></span>
                                                    </Label>
                                                </FormGroup>
                                                <div className="row">
                                                    <div className="d-flex">
                                                            <FormGroup className="mr-2 ml-1">
                                                                <Field name="webUrlDropdown">
                                                                    {({ field, form }: any) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={webUrlOptions}
                                                                            onChange={(selectedOption: SelectBtnOption) => {
                                                                                form.setFieldValue(
                                                                                    "webUrlDropdown",
                                                                                    selectedOption?.value
                                                                                );
                                                                                form.setFieldValue("variable", "");
                                                                                form.setFieldValue("websiteName", "");
                                                                                form.setFieldValue("webUrl", "");
                                                                            }}
                                                                            value={webUrlOptions.find(
                                                                                (option) => option.value === field.value
                                                                            )}
                                                                            isDisabled={
                                                                                type === "view"
                                                                            }
                                                                        styles={customSelect}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage name="webUrlDropdown"
                                                                    component="div"
                                                                    className="text-danger pt-1"></ErrorMessage>
                                                            </FormGroup>
                                                        <FormGroup className="mr-1 col-4">
                                                            <Field
                                                                type="text"
                                                                id="websiteName"
                                                                name="websiteName"
                                                                className="form-control tempInputsBorder formcontrol"
                                                                disabled={type === "view"}
                                                                placeholder="Ex: Visit us"
                                                                onChange={(
                                                                    event: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    restrictText(event, setFieldValue, 20);
                                                                    setUrlCount(event.target.value.length);
                                                                }}
                                                            />
                                                            <span className="float-right small text-muted">
                                                                {urlCount} / 20
                                                            </span>
                                                            <ErrorMessage
                                                                name="websiteName"
                                                                component="div"
                                                                className="text-danger pt-1"
                                                            />
                                                        </FormGroup>
                                                        <div className="col-6">
                                                            <FormGroup className="mr-2">
                                                                <div>
                                                                    <Field
                                                                        type="text"
                                                                        id="webUrl"
                                                                        name="webUrl"
                                                                        // style={{
                                                                        //     backgroundColor: values?.webUrlChecked
                                                                        //         ? "white"
                                                                        //         : "transparent",
                                                                        // }}
                                                                        className="form-control tempInputsBorder formcontrol "
                                                                        disabled={type === "view"}
                                                                        placeholder={
                                                                            values?.webUrlDropdown === "staticUrl"
                                                                                ? "Ex: http:websitename.com"
                                                                                : "Ex: http:websitename.com/{{1}}"
                                                                        }
                                                                        onChange={(
                                                                            event: React.ChangeEvent<HTMLInputElement>
                                                                        ) => {
                                                                            if (values.webUrl.includes("{{1}}")) {
                                                                                restrictText(
                                                                                    event,
                                                                                    setFieldValue,
                                                                                    values.webUrl.length
                                                                                );
                                                                            } else {
                                                                                restrictText(event, setFieldValue, 2000);
                                                                                setwebCount(event.target.value.length);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <span className="float-right small text-muted">
                                                                        {weburlCount} / 2000
                                                                    </span>

                                                                    <ErrorMessage
                                                                        name="webUrl"
                                                                        component="div"
                                                                        className="text-danger pt-1"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="offset-md-3">
                                                {values.webUrlDropdown === "dynamicUrl" ? (
                                                    <FormGroup className="col-8">
                                                        <div className="d-flex var-align">
                                                            <span className="mr-2 text-dark mt-2 dynamic-variable">
                                                                {"Sample Variable :"}
                                                            </span>
                                                            <Field
                                                                type="text"
                                                                id="variable1"
                                                                name="variable1"
                                                                className="form-control col-4"
                                                                placeholder="Content for variable"
                                                                disabled={type === "view"}
                                                                onChange={(
                                                                    event: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    restrictText(event, setFieldValue, 20);
                                                                    setVarCount(event.target.value.length);
                                                                    if (!values.webUrl.includes("{{1}}")) {
                                                                        setFieldValue(
                                                                            "webUrl",
                                                                            values.webUrl ? values.webUrl + "{{1}}" : ""
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <span className="offset-md-4 text-muted small mt-2">
                                                            {" "}
                                                            {varCount}/ 20
                                                        </span>
                                                        <ErrorMessage
                                                            name="variable1"
                                                            component="div"
                                                            className="text-danger pt-1 offset-md-1 col-4"
                                                        />
                                                    </FormGroup>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        :
                                        <div className="">
                                            <div className="actionsBtns d-flex">
                                                <Label className="mt-2 mr-2">Button<span className="required" /></Label>
                                                <FormGroup className="mr-2">
                                                    <Field
                                                        type="text"
                                                        id="1"
                                                        name="buttonOne"
                                                        //   value={"View catalog"}
                                                        className="form-control tempInputsBorder formcontrol"
                                                        placeholder="Ex: Interested, yes, no"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            restrictText(event, setFieldValue, 25);
                                                            setQuickCount1(event?.target?.value?.length)
                                                        }}
                                                    />
                                                    <span className="float-right small text-muted">{quickCount1} / 25</span>
                                                    <ErrorMessage name="buttonOne" component="div" className="text-danger pt-1" />

                                                </FormGroup>
                                            </div>
                                        </div>
                                }
                            </div>

                            <div>
                                {value.buttonTwo === 'mobileNumber' ?
                                    <div className="actionBtns mt-2">
                                        <FormGroup>
                                            <Label className="mt-2">
                                                <span className="mr-2">Phone<span className="required" /></span>
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className="mr-3">
                                            <Field
                                                type="text"
                                                id="phoneInputTwo1"
                                                name="phoneInputTwo1"
                                                style={{ backgroundColor: "transparent" }}
                                                placeholder="Ex: Call us"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    restrictText(event, setFieldValue, 20);
                                                    setCharphoneTwo(event.target.value.length);
                                                }}
                                                className="form-control "
                                                disabled={type === "view"}
                                            />
                                            <span className="float-right small text-muted">{charphoneTwo} / 20</span>
                                            <ErrorMessage name="phoneInputTwo1" component="div" className="text-danger pt-1" />
                                        </FormGroup>
                                        <div className="form-group mobile-login-flag col-md-6.5 ml-1">
                                            <div className="input-group">
                                                <div className="input-group-prepend ml-2">
                                                    <FlagInput countryCode={code2} codeChange={setCode2} />
                                                    {/* {type === 'view'  || type === 'edit' || type === 'create' ? '+1' : '+91'} */}
                                                </div>
                                                <Field
                                                    type="text"
                                                    name="phoneInputTwo2"
                                                    id="phoneInputTwo2"
                                                    style={{ backgroundColor: "transparent" }}
                                                    className="form-control formcontrol"
                                                    placeholder="Ex: 9988776655"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const value = e.target.value.replace(/[^\d]/g, "");
                                                        if (value.length < 27) {
                                                            setFieldValue("phoneInputTwo2", value);
                                                            setCountnumTwo(value.length);
                                                        }
                                                    }}
                                                    disabled={type === "view" || values.phoneInputTwo1 === ""}
                                                />
                                            </div>
                                            <span className="float-right small text-muted">{charnumTwo} / 26</span>
                                            <ErrorMessage name="phoneInputTwo2" component="div" className="text-danger pt-1" />
                                        </div>
                                    </div>
                                    : value.buttonTwo === 'weburl' ?
                                        <div>
                                            <div className="headerOptions urlBtns mt-3">
                                                <FormGroup>
                                                    <Label className="mt-2">
                                                        <span className="mr-4">URL<span className="required" /></span>
                                                    </Label>
                                                </FormGroup>
                                                <div className="row">
                                                    <div className="d-flex">
                                                            <FormGroup className="mr-2 ml-1">
                                                                <Field name="webUrlDropdownTwo">
                                                                    {({ field, form }: any) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={webUrlOptions}
                                                                            onChange={(selectedOption: SelectBtnOption) => {
                                                                                form.setFieldValue("webUrlDropdownTwo", selectedOption?.value);
                                                                                form.setFieldValue("variable", "");
                                                                                form.setFieldValue("websiteNameTwo", "");
                                                                                form.setFieldValue("webUrlTwo", "");
                                                                            }}
                                                                            value={webUrlOptions.find(option => option.value === field.value)}
                                                                            isDisabled={type === "view"}
                                                                            styles={customSelect}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage name="webUrlDropdownTwo"
                                                                    component="div"
                                                                    className="text-danger pt-1"></ErrorMessage>
                                                            </FormGroup>
                                                        <FormGroup className="mr-1 col-4">
                                                            <Field
                                                                type="text"
                                                                id="websiteNameTwo"
                                                                name="websiteNameTwo"
                                                                className="form-control tempInputsBorder formcontrol"
                                                                disabled={type === "view"}
                                                                placeholder="Ex: Visit us"
                                                                // style={{ backgroundColor: values?.webUrlCheckedTwo ? "white" : "transparent" }}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    restrictText(event, setFieldValue, 20);
                                                                    setUrlCountTwo(event.target.value.length);
                                                                }}
                                                            />
                                                            <span className="float-right small text-muted">{urlCountTwo} / 20</span>
                                                            <ErrorMessage name="websiteNameTwo" component="div" className="text-danger pt-1" />
                                                        </FormGroup>
                                                        <div className="col-6">
                                                            <FormGroup className="mr-2 ">
                                                                <div>
                                                                    <Field
                                                                        type="text"
                                                                        id="webUrlTwo"
                                                                        name="webUrlTwo"
                                                                        // style={{ backgroundColor: values?.webUrlCheckedTwo ? "white" : "transparent" }}
                                                                        className="form-control tempInputsBorder formcontrol"
                                                                        disabled={type === "view"}
                                                                        placeholder={values?.webUrlDropdownTwo === "staticUrl" ? "Ex: http:websitename.com" : "Ex: http:websitename.com/{{1}}"}
                                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                            if (values.webUrlTwo.includes("{{1}}")) {
                                                                                restrictText(event, setFieldValue, values.webUrlTwo.length);
                                                                            } else {
                                                                                restrictText(event, setFieldValue, 2000);
                                                                                setwebCountTwo(event.target.value.length);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <span className="float-right small text-muted">{weburlCountTwo} / 2000</span>
                                                                    <ErrorMessage name="webUrlTwo" component="div" className="text-danger pt-1" />
                                                                </div>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="offset-md-3">
                                                {values.webUrlDropdownTwo === "dynamicUrl" ? (
                                                    <FormGroup className="col-8">
                                                        <div className="d-flex var-align">
                                                            <span className="mr-2 text-dark mt-2 dynamic-variable">
                                                                {"Sample Variable :"}
                                                            </span>
                                                            <Field
                                                                type="text"
                                                                id="variable2"
                                                                name="variable2"
                                                                className="form-control col-4"
                                                                placeholder="Content for variable"
                                                                disabled={type === "view"}
                                                                onChange={(
                                                                    event: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    restrictText(event, setFieldValue, 20);
                                                                    setVarCountTwo(event.target.value.length);
                                                                    if (!values.webUrlTwo.includes("{{1}}")) {
                                                                        setFieldValue(
                                                                            "webUrlTwo",
                                                                            values.webUrlTwo ? values.webUrlTwo + "{{1}}" : ""
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <span className="offset-md-4 text-muted small mt-2">
                                                            {" "}
                                                            {varCountTwo}/ 20
                                                        </span>
                                                        <ErrorMessage
                                                            name="variable2"
                                                            component="div"
                                                            className="offset-md-1 text-danger pt-1  col-4"
                                                        />
                                                    </FormGroup>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        : value.buttonTwo === 'quickReply' ?
                                            <div className="">
                                                <div className="actionsBtns d-flex">
                                                    <Label className="mt-2 mr-2">Button <span className="required" /></Label>
                                                    <FormGroup className="mr-2">
                                                        <Field
                                                            type="text"
                                                            id="2"
                                                            name="buttonTwo"
                                                            className="form-control tempInputsBorder formcontrol"
                                                            placeholder="Ex: Interested, yes, no"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                restrictText(event, setFieldValue, 25);
                                                                setQuickCount2(event?.target?.value?.length)
                                                            }}
                                                        />
                                                        <span className="float-right small text-muted">{quickCount2} / 25</span>
                                                        <ErrorMessage name="buttonTwo" component="div" className="text-danger pt-1" />
                                                    </FormGroup>
                                                </div>
                                            </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <Button type="button" className="cancelButton mr-2" onClick={() => closeModal()}>Cancel</Button>
                    <Button type="submit" className="sendButton" disabled={type === "view" || loading} title={loading ? "Image Not Uploaded" : ''}>Submit</Button>
                </Form>
            )
            }
        </Formik>
    )
}

export default Carousel;