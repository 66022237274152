import { IoCheckboxOutline } from "react-icons/io5";
import { RxText } from "react-icons/rx";
import { PiParagraph } from "react-icons/pi";
import { CiCalendarDate } from "react-icons/ci";
import { MdFormatListNumbered } from "react-icons/md";
import { IoIosArrowDropdown } from "react-icons/io";
import { VscSymbolEnumMember } from "react-icons/vsc";

// types.ts
export interface Item {
  id: string | number;
  name: string;
  label: string;
  position: number;
  options: any[];
  isDefault: boolean;
  type: string;
  createdAt: string;
  updatedAt: string | null;
  deleted: boolean;
  deletedAt: string | null;
  dropDown: boolean;
}

interface SelectCategory {
  value: string;
  label: string;
}

export const initialStaticItems: Item[] = [
  {
    id: "item-1",
    name: "checkbox",
    label: "checkbox",
    position: 0,
    options: [],
    isDefault: false,
    type: "checkbox",
    createdAt: new Date().toISOString(),
    updatedAt: null,
    deleted: false,
    deletedAt: null,
    dropDown: false,
  },
  {
    id: "item-2",
    name: "text",
    label: "text",
    position: 0,
    options: [],
    isDefault: false,
    type: "text",
    createdAt: new Date().toISOString(),
    updatedAt: null,
    deleted: false,
    deletedAt: null,
    dropDown: false,
  },
  {
    id: "item-3",
    name: "paragraph",
    label: "paragraph",
    position: 0,
    options: [],
    isDefault: false,
    type: "paragraph",
    createdAt: new Date().toISOString(),
    updatedAt: null,
    deleted: false,
    deletedAt: null,
    dropDown: false,
  },
  {
    id: "item-4",
    name: "date",
    label: "date",
    position: 0,
    options: [],
    isDefault: false,
    type: "date",
    createdAt: new Date().toISOString(),
    updatedAt: null,
    deleted: false,
    deletedAt: null,
    dropDown: false,
  },
  {
    id: "item-5",
    name: "number",
    label: "number",
    position: 0,
    options: [],
    isDefault: false,
    type: "number",
    createdAt: new Date().toISOString(),
    updatedAt: null,
    deleted: false,
    deletedAt: null,
    dropDown: false,
  },
  {
    id: "item-6",
    name: "dropdown",
    label: "dropdown",
    position: 0,
    options: [],
    isDefault: false,
    type: "dropdown",
    createdAt: new Date().toISOString(),
    updatedAt: null,
    deleted: false,
    deletedAt: null,
    dropDown: false,
  },
];

export const getItemIcons = (itemName: string) => {
  if (itemName.includes("checkbox")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#dff5f1",
          borderRadius: "3px",
        }}
      >
        <IoCheckboxOutline size={20} />
      </div>
    );
  } else if (itemName.includes("text")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fef1e1",
          borderRadius: "3px",
        }}
      >
        <RxText size={20} />
      </div>
    );
  } else if (itemName.includes("paragraph")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#e5f2fe",
          borderRadius: "3px",
        }}
      >
        <PiParagraph size={20} />
      </div>
    );
  } else if (itemName.includes("date")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fdfee1",
          borderRadius: "3px",
        }}
      >
        <CiCalendarDate size={20} />
      </div>
    );
  } else if (itemName.includes("number")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#f2e5fe",
          borderRadius: "3px",
        }}
      >
        <MdFormatListNumbered size={20} />
      </div>
    );
  } else if (itemName.includes("dropdown")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#f2e5fe",
          borderRadius: "3px",
        }}
      >
        <IoIosArrowDropdown size={20} />
      </div>
    );
  } else if (itemName.includes("subject")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fef1e1",
          borderRadius: "3px",
        }}
      >
        <RxText size={20} />
      </div>
    );
  } else if (itemName.includes("description")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fef1e1",
          borderRadius: "3px",
        }}
      >
        <RxText size={20} />
      </div>
    );
  } else if (itemName.includes("priority")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fee5e7",
          borderRadius: "3px",
        }}
      >
        <IoIosArrowDropdown size={20} />
      </div>
    );
  } else if (itemName.includes("type")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fee5e7",
          borderRadius: "3px",
        }}
      >
        <IoIosArrowDropdown size={20} />
      </div>
    );
  } else if (itemName.includes("status")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fee5e7",
          borderRadius: "3px",
        }}
      >
        <IoIosArrowDropdown size={20} />
      </div>
    );
  } else if (itemName.includes("team")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fee5e7",
          borderRadius: "3px",
        }}
      >
        <IoIosArrowDropdown size={20} />
      </div>
    );
  } else if (itemName.includes("member")) {
    return (
      <div
        style={{
          padding: "3px 4px",
          background: "#fee5e7",
          borderRadius: "3px",
        }}
      >
        <IoIosArrowDropdown size={20} />
      </div>
    );
  }
};

export const tagsOption: SelectCategory[] = [
  { value: "one", label: "One" },
  { value: "two", label: "Two" },
  { value: "three", label: "Three" },
  { value: "four", label: "Four" },
];

export const statusOption: SelectCategory[] = [
  { value: "open", label: "Open" },
  { value: "pending", label: "Pending" },
  { value: "closed", label: "Closed" },
  { value: "resolved", label: "Resolved" },
];

export const typeOption: SelectCategory[] = [
  { value: "", label: "--" },
  { value: "question", label: "Open" },
  { value: "incident", label: "Pending" },
  { value: "problem", label: "Closed" },
  { value: "featureRequest", label: "Feature Request" },
];

export const priorityOption: SelectCategory[] = [
  { value: "low", label: "Low" },
  { value: "medium", label: "Medium" },
  { value: "high", label: "High" },
  { value: "urgent", label: "Urgent" },
];
