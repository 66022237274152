import * as React from "react";
import {
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import DefaultCampaign from "./DefaultCampaign";
import NewCommunity from "./NewCommunity";
import ExistCommunity from "./ExistCommunity";
import CampaignList from "./CampaignList";
import campaignContext, { IRepeatCampaignData } from "./campaignContext";
import { useSelector } from "react-redux";

interface CampaignState {
  newCommunity: boolean;
  existCommunity: boolean;
  success: string;
  checked: any;
  selectedContact: any;
  uploadContacts: boolean;
  EditCommunityData: any;
  excelContactList: any;
  dataShowManual: any;
  dataShowExcel: any;
  excelFileName: string;
  dataTransfer: boolean;
  checkboxExist: any;
  check_community_uid: any;
  contactUpdate: {
    contacts: [];
    id: string;
  };
  createCampaign: boolean;
  isCallbackUploadExcel: boolean;
  isCallbackValidExcel: boolean;
  isRepeatCampaign: boolean;

  // Gloabl campaign states
  repeatCampaign: null | IRepeatCampaignData;
  isInvalidAccess: boolean;
}

interface MainCampaignProps {
  EditCommunityData?: any;
  callbackChecked?: any;
  selectedContactData?: any;
  checkboxExistCommunity?: any;
  checkboxCommunityUid?: any;
  createCampaign?: boolean;
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Params;
  };
  navigate: any;
  memberUid: string;
  removeExistData:any;
}

class Campaign extends React.Component<MainCampaignProps, CampaignState> {
  constructor(props: MainCampaignProps) {
    super(props);
    this.state = {
      createCampaign: false,
      newCommunity: false,
      existCommunity: false,
      uploadContacts: false,
      selectedContact: "",
      success: "",
      checked: [],
      EditCommunityData: "",
      excelContactList: [],
      dataShowManual: [],
      dataShowExcel: [],
      excelFileName: "",
      dataTransfer: false,
      checkboxExist: [],
      check_community_uid: [],
      contactUpdate: {
        contacts: [],
        id: "",
      },
      isCallbackUploadExcel: false,
      isCallbackValidExcel: false,
      isRepeatCampaign: false,

      // Gloabl state
      repeatCampaign: null,
      isInvalidAccess: false
    };

  }

  componentDidMount(): void {
    const {search, pathname, state } = this.props.router.location as any;
    const isInvalidAccess = state?.isInvalidAccess;
    if(isInvalidAccess !== this.state.isInvalidAccess) {
      this.setState({isInvalidAccess: isInvalidAccess});
    }
    const queryParams = new URLSearchParams(search);
    const create = queryParams.get('create');
    const trimmedPathname = pathname.replace(/\/$/, '');
    if (trimmedPathname === '/campaign/newcampaign') {
      this.setState({ createCampaign: true })
    }


    if (this.props.EditCommunityData) {
      this.setState({
        EditCommunityData: this.props.EditCommunityData,
        selectedContact: this.props.selectedContactData,
        checked: this.props.callbackChecked,
        dataTransfer: true,
        checkboxExist: this.props.checkboxExistCommunity,
        check_community_uid: this.props.checkboxCommunityUid,
      });
      if (this.state.existCommunity === true) {
        this.communitySelect("existCommunity");
      } else {
        this.communitySelect("newCommunity");
      }
    }
    if (this.props.createCampaign) {
      this.setState({ createCampaign: this.props.createCampaign })
    }

    if (create === 'true') {
      this.setState({
        createCampaign: true,
      });
    }
  }
  componentDidUpdate(
    prevProps: Readonly<MainCampaignProps>,
    prevState: Readonly<CampaignState>,
    snapshot?: any
  ): void {
    const { pathname, state } = this.props.router.location as any;
    const isInvalidAccess = state?.isInvalidAccess;
    if(isInvalidAccess !== this.state.isInvalidAccess) {
      this.setState({isInvalidAccess: isInvalidAccess});
    }
    
    const trimmedPathname = pathname.replace(/\/$/, '');
    if (prevProps.router !== this.props.router) {
      if (trimmedPathname === '/campaign') {
        this.setState({ createCampaign: false })
      }
      else if (trimmedPathname === '/campaign/newcampaign') {
        this.setState({ createCampaign: true })
      }
    }

    if ((prevState.dataShowManual !== this.state.dataShowManual) && this.state.createCampaign) {
      if (Object.keys(this.state.dataShowManual).length > 0) {
        const { check } = this.state.checked;
        const updatedChecked = {
          ...this.state.checked, // Spread the existing checked object
          check: {
            ...check, // Spread the existing 'check' object
            selectContact: 'newCommunity'
          }
        };
        this.setState({
          checked: updatedChecked,
        });
        this.backToCampaign();
      }
    }


    if ((prevState?.excelContactList.length !== this.state.excelContactList.length) && !this.state.createCampaign) {
      const { check } = this.state.checked;
      const updatedChecked = {
        ...this.state.checked, // Spread the existing checked object
        check: {
          ...check, // Spread the existing 'check' object
          selectContact: 'upload'
        }
      };
      this.setState({
        checked: updatedChecked,
      });
    }


    if (prevState.contactUpdate !== this.state.contactUpdate) {

      const contactUpdate = this.state.contactUpdate;
      if (contactUpdate && contactUpdate) {
        if (this.state.contactUpdate && this.state.selectedContact.length > 0) {
          const { selectedContact } = this.state;
          const contactId = contactUpdate?.id;
          const updatedSelectedContact = selectedContact.map((item: any) => {
            if (item?.id === contactId) {
              return {
                ...item,
                contacts: [...contactUpdate.contacts],
              };
            } else {
              return {
                ...item,
              };
            }
          });

          this.setState({
            selectedContact: updatedSelectedContact,
            check_community_uid: this.props.checkboxCommunityUid,
          }, () => {
          });
        }
      }
    }

    if (this.state.repeatCampaign && !this.state.isRepeatCampaign) {
      this.setState({
        selectedContact: this.state.repeatCampaign?.communityList,
        isRepeatCampaign: true,
        checked: {
          ...this.state.checked,
          check: {
            ...this.state.checked.check,
            selectContact: "existCommunity"
          }
        }
      });
    }

  }

  selectedContact = (selectData: any) => {
    this.setState({ selectedContact: selectData });
  };

  updatedContact = (updateData: any) => {
    this.setState({ contactUpdate: updateData });
  };

  backToExist = () => {
    this.setState({
      existCommunity: true,
      newCommunity: false,
      EditCommunityData: "",
    });
  };

  showNewCampaign = (communityDatas: any, status: string) => {
    if (status === "MANUAL") {
      this.setState({
        dataShowManual: communityDatas,
      });
    } else if (status === "EXCEL") {
      this.setState({ dataShowExcel: communityDatas });
    }
  };

  backToCampaign = () => {
    const { check } = this.state.checked;
    this.setState({
      existCommunity: false,
      newCommunity: false,
      excelContactList: "",
      checkboxExist: "",
    });
    if (this.state.dataShowManual.length > 0) {
      if (Object.keys(this.state.dataShowManual).length > 0) {
        const { check } = this.state.checked;
        const updatedChecked = {
          ...this.state.checked, // Spread the existing checked object
          check: {
            ...check, // Spread the existing 'check' object
            selectContact: 'newCommunity'
          }
        };
        this.setState({
          checked: updatedChecked,
          isCallbackUploadExcel: false,
          isCallbackValidExcel: false
        });

      }
    }
    else if ((this.state.selectedContact?.length > 0)) {
      const { check } = this.state.checked;
      const updatedChecked = {
        ...this.state.checked, // Spread the existing checked object
        check: {
          ...check, // Spread the existing 'check' object
          selectContact: 'existCommunity'
        }
      };
      this.setState({
        checked: updatedChecked,
        isCallbackUploadExcel: false,
        isCallbackValidExcel: false
      });
    }

    else if (this.state.excelContactList.length > 0) {
      const { check } = this.state.checked;
      const updatedChecked = {
        ...this.state.checked, // Spread the existing checked object
        check: {
          ...check, // Spread the existing 'check' object
          selectContact: 'upload'
        }
      };
      this.setState({
        checked: updatedChecked,
        isCallbackUploadExcel: true,
        isCallbackValidExcel: true,
      });
    }
    else {
      if (Object.keys(this.state.dataShowManual).length > 0 || this.state.selectedContact?.length > 0 || this.state.excelContactList.length > 0) {
        return

      }
      else {

        const { check } = this.state.checked;
        const updatedChecked = {
          ...this.state.checked, // Spread the existing checked object
          check: {
            ...check, // Spread the existing 'check' object
            selectContact: ''
          }
        };
        this.setState({
          checked: updatedChecked,
        });
      }
    }


    //       const updatedChecked = {
    //         ...this.state.checked, // Spread the existing checked object
    //         check: {
    //           ...check, // Spread the existing 'check' object
    //           selectContact:
    //           check?.selectContact==='existCommunity' &&
    //           this.state.selectedContact.length === 0 ||
    //           check?.selectContact==='newCommunity' && Object.keys(this.state.dataShowManual).length === 0
    //         || check?.selectContact==='upload' && this.state.excelContactList.length === 0
    //           ? '':check?.selectContact
    //         }
    //       };
    //         this.setState({
    //           checked: updatedChecked,
    //         });
  };


  communitySelect = (Select: any) => {
    const { check } = this.state.checked;
    switch (Select) {
      case "newCommunity":

        this.setState({
          newCommunity: true,
          // selectedContact: "",
          dataShowExcel: "",
          isCallbackUploadExcel: false,
          isCallbackValidExcel: false
        });

        // const updatedChecked = {
        //   ...this.state.checked, // Spread the existing checked object
        //   check: {
        //     ...check, // Spread the existing 'check' object
        //     selectContact: 'newCommunity'
        //   }
        // };
        if (this.state.dataTransfer === true) {
          this.setState({
            selectedContact: "",
            //  checked: updatedChecked
          });

        }
        break;
      case "existCommunity":
        this.setState({
          existCommunity: true,
          dataShowManual: "",
          dataShowExcel: "",
          dataTransfer: true,
          excelContactList: '',
          isCallbackUploadExcel: false,
          isCallbackValidExcel: false
        });
        // const updatedData = {
        //   ...this.state.checked, // Spread the existing checked object
        //   check: {
        //     ...check, // Spread the existing 'check' object
        //     selectContact: 'existCommunity'
        //   }
        // };
        // if (this.state.dataTransfer === true) {
        //   this.setState({ selectedContact: "", checked: updatedData, });
        // }
        break;
      case "uploadContacts":
        this.setState({
          uploadContacts: true,
          dataShowManual: "",
          selectedContact: "",
        });
        break;
      default:
        this.setState({ existCommunity: false, checkboxExist: '' });
        break;
    }
  };

  excelData = (dataExcel: any, fileName: string) => {
    this.setState({ excelContactList: dataExcel, excelFileName: fileName });
  };
  updateExcelData = (updateExcel: any) => {
    this.setState({ excelContactList: updateExcel });
  }

  checkedData = (data: any) => {
    this.setState({ checked: data })

    if (data?.check?.selectContact === "newCommunity") {
      this.setState({ excelContactList: '', checked: data, })
    }
    // if(data?.check?.selectContact === "upload"){
    //   this.setState({ checked:data })
    // }
  };

  onGoBack = () => {
    this.props.navigate('/campaign')
    this.handleRemoveAll()
    this.setState({ createCampaign: false })
  }

  handleRemoveAll = () => {
    const { removeExistData } = this.props;
    if (removeExistData) {
      removeExistData();
    }
  };

  setRepeatCampaignHandler = (repeatCampaign: null | IRepeatCampaignData) => {
// console.log("zz",repeatCampaign);

    this.setState({
      repeatCampaign,
      isRepeatCampaign: false
    });
  }

  render() {
    const {
      existCommunity,
      newCommunity,
      uploadContacts,
      EditCommunityData,
      excelContactList,
      dataShowManual,
      checkboxExist,
      check_community_uid,
      createCampaign,
      isCallbackUploadExcel,
      isCallbackValidExcel,
    } = this.state;

    return (
      <campaignContext.Provider value={{
        repeatCampaign: this.state.repeatCampaign,
        setRepeatCampaign: this.setRepeatCampaignHandler
      }}>
        {!createCampaign ? (
          <CampaignList
            onCreateCampaign={() => this.setState({
              createCampaign: true,
              newCommunity: false,
              existCommunity: false,
              uploadContacts: false,
              selectedContact: "",
              success: "",
              checked: [],
              EditCommunityData: "",
              excelContactList: [],
              dataShowManual: [],
              dataShowExcel: [],
              excelFileName: "",
              dataTransfer: false,
              checkboxExist: [],
              check_community_uid: [],
              contactUpdate: {
                contacts: [],
                id: "",
              },
              isCallbackUploadExcel: false,
              isCallbackValidExcel: false
            })}
          />
        ) : (
          newCommunity ? (
            <NewCommunity
              backToExist={this.backToExist}
              EditCommunityData={EditCommunityData}
              backToCampaign={this.backToCampaign}
              excelContactList={excelContactList}
              showNewCampaign={this.showNewCampaign}
              callbackChecked={this.state.checked}
              selectedContactData={this.state.selectedContact}
              updatedContact={this.updatedContact}
              checkboxExistCommunity={checkboxExist}
              iscreateCampaign={this.state.createCampaign}
              updateExcelData={this.updateExcelData}
            // checkedData={this.checkedData}
            />
          ) : existCommunity ? (
            <ExistCommunity
              selectedContactData={this.state.selectedContact}
              selectedContact={this.selectedContact}
              communitySelect={this.communitySelect}
              backToCampaign={this.backToCampaign}
              callbackChecked={this.state.checked}
              checkboxExistCommunity={this.state.checkboxExist}
              check_community_uid={check_community_uid}
              iscreateCampaign={this.state.createCampaign}
              contactUpdate={this.state.contactUpdate}
            />
          ) : (
            <DefaultCampaign
              selectedContact={this.state.selectedContact}
              groupCreated={this.state.success}
              checkedData={this.checkedData}
              callbackChecked={this.state.checked}
              communitySelect={this.communitySelect}
              excelData={this.excelData}
              selectedDataManual={this.state.dataShowManual}
              selectedDataExcel={this.state.dataShowExcel}
              excelFileName={this.state.excelFileName}
              selectedContactData={this.state.selectedContact}
              onGoBack={this.onGoBack}
              excelContactList={excelContactList}
              isCallbackUploadExcel={isCallbackUploadExcel}
              isCallbackValidExcel={isCallbackValidExcel}
              memberUid={this.props.memberUid}
              isInvalidAccess={this.state.isInvalidAccess}
            />
          )
        )}
      </campaignContext.Provider>
    );
  }
}



export default (props: any) => {
  const memberUid = useSelector((state: any) => state.cartreducer.business?.uid);
  // console.log(useSelector((state: any) => state.cartreducer))
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Campaign
      {...props}
      router={{ location, navigate, params }}
      navigate={navigate}
      memberUid={memberUid}
    />
  );
};
