import Badge from 'react-bootstrap/Badge';
import { MdTitle } from 'react-icons/md';
import {Tooltip, OverlayTrigger } from 'react-bootstrap';
import Node from '../node';

function AskFile(props: any) {
    return (
        <div>
            <Node {...props} label='Ask File' content={(
                <>
                    {props.data.storageVariable ? (

                        <Badge bg='secondary' style={{ fontSize: 6.5 }}>
                            <MdTitle />
                            {props.data.storageVariable.name}
                        </Badge>
                    ) : null}
                    <div>{props.data.text? (
            <div style={{ cursor: 'pointer' }}>
                {props.data.text.substring(0, 20)}
                {props.data.text.length > 20 ? '...' : null}
            </div>
        ) : null}</div>
                </>
            )} />
        </div>
    );
}

export default AskFile;