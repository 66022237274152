import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button } from 'reactstrap';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { Provider } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { QRCodeCanvas } from 'qrcode.react';
import { BsWhatsapp } from 'react-icons/bs';

import styles from './verifyBot.module.scss';
import * as botService from '../../services/bots';
import FlagInput from '../../../common/flagInput';
import store from '../../../redux/store/store';
import { IChannel } from '../../store/metaSlice';


interface VerifyBotSuccessProps {
    currentChannel: IChannel | null;
}

const VerifyBotSuccess: React.FC<VerifyBotSuccessProps> = ({ currentChannel }) => {
    if (!currentChannel) {
        return <Spinner />;
    }
    const link = `https://wa.me/${currentChannel.mobileNumber}`
    return (
        <div className={styles.verifySuccess}>
            <div className={styles.caption}>Scan QR to start testing</div>
            <QRCodeCanvas value={link} />
            <div className="dimmed mb-2">-- OR --</div>
            <a className={styles.testOnWhatsapp} href={link} target="_blank">
                <i><BsWhatsapp /></i>
                Test on Whatsapp Web
            </a>
        </div>
    );
}

interface VerifyBotProps {
    bot: any;
    flowUid: string;
    currentChannel: IChannel | null;
};

const VerifyBot: React.FC<VerifyBotProps> = ({ bot, flowUid, currentChannel }) => {
    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState<string>('91');

    const schema = yup.object().shape({
        mobileNumber: yup.string().required('Mobile number is required').max(13, 'Mobile number cannot exceed 13 characters').min(7, 'Mobile number must be at least 7 numbers'),
    });

    const verifyBotHandler = async (formData: { mobileNumber: string }) => {
        const mobileNumber = `${code}${formData.mobileNumber}`;
        setLoading(true);

        try {
            const response = await botService.verifyBot(bot.uid, flowUid, mobileNumber);
            setLoading(false);
            if (response === 'SUCCESS') {
                MySwal.fire({
                    title: <VerifyBotSuccess currentChannel={currentChannel} />,
                    showConfirmButton: false,
                    showCloseButton: true
                });
            } else {
                throw Error();
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Bot verification failed!',
                showCloseButton: true
            });
        }
    }

    return (
        <Provider store={store}>
            <div className={styles.verifyBot}>
                {loading ? <Spinner /> : (
                    <>
                        <div className={styles.header}>
                            <h1>Verify your  Bot Flow</h1>
                            <span className={styles.botName}>{bot.name}</span>
                        </div>
                        <Formik
                            validationSchema={schema}
                            onSubmit={verifyBotHandler}
                            initialValues={{ mobileNumber: '' }}
                        >
                            {({ handleSubmit,setFieldValue, handleChange, values, touched, errors, setValues }) => {
                                return (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group className='mb-3 mt-3'>
                                            <h5>Mobile Number</h5>
                                            <InputGroup>
                                                <InputGroup.Text style={{ width: 100, padding: '8.5px 12px', height: "40px" }}>
                                                    <FlagInput countryCode={`+${code}`} codeChange={setCode} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    name='mobileNumber'
                                                    value={values.mobileNumber}
                                                    isInvalid={(touched.mobileNumber && errors.mobileNumber) ? true : false}
                                                    // onChange={handleChange}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        let value = e.target.value.replace(/[^0-9]/g, "");
                                                        if (value.length <= 13) {
                                                          setFieldValue("mobileNumber", value);
                                                        }
                                                      }}
                                                    isValid={touched.mobileNumber && !errors.mobileNumber}
                                                />
                                            </InputGroup>
                                            {(touched.mobileNumber && errors.mobileNumber) ? (
                                                <div className={styles.errorMessage}>
                                                    {errors.mobileNumber}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                        <div className={styles.action}>
                                            <Button color='primary' type='submit'>Verify</Button>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </>
                )}
            </div>
        </Provider>
    );
}

export default VerifyBot;