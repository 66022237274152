import React, { useState } from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from "reactstrap";
export interface IAppProps {
  scriptData?: any;
}

function WidgetScript(props: IAppProps) {
  const { backgroundColors, buttonColor, businessName, tagline, trigerMsg, radioBtn,
    welcomeTxt,
    files,
    mobileNumber
  } = props.scriptData;
  console.log('DATA',props);
  const phoneNumber = '9787111644';
  let whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(businessName)}&type=phone_number&app_absent=0`;
  // Reset the "Copied" state after a certain time (e.g., 3 seconds)
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    const text=   `
    <script>
            var url = 'https://sgp1.digitaloceanspaces.com/vamotest/website/vamotest.sgp1.cdn.digitaloceanspaces.com_yoyo_obb_wawidget.js';
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = url;
            var options = {
              "enabled": true,
              "chatButtonSetting": {
                "backgroundColor": "${buttonColor}",
                "ctaText": "Chat with us",
                "borderRadius": "25",
                "marginLeft": "0",
                "marginRight": "20",
                "marginBottom": "20",
                "ctaIconWATI": false,
                "position": "${radioBtn}" // Updated alignment property
              },
              "brandSetting": {
                "brandName": "${businessName}",
                "brandSubTitle": "${tagline}",
                "brandImg": "${files}",
                "welcomeText": "${welcomeTxt}",
                "messageText": "${trigerMsg}",
                "backgroundColor": "${backgroundColors}",
                "ctaText": "Chat with us",
                "borderRadius": "25",
                "autoShow": false,
                "phoneNumber": "${mobileNumber}"
              }
            };
            s.onload = function() {
              CreateWhatsappChatWidget(options);
            };
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          </script>
    `
    navigator.clipboard.writeText(text);
    setIsCopied(true);
            console.log(text)
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <div className="container">
      <div className='Copy'><Button onClick={handleCopy}>{isCopied == true ?'Copied':'Copy'}</Button></div>
      <div className="sidebarScript" style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
     {`
          <script>
            var url = 'https://sgp1.digitaloceanspaces.com/vamotest/website/vamotest.sgp1.cdn.digitaloceanspaces.com_yoyo_obb_wawidget.js';
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = url;
            var options = {
              "enabled": true,
              "chatButtonSetting": {
                "backgroundColor": "${buttonColor}",
                "ctaText": "Chat with us",
                "borderRadius": "25",
                "marginLeft": "0",
                "marginRight": "20",
                "marginBottom": "20",
                "ctaIconWATI": false,
                "position": "${radioBtn}" // Updated alignment property
              },
              "brandSetting": {
                "brandName": "${businessName}",
                "brandSubTitle": "${tagline}",
                "brandImg": "${files}",
                "welcomeText": "${welcomeTxt}",
                "messageText": "${trigerMsg}",
                "backgroundColor": "${backgroundColors}",
                "ctaText": "Chat with us",
                "borderRadius": "25",
                "autoShow": false,
                "phoneNumber": "916381383678"
              }
            };
            s.onload = function() {
              CreateWhatsappChatWidget(options);
            };
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          </script>
        `}
      </div>
    </div>
  );
}

export default WidgetScript;
