import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { Button, Spinner } from 'reactstrap'
import { Alert } from 'react-bootstrap'

import styles from './SelectHoliday.module.scss'
import * as appointmentService from '../../services/appointmentService'
import { FormData } from './MemberCalenderForm'
import Avatar from 'react-avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { SOMETHING_WENT_WRONG } from '../../constants/errorMessage'

type Props = {
  onCancel: () => void
}

function SelectHoliday({
  onCancel
}: Props) {
  const formik = useFormikContext<FormData>()
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.id
  )
  const holidayQuery = useQuery({
    queryKey: ['holiady-list', 'all'],
    queryFn: () => appointmentService.getAllHolidayList(businessId),
  })

  if (holidayQuery.isLoading) {
    return <Spinner />
  } else if (holidayQuery.isError) {
    return (
      <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
    )
  }

  const selectHolidayHandler = (id: number) => {
    if (formik.values.holidayList === id) {
      formik.setFieldValue('holidayList', 0)
    } else {
      formik.setFieldValue('holidayList', id)
      onCancel()
    }
  }

  const holidays = [...holidayQuery.data.filter(h => h.id !== formik.values.holidayList)]
  const selectedHoliday = holidayQuery.data.find(h => h.id === formik.values.holidayList)
  if (selectedHoliday) holidays.unshift(selectedHoliday)

  return (
    <div>
      {holidays.map(holiday => {
        const isSelected = formik.values.holidayList === holiday.id
        const cssClasses = [styles.holidayItem]
        if (isSelected) {
          cssClasses.push(styles.selected)
        }
        return (
          <div
            key={holiday.id}
            className={cssClasses.join(' ')}
            onClick={() => selectHolidayHandler(holiday.id)}
          >
            <div className={styles.holidayItem_content}>
              <span className={styles.holidayItem__name}>{holiday.name}</span>
              <span className={styles.holidayItem__creator}>
                Created by
                {holiday.creator === 'BIZMAGNET' ? (
                  <>
                    <img src={`${process.env.PUBLIC_URL}/m_logo.png`} />
                    <span className={styles.holidayItemCreator___name}>
                      Biz Magnets
                    </span>
                  </>
                ) : (
                  <>
                    <Avatar
                      name={holiday.creator}
                      round
                      size='20'
                      textSizeRatio={2.5}
                      className='mx-1'
                    />
                    <span className={styles.holidayItemCreator___name}>
                      {holiday.creator}
                    </span>
                  </>
                )}
              </span>
            </div>
            <div className={styles.holidayItem_context}>
              {isSelected ? (
                <span className={styles.holidayItemContext__selectedLabel}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className='mr-1'
                  />
                  Selected
                </span>
              ) : null}
            </div>
          </div>
        )
      })}
      <Button
        onClick={onCancel}
        className='cancelButton'>
        Back
      </Button>
    </div>
  )
}

export default SelectHoliday