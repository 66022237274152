import * as  React from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { FormGroup, Button } from "reactstrap";
import { Form as FormBootstrap } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./widgetStyle.scss";
import WhatsAppChatButton from "./WhatsAppChatButton";

export interface WhatsAppMarketClasses<T> {
  component: T;
}

export interface WhatsAppMarketProps {
  classes?: WhatsAppMarketClasses<string>;
  className?: string;
}

export function WhatsAppMarket(props: WhatsAppMarketProps) {
  return (
    <div className="row">
      <div className="col-6">
        <FormGroup className="centered-form-temp">
          <h1 className="mb-3 businessName ">Bizmagnet</h1>
          <h6>Create your WhatsApp Widget</h6>
          <h2 className="widgetTitle">
            Acquire Leads with Live
            <br />
            &nbsp; &nbsp; &nbsp; WhatsApp Chat Button
          </h2>
          <br />
          <h3 className="subContent">
            <FontAwesomeIcon icon={faWhatsapp} />
            Allow Website Visitors to Reach Out Instantly
          </h3>
          <h3 className="subContent">
            <FontAwesomeIcon icon={faInstagram} />
            Easily Identify Your Potential Customers
          </h3>
          <h3 className="subContent">
            <FontAwesomeIcon icon={faTwitter} />
            Capture More Data & Make Better Decisions
          </h3>
          <Button className="widgetBtn">Generate Now</Button>
        </FormGroup>
      </div>
      <div className="col-6 promoteImg">
        <img
          id="target"
          src={require(`./../../assets/img/promote.jpg`)}
          alt="oldimage"
          height={450}
          width={580}
        />
      </div>
      <div className="widgetTtl">Generate WhatsApp Live Chat Widget</div>
      <WhatsAppChatButton typeofWidget="WhatsAppMarket" />
    </div>
  );
}

export default WhatsAppMarket;
