import React from 'react';
import { Button } from 'reactstrap';
interface Log {
    id: number;
    message: string;
    timestamp: Date;
}

const ActivityLogs: React.FC = () => {
    const logs: Log[] = [
        { id: 1, message: 'Log 1', timestamp: new Date() },
        { id: 2, message: 'Log 2', timestamp: new Date() },
        { id: 3, message: 'Log 3', timestamp: new Date() },
    ];

    return (
        <div>
            <h1>Activity Logs Page</h1>
            <ul>
                {logs.map((log) => (
                    <li key={log.id}>
                        {log.message} - {log.timestamp.toISOString()}
                    </li>
                ))}
            </ul>
            <Button className='sendButton'>No more to load</Button>
            
        </div>
    );
};

export default ActivityLogs;
