import axios from '../../utils/axios';
import { toastAlert, toast } from '../../common/alert';


export const getTag = (data: any, component: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`tag/getAll`, data).then(res => {
            resolve(res);
        }).catch((err: any) => {
            if (component !== 'conversationFilter')
                toast('error', err.response);
        })
    });
};

export const createTag = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`tag/createTag`, data).then(res => {
            resolve(res);
            toastAlert('top-end', `Tag created successfully`, 'success');
        }).catch((err: any) => {
            toast('error', "Tag name already exists.");
        })
    });
};

export const deleteTag = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete(`tag/deleteTag`, { data }).then(res => {
            resolve(res);
            toastAlert('top-end', `Tag deleted successfully`, 'success');
        }).catch(err => {
            toast('error', err.message);
        })
    });
}