import React, { useEffect, useState, } from 'react';
import { BrowserRouter as Router, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { auth } from "./Firebase/firebase";
import { useUserLoginContext } from './contexts/userLoginContext';
import { handleSignInWithEmailLink } from './services/auth/authenticationService';
import axios from "./utils/axios";
import { toast } from './common/alert';
import { resetState } from './redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';
import { clearWindowCache } from './common/commonfns';
import moment from 'moment';
import { socket } from './common/socketconfig';

const AuthenticatedRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const { justLoggedIn, setJustLoggedIn } = useUserLoginContext();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const routeSocket = socket;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const mode = queryParams.get('mode');
        const oobCode = queryParams.get('oobCode');
        const zohodata = queryParams.get('zohodata');

        if (zohodata) {
            dispatch({ type: 'ZOHO_DATA', payload: JSON.parse(zohodata) });
        }

        if (mode === 'email') {
            navigate(`${process.env.PUBLIC_URL}/login`);
        }
        else if (mode === 'resetPassword') {
            const dataToSend = { oobCode: oobCode };
            navigate(`${process.env.PUBLIC_URL}/resetPassword`, { state: { data: dataToSend } });
        }
        else if (mode === 'verifyEmail') {
            handleSignInWithEmailLink();
            navigate(`${process.env.PUBLIC_URL}/login`);
        }
        else {
            const unsubscribe = auth.onAuthStateChanged((user: any) => {
                const isLoggedIn = !!user;
                const apiToken = localStorage.getItem('apiToken');
                const tokenData = apiToken ? JSON.parse(apiToken) : null;
                if (apiToken) {
                    if (tokenData.expiry >= new Date().getTime()) {
                        setJustLoggedIn(true);
                        axios.get('profile', {
                            headers: {
                                uid: user?.uid
                            }
                        }).then(res => {
                            setJustLoggedIn(true);
                            const givenDate = moment(res.data?.business?.plan?.expireAt);
                            const today = moment();
                            if (givenDate.isBefore(today, 'day')) {
                                //dispatch(resetState());
                                localStorage.clear();
                                clearWindowCache();
                                auth.signOut();
                                if (routeSocket)
                                    routeSocket.disconnect();
                            }
                            if (zohodata)
                                navigate(`${process.env.PUBLIC_URL}/channels`);
                            else {
                                if (res.data.business?.freeTrial) {
                                    navigate(`${process.env.PUBLIC_URL}/welcome/`);
                                }
                                else {
                                    navigate(`${process.env.PUBLIC_URL}/dashboard`);
                                }
                            }
                        })
                            .catch(err => {
                                toast('error', err.response.data.message);
                            });
                    }
                    else {
                        setJustLoggedIn(false);
                        // if (!zohodata)
                        //     dispatch(resetState());
                        localStorage.clear();
                        clearWindowCache();
                        auth.signOut();
                        if (routeSocket)
                            routeSocket.disconnect();
                        navigate(`${process.env.PUBLIC_URL}/login`);
                    }
                }
                else {
                    setJustLoggedIn(false);
                    // if (!zohodata)
                    //     dispatch(resetState());
                    localStorage.clear();
                    clearWindowCache();
                    auth.signOut();
                    if (routeSocket)
                        routeSocket.disconnect();
                    navigate(`${process.env.PUBLIC_URL}/login`);
                }
            });
            return () => unsubscribe();
        }
    }, []);

    return (
        <></>
    );
};


export default AuthenticatedRoute;
