import { ReactNode, useState } from 'react';
import { Card, Form, FormGroup, Offcanvas } from 'react-bootstrap';
import { BsCalendarDate, BsFileEarmarkText } from 'react-icons/bs';
import { MdLocationOn, MdOutlineMailOutline, MdOutlineNumbers } from 'react-icons/md';
import { FaLink } from 'react-icons/fa';

import styles from './askMore.module.css';
import { IEditorProps } from '../types';
import EditorCaption from '../editorCaption';
import AskNumberEditor from './askNumber';
import AskEmailEditor from './askEmail';
import AskDateEditor from './askDate';
import AskUrlEditor from './askUrl';
import AskFileEditor from './askFile';
import AskLocationEditor from './askLocation';
import AskCtaUrlEditor from './askCtaUrl';
import { askMoreIcon } from '../../../../icons';


interface HeaderTypeProps {
    items: {
        icon: ReactNode;
        title: string;
        id: string;
    }[];
    active: string;
    setActive: (value: string) => void;
    varient?: 'primary' | 'secondary';
}

const HeaderType: React.FC<HeaderTypeProps> = props => {
    const classList = [styles.grid];
    if (props.varient === 'secondary') {
        classList.push(styles.secondary);
    }


    return (
        <div className={classList.join(' ')}>
            {props.items.map(item => {
                const classList = [styles.gridItem];
                if (props.active === item.id) {
                    classList.push(styles.active);
                }
                return (
                    <div
                        key={item.id}
                        className={classList.join(' ')}
                        onClick={() => props.setActive(item.id)}
                    >
                        <Card className={styles.iconCard}>
                            <div className={styles.icon}>
                                {item.icon}
                            </div>
                        </Card>
                        <div className={styles.title}>
                            {item.title}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

const AskMoreMenu: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [active, setActive] = useState('ask_number');

    return (
        <>
            <EditorCaption
                onHide={props.onClose}
                caption='Ask More'
                icon={<img style={{ width: 14 }} alt='' src={askMoreIcon} />}
            />
            <Offcanvas.Body className="mt-2" style={{ marginBottom: 0, minHeight: "unset" }}>
                <FormGroup className='mb-3'>
                    <Form.Label>Header Type <span className='required'></span></Form.Label>
                    <HeaderType
                        active={active}
                        setActive={setActive}
                        items={[
                            { id: 'ask_number', title: 'Number', icon: <MdOutlineNumbers /> },
                            { id: 'ask_email', title: 'Mail', icon: <MdOutlineMailOutline /> },
                            { id: 'ask_date', title: 'Date', icon: <BsCalendarDate /> },
                            { id: 'ask_url', title: 'Link', icon: <FaLink /> },
                            { id: 'ask_file', title: 'File', icon: <BsFileEarmarkText /> },     
                        ]}
                    />
                </FormGroup>
            </Offcanvas.Body>
            {active === 'ask_number' && <AskNumberEditor {...props} />}
            {active === 'ask_email' && <AskEmailEditor {...props} />}
            {active === 'ask_date' && <AskDateEditor {...props} />}
            {active === 'ask_url' && <AskUrlEditor {...props} />}
            {active === 'ask_file' && <AskFileEditor {...props} />}
        </>
    );
}

export default AskMoreMenu;