import React, { createContext, useContext, useEffect, useState } from "react";
import { FaWpforms } from "react-icons/fa";
import { MdPermMedia } from "react-icons/md";
import { BsMenuButtonWideFill } from "react-icons/bs";
import { BiSolidEditLocation } from "react-icons/bi";

export interface DataContextProps {
  data: Record<string, any> | any;
  setData: React.Dispatch<React.SetStateAction<Record<string, any> | any>>;
  clearData: () => void;
  setComponentName: React.Dispatch<React.SetStateAction<string | undefined>>;
  componentName: string | undefined;
  selectedTemplateTab?: string;
  setSelectedTemplateTab?: React.Dispatch<React.SetStateAction<string>>;
  selectedTemplate?: any;
  setSelectedTemplate?: React.Dispatch<
    React.SetStateAction<Record<string, any> | any>
  >;
  tempVariables?: any;
  setTempVariables?: React.Dispatch<
    React.SetStateAction<Record<string, any> | any>
  >;
  templateModalIcons?: any;
  setTemplateModalIcons?: React.Dispatch<
    React.SetStateAction<Record<string, any> | any>
  >;
  wbModal?: boolean;
  setWbModal?: React.Dispatch<React.SetStateAction<boolean>>;
  inputOptions?: any;
  setInputOptions?: React.Dispatch<
    React.SetStateAction<Record<string, any> | any>
  >;
  templateProcessed?: boolean;
}

export const DataContext = createContext<DataContextProps | undefined>(
  undefined
);

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) throw new Error("useData must be used within a DataProvider");
  return context;
};

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<Record<string, any> | any>({});
  const [componentName, setComponentName] = useState<string | undefined>(
    undefined
  );
  const [wbModal, setWbModal] = useState(false);

  const [selectedTemplateTab, setSelectedTemplateTab] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [tempVariables, setTempVariables] = useState<any>({
    recipientName: "",
    recipientPhone: "",
    advanceOption: {},
    variables: [],
    mediaVariables: [],
    buttonVariables: [],
    locationVariables: [],
  });
  const [templateProcessed, setTemplateProcessed] = useState<boolean>(false); // Track if template is processed
  const [inputOptions, setInputOptions] = useState<any>([]);
  const [templateModalIcons, setTemplateModalIcons] = useState<any>([
    {
      id: "Set-Variables",
      icon: <FaWpforms size={25} />,
      tooltip: "Set-Variables",
      disable: false,
    },
    {
      id: "Set-Media",
      icon: <MdPermMedia size={25} />,
      tooltip: "Set-Media",
      disable: false,
    },
    {
      id: "Set-Buttons",
      icon: <BsMenuButtonWideFill size={25} />,
      tooltip: "Set-Buttons",
      disable: false,
    },
    {
      id: "Set-Location",
      icon: <BiSolidEditLocation size={25} />,
      tooltip: "Set-Location",
      disable: false,
    },
  ]);

  const checkTemplateFormDisable = (data: any) => {
    const selectedTemplateComponent = data?.components;
    const headerContent = selectedTemplateComponent.filter(
      (item: any) => item.type === "HEADER"
    );
    const isBodyColumns = data?.bodyColumns?.length > 0 || false;

    let firstEnabledTabId: string | null = null; // To track the first tab with `disable: false`

    // Update templateModalIcons and track the first enabled tab
    const updatedIcons = templateModalIcons.map((icon: any) => {
      let disable = true;

      // Conditions for enabling/disabling tabs
      if (
        (headerContent.some(
          (item: any) =>
            item.format === "IMAGE" ||
            item.format === "VIDEO" ||
            item.format === "DOCUMENT"
        ) &&
          icon.id === "Set-Media") ||
        (headerContent.some((item: any) => item.format === "LOCATION") &&
          icon.id === "Set-Location") ||
        (isBodyColumns && icon.id === "Set-Variables")
      ) {
        disable = false;
      }

      // Track the first tab with `disable: false`
      if (!disable && firstEnabledTabId === null) {
        firstEnabledTabId = icon.id; // Set the first enabled tab
      }

      return {
        ...icon,
        disable,
      };
    });

    setTemplateModalIcons(updatedIcons);

    // Set selectedTemplateTab
    if (firstEnabledTabId) {
      setSelectedTemplateTab(firstEnabledTabId); // Set the first enabled tab
    } else {
      setSelectedTemplateTab(""); // Set to an empty string if all tabs are disabled
    }
  };

  const processTemplate = (template: any) => {
    let variables: string[] = [];
    let mediaUrls: string[] = [];
    let updatedTemplate = { ...template };

    template.components.forEach((component: any) => {
      if (component.type === "BODY" && component.text) {
        // Extract variables from text like {{1}}, {{2}}, etc.
        const regex = /{{(\d+)}}/g;
        let match;
        while ((match = regex.exec(component.text)) !== null) {
          const variableNumber = match[1]; // The number inside {{}} (e.g., 1, 2, 3, etc.)
          if (!variables.includes(variableNumber)) {
            variables.push(variableNumber); // Add to variables array
          }
        }
      }

      if (
        component.type === "HEADER" &&
        component.format === "IMAGE" &&
        component.example?.header_handle
      ) {
        mediaUrls = component.example.header_handle;
      }
    });

    // Update tempVariables state
    setTempVariables((prev: any) => ({
      ...prev,
      variables: variables, // Only update the variables array
      mediaVariables: mediaUrls, // Update mediaVariables with the extracted image URL
    }));

    // Update selectedTemplate with the updated text (with variables)
    // updatedTemplate = updateSelectedTemplateWithVariables(updatedTemplate);
    // setSelectedTemplate(updatedTemplate);
  };

  const updateSelectedTemplateWithVariables = (updatedTemplate: any) => {
    const updatedComponents = updatedTemplate.components.map(
      (component: any) => {
        if (component.type === "BODY" && component.text) {
          let updatedText = component.text;
          // Replace placeholders like {{1}}, {{2}} with actual values from tempVariables
          tempVariables.variables.forEach((variable: string) => {
            const regex = new RegExp(`{{${variable}}}`, "g");
            updatedText = updatedText.replace(
              regex,
              tempVariables[variable]
            );
          });

          // Update the component text with the new values
          return { ...component, text: updatedText };
        }

        if (
          component.type === "HEADER" &&
          component.format === "IMAGE" &&
          component.example?.header_handle
        ) {
          const updatedHeader = component.example.header_handle.map(
            (url: string) => {
              return url; // Optionally update media URL if necessary
            }
          );
          return { ...component, example: { header_handle: updatedHeader } };
        }

        return component;
      }
    );

    return { ...updatedTemplate, components: updatedComponents };
  };

  // Updates selectedTemplate whenever tempVariables change
  // useEffect(() => {
  //   if (tempVariables && tempVariables.variables.length) {
  //     // Update selectedTemplate based on the tempVariables
  //     const updatedTemplate = { ...selectedTemplate };

  //     updatedTemplate.components = updatedTemplate.components.map(
  //       (component: any) => {
  //         if (component.type === "BODY" && component.text) {
  //           let updatedText = component.text;
  //           tempVariables.variables.forEach((variable: string) => {
  //             const regex = new RegExp(`{{${variable}}}`, "g");
  //             updatedText = updatedText.replace(
  //               regex,
  //               tempVariables[variable] || "" // Replace variable with its value (or empty if not set)
  //             );
  //           });
  //           return { ...component, text: updatedText };
  //         }

  //         return component;
  //       }
  //     );

  //     setSelectedTemplate(updatedTemplate); // Update the selected template state
  //   }
  // }, [tempVariables]); // Runs whenever tempVariables changes

  useEffect(() => {
    if (wbModal && selectedTemplate && !templateProcessed) {
      processTemplate(selectedTemplate);
      checkTemplateFormDisable(selectedTemplate);
      setTemplateProcessed(true);
    }
  }, [wbModal, selectedTemplate, templateProcessed]);

  const hasRecipientName = tempVariables.recipientName.trim() !== "";
  const hasRecipientPhone = tempVariables.recipientPhone.trim() !== "";
  const hasAdvanceOption = Object.keys(tempVariables.advanceOption).length > 0;
  const hasVariables = tempVariables.variables.length > 0;
  const hasMediaVariables = tempVariables.mediaVariables.length > 0;
  const hasButtonVariables = tempVariables.buttonVariables.length > 0;
  const hasLocationVariables = tempVariables.locationVariables.length > 0;
  
  const hasContent = hasRecipientName || hasRecipientPhone || hasAdvanceOption || hasVariables || hasMediaVariables || hasButtonVariables || hasLocationVariables;
  

  const clearData = () => {
    setData({});
    setComponentName(undefined);
    setSelectedTemplate([]);
    setTempVariables({
      recipientName: "",
      recipientPhone: "",
      advanceOption: {},
      variables: [],
      mediaVariables: [],
      buttonVariables: [],
      locationVariables: [],
    });
    setSelectedTemplateTab("");
    setTemplateProcessed(false);
    setWbModal(false);
  };

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        clearData,
        setComponentName,
        componentName,
        selectedTemplate,
        setSelectedTemplate,
        selectedTemplateTab,
        setSelectedTemplateTab,
        tempVariables,
        setTempVariables,
        templateModalIcons,
        wbModal,
        setWbModal,
        inputOptions,
        setInputOptions,
        templateProcessed,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
