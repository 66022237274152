import { ListGroup, Spinner } from 'react-bootstrap'
import Node from '../node'
import React from 'react'
import { Handle, Position } from 'reactflow'
import classes from '../delay.module.css';


function  CreateTicketNode(props: any) {

  
  let content = <Spinner />

    content = (
      <>
      <div>
       {props?.data?.ticketFlow?.data?.subject}
  </div>
      </>
    )

  return (
    <Node {...props} label='Create Ticket' content={content} />
  )
}


export default CreateTicketNode;