import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { jumpToIcon } from '../../../../icons';
import EditorCaption from '../editorCaption';
import { useElmentEditor } from '../hooks';
import { IEditorProps } from '../types';
import { SelectVariable } from '../setVariable';
import { IStorageVariable } from '../../../../entity/Variable';
import { BsInfoCircleFill } from 'react-icons/bs';
import infoCss from '../catalogue/sendOrderDetails.module.scss';



interface FormData {
    storageVariable: IStorageVariable<'OBJECT'>,
};

function AppointmentConfirmationEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        storageVariable: {
            name: '',
            type: 'OBJECT'
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'appointment_confirmation',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        storageVariable: yup.object().shape({
            name: yup.string().required("Variable is required"),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Appointment Confirmation' icon={<img style={{ width: 16 }} alt='' src={jumpToIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    Variable <span className='required'></span>
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={<Tooltip> Please select the varaiable after creating appointment flow </Tooltip>}
                                    >
                                        <Button size='sm' id={infoCss.round} variant='default'>
                                            <BsInfoCircleFill />
                                        </Button>
                                    </OverlayTrigger>
                                </Form.Label>
                                <SelectVariable
                                    placeholder='Select variable'
                                    name='storageVariable'
                                    value={String(values?.storageVariable?.name)}
                                    onChange={handleChange}
                                    type='OBJECT'
                                    varType='appointment_confirmation'
                                />
                                <ErrorMessage
                                    name="storageVariable.name"
                                    component="div"
                                    className="invalid-feedback d-block"
                                />
                            </Form.Group>

                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AppointmentConfirmationEditor;