import React, { useEffect, useMemo, useState } from 'react'
import { Form, Offcanvas, Button, Spinner, Alert, Accordion, OverlayTrigger, Tooltip, FormControl, FormCheck } from 'react-bootstrap'
import { Formik, useFormikContext } from 'formik'
import * as yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import ReactSelect from 'react-select'
import { Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { PiMinusCircleFill } from 'react-icons/pi'

import styles from './zohoCRM.module.scss'
import { useElmentEditor } from '../hooks'
import EditorCaption from '../editorCaption'
import { zohoBiginIcon } from '../../../../icons'
import { IEditorProps } from '../types'
import { IIntegrationApp } from '../../../../entity/integration/integrationApp'
import SelectIntegrationAction from './selectIntegrationAction'
import * as designFlowService from '../../../../services/designFlow'
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList'
import ActionsField, { IActionsField } from '../../../../entity/integration/actionsField'
import IntegrationConnection from './integrationConnection'
import { SOMETHING_WENT_WRONG } from '../../../../../constants/errorMessage'
import VariableInputLabel from '../variableInputLabel'
import { SelectVariable } from '../setVariable'
import AddVariable from '../addVariable';
import { uuidv4 } from '../../../../utils/uuid'
import bg from '../catalogue/sendProducts.module.scss';

type Payload = Record<string, string> | null
type QueryParam = {
  uid: string,
  field: string,
  condition: 'equals' | 'starts_with',
  value: string,
}
type QueryParamKey = 'field' | 'condition' | 'value';

interface FormData {
  integrationData: {
    app: IIntegrationApp,
    scope: 'COM' | 'IN'
    connectionId: number | null
    action: IIntegrationActionListId | null
    payload: Payload,
    query: QueryParam[],
    recordId: string
  },
  storageVariable: { name: string, type: 'OBJECT' } | null,
  isSameFlow: boolean
}

type SelectModuleInputProps = {
  connectionId: number,
  fetchFields: () => void
}

function SelectModuleInput({ connectionId, fetchFields }: SelectModuleInputProps) {
  const formik = useFormikContext<FormData>()
  const zohoModulesQuery = useQuery({
    queryKey: ['zoho-crm-modules'],
    queryFn: () => designFlowService.getZohoModules(connectionId)
  })

  if (zohoModulesQuery.isLoading) {
    return <Spinner />
  } else if (zohoModulesQuery.isError) {
    return (
      <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
    )
  }

  const isError = Boolean(formik.errors.integrationData?.payload
    && typeof formik.errors.integrationData?.payload === 'object'
    && 'module' in (formik.errors.integrationData as any).payload
    && Boolean((formik.errors.integrationData as any).payload['module']))
  const isTouched = Boolean(formik.touched.integrationData?.payload
    && typeof formik.touched.integrationData?.payload === 'object'
    && 'module' in (formik.touched.integrationData as any).payload
    && Boolean((formik.touched.integrationData as any).payload['module']))

  return (
    <Form.Group className='my-2'>
      <Form.Label>Module <span className='required'></span></Form.Label>
      <ReactSelect
        value={(() => {
          if (formik.values.integrationData.payload && 'module' in formik.values.integrationData.payload) {
            const module = (zohoModulesQuery.data as any).find((m: any) => m.label === formik.values.integrationData.payload?.module)
            if (module) {
              (window as any).selectedModuleId = module.id
              fetchFields()
              return {
                label: module.label,
                value: module.label,
                id: module.id
              }
            }
          }
          return ''
        })() as any}
        name='integrationData.payload.module'
        onChange={(selected) => {
          if (selected) {
            (window as any).fieldsInit = false;
            (window as any).selectedModule = String(selected.value);
            (window as any).selectedModuleId = selected.id
            formik.setFieldValue('integrationData.payload.module', selected.value)
            fetchFields();
          }
        }}
        options={zohoModulesQuery.data.map(module => ({
          label: module.label,
          value: module.label,
          id: module.id
        }))}
      />
      {isTouched && isError ? (
        <div className='invalid-feedback d-block'>
          {String((formik.errors.integrationData as any).payload['module'])}
        </div>
      ) : null}
    </Form.Group>
  )
}


type QueryInputProps = {
  fields: ActionsField[]
}

function QueryInput({ fields }: QueryInputProps) {
  const formik = useFormikContext<FormData>()

  const createQueryParameter = () => {
    if (Array.isArray(formik.values.integrationData.query)) {
      const updatedQuery = [...formik.values.integrationData.query]
      updatedQuery.push({
        uid: uuidv4(),
        field: '',
        condition: 'equals',
        value: '',
      })
      formik.setFieldValue('integrationData.query', updatedQuery)
    }
  }

  const deleteQueryParameter = (uid: string) => {
    if (Array.isArray(formik.values.integrationData.query)) {
      const updatedQuery = formik.values.integrationData.query.filter(query => query.uid !== uid)
      formik.setFieldValue('integrationData.query', updatedQuery)
    }
  }

  const getErrorForField = (field: QueryParamKey, index: number) => {
    if (
      formik.touched.integrationData?.query &&
      formik.errors.integrationData?.query &&
      Array.isArray(formik.errors.integrationData?.query) &&
      ((formik.errors.integrationData as any)?.query.length - 1) >= index &&
      (formik.errors.integrationData as any)?.query[index][field] &&
      typeof (formik.errors.integrationData as any)?.query[index][field] === 'string'
    ) {
      return (
        <div className='invalid-feedback d-block'>
          {(formik.errors.integrationData as any)?.query[index][field]}
        </div>
      )
    }
    return null
  };

  return (
    <div>
      {Array.isArray(formik.values.integrationData.query) ? (
        <div className={styles.queryParams}>
          {formik.values.integrationData.query.map((query, index) => {
            const fieldValue = (() => {
              if ((formik.values.integrationData.query as QueryParam[])[index].field) {
                console.log('heyy field id', (formik.values.integrationData.query as QueryParam[])[index].field);

                const myField = fields.find(field => field.id === (formik.values.integrationData.query as QueryParam[])[index].field)

                console.log('heyy myField', myField);


                if (myField) {
                  return {
                    label: myField.label,
                    value: myField.id
                  }
                }
              }
              return ''
            })();

            return (
              <div
                className={styles.queryParams__item}
                key={query.uid}
              >
                <Button variant='default' size='sm' className='minusRemoveBtn' onClick={() => deleteQueryParameter(query.uid)}>
                  <PiMinusCircleFill />
                </Button>
                <div className={styles.grid}>
                  <Form.Group className={styles.queryParamsItem__field}>
                    <Label>Field <span className='required'></span></Label>
                    <ReactSelect
                      value={fieldValue}
                      onChange={(selected: any) => {
                        if (selected) {
                          formik.setFieldValue(`integrationData.query[${index}].field`, selected.value)
                        }
                      }}
                      options={fields.map(field => ({
                        label: field.label,
                        value: field.id
                      })) as any}
                    />
                    {getErrorForField('field', index)}
                  </Form.Group>

                  <Form.Group className={styles.queryParamsItem__field}>
                    <Label>Condition <span className='required'></span></Label>
                    <ReactSelect
                      value={(formik.values.integrationData.query as QueryParam[])[index].condition === 'equals' ?
                        { label: 'Equal to', value: 'equals' }
                        : (formik.values.integrationData.query as QueryParam[])[index].condition === 'starts_with' ?
                          { label: 'Starts with', value: 'starts_with' }
                          : ''}
                      onChange={(selected: any) => {
                        if (selected) {
                          formik.setFieldValue(`integrationData.query[${index}].condition`, selected.value)
                        }
                      }}
                      options={[
                        { label: 'Equal to', value: 'equals' },
                        { label: 'Starts with', value: 'starts_with' }
                      ] as any}
                    />
                    {getErrorForField('condition', index)}
                  </Form.Group>

                  <Form.Group className={[styles.queryParamsItem__field, styles.valueField].join(' ')}>
                    <Label>Value <span className='required'></span></Label>
                    <FormControl
                      name={`integrationData.query[${index}].value`}
                      as='input'
                      value={(formik.values.integrationData.query as QueryParam[])[index].value || ''}
                      isInvalid={false}
                      isValid={false}
                      onChange={formik.handleChange}
                    />
                    {getErrorForField('value', index)}
                  </Form.Group>
                </div>
              </div>
            )
          })}
        </div>
      ) : null}

      <OverlayTrigger
        overlay={<Tooltip>Add Query Parameter</Tooltip>}
        placement='bottom'
      >
        <Button
          onClick={createQueryParameter}
          size='sm'
          className='addButton ml-2'
        >
          <FontAwesomeIcon icon={faAdd} />
        </Button>
      </OverlayTrigger>
    </div>
  )
}

function ZohoBigin(props: IEditorProps) {
  const [isQueryMode, setIsQueryMode] = useState<boolean | null>(null)
  const [selectedAction, setSelectedAction] = useState<IIntegrationActionListId | null>(null)
  const [action, setAction] = useState<IIntegrationActionListId | null>(null)
  const [isCreateScreen, setIsCreateScreen] = useState(false);
  const [isEditScreen, setEditScreen] = useState(true);
  const [formData, setFormData] = useState<FormData>({
    integrationData: {
      app: 'ZohoBigin',
      scope: 'IN',
      connectionId: null,
      action: null,
      payload: null,
      query: [],
      recordId: ''
    },
    storageVariable: null,
    isSameFlow: false
  })
  const { init, saveElementChanges } = useElmentEditor({
    type: 'integration/ZohoBigin',
    data: formData
  }, props)
  const zohoModuleFieldsQuery = useQuery({
    queryKey: ['zoho-crm-module-fields'],
    queryFn: () => {
      return new Promise<IActionsField[]>((resolve, reject) => {
        designFlowService.getZohoModules((window as any).appConnectionId, (window as any).selectedModuleId)
          .then((data: any) => {
            resolve(data as IActionsField[])
          })
          .catch((error: any) => {
            reject(error)
          })
      })
    },
    enabled: false
  })
  const actionClenup = () => {
    (window as any).actionFields = null;
    (window as any).fieldsInit = false;
    (window as any).selectedModule = null;
    (window as any).selectedModuleId = null;
    zohoModuleFieldsQuery.remove()
    setIsQueryMode(null)
  }
  useEffect(() => {
    init(setFormData)
    return actionClenup
  }, [])


  const getPayloadObjectShape = () => {
    const forEachFieldHandler = (field: ActionsField) => {
      resultShape[field.id] = (field.required ? yup.string().required(field.label + ' is required')
        : yup.string()) as yup.StringSchema<string>
    }
    const resultShape: Record<string, yup.StringSchema<string>> = {}
    if (selectedAction && (selectedAction === 'dynamicModule' || selectedAction === 'getARecord')) {
      resultShape['module'] = yup.string().required('Module is required') as yup.StringSchema<string>
      if (zohoModuleFieldsQuery.isFetched && !zohoModuleFieldsQuery.isError && selectedAction !== 'getARecord') {
        const myFields = zohoModuleFieldsQuery.data?.map((field: IActionsField) => new ActionsField(field))
        myFields?.forEach(forEachFieldHandler)
      }
    } else if ((window as any).actionFields) {
      (window as any).actionFields.forEach(forEachFieldHandler)
    }
    return resultShape
  }

  const storageVariableSchema = yup.object().shape({
    name: yup.string(),
  });
  const schema = yup.object().shape({
    integrationData: yup.object().shape({
      connectionId: yup.string().required('Connection is required'),
      action: yup.string().required('Action is required'),
      payload: yup.object().shape(getPayloadObjectShape()).nullable(),
      recordId: (isQueryMode !== null) ? isQueryMode ? yup.string().notRequired().nullable()
        : yup.string().required('Record ID required').nullable() : yup.string().notRequired().nullable(),
      query: (action && action === 'getARecord') ?
        isQueryMode !== null ? isQueryMode ? yup.array().of(yup.object().shape({
          field: yup.string().required('Field is required'),
          value: yup.string().required('Value is required'),
        })).min(1, 'Atleast 1 query is required')
          : yup.array().nullable()
          : yup.object().nullable()
        : yup.array().nullable()
    }),
    storageVariable: action === 'getARecord' ? storageVariableSchema
      : yup.object().nullable()
  })

  return (
    <Formik
      validationSchema={schema}
      onSubmit={saveElementChanges}
      initialValues={formData}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched, setValues, handleChange }) => {
        const onGoBackHandler = () => {
          if (values.integrationData.action) {
            setFieldValue('integrationData.action', null)
            setFieldValue('integrationData.payload', null)
            actionClenup()
          } else if (values.integrationData.connectionId) {
            setFieldValue('integrationData.connectionId', null)
          } else {
            props.onClose()
          }
        }
        useEffect(() => {
          setValues(formData)
          console.log('heyy setting form data', {
            formData,
            props
          });
        }, [formData, setValues])
        useEffect(() => {
          if (!props.create
            && isQueryMode === null
            && formData.integrationData.payload
            && formData.integrationData.action === 'getARecord') {
            setIsQueryMode(formData.integrationData.query.length > 0)
          }
        }, [formData])
        const myAction: ActionsListItem | null = useMemo(() => {
          if (values.integrationData.connectionId) {
            (window as any).appConnectionId = values.integrationData.connectionId
          }
          if ('action' in values.integrationData && values.integrationData.action) {
            if (
              (values.integrationData.action === 'dynamicModule' || values.integrationData.action === 'getARecord')
              && (zohoModuleFieldsQuery.isFetched && !zohoModuleFieldsQuery.isError)
              && values.integrationData.payload
              && 'module' in values.integrationData.payload
            ) {
              return {
                ...ACTION_LIST.ZohoBigin.find(a => a.id === values.integrationData.action),
                fields: zohoModuleFieldsQuery.data?.map((field: IActionsField) => new ActionsField(field))
              } as ActionsListItem
            }
            return ACTION_LIST['ZohoBigin'].find(action => action.id === values.integrationData.action) || null
          }
          return null
        }, [
          values,
          zohoModuleFieldsQuery.isFetched,
          zohoModuleFieldsQuery.isLoading,
          zohoModuleFieldsQuery.isError,
          zohoModuleFieldsQuery.data
        ])
        useEffect(() => {
          if (myAction) {
            const isModuleRequired = (values.integrationData.action === 'dynamicModule' || values.integrationData.action === 'getARecord')
            if (isModuleRequired && !values.integrationData.payload) {
              setFieldValue('integrationData.payload.module', '')
            }
            setSelectedAction(myAction.id)
            if (
              isModuleRequired
              && (zohoModuleFieldsQuery.isLoading || zohoModuleFieldsQuery.isError)
            ) return;
            setAction(myAction.id);
            (window as any).actionFields = myAction.fields;
            if (!(window as any).fieldsInit && props.create) {
              Object.keys(getPayloadObjectShape()).forEach((name: string) => {
                if (name !== 'module') {
                  const fieldName = `integrationData.payload.${name}`
                  setFieldValue(fieldName, '')
                }
              });
              if (myAction.id === 'getARecord' && (isQueryMode === null || isQueryMode === true)) {
                setIsQueryMode(true)
                setFieldValue('integrationData.query', [])
              } else if (isQueryMode == false) {
                setFieldValue('integrationData.query', '')
              }
              (window as any).fieldsInit = true
            }
          }
        }, [myAction])

        const mapFieldsHandler = (field: ActionsField) => {
          const isError = Boolean(errors.integrationData?.payload
            && field.id in (errors.integrationData as any).payload
            && Boolean((errors.integrationData as any).payload[field.id]))
          const isTouched = Boolean(touched.integrationData?.payload
            && field.id in (touched.integrationData as any).payload
            && Boolean((touched.integrationData as any).payload[field.id]))
          return (
            <Form.Group
              key={field.id}
              className='my-2'
            >
              <Label>{field.label}</Label>
                           <FormControl
                name={'integrationData.payload.' + field.id}
                required={field.required as boolean} // Cast field.required to boolean
                as='input'
                value={values.integrationData.payload ? values.integrationData.payload[field.id] : ''}
                isInvalid={isTouched && isError}
                isValid={isTouched && !isError}
                onChange={handleChange}
              />
              
              {isTouched && isError ? (
                <div className='invalid-feedback d-block'>
                  {String((errors.integrationData as any).payload[field.id])}
                </div>
              ) : null}
              
              <AddVariable 
                static 
                fieldName={'integrationData.payload.' + field.id} 
                fieldValue={values.integrationData.payload ? values.integrationData.payload[field.id] : ''} 
                limit={1024} 
              />
                                    
            </Form.Group>
          )
        }

        const isQueryTouched = Boolean(touched.integrationData?.recordId);
        const isQueryError = Boolean(errors.integrationData?.recordId);

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <EditorCaption
              onHide={onGoBackHandler}
              caption={
                <>Zoho Bigin
                  <span className='fw-normal ml-1'>
                    (Scope:
                    <select
                      className={styles.scopeSelect}
                      name='integrationData.scope'
                      value={values.integrationData.scope}
                      onChange={(event) => {
                        setFieldValue('integrationData.action', null)
                        setFieldValue('integrationData.payload', null)
                        setFieldValue('integrationData.connectionId', null)
                        actionClenup()
                        handleChange(event)
                      }}
                    >
                      <option value='COM'>COM</option>
                      <option value='IN'>IN</option>
                    </select>
                    )
                  </span>
                </>
              }
              icon={<img style={{ width: 40 }}
                alt=''
                src={zohoBiginIcon}
              />} />
            <Offcanvas.Body>
            {myAction ? <h5 className='mb-3'>{myAction.label}</h5> : null}
                            {values.integrationData.connectionId ? (
                                values.integrationData.action ? (
                                    <>
                                        {myAction ?
                                            <>
                                                {myAction.fields
                                                    .filter(field => field.required)
                                                    .map(mapFieldsHandler)}
                                                {myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 && (
                                                        <>
                                                            <FormCheck
                                                                name='isSameFlow'
                                                                type='switch'
                                                                id='fields-shopify-switch'
                                                                label="Optional Fields"
                                                                checked={values.isSameFlow}
                                                                onChange={event => {
                                                                    setFieldValue('isSameFlow', event.target.checked);
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {values?.isSameFlow && myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 ? (
                                                    <div className={bg.background}>
                                                        {myAction.fields
                                                            .filter(field => !field.required)
                                                            .map(mapFieldsHandler)}
                                                    </div>
                                                ) : null}
                                            </> : null}
                                    </>
                                ) : <SelectIntegrationAction app='ZohoBigin' />) : (
                                <IntegrationConnection app='ZohoBigin' scope={values?.integrationData?.scope} onScreenChange={(screen: any) => { setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit'); }} isInvalidAccess = {props?.isInvalidAccess} />
                            )}
            </Offcanvas.Body>

          { !isCreateScreen && isEditScreen && 
            <div className='editor-footer'>
              <Button variant='outline-dark' onClick={props.onClose}>
                Cancel
              </Button>
              <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                Save
              </Button>
            </div>
           }
          </Form>
        )
      }}
    </Formik >
  )
}

export default ZohoBigin