import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Conversation from './conversation'
import ConversationReport from './conversationReport';

function RootConversation() {
    return (
        <Routes>
            <Route path='/' element={<Conversation />} />
            {/* <Route path='/report' element={<ConversationReport />} /> */}
        </Routes>
    );
}

export default RootConversation