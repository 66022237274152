import React, { useEffect, useMemo, useState } from 'react';
import { IEditorProps } from '../types';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList';
import { IIntegrationApp } from '../../../../entity/integration/integrationApp';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { wooIcon } from '../../../../icons';
import { Button, Offcanvas, Form, FormControl, FormCheck } from 'react-bootstrap';
import IntegrationConnection from './integrationConnection';
import ActionsField from '../../../../entity/integration/actionsField';
import { FormGroup, Label } from 'reactstrap';
import SelectIntegrationAction from './selectIntegrationAction';
import bg from '../catalogue/sendProducts.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sheets from './googleSheets.module.scss';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import styles from './woocommerce.module.scss';
import AddVariable from '../addVariable';
import BodyInput from '../bodyInput';


interface KeyValue {
    key: string,
    value: string
}

interface FormData {
    integrationData: {
        app: IIntegrationApp,
        scope: 'COM' | 'IN'
        connectionId: number | null
        action: IIntegrationActionListId | null
        orderId: string,
        keyValue: KeyValue[],
        notes: string
    },
    isSameFlow: boolean
}



function WooCommerce(props: IEditorProps) {

    const [isCreateScreen, setIsCreateScreen] = useState(false);
    const [isEditScreen, setEditScreen] = useState(true);
    const [isOrderBased, setIsOrderBased] = useState(true);
    const [keyValue, setKeyValue] = useState([{ key: '', value: '' }]);
    const [formData, setFormData] = useState<FormData>({
        integrationData: {
            app: 'Woocommerce',
            scope: 'COM',
            connectionId: null,
            action: null,
            orderId: '',
            keyValue: [{
                key: '',
                value: ''
            }],
            notes: ''
        },
        isSameFlow: false
    })
    const actionClenup = () => {
        (window as any).actionFields = null;
        (window as any).fieldsInit = false;
        (window as any).selectedModule = null;
        (window as any).selectedModuleId = null;
        // zohoModuleFieldsQuery.remove()
    }
    useEffect(() => {
        init(setFormData)
        return actionClenup
    }, [])

    const { init, saveElementChanges } = useElmentEditor({
        type: 'integration/Woocommerce',
        data: formData
    }, props)

    const schema = yup.object().shape({
        integrationData: yup.object().shape({
            connectionId: yup.string().required('Connection is required'),
            action: yup.string().required('Action is required'),
            orderId: yup.string().required('Order ID is required'),
            keyValue: yup.array().when('action', {
                is: 'addMetaData',
                then: yup.array().of(
                    yup.object().shape({
                        key: yup.string()
                            .required('Key is required')
                            .min(1, 'Key cannot be empty'),
                        value: yup.string()
                            .required('Value is required')
                            .min(1, 'Value cannot be empty')
                    })
                ),
                otherwise: yup.array()
            }),
            notes: yup.string().when('action', {
                is: 'addNotes',
                then: yup.string().required('Notes is required'),
                otherwise: yup.string()
            }),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >

            {({ handleSubmit, setFieldValue, values, errors, touched, setValues, handleChange }) => {

                const [code, setCode] = useState<string>('91');
                const onGoBackHandler = () => {
                    if (values.integrationData.action) {
                        setFieldValue('integrationData', {
                            app: 'Woocommerce',
                            scope: 'COM',
                            connectionId: values?.integrationData?.connectionId,
                            action: null,
                            payload: null,
                            query: [],
                            recordId: '',
                            name: '',
                            phone: '',
                            financialStatus: '',
                            orderId: '',
                            tags: '',
                            keyValue: [{
                                key: '',
                                value: ''
                            }]
                        })
                        actionClenup()
                    } else if (values.integrationData.connectionId) {
                        setFieldValue('integrationData.connectionId', null)
                    } else {
                        props.onClose()
                    }
                }

                const myAction: ActionsListItem | null = useMemo(() => {
                    if (values.integrationData.connectionId) {
                        (window as any).appConnectionId = values.integrationData.connectionId
                    }
                    if ('action' in values.integrationData && values.integrationData.action) {
                        return ACTION_LIST['Woocommerce'].find(action => action.id === values.integrationData.action) || null
                    }
                    return null
                }, [values])

                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues])


                const handleAdd = () => {
                    const newKeyValue = [...(values.integrationData.keyValue || []), { key: '', value: '' }];
                    setFieldValue('integrationData.keyValue', newKeyValue);
                };

                const handleDelete = (index: number) => {
                    const newKeyValue = values.integrationData.keyValue.filter((_, i) => i !== index);
                    setFieldValue('integrationData.keyValue', newKeyValue.length > 0 ? newKeyValue : [{ key: '', value: '' }]);
                };

                const mapFieldsHandler = (field: ActionsField) => {

                    const isError = Boolean(errors.integrationData
                        && field.id in (errors.integrationData as any)
                        && Boolean((errors.integrationData as any)[field.id]))
                    const isTouched = Boolean(touched.integrationData
                        && field.id in (touched.integrationData as any)
                        && Boolean((touched.integrationData as any)[field.id]))

                    return (
                        <>
                            {field && (
                                <>
                                    <FormGroup className="mb-3">
                                        <Label>{field.label}<span className='required'></span></Label>
                                        <FormControl
                                            name={`integrationData.${field.id}`}
                                            as='input'
                                            value={(values.integrationData as any)[field.id] || ''}
                                            onChange={handleChange}
                                            placeholder={field.placeholder || ''}
                                            required={true}
                                        />
                                        <ErrorMessage name={`integrationData.${field.id}`} component="div" className='invalid-feedback d-block' />
                                        <AddVariable static fieldName={'integrationData.' + field.id} fieldValue={values.integrationData ? (values.integrationData as any)[field.id] : ''} limit={1024} />
                                    </FormGroup>

                                    {values?.integrationData?.action === "addMetaData" && (
                                        <FormGroup>
                                            <Label><b>Meta Data</b></Label>
                                            {values.integrationData?.keyValue?.map((_, index) => (
                                                <div key={index} className={bg.background}>
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        className={`${sheets.trashIcon} ${styles.deleteContainer}`}
                                                        onClick={() => handleDelete(index)}
                                                    />
                                                    <Label>Key <span className="required"></span></Label>
                                                    <FormControl
                                                        name={`integrationData.keyValue[${index}].key`}
                                                        type="text"
                                                        value={values?.integrationData?.keyValue[index]?.key}
                                                        onChange={handleChange}
                                                        placeholder="Key"
                                                    />
                                                    <ErrorMessage name={`integrationData.keyValue[${index}].key`} component="div" className='invalid-feedback d-block' />
                                                    <AddVariable static fieldName={'integrationData.keyValue[' + index + '].key'} fieldValue={values.integrationData.keyValue ? (values.integrationData.keyValue as any)[index]?.key : ''} limit={1024} />
                                                    <br></br>
                                                    <Label>Value <span className="required"></span></Label>
                                                    <FormControl
                                                        name={`integrationData.keyValue[${index}].value`}
                                                        type="text"
                                                        value={values?.integrationData?.keyValue[index]?.value}
                                                        onChange={handleChange}
                                                        placeholder="Value"
                                                    />
                                                    <ErrorMessage name={`integrationData.keyValue[${index}].value`} component="div" className='invalid-feedback d-block' />
                                                    <AddVariable static fieldName={'integrationData.keyValue[' + index + '].value'} fieldValue={values.integrationData.keyValue ? (values.integrationData.keyValue as any)[index]?.value : ''} limit={1024} />
                                                </div>
                                            ))}

                                            <Button color="primary" className='mt-3' onClick={handleAdd}>Add +</Button>
                                        </FormGroup>
                                    )}

                                    {values?.integrationData?.action === "addNotes" && (
                                        <>
                                            <BodyInput
                                                name='integrationData.notes'
                                                value={values?.integrationData?.notes}
                                                isInvalid={(touched?.integrationData?.notes && errors?.integrationData?.notes) ? true : false}
                                                onChange={handleChange}
                                                isValid={touched?.integrationData?.notes && !errors?.integrationData?.notes}
                                                error={errors?.integrationData?.notes}
                                                label={<>Notes<span className="required"></span></>}
                                                placeholder='  Type your notes here'
                                                limit={1024}
                                            />
                                            <ErrorMessage 
                                               name = "integrationData.notes"
                                               component='div'
                                               className='invalid-feedback d-block'
                                            />
                                        </>
                                    )}

                                </>

                            )}
                        </>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption
                            onHide={onGoBackHandler}
                            caption="Woocommerce"
                            icon={<img style={{ width: 28 }}
                                alt=''
                                src={wooIcon}
                            />}
                        />
                        <Offcanvas.Body>

                            {myAction ? <h5 className='mb-3'>{myAction.label}</h5> : null}
                            {values.integrationData.connectionId ? (
                                values.integrationData.action ? (
                                    <>
                                        {myAction ?
                                            <>
                                                {myAction.fields
                                                    .filter(field => field.required)
                                                    .map(mapFieldsHandler)}
                                                {myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 && (
                                                        <>
                                                            <FormCheck
                                                                name='isSameFlow'
                                                                type='switch'
                                                                id='fields-shopify-switch'
                                                                label="Optional Fields"
                                                                checked={values.isSameFlow}
                                                                onChange={event => {
                                                                    setFieldValue('isSameFlow', event.target.checked);
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {values?.isSameFlow && myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 ? (
                                                    <div className={bg.background}>
                                                        {myAction.fields
                                                            .filter(field => !field.required)
                                                            .map(mapFieldsHandler)}
                                                    </div>
                                                ) : null}
                                            </> : null}
                                    </>
                                ) : <SelectIntegrationAction app='Woocommerce' isOrder={isOrderBased} />) : (
                                <IntegrationConnection app='Woocommerce' scope="COM" onScreenChange={(screen: any) => { setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit'); }} isInvalidAccess = {props?.isInvalidAccess} />
                            )}

                        </Offcanvas.Body>

                        {(isEditScreen && !isCreateScreen) && values?.integrationData?.connectionId && (
                            <div className='editor-footer'>
                                <Button variant='outline-dark' onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button className='sendButton' type='submit' disabled={isCreateScreen || props?.isInvalidAccess}>
                                    Save
                                </Button>
                            </div>)}
                    </Form>
                )
            }}
        </Formik >
    )
}

export default WooCommerce;