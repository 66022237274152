import { Handle, Position } from 'reactflow';
import { ListGroup } from 'react-bootstrap';

import Node from './node';
import classes from './delay.module.css';

function ApiCalls(props: any) {
    return (
        <Node {...props} label='API Call' showRight={false} content={(
            <div>
                <ListGroup>
                    <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
                        <div>
                            <span ></span>
                            <span className={classes.caption}>On Success</span>
                            <Handle
                                className={classes.dot}
                                id='api_call/success'
                                type='source'
                                position={Position.Right}
                                isConnectable
                            />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
                        <div>
                            <span className={classes.caption}>On Fail</span>
                            <Handle
                                className={classes.dot}
                                id='api_call/failed'
                                type='source'
                                position={Position.Right}
                                isConnectable
                            />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        )} />
    )
}

export default ApiCalls;