import React, { useState } from "react";
import Modall from "../../common/modall";
import StyleTestingCampaign from "./CampaignInfo.module.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Label } from "reactstrap";
import FlagInput from "../../common/flagInput";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";
import { LuInfo } from "react-icons/lu";
import TooltipReference from "../../common/tooltip";
import {
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type Props = {
  isTestCampaign: boolean;
  closeModal: () => void;
  handleSubmit: (contacts: any) => void;
};

const TestCampaign: React.FC<Props> = ({
  isTestCampaign,
  closeModal,
  handleSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(isTestCampaign);

  const initialContacts = [{ id: 1, countryCode: "91", mobileNumber: "" }];

  const handleClose = () => {
    setIsOpen(false);
    closeModal();
  };

  const validationSchema = Yup.object().shape({
    testcontact: Yup.array()
      .of(
        Yup.object().shape({
          mobileNumber: Yup.string()
            .matches(/^[0-9]+$/, "Must be a valid phone number")
            .min(7, "Phone number must be at least 7 characters")
            .max(13, "Phone number cannot exceed 13 characters")
            .required("Phone number is required"),
        })
      )
      .min(1, "At least one contact is required"), // Ensures at least one object exists in the array
  });

  return (
    <Modall isOpen={isOpen} onClose={handleClose} title="Test Campaign" size="md">
      <div className={StyleTestingCampaign.testCampaignInfo}>
        <div className={StyleTestingCampaign.information}>
          <div className="me-1">
            <LuInfo size={16} />
          </div>
          Template variables and URL data will come from the first contact in the dynamic segment or Excel sheet. This helps ensure you can test the message quality and engagement.
        </div>
        <Formik
          initialValues={{ testcontact: initialContacts }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values.testcontact);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className="mt-2">
              <Label htmlFor="testcontact">Phone Numbers{" "}
              <TooltipReference
                                    placement="bottom"
                                    icon={faInfoCircle}
                                    content={'Max limit 10 numbers'}
                                    tooltipId="testCampaign_max"
                                  ></TooltipReference>
              </Label>
              {values.testcontact.map((phone, index) => (
                <div key={phone.id} className="mb-3">
                  <div className="d-flex align-items-center">
                    <div className="input-group inputBorder">
                      <div className="input-group-prepend ms-1 me-3">
                        <FlagInput
                          codeChange={(newCode: string) => {
                            // Remove the '+' if present
                            const sanitizedCode = newCode.startsWith('+') ? newCode.slice(1) : newCode;
                            setFieldValue(`testcontact[${index}].countryCode`, sanitizedCode);
                          }}
                        />
                      </div>
                      <Field
                        type="text"
                        name={`testcontact[${index}].mobileNumber`}
                        placeholder="Enter phone number"
                        className="form-control"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const mobileNumber = e.target.value.replace(/[^0-9]/g, "");
                          if(mobileNumber?.length <=13){
                            setFieldValue(`testcontact[${index}].mobileNumber`, mobileNumber);
                          }
                        }}
                      />
                    </div>
                    <RiDeleteBin6Line
                      size={20}
                      className={`ms-2 ${
                        values.testcontact.length <= 1 ? "text-muted" : "text-danger"
                      }`}
                      style={{
                        cursor:
                          values.testcontact.length <= 1 ? "not-allowed" : "pointer",
                      }}
                      onClick={() => {
                        if (values.testcontact.length > 1) {
                          const updatedContacts = values.testcontact.filter(
                            (_, i) => i !== index
                          );
                          setFieldValue("testcontact", updatedContacts);
                        }
                      }}
                    />
                  </div>
                  <ErrorMessage
                    name={`testcontact[${index}].mobileNumber`}
                    component="div"
                    className="text-danger ms-2"
                  />
                </div>
              ))}
              <div className="mb-3">
                <button
                  type="button"
                  className="sendButton p-1"
                  onClick={() => {
                    if (values.testcontact.length < 10) {
                      setFieldValue("testcontact", [
                        ...values.testcontact,
                        {
                          id: Date.now(),
                          countryCode: "91",
                          mobileNumber: "",
                        },
                      ]);
                    }
                  }}
                  disabled={values.testcontact.length >= 10}
                >
                  <FaPlus /> Add New
                </button>
              </div>
              <div className="row">
                <div className="col-6">
                  {/* <div className="mt-1"></div> */}
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      type="button"
                      className="cancelButton w-50 p-2 me-2"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="sendButton w-50 p-2"
                      disabled={isSubmitting}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modall>
  );
};

export default TestCampaign;
