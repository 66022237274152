import { Button, Modal } from 'react-bootstrap';

import styles from './createBot.module.scss';
import * as botService from '../../services/bots';

interface Props {
    uid: string;
    show: boolean;
    onHide: () => void;
    onDelete: () => void;
    name: string;
};

const DeleteBot: React.FC<Props> = (props: Props) => {
    async function deleteBotHandler() {
        await botService.deleteBot(props.uid);
        props.onHide();
        props.onDelete();
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className={styles.createBot}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Bot - {props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete the bot?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ width: "unset" }} className='mt-2' variant='outline-dark' onClick={props.onHide}>
                    Cancel
                </Button>
                <Button className='signUpBtn mb-2' onClick={deleteBotHandler}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteBot;