import Badge from 'react-bootstrap/Badge';
import { MdTitle } from 'react-icons/md';

import Node from '../node';

function AskText(props: any) {
    return (
        <div>
            <Node {...props} label='Ask Text' content={(
                <>
                    {props.data.storageVariable ? (
                        <Badge bg='secondary' style={{ fontSize: 6.5 }}>
                            <MdTitle />
                            {props.data.storageVariable.name}
                        </Badge>
                    ) : null}
                    <div>
                    {props.data.text? (
            <div style={{ cursor: 'pointer' }}>
                {props.data.text.substring(0, 17)}
                {props.data.text.length > 17 ? '...' : null}
            </div>
        ) : null}
                    </div>
                </>
            )} />
        </div>
    );
}

export default AskText;