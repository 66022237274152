import React, { useState }from 'react';
import fb from '../../assets/img/fbCat.png';
import { metaSync, metaGetAll } from '../../services/catalogueService';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

export const ConnectPage = () => {
    const [isRotating, setIsRotating] = useState(false);
    const channel :any = useSelector ((state: any) => state.cartreducer.channelUid);
    const getAllData = async () => {
        try {
          const response = await  metaGetAll(channel?.value);
        } catch (error) {
          console.error('Error:', error); 
        }
    };

    const handleSync = async () => {
        try {
          await metaSync(channel?.value);
          getAllData();
        } catch (error) {
          console.error('Error:', error); 
        }
    };
    return (
        <div>   
        <h5 className='mt-3'>Facebook Catalogues</h5>
        <Button
            className="refreshIconHistory me-2 float-right"
            title='Sync'
            onClick={() => {
              setIsRotating(true);
              handleSync();
            }}
          >
            <svg
              className={isRotating ? "rotate" : "reload"}
              onAnimationEnd={() => {
                setIsRotating(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_2402_33684)">
                <path
                  d="M19.166 3.33325V8.33325H14.166"
                  stroke="#666E7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.833984 16.6667V11.6667H5.83398"
                  stroke="#666E7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.92565 7.49998C3.34829 6.30564 4.0666 5.23782 5.01354 4.39616C5.96048 3.55451 7.10521 2.96645 8.34089 2.68686C9.57657 2.40727 10.8629 2.44527 12.08 2.79729C13.297 3.14932 14.405 3.80391 15.3007 4.69998L19.1673 8.33331M0.833984 11.6666L4.70065 15.3C5.59627 16.1961 6.7043 16.8506 7.92133 17.2027C9.13836 17.5547 10.4247 17.5927 11.6604 17.3131C12.8961 17.0335 14.0408 16.4455 14.9878 15.6038C15.9347 14.7621 16.653 13.6943 17.0757 12.5"
                  stroke="#666E7D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2402_33684">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Button>
        <div className='bg-white fbModal'>
            <div className='container'>
                <div className='row'>
                    <div className='offset-md-3 col-md-6'>
                        <div className='card'>
                            <div className='card-body d-flex'>
                                <img src={fb} alt='cat' className='col-md-4'></img>
                                <div className='col-md-8'>
                                <h6>Facebook Catalog!</h6>
                                <p className='mb-4 text-muted'>Connect your own Facebook catalog effortlessly to share products and collections with your users instantly through WhatsApp messages!</p>
                                <a href='https://business.facebook.com/settings/' className='sendButton ml-5' target='_blank'>Connect your Facebook</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}