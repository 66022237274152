import * as React from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';

export interface IAppProps {
    profileChange: (profileUrl:any) => void;
}


function PicturesModal (props: IAppProps) {
    const [activeImage, setActiveImage] = React.useState<number | null>(null);
  const images = ['Dummy1.png', 'Dummy2.png', 'Dummy3.png', 'Dummy4.png', 'Dummy5.png',
  'Dummy6.png', 'Dummy7.png', 'Dummy8.png', 'Dummy9.png', 'Dummy10.png',
  'Dummy11.png', 'Dummy12.png', 'Dummy13.png', 'Dummy14.png', 'Dummy15.png',
];
  
  const handleImageClick = (index: number,image:any) => {
    setActiveImage(index);
    props.profileChange(image)
  };

  const renderImages = () => {
    const imagesPerRow = 3; // Number of images per row
    const numRows = Math.ceil(images.length / imagesPerRow); // Calculate the number of rows
  
    // Generate the rows and columns for the images
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      const rowImages = images.slice(i * imagesPerRow, (i + 1) * imagesPerRow);
  
      const cols = rowImages.map((image, index) => (
        <Col key={index}>
          <div
            className={`dummyImg ${activeImage === index + i * imagesPerRow ? 'dummyImgActive' : ''}`}
            onClick={() => handleImageClick(index + i * imagesPerRow, image)}
          >
            <img src={require(`./../../assets/img/${image}`)} width={130} height={130} alt={`oldimage_${index}`} />
          </div>
        </Col>
      ));
  
      rows.push(<Row key={i}>{cols}</Row>);
    }
  
    return rows;
  };
  
  return (
    <div>
      <FormGroup className='images'>
  
          {renderImages()}
        {/* <Row>
          {renderImages()}
        </Row> */}
      </FormGroup>
    </div>
  );
}

export default PicturesModal;
