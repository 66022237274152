import React, { useEffect, useState } from 'react';
import Node from './node';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import MapLoader from '../../../../common/MapLoader';

function SendLocation(props: any) {

  return (
    <Node {...props} label='Send Location' content={(
      <MapLoader>
        <div id='send-loc'>
          <GoogleMap
            id="send-loc-map"
            mapContainerStyle={{ width: "150px", height: "150px" }}
            zoom={12}
            center={{
              lat: props.data.location.latitude,
              lng: props.data.location.longitude,
            }}
          >
            <Marker
              position={{
                lat: props.data.location.latitude,
                lng: props.data.location.longitude,
              }}
            />
          </GoogleMap>
        </div>
      </MapLoader>
    )} />
  );
}

export default SendLocation;
