import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormBootstrap from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import axios from "./../../utils/axios";
import * as Yup from "yup";
import { Col, Row } from "reactstrap";
import { toast, toastAlert } from "../../common/alert";

type apiObject = {
  name: string,
  id: number,
  businessUid: string,
  apiKey: string,
  memberUid: string
}

type addContactProps = {
  component?: string;
  editName?: string;
  setEditName?: any;
  apiObject?: apiObject;
  fetchContacts?: () => void;
  handleCancel?: () => void;
  createContact?: (val: boolean) => void;
  getSingleConversation?: (conv: any) => void;
  handleClose?: () => void;
  getConvFromContact?: () => void;
  contactType?: string
};
interface FormValues {
  name: string,
  id: number,
  businessUid: string,
  apiKey: string,
  memberUid: string

}

const AddApiKey: React.FC<addContactProps> = ({
  fetchContacts,
  handleCancel,
  handleClose,
  editName,
  apiObject
}) => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const memberUid = useSelector((state: any) => state.cartreducer.business?.uid)
  const [isSubmit, setSubmit] = useState(false);
  const initialValues: FormValues = {
    name: editName || "",
    id: 0,
    businessUid: '',
    apiKey: '',
    memberUid: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (!isSubmit) {
      if (!editName) {
        const addApiKey = {
          businessUid: businessId,
          name: values.name,
          memberUid: memberUid
        };
        axios
          .post("/business/createApiKey", addApiKey)
          .then((res) => {
            toastAlert("top-end", "API Key created successfully", "success");
            fetchContacts?.();
            handleCancel?.();
            handleClose?.();
          })
          .catch((error: any) => {
            if (error.response.data.message === "API key name already exist") {
              toast("error", "API key name already exists");
              setSubmit(false);
            }
            else {
              toast("error", error.response.data.message);
            }
          }).finally(() => {
            setSubmitting(false);
          });
      }
      else if (editName && apiObject) {
        apiObject.name = values?.name;
        const editKey = apiObject;
        axios
          .post("/business/createApiKey", editKey)
          .then((res) => {
            toastAlert("top-end", "API Key updated successfully", "success");
            fetchContacts?.();
            handleCancel?.();
            handleClose?.();
          })
          .catch((error: any) => {
            if (error.response.data.message === "API key name already exist") {
              toast("error", "API key name already exists");
              setSubmit(false);
            }
            else {
              toast("error", error.response.data.message);
            }
          }).finally(() => {
            setSubmitting(false);
          });
      }
      setSubmit(true);
    }
  }


  const cancelButton = () => {
    if (handleCancel) {
      handleCancel()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount={true}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className={`centered-form-temp`}>
          <FormBootstrap.Group className="form-group">
            <FormBootstrap.Label className="pt-2">
              Name<span className="redstar_required"></span>
            </FormBootstrap.Label>
            <Field
              type="text"
              name="name"
              value={values.name}
              id="name"
              placeholder="Enter your name"
              className="form-control"


            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-danger pt-1"
            />
          </FormBootstrap.Group>

          <Row className="mt-3">
            <Col>
              <Button
                variant=""
                type="button"
                className="cancelButton mr-3"
                onClick={cancelButton}
              >
                Cancel
              </Button>
              <Button variant="" type="submit" className="sendButton" disabled={editName === values.name ? true : false}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>

      )}
    </Formik>
  );
};

export default AddApiKey;
