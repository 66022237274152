import React from 'react';

interface SourceIconsProps {
    source: string;
}

const SourceIcons: React.FC<SourceIconsProps> = ({ source }) => {
    const getSourceIcon = (source: string) => {
        switch (source.toLowerCase().replaceAll(" ", "")) {
            case "facebook":
            case "facebook-post":
                return (
                    <img src={`${process.env.PUBLIC_URL}/images/sourceIcons/facebook.svg`} alt='' width={30} />
                );
            case "facebook-ad":
                return (
                    <img src={`${process.env.PUBLIC_URL}/images/sourceIcons/FB_ads.svg`} alt='' width={27} />
                );
            case "google":
                return (
                    <img src={`${process.env.PUBLIC_URL}/images/sourceIcons/google.svg`} alt='' width={30} />
                );
            case "instagram":
                return (
                    <img src={`${process.env.PUBLIC_URL}/images/sourceIcons/insta.svg`} alt='' width={30} />
                );
            case "website":
                return (
                    <img src={`${process.env.PUBLIC_URL}/images/sourceIcons/web.svg`} alt='' width={30} />
                );
            case "whatsapp":
                return (
                    <img src={`${process.env.PUBLIC_URL}/images/sourceIcons/whatsapp.svg`} alt='' width={27} />
                );
            default:
                return (
                    <img src={`${process.env.PUBLIC_URL}/biz_m_logo.svg`} alt='' width={20} />
                );
        }
    };

    return (
        <div>
            {getSourceIcon(source)}
        </div>
    );
};

export default SourceIcons;
