
import React from 'react'
import QRCode from "qrcode.react";
import { saveAs } from 'file-saver';

type Props = {
    qrCodeLink:string;
    setQrCodeLink:any;
}

const qrCodeDownload = (props: Props) => {
    const {qrCodeLink,setQrCodeLink} = props;
    
    const downloadQRCodeHandler = () => {
        const canvas = document.getElementById(qrCodeLink) as HTMLCanvasElement;
        const dataURL = canvas.toDataURL("image/png");
        saveAs(dataURL, qrCodeLink+".png");
      };
  return (
 <>
 <p>
 Copy it and share it via emails, messaging, your website or even social media. Your desired users can contact you in just one click. Download the QR code to share it anywhere.
 </p>
<div className="row  p-4">
    <div className="col text-center">
 <QRCode size={120}  value={qrCodeLink} id={qrCodeLink} />
    </div>
</div>
 <div className="row ">
    <div className=" col d-flex justify-content-end">
 <button className='cancelButton p-1 w-25 me-2' onClick={setQrCodeLink}>Cancel</button>
 <button className='sendButton p-1 w-25' onClick={downloadQRCodeHandler}>Download </button>
    </div>
 </div>
 </>
  )
}

export default qrCodeDownload