import React, { useEffect, useState } from 'react';
import './zohoSubscription.scss';
import { Input, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { submitSubscriptionDetails } from '../../services/SubscriptionService';
import { toast } from '../../common/alert';
import { error } from 'console';
interface zohoProps {
    planDetails: any;
    formatCurrency: (amount: number, currencyType: string) => string;
    addons: any[];
    currency: string | null;
    billingData: any;
}
interface Addon {
    addonCode: string;
    quantity: number;
}

const ZohoSub: React.FC<zohoProps> = (props) => {
    const [addons, setAddons] = useState<Addon[]>([]);
    const [loading, setLoading] = useState(false);
    const business = useSelector((state: any) => state.cartreducer.business);
    const [durInMonths, setDurInMonths] = useState(0);

    useEffect(() => {
        var subs: any[] = [];
        props.addons.map(ad => {
            subs.push({ addonCode: ad.addon_code, quantity: 0 });
        })
        setAddons(subs);
    }, [props.addons])

    useEffect(() => {
        if (props.planDetails)
            getDurInNumber(props.planDetails)
    }, [props.planDetails])

    const getDurInNumber = (plan: any) => {
        switch (plan.interval_unit) {
            case 'months':
                if (plan.interval === 1) {
                    setDurInMonths(1);
                } else if (plan.interval === 3) {
                    setDurInMonths(3);
                }
                break;
            case 'years':
                setDurInMonths(12);
                break;
            default:
                setDurInMonths(0);
                break;
        }
    }

    const getDuration = (plan: any) => {
        switch (plan.interval_unit) {
            case 'months':
                if (plan.interval === 1) {
                    return 'Monthly';
                } else if (plan.interval === 3) {
                    return 'Quarterly';
                }
                break;
            case 'years':
                return 'Yearly';
            default:
                return '';
        }
    }

    const handleInputChange = (code: string, quantity: string, index: number) => {
        const tempaddons = [...addons];
        tempaddons[index].quantity = quantity ? parseInt(quantity) : 0;
        setAddons(tempaddons);
    };

    const goToPayments = async () => {
        setLoading(true);
        const internationalPrice = props.planDetails?.pricebooks?.
            filter((pr: any) => pr.pricebook_name === 'International');
        const indianPrice = props.planDetails?.pricebooks?.
            filter((pr: any) => pr.pricebook_name === 'Bizmagnets');
        const payload = {
            memberUid: business.uid,
            planCode: props.planDetails.plan_code,
            addons: addons.filter(addon => addon.quantity > 0),
            pricebookId: props.currency === 'INR' &&
                (props.billingData?.country?.toLowerCase() === 'india' || !props.billingData?.country) ? indianPrice[0]?.pricebook_id :
                internationalPrice[0]?.pricebook_id
        }
        try {
            const res = await submitSubscriptionDetails(payload);
            if (res?.response?.status !== 400) {
                window.open(
                    res.url,
                    "_blank"
                );
            }
            else {
                toast('error', 'Invalid subscription');
            }
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        const value = (event.target as HTMLInputElement).value;
        if ((charCode < 48 || charCode > 57 || value.length >= 3)) {
            event.preventDefault();
        }
    };

    const internationalPrice = props.planDetails?.pricebooks.
        filter((pr: any) => pr.pricebook_name === 'International');

    return (
        <div className='centered-form-temp'>
            <div className='fontWeight'>
                <span><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481.22 488" height={20} width={20}>
                    <path fill='rgb(0 184 212)' d="M42 110v268c0 10.48 8.52 19 19 19h19.22c11.6 0 21 9.4 21 21s-9.4 21-21 
                    21H61c-33.64 0-61-27.36-61-61V110c0-33.64 27.36-61 61-61h19.22c11.6 0 21 9.4 21 21s-9.4 21-21 
                    21H61c-10.48 0-19 8.52-19 19Zm378.22-61H401c-11.6 0-21 9.4-21 21s9.4 21 21 21h19.22c10.48 
                    0 19 8.52 19 19v268c0 10.48-8.52 19-19 19H401c-11.6 0-21 9.4-21 21s9.4 21 21 21h19.22c33.64 0 61-27.36 
                    61-61V110c0-33.64-27.36-61-61-61ZM308.61 0h-136c-22.09 0-40 17.91-40 40v408c0 22.09 17.91 40 40 40h136c22.09 
                    0 40-17.91 40-40V40c0-22.09-17.91-40-40-40Z"></path></svg></span>
                <span className='pl-2'>PLAN</span>
            </div>
            <hr className='mt-3' />
            <div className='fontWeight pt-3'>
                <span className='float-right mr-3 fontchange'>{props.formatCurrency(props.currency === 'INR' &&
                    (props.billingData?.country?.toLowerCase() === 'india' || !props.billingData?.country) ?
                    props.planDetails.price_brackets[0].price : internationalPrice[0].pricebook_rate,
                    props.currency === "INR" &&
                        (props.billingData?.country?.toLowerCase() === 'india' || !props.billingData?.country) ? "INR" : "USD"
                )}</span>
                <span>{props.planDetails.name} - </span>
                <span>{getDuration(props.planDetails)}</span>
            </div>
            <div className='fontWeight pt-3'>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504.2 491.92" height={20} width={20}>
                        <path fill='rgb(0 184 212)' d="M286.62 91.46v115.7c0 8.07-5.45 15.29-13.32 17.08-26.56 6.05-46.38 29.82-46.38 
                    58.2s19.82 52.15 46.38 58.2c7.87 1.79 13.32 9.01 13.32 17.08v115.22c0 9.56-7.75 17.31-17.31 
                    17.31h-50.72c-8.07 0-15.29-5.45-17.08-13.32-6.05-26.55-29.82-46.38-58.2-46.38s-52.14 19.82-58.19 
                    46.38c-1.79 7.87-9.01 13.32-17.08 13.32H26.33C11.79 490.25 0 478.46 0 463.92V100.46c0-14.53 
                    11.78-26.32 26.32-26.32h43.29c7.69 0 14.09-6.19 14.01-13.87-.03-3.18.19-6.42.69-9.71C88.35 23.84 
                    110.39 2.92 137.3.29 172.97-3.2 203 24.74 203 59.69v.15c-.02 7.85 6.16 14.3 14.01 14.3h52.3c9.56 0 
                    17.31 7.75 17.31 17.31ZM504.2 
                    282.44c0-46.42-30.99-86.1-73.81-98.73v-51.79c0-32.5-26.43-58.94-58.93-58.94h-29.85c-13.42 0-24.31 10.88-24.31 
                    24.31s10.88 24.31 24.31 24.31h29.85c5.69 0 10.31 4.63 10.31 10.32v72.03c0 .17.02.33.02.49 0 .44.03.88.07 
                    1.32.03.38.06.76.1 1.13.05.43.12.86.2 1.28.07.38.13.75.22 1.12.09.4.2.8.31 
                    1.2.11.38.21.75.34 1.12.12.37.26.74.4 1.11.15.38.29.75.46 1.12.15.35.32.68.49 1.02a18.4 18.4 0 0 0 1.15 
                    2.03c.22.34.44.69.67 1.02.22.31.44.6.67.9a20.871 20.871 0 0 0 1.53 
                    1.79c.26.28.53.56.81.83.29.28.58.54.88.8.28.25.57.49.86.73.32.25.65.49.98.73.3.22.6.43.91.63.34.22.69.43 
                    1.05.64.33.19.65.38.99.55.36.19.72.36 1.09.52.36.16.71.32 1.08.47.36.15.73.28 1.1.4.39.13.78.26 1.18.38.36.11.73.2 1.11.28.42.1.84.19 
                    1.26.27.37.07.75.12 1.13.17.43.06.86.11 1.3.14.17.01.33.04.49.05 28.58 1.73 50.97 25.55 50.97 
                    54.22s-22.39 52.5-50.97 54.22c-.17.01-.33.04-.49.05-.44.04-.87.09-1.3.15-.38.05-.75.1-1.13.17-.43.08-.85.17-1.27.27-.37.09-.
                    74.18-1.1.28-.4.12-.79.25-1.18.38-.37.13-.73.26-1.09.4-.37.15-.73.31-1.08.47-.36.17-.73.34-1.08.52-.34.18-.67.36-.
                    99.55-.35.2-.7.41-1.04.63-.31.2-.62.42-.92.64-.33.24-.66.47-.97.73-.3.24-.58.48-.86.73-.3.26-.59.52-.88.8-.28.27-.55.
                    55-.81.83-.26.28-.53.56-.78.85-.26.3-.5.62-.75.93-.23.3-.46.59-.67.9-.23.33-.45.67-.67 1.02-.2.31-.39.63-.58.95-.2.
                    35-.39.72-.57 1.08-.17.34-.34.68-.49 1.02-.16.37-.31.74-.46 1.11-.14.37-.28.73-.41 1.11-.12.37-.23.74-.33 1.12-.11.4-.22.8-.31 1.2-.08.37-.15.74-.22 1.12-.07.43-.14.85-.2 1.28-.05.37-.08.75-.1 1.13-.03.44-.06.87-.07 1.32 0 .16-.02.32-.02.49v72.05c0 5.69-4.63 10.32-10.31 10.32h-29.85c-13.42 0-24.31 10.88-24.31 24.31s10.88 24.31 24.31 24.31h29.85c32.49 0 58.93-26.44 58.93-58.94V381.1c42.82-12.63 73.81-52.31 73.81-98.73Z"></path></svg>
                </span>
                <span className='pl-2'>ADDONS</span>
            </div>
            <hr className='mt-3' />
            {props.addons.map((add, ind) => {
                const quantity = addons?.find(ad => ad.addonCode === add.addon_code)?.quantity || 0;
                const internationalAddonPrice = add.pricebooks?.filter((ad: any) => ad.pricebook_name === 'International');
                return (
                    <>
                        <div className='pt-3 pb-2'>
                            <span className='float-right mr-3'>
                                <Input type='text' onChange={(e) => handleInputChange(add.addon_code, e.target.value, ind)}
                                    onKeyPress={handleKeyPress}
                                />
                            </span>
                            <span>{add.name}</span>
                        </div>
                        <div className='pt-3 pb-2'>
                            <span className='float-right mr-3 fontWeight fontchange'>
                                {props.formatCurrency(
                                    (addons[ind]?.quantity) * (props.currency === 'INR' &&
                                        (props.billingData?.country?.toLowerCase() === 'india' || !props.billingData?.country) ? (add.price_brackets[0].price) :
                                        internationalAddonPrice[0]?.pricebook_rate) * durInMonths,
                                    props.currency === 'INR' &&
                                        (props.billingData?.country?.toLowerCase() === 'india' || !props.billingData?.country) ? "INR" : "USD"
                                )}
                            </span>
                            <span className='fontchange small'>Price ( {(addons[ind]?.quantity) + ' ' + '*' + ' ' +
                                props.formatCurrency(props.currency === 'INR' &&
                                    (props.billingData?.country?.toLowerCase() === 'india' || !props.billingData?.country) ?
                                    add.price_brackets[0].price : internationalAddonPrice[0]?.pricebook_rate,
                                    props.currency === 'INR' &&
                                        (props.billingData?.country?.toLowerCase() === 'india' || !props.billingData?.country) ? "INR" : "USD") + ' ' + '*' + ' ' +
                                durInMonths} )</span>
                        </div>
                    </>
                )
            })}
            <Button
                variant=""
                type="button"
                className="sendButton ml-auto mt-3"
                disabled={loading}
                onClick={goToPayments}
            >
                {loading ? 'Wait...' + ' ' : 'Proceed'}
                {loading && (<div className="inviteloader float-right"></div>)}
            </Button>
        </div>
    );
}

export default ZohoSub;
