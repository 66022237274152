import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Button, FormLabel, Form as FormBootstrap } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import axios from "../../utils/axios";
import SwitchButton from "./SwitchButton";
import { useSelector } from "react-redux";
import { showAlert, toastTags, toastAlert } from "../../common/alert";
import FlagInput from "../../common/flagInput";
import Select from "react-select";
import {
  faCancel,
  faInfoCircle,
  faPlus,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreatableSelect from "react-select/creatable";
import { Container, Row, Col, FormGroup, Label, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import TooltipReference from "../../common/tooltip";
import { getTag } from '../Tag/tagApi'
import { Value } from "sass";
import './contact.scss';
import { isValidNumber, getExampleNumber, isPossibleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples'
import { getAll } from "../../services/companyService";

interface FormValues {
  firstname: string;
  email: string;
  mobileNumber: string;
  // assignMember: string;
  companies: any;
  companyWebsite: string;
  uid: string;
  countryCode: any;
  optIn: boolean;
  tags: any;
  description: string;
  channels: [{
    label: string;
    value: string;
  }]
  contactType: string;
}

interface IAppProps {
  contactEdit: any;
  handleCancel: () => void;
  fetchContacts: any;
  companyLists: any;
  getCompanyContacts: (id: string) => void;
  setTagsCount: (count: number) => void;
  isInvalidAccess: boolean;
  // filterPayload:any;
  // filterCount:number;
}


const EditContact: React.FC<IAppProps> = ({
  contactEdit,
  handleCancel,
  fetchContacts,
  companyLists,
  getCompanyContacts,
  setTagsCount,
  isInvalidAccess
}) => {

  const business = useSelector((state: any) => state.cartreducer.business);
  const [optState, setOptState] = useState<boolean | undefined>(
    contactEdit?.optState
  );
  const [assignMemberOption, setAssignMemberOption] = useState();
  const [addTags, addTagline] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [emailData, getEmail] = useState(false);
  const [tagsList, getTags] = useState<any>([]);
  const [contactType, setContactType] = useState(contactEdit?.contactType);
  const [countryCode, setFlagCode] = useState();
  const [companyList, setCompanyList] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');

  const getdata = useSelector((state: any) => state.cartreducer.flagData);

  // const mobileNumberValidator = Yup.string()
  // .required("WhatsApp number is required")
  // .test('is-valid-mobile', 'Invalid WhatsApp number', (value:any,context) => {
  //   if (!value) return false;
  //   // Extract the country code from the form context if available
  //   const country = countryCode ??  context?.parent?.countryCode;
  //   const codePlusRem = country.slice(1);
  //   const countryIso2Obj = getdata.find((countryId:any) => countryId.phoneCode === codePlusRem);
  //   if(countryIso2Obj){
  //     const { iso2 } = countryIso2Obj;
  //     const isValid = isValidNumber(value, iso2);
  //     return isValid;
  //   } else {
  //     return false;
  //   }
  //   return true;
  // });

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Name is required"),
    // mobileNumber:mobileNumberValidator,
    mobileNumber: Yup.string()
      .required("WhatsApp number is required")
      .min(7, "WhatsApp number must be at least 7 numbers")
      .max(13, "Whatsapp number cannot exceed 13 characters"),
    email: Yup.string().when([], {
      is: () => emailData === true,
      then: Yup.string().matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Email is invalid"
      ),
      otherwise: Yup.string().nullable(),
    }),
    // companyWebsite: Yup.string().when('companies', {
    //   is: (companies: any) => companies && companies?.value,
    //   then: Yup.string().nullable().required('Company website is required')
    //   .matches(
    //     /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
    //     'Please enter a valid website, domain, or email'
    //   ),
    //   otherwise: Yup.string().nullable(),
    // }),
    channels: Yup.array().min(1, 'At least one channel is required'),
  });

  const initialMember = contactEdit?.member
    ? {
      label: contactEdit.member.name,
      value: contactEdit.member.name,
      uid: contactEdit.member.uid,
    }
    : null;
  const [SelectassignMember, setAssignMember] = useState(initialMember || []);


  const [mergeTags, setMergeTags] = useState([]);
  // const [countryMobileError, setCountryMobileError] = useState(''); 
  // const [mergeTagsChanged, setMergeTagsChanged] = useState<boolean>(false);

  // const validateMobile = (country: any, mobilenumber: any) => {
  //   const countryIso2 = getdata.find((countryId: any) => countryId?.phoneCode === country);
  //   const {iso2} =countryIso2
  //   const data = isValidNumber(mobilenumber, iso2);
  //   const exmapleNumber = getExampleNumber(iso2 , examples)
  //   const example =   exmapleNumber?.nationalNumber?.length
  //   setCountryMobileError(`Invalid WhatsApp number`)
  //   return data; 
  // };

  const handleSubmit = async (
    values: any,
    { setSubmitting }: any
  ) => {
    try {

      const contactId = values.uid;
      const country = countryCode ? countryCode : values.countryCode;
      const codePlusRem = country.slice(1);
      const mobile_country = values.mobileNumber;
      const companiesDetails = values?.companies ? values.companies.map((company: any) => company?.details) : null;
      const contactUpdatePayload = {
        uid: business?.business?.uid,
        name: values.firstname.trim(),
        mobileNumber: mobile_country,
        countryCode: codePlusRem,
        email: values.email,
        optIn: optState,
        companies: companiesDetails,
        // companywebsite: values.companyWebsite,
        memberId: SelectassignMember ? (SelectassignMember as any).uid : null,
        tags: mergeTags,
        description: values?.description,
        channelList: values.channels.map((item: any) => item.value),
        contactType: contactType
      };
      // const data = validateMobile(codePlusRem,mobile_country)
      // if(data){
      await axios
        .patch(`contact/${contactId}/update`, contactUpdatePayload)
        .then(async (res) => {

          if (values.firstname) {
            handleCancel();
          }
          const pathParts = window.location.pathname.split('/');
          const id = pathParts[2];
          if (id) {
            getCompanyContacts(id)
          }
          else {
            fetchContacts();
          }
          const tagCount = await getTag({ businessUid: business?.business?.uid, limit: 1, page: 0, search: null }, 'contact')
          setTagsCount(tagCount?.data?.count);
          toastAlert("top-end", "Contact updated successfully", "success");
        });
      // }
    } catch (error: any) {
      toastAlert("top-end", error.response.data.message, "error");
    } finally {
      setSubmitting(false);
    }

  };

  const codeChanges = (e: any) => {
    setFlagCode(e);
  };

  const whatsappOptState = (check: boolean) => {
    setOptState(check);
  };

  const swapContactType = (Type: any) => {
    setContactType(Type.target.value)
  }

  const [channelOpts, getChannelOpts] = useState([])
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );


  const assign = async () => {
    const payload = {
      "businessUid": business?.business?.uid,
      "limit": 1000,
      "page": 0,
      "search": null
    }
    const tagsData = await getTag(payload, 'contact')
    if (tagsData) {
      const tags = tagsData?.data
      getTags(tags)
    }
    const { MemberName, tags } = contactEdit;
    if (MemberName !== "" && MemberName !== undefined) {
      MemberName?.map((member: any) => {
        setAssignMemberOption(member?.name);
      });
    }
  };

  const handleChange = (selectedOption: any) => {
    setAssignMember(selectedOption);
  };

  const addTag = () => {
    addTagline(true);
  };


  const updateContactList = () => {
    let options;
    if (channelData?.length > 0) {
      options = channelData.map((channel: any) => ({
        label: channel.name,
        value: channel.uid,
      }));
    }

    getChannelOpts(options)
  }

  useEffect(() => {
    assign();
    setCompanyList(companyLists?.data)
  }, []);

  useEffect(() => {
    updateContactList();
  }, [])

  useEffect(() => {
    if (contactEdit?.tags) {
      setMergeTags(contactEdit.tags)
    }
    if (limit) {
      getAllCompany(limit)
    }
  }, [contactEdit?.tags, limit])


  const getAllCompany = async (limit: number) => {
    const payload = {
      businessUid: business?.business?.uid,
      limit: limit,
      page: 0
    }
    const response = await getAll(payload);
    if (response?.data) {
      setCompanyList(response?.data)
    }
  }

  const updateContactTags = async (tags: string[]) => {
    const payload = {
      businessUid: business?.business?.uid,
      memberId: business?.uid,
      name: tags,
    }
    try {
      const response = await axios.post(`tag/createTag`, payload)
      addTagline(false);
      assign()
      return response.data;
    } catch (error) {
      console.error("Error updating tags:", error);
    }
  };

  const handleSelectChange = (newValue: any, actionMeta: any) => {
    if (newValue !== null) {
      const tags = newValue.tagsData;
      setMergeTags((prevTags: any) => {
        if (!prevTags.some((tag: any) => tag.name === tags.name)) {
          return [...prevTags, tags];
        }
        return prevTags; // If it's already in prevTags, don't add it again
      });
    }
  };


  const handleCreateOption = async (inputValue: any) => {
    const createTag = await updateContactTags(inputValue)
    if (createTag) {
      setMergeTags((prevTags: any) => {
        const updatedSet = [...prevTags, createTag] as any;
        return updatedSet;
      });
    }
  };
  const { tagsDto } = tagsList;

  const tagOptions = tagsDto && tagsDto?.map((tag: any) => ({
    label: tag.name,
    value: tag.name,
    id: tag.id,
    tagsData: tag
  }));

  const dropTag = async (indexToDrop: number) => {
    setMergeTags((prevTags: any) =>
      prevTags.filter((item: any, index: number) => index !== indexToDrop)
    );
  };

  const emailValidation = (event: any) => {
    const { name, value } = event.target;
    if (value) {
      getEmail(true);
    }
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };


  const searchForCompany = async (company: any) => {
    const payload = {
      businessUid: business?.business?.uid,
      limit: limit,
      page: 0,
      search: company
    }
    const response = await getAll(payload);
    if (response?.data) {
      setCompanyList(response)
    }
  };

  const scrollBottom = (scrollBottom: any) => {
    setLimit(limit + 10)
  }

  return (
    <div>
      <Formik
        initialValues={{
          uid: contactEdit.uid,
          firstname: contactEdit.name,
          mobileNumber: contactEdit.mobileNumber,
          email: contactEdit.email,
          companies: contactEdit.companies
            ? contactEdit.companies.map((company: any) => ({
              label: company.name,
              value: company.name,
              details: company
            }))
            : [],
          companyWebsite: contactEdit.companies ? contactEdit.companies?.map((company: any) => company.webSite) : [],
          countryCode: "+" + contactEdit?.countryCode,
          optIn: contactEdit.optState,
          memberId: contactEdit.member?.name,
          tags: contactEdit?.tags,
          description: contactEdit?.description,
          channels: contactEdit?.channels.map((item: any) => ({ label: item.name, value: item.uid })),
          contactType: contactEdit?.contactType,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      // validateOnMount={true}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className="centered-form-temp ">
            <FormBootstrap.Group className="form-group mb-0">
              <FormLabel className="pt-2">
                Name <span className="redstar_required"></span>
              </FormLabel>
              <Field
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Enter your name"
                className="form-control  "
                onKeyDown={handleKeyDown}
              />
              <ErrorMessage
                name="firstname"
                component="div"
                className="text-danger"
              />
              <FormBootstrap.Label className="pt-1 ">
                WhatsApp number
                <span className="redstar_required"></span>
              </FormBootstrap.Label>
              <div className="input-group inputBorder">
                <div className="input-group-prepend  ms-1 me-3">
                  <FlagInput
                    countryCode={values.countryCode}
                    codeChange={codeChanges}
                  />
                </div>
                <Field
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  placeholder="Enter your WhatsApp number"
                  className="form-control"
                  value={values.mobileNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = e.target.value.replace(/[^0-9]/g, "");
                    if (value.length <= 13) {
                      setFieldValue("mobileNumber", value);
                    }
                  }}
                />
              </div>
              <ErrorMessage
                name="mobileNumber"
                component="div"
                className="text-danger"
              />
              <FormBootstrap.Label className="pt-1 ">Email</FormBootstrap.Label>
              <Field
                type="text"
                name="email"
                id="email"
                placeholder="Enter your email"
                className="form-control"
                onChange={(event: any) => {
                  emailValidation(event);
                  const { name, value } = event.target;
                  setFieldValue(name, value);
                }}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
              <FormBootstrap.Label htmlFor="assignMember" className="pt-1">
                Assign Member
              </FormBootstrap.Label>
              <Select
                name="assignMember"
                options={contactEdit?.MemberName?.map((member: any) => ({
                  value: member?.name,
                  label: member?.name,
                  uid: member?.uid,
                }))}
                value={SelectassignMember}
                onChange={handleChange}
              />
              {/* <ErrorMessage
                name="assignMember"
                component="div"
                className="text-danger pt-1"
              /> */}

              <div className="d-flex justify-content-between pt-1">
                <span>
                  <img
                    src={require("./../../assets/img/whatsappiconImg.png")}
                    className="whatsappImg "
                    alt="Image"
                  />
                  WhatsApp Opt-In {"  "}
                  <TooltipReference
                    placement="bottom"
                    icon={faInfoCircle}
                    content="When 'Opt-in' is on, messages are sent only to those who agreed to marketing."
                    tooltipId="opt-in"
                  ></TooltipReference>
                </span>
                <SwitchButton
                  whatsappOptState={whatsappOptState}
                  checked={values.optIn}
                />
              </div>
              <FormBootstrap.Label className="pt-1 ">
                Company Name
              </FormBootstrap.Label>
              {/* <Field
                type="text"
                name="companies"
                placeholder="Enter company name"
                id="companies"
                className="form-control  "
              /> */}
              <Field name="companies" >
                {({ field, form }: any) => (
                  <Select
                    {...field}
                    // isMulti
                    name="companies"
                    className='pb-1'
                    options={companyList && companyList?.map((company: any) =>
                    ({
                      value: company?.name,
                      label: company?.name,
                      details: company
                    }))}
                    onChange={(company: any) => {
                      if (company) {
                        setFieldValue('companyWebsite', company.details?.webSite);
                        setFieldValue("companies", [company]);
                      } else {
                        setFieldValue('companyWebsite', '');  // Clear the associated field
                        setFieldValue("companies", null);      // Clear the company field
                      }
                    }}
                    // onInputChange={(event: KeyboardEvent) => {searchForCompany(event)}} 
                    onMenuScrollToBottom={(event: KeyboardEvent) => { scrollBottom(event) }}
                    isClearable={true}
                  />
                )}
              </Field>
              {/* <ErrorMessage
                name="companies"
                component="div"
                className="text-danger"
              /> */}
              <FormBootstrap.Label className="pt-1 ">
                Company Website {values?.companies && <span className="required"></span>}
              </FormBootstrap.Label>
              <Field
                type="text"
                name="companyWebsite"
                id="companyWebsite"
                placeholder="Enter website"
                className="form-control mb-2 disabled-data"
                disabled={true}
              />
              <ErrorMessage
                name="companyWebsite"
                component="div"
                className="text-danger"
              />
              <FormBootstrap.Label className="">
                Contact Type
              </FormBootstrap.Label>
              <div className="d-flex">
                <label className="mr-3 text-muted">
                  <Field
                    type="radio"
                    name="type"
                    value="ACCOUNT"
                    checked={contactType === 'ACCOUNT'}
                    onChange={swapContactType}
                    className="mr-1 radiolabel"
                  />
                  Account
                </label>
                <label className="text-muted ">
                  <Field
                    type="radio"
                    name="type"
                    value="LEAD"
                    checked={contactType === 'LEAD'}
                    onChange={swapContactType}
                    className="mr-1 radiolabel"
                  />
                  Lead
                </label>
              </div>

              <FormBootstrap.Label>
                Channels <span className="required"></span>
              </FormBootstrap.Label>
              <Field name="channels" >
                {({ field, form }: any) => (
                  <Select
                    {...field}
                    isMulti
                    name="channels"
                    className='pb-1'
                    options={channelOpts && channelOpts.map((channel: any) =>
                    ({
                      value: channel.value,
                      label: channel?.label,
                      uid: channel?.value,
                    }))}
                    onChange={(channel: any) => {
                      setFieldValue("channels", channel)
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="channels"
                component="div"
                className="text-danger"
              />
              <Button variant="" className="addTag mt-1" onClick={addTag}>
                <FontAwesomeIcon icon={faPlus} /> Add Tag
              </Button>
              {addTags === true ? (
                <div className="addTagSelect">
                  <>
                    <CreatableSelect
                      name="tags"
                      placeholder="Select..."
                      isClearable
                      options={tagOptions}
                      onChange={handleSelectChange}
                      onCreateOption={handleCreateOption}
                    />
                  </>
                </div>
              ) : null}
              <div className="pt-1 pb-1">
                {Array.isArray(mergeTags) &&
                  mergeTags?.slice(0, 3)?.map((tag: any, index) => (
                    <>
                      <span key={index} className="tag-list">
                        {tag.name}{" "}
                        <span
                          className="dropTag"
                          onClick={() => dropTag(index as any)}
                        >
                          x
                        </span>
                      </span>
                    </>
                  ))}
                {mergeTags?.length > 3 && (
                  <>
                    <span
                      className="tagsIcon"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      <FontAwesomeIcon icon={faTag} className="faTags" />
                      <span className="faTagsCount">
                        +{mergeTags.length - 3}
                      </span>
                    </span>
                  </>
                )}
                {showDropdown && (
                  <div className="tag-dropdown-content">
                    {mergeTags && mergeTags?.map((item: any, index) =>
                      index > 2 ? (
                        <div>
                          <Row className="row">
                            <Col className="col-8 drop-item">
                              {" "}
                              <span>{item.name}</span>
                              <span
                                className="dropTag"
                                onClick={() => dropTag(index as any)}
                              >
                                {" "}
                                x{" "}
                              </span>{" "}
                            </Col>
                          </Row>
                        </div>
                      ) : null
                    )}
                  </div>
                )}
              </div>
            </FormBootstrap.Group>
            <FormBootstrap.Label>Notes</FormBootstrap.Label>
            <FormBootstrap.Label>
              {/* Write your post: */}
              <Field name="description">
                {({ field, form }: any) => (
                  <FormBootstrap.Control
                    {...field}
                    as="textarea"
                    placeholder="Add your Notes here"
                    rows={2}
                    cols={40}
                    name="description"
                  />
                )}
              </Field>
            </FormBootstrap.Label>
            <div className="d-flex justify-content-end mt-1">
              <Button
                variant=""
                type="button"
                className="cancelButton me-2"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <span className={`${isInvalidAccess ? "notAllowed" : ""}`} >
                <Button
                  variant=""
                  type="submit"
                  className="sendButton"
                  disabled={isSubmitting || isInvalidAccess}
                >
                  Submit
                </Button>
              </ span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditContact;
