import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const CustomYAxisTick = ({ x, y, payload }:any) => {
  let truncatedValue = payload.value;
  if (truncatedValue.length > 9) {
    truncatedValue = truncatedValue.slice(0, 7) + "..";
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <title>{payload.value}</title>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" style={{ wordBreak: "break-word" }}>
        {truncatedValue}
      </text>
    </g>
  );
};
interface Props{
    ticketTypesReport:any;
}

const SideBarCharts = ({ ticketTypesReport }:Props) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        layout="vertical"
        data={ticketTypesReport}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="types" type="category" tick={<CustomYAxisTick />} />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalStatusCount" name='Total' stackId="a" fill="#21a144" barSize={10} />
        <Bar dataKey="pendingCount" name='Pending' stackId="a" fill="#DFCD24" barSize={10} />
        <Bar dataKey="closedCount" name="Closed" stackId="a" fill="#6EC77C" barSize={10} />
        <Bar dataKey="openCount"  name="Open"stackId="a" fill="#007EC3" barSize={10} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SideBarCharts;
