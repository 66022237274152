import React, { useEffect } from 'react'
import { ListGroup, Spinner } from 'react-bootstrap'
import Node from '../node'
import classes from '../delay.module.css';
import { Handle } from 'reactflow'
import { Position } from 'reactflow'

function AppointmentConfirmation(props: any) {

  let content = (
    <>
    <div>
    <ListGroup>
        <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
            <div>
                <span ></span>
                <span className={classes.caption}>On Success</span>
                <Handle
                    className={classes.dot}
                    id='appointment_confirmation/success'
                    type='source'
                    position={Position.Right}
                    isConnectable
                />
            </div>
        </ListGroup.Item>
        <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
            <div>
                <span className={classes.caption}>On Fail</span>
                <Handle
                    className={classes.dot}
                    id='appointment_confirmation/failed'
                    type='source'
                    position={Position.Right}
                    isConnectable
                />
            </div>
        </ListGroup.Item>
    </ListGroup>
</div>
    </>
  )

  return (
    <Node {...props} label='Appointment Confirm' content={content} />
  )
}

export default AppointmentConfirmation;