import { ListGroup } from 'reactstrap';
import Node from '../node';
import classes from '../sendButtonOption.module.css';
import OptionItem from '../optionItem';

function SetMember(props: any) {
    return (
        <Node {...props} label='Set Member ' content={(
            <>
            <div>Members for Appointment</div>
            <ListGroup as='ol' className={classes.list}>
                { props?.data?.memberDTO && props?.data?.memberDTO?.map((option: any) => {
                    return (
                        <OptionItem
                            key={option?.name}
                            id={`send_product'/${option?.uid + 1}`}
                            optionText={option?.name}
                            readOnly={props.readOnly}
                            isOption={false}
                        /> 
                    );
                })}
            </ListGroup>
            </>
        )} />
    );
}

export default SetMember;



