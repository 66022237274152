import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import moment from 'moment';

const downloadExcel = (appointments: any, memberName: string,config?:any) => {  

  console.log("check",appointments);
  
  const formatDate = (date: any) => {
    const data = new Date(date);
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit'
    } as const;
    const formattedDate = data.toLocaleString('en-US', options);
    return formattedDate;
  }
  const getAvailableDays=(selectedDays:boolean[])=>{
    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const availableDays:string[]=[];
   for(let i=0;i<selectedDays.length;i++){
    if(selectedDays[i]){
      availableDays.push(dayNames[i]);
    }
   }
   if (availableDays.length === 0) {
    return "No days available";
  } else {
    return availableDays.join(" ,");
  }

  }
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Appointments');
  const data = appointments.map((appointment: any) => ({
    Name: appointment.name,
    Description:appointment?.description,
    'Booked On': formatDate(appointment?.createdat),

    'Scheduled Date': appointment?.scheduledAt &&!appointment.emergencyMember ? appointment.scheduledAt.date:appointment?.emergencyMember ?appointment?.date:"-",
    Timeslot: appointment?.scheduledAt?.startTime && appointment?.scheduledAt?.endTime? `${moment(appointment?.scheduledAt?.startTime, "HH:mm:ss").format("h:mm a")} - ${moment(appointment?.scheduledAt?.endTime, "HH:mm:ss").format("h:mm a")}`:'-',
    Contact: appointment.contact?.name,
'Contact Number': appointment?.contact?.mobileNumber 
  ? `+${appointment.contact.mobileNumber.slice(0, 2)} ${appointment.contact.mobileNumber.slice(2)}`
  : '-',
    Status: appointment.status,
  }));

  

  const startDate = moment(config?.dateRange?.startDate).format("DD MMM YYYY");
const endDate = moment(config?.dateRange?.endDate).format("DD MMM YYYY");
const dateRange = `${startDate} to ${endDate}`;
const availableHoursAndDays = config?.availableHour.map((val: any) => 
  `${moment(val.startTime, "HH:mm:ss").format("h:mm a")} to ${moment(val.endTime, "HH:mm:ss").format("h:mm a")} (${getAvailableDays(val?.day)})`,
);
  worksheet.addRow([memberName]);
  if(config){
    worksheet.addRow(availableHoursAndDays);
    worksheet.addRow([dateRange]);
    worksheet.mergeCells('A2:E2');
    worksheet.mergeCells('A3:E3');
  }
  worksheet.mergeCells('A1:E1');
  const headerRow = worksheet.getRow(1);
  headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
  headerRow.font = { bold: true, color: { argb: "black" } ,size:16};
  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F6FAFE' }
  };
  const headerRow1 = worksheet.getRow(2);
  headerRow1.alignment = {vertical: 'middle',  horizontal: 'center' };
  headerRow1.font = { bold: true, color: { argb: "FF000000" }, size: 13 }; 
  headerRow1.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F6FAFE' }
  };
  const headerRow2 = worksheet.getRow(3);
  headerRow2.alignment = {vertical: 'middle',  horizontal: 'center' };
  headerRow2.font = { bold: true, color: { argb: "FF000000" }, size: 13 }; 
  headerRow2.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'F6FAFE' }
  };
  worksheet.addRow([
    'Name',
    'Description',
    'Booked On',
    'Scheduled Date',
    'Time Slot',
    'Contact',
    'Contact Number',
    'Status',
  ]);

  const cellRow=worksheet.getRow(4);
  cellRow.font={bold:true};
  data.forEach((row: any) => {
    worksheet.addRow(Object.values(row));
  });
 
  worksheet.columns.forEach((column) => {
    column.width = 15; 
  });

  worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'A2' }];

  workbook.xlsx.writeBuffer().then((buffer: any) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, 'appointments.xlsx');
  });
};

export default downloadExcel;
