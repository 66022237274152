import Badge from 'react-bootstrap/Badge';
import { MdTitle } from 'react-icons/md';
import Node from '../node';

function AskCtaUrl(props: any) {
    return (
        <div>
            <Node {...props} label='Send CTA URL' content={(
                <>
                    {props.data.storageVariable ? (
                        <Badge bg='secondary' style={{ fontSize: 6.5 }}>
                    {props.data.storageVariable.name && ( <MdTitle />)}    
                            {props.data.storageVariable.name}
                        </Badge>
                    ) : null}
                    <div>
                    {props.data?.interActiveMessage?.bodyText ? (
            <div style={{ cursor: 'pointer' }}>
                {props.data.interActiveMessage.bodyText.substring(0, 25)}
                {props.data.interActiveMessage.bodyText.length > 25 ? '...' : null}
            </div>
        
        ) : null}</div>
                </>
            )} />
        </div>
    );
}

export default AskCtaUrl;