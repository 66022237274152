import * as React from 'react'
import * as Yup from 'yup';

export interface WhatsAppValidationClasses<T> {
    component: T,
}
  
export const WhatsAppValidation  = (chooseNav:number) =>
{
    //----------------Whatsapp profile---------------//
 return  Yup.object({
        email: Yup.string().when([], {
            is: () => chooseNav === 0,
            then: Yup.string().nullable().email("Invalid email").required("Email is required"),
            otherwise: Yup.string(),
        }),
        websiteUrl: Yup.string().when([], {
            is: () => chooseNav === 0,
            then: Yup.string().nullable().required("Website is required")
            .matches(
              /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
              'Please enter a valid website, domain, or email'
            ),
               otherwise: Yup.string(),
            }),
    //----------------ComplianceInfo---------------//
        whatsAppProfilename: Yup.string().when([], {
        is: () => chooseNav === 1,
        then: Yup.string().nullable().required("Name is required"),
        otherwise: Yup.string(),
        }),
          customerEmail: Yup.string().when([], {
            is: () => chooseNav === 1,
            then: Yup.string().nullable().email("Invalid email").required("Email is required"),
            otherwise: Yup.string(),
          }),
          grievanceName: Yup.string().when([], {
            is: () => chooseNav === 1,
            then: Yup.string().nullable().required("Grievance name is required"),
            otherwise: Yup.string(),
          }),

          //----------------Whtastpp Widget---------------//
          widget_businessName: Yup.string().when([],{
            is:()=>chooseNav === 2,
            then: Yup.string().nullable().required('Business is required'),
            otherwise:Yup.string(),
          }),
          widget_tagline: Yup.string().when([],{
            is:()=>chooseNav === 2,
            then: Yup.string().nullable().required('Tagline is required'),
            otherwise:Yup.string(),
          }),
        });
    }
export default WhatsAppValidation