import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Contacts from './Contacts'
import ImportContact from './ImportContact'
import { useSelector } from 'react-redux'

function RootContact() {
    const ChannelData = useSelector((state: any) => state.cartreducer.channelData)  
    return (
        <Routes>
            <Route path='/' element={<Contacts />} />
            <Route path={ChannelData?.length > 0 ? '/import' : ''} element={<ImportContact />} />
        </Routes>
    )
}

export default RootContact