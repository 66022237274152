import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  RectangleProps,
  Rectangle,
  Label,
} from "recharts";
import boticon from "../../assets/img/svgs/bot.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

interface DataItem {
  name: string;
  type: string;
  open: number;
  pending: number;
  qualified: number;
  spam: number;
}

interface CustomBarProps extends RectangleProps {
  index?: number;
  dataKey?: string;
  data?: DataItem[];
}

// Function to determine if the current bar is the last bar in the stack that has a non-zero value
const isLastVisibleBar = (
  data: DataItem[],
  dataKey: string,
  index: number
): boolean => {
  const relevantDataKeys = ["open", "pending", "qualified", "spam"];
  const currentKeyIndex = relevantDataKeys.indexOf(dataKey);
  const restKeys = relevantDataKeys.slice(currentKeyIndex + 1);

  // Check if all following keys have zero values for this index
  return restKeys.every((key) => data[index][key as keyof DataItem] === 0);
};

const CustomBarShape = (props: CustomBarProps) => {
  const { fill, x, y, width, height, index, dataKey, data } = props;

  if (!data || index === undefined || !dataKey) return null;

  const radius = isLastVisibleBar(data, dataKey, index) ? 8 : 0;

  return (
    <Rectangle
      fill={fill}
      x={x}
      y={y}
      width={width}
      height={height}
      radius={[0, radius, radius, 0]} // Apply rounded corners on the right side
    />
  );
};

const CircleLegend = (props: any) => {
  const { payload } = props;
  return (
    <div className="d-flex convmargin">
      {payload.map((entry: any, index: number) => (
        <span
          key={`item-${index}`}
          style={{ display: "inline-block", marginRight: 10 }}
        >
          <svg width={10} height={10}>
            <circle cx={5} cy={5} r={5} fill={entry.color} />
          </svg>
          <span style={{ marginLeft: 5 }}>{entry.value === "open" ? "New" : entry.value.charAt(0).toUpperCase() + entry.value.slice(1)}</span>
        </span>
      ))}
    </div>
  );
};


const CustomYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  let truncatedValue = payload.value;
  if (truncatedValue.length > 9) {
    truncatedValue = truncatedValue.slice(0, 7) + ".."; 
  }

  return (
    <g transform={`translate(${x},${y})`}>
       <title>{payload.value}</title>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        style={{ wordBreak: "break-word" }}
      >
        {truncatedValue}
      </text>
    </g>
  );
};

const MyBarChart: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const barColors = ["#4aa6fc", "#3364fa", "#21a144", "#FF7F7F"];
  const nameFormatter = (type:any, name: string) => {
    return name? <span>{type === 1 ? <img src={boticon} className="mr-1 pb-1" height={18} alt='bot'></img>: <FontAwesomeIcon icon={faUser} className="mr-1 pt-0"></FontAwesomeIcon>}{name} </span> : '';
  }

  const getUniqueXTicks = () => {
    const maxXValue = Math.max(
      ...data.flatMap((item) => [item.open+ item.pending + item.qualified + item.spam])
    );
  
    const tickCount = 6; // Including 0 and the maximum value
    const step = Math.ceil(maxXValue / (tickCount - 1)); // Calculate step size
  
    const ticks = Array.from({ length: tickCount }, (_, index) => index * step);
    ticks[ticks.length - 1] = maxXValue; // Ensure the last tick is the maxXValue
    return ticks;
};
  
  return (
    <div style={{ width: "max-content" }}>
      <BarChart
        width={600}
        height={450}
        data={data}
        layout="vertical"
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis dataKey="name" type="category" tick={<CustomYAxisTick />} />

        <XAxis type="number" ticks={getUniqueXTicks()} interval={0}/>

        <Tooltip
          labelFormatter={(label, payload) => {
            if (payload && payload.length > 0 && payload[0].payload) {
              return nameFormatter(payload[0].payload.type, payload[0].payload.name);
            }
            return '';
          }}
          formatter={(value: any, name:any, entry: any) => {
            if (entry && entry.dataKey) {
              let label = '';
              switch (entry.dataKey) {
                case 'open':
                  label = 'New';
                  break;
                case 'pending':
                  label = 'Pending';
                  break;
                case 'qualified':
                  label = 'Qualified';
                  break;
                case 'spam':
                  label = 'Spam';
                  break;
                default:
                  break;
                }
                return [value, label];
              }
              return [value];
          }}
        />  

        <Legend content={<CircleLegend />} />
        {["open", "pending", "qualified", "spam"].map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            stackId="a"
            fill={barColors[index]}
            barSize={15}
            shape={<CustomBarShape data={data} />}
          />
        ))}
      </BarChart>
      <small className="d-flex convContent">Total Conversations Vs Status</small>
    </div>
  );
};

export default MyBarChart;
