import React, { FormEvent, useEffect, useState } from 'react'
import { Alert, Button, Container, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { faArrowLeft, faArrowRight, faDownload, faInfoCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import styles from './ImportContact.module.scss';
import excelIcon from '../../assets/img/excel.png';
import { toast } from '../../common/alert';
import AxiosInstance from '../../utils/axios';
import moment, { isDate } from 'moment';

enum ImportContactPages {
    Download_Sample,
    Upload_Excel
}

function ImportContact() {
    const [page, setPage] = useState<ImportContactPages>(ImportContactPages.Download_Sample);
    const navigate = useNavigate();
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone();
    const businessUid = useSelector((state: any) => state.cartreducer.business?.business?.uid);
    const memberUid = useSelector((state: any) => state.cartreducer.business?.uid);
    const activeChannel = useSelector((state: any) => state.cartreducer.channelUid);

    // Upload contact
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<null | File>(null);
    const [marketOptin, setMarketOptin] = useState(true);
    const [generalAlert, setGeneralAlert] = useState<string>('');

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    const onGoBackHandler = () => {
        if (page === ImportContactPages.Upload_Excel) {
            setPage(ImportContactPages.Download_Sample);
        } else {
            navigate("../");
        }
    }
    const dataToExport = [
        ["Name", "Email", "Mobile Number", "Country Code", "Tags"],
        ["Sample0", "sample0@example.com", "9800000000", "91", "test tag"],
        ["Sample1", "sample1@example.com", "9800000001", "91", "test tag, testtag_1"]
    ];

    const exportToExcel = (data: any, filename: any) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "upload");
        XLSX.writeFile(workbook, filename + ".xlsx");
      };
    const exportExcel = () => {
        exportToExcel(dataToExport, "import_contact");
      };

      
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validateExcelDataHandler = (data: any[]): boolean => {
        const contactSchema = yup.object().shape({
            name: yup.string().trim().required(),
            // email: yup.string().trim().email().notRequired(),
            email: yup.string().trim()
                .matches(emailRegex, "Email format is not valid") // Further validates email against the custom regex
                .nullable() // Allows the value to be null
                .notRequired(),
            mobileNumber: yup.string().trim()
                .required()
                .matches(/^\d+$/, "Mobile number must be numeric")
                .min(7, 'Mobile number must be at least 7 digits')
                .max(15, 'Mobile number must be no more than 15 digits'),
            countryCode: yup.string().trim().required(),
        });

        for (let i = 0; i < data.length; i++) {
            try {
                contactSchema.validateSync(data[i]);
            } catch (error) {
                return false;
            }
        }
        return true;
    }
    const validateContact = (contact: any) => {
        const errors: string[] = [];
        const { name, email, mobileNumber, countryCode } = contact;

        if (!name) {
            errors.push("Name is required");
        }
        if (!mobileNumber) {
            errors.push("Mobile number is required");
        } else if (!/^\d+$/.test(mobileNumber)) {
            errors.push("Mobile number must be numeric");
        } else if (mobileNumber.length < 7 || mobileNumber.length > 15) {
            errors.push("Mobile number must be between 7 and 15 digits");
        }
        if (!countryCode) {
            errors.push("Country code is required");
        }
        else if (!/^\d+$/.test(countryCode)) {
            errors.push("Country code must be numeric");
        }
        else if (countryCode.length > 4) {
            errors.push("Country code must be less than 4 digits");
        }
        if (email) {
    const emailPattern = /^([\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6})(,\s*[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6})*$/;
    if (!emailPattern.test(email)) {
        errors.push("Email format is invalid");
    }
}

        return errors;
    };

    const onUploadHandler = (event: FormEvent) => {
        setLoading(true);
        event.preventDefault();
        if (file) {
            if (!file || !file.name.match(/\.(xls|xlsx)$/i)) {
                toast("error", "Invalid file format");
                setLoading(false);
            } else {
                const reader = new FileReader();
                reader.onload = async (e: any) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'array',cellDates: true});
                    let errors = "";

                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const expectedHeaders = ['Name', 'Email', 'Mobile number', 'Country code', 'Tags'];
                    const actualHeaders = [
                        worksheet["A1"]?.v,
                        worksheet["B1"]?.v,
                        worksheet["C1"]?.v,
                        worksheet["D1"]?.v,
                        worksheet["E1"]?.v
                    ].map(header => header?.toLowerCase()); // Convert to lowercase for comparison

                    const expectedHeadersLowerCase = expectedHeaders.map(header => header.toLowerCase());
                    const invalidHeaders = expectedHeadersLowerCase.filter(header => !actualHeaders.includes(header));
                    const json = XLSX.utils.sheet_to_json(worksheet);                    
                    
                    const contacts = json.map((row: any, index: number) => {
                        //invalid header
                        if (invalidHeaders?.length > 0) {
                            errors = `Header format is invalid. Please use this format: ${expectedHeaders.join(', ')}.`;
                            setLoading(false);
                            return null;
                        }
                    // case-sensitive removed
                        const normalizedRow = Object.fromEntries(
                            Object.entries(row).map(([key, value]) => [key.toLowerCase(), value])
                        );                                                
                    
                        const name = String(normalizedRow['name'] || '').trim().toLowerCase();
                        const mobileNumber = String(normalizedRow['mobile number'] || '').trim(); // No need to lowercase for numbers
                        const email = String(normalizedRow['email'] || '').trim().toLowerCase();
                        const countryCode = String(normalizedRow['country code'] || '').trim();
                    
                        let tagsName = normalizedRow['tags'] ? String(normalizedRow['tags']).split(',').map(tag => tag.trim()) : [];
                        if (isDate(normalizedRow['tags'])) {
                            tagsName = [String(moment(new Date(normalizedRow['tags'].getFullYear(), normalizedRow['tags'].getMonth(), normalizedRow['tags'].getDate() + 1)).format('MMM DD YYYY'))];
                        }

                        // const name = row['Name'];
                        // const mobileNumber = String(row['Mobile Number'] || '').trim();
                        // const email = String(row['Email'] || '').trim();
                        // const countryCode = String(row['Country Code'] || '').trim();

                        // let tagsName = row['Tags'] ? String(row['Tags'])?.split(',') : [];
                        // if (isDate(row['Tags'])) {
                        //     tagsName = [String(moment(new Date(row['Tags'].getFullYear(), row['Tags'].getMonth(), row['Tags'].getDate()+1)).format('MMM DD YYYY'))];
                        // }
                        const contactErrors = validateContact({ name, email, mobileNumber, countryCode });
                        if (contactErrors.length > 0) {
                            errors += `Error in row ${index + 2}: ${contactErrors.join(', ')}\n`;
                            return null;
                        } else {
                            return {
                                name,
                                email,
                                mobileNumber,
                                countryCode,
                                optIn: marketOptin,
                                tagsName
                            };
                        }
                    });
                    if (errors !== "") {
                        setGeneralAlert(errors);
                        setLoading(false);
                        return;
                    }

                    const createPayload = {
                        memberId: memberUid,
                        businessUid: businessUid,
                        channelList: [activeChannel?.value],
                        contactsList: contacts,
                    }

                    if (validateContact(contacts)) {
                        try {
                            await AxiosInstance.post(`contact/createlistofcontact`, createPayload);
                            toast("success", "Contacts uploaded successfully");
                            navigate("../");
                        } catch (error) {
                            toast("error", "Contacts failed to upload");
                            setLoading(false);
                        }
                    }
                    else {
                        toast("error", "Invalid fields in file");
                        setLoading(false);
                    }
                };

                reader.readAsArrayBuffer(file);
            }
        } else {
            toast("error", "No File selected");
            setLoading(false);
        }
    }

    let pageContent = (
        <Container className={[styles.container, styles.downloadSample].join(' ')}>
            <h3>Download File Sample</h3>
            {/* <p>Need to see this in action first?<br /> Download the small sample file and make changes <br /> accordingly to prepare your files for imports</p> */}
            <p>Download the sample file and make changes accordingly to import your contacts.</p>
            {/* <a download href={`${process.env.PUBLIC_URL}/default-templates/import_contact.xlsx`} target='_blank'> */}
            <a href="#" onClick={exportExcel}>
                <FontAwesomeIcon className={styles.icon} icon={faFileExcel} />
                <Button color='link'>
                    <FontAwesomeIcon icon={faDownload} className='mr-2' />
                    Download Sample File
                </Button>
            </a>
            <div className='mt-4'>
                <Button
                    onClick={() => setPage(ImportContactPages.Upload_Excel)}
                    // color='primary'
                    className='signUpBtn'
                >
                    <FontAwesomeIcon icon={faArrowRight} className='mr-2' />
                    Next
                </Button>
            </div>
        </Container>
    );
    if (page === ImportContactPages.Upload_Excel) {
        pageContent = (
            <Container className={[styles.container, styles.uploadExcel].join(' ')}>
                <form onSubmit={onUploadHandler}>
                    {!file ? (
                        <div {...getRootProps({ className: styles.dropzone })}>
                            <input {...getInputProps()} accept=".xlsx" />
                            <div>
                                {!isDragActive ? <>Should be an Excel file with format <code>.xlsx</code></> : "Drop the file here ..."}
                                <FontAwesomeIcon className={styles.icon} icon={faUpload} />
                                <Button type='button' size='sm' className='sendButton'>Choose a file</Button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.contactFile}>
                            <div>
                                <img className={styles.icon} alt="" src={excelIcon} />
                                <span className={styles.filename}>{file.name || 'contacts.xlsx'}</span>
                            </div>
                            <Button
                                color='link'
                                onClick={() => { setFile(null); setGeneralAlert('') }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </div>
                    )}
                    <Alert color='warning'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                        Only maximum of 5,000 contacts will be imported at a time
                    </Alert>
                    {generalAlert ? <Alert color="danger" className={styles.errorAlert}>{generalAlert}</Alert> : null}
                    <div className='mt-4'>
                        <Form>
                            <FormGroup switch className={styles.formItem}>
                                <Label check>
                                    Marketing Opt-In
                                </Label>
                                <Input
                                    role="switch"
                                    type="switch"
                                    checked={marketOptin}
                                    onChange={(event) => setMarketOptin(event.target.checked)}
                                />
                                <FormText>Provide the new contacts consent to receive marketing messages. </FormText>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='mt-4'>
                        <Button
                            disabled={loading}
                            type='button'
                            onClick={() => { setPage(ImportContactPages.Download_Sample)
                                if(file){
                                    setFile(null)
                                    setGeneralAlert('')
                                }
                             }}
                            color='primary'
                            className='cancelButton w-25 me-2'
                        >
                            Back
                        </Button>
                        <Button
                            disabled={loading}
                            // color='primary'
                            className='w-25 sendButton'
                        >
                            {loading ? "Loading..." : "Upload"}
                        </Button>
                    </div>
                </form>
            </Container>
        );
    }

    return (
        <Container className="contact-container">
            <div className="global-table-title">
                <h5>
                    <Button
                        color='link'
                        className={styles.backBtn}
                        onClick={onGoBackHandler}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <span>Import Contacts</span>
                </h5>
            </div>
            {pageContent}

        </Container>
    )
}

export default ImportContact