import { ListGroup, Spinner } from 'react-bootstrap'
import Node from '../node'
import React from 'react'
import { Handle, Position } from 'reactflow'
import classes from '../delay.module.css';


function Ticketing(props: any) {

  
  let content = <Spinner />

    content = (
      <>
      <div>
      <ListGroup>
          <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
              <div>
                  <span ></span>
                  <span className={classes.caption}>On Create</span>
                  <Handle
                      className={classes.dot}
                      id='ticketIt/create'
                      type='source'
                      position={Position.Right}
                      isConnectable
                  />
              </div>
          </ListGroup.Item>
          <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
              <div>
                  <span className={classes.caption}>On Update</span>
                  <Handle
                      className={classes.dot}
                      id='ticketIt/update'
                      type='source'
                      position={Position.Right}
                      isConnectable
                  />
              </div>
          </ListGroup.Item>
      </ListGroup>
  </div>
      </>
    )

  return (
    <Node {...props} label='Tickets' content={content} />
  )
}


export default Ticketing;