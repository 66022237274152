import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Popover, Form, PopoverBody, Row } from "reactstrap";
import { Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import "../main.scss";
import Shiprocket from "./IntegrationTypes/Shiprocket";
import ZohoBooks from "./IntegrationTypes/ZohoBooks";
import Shopify from "./IntegrationTypes/Shopify";
import {
  createConnectionBasedOnTypes,
  deletIntegrationBasedOnIntegrationUid,
  getConnectionBasedOnZohoBooks,
  deleteZohoConnection,
  razorPayIntegration,
  wooCommerceIntegration,
  getConnection,
  webEngageIntegration,
  calendlyIntegration
} from "../../../services/integrationService";
import { useSelector } from "react-redux";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert, toast } from "../../../common/alert";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import Razorpay from "./IntegrationTypes/Razorpay";
import WooCommerce from "./IntegrationTypes/WooCommerce";
import { getIntegrationConnections } from "../../../botFlows/services/designFlow";
import { SERVER_URL } from "../../../utils/axios";
import Modall from "../../../common/modall";
import { IIntegrationApp } from "../../../botFlows/entity/integration/integrationApp";
import CartFlow from "./IntegrationTypes/WooCommerceCartFlow";
import Select from "react-select";
import WebEngage from "./IntegrationTypes/WebEngage";
import Stripe from "./IntegrationTypes/Stripe";
import { PiInfo } from "react-icons/pi";
import CashFree from "./IntegrationTypes/CashFree";
import WebHook from "./IntegrationTypes/WebHook";
import Calendly from "./IntegrationTypes/Calendly";
import {
  validationSchema, shopifyValidationSchema, webEngageValidationSchema, calendlyValidationSchema, wooCommerceCartValidationSchema,
  stripeValidationSchema, cashFreeValidationSchema, validationSchemaBrandName, zohoValidationSchema, validationSchemaWoocomerce
} from "./validationSchema";
import FacebookLeads from "./IntegrationTypes/FacebookLeads";

interface LocationState {
  imgSrc: string;
  description: string;
  connectionUid: string;
  connectionName: string;
  isInvalidAccess?: boolean;
  wooCommerceUid?: string | null;
}

export interface MetaData {
  shopifyDomainUrl: string;
  sangamUrl: string;
  woocommerceUrl: string;
}

const IntegrationDetails = () => {
  const { name, integrationUid } = useParams<{
    name?: string;
    integrationUid?: string;
  }>();
  const location = useLocation();
  // const state = location?.state as LocationState;
  const [state, setState] = useState<any>(location.state as LocationState);

  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.id
  );

  const navigate = useNavigate();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Overview");
  const [callDb, setCallDb] = useState(false);
  const [workFlowEnable, setWorkFlowEnable] = useState(false);
  const [zohoConnectionStatus, setZohoConnectionStatus] = useState<any>([]);
  const [zohoBooksConnectionName, setZohoBooksConnectionName] =
    useState<string>("");
  const [isTabVisible, setIsTabVisible] = useState(true);
  const [createModal, setCreateModal] = useState(false);
  const [storedShopifyIntegration, setStoredShopifyIntegration] =
    useState<any>(null);
  const integrationTabs = ["Overview", "Configuration", "Workflow", "Logs"];
  const displayTabs =
    name !== "webEngage" && integrationUid
      ? integrationTabs
      : zohoConnectionStatus.length > 0 || name === "webEngage"
        ? ["Overview", "Configuration"]
        : ["Overview"];

  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const [zohoProcessed, setZohoProcessed] = useState(false);

  const initialValues = {
    integrationName: "",
    userEmail: "",
    userPassword: "",
  };

  const [initialShopify, setInitialShopify] = useState({
    myShopifyShop: "",
  });

  const [initialWebEngage, setInitialWebEngage] = useState({
    webEngage: "",
  });

  const [initialCalendly, setCalendly] = useState({
    connectionName: "",
  })


  const [shopifyInitialValues, setShopifyInitialValues] = useState({
    integrationTypes: "shopify",
    shopName: "",
    shopUrl: "",
  });

  const [cartFlowInitialValues, setCartFlowInitialValues] = useState({
    brandName: "",
    connection: "",
    newConnectionName: "", // Add this field for the new connection input
  });

  const [stripeInitialValues, setStripeInitialValues] = useState({
    integrationName: "",
    tokenKey: "",
  });

  const [cashFreeInitialValues, setCashFreeInitialValues] = useState({
    integrationName: "",
  });

  const initBrandName = {
    brandName: "",
  };

  const initConnectionName = {
    connectionName: "",
  };

  const initialWoocommerce = {
    ConnectionName: "",
    shopUrl: "",
  };

  const initialWebHookValues = {
    webHookName: "",
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [connections, setConnections] = useState<string[]>([]); // Store dropdown options
  const [isNewConnection, setIsNewConnection] = useState(false); // Track if new connection is selected

  const createOrUpdateConnectionHandler = async (connectionName: string) => {
    // if (screen === "create") {
    let url = "";
    url =
      SERVER_URL +
      "/integration/connect?scope=" +
      "COM" +
      "&app=" +
      "RazorPay" +
      "&connectionName=" +
      connectionName +
      "&businessUid=" +
      businessUid +
      "&redirectOAuth=true&type=webhook";

    const checkPopupClosed = () => {
      if (connectionWindow && connectionWindow.closed) {
        getAll(connectionName);
        clearInterval(pollingInterval);
      }
    };

    const connectionWindow = window.open(
      url,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,width=800,height=600"
    );
    const pollInterval = 1000;
    let pollingInterval: NodeJS.Timeout | undefined;

    if (connectionWindow) {
      pollingInterval = setInterval(checkPopupClosed, pollInterval);
    }
  };

  const wooCommerceConnect = async (values: any) => {
    const { ConnectionName, shopUrl } = values;
    // if (screen === "create") {
    let url = "";
    url =
      SERVER_URL +
      "/integration/connect?scope=" +
      "read_write" +
      "&app=" +
      "Woocommerce" +
      "&connectionName=" +
      ConnectionName +
      "&businessUid=" +
      businessUid +
      "&url=https://" +
      shopUrl +
      "&type=webhook";

    const checkPopupClosed = () => {
      if (connectionWindow && connectionWindow.closed) {
        getAllWoocommerce(ConnectionName);
        clearInterval(pollingInterval);
      }
    };

    const connectionWindow = window.open(
      url,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,width=800,height=600"
    );
    const pollInterval = 1000;
    let pollingInterval: NodeJS.Timeout | undefined;

    if (connectionWindow) {
      pollingInterval = setInterval(checkPopupClosed, pollInterval);
    }
  };

  const calendlyConnect = async (values: any) => {
    // if (screen === "create") {
    let url = "";
    url =
      SERVER_URL +
      "/integration/connect?scope=" +
      "COM" +
      "&app=" +
      "Calendly" +
      "&connectionName=" +
      values +
      "&businessUid=" +
      businessUid +
      "&type=webhook";

    const checkPopupClosed = () => {
      if (connectionWindow && connectionWindow.closed) {
        getAllCalendly(values);
        clearInterval(pollingInterval);
      }
    };

    const connectionWindow = window.open(
      url,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,width=800,height=600"
    );
    const pollInterval = 1000;
    let pollingInterval: NodeJS.Timeout | undefined;

    if (connectionWindow) {
      pollingInterval = setInterval(checkPopupClosed, pollInterval);
    }
  };

  const getAll = async (connectionName: string) => {
    try {
      const response = await getIntegrationConnections(
        "RazorPay",
        businessId,
        "COM"
      );

      if (response && response.length > 0) {
        response.forEach(async (item: any) => {
          if (item.name === connectionName) {
            const payload = {
              integrationTypes: "razorPay",
              name: item.name,
              integrationConnectionId: item.id,
              razorPay: item?.metaData,
            };
            // razorPayAccountId
            const response = await razorPayIntegration(businessUid, payload);
            if (response?.data) {
              const { uid } = response?.data;
              const { connection } = response?.data;

              toast("success", "Connection  Successfully");
              navigate(`/integrations/${name}/edit/${uid}`, {
                state: {
                  imgSrc: state?.imgSrc,
                  description: state?.description,
                  connectionUid: connection?.uid,
                },
              });
              setActiveTab("Configuration");
            }
          }
        });
      }
    } catch (error) {
      console.error("Failed to get integration connections:", error);
    }
  };

  const getAllWoocommerce = async (connectionName: string) => {
    try {
      const response = await getIntegrationConnections(
        "Woocommerce",
        businessId,
        "COM"
      );

      if (response && response.length > 0) {
        response.forEach(async (item: any) => {
          if (item.name === connectionName) {
            const payload = {
              integrationTypes: "wooCommerce",
              name: connectionName,
              wooCommerce: {
                wooCommerceWebhooks: ["order"],
              },
              integrationConnectionId: item.id, // the id of the getConnection api
            };
            const response = await wooCommerceIntegration(businessUid, payload);
            if (response?.data) {
              const { uid } = response?.data;
              const { connection } = response?.data;

              toast("success", "Connection  Successfully");
              navigate(`/integrations/${name}/edit/${uid}`, {
                state: {
                  imgSrc: state?.imgSrc,
                  description: state?.description,
                  connectionUid: connection?.uid,
                },
              });
              setActiveTab("Configuration");
            }
          }
        });
      }
    } catch (error) {
      console.error("Failed to get integration connections:", error);
    }
  };

  const getAllCalendly = async (connectionName: string) => {
    try {
      const response = await getIntegrationConnections(
        "calendly",
        businessId,
        "COM"
      );

      if (response && response?.length > 0) {
        response.forEach(async (item: any) => {
          if (item.name === connectionName) {
            const payload = {
              integrationTypes: "calendly",
              name: connectionName,
              integrationConnectionId: item.id, // the id of the getConnection api
            };
            const response = await calendlyIntegration(businessUid, payload);
            if (response?.data) {
              const { uid } = response?.data;
              const { connection } = response?.data;

              toast("success", "Connection  Successfully");
              navigate(`/integrations/${name}/edit/${uid}`, {
                state: {
                  imgSrc: state?.imgSrc,
                  description: state?.description,
                  connectionUid: connection?.uid,
                },
              });
              setActiveTab("Configuration");
            }
          }
        });
      }
    } catch (error) {
      console.error("Failed to get integration connections:", error);
    }
  };
  // const handleZohoBookConnect = (e: any) => {
  //   const payload = {
  //     app: "ZohoBooks",
  //     connectionName: e.connectionName,
  //     businessUid: businessUid,
  //     scope: "COM",
  //     type: "webhook",
  //   };

  //   setZohoBooksConnectionName(e.connectionName);
  //   localStorage.setItem("connectionName", e.connectionName);

  //   const url = window.open(
  //     `${SERVER_URL}/integration/connect?app=${payload.app}&connectionName=${payload.connectionName}&businessUid=${payload.businessUid}&scope=${payload.scope}&type=${payload.type}`,
  //     "_blank"
  //   );
  // };

  const togglePopover = (resetForm?: () => void) => {
    setPopoverOpen(!popoverOpen);
    if (!popoverOpen && resetForm) {
      resetForm();
    }
  };

  const deleteIntegration = async (
    integrationUid: string | number | undefined,
    integrationType: string,
    statusId?: string | number | undefined
  ) => {
    confirmAlert(
      "warning",
      "Are you sure you want to delete this integration?",
      async () => {
        await deletIntegrationBasedOnIntegrationUid(integrationUid).then(
          async (res) => {
            // if (integrationType === "zohoBooks") {
            //   await deleteZohoConnection(statusId);
            // }
            localStorage.removeItem("connectionName");
            localStorage.removeItem("shopifyIntegrationData");
            navigate("/integrations", { state: { update: true } });
          }
        );
      },
      () => { }
    );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // const validationSchema = Yup.object({
  //   integrationName: Yup.string().required("Integration Name is required"),
  //   userEmail: Yup.string()
  //     .email("Invalid email address")
  //     .required("Email is required"),
  //   userPassword: Yup.string().required("Password is required"),
  // });

  const webHookValidationSchema = Yup.object({
    webHook: Yup.string().required("Webhook is required"),
  });

  const shopifyValidationSchema = Yup.object({
    myShopifyShop: Yup.string().required("Shop Name is required"),
  });

  // const webEngageValidationSchema = Yup.object({
  //   webEngage: Yup.string().required("Integration is required"),
  // });

  // const calendlyValidationSchema = Yup.object({
  //   connectionName: Yup.string().required("Integration is required"),
  // });

  // const wooCommerceCartValidationSchema = Yup.object().shape({
  //   brandName: Yup.string().required("Brand Name is required"),
  //   connection: Yup.string().required("Connection is required"),
  //   newConnectionName: Yup.string().when("connection", {
  //     is: "newConnection",
  //     then: Yup.string().required("New Connection Name is required"),
  //   }),
  // });

  // const stripeValidationSchema = Yup.object({
  //   integrationName: Yup.string().required("Integration is required"),
  //   tokenKey: Yup.string().required("Integration is required"),
  // });

  // const cashFreeValidationSchema = Yup.object({
  //   integrationName: Yup.string().required("Integration is required"),
  // });

  // const validationSchemaBrandName = Yup.object({
  //   brandName: Yup.string().required("Brand Name is required"),
  // });
  // const zohoValidationSchema = Yup.object({
  //   connectionName: Yup.string().required("Connection Name is required"),
  // });
  // const validationSchemaWoocomerce = Yup.object({
  //   ConnectionName: Yup.string().required("Connection name is required"),
  //   shopUrl: Yup.string().required("Shop Url is required"),
  // });

  useEffect(() => {
    // Update local state if location.state has initial data
    if (location.state) {
      setState(location.state as LocationState);
    }
  }, [location.state]);

  useEffect(() => {
    // Update local state if location.state has initial data
    if (location.state) {
      setState(location.state as LocationState);
    }
  }, [location.state]);

  useEffect(() => {
    if (typeof integrationUid === "string") {
      setActiveTab("Configuration");
    } else {
      setActiveTab("Overview");
    }
  }, [integrationUid, name]);

  useEffect(() => {
    if (integrationUid?.length === 0) {
      if (name === "zohoBooks") {
        if (zohoConnectionStatus.length > 0) {
          setActiveTab("Configuration");
        } else {
          setActiveTab("Overview");
        }
      }
    }
  }, [zohoConnectionStatus]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(document.visibilityState === "visible");
    };

    // Add event listener to detect visibility changes
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []); //

  useEffect(() => {
    if (name === "zohoBooks") {
      const savedConnectionName = localStorage.getItem("connectionName");
      if (state?.connectionName) {
        setZohoBooksConnectionName(state?.connectionName);
      } else if (savedConnectionName) {
        setZohoBooksConnectionName(savedConnectionName);
      }
    }
    // Retrieve the values from localStorage
    const storedData = localStorage.getItem("shopifyIntegrationData");
    if (storedData) {
      setStoredShopifyIntegration(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (isTabVisible && zohoBooksConnectionName.length > 0 && !zohoProcessed) {
      // If tab is visible and zohoBooksConnectionName has a valid value, make the API call
      getConnectionBasedOnZohoBooks(
        "ZohoBooks",
        businessId,
        zohoBooksConnectionName
      ).then((res) => {
        setZohoConnectionStatus(res);
        setActiveTab("Configuration");
        setZohoProcessed(true);
      });
    }
  }, [zohoBooksConnectionName, isTabVisible, zohoProcessed]);

  useEffect(() => {
    // Extract the query params from the location.search
    const queryParams = new URLSearchParams(location.search);
    const shop = queryParams.get("shop"); // Get the value of the "shop" query param

    if (shop && shop.includes(".myshopify.com")) {
      // Extract shop name and shop URL from the query param
      const shopName = shop.split(".myshopify.com")[0]; // Get the part before '.myshopify.com'
      const shopUrl = `https://${shop}`; // Construct the full shop URL

      setInitialShopify({
        myShopifyShop: shopName, // Set shop name
      });

      // Set state with extracted values
      setShopifyInitialValues({
        integrationTypes: "shopify",
        shopName, // Set shop name
        shopUrl, // Set full shop URL
      });
      setCreateModal(true); // Open the modal when the URL includes the shop param
    }
  }, [location.search]);

  useEffect(() => {
    if (state?.wooCommerceUid && name === "wooCommerceCart") {
      getConnection(state.wooCommerceUid).then((res) => {
        const connectionUrl =
          res.integrationConfigurationDTO.metaData.woocommerceUrl;
        setConnections([connectionUrl]); // Set the response as options
      });
    }
  }, [state, name]);

  return (
    <div className="integrationDetails">
      <div className="integrationOverview">
        <div
          onClick={() => {
            navigate("/integrations");
            // Remove connectionName from localStorage after deletion
            if (name === "zohoBooks") {
              localStorage.removeItem("connectionName");
            }
          }}
          style={{ cursor: "pointer" }}
        >
          Integration {">"}
        </div>
        <div className="selected-type">
          {name === "sprocket"
            ? "Shiprocket"
            : name === "razorPay"
              ? "Razorpay"
              : name === "shopify"
                ? "Shopify"
                : name === "wooCommerceCart"
                  ? "CartFlow"
                  : name === "stripe"
                    ? "Stripe"
                    : name === "cashFree"
                      ? "Cash Free"
                      : name === "genericWebHook"
                        ? "Generic Webhook"
                        : name === "calendly"
                          ? "Calendly"
                          : name === "FacebookLeads"
                            ? "Facebook Leads"
                            : name}
        </div>
      </div>
      <div className="integrationDetails-header">
        <Row>
          <Col
            md={1}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              {name === "shopify" && !integrationUid ? (
                <img src={storedShopifyIntegration?.imgSrc} alt={name} />
              ) : (
                <img src={state?.imgSrc} alt={name} />
              )}
            </div>
          </Col>
          <Col
            md={
              name === "shopify" && !integrationUid ? 6 : integrationUid ? 7 : 9
            }
            className="d-flex align-items-center"
          >
            <div>
              <h5 className="fw-bold">
                {name === "sprocket"
                  ? "Seamlessly Connect ShipRocket with BizMagnets!"
                  : name === "Zoho Books"
                    ? "Zoho Books Integration"
                    : name === "razorPay"
                      ? "Razorpay"
                      : name === "shopify"
                        ? "Shopify Integration"
                        : name === "wooCommerceCart"
                          ? "WooCommerce CartFlow Integration"
                          : name === "webEngage"
                            ? "webEngage Integration"
                            : name === "stripe"
                              ? "Stripe Integration"
                              : name === "cashFree"
                                ? "Cash Free Integration"
                                : name === "genericWebHook"
                                  ? "WebHook Integration"
                                  : name === "calendly"
                                    ? "Calendly Integration"
                                    : name === "FacebookLeads"
                                      ? "Facebook Leads Integration"
                                      : name}
              </h5>
              <div className="description">
                {name === "shopify" && !integrationUid ? (
                  <>{storedShopifyIntegration?.description}</>
                ) : (
                  <>{state?.description}</>
                )}
              </div>
            </div>
          </Col>
          <Col
            md={
              name === "shopify" && !integrationUid ? 5 : integrationUid ? 4 : 2
            }
            className={`d-flex justify-content-${integrationUid || (name === "shopify" && !integrationUid)
                ? "end"
                : "start"
              } align-items-center`}
          >
            <div>
              <Formik
                initialValues={{}}
                validationSchema={validationSchema}
                onSubmit={(e: any) => console.log(e, "event")}
              >
                {({ handleChange, values, resetForm, touched, errors }) => (
                  <>
                    {integrationUid || zohoConnectionStatus.length > 0 ? (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="me-3 lastUpdate">Status :</div>
                          <div className="connectionConnected">Connected</div>
                          <div
                            className={`d-flex align-items-center mt-2 ms-1 ${state?.isInvalidAccess ? "disabledState" : ""
                              } `}
                          >
                            <UncontrolledDropdown group>
                              <DropdownToggle
                                className="delete-toggle"
                                style={{ background: "none", border: "none" }}
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="pt-1"
                                />
                              </DropdownToggle>
                              <DropdownMenu className="ctxMenu">
                                <DropdownItem className="ctxMenuItem itemsColor">
                                  <div
                                    onClick={() => {
                                      if (
                                        name === "sprocket" ||
                                        name === "razorPay" ||
                                        name === "wooCommerce" ||
                                        name === "shopify" ||
                                        name === "wooCommerceCart" ||
                                        name === "webEngage" ||
                                        name === "stripe" ||
                                        name === "cashFree" ||
                                        name === "genericWebHook" ||
                                        name === "calendly" ||
                                        name === "FacebookLeads"
                                      ) {
                                        deleteIntegration(integrationUid, name);
                                      } else if (name === "zohoBooks") {
                                        deleteIntegration(
                                          integrationUid,
                                          "zohoBooks",
                                          zohoConnectionStatus[0].id
                                        );
                                      }
                                    }}
                                    style={{ color: "#00b598" }}
                                  >
                                    <FontAwesomeIcon
                                      className="mr-2"
                                      icon={faTrash}
                                      color="#00b598"
                                    />
                                    Delete
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </>
                    ) : name === "razorPay" ||
                      name === "wooCommerce" ||
                      name === "sprocket" ||
                      name === "zohoBooks" ||
                      name === "wooCommerceCart" ||
                      name === "stripe" ||
                      name === "cashFree" ||
                      name === "genericWebHook" ||
                      name === "FacebookLeads"
                      ? (
                        <>
                          <Button
                            id="Popover1"
                            type="button"
                            onClick={() => togglePopover(resetForm)}
                            className="connect-btn"
                          >
                            Connect
                          </Button>
                          <Popover
                            placement="bottom"
                            isOpen={popoverOpen}
                            target="Popover1"
                            toggle={() => togglePopover(resetForm)}
                          >
                            <PopoverBody
                              style={
                                name === "razorPay" ? { width: "280px" } : {}
                              }
                            >
                              {name === "razorPay" ? (
                                <>
                                  <Formik
                                    initialValues={initBrandName}
                                    validationSchema={validationSchemaBrandName}
                                    onSubmit={async (
                                      newValues,
                                      formikHelpers
                                    ) => {
                                      await createOrUpdateConnectionHandler(
                                        newValues.brandName
                                      );
                                    }}
                                    validateOnBlur={true} // Ensure validation on blur
                                    validateOnChange={true} // Ensure validation on field change
                                  >
                                    {({
                                      handleChange,
                                      values,
                                      touched,
                                      errors,
                                      handleSubmit,
                                    }) => (
                                      <Form onSubmit={handleSubmit}>
                                        {" "}
                                        {/* Important: Make sure to use Formik's Form */}
                                        <div className="form-group">
                                          <Form className="mt-2">
                                            <label htmlFor="integrationName">
                                              Connection Name{" "}
                                              <span className="required"></span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="brandName"
                                              placeholder="Enter Connection Name"
                                              value={values.brandName}
                                              onChange={handleChange}
                                              className={
                                                touched.brandName &&
                                                  errors.brandName
                                                  ? "is-invalid form-control"
                                                  : "form-control"
                                              }
                                            />
                                            {/* Show validation error */}
                                            <ErrorMessage
                                              name="brandName"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Form>
                                        </div>
                                        <Row className="mt-2">
                                          <Col className="pe-0">
                                            <Button
                                              type="button"
                                              onClick={() => {
                                                resetForm();
                                                togglePopover();
                                              }}
                                              className="cancelButton w-100 p-1"
                                            >
                                              Cancel
                                            </Button>
                                          </Col>
                                          <Col>
                                            <Button
                                              type="submit"
                                              className="sendButton p-1 w-100"
                                            >
                                              Submit
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Form>
                                    )}
                                  </Formik>
                                </>
                              ) : name === "wooCommerce" ? (
                                <>
                                  <Formik
                                    initialValues={initialWoocommerce}
                                    validationSchema={validationSchemaWoocomerce}
                                    onSubmit={async (
                                      newValues,
                                      formikHelpers
                                    ) => {
                                      await wooCommerceConnect(newValues);
                                    }}
                                    validateOnBlur={true} // Ensure validation on blur
                                    validateOnChange={true} // Ensure validation on field change
                                  >
                                    {({
                                      handleChange,
                                      values,
                                      touched,
                                      errors,
                                      handleSubmit,
                                    }) => (
                                      <Form onSubmit={handleSubmit}>
                                        <div className="woocommerceContainer">
                                          <div className="form-group">
                                            <label htmlFor="integrationName">
                                              Connection Name{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="ConnectionName"
                                              className="form-control shopUrl"
                                            />
                                            <ErrorMessage
                                              name="ConnectionName"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label htmlFor="">
                                              Enter Shop URL{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Row className="httpsContainer">
                                              <Col className="https p-0 col-3">
                                                <div>https://</div>
                                              </Col>
                                              <Col className="p-0">
                                                <Field
                                                  type="text"
                                                  name="shopUrl"
                                                  placeholder="shop.bizmagnets.com"
                                                  className="form-control shopUrl"
                                                />
                                              </Col>
                                            </Row>
                                            <ErrorMessage
                                              name="shopUrl"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div>
                                          <div className="d-flex justify-content-end mt-3">
                                            <Button
                                              type="button"
                                              onClick={() => {
                                                resetForm();
                                                togglePopover();
                                              }}
                                              className="cancelButton w-100 me-2 p-1"
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              type="submit"
                                              className="sendButton p-1 w-100"
                                            >
                                              Submit
                                            </Button>
                                          </div>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </>
                              ) : name === "sprocket" ? (
                                <>
                                  <Formik
                                    initialValues={initialValues}
                                    enableReinitialize // This will reinitialize the form when initialValues change
                                    onSubmit={(values, formikHelpers) => {
                                      formikHelpers.setSubmitting(false);
                                    }}
                                    validationSchema={validationSchema}
                                  >
                                    {({ isSubmitting, values }) => (
                                      <Form
                                        onSubmit={async (e) => {
                                          e.preventDefault();
                                          const data = {
                                            name: values.integrationName,
                                            integrationTypes: "sprocket",
                                            shipRocket: {
                                              email: values.userEmail,
                                              password: values.userPassword,
                                            },
                                          };

                                          try {
                                            const response =
                                              await createConnectionBasedOnTypes(
                                                businessUid,
                                                data
                                              );
                                            // formikHelpers.resetForm();
                                            setPopoverOpen(false);
                                            navigate(
                                              `/integrations/${name}/edit/${response.uid}`,
                                              {
                                                state: {
                                                  imgSrc: state?.imgSrc,
                                                  description: state?.description,
                                                  connectionUid:
                                                    response.connection.uid,
                                                },
                                              }
                                            );
                                            setActiveTab("Configuration");
                                          } catch (error) {
                                            console.error(
                                              "Error creating connection:",
                                              error
                                            );
                                          }
                                        }}
                                      >
                                        <div className="form-group">
                                          <label htmlFor="integrationName">
                                            Enter Integration Name{" "}
                                            <span className="text-danger">*</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="integrationName"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="integrationName"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="userEmail">
                                            Shiprocket API User Email{" "}
                                            <span className="text-danger">*</span>
                                            <br />
                                            <a
                                              href="https://app.shiprocket.in/login?routestate=api-user"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Where to get{" "}
                                              <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                focusable="false"
                                                className="chakra-icon css-13otjrl"
                                                aria-hidden="true"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                                <polyline points="15 3 21 3 21 9"></polyline>
                                                <line
                                                  x1="10"
                                                  y1="14"
                                                  x2="21"
                                                  y2="3"
                                                ></line>
                                              </svg>
                                            </a>
                                          </label>
                                          <Field
                                            type="email"
                                            name="userEmail"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="userEmail"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="userPassword">
                                            Shiprocket API User Password{" "}
                                            <span className="text-danger">*</span>
                                          </label>
                                          <div className="password-field">
                                            <Field
                                              type={
                                                passwordVisible
                                                  ? "text"
                                                  : "password"
                                              }
                                              name="userPassword"
                                              className="form-control"
                                            />
                                            <span
                                              className="password-toggle"
                                              onClick={togglePasswordVisibility}
                                            >
                                              {passwordVisible ? (
                                                <svg
                                                  stroke="currentColor"
                                                  fill="none"
                                                  strokeWidth="2"
                                                  viewBox="0 0 24 24"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  height="1em"
                                                  width="1em"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                  <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="3"
                                                  ></circle>
                                                </svg>
                                              ) : (
                                                <AiOutlineEyeInvisible />
                                              )}
                                            </span>
                                          </div>
                                          <ErrorMessage
                                            name="userPassword"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>

                                        <div className="d-flex justify-content-end mt-3">
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              resetForm();
                                              togglePopover();
                                            }}
                                            className="cancelButton w-100 p-1 me-2"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            type="submit"
                                            className="sendButton p-1 w-100"
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </>
                              ) : name === "zohoBooks" ? (
                                <Formik
                                  initialValues={{
                                    connectionName: zohoBooksConnectionName || "",
                                  }}
                                  validationSchema={zohoValidationSchema}
                                  onSubmit={(values, formikHelpers) => {
                                    formikHelpers.setSubmitting(false);
                                  }}
                                // onSubmit={handleZohoBookConnect}
                                >
                                  {({ isSubmitting, values }) => (
                                    <Form
                                      onSubmit={async (e) => {
                                        e.preventDefault();

                                        const payload = {
                                          app: "ZohoBooks",
                                          connectionName: values.connectionName,
                                          businessUid: businessUid,
                                          scope: "COM",
                                          type: "webhook",
                                        };

                                        setZohoBooksConnectionName(
                                          values.connectionName
                                        );
                                        localStorage.setItem(
                                          "connectionName",
                                          values.connectionName
                                        );

                                        const url = window.open(
                                          `${SERVER_URL}/integration/connect?app=${payload.app}&connectionName=${payload.connectionName}&businessUid=${payload.businessUid}&scope=${payload.scope}&type=${payload.type}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <div className="form-group">
                                        <label htmlFor="connectionName">
                                          Enter Connection Name{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="connectionName"
                                          className="form-control"
                                        />
                                        <ErrorMessage
                                          name="connectionName"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className="d-flex justify-content-end mt-3">
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            resetForm();
                                            togglePopover();
                                          }}
                                          className="cancelButton w-100 p-1 me-2"
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          type="submit"
                                          className="sendButton p-1 w-100"
                                          disabled={isSubmitting}
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              ) : name === "wooCommerceCart" ? (
                                <>
                                  <Formik
                                    initialValues={cartFlowInitialValues}
                                    validationSchema={
                                      wooCommerceCartValidationSchema
                                    }
                                    onSubmit={(values, formikHelpers) => {
                                      formikHelpers.setSubmitting(false);
                                    }}
                                  // onSubmit={handleCartFlowConnect}
                                  >
                                    {({
                                      isSubmitting,
                                      setFieldValue,
                                      values,
                                    }) => (
                                      <Form
                                        onSubmit={async (e) => {
                                          e.preventDefault();

                                          const payload = {
                                            integrationTypes: "wooCommerceCart",
                                            name: values.brandName,
                                            wooCommerce: {
                                              wooCommerceUrl: isNewConnection
                                                ? values.newConnectionName
                                                : values.connection,
                                            },
                                          };

                                          try {
                                            const response =
                                              await createConnectionBasedOnTypes(
                                                businessUid,
                                                payload
                                              );

                                            setPopoverOpen(false);
                                            navigate(
                                              `/integrations/${name}/edit/${response.uid}`,
                                              {
                                                state: {
                                                  imgSrc: state?.imgSrc,
                                                  description: state?.description,
                                                  connectionUid:
                                                    response.connection.uid,
                                                },
                                              }
                                            );
                                            setActiveTab("Configuration");
                                          } catch (error) {
                                            console.error(
                                              "Error creating connection:",
                                              error
                                            );
                                          }
                                        }}
                                      >
                                        <div className="form-group">
                                          <label htmlFor="brandName">
                                            Enter Brand Name{" "}
                                            <span className="text-danger">*</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="brandName"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="brandName"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="connection">
                                            Choose Connection{" "}
                                            <span className="text-danger">*</span>
                                          </label>
                                          <Select
                                            options={[
                                              ...connections.map((conn) => ({
                                                label: conn,
                                                value: conn,
                                              })),
                                              {
                                                label: "New Connection",
                                                value: "newConnection",
                                              },
                                            ]}
                                            name="connection"
                                            value={
                                              values.connection
                                                ? {
                                                  label: values.connection,
                                                  value: values.connection,
                                                }
                                                : null
                                            } // Fix here
                                            onChange={(option: any) => {
                                              setFieldValue(
                                                "connection",
                                                option.value
                                              );
                                              setIsNewConnection(
                                                option.value === "newConnection"
                                              );
                                            }}
                                            className="channel-select"
                                          />

                                          <ErrorMessage
                                            name="connection"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>

                                        {isNewConnection && (
                                          <div className="form-group">
                                            <label htmlFor="newConnectionName">
                                              Enter New Connection Name{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="newConnectionName"
                                              className="form-control"
                                            />
                                            <ErrorMessage
                                              name="newConnectionName"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div>
                                        )}
                                        <div className="d-flex justify-content-end mt-3">
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              resetForm();
                                              togglePopover();
                                            }}
                                            className="cancelButton w-100 me-2 p-1"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            type="submit"
                                            className="sendButton p-1 w-100"
                                            disabled={isSubmitting}
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </>
                              ) : name === "stripe" ? (
                                <>
                                  <Formik
                                    initialValues={stripeInitialValues}
                                    validationSchema={stripeValidationSchema}
                                    onSubmit={(values, formikHelpers) => {
                                      formikHelpers.setSubmitting(false);
                                    }}
                                  // onSubmit={handleCartFlowConnect}
                                  >
                                    {({
                                      isSubmitting,
                                      setFieldValue,
                                      values,
                                    }) => (
                                      <Form
                                        onSubmit={async (e) => {
                                          e.preventDefault();

                                          const payload = {
                                            integrationTypes: "stripe",
                                            name: values.integrationName,
                                            stripe: {
                                              token: values.tokenKey,
                                            },
                                          };

                                          try {
                                            const response =
                                              await createConnectionBasedOnTypes(
                                                businessUid,
                                                payload
                                              );

                                            setPopoverOpen(false);
                                            navigate(
                                              `/integrations/${name}/edit/${response.uid}`,
                                              {
                                                state: {
                                                  imgSrc: state?.imgSrc,
                                                  description: state?.description,
                                                  connectionUid:
                                                    response.connection.uid,
                                                },
                                              }
                                            );
                                            setActiveTab("Configuration");
                                          } catch (error) {
                                            console.error(
                                              "Error creating connection:",
                                              error
                                            );
                                          }
                                        }}
                                      >
                                        <div className="form-group">
                                          <div className="mb-2">
                                            <label htmlFor="integrationName">
                                              Enter Integration Name{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              type="text"
                                              name="integrationName"
                                              className="form-control"
                                            />
                                            <ErrorMessage
                                              name="integrationName"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div>

                                          <label htmlFor="tokenKey">
                                            Enter Stripe Token
                                            <PiInfo
                                              size={12}
                                              color="#718096"
                                              title="Get this from Stripe ApiKey Configuration"
                                            />{" "}
                                            <span className="text-danger">*</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="tokenKey"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="tokenKey"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              resetForm();
                                              togglePopover();
                                            }}
                                            className="cancelButton w-100 me-2 p-1"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            type="submit"
                                            className="sendButton p-1 w-100"
                                            disabled={isSubmitting}
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </>
                              ) : name === "cashFree" ? (
                                <>
                                  <Formik
                                    initialValues={cashFreeInitialValues}
                                    validationSchema={cashFreeValidationSchema}
                                    onSubmit={(values, formikHelpers) => {
                                      formikHelpers.setSubmitting(false);
                                    }}
                                  // onSubmit={handleCartFlowConnect}
                                  >
                                    {({
                                      isSubmitting,
                                      setFieldValue,
                                      values,
                                    }) => (
                                      <Form
                                        onSubmit={async (e) => {
                                          e.preventDefault();

                                          const payload = {
                                            integrationTypes: "cashFree",
                                            name: values.integrationName,
                                          };

                                          try {
                                            const response =
                                              await createConnectionBasedOnTypes(
                                                businessUid,
                                                payload
                                              );

                                            setPopoverOpen(false);
                                            navigate(
                                              `/integrations/${name}/edit/${response.uid}`,
                                              {
                                                state: {
                                                  imgSrc: state?.imgSrc,
                                                  description: state?.description,
                                                  connectionUid:
                                                    response.connection.uid,
                                                },
                                              }
                                            );
                                            setActiveTab("Configuration");
                                          } catch (error) {
                                            console.error(
                                              "Error creating connection:",
                                              error
                                            );
                                          }
                                        }}
                                      >
                                        <div className="form-group">
                                          <label htmlFor="integrationName">
                                            Enter Integration Name{" "}
                                            <span className="text-danger">*</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="integrationName"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="integrationName"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              resetForm();
                                              togglePopover();
                                            }}
                                            className="cancelButton w-100 me-2 p-1"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            type="submit"
                                            className="sendButton p-1 w-100"
                                            disabled={isSubmitting}
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </>
                              ) : name === "genericWebHook" ? (
                                <>
                                  <Formik
                                    initialValues={initialWebHookValues}
                                    enableReinitialize // This will reinitialize the form when initialValues change
                                    onSubmit={(values, formikHelpers) => {
                                      formikHelpers.setSubmitting(false);
                                    }}
                                    validationSchema={webHookValidationSchema}
                                  >
                                    {({ isSubmitting, values }) => (
                                      <Form
                                        onSubmit={async (e) => {
                                          e.preventDefault();
                                          const payload = {
                                            integrationTypes: "genericWebHook",
                                            name: values.webHookName,
                                          };

                                          try {
                                            const response =
                                              await createConnectionBasedOnTypes(
                                                businessUid,
                                                payload
                                              );
                                            // formikHelpers.resetForm();
                                            setPopoverOpen(false);
                                            navigate(
                                              `/integrations/${name}/edit/${response.uid}`,
                                              {
                                                state: {
                                                  imgSrc: state?.imgSrc,
                                                  description: state?.description,
                                                  connectionUid:
                                                    response.connection.uid,
                                                },
                                              }
                                            );
                                            setActiveTab("Configuration");
                                          } catch (error) {
                                            console.error(
                                              "Error creating connection:",
                                              error
                                            );
                                          }
                                        }}
                                      >
                                        <div className="form-group">
                                          <label htmlFor="webHookName">
                                            Enter Webhook Name{" "}
                                            <span className="text-danger">*</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="webHookName"
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name="webHookName"
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>

                                        <div className="d-flex justify-content-end mt-3">
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              resetForm();
                                              togglePopover();
                                            }}
                                            className="cancelButton w-100 p-1 me-2"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            type="submit"
                                            className="sendButton p-1 w-100"
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </>
                              ) : null}
                            </PopoverBody>
                          </Popover>
                        </>
                      ) : name === "shopify" ? (
                        <>
                          <Formik
                            initialValues={initialShopify}
                            enableReinitialize // This will reinitialize the form when initialValues change
                            onSubmit={(values, formikHelpers) => {
                              formikHelpers.setSubmitting(false);
                            }}
                            validationSchema={shopifyValidationSchema}
                          >
                            {({ isSubmitting, values }) => (
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  const shopUrl = `${SERVER_URL}/api/integration/shopify/connection?shop=${values.myShopifyShop}.myshopify.com`;

                                  // Open the shop URL in a new tab
                                  window.location.href = shopUrl;
                                }}
                              >
                                <div>
                                  <div className="d-flex align-items-center mb-2">
                                    <div className="me-2">
                                      <Field
                                        type="text"
                                        placeholder="Enter shop name"
                                        name="myShopifyShop"
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name="myShopifyShop"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                    <div>.myshopify.com</div>
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="submit-btn"
                                      disabled={isSubmitting}
                                      style={{ width: "100%" }}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </>
                      ) : name === "webEngage" ? (
                        <>
                          <Formik
                            initialValues={initialWebEngage}
                            enableReinitialize // This will reinitialize the form when initialValues change
                            onSubmit={(values, formikHelpers) => {
                              formikHelpers.setSubmitting(false);
                            }}
                            validationSchema={webEngageValidationSchema}
                          >
                            {({ isSubmitting, values }) => (
                              <Form
                                onSubmit={async (e) => {
                                  e.preventDefault();
                                  const payload = {
                                    name: values.webEngage,
                                    integrationTypes: "webEngage",
                                    webEngage: {
                                      channelUid: channelData.value,
                                    },
                                  };
                                  const response = await webEngageIntegration(
                                    businessUid,
                                    payload
                                  );
                                  if (response) {
                                    const { uid } = response?.data;
                                    const { connection } = response?.data;
                                    toast("success", "Connection  Successfully");
                                    navigate(
                                      `/integrations/${name}/edit/${uid}`,
                                      {
                                        state: {
                                          imgSrc: state?.imgSrc,
                                          description: state?.description,
                                          connectionUid: connection?.uid,
                                        },
                                      }
                                    );
                                    setActiveTab("Configuration");
                                  }
                                }}
                              >
                                <div>
                                  <div className="d-flex align-items-center mb-2">
                                    <div className="me-2">
                                      <Field
                                        type="text"
                                        placeholder="Integration name"
                                        name="webEngage"
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name="webEngage"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="submit-btn"
                                      disabled={isSubmitting}
                                      style={{ width: "100%" }}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </>
                      ) : name === "calendly" ? (
                        <>
                          <Formik
                            initialValues={initConnectionName}
                            validationSchema={zohoValidationSchema}
                            onSubmit={async (
                              newValues,
                              formikHelpers
                            ) => {
                              await calendlyConnect(
                                newValues.connectionName
                              );
                            }}
                            validateOnBlur={true} // Ensure validation on blur
                            validateOnChange={true} // Ensure validation on field change
                          >
                            {({
                              handleChange,
                              values,
                              touched,
                              errors,
                              handleSubmit,
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                {" "}
                                {/* Important: Make sure to use Formik's Form */}
                                <div className="form-group">
                                  <Form className="mt-2">
                                    <label htmlFor="integrationName">
                                      Connection Name{" "}
                                      <span className="required"></span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="connectionName"
                                      placeholder="Connection Name"
                                      value={values.connectionName}
                                      onChange={handleChange}
                                      className={
                                        touched.connectionName &&
                                          errors.connectionName
                                          ? "is-invalid form-control"
                                          : "form-control"
                                      }
                                    />
                                    {/* Show validation error */}
                                    <ErrorMessage
                                      name="connectionName"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Form>
                                </div>
                                <Row className="mt-2">
                                  <Col className="pe-0">
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        resetForm();
                                        togglePopover();
                                      }}
                                      className="cancelButton w-100 p-1"
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Button
                                      type="submit"
                                      className="sendButton p-1 w-100"
                                    >
                                      Submit
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            )}
                          </Formik>
                        </>
                      ) : null}
                  </>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </div>

      <div className="integration-body">
        <div className="integration-tabs">
          {displayTabs.map((tab: any) => {
            if (tab === "Workflow" || tab === "Logs") {
              return (
                <div
                  key={tab}
                  className={`tab-${activeTab === tab ? "active" : ""}`}
                  onClick={(e: any) => {
                    if (workFlowEnable) {
                      setActiveTab(tab);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  style={{
                    cursor: workFlowEnable ? "pointer" : "not-allowed",
                    opacity: workFlowEnable ? 1 : 0.5,
                  }}
                >
                  {tab}
                </div>
              );
            } else {
              return (
                <div
                  key={tab}
                  className={`tab-${activeTab === tab ? "active" : ""}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </div>
              );
            }
          })}
        </div>

        {createModal && (
          <Modall
            isOpen={createModal}
            onClose={() => setCreateModal(false)}
            title="Integrate Shop"
            size="md"
          >
            <Formik
              initialValues={shopifyInitialValues}
              onSubmit={(values, formikHelpers) => {
                formikHelpers.setSubmitting(false);
              }}
            // onSubmit={handleShopifyCreate}
            >
              {({ setFieldValue, values }) => (
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const payload = {
                      integrationTypes: "shopify",
                      name: values.shopName,
                      shopify: {
                        shopifyShopUrl: values.shopUrl,
                        publicShopUrl: "",
                      },
                    };

                    try {
                      const response = await createConnectionBasedOnTypes(
                        businessUid,
                        payload
                      );

                      setCreateModal(false);
                      navigate(`/integrations/${name}/edit/${response.uid}`, {
                        state: {
                          imgSrc: storedShopifyIntegration.imgSrc,
                          description: storedShopifyIntegration.description,
                          connectionUid: response.connection.uid,
                        },
                      });
                      setActiveTab("Configuration");
                    } catch (error) {
                      console.error("Error creating connection:", error);
                    }
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="integrationName">Integration Type</label>
                    <Field
                      type="text"
                      name="integrationTypes"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="integrationName">Shop Name</label>
                    <Field
                      type="text"
                      name="shopName"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="integrationName">Shop Url</label>
                    <Field
                      type="text"
                      name="shopUrl"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="submit-btn">
                      Connect
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modall>
        )}

        <div className="integration-content">
          {name === "sprocket" && (
            <Shiprocket
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}

          {name === "zohoBooks" && (
            <ZohoBooks
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              connectionStatus={zohoConnectionStatus}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}

          {name === "shopify" && (
            <Shopify
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
            />
          )}

          {name === "razorPay" && (
            <Razorpay
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}

          {name === "wooCommerce" && (
            <WooCommerce
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}

          {name === "wooCommerceCart" && (
            <CartFlow
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}
          {name === "webEngage" && (
            <WebEngage
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}

          {name === "stripe" && (
            <Stripe
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}

          {name === "cashFree" && (
            <CashFree
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}

          {name === "genericWebHook" && (
            <WebHook
              integrationUid={integrationUid}
              connectionUid={state?.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state?.isInvalidAccess ?? false}
            />
          )}
          {name === "calendly" && (
            <Calendly
              integrationUid={integrationUid}
              connectionUid={state.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state.isInvalidAccess ?? false}
            />
          )}
          {name === "FacebookLeads" && (
            <FacebookLeads
              integrationUid={integrationUid}
              connectionUid={state.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
              isInvalidAccess={state.isInvalidAccess ?? false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegrationDetails;
