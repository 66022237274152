import React from 'react'

export default function NotFoundPage() {
  return (
    <div className='pageNotFoundContainer'>
      <h1>404</h1>
      <div className=''>Page Not Found</div>
    </div>
  )
}
