import axios from '../utils/axios'
import { ITemplateComponent } from '../botFlows/components/designFlow/editor/sendTemplate'

export const getMemberCalender = async (memberUid: string) => {
    try {
        const response = await axios.get(`caly-v2/${memberUid}/getMemberConfigration`);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getAllMemberConfiguration=async (businessUid:string)=>{
    try {
      const response = await axios.get(
        `caly-v2/${businessUid}/getAllMemberConfigration`
      );
      const data = response.data;

      const sortedData = data?.sort((a: { id: number }, b: { id: number }) => b.id - a.id);
      return sortedData;
    } catch (error) {
      console.error(error);
      throw error;
    }
}

export const getTemplateMessage = async (configId: number, templateType: string) => {

    try {
        const response = await axios.get(`caly-v2/${configId}/${templateType}/getTemplateMessage`);
        const data = response.data;
        return data;
    }
    catch (error) {
        console.error(error);
        throw error;
    }

}

interface ITemplate {
    id: string;
    name: string;
    language: string;
    category: string;
    templateStatus: 'APPROVED' | 'DRAFT' | 'REJECTED';
    components: ITemplateComponent[];
    media: null | {
        id: number;
        fileName: string;
        fileSize: number;
        mediaType: string;
        url: string;
    };
};

export type IMemberCalendar = {
    id: number;
    enabled: boolean;
    unavailableDate: string[];
    dateAvailabilityPreset: string;
    availableHour: {
        startTime: string;
        endTime: string;
    }[];
    duration: number;
    createdAt: string;
    dateRange: {
        startDate: string;
        endDate: string;
    };
    defaultSettings: {
        enableContactReschedule: boolean;
    };
    startTemplateId: string;
    templateStatus: boolean;
    template: ITemplate | null;
    templateNodes: null | { [key: string]: string };
    media: any;
    remainingTemplateId: string;
    endTemplateId: string;
    templateMessage: any;
    deleteTemplateId: string;
};

export type GetTimeSlotInterface = {
    date: string;
    memberUids: string[];
  };

export const createMemberCalender = async (memberId: number, config: any) => {
    try {
        const response = await axios.post<IMemberCalendar>('caly-v2/createMemberConfigration', {
            ...config,
            enabled: true,
            member: { id: memberId }
        });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const getTimeSlots = async (data: GetTimeSlotInterface) => {
    try {
      const response = await axios.post('caly-v2/getTimeSlots', data);
      
      return response.data;
    } catch (error) {
      console.error('Error fetching time slots', error);
      throw error;
    }
  };

export const updateMemberCalender = async (memberUid: string, config: any) => {
    try {
        const response = await axios.put<IMemberCalendar>(`caly-v2/${memberUid}/updateMemberConfigration`, config);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const deleteMemberCalender = async (memberUid: string) => {
    try {
        const response = await axios.delete(`caly-v2/${memberUid}/deleteMemberConfigration`);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


export interface IAppointment {
    id: number,
    uid: string,
    name: string,
    description: string,
    date: string,
    status: 'PENDING' | 'COMPLETED' | 'CANCELLED',
    contact: {
        id: number,
        uid: string,
        name: string
    },
    settings: any,
    scheduledAt: {
        date: string,
        startTime: string,
        endTime: string
    }
    emergencyMember:boolean
}

export const getAppointment = async (memberUid: string) => {
    try {
        const response = await axios.get<IAppointment[]>(`caly-v2/${memberUid}/getCalyAppointmentsBasedMember`);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const getAppointmentV2 = async (payload: any) => {
    try {
        const response = await axios.post(`caly-v2/getCalyAppointmentsBasedMemberV2`,payload);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const createAppointment = async (appointmentData: any, channelUid: any) => {
    try {
        const response = await axios.post(`caly-v2/createCalyAppointments/${channelUid}`, appointmentData);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const updateAppointment = async (appointmentData: any, channelUid: any) => {
    try {
        const response = await axios.put(`caly-v2/${appointmentData.uid}/updateCalyAppointment/${channelUid}`, appointmentData);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const deleteAppointment = async (uid: string, channelUid: string) => {
    try {
        const response = await axios.delete(`caly-v2/${uid}/deleteCalyAppointment/${channelUid}`);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const rescheduleAppointment = async (body: any, channelUid: string) => {
    try {
        const response = await axios.post(`caly-v2/rescheduleCalyAppointment/${channelUid}`, body);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export type IHolidayList = {
    id: number,
    name: string,
    description: string,
    creator: string,
    listItem: { id: number, name: string, date: string }[]
}

export const getSingleHolidayList = async (payload: any) => {
    try {
        const response = await axios.post<IHolidayList>('caly-v2/holidayList/getSingleList', payload);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getAllHolidayList = async (businessId: number, withPreview: boolean = false) => {
    try {
        const response = await axios.post<IHolidayList[]>('caly-v2/holidayList/getList', {
            businessId,
            withPreview
        });
        const data = response.data.sort((a, b) => b.id - a.id);
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getAllHolidayListV2 = async(payload:any) => {
    try {
        const response = await axios.post('caly-v2/holidayList/getListV2', payload);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const createHolidayList = async (listdata: any) => {
    try {
        const response = await axios.post<IHolidayList>('caly-v2/holidayList/createList', listdata);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


export const updateHolidayList = async (listdata: any) => {
    try {
        const response = await axios.patch<IHolidayList>('caly-v2/holidayList/updateList', listdata);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const deleteHolidayList = async (listId: number) => {
    try {
        const response = await axios.delete<string>('caly-v2/holidayList/' + listId + '/deleteList');
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export interface IAppointmentAnalytics {
    appointmentCount: number,
    totalPendingAppointments: number,
    totalCompletedAppointments: number,
    totalCancelledAppointments: number
}

export const getAppointmentAnalytics = async (memberId: string, startDate: string, endDate: string) => {
    try {
        const response = await axios.post<IAppointmentAnalytics>('caly-v2/' + memberId + '/getAppointmentAnalytics?start=' + startDate + '&end=' + endDate);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getAppointmentAnalyticsBasedonBusiness = async (businessUid: string, startDate: string, endDate: string,type:string) => {
    try {
        const response = await axios.post<IAppointmentAnalytics>('caly-v2/' + businessUid + '/getAppointmentAnalyticsBusiness?start=' + startDate + '&end=' + endDate+'&type='+type);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export const getAppointmentBasedonBusiness = async (businessUid: string, startDate: string, endDate: string) => {
    try {
        const response = await axios.post('caly-v2/' + businessUid + '/getAppointmentbasedonBusiness?start=' + startDate + '&end=' + endDate);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export interface BulkDeletePayload {
    type: string,
    uids: string[],
    channelId: string,
    appointmentStatus?: string
}

export const setBulkDeletionForAppointments = async (data: BulkDeletePayload) => {
    try {
      const response = await axios.post('caly-v2/appointmentBulkUpdate', data);
      return response.data;
    } catch (error) {
      console.error('Error fetching time slots', error);
      throw error;
    }
  }