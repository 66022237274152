import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Modall from '../../common/modall';
import * as Yup from 'yup';
import { getAll, createPay, updatePay, deletePay } from '../../services/paymentService';
import { Label, FormGroup, Button, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import Select from 'react-select';
import "./payment.scss";
import { PaymentHistory } from './paymentHistory';
import { faEllipsisVertical, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipReference from "../../common/tooltip";
import razor from '../../assets/img/newRazor.svg';
import upi from '../../assets/img/newUpi.svg';
import payu from '../../assets/img/newPayU.svg';
import CommonPopover from '../../common/commonPopover';


interface SelectBtnOption {
  value: string;
  label: string;
}

const Payment = () => {
  const [allData, setAllData] = useState([]);
  const [open, openModal] = useState<boolean>(false);
  const [showEdit, setEdit] = useState<boolean>(false);
  const [selectedRow, setRow] = useState<any>();
  const [selectedMethod, setMethod] = useState<any>();
  const [activeTab, setTab] = useState('1');
  const [paymethod, setPayMethod] = useState<any>();
  const [showPop, setShowPop] = useState<number | null>(null);
  const [viewmodal, setmodal] = useState<boolean>(false);
  const [selectedData, setData] = useState<any>();
  const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
  const [loading, setLoading] = useState(true);

  const methods = [
    { value: "razorpay", label: "Razorpay" },
    { value: "payu", label: "PayU" },
    { value: "upi", label: "UPI VPA ID" },
    // {value:"payment_link", label:"payment_link"}
  ];

  
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

  useEffect(() => {  
    if(roleRules) {
      const isInvalidAccess = roleRules.canViewPayments === true && roleRules.canCreateViewAndManagePayments === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);
  const getAllData = async () => {
    try {
      const response = await getAll(channel?.channelAllData?.uid);
      const data = response.data;
      setAllData(data);
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab === "2") {
      getAllData();
    }
  }, [activeTab, channel]);


  const addPayment = (method: string) => {
    setPayMethod(method)
    setEdit(false);
    openModal(true);
  }

  const closeModal = () => {
    openModal(false)
  }
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      height: '120px',
      overflowY: 'scroll',
      zIndex: 9999,
    }),
  };



  const validation = Yup.object({
    name: Yup.string().trim().required('Name is required'),
    // method: Yup.object().required('Method is required'),
  });

  const handleEdit = (row: any) => {
    setShowPop(null);
    setRow(row);
    setEdit(true);
    openModal(true);
  }
  const handleDelete = async (row: any) => {
    setShowPop(null);
    await deletePay(row.uid);
    getAllData();
  }

  const renderEditColumn = (cell: any, rowData: object) => {
    return (
      <div className="ActionField">
        <div onClick={
          () => handleEdit(cell)} className="ml-5">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div onClick={
          () => handleDelete(cell)} className="mr-5">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 4H3.33333H14"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.66602 7.3335V11.3335"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.33398 7.3335V11.3335"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

      </div>
    );
  };

  const handleChange = (selectedOption: { value: string; label: string; } | null) => {
    setMethod(selectedOption);
  };

  const handleSubmit = async (values: any) => {
    const payload = {
      "name": values.name,
      "method": showEdit ? values?.method?.value : paymethod,
    }
    if (!showEdit) {
      await createPay(channel?.channelAllData?.uid, payload);
    } else {
      await updatePay(selectedRow?.uid, payload);
    }
    getAllData();
    openModal(false);
  }

  const getmethod = (value: SelectBtnOption) => {
    let method;
    method = methods.find((obj: any) => obj.value === value.toString());
    return method;
  }

  const dataWithSerialNum = allData.map((item: any, index: any) => ({
    ...item,
    serialNumber: index + 1
  }));


  const openPopover = (index: number) => {
    setShowPop(index);
  }

  const closePopover = () => {
    setShowPop(null);
  }

  const handleView = (data: any) => {
    setShowPop(null);
    setmodal(true);
    setData(data)
  }

  const closeView = () => {
    setmodal(false);
  }


  return (
    <div className="name-tag mb-2 pt-2">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setTab("1")}
          >
            Payment History
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => setTab("2")}
          >
            Payment Configuration
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PaymentHistory activeTab={activeTab} isInvalidAccess={isInvalidAccess} />
        </TabPane>
        <TabPane tabId="2">
          {loading ?
            <div className="centerItemsWithWidth">
              <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div> :
            <div>
              <div className='ml-3 mt-3'>
                <h6>Connected Payment Gateway</h6>
                {dataWithSerialNum.length ?
                  <div className='row mt-4'>
                    {dataWithSerialNum.map((item: any, index: any) => {
                      const popoverTargetId = `sidepop_${index}`;

                      return (
                        <div className='card ml-3 col-2 text-center p-1' key={index}>
                          <FontAwesomeIcon icon={faEllipsisVertical} id={popoverTargetId} onClick={() => openPopover(index)} className="iconsColor optionicon" />
                          <span className=' align-items-center'>
                            <img src={item.method === 'razorpay' ? razor : item.method === 'payu' ? payu : upi} alt='razorpay' width={50} height={50} className='mb-3'></img>
                          </span><h6>{item.method === 'upi' ? "UPI VPA ID" : item.method === "payu" ? "PayU" : "Razorpay"}</h6>
                          <Button className='h6 signUpBtn mb-4'>Connected</Button>
                          <CommonPopover isOpen={showPop === index} target={popoverTargetId} toggle={closePopover} placement='right' component='payment'>
                            <div onClick={() => handleView(item)} className='pl-2 cursor-pointer'>View</div>
                            <hr />
                            <span className={`${isInvalidAccess ? "notAllowed" : "pointer"}`} >
                            <div onClick={() => handleEdit(item)} className={`pl-2 cursor-pointer ${isInvalidAccess ? "disabledState" : ""} `}>Edit</div>
                            </span>
                            <hr />
                            <span className={`${isInvalidAccess ? "notAllowed" : "pointer"}`} >
                            <div onClick={() => handleDelete(item)} className={`pl-2 cursor-pointer ${isInvalidAccess ? "disabledState" : ""} `} >Delete</div>
                            </span>
                          </CommonPopover>
                        </div>
                      );
                    })}
                  </div>
                  : (<div className='errorPayment'>No connected payments are available.</div>)}
                <Modall isOpen={viewmodal} onClose={closeView} title={'Accept Payments directly on WhatsApp'} size="md">
                  <div>
                    <Label className='text-muted'>Payment provider</Label>
                    <p>{selectedData?.method}</p>
                    <Label className='text-muted'>Config</Label>
                    <p>{selectedData?.name}</p>
                  </div>
                </Modall>
              </div>
              <div className='ml-3 mt-3'>
                <h6>Available Payment Gateway</h6>
                <div className='d-flex mt-4'>
                  <div className='card col-2 text-center'>
                    <span className=' align-items-center'> <img src={razor} alt='razorpay' width={50} height={50} className='mt-2 mb-3'></img></span>
                    <p className='mb-1'>Razorpay</p>
                    <h6>WhatsApp Pay </h6>
                    {(channel?.channelAllData?.business?.country_code === "91" || channel?.channelAllData?.business?.country_code === "+91") &&  !allData?.some((item: any) => item?.method === "razorpay")?
                      <span className={`${isInvalidAccess ? "notAllowed" : "pointer" }`} ><Button className='h6 signUpBtn mb-4' onClick={() => addPayment('razorpay')} disabled={isInvalidAccess} >Connect</Button></span>
                      :
                      <TooltipReference
                        placement="right"
                        content={ isInvalidAccess ? "You don't have permission" : (allData?.some((item: any) => item?.method === "razorpay")? "Connection already exists. Please delete it to add a new one." : "Payment Configuration is only available for India")}
                        tooltipId="paymnet"
                      >
                         <span className={`${isInvalidAccess ? "notAllowed" : "pointer" }`} ><Button className='h6 signUpBtn mb-3 col-12' onClick={() => addPayment('razorpay')} disabled={(channel?.channelAllData?.business?.country_code !== "91" || channel?.channelAllData?.business?.country_code !== "+91" ||   allData?.some((item: any) => item?.method === "razorpay") || isInvalidAccess)}>Connect</Button></span>
                      </TooltipReference>
                    }
                  </div>
                  <div className='card ml-3 col-2 text-center'>
                    <span className=' align-items-center'><img src={payu} alt='payu' width={50} height={50} className='mt-2 mb-3'></img></span>
                    <p className='mb-1'>PayU</p>
                    <h6>WhatsApp Pay </h6>
                    {channel?.channelAllData?.business?.country_code === "91" || channel?.channelAllData?.business?.country_code === "+91"  &&  !allData?.some((item: any) => item?.method === "payu")?
                       <span className={`${isInvalidAccess ? "notAllowed" : "pointer" }`} ><Button className='h6 signUpBtn mb-4' onClick={() => addPayment('payu')} disabled={isInvalidAccess} >Connect</Button></span>
                      :
                      <TooltipReference
                        placement="right"
                        content={ isInvalidAccess ? "You don't have permission" : (allData?.some((item: any) => item?.method === "payu")? "Connection already exists. Please delete it to add a new one." :  "Payment Configuration is only available for India")}
                        tooltipId="paymnet"
                      >
                         <span className={`${isInvalidAccess ? "notAllowed" : "pointer" }`} ><Button className='h6 signUpBtn mb-3 col-12' onClick={() => addPayment('payu')} disabled={(channel?.channelAllData?.business?.country_code !== "91" || channel?.channelAllData?.business?.country_code !== "+91" ||  allData?.some((item: any) => item?.method === "payu") || isInvalidAccess)}>Connect</Button></span>
                      </TooltipReference>
                    }
                  </div>
                  <div className='card ml-3 col-2 text-center'>
                    <span className=' align-items-center'> <img src={upi} alt='upi' width={50} height={50} className=' mt-2 mb-3'></img></span>
                    <p className='mb-1'>UPI VPA ID</p>
                    <h6>WhatsApp Pay </h6>
                    {channel?.channelAllData?.business?.country_code === "91" || channel?.channelAllData?.business?.country_code === "+91" &&  !allData?.some((item: any) => item?.method === "upi")?
                       <span className={`${isInvalidAccess ? "notAllowed" : "pointer" }`} ><Button className='h6 signUpBtn mb-4' onClick={() => addPayment('upi')} disabled={isInvalidAccess} >Connect</Button></span>
                      :
                      <TooltipReference
                        placement="right"
                        content={ isInvalidAccess ? "You don't have permission" : (allData?.some((item: any) => item?.method === "upi")? "Connection already exists. Please delete it to add a new one." : "Payment Configuration is only available for India")}
                        tooltipId="paymnet"
                      >
                         <span className={`${isInvalidAccess ? "notAllowed" : "pointer" }`} ><Button className='h6 signUpBtn mb-3 col-12' onClick={() => addPayment('upi')} disabled={(channel?.channelAllData?.business?.country_code !== "91" || channel?.channelAllData?.business?.country_code !== "+91" ||  allData?.some((item: any) => item?.method === "upi") || isInvalidAccess )}>Connect</Button></span>
                      </TooltipReference>
                    }
                  </div>
                </div>
              </div>
            </div>}
          {/* {channel?.channelAllData?.business?.country_code === "91" || channel?.channelAllData?.business?.country_code === "+91"?
       <Button className='h6 signUpBtn float-right p-2 mt-1' onClick={() => addPayment()}>+ Add Payment</Button>
       :
       <TooltipReference
            placement="right"
            content={"Payment Configuration is only available for India"}
            tooltipId="paymnet"
          >
              <Button className='h6 signUpBtn float-right p-2 mt-1' onClick={() => addPayment()} disabled={(channel?.channelAllData?.business?.country_code !== "91" || channel?.channelAllData?.business?.country_code !== "+91")}>+ Add Payment</Button>
          </TooltipReference> 
     }
      <div className='tagTable'>
        <BootstrapTable data={ dataWithSerialNum } pagination={allData.length > 10 ? true : false} version="4" striped hover
          containerStyle={{ textAlign: 'left' }}
          search
          options={{
            headerAlign: "center",
            noDataText: "No records found",
            sortIndicator: true,
            hidePageListOnlyOnePage: true,
            clearSearch: false,
            alwaysShowAllBtns: true,
            sizePerPageList: [10, 25, 50, 100, { text: "All", value: dataWithSerialNum.length }],
            withFirstAndLast: true,
            sizePerPage: 10,
            paginationPosition: "bottom",
          } as any}>
          <TableHeaderColumn dataField='id' isKey hidden>#</TableHeaderColumn>
          <TableHeaderColumn dataField='serialNumber' dataAlign="center" width="70" dataFormat={(cell,row)=> row.serialNumber + '.'}>
              <b>S.No</b>
            </TableHeaderColumn>
          <TableHeaderColumn dataField='name' columnClassName="text-wrap" dataAlign='center' width="90"><b>Name</b></TableHeaderColumn>
          <TableHeaderColumn dataField='method' dataAlign='center' columnClassName="text-wrap" width="120"><b>Method</b></TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataAlign="center"  width="80"  dataFormat={renderEditColumn}
            >
            <b>action</b>
            </TableHeaderColumn>
        </BootstrapTable>
      </div> */}
          <div>
            <Modall isOpen={open} onClose={closeModal} title={showEdit ? "WhatsApp Pay Configuration (India Only)" : "WhatsApp Pay Configuration (India Only)"} size="md">
              <Formik
                initialValues={{
                  name: showEdit ? selectedRow.name : '',
                  method: showEdit ? getmethod(selectedRow.method) : '',
                }}
                validationSchema={validation}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div>
                      <p className='text-muted'>To accept payments on WhatsApp, set up your payment account <br />in the WhatsApp Business App settings and choose a supported payment provider.</p>
                      <Label className="text-dark">Step 1</Label><br />
                      <div className='mb-3 d-flex'>
                        <p className='pr-1'>Update the payment provider and configuration name.</p><span>
                          <a
                            type="button"
                            className="configureBtn"
                            href={`https://business.facebook.com/wa/manage/india?waba_id=${channel?.channelAllData?.waba?.externalId}`}
                            target="_blank">Configure <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                        </span>
                      </div>
                      <Label className='text-dark'>Step 2</Label>
                      <FormGroup className='pt-1 pb-1'>
                        <Label htmlFor="">Select Payment Provider<span className="required" /></Label>
                        <Field name="method">
                          {({ field, form }: any) => (
                            <Select
                              // options={methods}
                              options={methods.map((option, index) => ({
                                ...option,
                                isDisabled: showEdit ? false : paymethod === 'razorpay' ? index !== 0 : paymethod === 'payu' ? index !== 1 : paymethod === 'upi' ? index !== 2 : false
                              }))}
                              className=''
                              value={paymethod === 'razorpay' ? methods[0] : paymethod === 'payu' ? methods[1] : paymethod === 'upi' ? methods[2] : values.method}
                              onChange={(event) => {
                                setFieldValue('method', event);
                                handleChange(event as any);
                              }}
                              styles={customStyles}
                            />
                          )}</Field>
                        <ErrorMessage name='method' component='div' className="errorMsg"></ErrorMessage>
                      </FormGroup>
                      <Label className='text-dark'>Step 3</Label>
                      <FormGroup>
                        <Label>Payment Configuration Name<span className="required" /></Label>
                        <Field name='name' className='form-control'></Field>
                        <ErrorMessage name='name' component='div' className="errorMsg"></ErrorMessage>
                      </FormGroup>
                      <div className='d-flex justify-content-end mt-3 mb-3'>
                        <Button type='button' onClick={closeModal} className='cancelButton w-25 p-1 me-2'>Cancel</Button>
                        <Button type='submit' className='sendButton w-25 p-1' disabled={isInvalidAccess} >Save</Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modall>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Payment;
