import React, { useState, useRef } from 'react';
import { Form, FormGroup, Col, Row, FormControl } from 'react-bootstrap';
import { useFormik } from 'formik';
import FlagInput from "../common/flagInput";
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import './WhatsappLink.scss';
import TemplatePreview from '../components/Template/templatePreview';
import Modal from '../whatsapp/whatsappModal';

interface FormValues {
  mobileNo: string;
  message: string;
  modal: boolean;
}

const WhatsappLink = () => {
  const [code, setCode] = useState<string>('91');
  const [modalOpen, setModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(true);
  // let timeoutId: NodeJS.Timeout | null = null;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const formik = useFormik({
    initialValues: {
      mobileNo: '',
      message: '',
    },

    validationSchema: Yup.object({
      mobileNo: Yup.string()
        .required('Mobile number is required').matches(/^\d+$/, 'Mobile number must contain only numbers')
        .max(10, 'Mobile number cannot exceed 10 characters')
        .min(10, 'Mobile number must be at least 10 numbers'),
      message: Yup.string().required('Message is required'),
    }),

    onSubmit: (values: any) => {
      setModalOpen(true);
      console.log(values);
    },
  });

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const codeChange = (e: string) => {
    setCode(e);
  }

  return (
    <Form>
      <Row>
        <Col md="4" className="formdesign p-4">
          <FormGroup className='pt-4'>
            <Form.Label htmlFor="mobileNo">Enter your Whatsapp number</Form.Label>
            <div className="form-group mobile-login-flag pb-0">
              <div className="input-group">
                <div className="input-group-prepend ms-2">
                  <FlagInput countryCode='+91' codeChange={codeChange} />
                </div>
                <FormControl
                  type="text"
                  name="mobileNo"
                  id="mobileNo"
                  value={formik.values.mobileNo}
                  className="form-control"
                  placeholder="Enter your whatsapp number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replace(/[^\d]/g, '');
                    formik.setFieldValue('mobileNo', value);
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.mobileNo && formik.errors.mobileNo && (
                <div className="text-danger pt-0 error">
                  {formik.errors.mobileNo as any}
                </div>
              )}</div>
          </FormGroup>
          <FormGroup className="mt-4">
            <Form.Label htmlFor="message">Add your custom message</Form.Label>
            {/* <div>
      <span role="img" aria-label="Smiling Face">&#x1F60A;</span>
    </div> */}
            {/* <div>
      <Picker onSelect={handleEmojiSelect} />
      {selectedEmoji && (
        <div>
          <span>{selectedEmoji}</span>
        </div>
      )}
    </div> */}
            <FormControl
              as="textarea"
              name="message"
              id="message"
              value={formik.values.message}
              className="form-control"
              placeholder="Enter your message"
              onChange={(e) => {
                formik.handleChange(e);
                setIsHovered(true);
                // Clear the existing timeout
                if (timeoutRef.current !== null) {
                  clearTimeout(timeoutRef.current);
                }
                // Set a new timeout to change isHovered to false after 2 seconds
                timeoutRef.current = setTimeout(() => {
                  setIsHovered(false);
                }, 2000);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.message && formik.errors.message && (
              <div className="text-danger pt-1">{formik.errors.message as any}</div>
            )}
          </FormGroup>
          <Link to={`https://wa.me/${formik.values.mobileNo}?text=${encodeURIComponent(formik.values.message)}`} className="btn btn-primary signUpBtn mt-4 col-12" target="_blank"> Preview</Link>
          <div className="text-dark h6 text-center mt-4">Or</div>
          <button type="submit" className="btn btn-primary mt-4 signUpBtn col-12" onClick={formik.handleSubmit as any}>
            Submit
          </button>
        </Col>
        <Col md="6">
          <TemplatePreview values={formik.values} files={isHovered} component="whatsapp" type='' carouselData='' allVariables=''></TemplatePreview>
        </Col>
      </Row>
      {modalOpen ? <Modal showModal={modalOpen} handleClose={handleModalClose} link={`https://wa.me/${formik.values.mobileNo}?text=${encodeURIComponent(formik.values.message)}`}></Modal> : ''}
    </Form>
  )
}
export default WhatsappLink;
