import axios from '../utils/axios';
import { showAlert, toast } from '../common/alert';

export const getConversations = (payload: { channelId: string, page: number, limit: number }): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post('conversation/getConversationsBasedOnChannel', payload).then(res => {
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response.data.message);
        })
    });
};


export const getConversationsWithFilters = (payload: any): Promise<any> => {
    return axios.post('conversation/getConversationFilter', payload);
};

export const getConversationsBasedOnSearch = (payload: { channelId: string, type: string, search: string }): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post('conversation/searchConversations', payload).then(res => {
            resolve(res);
        }).catch((err: any) => {
            reject(err.response.data.message);
        })
    });
};

export const getAllContacts = (payload: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post('contact/getAll', payload).then(res => {
            resolve(res)
        }).catch(err => {
            toast('error', err.response.data.message);
        })
    })
}

export const getConvBasedOnContact = (payload: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(`conversation/${payload}/getConversation`).then(res => {
            resolve(res.data);
        }).catch(err => {
            toast('error', err.response.data.message);
        })
    })
}

export const assignConversation = (payload: { conversationUid: string, id: string, assignTo: number }): Promise<string> => {
    return new Promise((resolve, reject) => {
        axios.post(`conversation/${payload.conversationUid}/updateAssign?id=${payload.id}&assignTo=${payload.assignTo}`).
            then(res => {
                resolve(res.data);
            }).catch(err => {
                toast('error', err.response.data.message);
            })
    })
}

export const assignStatus = (payload: { conversationUid: string, status: string }): Promise<string> => {
    return new Promise((resolve, reject) => {
        axios.post(`conversation/${payload.conversationUid}/updateStatus?status=${payload.status}`).then(res => {
            resolve(res.data);
        }).catch(err => {
            toast('error', err.response.data.message);
        })
    })
}

export const contactCreateOnConv = (payload: any, component: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        axios.post('contact/contactCreateOnConversation', payload).then(res => {
            resolve(res.data);
            if (component !== 'convFromContact')
                toast('success', 'Contact created successfully');
        }).catch(err => {
            toast('error', err.response.data.message);
        })
    })
}

export const sendConvImage = (payload: any): Promise<string> => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();

        formData.append('uid', payload.uid);
        formData.append('caption', payload.caption);
        formData.append('content', payload.fileData.image);
        axios.post(`media/saveMessageMedia`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            toast('error', err.response.data.message);
        })
    })
}

export const getSingleMemberData = (memberId: number): Promise<string> => {
    return axios.get(`member/${memberId}`)
        .then(res => {
            return res.data.name;
        })
        .catch(err => {
            return ''; // handle error accordingly
        });
}

export const updatePinState = (conversationUid: string, pin: boolean) => {
    return axios.post("conversation/pinConversation", {
        conversationUid,
        pin
    });
}

export const updateTagOnConversation = (conversationUid: string, tags: any[]) => {
    return axios.post("conversation/updateTagOnConversation", {
        conversationUid,
        tags
    })
}

export const bulkUpdateAssign = (
    businessUid: string,
    channelUid: string,
    conversationUids: string[],
    assignId: number,
    assignTo: number
) => {
    return axios.post("conversation/updateBulkConversations", {
        businessUid,
        channelUid,
        conversationUids,
        assignId,
        assignTo
    })
}

export const bulkUpdateStatus = (
    businessUid: string,
    channelUid: string,
    conversationUids: string[],
    status: string
) => {
    return axios.post("conversation/updateBulkConversations", {
        businessUid,
        channelUid,
        conversationUids,
        status
    });
}

export const getConversationsCount = async (channelUid: string) => {
    try {
        const res = await axios.get(`conversation/conversationCounts/${channelUid}`);
        return res.data;
    }
    catch (err) {
        return err;
    }
}

export const getAllBotFlows = (payload: any) => {
    try {
        const response = axios.post('bot/getAllBotFlows', payload);
        return response;
    }
    catch (err) {
        // return err;
    }

}

export const triggerBotByConversation = (payload: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post('bot/triggerBotByConversation', payload).then(res => {
            resolve(res)
        }).catch(err => {
            toast('error', 'Bot trigger failed');
        })
    })
}


