import { Reducer } from 'react';

interface TableState {
    data: any[];
    loading: boolean;
    error: string | null;
    tableData:any;
    selectedUids: any;
}

enum TableActionTypes {
    TABLE_DATA = 'TABLE_DATA',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_ERROR = 'FETCH_ERROR',
    TABLE_RESET = 'TABLE_RESET',
    SELECTED_DATA = 'SELECTED_DATA',
    DESELECT_ROW='DESELECT_ROW',
    RESET_SELECTED_DATA = 'RESET_SELECTED_DATA',
}

interface FetchDataAction {
    type: TableActionTypes.TABLE_DATA;
    payload: any[];
}

interface FetchSuccessAction {
    type: TableActionTypes.FETCH_SUCCESS;
    payload: any[];
}

interface FetchErrorAction {
    type: TableActionTypes.FETCH_ERROR;
    payload: string;
}
interface TableResetAction {
    type: TableActionTypes.TABLE_RESET;
}

interface TableSelectedData {
    type: TableActionTypes.SELECTED_DATA;
}

interface TableDeselectRow {
    type: TableActionTypes.DESELECT_ROW;
}

interface TableResetSelectedData {
    type: TableActionTypes.RESET_SELECTED_DATA;
}

type TableAction = FetchDataAction | TableResetAction | FetchSuccessAction | FetchErrorAction | TableSelectedData | TableDeselectRow;

const initialState: TableState = {
    data: [],
    loading: false,
    error: null,
    tableData: [],
    selectedUids:[],
};

const tableReducer: Reducer<TableState, TableAction> = (state = initialState, action:any) => {
    switch (action.type) {
        case TableActionTypes.TABLE_DATA:
            const { tableName, data, currentPage } = action.payload;
            return {
              ...state,
              tableData: {
                ...state.tableData,
                [tableName]: {
                  ...(state.tableData[tableName] || {}),
                  [currentPage]: data,
                },
              },
            };
           case TableActionTypes.SELECTED_DATA:
            const { tableName: newTableName, selectedUids, currentPage: pages } = action.payload;
            const existingUids = state.selectedUids[newTableName]?.[pages] || [];
            const uniqueUids = [...new Set([...existingUids, ...selectedUids])];
            return {
                ...state,
                selectedUids: {
                    ...state.selectedUids,
                    [newTableName+pages]: uniqueUids,
                },
            };
            case TableActionTypes.RESET_SELECTED_DATA:
                const { tableName: resetTableName, currentPage: resetPage } = action.payload;
                const resetKey = `${resetTableName}${resetPage}`;
                return {
                  ...state,
                  selectedUids: {
                    ...state.selectedUids,
                    [resetKey]: [],
                  },
                };
          
            case TableActionTypes.DESELECT_ROW:
                    const { tableName: newTableName2, uid } = action.payload;
                    const updatedSelectedIds = new Set(state.selectedUids[newTableName2] || []);
                    updatedSelectedIds.delete(uid);
                    return {
                        ...state,
                        selectedUids: {
                            ...state.selectedUids,
                            [newTableName2]: Array.from(updatedSelectedIds)
                        },
                    };
        case TableActionTypes.TABLE_RESET:
                return initialState;
        case TableActionTypes.FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default tableReducer;