import { useFormikContext } from 'formik';
import React from 'react';
import { MdTitle } from 'react-icons/md';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { createVariable } from '../../../../store/graphSlice';
import { isError } from '@tanstack/react-query';
import { Label } from 'reactstrap';
import { Form } from 'react-bootstrap';



interface SelectVariableProps {
    name: string;
    value: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    type: 'TEXT';
    label: string | null;
    header?: string;
    required?: boolean;
    selectedValues?: any;
}

interface CreateVariableForm {
    name: string;
    label: string | null;
    format: 'TEXT';
}


export const CreateSelectVariable: React.FC<SelectVariableProps> = props => {


    const formik = useFormikContext();
    const variables = useAppSelector((state) => state.graph.variables);
    const dispatch = useAppDispatch();

    const variableNameSchema = yup.string().required("Variable name is required")

    const schema = yup.object().shape({
        name: variableNameSchema
    })

    const createVariableHandler = async (data: CreateVariableForm) => {
        const variable = {
            name: data.name,
            label: data.label,
            type: data.format
        };
        try {
            await variableNameSchema.validate(data.name);
            dispatch(createVariable(variable));
            formik.setFieldValue(props.name, variable);
            props.onChange && props.onChange(data.name);
        } catch (validationError) {
            console.log("validationError", validationError);
        }
    }
    return (

        <>
            <Form.Group  className={props.header ? 'mt-2' : ''}>
                { props?.header && (<Label>{props.header} {props?.required && <span className="required"></span>}</Label>)}
                <CreatableSelect
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 1100 }),
                        menu: base => ({ ...base, width: 370, zIndex: 100, position: "relative" }),
                    }}

                    placeholder={props.placeholder}
                    value={props.value ? { value: props.value, label: props.value } : ''}
                    onChange={({ value }: any) => {
                        const variable = {
                            name: value,
                            label: props.label,
                            type: props.type
                        }
                        formik.setFieldValue(props.name, variable);
                        if (props.onChange) {
                            props.onChange(value);
                        }
                    }}
                    formatOptionLabel={({ value }: any) => (
                        <>
                            <MdTitle />
                            <span className="ml-2">{value}</span>
                        </>
                    )}
                    options={
                        variables
                            .filter((variable: any) => {
                                if (!props.type) return true;
                                return variable.type === props.type;
                            })
                            .map((variable: any) => {
                                return { value: variable.name, label: variable.name };
                            })
                            .filter((option: any) => {
                                return !props?.selectedValues?.some(
                                    (selectedValue: any) => selectedValue.name === option.label
                                )
                            })
                    }
                    onCreateOption={(varName) => {
                        createVariableHandler({ name: varName, format: props.type, label: props.label });
                    }}
                />
            </Form.Group>
        </>

    )


}


