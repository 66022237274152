import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../utils/axios';
import { useSelector } from 'react-redux';
import './ChatGpt.scss';
import { toast, toastAlert } from '../../common/alert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { planCodes } from '../../common/commonfns';
import BizTable from '../../common/BizTable';
interface props {

}

const ChatGpt = (props: props) => {
  const [file, setFile] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const profile = useSelector((state: any) => state.cartreducer.business);
  const [getAll, getGptData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  // const getAllChatGpt = async () => {
  //   const chatGptPayload = {
  //     businessUid: profile.business.uid,
  //     page: 0,
  //     limit: 1000,
  //   }
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post('openai/getAll', chatGptPayload);
  //     const data = response?.data
  //     getGptData(data);
  //     setIsLoading(false);
  //   }
  //   catch (error: any) {
  //     toast('error', error.message);
  //     setIsLoading(false);
  //   }


  // }

  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    const chatGptPayload = {
      businessUid: profile.business.uid,
      page: 0,
      limit: 1000,
    }
    setIsLoading(true);
      const response = await axios.post('openai/getAll', chatGptPayload);
      const data = response?.data
      if(data){
        getGptData(data);
        setIsLoading(false);
        return {data:data,totalPages:100}
      }
      else{
        return {data:[],totalPages:0}
      }
    // catch (error: any) {
    //   toast('error', error.message);
    //   setIsLoading(false);
    // }

    // if (response) {
    //   const { digitalItem,count } = response;
    //   const countData = searchTerm ? digitalItem?.length : count;
    //   setCount(countData)
    //   setAlldata(digitalItem);
    //   setIsLoading(false);
   
    //   return {data:digitalItem,totalPages:100}
    // }else {
    //   return {data:[],totalPages:0}
    // }
   
  }, [profile.business.uid]);
 
  // useEffect(() => {
  //   getAllChatGpt()
  // }, [])

  const handleFileChange = (values: any) => {
    if (values) {
      // setFile(values[0]?.name);
      const allowedFormats = ['.pdf', '.doc', '.docx'];
      const fileExtension = values[0].name.substring(values[0].name.lastIndexOf('.'));
      if (!allowedFormats.includes(fileExtension)) {
        setError('Invalid file format. Please upload a .pdf, .doc, or .docx file.');
        setFile(null);
      } else {
        setError(null);

        const updatedFiles: { imgUrl: string, imgName: string, image: File, type: string, size: number }[] = [];
        const newFile = values[0];
        const imgUrl = URL.createObjectURL(newFile);

        updatedFiles.push({ imgUrl: imgUrl, imgName: newFile.name, image: newFile, type: newFile.type, size: newFile.size });
        values.file = updatedFiles[0];
        const formData = new FormData();
        formData.append('content', values.file.image);
        formData.append('businessUid', profile.business.uid)
        const apiUrl = `media/uploadGptDocument`;
        axios.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
        // getAllChatGpt();
        fetchData(0,20,'',0)
        setFile(values[0].name);
        toastAlert('top-end', 'File successfully uploaded. Our team will review the document and respond to you in the chat within 24 hours.', 'success')
      })
      .catch(error => {
        toast('error', error.response?.data?.errorDesc);
    });
      }
    }

  };
  const columns = [
    {
      Header: (
        <input
          type="checkbox"
          hidden={true}
          // checked={this.state.allChecked}
          // onChange={this.toggleAllCheckboxes}
        />
      ),
      accessor: "checkbox",
      // Cell: this.checkboxCellRenderer,
      sticky: "left",
    },
      {
        Header: "Name",
        accessor: "name",
        sticky: "left",
        // Cell: ({ cell, row }: any) =>console.log( row.index * currentPage,row.index,currentPage),
      },
      {
        Header: "Status",
        accessor: "status",
        // Cell: ({ cell, row }: any) => this.mobileData(cell.value, row.original),
      },
      {
        Header: "System Content",
        accessor: "systemContant",
  
      },
      {
        Header: "Data Set",
        accessor: "datasetUsed",
      },
      
    ] as any;
  
  return (
    <div>
      <h5 className='mt-4'>ChatGpt Customization</h5>
      <div className='d-flex'>
        <span className='mt-4 text-muted medium mr-3'>Accepted file types: .doc, .docx, .Pdf.</span>
        {(getAll && getAll.length && ((!profile?.business?.plan?.code && !profile?.bsiness?.freeTrial) || profile?.business?.freeTrial
        || profile?.business?.plan?.code===planCodes('free')) ?
            <label className={`uploadFilesdisable`}> <span>Upload a  file <FontAwesomeIcon icon={faCloudUpload} /></span>
              <input
                type='file'
                name='file'
                className='hideFileInput'
                accept=".pdf,.doc,.docx"
                disabled
                onChange={(event) => {
                  handleFileChange(event.target.files)
                }}
              />
            </label> :
            <label className={`uploadFiles`}> <span>Upload a file <FontAwesomeIcon icon={faCloudUpload} /></span>
              <input
                type='file'
                name='file'
                className='hideFileInput'
                accept=".pdf,.doc,.docx"
                onChange={(event) => {
                  handleFileChange(event.target.files)
                }}
              />
            </label>
          )
        }
      </div>
      {error && <p className='errorMsg'>{error}</p>}
      {file && <div className='fileInfo mt-3'>
        <b>{file}</b>
      </div>}
      {/* {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : ( */}
      <div className='pt-3'>
        {/* <BootstrapTable
          data={getAll}
          search={false}
          // pagination={}
          version="4"
          striped
          hover
          containerStyle={{ textAlign: "left", marginBottom: 80 }}

        >
          <TableHeaderColumn dataField="messageId" isKey hidden>
            #
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="name"
          // dataFormat={date => formatDate(date)}
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
          // dataFormat={date => formatDate(date)}
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="systemContant"
          // dataFormat={date => formatDate(date)}
          >
            System Content
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="datasetUsed"
          // dataFormat={date => formatDate(date)}
          >
            Data Set
          </TableHeaderColumn>

        </BootstrapTable> */}
           <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={0}
              refetchData={getAll}
              tableName='CHATGPT'
            /> 
      </div>
        {/* )} */}
    </div>
  );
};

export default ChatGpt;
