import React from 'react';

const ConnectionLine: React.FC = ({
    fromX,
    fromY,
    toX,
    toY,
}: any) => {

    return (
        <g>
            <path
                fill="none"
                stroke="#b1b1b7"
                strokeWidth={1}
                className="animated"
                d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
            />
           <circle cx={toX} cy={toY} fill="#fff" r={3} stroke="#b1b1b7" strokeWidth={1} />
        </g>
    );
};

export default ConnectionLine;