import * as ExcelJS from "exceljs";
import excelIcon from "../../assets/img/excel.png";
import { saveAs } from "file-saver"; 
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux"; 

const ExportToExcel = async (data:any) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Payment History");

  worksheet.columns = [
    { header: "Contact Name", key: "contactName", width: 20 },
    { header: "Mobile Number", key: "mobileNumber", width: 15 },
    { header: "Created At", key: "createdAt", width: 20 },
    { header: "Amount", key: "totalAmount", width: 10 },
    { header: "Message Status", key: "messageStatus", width: 15 },
    { header: "Payment Status", key: "paymentStatus", width: 15 }
  ];

  data?.forEach((item:any) => {
    const paymentStatusFormatted = 
    item.paymentStatus === 'new_payment' ? 'New' :
    item.paymentStatus === 'payment_initiated' ? 'Payment Initiated' :
    item.paymentStatus === 'order_request' ? 'Order Request' :
    item.paymentStatus ? item.paymentStatus.charAt(0).toUpperCase() + item.paymentStatus.slice(1) : "-";
 
    worksheet.addRow({
      contactName: item.contact?.name || "-",
      mobileNumber: item.contact?.countryCode && item.contact?.mobileNumber
        ? `+${item.contact.countryCode} ${item.contact.mobileNumber}`
        : "-",
      createdAt: moment(item.createdAt).format("MMM YYYY h:mm A"),
      totalAmount: item.totalAmount ? `₹${(item.totalAmount / 100).toFixed(2)}` : "-",
      messageStatus: item.messageStatus || "-",
      paymentStatus: paymentStatusFormatted
    });
  });

  worksheet.getRow(1).font = { bold: true };

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), "Payment_History.xlsx");
};

const DownloadExcelButton = ({ allData }:any) => {
    console.log("alldataaa", allData)
  return (
    <img
      src={excelIcon}
      alt="Download Excel"
      title="Download"
      onClick={() => ExportToExcel(allData)}
      style={{ cursor: "pointer", width: 28, height: 28 }}
    />
  );
};

export default DownloadExcelButton;
