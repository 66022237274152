import React from 'react'
import DragDrap from './dragDrops'

type Props = {}

const ticketFields = (props: Props) => {
  return (
    <div>
      <DragDrap />
    </div>
  )
}

export default ticketFields