import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

import styles from './addElementRow.module.scss';
import classNames from 'classnames';

interface AddElementRowProps {
    items: {
        icon: ReactNode;
        title: string;
        id: string;
    }[];
    varient?: 'primary' | 'secondary';
    onClick: (id: string) => void;
}

const AddElementRow: React.FC<AddElementRowProps> = props => {
    const classList = [styles.grid];
    if (props.varient === 'secondary') {
        classList.push(styles.secondary);
    }


    return (
        <div className={classList.join(' ')}>
            {props.items.map(item => {
                return (
                    <div key={item.id} className={styles.gridItem} onClick={() => props.onClick(item.id)}>
                        <Card className={styles.iconCard}>
                            <div className={styles.icon}>
                                {item.icon}
                            </div>
                        </Card>
                        <div  className={classNames(styles.title, {[styles.text]: item.title === "Text"} ,{ [styles.specialTitle]: item.title === "Send Payments" || item.title === "Create Ticket" })}>
                            {item.title}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

AddElementRow.defaultProps = {
    varient: 'primary'
};

export default AddElementRow;