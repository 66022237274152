import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import BodyInput from './bodyInput';
import EditorCaption from './editorCaption';
import { textIcon } from '../../../icons';

interface FormData {
    message: string;
    attempt: number;
};

function SendTextEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        message: '',
        attempt: 1,
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_text',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        message: yup.string().required('Message Text is required').test('is-double-space', 'Message Text is required', value => value !== '  '),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required')
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Send Text' icon={<img style={{ width: 16 }} alt='' src={textIcon} />} />
                        <Offcanvas.Body>
                            <BodyInput
                                name='message'
                                value={values.message}
                                isInvalid={(touched.message && errors.message) ? true : false}
                                onChange={handleChange}
                                isValid={touched.message && !errors.message}
                                error={errors.message}
                                label={<>Question Text<span className="required"></span></>}
                                placeholder='  Hey, How can you help'
                                limit={4096}
                            />
                            {(touched.message && errors.message) ? (
                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                    {errors.message}
                                </div>
                            ) : null}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SendTextEditor;