import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { signInWithCustomToken } from 'firebase/auth';

var firebaseConfig = {};

if (window.location.hostname.includes("app.bizmagnets.ai")) {
	firebaseConfig = {
		apiKey: "AIzaSyD6GGUsEGUXYrdSQMJz2DARJCTmdGkENBg",
		authDomain: "bizmagnet-132ce.firebaseapp.com",
		projectId: "bizmagnet-132ce",
		storageBucket: "bizmagnet-132ce.appspot.com",
		messagingSenderId: "238921498928",
		appId: "1:238921498928:web:9a567197b7fc60eada08f5"
	};
}
else {
	firebaseConfig = {
		apiKey: "AIzaSyAn4mEafXBvPvlbrqtt-1LyZgkN7NIPcKQ",
		authDomain: "bizmag-25452.firebaseapp.com",
		projectId: "bizmag-25452",
		storageBucket: "bizmag-25452.appspot.com",
		messagingSenderId: "936077167823",
		appId: "1:936077167823:web:da551c39c4e45d62a7bbba",
		measurementId: "G-VTMRHN0V86"
	};
}

// Check if firebase is already initialized to prevent double initialization
var app;
if (!firebase.apps.length) {
	app = firebase.initializeApp(firebaseConfig);
}

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const db = firebase.firestore();
const auth = firebase.auth();
const messaging = getMessaging(app);

export { db, auth, app, getMessaging, getToken, messaging, onMessage, signInWithCustomToken };
