import { createContext } from 'react';

export interface IRepeatCampaignData {
    name: string;
    type: "immediate" | "scheduled",
    replyAssignTo: number,
    replyAssignId: number,
    scheduledAt: number,
    templateId: string,
    communityList: any[],
    templateVariables: string[],
    forceSent: boolean;
    selectedTemplate: any;
};

const campaignContext = createContext<{
    repeatCampaign: null | IRepeatCampaignData,
    setRepeatCampaign: (repeatCampaign: IRepeatCampaignData | null) => void;

}>({
    repeatCampaign: null,
    setRepeatCampaign() { }
});

export default campaignContext;
