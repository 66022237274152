import React, { useState } from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import { IAssignMode } from "./conversation";
import styles from "./bulkAssign.module.scss";

interface BulkAssignProps {
    assignMode: IAssignMode;
    disable?: boolean;
    onAssign: (c: number) => void;
}

const BulkAssign: React.FC<BulkAssignProps> = ({
    assignMode,
    onAssign,
    disable
}) => {
    const [selected, setSelected] = useState(0);
    const members = useSelector((state: any) => state.cartreducer.memberData);

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Assign Member</Tooltip>}
            >
                <Button disable={disable} id="ConvBulkAssignMember">
                    <FontAwesomeIcon className="mr-2" icon={faUserPlus} />
                </Button>

            </OverlayTrigger>
            {!disable ? (
                <UncontrolledPopover
                    placement="bottom"
                    target="ConvBulkAssignMember"
                    trigger="click"
                >
                    <PopoverBody>
                        <ul className={styles.selectList}>
                            {members.map((member: any) => (
                                <li
                                    key={member.uid}
                                    onClick={() => {
                                        setSelected(member.id);
                                        onAssign(member.id);
                                    }}
                                    className={selected === member.id ? styles.selected : undefined}
                                >
                                    {selected === member.id ? "✓ " : null}
                                    {member.name}
                                </li>
                            ))}
                        </ul>
                    </PopoverBody>
                </UncontrolledPopover>
            ) : null}
        </>
    );
};

export default BulkAssign;