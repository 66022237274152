import React, { Component, ErrorInfo, ReactNode, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button } from 'reactstrap';
import { Form, InputGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import styles from '../bots/verifyBot.module.scss';
import * as flowServices from '../../services/flows';
import SelectBot from '../bots/selectBotInput';
import { SOMETHING_WENT_WRONG } from '../../../constants/errorMessage';
import { toast } from '../../../common/alert';

interface CopyFlowProps {
    flow: any;
    queryClient: QueryClient;
    memberUid: string;
    onUpdate: () => void;
};

const CopyFlow: React.FC<CopyFlowProps> = ({ flow, queryClient, memberUid, onUpdate }) => {
    const MySwal = withReactContent(Swal);
    const schema = yup.object().shape({
        botUid: yup.string().required('Bot is required'),
    });
    const [sameBot, setSameBot] = useState(true);

    const copyFlowHandler = async (formData: { botUid: string }) => {
        try {
            await flowServices.copyFlow(formData.botUid, flow.uid, memberUid);
            onUpdate();
            // MySwal.fire({
            //     title: "Flow copied successfully",
            //     icon: "success"
            // });
            toast('success', 'Flow copied successfully');
        } catch (error) {
            const axiosError = error as AxiosError<{ message: string }>;
            // MySwal.fire({
            //     title: "Failed to copy flow",
            //     icon: "error",
            //     text: axiosError.response && 'message' in axiosError.response.data ? axiosError.response.data.message : undefined
            // });
            toast("error", 'Failed to copy flow')
        }
    };

    return (
        <QueryClientProvider client={queryClient}>
            <div className={styles.verifyBot}>
                <div className={styles.header}>
                    <h1>Copy Bot Flow</h1>
                    <span className={styles.botName}>{flow.flowName}</span>
                </div>
                <Formik
                    validationSchema={schema}
                    onSubmit={copyFlowHandler}
                    initialValues={{ botUid: flow.bot.uid }}
                >
                    {({ handleSubmit, values, touched, errors, setFieldValue, setFieldTouched }) => {
                        useEffect(() => {
                            if (sameBot) {
                                setFieldValue("botUid", flow.bot.uid);
                            } else {
                                setFieldValue("botUid", "");
                                setFieldTouched("botUid", false);
                            }
                        }, [sameBot])
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className='mb-3 mt-3'>
                                    <InputGroup className={styles.normalSize}>
                                        <Form.Check
                                            inline
                                            label="Same Bot"
                                            type='radio'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setSameBot(event.target.checked);
                                            }}
                                            checked={sameBot}
                                        />
                                        <Form.Check
                                            inline
                                            label='Different Bot'
                                            type='radio'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setSameBot(!event.target.checked);
                                            }}
                                            checked={!sameBot}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                {!sameBot ? (
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Bot <span className="required"></span></Form.Label>
                                        <InputGroup className={styles.normalSize}>
                                            <SelectBot
                                                value={values.botUid}
                                                setValue={(value: string) => {
                                                    setFieldValue("botUid", value);
                                                }}
                                                isInvalid={(touched.botUid && errors.botUid) ? true : false}
                                                isValid={touched.botUid && !errors.botUid}
                                                error={errors.botUid}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                ) : null}
                                <div className={styles.action}>
                                    <Button className='signUpBtn mb-2 pt-2 pb-2 pl-3 pr-3' type='submit'>Copy</Button>
                                    <Button onClick={() => MySwal.close()} type="button" className={[styles.cancel, "ml-2 mt-1"].join(" ")}>Cancel</Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </QueryClientProvider>
    );
}


class CopyflowErrorBoundary extends Component<{ children: ReactNode }> {
    state = { hasError: false };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <p>{SOMETHING_WENT_WRONG}</p>;
        }

        return <>{this.props.children}</>;
    }
}

export default (props: CopyFlowProps) => (
    <CopyflowErrorBoundary>
        <CopyFlow {...props} />
    </CopyflowErrorBoundary>
);