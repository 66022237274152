import React, { createContext, useContext, useEffect, useState } from 'react';
import { messaging, onMessage } from "../Firebase/firebase";
import { toast } from '../common/alert';
interface UserLoginContextProps {
    justLoggedIn: boolean;
    setJustLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
    requestMessagingToken: () => void;
    //initializeSocket: () => void;
}

// var SOCKET_SERVER_URL: string;
// if (window.location.hostname === "app.bizmagnets.ai") {
//     SOCKET_SERVER_URL = "https://api.bizmagnets.ai/";
// }
// else {
//     SOCKET_SERVER_URL = "https://biz.vamosys.com/";
// }

const UserLoginContext = createContext<UserLoginContextProps | undefined>(undefined);

export const UserLoginProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [justLoggedIn, setJustLoggedIn] = useState<boolean>(false);
    const [notificationCount, setNotificationCount] = useState(0);
    //const business = useSelector((state: any) => state.cartreducer.business);
    const [isSocketConnected, connectSocket] = useState(false);
    const [shouldPlayAudio, setShouldPlayAudio] = useState(false);

    // const socket = io(SOCKET_SERVER_URL, {
    //     reconnection: true,
    //     reconnectionAttempts: Infinity,
    //     reconnectionDelay: 1000,
    //     extraHeaders: {
    //         business_uid: localStorage.getItem('businessId')
    //     }
    // });

    // useEffect(() => {
    //     if (notificationCount) {
    //         document.title = `(${notificationCount + 1}) BizMagnets`;
    //     }
    // }, [notificationCount])
    useEffect(() => {
        if (notificationCount) {
            document.title = `(${notificationCount}) BizMagnets`;
            setTimeout(() => {
                const audio = new Audio("/pushSound.mp3");
                audio.play().catch(error => {
                    console.error('Failed to play audio:', error);
                });
            }, 1000)
        }
    }, [notificationCount]);

    const requestMessagingToken = async () => {
        try {
            // const token = await getToken(messaging,
            //     { vapidKey: 'BKy6AjlZQH2S7UthKTJ6A4Q2S2QMvLY76n0skgzpZgSZIqHf9y8_XElwlXjRMhaM2FRcAmT_5JAutcMBtetymIc' });
            // Notification.requestPermission().then((permission) => {
            //     if (permission === 'granted') {
            //         console.log('Notification permission granted.');
            //     }
            // })
            // const firebaseNotification = await getNotifications(token);
            //   const snapshot = await get(ref(database, 'Leaders'));
            //     if (snapshot.exists()) {
            //       const leadersArray = [];
            //       snapshot.forEach((childSnapshot) => {
            //         leadersArray.push({
            //           id: childSnapshot.key,
            //           ...childSnapshot.val()
            //         });
            //       });
            //     }
            onMessage(messaging, (payload: any) => {
                console.log(payload);
                setNotificationCount(prevCount => prevCount + 1);
            });
        } catch (error) {
            toast('error', 'Error getting FCM token');
        }
    };

    // const initializeSocket = () => {
    //     socket.on("connect", () => {
    //         console.log("Successfully connected to the server!");
    //         socket.io.opts.extraHeaders = {
    //             business_uid: localStorage.getItem('businessId'),
    //         };
    //         initializeConnection();
    //         connectSocket(true);
    //     });
    //     socket.on("connect_error", (error: any) => {
    //         console.error("Connection Error:", error);
    //         connectSocket(false);
    //     });
    // }

    // const initializeConnection = () => {
    //     socket.off("onUserConnect");
    //     socket.emit("userConnect", localStorage.getItem('businessId'));
    //     socket.on("onUserConnect", (data: string) => {
    //         console.log(data);
    //     });
    // }

    const contextValue: UserLoginContextProps = {
        justLoggedIn,
        setJustLoggedIn,
        requestMessagingToken,
        //initializeSocket
    };

    return (
        <UserLoginContext.Provider value={contextValue}>
            {children}
        </UserLoginContext.Provider>
    );
};

export const useUserLoginContext = () => {
    const context = useContext(UserLoginContext);
    if (!context) {
        throw new Error('useUserLoginContext must be used within a UserLoginProvider');
    }
    return context;
};
