// import React from "react";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

// interface ContactDataItem {
//   contactDate: string;
//   contactCount: number;
// }

// const ContactChart: React.FC<{ data: ContactDataItem[] }> = ({ data }) => {
//   const areaColors = ["#eef3f7"];

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     return date.toLocaleString('default', { month: 'short' }) + ' ' + date.getDate().toString().padStart(2, '0');
//   };


//   return (
//     <div style={{ width: "max-content" }}>
//       <AreaChart
//         width={600}
//         height={300}
//         data={data}
//         margin={{
//           top: 20,
//           right: 30,
//           left: 20,
//           bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="contactDate" tickFormatter={formatDate} />
//         <YAxis />
//          <Tooltip labelFormatter={formatDate} formatter={(value) => [value, 'Count']} />
//          {/* <Legend content={<CircleLegend />} /> */}
//         <Area
//           dataKey="contactCount"
//           fill={areaColors[0]}
//           type="monotone"
//           name="Count"
//         />
//       </AreaChart>
//       <small className="d-flex contactContent">Days Vs Count Of New Contacts</small>
//     </div>
//   );
// };

// export default ContactChart;

import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

interface ContactDataItem {
  contactDate: string;
  contactCount: number;
}

const ContactChart: React.FC<{ data: ContactDataItem[] }> = ({ data }) => {
  const areaColors = ["#eef3f7"];

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'short' }) + ' ' + date.getDate().toString().padStart(2, '0');
  };

  const formatYAxis = (tickItem: number) => {
    return tickItem?.toString();
  };

  const getUniqueTicks = () => {
    let maxYValue = Math.max(...data.map((item) => item?.contactCount), 0);
    const minYValue = 0;
    const minValue = 4;

    if (maxYValue < minValue) {
        maxYValue = minValue;
    }
    const roundUpValue = (value: number) => {
        if (value <= 10) return Math.ceil(value / 5) * 5;
        if (value <= 100) return Math.ceil(value / 10) * 10;
        if (value <= 1000) return Math.ceil(value / 50) * 50;
        return Math.ceil(value / 100) * 100;
    };
    maxYValue = roundUpValue(maxYValue);

    // Adjust the step calculation to ensure exactly 5 lines in the y-axis
    const step = Math.ceil(maxYValue / 4);
    const ticks = [];
    for (let i = 0; i <= 4; i++) {
        ticks.push(i * step);
    }

    return ticks;
};


  
  
  return (
    <div style={{ width: "max-content" }}>
      <AreaChart
        width={600}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="contactDate" tickFormatter={formatDate} />
        <YAxis 
          tickFormatter={formatYAxis} 
          ticks={getUniqueTicks()} 
          interval={0}
          type="number"
          domain={[0, 'dataMax']}
          allowDecimals={false}        
        />
        <Tooltip labelFormatter={formatDate} formatter={(value) => [value, 'Count']} />
        <Area
          dataKey="contactCount"
          fill={areaColors[0]}
          type="monotone"
          name="Count"
        />
      </AreaChart>
      <small className="d-flex contactContent">Days Vs Count Of New Contacts</small>
    </div>
  );
};

export default ContactChart;


