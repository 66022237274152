import React, { useState, useCallback } from 'react';
import { getCollections } from '../../services/catalogueService';
import { useSelector } from 'react-redux';
import './Catalogue.scss';
import BizTable from '../../common/BizTable';

export const Collections = () => {
    const [collection, setCollection] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const channel :any = useSelector ((state: any) => state.cartreducer.channelUid);
    const [counts,setCount] = useState<number>(0);
 
const  columns = [
  {
    Header: "Images",
    accessor: "catalogueProducts",
    Cell: ({ cell }: any) => {
      const products = cell.value;
      if (products.length >= 4) {
        return (
          <div className="four-image-square">
          {products.slice(0, 4).map((product: any, index: any) => (
            <img key={index} src={product?.media?.url} alt={product.name} className="corner-image" />
          ))}
        </div>
        );
      } else {
        return (
          <img src={products[0]?.media?.url} alt={products[0]?.name} className="singleImg" width={50} />
        );
      }
    }
  },
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ cell, row }: any) => cell.value? cell.value : '-',
    },
    {
      Header: 'Name',
      accessor: 'name', 
      Cell: ({ cell, row }: any) => cell.value? cell.value : '-',
    },
    {
        Header: 'Collections Id',
        accessor: 'catalogueId',
        Cell: ({ cell, row }: any) => cell.value? cell.value : '-',
    },
    {
      Header: 'No. Of Products',
      accessor: 'numberOfProducts', // Renamed accessor to avoid conflict
      Cell: ({ row }: any) => <p>{row.original.catalogueProducts.length}</p>, // Accessing the length from the original data
    }
  ] as any;
  
    
    const fetchData = useCallback(
        async (
          pageIndex: number,
          pageSize: number,
          searchText: string,
          currentPage: number
        ): Promise<{ data: any; totalPages: number }> => {
            const channelId = channel?.value;
            const response = await getCollections(channelId);
            if (response) {
              setCollection(response.data);
              setIsLoading(false);  
              return { data: response.data, totalPages: 100 };
            } else {
              return { data: [], totalPages: 0 };
            }
        },
        [channel?.value]
      );
    
    return(
        <div>
             {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
          <div className='row pt-3 pb-2'>
            <div className='col-md-3'>

        </div>
        </div>
          <BizTable
          columns={columns}
          fetchData={fetchData}
          counts={counts}
          refetchData={collection}
          tableName='PRODUCTLOG'
        />
        </>
        )}
        </div>
    )
}
