import React, { useState, useContext } from 'react';
import './conversation.scss';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faWarning, faCancel, faExchange } from '@fortawesome/free-solid-svg-icons';
import './optinCheckbox.scss';
import axios from '../../utils/axios';
import { useSelector } from 'react-redux';
import { toast } from '../../common/alert';
import conversationContext from './conversationContext';
import { useNavigate } from 'react-router-dom';
import { url } from 'inspector';

interface convDropdownProps {
    component: string | undefined;
    selectedConversation: any;
    markConversationAsSpamHandler: () => void;
    toggleBlockHandler: () => void;
    swapAccount: (acc: string, tags: any) => void;
}

const ConversationDropdown: React.FC<convDropdownProps> = ({ component, selectedConversation, markConversationAsSpamHandler, toggleBlockHandler,
    swapAccount
}) => {
    const [isChecked, setIsChecked] = useState(selectedConversation?.contact?.optState || false);
    const businessUid = useSelector((state: any) => state.cartreducer.business?.business?.uid);
    const channelUid = useSelector((state: any) => state.cartreducer.channelUid);
    const conversationCtx = useContext(conversationContext);
    const currentUrl = window.location.href;
    const navigate = useNavigate();
    const handleCheckboxClick = () => {
        changeOptin(!isChecked);
        setIsChecked(!isChecked);
    };

    const changeOptin = async (optin: boolean) => {
        try {
            const contact = selectedConversation?.contact;
            const countryCodeLength = contact?.countryCode?.length || 0;
            contact.mobileNumber = contact?.mobileNumber?.slice(countryCodeLength);
            delete contact.optState;
            contact.optIn = optin;
            contact.channelList = [channelUid?.value];
            const updatedContactResponse = await axios.patch(`contact/${contact?.uid}/update`, {
                ...contact,
                uid: businessUid
            });
            if (Object.keys(updatedContactResponse).length) {
                if (optin) {
                    toast('success', 'WhatsApp Opt-In enabled successfully');
                } else {
                    toast('success', 'WhatsApp Opt-In disabled successfully');
                }
                contact.mobileNumber = updatedContactResponse?.data?.mobileNumber;
                conversationCtx.updateConversationContact(updatedContactResponse?.data);
            }
        } catch (error) {
            toast('error', 'Failed to enable WhatsApp Opt-In');
        }
    }


    return (
        <div id='conversation-options'>
            <UncontrolledDropdown group>
                <DropdownToggle className="btn btn-link contextMenu">
                    <FontAwesomeIcon icon={faEllipsisVertical} className='pt-1' />
                </DropdownToggle>
                <DropdownMenu className="ctxMenu">
                    {selectedConversation?.status !== "SPAM" ? (
                        <DropdownItem
                            className="ctxMenuItem itemsColor"
                            onClick={() => markConversationAsSpamHandler()}
                        >
                            <FontAwesomeIcon className="mr-2" icon={faWarning} />
                            Mark as Spam
                        </DropdownItem>
                    ) : null}
                    <DropdownItem
                        className="ctxMenuItem itemsColor"
                        onClick={() => toggleBlockHandler()}
                    >
                        <FontAwesomeIcon className="mr-2" icon={faCancel} />
                        {selectedConversation?.statusType ? selectedConversation?.
                            statusType === 'BLOCK' ? 'Unblock' : 'Block' : 'Block'}
                    </DropdownItem>
                    <DropdownItem
                        className="ctxMenuItem"
                    >
                        <div className='checkbox-wrapper-27' onClick={handleCheckboxClick}>
                            <label className="checkbox itemsColor m-0">
                                <input type="checkbox" checked={isChecked} />
                                <span className="checkbox__icon"></span>
                                WhatsApp Opt-In
                            </label>
                        </div>
                    </DropdownItem>
                    {component !== 'supportInbox' ? (
                        <DropdownItem
                            className="ctxMenuItem itemsColor"
                            onClick={() => {
                                swapAccount("ACCOUNT", selectedConversation?.contact?.tags);
                                if (currentUrl.includes("conversations?contactUid=")) {
                                    navigate('/conversations', { replace: true });
                                }
                            }}
                        >
                            <FontAwesomeIcon className="mr-2" icon={faExchange} />
                            Convert as Account
                        </DropdownItem>
                    ) :
                        <DropdownItem
                            className="ctxMenuItem itemsColor"
                            onClick={() => {
                                swapAccount("LEAD", selectedConversation?.contact?.tags);
                                if (currentUrl.includes("supportInbox?contactUid=")) {
                                    navigate('/supportInbox', { replace: true });
                                }
                            }}
                        >
                            <FontAwesomeIcon className="mr-2" icon={faExchange} />
                            Convert as Lead
                        </DropdownItem>
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

export default ConversationDropdown;
