import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import "../../main.scss";
import {
  getConnection,
  updateConnection,
} from "../../../../services/integrationService";
import { useSelector } from "react-redux";
import { workFlowPayment } from "../../assets";
import WorkFlowHeader from "../../Component/WorkFlowHeader";
import WorkFlowForm from "../../Component/WorkFlowForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import LogsTable from "../../Component/LogsTable";


interface RazorpayProps {
  integrationUid?: string; // Make it optional if it can be undefined
  activeTab: string;
  connectionUid: string;
  callDb: boolean;
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkFlowEnable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isInvalidAccess: boolean;
}

interface FormValues {
  connectionName: string;
  channelName: { value: string; label: string } | null;
  integrationVariables: any[];
  webhookUrl: string;
  webhookSecret: string;
}

const validationSchema = Yup.object({
  connectionName: Yup.string().required("Integration is required"),
  channelName: Yup.object().nullable().required("Channel Name is required"),
  webhookUrl: Yup.string().required("WebHook URL is required"),
});

const WebEngage: React.FC<RazorpayProps> = (props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    connectionName: "",
    channelName: null,
    webhookUrl: "",
    integrationVariables:[],
    webhookSecret: ""
  });

  const [webhookSecretCopied, setWebhookSecretCopied] = useState(false);
  const [webhookUrlCopied, setWebhookUrlCopied] = useState(false);
  const [activeWorkflowId, setActiveWorkflowId] = useState<number | null>(null);
  const [isWorkFlow, setIsWorkFlow] = useState([]);
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
  useEffect(() => {
    if (props.integrationUid) {
      getConnection(props.integrationUid)
        .then((data: any) => {
          setInitialValues({
            connectionName: data.integrationConfigurationDTO.integrationName || "",
            channelName: data.integrationConfigurationDTO.channelDto
              ? {
                  value: data.integrationConfigurationDTO.channelDto.uid,
                  label: data.integrationConfigurationDTO.channelDto.name,
                }
              : null,
              webhookSecret:
              data.integrationConfigurationDTO.webhookData.webhookSecret || "",
              integrationVariables: data?.integrationVariables,
              webhookUrl:
              data.integrationConfigurationDTO.webhookData.webhookUrl || "",
          });
          props.setWorkFlowEnable(
            Object.keys(data.integrationConfigurationDTO?.channelDto).length >
              0 || false
          );

          setIsWorkFlow(data.workFlows);
        })
        .catch((error) => {
          console.error("Error fetching connection data:", error);
        });
    }
  }, [props.integrationUid, props.callDb]);

  useEffect(() => {
    if (props.callDb) {
      setTimeout(() => {
        props.setCallDb(false);
      }, 1000); // set callDb to false after 2 seconds
    }
  }, [props.callDb]);

  const handleCopy = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    // props.setActiveTab("Workflow");
    const data = {
      integrationConfigurationDTO: {
        channelDto: {
          uid: values.channelName?.value,
        },
        integrationName: values.connectionName,
      },
    };

    try {
      const response = await updateConnection(props.connectionUid, data);
      props.setCallDb(true);
      // props.setActiveTab("Workflow");
      // formikHelpers.resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const channelOptions = channelData.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));

  function toCamelCase(str: string | undefined): string {
    return (
      str?.split(" ").reduce((acc, word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return (
            acc + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          );
        }
      }, "") || ""
    );
  }

  // const findWorkFlowById = (id: number) => {
  //   const workFlowType = workFlowPayment.find((types: any) => types.id === id);
    
  //   const workFlow = isWorkFlow.find(
  //     (wf: any) => wf.workFlowType === toCamelCase(workFlowType?.title)
  //   );

  //   return workFlow || null;
  // };
  const [showPassword,setIspassword] = useState(false);
  const PasswordVisibility = ()=>{
    setIspassword(!showPassword)
  }

  const findWorkFlowById = (id: number) => {
    const workFlowType = workFlowPayment.find(
      (types: any) => types.id === id
    );
    const workFlow = isWorkFlow.find(
      (wf: any) => wf.workFlowType === workFlowType?.value
    );
    return workFlow || null;
  };
  return (
    <>
      {props.activeTab === "Overview" && (
        <div className="shipRocketOverview">
          <div className="headerOverview">
          Integrate WebEngage with your official WhatsApp Business Number and initiate automated WhatsApp Notifications. Send customised WhatsApp Messages to the identified customer segment. Connect with you customers on their favourite message platform.
          </div>

          <div>
            <h5 className="fw-bold mt-4 mb-4">What can I do?</h5>
          </div>

          <div className="body">
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO1SURBVHgBvVhLTBNBGP5mqaIx6hqDiaXGFvSgHmhP6kVK4oMLoTHgQQ6UKAcvUrh6oMQYE0wMePEioRzkoAHbmxIS6gk40Yt4UXYJBUI0UkhQEbrjP8sjrYXuLA+/BLYz+8/M1//xzUwZJDHGNVXFobJVpAMK4AeYCnD3+usU/ekcTKe+qAIlXsKck7JzMysDsfgxHGyiyUPUVCEJDsSJVMd5Vhyzss1LQuMzTQaMsJ3Fc8kw8gwL5fPMliQ+81n3AaS7men2PUGKPBM6x1w9kCEhCByEMZQR7z0EC5ey4rac3v9HYANKqJQ5O7N6MhsiBPtLQMDo0PhsObYi8ZVPt9rJgcXUIro7XqEv8hZ2YSAdEVW30XaIfyIMQDpkZ6Iq700kJ5PrhBbQELpvZ7h7rexh5oeZE1/4NFUCD8qMHk98woPAPRS7z+CC95JJYE6fRX+0H6fUIizjD+bwAyRqVlORL1c8PuZJOdaZ+CGBpD6FuopavHzXhSv+q5v9RSQjR19HgVgMhdXVKKqrwSy+W01HIlhYT89ORePT1bLJ+CL8HA9bW7IICBxGITA4CCwtAb29a20JcBgB8VQMcD8kMRD7gBuBypz+JfwGyAMoKQEaG/ETv2SmMyMgEpRN8OQQlwxHKXNRFSVz+mnDwkkcx0pqGRP6BE55i2VywgRZ+RQi4MUuQfsLvmEeXdEInna2SxMQII0oEzqx481pL8CgqIqscV/kDS6XX81rI0p2MPoe9sBNEikZU1EZt4N38tpcJBIXyi6hk2ylKYDrjORasypR4YWR+AieRawnF1pS5buFsflxyICqs0J4Im5luEDaZnkE2yGOoEBz0KknYYjDWB7UUBgeh1rhO3HRjLvIDZfbtflebGbTehKjH4cxRZ5oIkGThO6kExfTSCwMHJiXGSEWG6DEe9IcxvXqyoz+BcwQiUcd4Rw1tUCEtKfB9LIdwRK466/FZVqsKdxiEqujdj3tojXBWtjBYShu4QmzRBkKwnYGt1OCjsaHTQW95rlCVVNrm4A4jTvXD7+b+WbXG7vFhhfE502xIm80QFIzdg8WdmZcAbIqj85+9eLohf1FgsLoy+zIkm0PO90jWGL/oFMYAv925uwda/eCfSGSULDic25xE9tWCJN8rmwZq9G9uAIYAN1JXc3bvbdUY3EVoEdwJ2Ro8ngavO08OxO3sLOGZl4JUM6RDlqX8drPA7QVxKwWt0Uim5CmrsLhpSuCWgDHWVpMpW9LqmekCpBOeJhHh038Bbn3akTlgY4tAAAAAElFTkSuQmCC"
                  alt=""
                />
              </div>
              <div>Send customised WhatsApp notification based on triggers</div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO/SURBVHgBvZdNaBNBFMf/MyabqrSuBxW12I0fBz3Y9KQIYg7iTUwFDyJiWhQ8FFsPehJc0YMo+HETPJgiCH5h/DipYCwI6qWxhxbFmi1UlHpo2gptk3bHN5O2Jk12s+nXD5bO7LzO/PPemzezDBUgRKeObPUhCBambggCOv016EmDwSKDNLhIgvnizB9873Ve5sVIZFP7MDlpknkY3rHouYUAizO2uQ9zFSFGewww/70KFy8Ww4XJtK3tqFRE7tfbcWrqWAiYiEEbOctYQ7poqJS9GEu1AvYtLDwWhSc8OzxsCQXkCRluyPdIgYhcDmgpLDoizqq2Nk73eMEY095hSWARMdbbWiRCjPdeRG7PLxWmqjv5IqjwRJ2sm06dU0+yqxvu+AD/ekDbRM01ub4zOjI1yhsqJ0Tm+wnYLOZkHX/xGvGXb5S5eeGM46zGtj3Ag4fA27fA/v3A0cNA9hdcSLOqLatzIsZ+UC6IsJt18ks3GnYdRF1dbWkBhoHEwydAc/P/l48ekTP+UMN2nngZD0/5y12AWoQW1/UaWF+djgSKbHYlsHYtMDAArFsHrNCAjO0+sZiI+ETmWwhl7CRSgLFpo8qL0M4dJSxokqoscPUq0NUF7KHQTAyWn9jmBgf318Ej9fXbKSw9zgaTg7CGe2F+IG/5KQz2X3ggxJHNroZHQuVEEOblGzBqa8jNHtybQ/ehAsJ7d6Pp/nlYff0lx9NDw3jf8RGxu9dQAZWJkNtUCggfOOZo47aFHbB88PsHMVHedeaV22i//xSdn16pnbJwCIsJkdIxbpdNYyoqePb4Du2M7a52+qoatZM8wxDzMRZM02Fiocy50doSReOR06pY9VFIZhct+U7Xq1X74oVWtLU0wZsInpgq2703aZu3udnKcMh8iN29jiidI6H6HQULSU+lpgqZrKyDvzvhicByg0+piZezlb8yPTRCxaoHiY7PlBcbi8YTHZ/Uo6+qhkfoEryhT+0OeT2n8yPhVr7bWpphWT8RoZBEjx9G5OCBgvF3rx8gevK8ygeZO54Q9m21/kw/018PezyJpYISkgW2qHjO3CeYVvuFeot5t8zHgrbcnFl79qiXY33e+HiE+YLPp7u8yCDAGkna4oWF7kX5AnKvSpArYOLZAnuEvlN5G9OC7cW6XFCXXwET84c+BQMRlXcl4G7/Sdl7CYIHZSZjbsivdRMB3uAkQK0Dj4jRlAFuS8+E4V7i6cuKUU6xOAJol8cCyuBZRIEgmTNjEyFqGQUDk/4kVsLysnA+/wCXsVCieuwPTgAAAABJRU5ErkJggg=="
                  alt=""
                />
              </div>
              <div>WhatsApp Broadcast to targeted customer segments</div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOeSURBVHgBvVc/TBNRGP+9o2IcwG4m7RFaURN1ABYEl54D6iYKOLC0RhYXC+6GM7hq6EgioS4O/gkYFxETykR0oQyWQZEaqsTJwqbIPb/vaCut5e4dof6S43j3vt77ve/7fd/7TsADVtdl5Pc2egTQJgRCkHT9RVoKZOl5iuanjwfEF9X3CjeDxVXpb6xHnBYcoqEf6khLYOyELh67GTqSWP0m45YF0+PilcgKDYaTZ6qS4N03HMIUTRo4KAiYLUFxr/pUBZbXZajewlxFvGtKpIxEIf6LNSHgQETbPWiox2RNCTAkzBXSGsp4FfBpXUbFNpL4P8iTWNuKYrU9wToQO1mghNxaFpHOEFp0YV+RrjAyH9LwAD9lXbI4sEmQEKNewvBuIYXTZ9uwkpP21dEZwXLGEwkOgcHFr/A/sPJVrqqQ4MUnHyVKCwb1nZ8skxf0phAaGv3ovR5Fb38MKrComJ3UxbCP2Vjb7gRmZ6Yxag4jPjyC2GC8qg2HKfHARCOR6b7U4/ZKDkOMbsM+IuBuTXjxNInbd0acd9kFHD3qt21VSBD87AQfH0ZSwXpzcwNNBfczODQsxs3NPIUgBr0wxyHhZ6qwJNp8cuc0VMIGvZzDwrrI2Dpotp/zzvWmsK2H4C6iSiQshHwqgizu+NbNqzjXFaGwmOjsMpB4aNrzcRq/IXJM5v3CPBooJLlctuQdJ5Au/JqbEe98oP8CxfgKFjM/8ORZyiZQiYukgfGJabyaWSTdRDHQZ5DHxqACH10cwD2Panb9+MQUecCACjhV2TNnqI6wZ24MDrn+RqNK4aiioN7stRp6A3VjGmnScQWujJnMErxi9vW0kvdID2mtjnpCJ6M+Sr+39EIvacfgFO6+7F4rDgsisWVh3smIq981Sr37pntsixgl247zhkp2ZAN0kmqndJGmgpVysmShcUke6DfsHe4FnuNM4vtd0z0zKBQm3+0DjEpn1FLoJZ6T2pMTY3RgVdcI1xDWAZNWQPYINcDsiVJT8zkn5+RBNrZu2NXmlUjkvsvWn1uoYS6WIUvNULg4KFVM/ZhY0jSoq2//yHMYdj8oK9vhgEiwm1BDaAKxQMWH0D9nhx2n2hDJE4GecFC8rJzY8zPwgD4BiyhlQrXJPU9RDo1Wh3aimcT+kWevaltoD3j9Fq0E1ZEQeWUEnMIqXTkdSvQ3qf1CIhwWeXdzj/i4Jg1fHVq5I0J5qPLbwNKhLaRo4Sw84A8haGDUNpxzPQAAAABJRU5ErkJggg=="
                  alt=""
                />
              </div>
              <div>Configure custom fields and trigger customised messages</div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANvSURBVHgBtVi7T+pgFD8fV00MEepqTCw+Bl2EOxg3MFE3A9e4OYibk3AXJ5NbEydd4B+44KCbRhMnh0uvk7rApIuPkuB8K04m2t5zPgryKm0N/SXQ73Ho+fW8vlMY2ISezwsgCNPw/h4DjyeCSwJ+RGNbxY8Cuq7g9RT3ZTY6WrR5a2BWAly5z5fAYdJQbJO1LuMnxSYmzqxEO5LQn54SoGmSI+WtZMgyyU6WaUtCv7sTobc3A4xFoDtQkUySjY8fgB0SnEBfXw4+/d1NSGxsbAc6kXCZQBVJJJKuX/A0bJML3CVASGGshaEdCf3h4ZfTGFDLZUhlMpA9PuZj29C0LM86A9wdhhvy4DALxHAYIjMzfKw8P4N8dOTk57X4qJC4v6dMiDu4AUjpNCilEmT39/l8MBiE/Pk5iMPDdm+hQrkcYKGQ2sOnHdygvr7Cy8sLH/v9fhAGBrjyA3RBznhycgXJOSBAoCK4htd0DwZJFH0ktpMiE39fWgK/z8fnRVT+5/AQ4ltbIG1u1pQSqeDkJDiGrsc4CSQQMZOhp43Oz0Nmb4/Psycn8GNjg6/FV1YayI44s0IF6AEK0B4cBJGRqVyziQfRHVIi0bBGVijc3kIg3JB5EF1YgNT2NnSE1yv2IIEgOEBkdraFGM2Vy0vuliooTpK7uzyFk+vr5jf0eKYpML9+ODWhHTlLMCZ4wEXI19cQQ5dYgJNQwQXIV1c8fiytgY2QayQok8IYP5bQ9SKRkMEFkCviy8vWgl7vkwejswAO8A8roxUoXRmmfXBqykpUYUND3BIHZhJUotU6pVS+Ty8ueOp1Ap2q0cVFsAGZvioH2ONjDn0TaZagvJ9bXcUsqvQ+KpL4jdWTiPy9uYEclvDmwONuwLIut9lrQX+/SJaokKAmQ9NkM9lqERLwDBGMc4ROUXriIpbsepD1qMzHrCyB3Tj2nHM0rLV3ZtZwDYYVaPhZrBij2upKuraBVCXAVdfvoFvWqPUCd1HAjipUv9BQtlkgQJkigXtQ0A2x5sWWs8Po+yToPgpYk0L1bqjpNPuFXipNw9vbKXTjFUDT6J30p9m29QsxvQoAxL9EhjEZPj52kIDcUQxsAANWxEsYUzhuI40VoL8HNO3MSrkjEk2EBPyPIkjNCHz7NoLKBHzaIvpbxXkBg1sBh/gPzm1d23tB6R4AAAAASUVORK5CYII="
                  alt=""
                />
              </div>
              <div>
              Handle responses through WhatsApp Chatbot
              </div>
            </div>
          </div>
        </div>
      )}

      {props.activeTab === "Configuration" && (
        <div className="shipRocket-configuration">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="connectionName">
                    Integration Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <div className="row">
                    <div className="col-5 pe-3">
                  <Field
                    type="text"
                    name="connectionName"
                    className="form-control w-100"
                  />
                    </div>
                  </div>
                  <ErrorMessage
                    name="connectionName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* <div className="form-group">
                  <label htmlFor="webhookSecret">API Key</label>
                  <div className="row">
                    <div className="d-flex col-5 pe-0 align-items-center">
                    <Field
                     type={showPassword ? "text" : "password"}
                      name="webhookSecret"
                      className="form-control w-100"
                    //   disabled
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      className="text-muted"
                      onClick={PasswordVisibility}
                    />
                    </div>
                    <div className="col">
                    <button
                      type="button"
                      className="copyButton"
                      onClick={() =>
                        handleCopy(
                          document.querySelector<HTMLInputElement>(
                            'input[name="webhookSecret"]'
                          )?.value || "",
                          setWebhookSecretCopied
                        )
                      }
                    >
                      {" "}
                      <svg
                        className="me-2"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                        height="1.2em"
                        width="1.2em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        ></path>
                      </svg>
                      {webhookSecretCopied ? "Copied" : "Copy"}
                    </button>
                    </div>
                  </div>
                </div> */}
                <div className="form-group">
                  <label htmlFor="channelName">
                    Channel Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Select
                    options={channelOptions}
                    name="channelName"
                    value={values.channelName}
                    onChange={(option) => setFieldValue("channelName", option)}
                    className="channel-select"
                  />
                  <ErrorMessage
                    name="channelName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group" >
                  <label htmlFor="webhookUrl">WebHook URL</label>
                  <div className="row">
                  <div className="d-flex col-5">
                    <Field
                      type="text"
                      name="webhookUrl"
                      className="form-control w-100"
                      disabled
                    />
                    </div>
                    <div className="col">
                    <button
                      type="button"
                      className="copyButton"
                      onClick={() =>
                        handleCopy(
                          document.querySelector<HTMLInputElement>(
                            'input[name="webhookUrl"]'
                          )?.value || "",
                          setWebhookUrlCopied
                        )
                      }
                    >
                      <svg
                        className="me-2"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                        height="1.2em"
                        width="1.2em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        ></path>
                      </svg>
                      {webhookUrlCopied ? "Copied" : "Copy"}
                    </button>
                  </div>
                  <ErrorMessage
                    name="webhookUrl"
                    component="div"
                    className="text-danger"
                  />
                </div>
                </div>
                <div className={`d-flex justify-content-center mt-3 ${props?.isInvalidAccess ? "notAllowed" : "pointer"} `}>
                  <button
                    type="submit"
                    className="btn btn-primary d-flex justify-content-between"
                    disabled={props?.isInvalidAccess}
                  >
                    Update Configuration
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {props.activeTab === "Workflow" && (
        <div>
          {activeWorkflowId ? (
            <WorkFlowForm
              id={activeWorkflowId}
              title={
                workFlowPayment.find((wf) => wf.id === activeWorkflowId)
                  ?.title || ""
              }
              description={
                workFlowPayment.find((wf) => wf.id === activeWorkflowId)
                  ?.description || ""
              }
              setActiveWorkflowId={setActiveWorkflowId}
              connectionUid={props.connectionUid}
              channelDtoUid={initialValues.channelName?.value}
              integrationName={initialValues.connectionName}
              workflowData={findWorkFlowById(activeWorkflowId)}
              setCallDb={props.setCallDb}
              value={
                workFlowPayment.find((wf) => wf.id === activeWorkflowId)
                  ?.value || ""
              }
              isInvalidAccess={props.isInvalidAccess}
              integrationVariables={initialValues.integrationVariables}
            />
          ) : (
            workFlowPayment.map((el: any) => {
              return (
                <WorkFlowHeader
                  key={el.id}
                  id={el.id}
                  title={el.title}
                  description={el.description}
                  setActiveWorkflowId={setActiveWorkflowId}
                  workflowData={findWorkFlowById(el.id)}
                  connectionUid={props.connectionUid}
                  channelDtoUid={initialValues.channelName?.value}
                  integrationName={initialValues.connectionName}
                  setCallDb={props.setCallDb}
                  isInvalidAccess={props.isInvalidAccess}
                />
              );
            })
          )}
        </div>
      )}
            {props.activeTab === "Logs" &&(
        <>
          <LogsTable
          connectionUid = {props.connectionUid}
          />
        </>
      )}
    </>
  );
};

export default WebEngage;
