import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import './flagInput.scss';

export function showAlert(icon: SweetAlertIcon, text: string): void {
  Swal.fire({
    title: `<span class="medium">${text}</span>`,
    icon: icon,
    width: '25%',
  });
}


export function sweetalert(icon: SweetAlertIcon, text: string, confirmCallback: () => void, cancelCallback: () => void): void {
  Swal.fire({
    title: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    customClass: {
      container: 'custom-sweetalert'
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
      if (confirmButton) {
        confirmButton.style.backgroundImage = 'linear-gradient(to right, #00B598, #458d80)';
        confirmButton.style.border = 'none';
        confirmButton.style.color = 'white';
      }
    }
  }).then((result) => {
    if (result.isConfirmed) {
      if (confirmCallback) {
        confirmCallback();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      if (cancelCallback) {
        cancelCallback();
      }
    }
  });
}
export function confirmAlert(icon: SweetAlertIcon, text: string, confirmCallback: () => void, cancelCallback: () => void): void {
  Swal.fire({
    title: text,
    icon: icon,

    showCancelButton: true,
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
    customClass: {
      container: 'custom-sweetalert',
      confirmButton: 'custom-confirm-button'
    },
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
      if (confirmButton) {
        confirmButton.style.backgroundImage = 'linear-gradient(to right, #00B598, #458d80)';
        confirmButton.style.border = 'none';
        confirmButton.style.color = 'white';
      }
    },
    imageWidth: 100, // Adjust width of the image if needed
    imageHeight: 100, // Adjust height of the image if needed
    imageAlt: 'Image',
  }).then((result) => {
    if (result.isConfirmed) {
      if (confirmCallback) {
        confirmCallback();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      if (cancelCallback) {
        cancelCallback();
      }
    }
  });
}


export function toast(icon: SweetAlertIcon, text: string, width?: string): void {
  Swal.fire({
    toast: true,
    icon: icon,
    title: text,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    width: width || 'auto',
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
}

export function toastTags(icon: SweetAlertIcon, text: string): void {
  Swal.fire({
    toast: true,
    icon: icon,
    title: text,
    position: 'center',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
}

export function warningAlert(icon: SweetAlertIcon, confirmationText: string, actionFunction: any): void {
  Swal.fire({
    title: 'Are you sure?',
    text: confirmationText || "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, proceed!',
    cancelButtonText: 'Cancel'
  }).then((result) => {
    if (result.isConfirmed) {
      actionFunction(); // Call the provided action function
    }
  });
}

// export function DeleteAlert(icon: SweetAlertIcon, text: string, confirmCallback: () => void, cancelCallback: () => void): void {
// Swal.fire({
//   title: 'Are you sure?',
//   text: text,
//   icon: 'warning',
//   showCancelButton: true,
//   confirmButtonColor: '#3085d6',
//   cancelButtonColor: '#d33',
//   confirmButtonText: 'Yes, delete it!',
//   cancelButtonText: 'No, cancel',
// }).then((result) => {
//   if (result.isConfirmed) {
//     if (confirmCallback) {
//       confirmCallback();
//     }
//   } else if (result.dismiss === Swal.DismissReason.cancel) {
//     if (cancelCallback) {
//       cancelCallback();
//     }
//   }
// })
// }

export function toastAlert(position: SweetAlertPosition, title: string, icon?: SweetAlertIcon, html?: boolean,
  timing?: number, width?: string): void {
  Swal.fire({
    toast: true,
    position: position,
    timerProgressBar: true,
    showConfirmButton: false,
    timer: timing || 3000,
    title: title,
    icon: icon,
    width: width || 'auto'
  });
}



export function PayNowAlert(text: string, confirmCallback: () => void, cancelCallback: () => void): void {
  Swal.fire({
    title: `<span class="small">${text}</span>`,
    width: '30%',
    imageUrl: '/images/paynow.jpg',
    imageWidth: '40%',
    confirmButtonText: 'Pay Now',
  }).then((result) => {
    if (result.isConfirmed) {
      if (confirmCallback) {
        confirmCallback();
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        if (cancelCallback) {
          cancelCallback();
        }
      }

    }
  })
}