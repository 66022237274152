import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Offcanvas } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'

import { IIntegrationApp } from '../../../../entity/integration/integrationApp'
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList'
import { useElmentEditor } from '../hooks'
import { IEditorProps } from '../types'
import EditorCaption from '../editorCaption'
import { razorPayIcon } from '../../../../icons'
import IntegrationConnection from './integrationConnection'
import SelectIntegrationAction from './selectIntegrationAction'
import ActionsFieldRenderer from './actionsFieldRenderer'

type Payload = Record<string, string> | null

interface FormData {
    integrationData: {
        app: IIntegrationApp,
        connectionId: number | null
        action: IIntegrationActionListId | null
        payload: Payload,
        query: null,
        recordId: null
    },
    storageVariable: { name: string, type: 'OBJECT', paymentId: string } | null
}

function RazorPay(props: IEditorProps) {
    const [acceptPartial, setAcceptPartial] = useState(false)
    const [isCreateScreen, setIsCreateScreen] = useState(false);
    const [isEditScreen, setEditScreen] = useState(true);
    const [formData, setFormData] = useState<FormData>({
        integrationData: {
            app: 'RazorPay',
            connectionId: null,
            action: null,
            payload: null,
            query: null,
            recordId: null
        },
        storageVariable: null,
    })
    const { init, saveElementChanges } = useElmentEditor({
        type: 'integration/RazorPay',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const payloadSchema = yup.object().shape({
        amount: yup.string().required('Amount is required'),
        currency: yup.string().notRequired(),
        accept_partial: yup.bool(),
        first_min_partial_amount: acceptPartial ? yup.string().required('Partial amount is required') : yup.string().notRequired(),
    });
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        integrationData: yup.object().shape({
            connectionId: yup.string().required('Connection is required'),
            action: yup.string().required('Action is required'),
            payload: payloadSchema,
        }),
        storageVariable: storageVariableSchema
    })

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, setFieldValue, values, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                const myAction: ActionsListItem | null = useMemo(() => {
                    if (values.integrationData.connectionId) {
                        (window as any).appConnectionId = values.integrationData.connectionId
                    }
                    if ('action' in values.integrationData && values.integrationData.action) {
                        return ACTION_LIST['RazorPay'].find(action => action.id === values.integrationData.action) || null
                    }
                    return null
                }, [values])
                useEffect(() => {
                    if (myAction && myAction.id === 'createPaymentLink'
                        && !values.integrationData.payload
                        && !formData.integrationData.payload) {
                        setFieldValue('integrationData.payload', {
                            amount: '',
                            currency: '',
                            accept_partial: false,
                            first_min_partial_amount: ''
                        });
                    }
                }, [myAction]);
                useEffect(() => {
                    if (values.integrationData.payload
                        && 'accept_partial' in values.integrationData.payload) {
                        setAcceptPartial((values.integrationData.payload as any).accept_partial);
                    }
                }, [values]);

                const onGoBackHandler = () => {
                    if (values.integrationData.action) {
                        setFieldValue('integrationData.action', null)
                        setFieldValue('integrationData.payload', null)
                    } else if (values.integrationData.connectionId) {
                        setFieldValue('integrationData.connectionId', null)
                    } else {
                        props.onClose()
                    }
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption
                            onHide={onGoBackHandler}
                            caption='Razorpay'
                            icon={<img style={{ width: 16 }}
                                alt='Razorpay'
                                src={razorPayIcon}
                            />} />
                        <Offcanvas.Body>
                            {myAction ? <h5 className='mb-3'>{myAction.label}</h5> : null}
                            {values.integrationData.connectionId ? values.integrationData.action ?
                                myAction ? (
                                    <ActionsFieldRenderer actionsFields={myAction.fields} />
                                ) : null : (
                                    <SelectIntegrationAction app='RazorPay' />
                                ) : (
                                <IntegrationConnection app='RazorPay' scope='COM'
                                    onScreenChange={(screen: any) => {setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit');}}
                                    isInvalidAccess = {props?.isInvalidAccess}
                                />
                            )}
                        </Offcanvas.Body>
                        { !isCreateScreen && isEditScreen && 
                        <div className='editor-footer'>
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button variant='primary' className='sendButton' type='submit' disabled={isCreateScreen || props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
            }
                    </Form>
                )
            }}
        </Formik>
    )
}

export default RazorPay