import React from 'react';
import noData from "../../src/assets/img/noData.png";

export const ErrorImage = () => {
    return (
        <div className='col-12 text-center'>
        <img src={noData} alt='nodata' width={220} height={200} className='' ></img>
        <br/>
        <h5 className='text-muted'>No Data Found</h5>
        </div>
         
    )
}