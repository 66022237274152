import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

export function showAlertYesNo(icon: SweetAlertIcon | null, text: string): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    title: `<span class="medium" style="font-size: 24px;">${text}</span>`,
    icon: icon ? icon : undefined,
    width: '25%',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    didOpen: () => {
      const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
      if (confirmButton) {
        confirmButton.style.backgroundImage = 'linear-gradient(to right, #00B598, #458d80)';
        confirmButton.style.border = 'none';
        confirmButton.style.color = 'white';
      }
    }
  });
}
