import React from "react";
import moment from "moment";
import { Button } from "reactstrap";

import styles from "./PendingTransation.module.scss";
import { formatCurrency } from "./SubscriptionPage";

interface PendingTransationProps {
  amount: number;
  createdAt: string;
  onPay: () => void;
};

const PendingTransation: React.FC<PendingTransationProps> = ({
  amount,
  createdAt,
  onPay
}) => {
  return (
    <div className={styles.pendingTransation}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 13 13"
        fill="none"
        className="mb-2"
      >
        <g clip-path="url(#clip0_1856_6924)">
          <path
            d="M6.4987 11.9166C9.49024 11.9166 11.9154 9.49152 11.9154 6.49998C11.9154 3.50844 9.49024 1.08331 6.4987 1.08331C3.50716 1.08331 1.08203 3.50844 1.08203 6.49998C1.08203 9.49152 3.50716 11.9166 6.4987 11.9166Z"
            stroke="#4F5E71"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.5 3.25V6.5L8.66667 7.58333"
            stroke="#4F5E71"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1856_6924">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <h3>You have a one Pending Transaction for <span className="fontchange">{formatCurrency(amount, "INR")}</span> Created on {moment(createdAt).format("DD MMM, YYYY")}</h3>
      <p>If you still want to create a new Payment, please click on Pay Now. Your Pending Transaction will be automatically cancelled</p>
      <Button
        color="primary"
        className="signUpBtn"
        onClick={onPay}
      >
        Pay Now
      </Button>
    </div>
  );
};

export default PendingTransation;
