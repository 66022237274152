import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConnectButton } from "360dialog-connect-button";
import QRCode from 'qrcode.react';
import { Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './welcome.scss';
import Loader from '../../shade/Loaders/Loaders';
import { createChannel } from '../../services/dashboardService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import FacebookOnboarding from '../facebookOnboard/fbOnboard';
import axios from "../../utils/axios";
import ChannelConnect from "../../common/connectButton";

const Welcome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isProfileLoaded = Object.keys(useSelector((state: any) => state.cartreducer.business)).length > 0;
    const businessId = useSelector((state: any) => state.cartreducer.business?.business?.uid);
    const business = useSelector((state: any) => state.cartreducer.business?.business);
    const channels = useSelector((state: any) => state.cartreducer.channelData);
    const [loading, setLoading] = useState(false);
    const expireAtString = business?.plan?.expireAt;
    const expireAtDate = new Date(expireAtString);
    const activeChannel = useSelector((state: any) => state.cartreducer.channelUid);

    console.log(activeChannel);
    // Format the date to DD MMM YYYY format
    const formattedDate = expireAtDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });

    useEffect(() => {
        if (isProfileLoaded) {
            setLoading(false);
            // if ((channels && channels.length === 0 && currentSubscriptionPlan) || (channels && channels.length > 0 && !currentSubscriptionPlan)) {
            //     setLoading(false);
            // } else if (channels && currentSubscriptionPlan) {
            //     navigate(`${process.env.PUBLIC_URL}/dashboard`);
            // }
        }
    }, [isProfileLoaded]);

    useEffect(() => {
        if (businessId)
            localStorage.setItem('businessId', businessId);
    }, [businessId])

    useEffect(() => {
        if (business && Object.keys(business).length > 0) {
            if (business.plan && business.plan.name && business.plan.name.toUpperCase() === 'FREE') {
                navigate(`${process.env.PUBLIC_URL}/dashboard`);
            }
        }
    }, [business]);


    if (loading) return <Loader />;

    return (
        <>
            <div className='welcomeContainer'>
                {business?.freeTrial ? <div className='row'>
                    <h6 className='headerOne'>Check with Demo version</h6>
                    <div className='borderLine'></div>
                    <div className='demoContainer'>
                        <div className='demoContainerCol'>
                            <div className='title'>
                                <span>Send a WhatsApp Message</span>
                                <img src='/images/whatsapp-icon.svg' />
                            </div>
                            <p>Click on the button to send a pre-filled message to
                                <br />
                                {`${'+' + (activeChannel?.channelAllData?.mobileNumber || '').slice(0, 2) + ' ' +
                                    (activeChannel?.channelAllData?.mobileNumber || '').slice(2)}. Save this number in your
                                contacts and`}  start messaging.
                            </p>
                            <button className='whatsappBtn cursor-pointer' onClick={() => {
                                window.open(`https://wa.me/${'+' + (activeChannel?.channelAllData?.mobileNumber || '').slice(0, 2) +
                                    (activeChannel?.channelAllData?.mobileNumber || '').slice(2)}?text=Welcome to Bizmagnets`, '_blank');
                            }}>Open WhatsApp Web
                                <FontAwesomeIcon icon={faExternalLinkAlt} className='text-white ml-1'></FontAwesomeIcon></button>
                        </div>
                        <div>
                            <div className='before'></div><br />
                            <div className="lined-text">OR</div><br />
                            <div className='before'></div>
                        </div>
                        <div className='demoContainerCol d-flex'>
                            <div className='title'>
                                <span>Scan the QR Code</span>
                                <p>Use this QR Code to open WhatsApp with a
                                    message already filled in. Simply click "Send," and the message will show
                                    up in your Chat Inbox.</p>
                            </div>
                            <div className='qrImg float-right'>
                                <QRCode value={`https://wa.me/${'+' + (activeChannel?.channelAllData?.mobileNumber || '').slice(0, 2) + ' ' +
                                    (activeChannel?.channelAllData?.mobileNumber || '').slice(2)}?text=Welcome to Bizmagnets`} size={102} />
                                <p className='Qrtxt'>Scan via your mobile</p>
                            </div>
                        </div>
                        <div className='ml-2'>
                            <div className='before'></div><br />
                            <div className="lined-text">OR</div><br />
                            <div className='before'></div>
                        </div>
                        <div className='demoContainerCol'>
                            <div className='title'>
                                <span>Send your First Message</span>
                                <img src={require(`./../../assets/img/msgIcon.png`)} width={30} />
                            </div>
                            <p>{`Experience BizMagnets by using ${'+' + (activeChannel?.channelAllData?.mobileNumber || '').slice(0, 2) + ' ' +
                                (activeChannel?.channelAllData?.mobileNumber || '').slice(2)} as your demo WhatsApp Business number`}
                            </p>
                        </div>
                    </div>
                </div>
                    :
                    <div>
                        <div className='demoContainer'>
                            <div className='demoContainerCol p-2'>
                                <p className='title text-dark'> Hello {(business?.name) ? business.name : 'User'}!</p>
                                <br></br>
                                <b>Your demo account ends on - <span className='text-success'>{formattedDate}</span></b>
                            </div>
                            <span className='connectMargin'>
                                <ConnectButton
                                    // queryParameters={{ plan_selection: 'basic' }}
                                    callback={(obj: any) => {
                                        const storedBusinessId = localStorage.getItem('businessId');
                                        if (storedBusinessId !== null) {
                                            const payload = {
                                                "uid": storedBusinessId,
                                                "client": obj.client,
                                                "channelId": obj.channels,
                                                "revoked": obj?.revokedChannels ? obj.revokedChannels : []
                                            };
                                            createChannel(payload).then(res => {
                                                // axios.get(`channel/getAll?uid=${businessId}`).then((responseData: any) => {
                                                //     const channelData = responseData?.data;
                                                //     dispatch({ type: 'CHANNEL_DATA', payload: channelData })
                                                //     const uid = {
                                                //         value: channelData[0]?.uid, label: channelData[0]?.name,
                                                //         channelAllData: channelData[0]
                                                //     }
                                                //     dispatch({ type: 'CHANNEL_UID', payload: uid })
                                                //     navigate(`${process.env.PUBLIC_URL}/dashboard`);
                                                //     window.location.reload();
                                                // })
                                                //navigate(`${process.env.PUBLIC_URL}/dashboard`);
                                                window.location.reload();
                                            });
                                        }
                                    }}
                                    partnerId={"Nwc03TPA"}
                                    className="connectBtn mt-1"
                                    label="Connect Now"
                                    type="button"
                                />
                            </span>
                        </div>
                    </div>
                }
                <div className='row mt-4'>
                    <h6 className='headerOne'>Experience the Real</h6>
                    <div className='borderLine'></div>
                    <div className='expRealContainer row p-0'>
                        <div className='col-md-8 col-xs-12'>

                            <div>
                                <div className='headTitle'>
                                    <p>To Embark on Interactive Messaging, Connect Your WhatsApp Business Number</p>
                                </div>
                                <div className='content'>
                                    Empower engaging conversations by integrating your WhatsApp Number. This connection empowers you to initiate conversations, promptly address inquiries, and readily receive messages from your esteemed contacts.
                                </div>
                                {businessId?.includes('1018') ?
                                    <FacebookOnboarding /> :
                                    // <ConnectButton
                                    //     queryParameters={{ plan_selection: 'basic' }}
                                    //     callback={(obj: any) => {
                                    //         const storedBusinessId = localStorage.getItem('businessId');
                                    //         if (storedBusinessId !== null) {
                                    //             const payload = {
                                    //                 "uid": storedBusinessId,
                                    //                 "client": obj.client,
                                    //                 "channelId": obj.channels,
                                    //                 "revoked": obj?.revokedChannels ? obj.revokedChannels : []
                                    //             };
                                    //             createChannel(payload).then(res => {
                                    //                 navigate(`${process.env.PUBLIC_URL}/dashboard`);
                                    //                 window.location.reload();
                                    //             });
                                    //         }
                                    //     }}
                                    //     partnerId={"Nwc03TPA"}
                                    //     className="connectBtn"
                                    //     label="Connect Your Own Number"
                                    //     type="button"
                                    //     ></ConnectButton>
                                    <span className='connectBtn'>
                                        <ChannelConnect component="trialDashboard" /></span>
                                }
                            </div>
                        </div>
                        <div className='col-md-4 col-xs-12'>
                            <img src='/images/connect-pep.png' width={300} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Welcome;


// <>
//     {business?.freeTrial && (
//         <Link to={`/subscription`}>
//             <Button
//                 color='primary'
//                 className="connectBtn mt-2 float-right"
//             >
//                 Subscribe Now
//             </Button>
//         </Link>
//     )}
// </>

{/* {!business?.freeTrial ?
<div>
    <div className='headTitle'>
        <p>To Embark on Interactive Messaging,</p>
        <p>Connect Your WhatsApp Business Number</p>
    </div>
    <div className='content'>
        Empower engaging conversations by integrating your WhatsApp Number. This connection empowers you to initiate conversations, promptly address inquiries, and readily receive messages from your esteemed contacts.
    </div>
</div>
:
<div className='content'>
    <h6 className='headerOne'>Ready for More?</h6>
    <div className='borderLine'></div>
    Subscribe Today to Unlock Broadcasts, Template Creation, and <br /> Premium Features with BizMagnets!
</div>
} */}