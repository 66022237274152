import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'react-bootstrap';

import { useAppSelector } from '../../../hooks';
import Node from './node';
import { IChatModelItem } from '../editor/openAi';
import axios from '../../../../utils/axios';

function OpenAi(props: any) {
    const businessUid = useAppSelector(state => state.meta.businessUid);
    const openAiListQuery = useQuery({
        queryKey: ['open-ai'],
        queryFn: () => {
            return axios.post<IChatModelItem[]>("openai/getAll", {
                businessUid,
                limit: 10,
                page: 0
            })
        }
    })

    let content = <Spinner />

    if (openAiListQuery.isError) {
        content = <span className='text-danger'>Something went wrong.</span>
    } else if (openAiListQuery.data) {
        const myGpt = openAiListQuery.data.data.find(item => item.uid === props.data.gptData.openAiUid);
        if (myGpt) {
            content = (
                <div>
                    <div className='fw-500'>{myGpt.name}</div>
                    <small className='text-muted'>{myGpt.systemContant}</small>
                </div>
            );
        } else {
            content = <span className='text-danger'>Invalid GPT.</span>
        }
    }

    return (
        <Node {...props} label='Open AI' content={content} />
    );
}

export default OpenAi;