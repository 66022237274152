import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { IEditorProps } from '../../types';
import { useElmentEditor } from '../../hooks';
import BodyInput from '../../bodyInput';
import OptionsInput, { IListOption } from './optionsInput';
import EditorCaption from '../../editorCaption';
import { askKeywordsIcon } from '../../../../../icons';
import { uuidv4 } from '../../../../../utils/uuid';
import AdvancedSettings from '../advancedSettings';
import VariableInputLabel from '../../variableInputLabel';
import { SelectVariable } from '../../setVariable';
import { IStorageVariable } from '../../../../../entity/Variable';
import DynamicOptionType from '../../dynamicOptionType';

interface FormData {
    text: string;
    attempt: number;
    options: IListOption[];
    errorMessage: string;
    endFlow: boolean;
    storageVariable: { name: string, type: 'TEXT' };
    dynamic: boolean;
    dynamicFields: {
        iterateVariable: IStorageVariable<'ARRAY'>,
        idPath: string,
        titlePath: string,
    } | null;
};

const AskKeywordEditor: React.FC<IEditorProps> = props => {
    const [dynamic, setDynamic] = useState(false)
    const [formData, setFormData] = useState<FormData>({
        text: '',
        attempt: 1,
        endFlow: true,
        options: [
            {
                id: uuidv4(),
                order: 1,
                title: '',
                keyword: [`${1}`, ''],
            }
        ],
        errorMessage: "",
        storageVariable: {
            name: '',
            type: 'TEXT'
        },
        dynamic: false,
        dynamicFields: null
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'ask_keyword_options',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const optionSchema = yup.object().shape({
        id: yup.string().nullable(),
        title: yup.string().required('Option text is required'),
        keyword: yup.array().of(yup.string().required('Keyword is required')).min(1, 'At least one keyword is required')
    });
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        errorMessage: yup.string().notRequired(),
        text: yup.string().required('Question Text is a required field').test('is-double-space', 'Question Text is a required field', value => value !== '  '),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        options: dynamic ? yup.array() : yup.array().of(optionSchema).min(1, 'At least one option is required'),
        storageVariable: storageVariableSchema,
        dynamic: yup.boolean(),
        dynamicFields: dynamic ? yup.object().shape({
            iterateVariable: yup.object().shape({
                name: yup.string().required('Iterate Variable is required'),
            }),
            idPath: yup.string().required('Path to ID is required'),
            titlePath: yup.string().required('Path to Title is required'),
        }).required() : yup.object().nullable()
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => {
                values.text = values.text.trimEnd();
                saveElementChanges(values);
            }}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                useEffect(() => {
                    setDynamic(values.dynamic)
                    if (values.dynamic && !values.dynamicFields) {
                        setFieldValue('dynamicFields', formData.dynamic ? formData.dynamicFields : {
                            iterateVariable: { name: '' },
                            idPath: '',
                            titlePath: ''
                        })
                    }
                }, [values.dynamic]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Ask Keyword' icon={<img style={{ width: 16 }} alt='' src={askKeywordsIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className="mb-3">
                                <BodyInput
                                    name='text'
                                    label={<>Question Text<span className="required"></span></>}
                                    placeholder="Enter your text here"
                                    value={values.text}
                                    isInvalid={(touched.text && errors.text) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.text && !errors.text}
                                    error={errors.text}
                                    limit={4096}
                                />
                                {(touched.text && errors.text) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.text}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <DynamicOptionType>
                                <div className="dimmed mb-2">Maximum 10 keywords can be added</div>
                                <OptionsInput
                                    options={values.options}
                                    error={errors.options}
                                    isInvalid={(touched.options && errors.options) ? true : false}
                                    isValid={(touched.options && !errors.options) ? true : false}
                                    touched={touched.options}
                                    BodyText={values.text}
                                    values={values}
                                />
                            </DynamicOptionType>
                            <AdvancedSettings />
                            <VariableInputLabel
                                error={(
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.storageVariable?.name}
                                    </Form.Control.Feedback>
                                )}>
                                <SelectVariable
                                    placeholder="Create or Select variable"
                                    name="storageVariable"
                                    value={values.storageVariable.name}
                                    onChange={handleChange}
                                    type="TEXT"
                                />
                            </VariableInputLabel>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AskKeywordEditor;