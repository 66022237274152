import React,{Fragment} from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Offcanvas } from "react-bootstrap";

const RightSidebar = (props: any) => {
    const sidebarStyle = {
        backgroundColor: "white",
        width: props.width, // Set the desired background color here
        right: "0px"
    };
    return (
        // <div className="sidebar rightSidebar" style={sidebarStyle}>
        //     <div className="headerContainer">
        //         {/* <FontAwesomeIcon onClick={props.handleCancel} className="rightArrow" icon={ faArrowLeft} /> */}
        //         <span className="h5">{props.title}</span>
        //         <FontAwesomeIcon onClick={props.handleCancel} className="rightArrow" icon={faTimesCircle} />
        //     </div>
        //     {props.children}
        // </div>
        <Fragment>
        <Offcanvas 
        show={true} 
        onHide={props.handleCancel} className='sidebar rightSidebar' placement="end" style={sidebarStyle}>
              <div className='headerContainer'>
                   <span className="h5">{props.title}</span>
                  <FontAwesomeIcon onClick={props.handleCancel} className="rightArrow" icon={faTimesCircle} />
          </div>
          {props.children}
        </Offcanvas>        
        </Fragment>
    )
};

export default RightSidebar;