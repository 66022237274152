import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Input } from 'reactstrap';
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import { BsCalendar } from 'react-icons/bs';
import { Overlay, Popover } from 'react-bootstrap';
import DateRangePicker from "react-daterange-picker";
import BizTable from '../../common/BizTable';
import { useSelector } from 'react-redux';
import { getAllReportData } from '../../services/geofenceService';
import '../geofenceTracking/geofenceTrack.scss';
import Avatar from "react-avatar";
import TooltipReference from '../../common/tooltip';
import { sliceText } from '../../common/sliceText';
import SearchInput from "../../common/searchComponent";

const moment = extendMoment(originalMoment);
interface fieldforceprops {

}

enum DateFilterType {
    Past7Days = "Past7Days",
    Past30Days = "Past30Days",
    Past90Days = "Past90Days",
    ThisWeek = "ThisWeek",
    PreviousWeek = "PreviousWeek",
    ThisMonth = "ThisMonth",
    PreviousMonth = "PreviousMonth",
    CustomRange = "CustomRange",
}

const FieldForceReport: React.FC<fieldforceprops> = (props) => {
    const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
    const today = moment().clone().startOf("day");
    const [dateRangeValue, setDateRangeValue] = useState(
        moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
        )
    );
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datepickertarget = useRef(null);
    const [allData, setAllData] = useState<any[]>([]);
    const channelUid = useSelector((state: any) => state.cartreducer.channelUid?.value);
    const [totalCount, setTotalCount] = useState(0);
    const geoReportRef = useRef<NodeJS.Timeout | null>(null);
    const [searchTerm, setSearchTerm] = useState<string | null>();

    const getStartDate = (
        subtractDays: number,
        unit: moment.unitOfTime.DurationConstructor = "days"
    ) => {
        return today.clone().subtract(subtractDays, unit).startOf(unit);
    };

    const mobileData = (cell: any, row: any) => {
        const numberMaskString = localStorage.getItem('numberMask');
        const numberMasking = useSelector((state: any) => state.cartreducer.numberMasking);
        const numberMask = numberMaskString === 'true' || numberMasking;
        return (
            <div className={`${numberMask ? "numberMasking_Blur" : ''}`}>
                {row?.countryCode
                    ? "+" + row.countryCode + " " + cell?.slice(row.countryCode?.length)
                    : cell?.slice(row.countryCode?.length)}
            </div>
        )

    }

    const columns = [
        {
            Header: (
                <input
                    type="checkbox"
                    hidden={true}
                />
            ),
            accessor: "checkbox",
            sticky: "left",
        },
        {
            Header: "Contact Name",
            accessor: "name",
            Cell: ({ cell, row }: any) => (
                <div>
                    <span className='mr-1'>
                        <Avatar
                            fgColor="#4c5a80"
                            name={cell?.value}
                            round
                            size="27"
                            textSizeRatio={2.5}
                        />
                    </span>
                    <TooltipReference placement='right' content={cell.value} tooltipId={`geoContact-${row.id}`}>
                        <span className='pl-1'>{sliceText(cell.value, 12)}</span>
                    </TooltipReference>
                </div>
            ),
        },
        {
            Header: "Contact Number",
            accessor: "mobileNumber",
            Cell: ({ cell, row }: any) =>
                mobileData(cell.value, row.original)
        },
        {
            Header: "Marked Time",
            accessor: "messageTime",
            Cell: ({ cell, row }: any) =>
                cell.value ? moment(cell.value).format("DD MMM hh.mm A") : '-',
        },
        {
            Header: "Location Name",
            accessor: "location",
            Cell: ({ cell, row }: any) =>
            (
                <a
                    href={`https://www.google.com/maps?q=${row?.original?.reportLatitude},${row?.original?.reportLongitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='geoLocation'
                >
                    {row?.original?.location
                        ? row.original.location
                        : `${row.original?.reportLatitude},${row.original?.reportLongitude}`}
                </a>

            )
        },
        {
            Header: "Nearby Geofence",
            accessor: "message",
            Cell: ({ cell, row }: any) => {
                return (
                    <>
                        {row?.original?.geofence?.siteName ? (
                            <a
                                href={`https://www.google.com/maps?q=${row?.original?.geofence?.latitude},${row?.original?.geofence?.longitude}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="geoLocation"
                            >
                                {row?.original?.geofence?.siteName}
                            </a>
                        ) : (
                            <span>-</span>
                        )}
                    </>
                );
            }
        }]

    const changeDateFilterHandler = (event: any) => {
        const filter = event.target.value as DateFilterType;
        //setSearchTerm("");
        switch (filter) {
            case DateFilterType.Past7Days:
                setDateRangeValue(
                    moment.range(
                        today.clone().subtract(7, "days").startOf("day"),
                        today.endOf("day")
                    )
                );
                setShowDatePicker(false);
                break;

            case DateFilterType.Past30Days:
                setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
                setShowDatePicker(false);
                break;

            case DateFilterType.Past90Days:
                setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
                setShowDatePicker(false);
                break;

            case DateFilterType.ThisWeek:
                setDateRangeValue(
                    moment.range(today.clone().startOf("week"), today.endOf("day"))
                );
                setShowDatePicker(false);
                break;

            case DateFilterType.PreviousWeek:
                setDateRangeValue(
                    moment.range(
                        getStartDate(1, "week"),
                        getStartDate(1, "week").endOf("week")
                    )
                );
                setShowDatePicker(false);
                break;

            case DateFilterType.ThisMonth:
                setDateRangeValue(
                    moment.range(today.clone().startOf("month"), today.endOf("day"))
                );
                setShowDatePicker(false);
                break;

            case DateFilterType.PreviousMonth:
                setDateRangeValue(
                    moment.range(
                        getStartDate(1, "month"),
                        getStartDate(1, "month").endOf("month")
                    )
                );
                setShowDatePicker(false);
                break;

            case DateFilterType.CustomRange:
                setShowDatePicker(true);
                break;

            default:
                break;
        }
        setDateFilter(filter);
    };

    const getReportData = useCallback(async (pageIndex: number,
        pageSize: number,
        searchText: string | null,
        currentPage: number): Promise<{ data: any; totalPages: number }> => {
        if (channelUid) {
            if (dateRangeValue && searchTerm !== undefined) {
                const payload = {
                    "fromDate": dateRangeValue?.start.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    "toDate": dateRangeValue?.end.utc().format("YYYY-MM-DDT23:59:59.000[Z]"),
                    "search": searchTerm,
                    "page:": currentPage,
                    "limit": pageSize
                }
                const response = await getAllReportData(channelUid, payload);
                if (response) {
                    setAllData(response.geofenceReport);
                    setTotalCount(response.count);
                    return { data: response.geofenceReport, totalPages: response.count }
                }
                else {
                    setAllData([]);
                    setTotalCount(0);
                    return { data: [], totalPages: 0 }
                }
            }
            else {
                setAllData([]);
                setTotalCount(0);
                return { data: [], totalPages: 0 }
            }
        }
        else {
            setAllData([]);
            setTotalCount(0);
            return { data: [], totalPages: 0 }
        }
    }, [dateRangeValue, searchTerm, channelUid])

    const onSearchChange = (searchTerm: string) => {
        const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
        setSearchTerm(searchTermCheck);
    };

    return (
        <div className="row campaignTableHeader">
            <div className="col-md-3 col-12">
                <h6>Geofence Report</h6>
            </div>
            <div className="col-md-9 col-12 rightSideCon">
                <div className="col-3">
                    <div className="">
                        <Input
                            className="dateFilter"
                            type="select"
                            size={1}
                            value={dateFilter}
                            style={{ fontFamily: '"Public Sans", sans-serif' }}
                            onChange={changeDateFilterHandler}
                        >
                            <option value={DateFilterType.Past7Days}>
                                Past 7 days
                            </option>
                            <option value={DateFilterType.Past30Days}>
                                Past 30 days
                            </option>
                            <option value={DateFilterType.Past90Days}>
                                Past 90 days
                            </option>
                            <option value={DateFilterType.ThisWeek}>This week</option>
                            <option value={DateFilterType.PreviousWeek}>
                                Previous week
                            </option>
                            <option value={DateFilterType.ThisMonth}>
                                This month
                            </option>
                            <option value={DateFilterType.PreviousMonth}>
                                Previous month
                            </option>
                            <option value={DateFilterType.CustomRange}>
                                Custom range
                            </option>
                        </Input>
                    </div>
                </div>
                <div
                    className="form-control calenderInput col-3 ps-1 pe-0"
                    ref={datepickertarget}
                    onClick={() => {
                        setShowDatePicker((current) => !current);
                    }}
                >
                    <BsCalendar />
                    <span className="value ml-2">
                        {dateRangeValue.start.format("DD/MM/YYYY")}
                        {" - "}
                        {dateRangeValue.end.format("DD/MM/YYYY")}
                    </span>
                </div>
                <Overlay
                    target={datepickertarget.current}
                    show={showDatePicker}
                    placement="bottom"
                >
                    <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                        <DateRangePicker
                            value={dateRangeValue}
                            onSelect={(value: any) => {
                                //setSearchTerm("");
                                setDateRangeValue(
                                    moment.range(
                                        moment(value.start).startOf("day"),
                                        moment(value.end).endOf("day")
                                    )
                                );
                                setDateFilter(DateFilterType.CustomRange);
                                setShowDatePicker(false);
                            }}
                            singleDateRange={true}
                            maximumDate={moment().endOf("day")}
                        />
                    </Popover>
                </Overlay>

                <div className="col-6">
                    <SearchInput
                        component="community"
                        onSearchChange={onSearchChange}
                        placeHolder="Search Contact Name or Mobile Number"
                        isSearch={searchTerm ? true : false}
                    />
                    {/* <SearchBox
                        placeholder="Search Contact Name or Mobile Number"
                        value={search}
                        onChange={(event) => {
                            const { value } = event.target;
                            setSearch(value);
                            if (geoReportRef.current !== null) {
                                clearTimeout(geoReportRef.current);
                            }
                            geoReportRef.current = setTimeout(() => {
                                getReportData(0, 20, value, 0)
                            }, 300);
                        }}
                    /> */}
                </div>
            </div>
            <div className='mt-3'>
                <BizTable
                    columns={columns}
                    fetchData={getReportData}
                    counts={totalCount}
                    refetchData={allData}
                    tableName="GEOFENCEREPORT"
                />
            </div>
        </div>
    );
};

export default FieldForceReport;
