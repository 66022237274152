import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormGroup, Label, Button } from 'reactstrap';
import * as Yup from 'yup';
import {  createQr,qrgenerator } from '../../services/dashboardService';
import { toast } from '../../common/alert';
import './welcome.scss';

interface Props{
    activeChannel:any
    createdLink : (link:boolean)=>void;
    onClose:any;
}

const CreateLink: React.FC<Props> = ({activeChannel,createdLink,onClose}) => {
   
  // Initial form values
  const initialValues = {
    message: '',
    link: '',
  };

  const validationSchema = Yup.object({
    message: Yup.string().required('Message is required'),
    link: Yup.string().required('Link name is required'),
  });

  // Form submission handler
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const payload = {
        name: values.link,
        content: values.message,
      };

      if (activeChannel) {
        await createQr(activeChannel.value, payload);
        onClose(false)
        createdLink(false);
        toast('success', `Link created successfully`, 'success');
      } else {
        toast('error', `Please select a channel`, 'error');
      }
    } catch (error:any) {
      const {message} = error?.response?.data;
      toast('error', message);
    }
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault(); // Prevent space key from having its default behavior
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange
      >
        {(values:any) => (
          <Form className="formwidth">
            <FormGroup>
              <Label className="text-dark">Message</Label>
              <Field name="message">
                {({ field }: any) => (
                  <textarea
                    {...field}
                    placeholder="Send your WhatsApp message for enquiries"
                    className="textarea form-control pb-5 pt-2"
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                )}
              </Field>
              <span className='charCounts'>
              <span className="d-flex">
            <i>To add</i> 🙂 <i>press</i><div className="win">⊞ Win</div> + <div className="win">.</div>
              </span>
                <div>
                {values?.values?.message?.length}/50
                </div>
              </span>
              <ErrorMessage name="message" component="div" className="text-danger pt-1" />
            </FormGroup>

            <FormGroup>
              <Label className="text-dark">Customize your link</Label>
              <div className="d-flex">
                <Field
                  name="link"
                  placeholder="Enter link name"
                  className="form-control textarea ml-1"
                  onKeyDown={handleKeyDown}
                />
              </div>
              <ErrorMessage name="link" component="div" className="text-danger " />
            </FormGroup>

            <div className="d-flex justify-content-end">
            <Button onClick={()=>createdLink(false)} className='cancelButton p-1 w-25 me-2'>Cancel</Button>
              <Button type="submit" className="sendButton p-1 w-25">
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateLink;