import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import {
  Button,
  Form as FormBootstrap,
} from "react-bootstrap";
import { FormikHelpers } from 'formik';
import * as Yup from "yup";
import Select from "react-select";
import axios from "./../../utils/axios";
import { useSelector } from "react-redux";
import { toastAlert } from "../../common/alert";
import FlagInput from "../../common/flagInput";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobileNumber: Yup.string()
  .required("Mobile number is required")
  .min(7, "Mobile Number must be at least 7 numbers")
  .max(13, "Mobile number cannot exceed 13 characters"),
});
interface SelectOption {
  value: string;
  label: string;
}

interface FormValues {
  uid: string;
  firstname: string;
  email: string;
  mobileNumber: string;
  phoneCode:any;
  role:any;
  channels:[];
}
export interface IAppProps {
  memberEdit: any;
  handleCancel: () => void;
  fetchMembers:()=>void;
  roleOptions: any;
}
const EditMember: React.FC<IAppProps> = ({ memberEdit, handleCancel,fetchMembers, roleOptions }) => {
  const data = useSelector((state: any) => state.cartreducer.business)
  const roleMember = data?.role?.name
  const flag = useSelector((state: any) => state.cartreducer.flagData)
  const roleData = useSelector((state: any) => state.cartreducer.roleData)
  const channelData = useSelector((state: any) =>  state.cartreducer.channelData )


  const [roleSelected,setRole] = useState(memberEdit.role)
 
  const [roleOpts,getRoleOpts] = useState([])
  const [channelOpts,getChannelOpts] =useState([])
  const [country_Code, setFlagCode] = useState<string | undefined>();
  const channelList = memberEdit.channels.map((item:any)=> ({label:item.displayName,value:item.uid}))
  const [channelSelected,selectedchannel] = useState(channelList)


  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
      try {
        const memberId = data?.uid
        const channelVals = channelSelected.map((item:any)=> { return item.uid})
        const memberUpdatePayload = {
          "id": memberEdit?.id,
          "uid":  memberEdit?.uid,
          "business": data?.business,
          "name": values?.firstname,
          "email": values?.email,
          "mobileNumber": values?.mobileNumber,
          "country_code":country_Code,
          "role": values.role,
          "channelList": channelVals,
        } 
        const memberEditAllfields = {
          ...memberEdit,...memberUpdatePayload 
        };
        const response = await axios.patch(`member/${memberId}/update`, memberEditAllfields).then(() => {
          toastAlert('top-end', 'Member updated successfully', "success");
          fetchMembers();
           if (values.firstname && values.email) {
        handleCancel();
      }
        }).catch((err) => {
          toastAlert('top-end', err.response.data.message, "error");
        })
    
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const codeChanges = (e: any) => {
    setFlagCode(e)
  }
  
  const handleChange = (selectedOption:any) =>{
    setRole(selectedOption)
  }
  const channelChange=(channel:any)=>{
    const channelVal = channel.map((channelData:any)=>{return channelData})
    selectedchannel(channelVal);
  }
  const memberEditData =() =>{
      const country_code = memberEdit?.country_code
      getRoleOpts(roleOptions)
      let options;
            if (channelData?.length > 0) {
               options = channelData.map((channel:any) => ({
                label: channel.name,
                value: channel.uid,
              }));
           }
    
      getChannelOpts(options)
      if(country_code){
        setFlagCode(country_code)
      }
  }
  
  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };

  useEffect(()=>{
    memberEditData()
  },[])

  return (
    <div>
      <Formik
        initialValues={{
          uid: memberEdit.uid,
          firstname: memberEdit.name,
          mobileNumber: memberEdit.mobileNumber,
          email: memberEdit.email,
          phoneCode:country_Code,
          role:roleSelected,
          channels:channelSelected,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ isSubmitting,values,setFieldValue }) => (
          <Form className="centered-form-temp mt-2">
            <FormBootstrap.Group className="form-group">
              <FormBootstrap.Label className="pt-3">
                Name<span className='redstar_required'></span>
              </FormBootstrap.Label>
              <Field
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Enter your name"
                className="form-control"
                maxLength={60}
                onKeyDown={handleKeyDown}
              />
              <ErrorMessage
                name="firstname"
                component="div"
                className="text-danger error_alert"
              />
                <FormBootstrap.Label className="pt-3 ">
                Mobile Number<span className='redstar_required'></span>
              </FormBootstrap.Label>
              <div className="input-group inputBorder" >
                <div className="input-group-prepend  ms-1 me-3">
                  <FlagInput countryCode={values.phoneCode?.includes('+')? values.phoneCode : '+' + values.phoneCode}  codeChange={codeChanges} />
                </div>
                <Field type="text" name="mobileNumber" id="mobileNumber" disabled = {true} placeholder="Enter your mobile number" className="form-control"
                  value={values.mobileNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = e.target.value.replace(/[^0-9]/g, '');
                    if (value?.length <= 13) {
                        setFieldValue("mobileNumber", value);
                    }
                }}
                />
              </div>
              <ErrorMessage
                name="mobileNumber"
                component="div"
                className="text-danger error_alert"
              />
              <FormBootstrap.Label className="pt-3 redstar_required">
                Email
              </FormBootstrap.Label>
              <Field
                type="text"
                name="email"
                id="email"
                disabled = {true}
                placeholder="Enter your email"
                className="form-control  "
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger error_alert"
              />
              <FormBootstrap.Label className="pt-3 redstar_required" >
                Role
              </FormBootstrap.Label>
              <Select
                name="role"
                className={roleMember === 'MEMBER' ? 'disabled-data':''}
                options={roleOpts.map((role:any)=>({value:role?.name,label:role?.name,id:role?.id,
                  name:role?.name
                }))}
                value={{
                  value:  values?.role?.name ,
                  label:  values?.role?.name 
                }}
                onChange={(selectedOption:any) => {
                    handleChange(selectedOption);
                    setFieldValue('role', selectedOption);
                }}
                isDisabled={roleMember === 'MEMBER' ? true : false}
            />
            <FormBootstrap.Label className="pt-3" >
                Channels
              </FormBootstrap.Label>
              <Field name="channels">
            {({ field, form }: any) => (
              <Select
              {...field}
                isMulti
                name="channels"
                className={roleMember === 'MEMBER' ? 'disabled-data':''}
                options={channelOpts&& channelOpts.map((channel:any)=>
                  ({value:channel.value,
                    label:channel?.label,
                    uid:channel?.value,
                }))}
                onChange={(channel: any) => {
                  channelChange(channel);
                  setFieldValue(
                    "channels",
                    channel?.value
                  );
                }}
                isDisabled={roleMember === 'MEMBER' ? true : false}
            />
            )}
          </Field>
            </FormBootstrap.Group>
            <div className="addCancelBtn">
              <Button
                variant=""
                type="button"
                className="cancelButton me-2"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant=""
                type="submit"
                className="sendButton"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default EditMember;