import React, { useState, useRef, useEffect } from 'react';
import { InputGroup } from 'react-bootstrap';
import './common.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

interface searchProps {
    component: string,
    onSearchChange: (term: string) => void,
    placeHolder:string,
    isSearch?: boolean
}

const SearchInput: React.FC<searchProps> = ({ component, onSearchChange, placeHolder, isSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchFocussed, setSearchFocussed] = useState(false);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if(!isSearch) {
            setSearchTerm('');
            onSearchChange('');
        }
    },[isSearch])

    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const newValue = event.target.value;
    //     setSearchTerm(newValue);
    //     // onSearchChange(newValue);
    //     if (timeoutId) {
    //         clearTimeout(timeoutId); // Clear the previous timeout if exists
    //     }

    //     // Set a new timeout to trigger the search after 500 milliseconds (adjust as needed)
    //     timeoutId = setTimeout(() => {
    //         onSearchChange(newValue);
    //     }, 1000 * 1);
    // };

    const handleClear = () => {
        setSearchTerm('');
        onSearchChange('');
    };

    return (
        <InputGroup className={`searchDesign rounded ${searchFocussed ? "changeBorderColor" : ""
            }`}>
            <InputGroup.Text className="searchIcon removeBorder iconsColor">
                <FontAwesomeIcon icon={faSearch} aria-hidden="true" />
            </InputGroup.Text>
            <input
                className="form-control removeBorder"
                // placeholder={component === 'api' ? "Search API Name" : (component === 'tag' ? "Search Tags" : component === 'company' ? 'Search Company' : "Search Contacts")}
                type="search"
                placeholder={placeHolder}
                onFocus={() => setSearchFocussed(true)}
                onBlur={() => setSearchFocussed(false)}
                onChange={(e) => {
                    const newValue = e.target.value;
                     const data = component === 'paymentHistory' ? newValue?.replace(/\D/g, '') : newValue;
                    setSearchTerm(data);
                    if (timeoutRef.current !== null) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => {
                        onSearchChange(data);
                    }, 500);
                }
                }
                value={searchTerm}
            />
            <InputGroup.Text className="searchIcon removeBorder iconsColor">
                {searchTerm && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        // className="fa fa-times"
                        aria-hidden="true"
                        onClick={handleClear}
                    />
                )}
            </InputGroup.Text>
        </InputGroup>
    );
};

export default SearchInput;
