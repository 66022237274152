import React from "react";

import styles from "./flowElementCategory.module.scss";

interface FlowElementCategoryProps {
    title: string;
    description: string;
    icon: React.ReactNode;
    children: React.ReactNode;
    expanded: string | null;
    setExpanded: (val: string | null) => void;
    searchMode: boolean;
}

const FlowElementCategory: React.FC<FlowElementCategoryProps> = props => {
    const cssClasses = [styles.flowElementCategory];
    const currentExpanded = props.expanded && props.expanded === props.title;
    if (props.searchMode) {
        cssClasses.push(styles.animOff);
    }
    if (props.searchMode || currentExpanded) {
        cssClasses.push(styles.expanded);
    }

    const toggleExpandHandler = () => {
        props.setExpanded(props.expanded && props.expanded === props.title ? null : props.title);
    }
    return (
        <div className={cssClasses.join(" ")} onClick={() => {
            if (!props.searchMode && !currentExpanded) {
                toggleExpandHandler();
            }
        }}>
            <div className={styles.header} onClick={toggleExpandHandler}>
                <div className={styles.icon}>{props.icon}</div>
                <div className={styles.caption}>
                    <span className={styles.title}>{props.title}</span>
                    <span className={styles.description}>{props.description}</span>
                </div>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default FlowElementCategory;