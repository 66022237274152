import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik'
import DatePicker from 'react-date-picker';
import * as yup from 'yup';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import ValidationSettings from './validationSettings';
import BodyInput from '../bodyInput';
import VariableInputLabel from '../variableInputLabel';
import { SelectVariable } from '../setVariable';
import EditorCaption from '../editorCaption';
import { BsCalendarDate } from 'react-icons/bs';
import AdvancedSettings from './advancedSettings';

function formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

function stringToDate(str: string): Date {
    const [day, month, year] = str.split('-');
    return new Date(`${year}-${month}-${day}`);
}


interface FormData {
    text: string;
    errorMessage: string;
    endFlow: boolean;
    attempt: number;
    validation: { minDate: string, maxDate: string };
    storageVariable: { name: string, type: 'DATE' }
};

const AskDateEditor: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [formData, setFormData] = useState<FormData>({
        text: '',
        attempt: 1,
        endFlow: true,
        errorMessage: '',
        validation: {
            minDate: '',
            maxDate: '',
        },
        storageVariable: {
            name: '',
            type: 'DATE'
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'ask_type/ask_date',
        data: formData
    }, props);
    useEffect(() => {
        init((data: any) => {
            let newData = { ...data };
            let newValidation = {
                minDate: '',
                maxDate: '',
            };
            if (newData.validation) {
                if ('minDate' in newData.validation && newData.validation.minDate) {
                    newValidation.minDate = `${newData.validation.minDate}`;
                }
                if ('maxDate' in newData.validation && newData.validation.maxDate) {
                    newValidation.maxDate = `${newData.validation.maxDate}`;
                }
            }
            if (!newData.storageVariable) {
                newData.storageVariable = {
                    name: '',
                    type: 'DATE'
                };
            }
            newData.validation = newValidation;
            setFormData(newData);
        });
    }, []);

    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    const validationSchema = yup.object().shape({
        minDate: yup.string()
            .matches(dateRegex, 'Invalid date')
            .test('minDate', 'Minimum Date must be smaller than Maximum date', function (minDate) {
                const maxDate = this.parent.maxDate;
                if (minDate && maxDate) {
                    const minDateObj = new Date(minDate);
                    const maxDateObj = new Date(maxDate);

                    if (minDateObj > maxDateObj) {
                        return false;
                    }
                }
                return true;
            }),
        maxDate: yup.string()
            .matches(dateRegex, 'Invalid date')
            .notRequired()
            .test('maxDate', 'Maximum date must be greater than Minimum date', function (maxDate) {
                const minDate = this.parent.minDate;
                if (minDate && maxDate) {
                    const minDateObj = new Date(minDate);
                    const maxDateObj = new Date(maxDate);
                    if (maxDateObj < minDateObj) {
                        return false;
                    }
                }
                return true;
            })
    });
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        text: yup.string().required('Question Text is a required field').test('is-double-space', 'Question Text is a required field', value => value !== '  '),
        errorMessage: yup.string().notRequired(),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        validation: validationSchema,
        endFlow: yup.boolean().required("Action on Invalid Response required"),
        storageVariable: storageVariableSchema
    });

    type ValidationObject = {
        minDate: string;
        maxDate: string;
    };
    function isValidation(validation?: ValidationObject | null): boolean {
        if (!validation) {
            return false;
        }
        return Object.values(validation).some(value => value !== '');
    }


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        {!props.create ? (
                            <EditorCaption
                                onHide={props.onClose}
                                caption='Ask Date'
                                icon={<BsCalendarDate />}
                            />
                        ) : null}
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='text'
                                    value={values.text}
                                    isInvalid={(touched.text && errors.text) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.text && !errors.text}
                                    label={<>Question Text<span className="required"></span></>}
                                    placeholder='Hey, How can you help'
                                    limit={4096}
                                />
                                {(touched.text && errors.text) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.text}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <AdvancedSettings />
                                <ValidationSettings
                                    initiallyOpen={isValidation(values.validation)}
                                    onCancel={() => {
                                        setFieldValue('validation', {
                                            minDate: '',
                                            maxDate: '',
                                        });
                                    }}
                                >
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Minimum Date</Form.Label>
                                        <InputGroup>
                                            <DatePicker
                                                name='validation.minDate'
                                                dayPlaceholder="DD"
                                                monthPlaceholder="MM"
                                                yearPlaceholder="YYYY"
                                                onChange={date => {
                                                    if (date instanceof Date) {
                                                        const formattedDate = formatDate(date);
                                                        setFieldValue('validation.minDate', formattedDate);
                                                    } else {
                                                        setFieldValue('validation.minDate', '');
                                                    }
                                                }}
                                                value={
                                                    values.validation.minDate && values.validation.minDate.trim() !== ''
                                                        ? stringToDate(values.validation.minDate)
                                                        : null
                                                }
                                                calendarIcon={null}
                                                className='form-control'
                                            />
                                        </InputGroup>
                                        {errors.validation?.minDate && touched.validation?.minDate ? (
                                            <div className='invalid-feedback' style={{ display: "block" }}>
                                                {errors.validation.minDate}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Maximum Date</Form.Label>
                                        <InputGroup>
                                            <DatePicker
                                                dayPlaceholder="DD"
                                                monthPlaceholder="MM"
                                                yearPlaceholder="YYYY"
                                                name='validation.maxDate'
                                                onChange={date => {
                                                    if (date instanceof Date) {
                                                        const formattedDate = formatDate(date);
                                                        setFieldValue('validation.maxDate', formattedDate);
                                                    } else {
                                                        setFieldValue('validation.maxDate', '');
                                                    }
                                                }}
                                                value={
                                                    values.validation.maxDate && values.validation.maxDate.trim() !== ''
                                                        ? stringToDate(values.validation.maxDate)
                                                        : null
                                                }
                                                calendarIcon={null}
                                                className='form-control'
                                            />
                                        </InputGroup>
                                        {errors.validation?.maxDate && touched.validation?.maxDate ? (
                                            <div className='invalid-feedback' style={{ display: "block" }}>
                                                {errors.validation.maxDate}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </ValidationSettings>
                            </Form.Group>
                            <VariableInputLabel
                                error={(
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.storageVariable?.name}
                                    </Form.Control.Feedback>
                                )}>
                                {/* <Form.Control
                                    name='storageVariable.name'
                                    value={values.storageVariable.name}
                                    isInvalid={(touched.storageVariable?.name && errors.storageVariable?.name) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.storageVariable?.name && !errors.storageVariable?.name}
                                /> */}
                                <SelectVariable
                                    placeholder="Create or Select variable"
                                    name="storageVariable"
                                    value={values.storageVariable.name}
                                    onChange={handleChange}
                                    type="DATE"
                                />
                            </VariableInputLabel>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'  disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AskDateEditor;