import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import BodyInput from '../bodyInput';


interface FormData {
    text: string;
};

function AddNotesEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({ text: '' });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'actions/notes',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({ 
        text: yup.string().required('Text is required')
    });


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, values, touched, errors, setValues, setFieldValue, handleChange }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Add Notes'  icon={<svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.9257 10.5391L17.3566 8.92914C17.8599 7.04997 18.1124 6.11081 17.9232 5.29747C17.7733 4.65539 17.4363 4.07219 16.9549 3.62164C16.3457 3.05081 15.4057 2.79914 13.5266 2.29581C11.6474 1.79164 10.7074 1.53997 9.89491 1.72914C9.25282 1.87907 8.66962 2.21605 8.21908 2.69747C7.72991 3.21914 7.47491 3.98331 7.09575 5.37164L6.89241 6.12581L6.46158 7.73581C5.95741 9.61497 5.70575 10.5541 5.89491 11.3675C6.04484 12.0096 6.38183 12.5928 6.86325 13.0433C7.47241 13.6141 8.41241 13.8658 10.2916 14.37C11.9849 14.8233 12.9157 15.0725 13.6782 14.9783C13.7616 14.9683 13.8432 14.9533 13.9232 14.935C14.5651 14.7855 15.1483 14.4491 15.5991 13.9683C16.1699 13.3583 16.4216 12.4183 16.9257 10.5391Z"
                  stroke="#5d8ca6"
                  stroke-width="1.25"
                />
                <path
                  d="M13.6769 14.9783C13.5027 15.5119 13.1966 15.9929 12.7869 16.3766C12.1778 16.9475 11.2378 17.1991 9.35861 17.7025C7.47944 18.2058 6.53944 18.4583 5.72694 18.2683C5.08495 18.1186 4.50176 17.7819 4.05111 17.3008C3.48028 16.6916 3.22778 15.7516 2.72444 13.8725L2.29361 12.2625C1.78944 10.3833 1.53778 9.44329 1.72694 8.63079C1.87687 7.9887 2.21386 7.4055 2.69528 6.95495C3.30444 6.38412 4.24444 6.13246 6.12361 5.62829C6.47861 5.53329 6.80111 5.44662 7.09444 5.37079"
                  stroke="#5d8ca6"
                  stroke-width="1.25"
                />
                <path
                  d="M9.81156 8.33325L13.8366 9.41158M9.16406 10.7483L11.5791 11.3949"
                  stroke="#5d8ca6"
                  stroke-width="1.25"
                  stroke-linecap="round"
                />
              </svg> } />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>

                            <BodyInput
                                name='text'
                                value={values.text}
                                isInvalid={(touched.text && errors.text) ? true : false}
                                onChange={handleChange}
                                isValid={touched.text && !errors.text}
                                error={errors.text}
                                label={<>Text<span className="required"></span></>}
                                placeholder='Add Notes here...'
                                limit={4096}
                            />

                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}> 
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
export default AddNotesEditor;