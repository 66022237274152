import { toast } from "../common/alert";
import axios from "../utils/axios";

export const createRAG = async (requestBody: any) : Promise<any> => {
    try {
        const response = await axios.post(`openai/RAG/create`, requestBody);
        toast('success', "Knowledge Folder created Successfully")
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};

export const getAllRAGFolder = async (requestBody: any) : Promise<any> => {
    try {
        const response = await axios.post(`openai/RAG/getAll`, requestBody);
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};

export const uploadRAGDocument = async (uid: string, files: any): Promise<any> => {
    try {
        const response = await axios.post(`/openai/RAG/${uid}/upload/documnts`, files, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        toast('success', "Files uploaded successfully");
        return response.data;
    } catch (error: any) {
        toast("error", error.response?.data?.message || error.message || "Failed to upload files");
        return null;
    }
};

export const updateRAG = async (uid: string, requestBody: any) : Promise<any> => {
    try {
        const response = await axios.patch(`openai/RAG/${uid}/update`, requestBody);
      //  toast('success', "Knowledge Folder updated Successfully")
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};

export const deleteRAG = async (uid: string) : Promise<any> => {
    try {
        const response = await axios.delete(`openai/RAG/${uid}/delete`);
        toast('success', "Knowledge folder deleted successfully")
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};

export const trainRAG = async (uid: string) : Promise<any> => {
    try {
        const response = await axios.post(`openai/RAG/${uid}/training`);
        toast('success', "Knowledge Folder updated Successfully")
        return response.data;
    } catch (error: any) {
        toast("error", error.message);
    }
};



export const getRAG = async (uid: string) : Promise<any> => {
    try {
        const response = await axios.post(`openai/RAG/${uid}`);
      //  toast('success', "Knowledge Folder created Successfully")
        return response.data;
    } catch (error: any) {
       // toast("error", error.message);
    }
};

