import { Button, Modal } from 'react-bootstrap';

import styles from "./createFlow.module.scss";
import * as flowService from '../../services/flows';
import { toast } from '../../../common/alert';

interface Props {
    uid: string;
    show: boolean;
    flowName: string;
    onHide: () => void;
    onDelete: () => void;
};

const DeleteFlow: React.FC<Props> = (props: Props) => {
    async function deleteFlowHandler() {
        await flowService.deleteFlow(props.uid);
        toast("success", "Flow deleted successfully");
        props.onHide();
        props.onDelete();
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className={styles.createFlow}>
            <Modal.Header closeButton closeVariant="dark">
                <Modal.Title>Delete Flow - {props.flowName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this flow?
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-dark' style={{ width: "unset" }} onClick={props.onHide}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={deleteFlowHandler}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteFlow;