import { useEffect, useRef, useState, createContext, useContext } from 'react';
import { Form, Offcanvas, Button, Spinner, FormControl } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import { QueryClient, useQuery } from '@tanstack/react-query';
import Select, { Props } from 'react-select';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";
import {
    ContactsGetAll,
    previewTemplate,
} from "../../../../services/campaignService";
import styles from './sendTemplate.module.scss';
import { IEditorProps } from './types';
import mainState from '../../../../redux/store/store';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import templateIcon from '../../../assets/icons/template.svg';
import axios from '../../../../utils/axios';
import scanForVariables from '../../../utils/scanForVariables';
import { useAppSelector } from '../../../hooks';
import { isValidLink } from './fileInput';
import TemplateAdvancedSettings from './templateAdvancedsettings';
import { toast } from '../../../../common/alert';
import { companyContactFilters } from '../../../../services/contactMemberService';

interface ITemplateHeaderComponent {
    type: 'HEADER';
    text: string;
    format: 'TEXT' | 'VIDEO' | 'IMAGE';
    example?: {
        header_handle: string[];
    };
};

interface ITemplateFooterComponent {
    type: 'FOOTER';
    text: string;
};

interface ITemplateBodyComponent {
    type: 'BODY';
    text: string;
    example: {
        body_text: [string[]];
    };
};

interface ComponentCard {
    components: [
        {
            type: 'HEADER';
            text: string;
            format: 'TEXT' | 'VIDEO' | 'IMAGE';
            example?: {
                header_handle: string[];
            };
        },
        {
            type: 'BODY';
            text: string;
            example: {
                body_text: [string[]];
            };
        },
        {
            type: 'FOOTER';
            text: string;
        },
        {
            type: 'BUTTONS';
            buttons: [
                {
                    text: string;
                    type: 'QUICK_REPLY' | 'BUTTONS' | 'URL';
                    example?: string[];
                    url?: string;
                }
            ];
        },
    ],
    media: null | {
        id: number;
        fileName: string;
        fileSize: number;
        mediaType: string;
        url: string;
    }
}

interface ITemplateCarouselComponent {
    type: 'CAROUSEL';
    cards: ComponentCard[];
}

interface ITemplateButtonsComponent_Button {
    text: string;
    type: 'QUICK_REPLY' | 'BUTTONS' | 'URL' | 'otp';
    example?: string[];
    url?: string;
    otp_type?: string;
};

interface ITemplateButtonsComponent {
    type: 'BUTTONS';
    buttons: ITemplateButtonsComponent_Button[];
};

export type ITemplateComponent = ITemplateBodyComponent | ITemplateHeaderComponent | ITemplateFooterComponent | ITemplateButtonsComponent | ITemplateCarouselComponent;

interface ITemplate {
    id: string;
    name: string;
    language: string;
    category: string;
    templateStatus: 'APPROVED' | 'DRAFT' | 'REJECTED';
    components: ITemplateComponent[];
    media: null | {
        id: number;
        fileName: string;
        fileSize: number;
        mediaType: string;
        url: string;
    };
    showName: string;
    carouselColumns: carouselColumns[];
    headerColumns: headerColumns[];
};

interface SelectTemplateProps {
    value: null | ITemplate;
    isInvalid: boolean;
    isValid: boolean;
    setSelected: (value: null | ITemplate) => void;
};

/**
 * Error Context
 */

interface ErrorContextType {
    error: { [key: string]: boolean };
    setError: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}
const Community = (props: Props) => {
    const businessId = useSelector(
        (state: any) => state.cartreducer.business?.business?.uid
    );
    const channelData = useSelector((state: any) => state.cartreducer.channelUid);
    const [getContactData, setContactData] = useState([]);
    const [getContactCount, setContactCount] = useState(0);
    const currentPage = useRef(0);
    const paginationLimit = useRef(10);
    const [searchPgNo, setsearchPgNo] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const searchContacts = async () => { };
    const onSearchChange = (searchTerm: string) => {
        const searchTermCheck = searchTerm?.length > 0 ? searchTerm : null;
        setSearchTerm(searchTermCheck);
        const getAllContacts = async () => {
            const current = currentPage.current;
            const limit = paginationLimit.current;
            const page = current > 0 ? current - 1 : 0; // Adjusting for zero-based index if needed
            const ContactList = {
                uid: businessId,
                limit: limit,
                page: page,
                search: searchTerm,
            };
            const response = await ContactsGetAll(ContactList as any);
            if (response) {
                setContactData(response.data.list);
                searchTerm != null
                    ? setContactCount(response.data.list?.length)
                    : setContactCount(response.data.count);
            }
        };
    };


}
const errorCtx = createContext<ErrorContextType>({
    error: {},
    setError() { }
});



interface Value {
    value: string;
    label: string;
}


const SelectTemplate: React.FC<SelectTemplateProps> = props => {
    const [templates, setTemplates] = useState<Value[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const currentPage = useRef(0);
    const [hasMore, setHasMore] = useState(true);
    const [filteredData, setFilterData] = useState<any>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const channelId = useAppSelector(state => state.meta.channelUid);
    const limit = 10;
    const formik = useFormikContext();
    const debounceTimeout = useRef<number | null>(null);
    const [page, setPage] = useState(0);

    useEffect(() => {
        fetchData('', 0);
    }, []);

    useEffect(() => {
        if (!selectedTemplate && props?.value?.showName) {
            setSelectedTemplate({ label: props?.value?.showName, value: props?.value?.id });
        }
    }, [props?.value?.showName])

    const fetchData = async (searchTerm: any, page: any) => {
        setIsLoading(true);
        try {
            const payload = {
                channelId,
                limit,
                page,
                search: searchTerm,
                types: ['APPROVED']
            };
            const response = await axios.post('/template/getAllTemplates', payload);
            const data = response?.data?.templates?.filter((item: any) =>
                !item.components.some((comp: any) => comp.type === 'LIMITED_TIME_OFFER')
            );
            setFilterData(data);
            const templateData = data?.map((item: any) => ({
                value: item.id,
                label: item.showName
            }));
            if (page !== 0) {
                setTemplates(prevTemplates => [...new Set([...prevTemplates, ...templateData])]);
            }
            else {
                setTemplates(templateData)
            }
            if (response?.data?.length < 10) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        } catch (err) {
            console.error('Error Fetching Data', err);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectTemplateChange = async (selected: any) => {
        setSelectedTemplate(selected);
        const templateId = selected.value;
        const template: ITemplate = filteredData.find((template: ITemplate) => template.id === templateId);
        if (template) {
            props?.setSelected(template);
            formik.setFieldValue('template', template);
            formik.setFieldValue('templateNodes', null);
            formik.setFieldValue('media', null);
        }
        else {
            const newTemplate = await previewTemplate(templateId, channelId);
            formik.setFieldValue('template', newTemplate);
            formik.setFieldValue('templateNodes', null);
            formik.setFieldValue('media', null);
        }
        formik.setFieldValue('templateValuesList', []);
    };

    const handleInputChange = (inputValue: any) => {
        setSearchTerm(inputValue);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = window.setTimeout(() => {
            fetchData(inputValue, currentPage.current);
        }, 500);
    };

    return (
        <>
            <Form.Group className="mb-3">
                <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                        menu: base => ({ ...base, width: 400 })
                    }}
                    options={templates}
                    value={selectedTemplate}
                    onChange={handleSelectTemplateChange}
                    onMenuScrollToBottom={() => {
                        if (!isLoading && hasMore) {
                            setPage((prevPage) => prevPage + 1);
                            fetchData(searchTerm, page + 1);
                        }
                    }}
                    onInputChange={handleInputChange}
                />
            </Form.Group>
            <TemplateAdvancedSettings />
        </>
    );
};
/**
 * Template media component editor
 */
interface TemplateHeaderComponentInputProps {
    variables: string[];
    index: number;
    value: ITemplateHeaderComponent;
    template: any;
    media: any;
};

const TemplateHeaderComponentInput: React.FC<TemplateHeaderComponentInputProps> = props => {
    const formik = useFormikContext();
    const { error, setError } = useContext(errorCtx);
    const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
    const fileInputRef = useRef<{ current: HTMLInputElement & { index: number }; }['current']>(null);
    const currentChannelUId = useAppSelector((state) => state.meta.channelUid);

    async function handleChange(index: number, value: string) {
        const fieldName = `template.components[${props.index}]?.example?.header_handle[${index}]`;
        await formik.setFieldValue(fieldName, value);

        if (value.trim() === "" || isValidLink((props.value.format.toLocaleLowerCase() as 'image' | 'audio' | 'video' | 'document'), value)) {
            formik.setFieldError(fieldName, undefined);
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = false;
                return updatedError;
            });
        } else {
            const errorMsg = "Invalid " + props.value.format.toLocaleLowerCase() + " url"
            formik.setFieldError(fieldName, errorMsg);
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = true;
                return updatedError;
            });
        }
    }

    const handleUploadClick = (index: number) => {
        if (fileInputRef.current) {
            fileInputRef.current.index = index;
            fileInputRef.current.accept = getAcceptAttribute(props.value.format);
            fileInputRef.current.click();
        }
    };

    const getAcceptAttribute = (format: string): string => {
        if (format === 'VIDEO') {
            return 'video/*';
        } else if (format === 'IMAGE') {
            return 'image/*';
        }
        return '*';
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!fileInputRef.current) return;

        if ((e.target.files as any)?.length > 0 && (e.target.files as any)[0]?.size > 2097152) {
            toast('error', 'Please upload files under 2MB');
            return;
        }

        const index = fileInputRef.current.index;
        setLoading(prvLoading => {
            const updatedLoading = { ...prvLoading }
            updatedLoading["" + index] = true;
            return updatedLoading;
        });

        const files = e.target.files;
        if (!files || files?.length === 0) return;

        const file = files[0];
        const formData = new FormData();
        formData.append('content', file);

        try {
            const response = await axios.post(
                '/media/saveMessageMedia?uid=' + currentChannelUId,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setLoading(prvLoading => {
                const updatedLoading = { ...prvLoading }
                updatedLoading["" + index] = false;
                return updatedLoading;
            });
            if (typeof response.data.url === 'string') {
                // handleChange(index, response.data.url);
                formik.setFieldValue('media', response.data);
            }
        } catch (error) {
            console.error('Upload failed:', error);
            toast("error", "Failed to upload!");
        }
    };


    return (
        <Form.Group className='mb-3'>
            <Form.Label><b>Header Variables</b></Form.Label>
            {props.variables.map((mediaUrl: string, i: number) => {
                // const fieldName = `template.components[${props.index}].example.header_handle[${i}]`;
                const value = props.media ? props.media.url : props.template.media ? props.template.media.url : mediaUrl;
                const fieldName = `template.media.url`;
                const isError = fieldName in error && error[fieldName];
                const isLoading = i + "" in loading ? loading[i + ""] : false;
                return (
                    <Form.Group className='mb-3' key={`TempleteHeaderCmp-${props.index}-${i}`}>
                        <Form.Label>Media  <span className='dimmed'>(optional)</span></Form.Label>
                        {isLoading ? <div><Spinner /></div> : (
                            <>
                                <Button size='sm' variant='outline-primary' style={{ marginLeft: 12 }} onClick={() => handleUploadClick(i)}>Upload</Button>
                                <input
                                    type='file'
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                                <FormControl
                                    as='input'
                                    name={fieldName}
                                    placeholder='Enter a media URL'
                                    value={value}
                                    isInvalid={isError}
                                    onChange={(e: any) => handleChange(i, e.target.value)}
                                />
                                {isError ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        Invalid {props.value.format.toLowerCase()} url
                                    </div>
                                ) : null}
                                <div className='dimmed'>(
                                    You can send only these type of media:
                                    {props.value.format === 'VIDEO' && '.mp4, .3gpp'}
                                    {props.value.format === 'IMAGE' && '.jpg, .png'}
                                    )</div>
                            </>
                        )}
                    </Form.Group>
                );
            })}
        </Form.Group>
    );
}

/**
 * Template header component editor
 */
interface TemplateBodyComponentInputProps {
    variables: string[];
    index: number;
    value: ITemplateBodyComponent;
    templateId: string;
};

const TemplateBodyComponentInput: React.FC<TemplateBodyComponentInputProps> = props => {
    const formik = useFormikContext();
    const queryClient = new QueryClient();
    const { error, setError } = useContext(errorCtx);
    const [template, setTemplate] = useState<ITemplate | null>(null);
    useEffect(() => {
        queryClient.ensureQueryData({
            queryKey: ['templates'],
            queryFn: async () => {
                const channelId = mainState.getState().cartreducer.channelUid.value;
                if (!channelId) {
                    return new Promise(() => { });
                }
                try {
                    const response = await axios.request({
                        method: 'POST',
                        url: '/template/getAllTemplates',
                        data: JSON.stringify({
                            channelId,
                            page: 0,
                            limit: 5000,
                        })
                    });
                    const templates = response.data?.templates?.filter((template: any) => template.templateStatus === 'APPROVED');
                    return templates;
                } catch (error) {
                    console.error(error);
                    return [];
                }
            }
        }).then(templateList => {
            const template: ITemplate = templateList.find((temp: ITemplate) => temp.id === props.templateId);
            if (template && template.components[props.index].type === 'BODY') {
                setTemplate(template);
            }
        });
    }, []);


    function handleChange(index: number, value: string) {
        const fieldName = `template.components[${props.index}].example.body_text[0][${index}]`;
        formik.setFieldValue(fieldName, value);

        if (value.trim() !== "") {
            formik.setFieldError(fieldName, undefined);
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = false;
                return updatedError;
            });
        } else {
            formik.setFieldError(fieldName, "Variable required");
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = true;
                return updatedError;
            });
        }
    }

    if (!template) {
        return <Spinner />;
    }

    return (
        <Form.Group className='mb-3'>
            <Form.Label><b>Body Variables</b></Form.Label>
            {props.variables.map((variableName: string, i: number) => {
                const fieldName = `template.components[${props.index}].example.body_text[0][${i}]`;
                const isError = fieldName in error && error[fieldName];
                return (
                    <Form.Group className='mb-3' key={`TempleteBodyCmp-${props.index}-${variableName}`}>
                        <Form.Label>{variableName}<span className="required"></span></Form.Label>
                        <Form.Control
                            name={fieldName}
                            placeholder={(template.components[props.index] as ITemplateBodyComponent).example.body_text[0][i]}
                            value={props.value.example.body_text[0][i]}
                            onChange={(e: any) => handleChange(i, e.target.value)}
                            isInvalid={isError}
                        />
                        {isError ? (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                                {variableName} is required
                            </div>
                        ) : null}
                    </Form.Group>
                );
            })}
        </Form.Group>
    )
}

// Template component for carousel 

interface carouselColumns {
    card: number;
    variable: string[];
}

interface headerColumns {
    name: string,
    label: string,
    address: string,
    isSearch: boolean,
    latitude: number,
    longitude: number
}

interface TemplateCarouselComponentProps {
    cards: ComponentCard[];
    carouselColumns: carouselColumns[];
    index: number;
    value: ITemplateCarouselComponent;
}

const TemplateCarouselComponent: React.FC<TemplateCarouselComponentProps> = props => {
    const formik = useFormikContext();
    const { error, setError } = useContext(errorCtx);

    let titleRendered = false;
    
    const bodyComponents = props.cards.map((card: ComponentCard, index: number) => {
        const component = card.components;
        if (component.some((cmp => cmp.type === 'BODY')) && props.carouselColumns[index]?.card === index) {

            const bodyComponent = component.find((cmp => cmp.type === 'BODY'));
            const bodyIndex = component.findIndex((cmp => cmp.type === 'BODY'));
            const bodyText = bodyComponent?.text;
            if (bodyText) {
                const variables = scanForVariables(bodyComponent?.text);
                return (
                    <div key={index}>
                        {variables.map((variableName: string, i: number) => {
                            const fieldName = `template.components[${props.index}].cards[${index}].components[${bodyIndex}].example.body_text[0][${i}]`;
                            const isError = fieldName in error && error[fieldName];
                            function handleChange(i: number, value: any): void {
                                formik.setFieldValue(fieldName, value);
                                if (value.trim() !== "") {
                                    formik.setFieldError(fieldName, undefined);
                                    setError(prvError => {
                                        const updatedError = { ...prvError }
                                        updatedError[fieldName] = false;
                                        return updatedError;
                                    });
                                } else {
                                    formik.setFieldError(fieldName, "Variable required");
                                    setError(prvError => {
                                        const updatedError = { ...prvError }
                                        updatedError[fieldName] = true;
                                        return updatedError;
                                    });
                                }
                            }

                            return (
                                <Form.Group className='mb-3' key={`TempleteBodyCmp-${index}-${variableName}`}>
                                    <Form.Label>{variableName}<span className="required"></span></Form.Label>
                                    <Form.Control
                                        name={fieldName}
                                        placeholder={(props?.value.cards[index].components[bodyIndex] as any)?.example.body_text[0][i]}
                                        value={(props?.value.cards[index].components[bodyIndex] as any)?.example.body_text[0][i]}
                                        onChange={(e: any) => handleChange(i, e.target.value)}
                                        isInvalid={isError}
                                    />
                                    {isError ? (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {variableName} is required
                                        </div>
                                    ) : null}
                                </Form.Group>
                            );
                        })}
                    </div>
                );
            }
        }
    });

    // Return the JSX that includes the mapped components
    return (
        <div>
            { props.carouselColumns[0] &&
            <b className="mb-3"> Carousel Body Variables</b>}
            {bodyComponents}
        </div>
    );
};



/**
 * Template buttons component editor
 */
interface TemplateButtonComponentInputProps {
    variables: ITemplateButtonsComponent_Button[];
    index: number;
    value: ITemplateButtonsComponent;
    templateValuesList: templateVariable[];
};

const TemplateButtonComponentInput: React.FC<TemplateButtonComponentInputProps> = props => {
    const formik = useFormikContext();
    const shouldShowLabel = true;
    const { error, setError } = useContext(errorCtx);

    return (
        <Form.Group className='mb-3'>
            {shouldShowLabel && <Form.Label><b>Button Variables</b></Form.Label>}
            <div>
                {props.variables.map((button: ITemplateButtonsComponent_Button, i: number) => {
                    if ((!button?.example || !button?.url) && button?.type !== 'otp') {
                        return (
                            <div className={styles.replyButton} key={`TempleteButtonCmp-${props?.index}-${i}-`}>
                                {button?.text ? button.text : (button as any)?.example[0]}
                            </div>
                        )
                    };

                    if(button?.type === 'otp' && button?.otp_type === "copy_code") {
                        const fieldName = `templateValuesList[${i}].bodyText`;
                        function handleChange(index: number, value: string) {
                            formik.setFieldValue(fieldName, value);

                            if (value.trim() !== "") {
                                formik.setFieldError(fieldName, undefined);
                                setError(prvError => {
                                    const updatedError = { ...prvError }
                                    updatedError[fieldName] = false;
                                    return updatedError;
                                });
                            } else {
                                formik.setFieldError(fieldName, "Variable required");
                                setError(prvError => {
                                    const updatedError = { ...prvError }
                                    updatedError[fieldName] = true;
                                    return updatedError;
                                });
                            }
                        }

                        const isError = fieldName in error && error[fieldName];
                        return (
                            <Form.Group className='mt-3' key={i}>
                                <Form.Label>{i+1} <span className='required'></span></Form.Label>
                                <Form.Control
                                    placeholder='Enter otp code'
                                    value={props?.templateValuesList[i]?.bodyText ?? ""}
                                    onChange={(e: any) => handleChange(i, e.target.value)}
                                    isInvalid={isError}
                                />
                                {isError ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {i+1} is required
                                    </div>
                                ) : null}
                            </Form.Group>
                        );
                    }

                    const variables = scanForVariables(button.url ?? '');

                    return variables.map((variableName, j) => {
                        function handleChange(index: number, value: string) {
                            const fieldName = `template.components[${props.index}].buttons[${index}].example[${j}]`;
                            formik.setFieldValue(fieldName, value);

                            if (value.trim() !== "") {
                                formik.setFieldError(fieldName, undefined);
                                setError(prvError => {
                                    const updatedError = { ...prvError }
                                    updatedError[fieldName] = false;
                                    return updatedError;
                                });
                            } else {
                                formik.setFieldError(fieldName, "Variable required");
                                setError(prvError => {
                                    const updatedError = { ...prvError }
                                    updatedError[fieldName] = true;
                                    return updatedError;
                                });
                            }
                        }
                        const fieldName = `template.components[${props.index}].buttons[${i}].example[${j}]`;
                        const isError = fieldName in error && error[fieldName];
                        return (
                            <Form.Group className='mt-3' key={`TempleteButtonCmp-${props.index}-${i}-${j}`}>
                                <Form.Label>{variableName} <span className='required'></span></Form.Label>
                                <Form.Control
                                    name={`template.components[${props.index}].buttons[${i}].example[${j}]`}
                                    placeholder='Enter a value'
                                    value={(props.value.buttons[i].example as string[])[j]}
                                    onChange={(e: any) => handleChange(i, e.target.value)}
                                    isInvalid={isError}

                                />
                                {isError ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {variableName} is required
                                    </div>
                                ) : null}
                            </Form.Group>
                        );
                    });
                })}
            </div>
        </Form.Group>
    )
}


/**
 * Main editor
 */

export interface templateVariable {
    bodyText?: string;
    componentType: string;
    ownValue?: boolean;
    position?: number;
    carouselText?: null | [ {
        type: string,
        cards: []
    }
    ];
    metaData?: any;
}

export interface FormData {
    templateStatus: boolean;
    template: ITemplate | null;
    templateNodes: null | { [key: string]: string };
    media: any;
    templateValuesList: templateVariable[];
};

const SendTemplate: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [error, setError] = useState<{ [key: string]: boolean }>({});
    const [selected, setSelected] = useState<ITemplate | null>(null);
    const [templateVariables, setTemplateVariables] = useState<templateVariable[]>([]);
    const [formData, setFormData] = useState<FormData>({
        templateStatus: true,
        template: null,
        templateNodes: null,
        media: null,
        templateValuesList: []
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_wa_templateV2',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        templateStatus: yup.boolean(),
        template: yup.object().required('Template is required')
    });

    function formSubmitHandler(data: any) {
        const variables: templateVariable[] = [];
        const buttonVariables = data.template.components.find((comp: any) => comp.type === 'BUTTONS')?.buttons;
        const bodyVariables = data.template.components.find((comp: any) => comp.type === 'BODY')?.example?.body_text[0];
        if (data.template && bodyVariables?.length > 0) {
            bodyVariables?.map((text: any, index: any) => {
                const templateValue: templateVariable = {
                    bodyText: text,
                    componentType: 'body',
                    ownValue: true,
                    position: index + 1,
                    carouselText: null,
                    metaData: null
                }
                variables?.push(templateValue);
            });
        }
        buttonVariables?.map((button: any, index: any) => {
            if (button?.example?.length > 0) {
                const templateValue: templateVariable = {
                    bodyText: button?.example[0],
                    componentType: 'button',
                    ownValue: true,
                    position: index,
                    carouselText: null,
                    metaData: null
                }
                variables?.push(templateValue);
            }
        });

        if (data?.template?.components.some(((cmp: any) => cmp.type === 'CAROUSEL')) && data?.template?.carouselColumns?.length > 0) {

            const mainIndex = data.template.components.findIndex((cmp: any) => cmp.type === 'CAROUSEL');
            
            const templateValue: templateVariable = {
                componentType: 'carousel',
                carouselText: [ {
                    type: "CAROUSEL",
                    cards: data?.template?.carouselColumns[0] ? data?.template?.components[mainIndex]?.cards : data.template.cards
                }
                ],
            }
            variables?.push(templateValue);

        }
        if(data?.template?.components?.some((cmp: any) => cmp.type === 'HEADER' && cmp.format === 'LOCATION') && data?.template?.headerColumns?.length > 0) {
              data?.template?.headerColumns?.map((header: headerColumns, index: number) => {
                const templateValue: templateVariable = {
                    componentType: 'header',
                    bodyText: header?.address,
                    ownValue: false,
                    position: index+1,
                    metaData: {
                        address: header?.address,
                        latitude: header?.latitude,
                        longitude: header?.longitude,
                        name: header?.name,
                    }
                }
                variables?.push(templateValue);
              })
        }

        if(data?.templateValuesList?.length === 1 && data?.template?.category === "AUTHENTICATION") {
            const copyCode = data?.templateValuesList[0];
            if(copyCode) {
               const templateValue = {
                 componentType: 'button',
                 bodyText: copyCode?.bodyText,
                 ownValue: false,
                 position: 0
               }
               variables?.push(templateValue);
            }
        }
        let newData = data;
        if (selected) {
            newData = { ...data, template: selected, templateValuesList: variables };
        }
        if (Object.values(error).every(value => !value) || Object.keys(error)?.length === 0) {
            saveElementChanges(newData);
        }
    }

    return (
        <errorCtx.Provider
            value={{ error, setError }}
        >
            <Formik
                validationSchema={schema}
                onSubmit={formSubmitHandler}
                initialValues={formData}
            >
                {({ handleSubmit, values, touched, errors, setValues, setFieldValue, setFieldError }) => {
                    useEffect(() => {
                        setValues(formData);
                        if (formData.template) {
                            setSelected(formData.template);
                        }
                        if (formData.template && formData?.templateValuesList?.length > 0) {
                            const template = formData.template;

                            // Step 1: Find the BUTTONS and BODY components and their indexes
                            const buttonsComponentIndex = template.components?.findIndex((comp: any) => comp.type === 'BUTTONS');
                            const bodyComponentIndex = template.components?.findIndex((comp: any) => comp.type === 'BODY');

                            // Step 2: Get the button variables, body variables, and template values list
                            const buttonVariables = (template.components?.[buttonsComponentIndex] as any)?.buttons;
                            const bodyVariables = (template.components?.[bodyComponentIndex] as any)?.example?.body_text[0];

                            // Buttons and bodyText from the values list
                            const buttons = formData.templateValuesList?.filter((variable: any) => variable.componentType === 'button');
                            const bodyText = formData.templateValuesList?.filter((variable: any) => variable.componentType === 'body');

                            // Step 3: Create updated button variables
                            let ind = 0;
                            const updatedButtonVariables = buttonVariables?.map((buttonVar: { example: any[] }, index: number) => {
                                if (buttonVar?.example?.length > 0) {
                                    const button = buttons[ind];
                                    ind++;
                                    return {
                                        ...buttonVar, // Copy everything from the original object
                                        example: [button?.bodyText] // Replace the example array's first value
                                    };
                                }
                                else {
                                    return buttonVar;
                                }
                            });

                            // Step 4: Create updated body variables by splitting the bodyText by commas
                            const updatedBodyVariables = [
                                bodyText.map((body: any) => body.bodyText) // Create a single array of bodyText values
                            ];

                            // Step 5: Create a new template with the updated buttons and body
                            if ((buttonsComponentIndex !== -1 && updatedButtonVariables) || (bodyComponentIndex !== -1 && updatedBodyVariables)) {
                                // Create a copy of components
                                const updatedComponents = [...template.components] as any;

                                // Update the buttons component in the correct index
                                if (buttonsComponentIndex !== -1 && updatedButtonVariables) {
                                    updatedComponents[buttonsComponentIndex] = {
                                        ...updatedComponents[buttonsComponentIndex], // Keep other properties of the component
                                        buttons: updatedButtonVariables // Replace the buttons array with the updated one
                                    };
                                }

                                // Update the body component in the correct index
                                if (bodyComponentIndex !== -1 && updatedBodyVariables) {
                                    updatedComponents[bodyComponentIndex] = {
                                        ...updatedComponents[bodyComponentIndex], // Keep other properties of the component
                                        example: {
                                            ...updatedComponents[bodyComponentIndex].example,
                                            body_text: updatedBodyVariables // Replace the body_text array with updated values
                                        }
                                    };
                                }

                                // Step 6: Update the formData's template with the new components
                                const updatedTemplate = {
                                    ...template,
                                    components: updatedComponents
                                };
                                setFieldValue('template', updatedTemplate);
                            }
                            if(formData?.template?.components?.some((cmp: any) => cmp.type === 'CAROUSEL') && formData?.template?.carouselColumns?.length > 0) {
                                const mainIndex = template.components.findIndex((cmp: any) => cmp.type === 'CAROUSEL');
                                setFieldValue(`template.components[${mainIndex}].cards`, formData?.templateValuesList?.find((temp: any) => temp.componentType === 'carousel')?.carouselText?.[0]?.cards);
                            }

                        }
                    }, [formData, setValues]);

                    const handleSubmission = () => {
                        if(!values?.templateValuesList[0]?.bodyText && values?.template?.category === "AUTHENTICATION"
                            && (values?.template?.components?.find((cmp: any) => cmp.type === 'BUTTONS') as  ITemplateButtonsComponent)?.buttons?.length === 1) {
                            setFieldError("templateValuesList[0].bodyText", "Variable required");
                            setError(prvError => {
                                const updatedError = { ...prvError }
                                updatedError["templateValuesList[0].bodyText"] = true;
                                return updatedError;
                            });
                        }
                    }

                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <EditorCaption onHide={props.onClose} caption='Send Template' icon={<img style={{ width: 16 }} alt='' src={templateIcon} />} />
                            <Offcanvas.Body>
                                <Form.Group className='mb-3'>
                                    <Form.Label><b>Template <span className='required'></span></b></Form.Label>
                                    <SelectTemplate
                                        value={values.template}
                                        isInvalid={(touched.template && errors.template) ? true : false}
                                        isValid={(touched.template && !errors.template) ? true : false}
                                        setSelected={setSelected}
                                    />
                                    {(touched.template && errors.template) ? (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            Template is required
                                        </div>
                                    ) : null}
                                </Form.Group>

                                {values.template ? values.template.components.map((cmp: ITemplateComponent, i: number) => {
                                    switch (cmp.type) {
                                        case 'BODY':
                                            var variables = scanForVariables(cmp.text);
                                            if (variables?.length > 0 && values?.template) {
                                                return (
                                                    <TemplateBodyComponentInput
                                                        key={`TemplateComponent-${i}`}
                                                        variables={variables}
                                                        index={i}
                                                        value={values.template?.components[i] as ITemplateBodyComponent}
                                                        templateId={values.template?.id}
                                                    />
                                                );
                                            }
                                            return null;
                                        case 'BUTTONS':
                                            if (cmp.buttons?.length > 0) {
                                                return (
                                                    <TemplateButtonComponentInput
                                                        key={`TemplateComponent-${i}`}
                                                        variables={cmp.buttons}
                                                        index={i}
                                                        value={values.template?.components[i] as ITemplateButtonsComponent}
                                                        templateValuesList={values.templateValuesList}
                                                    />
                                                );
                                            }
                                            return null;
                                        case 'HEADER':
                                            if (cmp.format !== 'TEXT' && cmp?.example && cmp?.example?.header_handle?.length > 0) {
                                                return (
                                                    <TemplateHeaderComponentInput
                                                        key={`TemplateComponent-${i}`}
                                                        variables={cmp?.example?.header_handle}
                                                        index={i}
                                                        value={values.template?.components[i] as ITemplateHeaderComponent}
                                                        template={values.template}
                                                        media={values.media}
                                                    />
                                                );
                                            }
                                            return null;
                                        case 'CAROUSEL':
                                            if (cmp.cards?.length > 0 && values?.template?.carouselColumns && values?.template?.carouselColumns?.length > 0) {
                                                return (
                                                    <TemplateCarouselComponent
                                                        key={`TemplateComponent-${i}`}
                                                        cards={cmp.cards}
                                                        carouselColumns={values?.template?.carouselColumns}
                                                        index={i}
                                                        value={values.template?.components[i] as ITemplateCarouselComponent}
                                                    />
                                                );
                                            }
                                            return null;

                                        default:
                                            return null;
                                    }
                                }) : null}
                            </Offcanvas.Body>
                            <div className="editor-footer">
                                <Button variant='outline-dark' onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button className='sendButton mb-1' type='submit' disabled={props?.isInvalidAccess} onClick={handleSubmission} >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </errorCtx.Provider>
    );
}

export default SendTemplate;