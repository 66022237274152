import React, { useEffect, useState } from "react";
import { Container, Row, Col, Label, Button } from "reactstrap";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FormikContext,
  FormikValues,
} from "formik"; // Import Form component
import Select from "react-select";
import {
  sequenceVariables,
  sequenceFilters,
  isOptState,
} from "../../common/category";
import * as Yup from "yup";
import { useSequenceData } from "./SequenceContext";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { getTag } from "../Tag/tagApi";
import { useFormik } from "formik";

interface props {
  onClose: () => void;
  forms: {
    field: string;
    filter: string;
    value: string;
    contact: string;
  }[];
  variableType: string;
  setVariablesCount:any;
}

interface FormValues extends FormikValues {
  forms: {
    field: string;
    filter: string;
    value: string;
    contact: string;
  }[];
}

const AddVariablesDub = (props: props) => {
  const { onClose, forms, variableType, setVariablesCount } = props;

  const { setFieldValue } = useFormikContext<FormValues>();

  
  const [variables, setVariables] = useState<any[]>(forms);
  const [tagsList, setTagList] = useState([]);
  console.log("form", forms, variableType);

  const sequenceOption = sequenceVariables.map((sequence: any) => ({
    label: sequence.label,
    value: sequence.value,
  }));

  const filterOption = sequenceFilters.map((filter: any) => ({
    label: filter.label,
    value: filter.value,
  }));

  const optState = isOptState.map((opt: any) => ({
    label: opt.label,
    value: opt.value,
  }));

  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const getTags = async () => {
    const payload = {
      businessUid: businessId,
      limit: 10000,
      page: 0,
      search: null,
    };

    const tagsData = await getTag(payload, "contact");
    const tags = tagsData?.data?.tagsDto.map((tag: any) => ({
      label: tag.name,
      value: tag.name,
      tagAll: tag,
    }));
    setTagList(tags);
  };

  useEffect(() => {
    if (businessId) {
      getTags();
    }
  }, [businessId]);

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      width: "250px",
      alignItems: "center",
      maxHeight: "150px",
      overflow: "hidden",
    }),
  };
  

  const addForm = () => {
   const newVariables =  [
      ...variables,
      { field: "", filter: "", value: "", module: "contact" },
    ];
    setVariables(newVariables)
    setVariablesCount(newVariables.length)
  };

  const handleChange = (index: number, field: string, value: any) => {
    const newVariables = [...variables];
    newVariables[index][field] = value;
    setVariables(newVariables);
  };

  const handleRemove = (index: number) => {
    const newVariables = [...variables];
    newVariables.splice(index, 1);
    setVariables(newVariables);
  };

  const handleSubmit = () => {
    if (variableType === "START") {
      setFieldValue("startCondition", [...variables]);
    } else if (variableType === "STOP") {
      setFieldValue("stopCondition", [...variables]);
    }
    onClose();
  };

  const validationSchema = Yup.object().shape({
    variables: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().required("Field is required"),
        filter: Yup.string().required("Filter is required"),
        // value: Yup.string().required("Value is required"),
        value: Yup.string().when("field", {
          is: (field:string) => field !== "optState", // When field is not 'tags'
          then: Yup.string().required("Value is required"), // Make value required
          otherwise: Yup.string().notRequired(), // Otherwise, not required
        }),
      })
    ),
  });

  return (
    <>
      <Container>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            variables,
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={true}
          // validateOnBlur={true}
        >
          {({ values, setFieldValue, setFieldError }) => (
            <Form className="pb-1">
              <Row>
                <Col md={3}>
                  <Label>Variables</Label>
                </Col>
                <Col md={3}>
                  <Label>Filters</Label>
                </Col>
                <Col md={3}>
                  <Label>Values</Label>
                </Col>
                <Col md={3} className="d-flex justify-content-center">
                  <Button className="sendButton" onClick={addForm}>
                    Add Variable
                  </Button>
                </Col>
              </Row>
              {variables.map((variable, index) => (
                <div key={index} className="mb-2">
                  <Row>
                    <Col md={3}>
                      <Field name={`variables[${index}].field`}>
                        {({ field, form }: any) => (
                          <Select
                            options={sequenceOption}
                            // onBlur={() => form.validateForm()}

                            value={sequenceOption.find(
                              (option) => option.value === variable.field
                            )}
                            placeholder="Enter a Field"
                            onChange={(e: any) => {
                              handleChange(index, "field", e.value);
                              if (e.value) {
                                form.setFieldError(
                                  `variables[${index}].field`,
                                  ""
                                );
                              }
                            }}
                            styles={customStyles}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        className="text-danger pt-1"
                        name={`variables[${index}].field`}
                        component="div"
                      />
                    </Col>

                    <Col md={3}>
                      {variable.field === "optState" ? (
                        <>
                          <Field name={`variables[${index}].filter`}>
                            {({ field, form }: any) => (
                              <Select
                                options={optState}
                                // onBlur={() => form.validateForm()}
                                value={optState.find(
                                  (option) => option.value === variable.filter
                                )}
                                placeholder="Enter a Filter"
                                onChange={(e: any) => {
                                  handleChange(index, "filter", e.value);
                                  if (e.value) {
                                    form.setFieldError(
                                      `variables[${index}].filter`,
                                      ""
                                    );
                                  }
                                }}
                                styles={customStyles}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            className="text-danger pt-1"
                            name={`variables[${index}].filter`}
                            component="div"
                          />
                        </>
                      ) : (
                        <>
                          <Field name={`variables[${index}].filter`}>
                            {({ field, form }: any) => (
                              <Select
                                options={filterOption}
                                // onBlur={() => form.validateForm()}
                                value={filterOption.find(
                                  (option: any) =>
                                    option.value === variable.filter
                                )}
                                placeholder="Enter a Filter"
                                onChange={(e: any) => {
                                  handleChange(index, "filter", e.value);
                                  if (e.value) {
                                    form.setFieldError(
                                      `variables[${index}].filter`,
                                      ""
                                    );
                                  }
                                }}
                                styles={customStyles}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            className="text-danger pt-1"
                            name={`variables[${index}].filter`}
                            component="div"
                          />
                        </>
                      )}
                    </Col>

                    <Col md={3}>
                      {variable.field === "tags" ? (
                        <>
                          <Field name={`variables[${index}].value`}>
                            {({ field, form }: any) => (
                              <Select
                                options={tagsList as any}
                                // onBlur={() => form.validateForm()}
                                styles={customStyles}
                                value={tagsList.find(
                                  (option: any) =>
                                    option.value === variable.value
                                )}
                                placeholder="Enter value"
                                onChange={(e: any) => {
                                  handleChange(index, "value", e.value);
                                  if (e.value) {
                                    form.setFieldError(
                                      `variables[${index}].value`,
                                      ""
                                    );
                                  }
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            className="text-danger pt-1"
                            name={`variables[${index}].value`}
                            component="div"
                          />
                        </>
                      ) : 
                      variable.field === "optState" ? 
                        <>
                          <Field
                            type="text"
                            name={`variables[${index}].value`}
                            className="form-control"
                            value=""
                            onChange={(e: any) => {
                              handleChange(index, "value", e.target.value);
                              if (e.target.value) {
                                // setFieldError(`variables[${index}].value`, "");
                              }
                            }}
                            disabled={true}
                          />
                          </>
                          :
                      (
                        <>
                          <Field
                            type="text"
                            name={`variables[${index}].value`}
                            // validateOnBlur
                            className="form-control"
                            placeholder="Enter value"
                            onChange={(e: any) => {
                              handleChange(index, "value", e.target.value);
                              if (e.target.value) {
                                setFieldError(`variables[${index}].value`, "");
                              }
                            }}
                          />
                          <ErrorMessage
                            className="text-danger pt-1"
                            name={`variables[${index}].value`}
                            component="div"
                          />
                        </>
                      )}
                    </Col>

                    <Col md={3} className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleRemove(index)}
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <circle
                          cx="10.5"
                          cy="10.9199"
                          r="10"
                          fill="#29caaf"
                        ></circle>
                        <path
                          d="M4.5 10.9199H16.5"
                          stroke="white"
                          stroke-linecap="round"
                        ></path>
                      </svg>
                    </Col>
                  </Row>
                </div>
              ))}

              {variables.length > 0 ? (
                <Row className="mt-3">
                  <Col md={9}></Col>
                  <Col md={3} className="d-flex justify-content-center">
                    <Button type="submit" className="float-right sendButton">
                      Apply
                    </Button>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AddVariablesDub;
