import React from 'react';
import { SketchPicker, ColorResult } from 'react-color';

interface ColorPickerState {
  showPicker: boolean;
  color: any;
}
interface Props{
    onColorChange:(color: ColorResult) => void;
}

class ColorPicker extends React.Component<Props, ColorPickerState> {
  state: ColorPickerState = {
    showPicker: false,
    color:'#128c7e'
  };

  onClick = () => {
    this.setState((prevState) => ({
      showPicker: !prevState.showPicker,
    }));
  };

  onClose = () => {
    this.setState({
      showPicker: false,
    });
  };

  onChange = (color: ColorResult) => {
    this.setState({
      color: color.hex,
    },
    ()=>{this.props.onColorChange(this.state.color)}
    )
  };

  render() {
    const colorBoxStyle: React.CSSProperties = {
      width: '40px',
      height: '15px',
      borderRadius: '3px',
      background: this.state.color,
    };

    const swatchStyle: React.CSSProperties = {
      padding: '6px',
      background: '#ffffff',
      borderRadius: '2px',
      cursor: 'pointer',
      display: 'inline-block',
      boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
    };

    const popoverStyle: React.CSSProperties = {
      position: 'absolute',
      zIndex: 3,
    };

    const coverStyle: React.CSSProperties = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };

    return (
      <div>
        <div style={swatchStyle} onClick={this.onClick}>
          <div style={colorBoxStyle} />
        </div>
        {this.state.showPicker && (
          <div style={popoverStyle}>
            <div style={coverStyle} onClick={this.onClose} />
            <SketchPicker color={this.state.color} onChange={this.onChange} />
          </div>
        )}
      </div>
    );
  }
}

export default ColorPicker;
