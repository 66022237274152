import React, { Component, ComponentType, MouseEvent } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form } from "formik";
import {
  Col,
  Row,
  Alert,
  InputGroup,
  Form as FormBootstrap,
} from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import axios from "./../../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BootstrapTable,
  TableHeaderColumn,
  SelectRow,
} from "react-bootstrap-table";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faTrashCan,
  faEdit,
  faColumns,
  faPlus,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
// import * as  actions  from "./../../../redux/actions/action";
import { connect } from "react-redux";
// import  './../Contacts/contacts.scss';
import EditMember from "./EditMember";
import { showAlert, sweetalert, toastAlert } from "../../common/alert";
import FlagInput from "../../common/flagInput";
import Bootstrap from "../../assets/plugins/bootstrap/js/bootstrap";
import { Button, Label, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import RightSidebar from "../../common/rightSidebar";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";
import { memberDelete, restoreMember } from "../../services/contactMemberService";
import { MemberData } from "../../redux/actions/action";
import { useReactFlow } from "reactflow";
import "./Member.scss";
import { getAddonCode } from "../../common/commonfns";
import Loader from "../../shade/Loaders/Loaders";
import BizTable from "../../common/BizTable";
import { Nav, NavItem, NavLink } from "reactstrap";
import CreateGroup from "./GroupCreate";
import SearchInput from "../../common/searchComponent";
import { ErrorImage } from "../../common/ErrorMessage";
import Roles from "./roles";
// interface RowData {
//   uid: string;
//   name: string;
//   // Add other properties as necessary
// }
// const channelOptions : SelectBtnOption[] = [
//   {value: channels.uid ,label: channels.name}
//  ]

interface MembersState extends WithNavigateProps {
  showContent: boolean;
  showEdit: boolean;
  MemberLists: any;
  MemberEdit: any;
  code: string;
  mobileNumber: number | null;
  role: any;
  isLoading: boolean;
  roleOptions: [];
  channelOptions: any;
  selectedchannel: any[] | undefined;
  roleMembers: string;
  profileDetail: any;
  MemberCount: number;
  tab: string;
  searchTerm: string | null;
  paginationPaload: {
    pageIndex: number;
    pageSize: number;
    searchText: string;
    currentPage: number;
  };
  membersCountBasedOnType: number;
  isInvalidAccess: boolean;
  roleRules: any;
}
const initialValues: FormValues = {
  firstname: "",
  email: "",
  mobileNumber: "",
  role: "",
  channels: "",
};
interface FormValues {
  firstname: string;
  email: string;
  mobileNumber: string;
  role: string;
  channels: string;
}
interface SelectOption {
  value: string;
  label: string;
}

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(7, "Mobile Number must be at least 7 numbers")
    .max(13, "Mobile number cannot exceed 13 characters"),
  role: Yup.string().required("Role is required"),
});
interface Props {
  businessId: { id: number } | null;
  flagData: any; // Provide the appropriate type for 'business' prop
  roleData: [];
  channelData: [];
  updateMemberData: (data: any) => void;
  userRole: any;
  roleRules: any;
  navigate?: NavigateFunction;
}

interface WithNavigateProps {
  navigate?: NavigateFunction;
}

// Define a generic HOC that can wrap any component
const withNavigate = <P extends object>(Component: ComponentType<P>) => {
  return (props: P) => {
    const navigate = useNavigate();
    
    // Inject the navigate function into the component's props
    return <Component {...props as P} navigate={navigate} />;
  };
};

class Members extends Component<Props, MembersState> {
  columns: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      showContent: false, // Initially hide the content
      showEdit: false,
      MemberLists: [],
      MemberEdit: null,
      code: "+91",
      mobileNumber: null,
      role: "",
      isLoading: true,
      roleOptions: [],
      channelOptions: [],
      selectedchannel: [],
      roleMembers: "",
      profileDetail: null,
      MemberCount: 0,
      tab: "1",
      searchTerm: null,
      paginationPaload: {
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        currentPage: 0,
      },
      membersCountBasedOnType: 0,
      isInvalidAccess: false,
      roleRules: [],
      navigate: this.props?.navigate
    };
  }

  

  handleClickAdd = (event: MouseEvent<HTMLButtonElement>) => {
    this.setState((prevState) => ({
      showContent: !prevState.showContent,
      showEdit: false, // Toggle the visibility
    }));
  };

  handleClickEdit = (event: MouseEvent<HTMLButtonElement>, rowData: object) => {
    if ("uid" in rowData) {
      const uid = rowData.uid;
      axios
        .get(`member/${uid}`)
        .then((res) => {
          const responseData = res?.data;
          this.setState({ MemberEdit: responseData });
          this.setState((prevState) => ({
            showContent: !prevState.showContent,
            showEdit: true,
          }));
        })
        .catch((error: any) => {
          toastAlert("top-end", error.response.data.message, "error");
        });
    }
  };


  nameData = (cell: any, row: any) => {
    return (
      <div>
        {cell.length > 20 ? (
          <TooltipReference
            placement="right"
            content={cell}
            tooltipId={`nameData_${row.id}`}
          >
            {sliceText(cell, 20)}
          </TooltipReference>
        ) : (
          <span>{sliceText(cell, 20)}</span>
        )}
      </div>
    );
  };
  mobileData = (cell: any, row: any) => {
    return <div>{cell}</div>;
  };

  roleData = (cell: any, row: any) => {
    return <div>{cell}</div>;
  }

  emailData = (cell: any, row: any) => {
    return (
      <div>
        {/* {cell && cell.length > 11 ? ( */}
        <TooltipReference
          placement="right"
          content={cell}
          tooltipId={`emailData_${row.id}`}
        >
          {cell.length > 27 ? cell.slice(0, 27) + "..." : cell}
        </TooltipReference>
        {/* // ) : (
        //   <span className="email-data">{cell ? sliceText(cell, 15) : "-"}</span>
        // )} */}
      </div>
    );
  };

  statusData = (cell: any, row: any) => {
    const capitalizeWords = (str: any) => {
      if (typeof str !== "string") {
        return <span>str</span>;
      }
      const capitalizedStr = str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      const bgColor = str === "ACTIVE" ? "#eff8ef" : str === "DELETED" ? "#ffeaea" : str === "PENDING" ? "#fefcbf" : str === "INACTIVE" ? "#cfdfed" : "white";
      const color = str === "ACTIVE" ? '#327a30' : str === "DELETED" ? "#ce2d2d" : str === "PENDING" ? "#dca51f" : str === "INACTIVE" ? "#589CE3" : "black";
      return <span style={{ backgroundColor: bgColor, color: color }} className="status p-1">{capitalizedStr}</span>;
    };
    const resendInvitation = () => {
      const memberId = { memberId: row.uid };
      axios
        .post("/member/resendInvite", memberId)
        .then((res) => {
          toastAlert("top-end", " Invitation Sent Successfully", "success");
        })
        .catch((error) => {
          toastAlert("top-end", "error.data.message", "error");
        });
    };
    return (
      <>
        <p className="mb-0">{capitalizeWords(cell)}</p>
        {capitalizeWords(cell)?.props?.children === "Pending" && (
          <span className={`${this.state.isInvalidAccess ? "notAllowed" : ""}`} >
          <Button className={`resendBtn ml-2 mt-2 ${this.state.isInvalidAccess ? "disabledState" : ""} `} onClick={() => resendInvitation()}>
            Resend invitation
          </Button>
          </span>
        )}
      </>
    );
  };


  onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    this.setState({ searchTerm: searchTermCheck });
  };

  renderEditColumn = (cell: any, rowData: any) => {
    return (
      <>
        {rowData.status !== "DELETED" ? (
          <div className="d-flex justify-content-center ">
            <svg
              width="16"
              height="16"
              className={`${(this.state.profileDetail?.uid === rowData?.uid &&
                rowData.role.name === "OWNER") ||
                (this.state.profileDetail?.business?.email === rowData.email &&
                  rowData.role.name === "OWNER")
                ? "me-5 ms-1"
                : ""
                }`}
              onClick={() => this.handleClickEdit(cell, rowData)}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {(this.state.profileDetail?.uid === rowData?.uid &&
              rowData.role.name === "OWNER") ||
              (this.state.profileDetail?.business?.email === rowData.email &&
                rowData.role.name === "OWNER") ? (
              ""
            ) : (
              <svg
                width="16"
                height="16"
                className="ms-4"
                onClick={() => this.DeleteSelect(cell, rowData)}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4H3.33333H14"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.66602 7.3335V11.3335"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.33398 7.3335V11.3335"
                  stroke="#666E7D"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>
        ) : (

          <div className="d-flex justify-content-center ">

            <svg
              width="16"
              height="16"
              className="disabled-svg"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>


            <span>
              <TooltipReference
                placement="bottom"
                tooltipId={`tooltip-${rowData.id}`}
                content="Restore member"
              >
                <FontAwesomeIcon
                  className="restoreIcon"
                  icon={faClockRotateLeft}
                  onClick={() => this.restoreMember(rowData?.id)} />

              </TooltipReference>
            </span>

          </div>

        )}
      </>
    );
  };

  handleCancel = () => {
    this.setState({ showContent: false });
    // this.fetchMembers();
  };



  checkInvalidAccess() {
    const { roleRules } = this.props;
    if (roleRules) {
      const isInvalidAccess = roleRules.canViewMemberTeamsAndRoles === true && roleRules.canManageMemberTeamsAndRoles === false;
      this.setState({ isInvalidAccess });
      this.setState({ roleRules });
    }
  }


  componentDidMount(): void {
    const { businessId, roleData, userRole } = this.props;
    if (businessId) {
      this.fetchMembers();
      this.setState({ roleOptions: roleData });
    }
    if (userRole) {
      const roleMember = userRole?.role?.name;
      this.setState({ roleMembers: roleMember });
      const profile = userRole;
      this.setState({ profileDetail: profile });
    }
    const { channelData } = this.props;
    if (channelData) {
      const options = this.props?.channelData.map((data: any) => ({
        label: data.name,
        value: data.uid,
      }));
      this.setState({ channelOptions: options });
    }
    // let options;
    //         if (channelData && channelData?.length > 0) {
    //            options = channelData.map((channel:any) => ({
    //             label: channel.name,
    //             value: channel.uid,
    //           }));
    //        }

    // if(this.props.channelData){
    //   this.setState({channelOptions:options})
    // }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<MembersState>,
    snapshot?: any
  ): void {
    const { businessId, roleData } = this.props;
    if (businessId !== prevProps.businessId) {
      // this.fetchMembers();
      this.fetchData(0, 20, '', 0);
    }

    if (prevState.searchTerm !== this.state.searchTerm) {
      const { pageIndex, pageSize, searchText, currentPage } =
        this.state.paginationPaload;
      this.fetchData(0, 20, '', 0);
      // this.fetchMembers();
    }

    if (prevProps.roleData !== roleData) {
      this.setState({ roleOptions: this.props.roleData });
    }

    if (prevProps.channelData !== this.props?.channelData) {
      const options = this.props?.channelData.map((data: any) => ({
        label: data.name,
        value: data.uid,
      }));

      this.setState({ channelOptions: options });
    }

    if (this.state.roleRules !== this.props.roleRules) {
      this.checkInvalidAccess();
    }
  }

  handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    const { businessId, flagData } = this.props;
    const { code, role, selectedchannel } = this.state;
    // const countryData = flagData.find((item: any) => item.phoneCode === code ?.slice(1));
    const addInvitePayload = {
      name: values.firstname,
      businessId,
      mobileNumber: values.mobileNumber,
      email: values.email,
      country_code: code,
      role: role,
      channels: selectedchannel,
    };
    axios
      .post("member/invite", addInvitePayload)
      .then((res) => {
        toastAlert("top-end", "Member invited successfully", "success");
        this.fetchData(0, 20, '', 0);

        if (values.firstname && values.email && values.mobileNumber) {
          this.handleCancel();
        }
        setSubmitting(false);
      })
      .catch((error) => {
        toastAlert("top-end", error.response.data.message, "error");
        setSubmitting(false);
      });
  };

  DeleteSelect = (cell: any, rowData: any) => {
    if (rowData && rowData.uid) {
      const uid = rowData.uid;
      sweetalert(
        "warning",
        " Are you sure you want to delete this member?",
        async () => {
          if (uid) {
            const response = await memberDelete(uid);
            if (response === "SUCCESS") {
              this.fetchData(this.state.paginationPaload.pageIndex, this.state.paginationPaload.pageSize, this.state.paginationPaload.searchText, this.state.paginationPaload.currentPage);
              toastAlert("top-end", "Member deleted successfully", "success");
            }
          }
        },
        () => { }
      );
    }
  };


  restoreMember = (memberUid: string) => {

    if (memberUid) {
      sweetalert(
        "warning",
        " Are you sure you want to restore this member?",
        async () => {
          if (memberUid) {
            const response = await restoreMember(memberUid);
            if (response === "SUCCESS") {
              this.fetchData(this.state.paginationPaload.pageIndex, this.state.paginationPaload.pageSize, this.state.paginationPaload.searchText, this.state.paginationPaload.currentPage);
              toastAlert("top-end", "Member restored successfully", "success");
            }
          }
        },
        () => { }
      );
    }

  };

  fetchMembers = () => {
    const { businessId } = this.props;
    const MemberList = {
      businessUid: businessId,
      limit: this.state.paginationPaload.pageSize,
      page: this.state.paginationPaload.currentPage,
      status: ["ACTIVE", "PENDING", "DELETED"],
    };
    axios
      .post("member/getMembers", MemberList)
      .then((res) => {
        if (businessId) {
          const MemberLists = res?.data?.dataDTO
            // ?.filter((member: any) => member.status !== 'DELETED')
            ?.map((member: any) => ({
              ...member,
              roleName: member?.role?.name,
              mobileNumber: member?.country_code
                ? (member?.country_code.includes("+")
                  ? member?.country_code
                  : "+" + member?.country_code) +
                " " +
                member?.mobileNumber
                : "" + member?.mobileNumber,
            }));
          this.props.updateMemberData(MemberLists);
          this.setState({ MemberLists });
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (businessId) {
          toastAlert("top-end", error.response.data.message, "error");
        }
      });
  };

  handleChange = (selectedOption: any) => {
    this.setState({ role: selectedOption.value });
  };

  channelChange = (channel: any) => {
    const channelVal = channel.map((channelData: any) => channelData.value);
    this.setState((prevState: any) => ({
      selectedchannel: channelVal,
    }));
  };

  codeChange = (e: string) => {
    this.setState({ code: e });
  };

  handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };

  fetchData = async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any[]; totalPages: number }> => {
    const { businessId } = this.props;
    const { searchTerm } = this.state;
    // const currentData = {
    //   pageIndex: pageIndex,
    //   pageSize: pageSize,
    //   searchText: searchText,
    //   currentPage: currentPage,
    // };
    // if (currentData) {
    //   this.setState({ paginationPaload: currentData });
    // }

    const role = await axios.get(`${businessId}/getRoles`);
    this.setState({ roleOptions: role?.data });

    const MemberList = {
      businessUid: businessId,
      limit: pageSize,
      page: searchTerm ? 0 : currentPage,
      status: ["ACTIVE", "PENDING", 'DELETED'],
      search: searchTerm,
    };

    const res = await axios.post("member/getMembers", MemberList);
    const list = res?.data?.dataDTO
      ?.map((member: any) => ({
        ...member,
        roleName: member?.role?.name,
        mobileNumber: member?.country_code
          ? (member?.country_code.includes("+")
            ? member?.country_code
            : "+" + member?.country_code) +
          " " +
          member?.mobileNumber
          : "" + member?.mobileNumber,
      }));;
    const count = res?.data?.count;
    const countData = searchTerm ? list?.length : count;
    this.setState({ MemberCount: countData })
    if (!searchTerm) {
      const countdata = (res?.data?.active + res?.data?.pending + res?.data?.inactive);
      this.setState({ membersCountBasedOnType: countdata });
    }
    if (list) {
      this.setState({ MemberLists: list });
    }
    // if(currentPage){
    //   this.setState({currentPageCount:currentPage})
    // }
    const filteredData = searchText
      ? list?.filter((item: any) => item.name.includes(searchText))
      : list;
    const totalPages = Math.ceil(list?.length / pageSize);
    const paginatedData = filteredData?.slice(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    );
    return Promise.resolve({ data: paginatedData, totalPages });
  };

  handleNavigation = () => {
    const { navigate } = this.state;
    if (navigate) {
      navigate(`${process.env.PUBLIC_URL}/subscription?plan=subscribe`)
    }
  };

  render() {
    const {
      showContent,
      showEdit,
      MemberLists,
      MemberEdit,
      code,
      roleOptions,
      channelOptions,
      searchTerm
    } = this.state;
    let roleOption: any;
    if (roleOptions) {
      roleOption = roleOptions.map((role: any) => role.name);
    }
    let channelOption: any;
    if (channelOptions) {
      channelOption = channelOptions?.map((channel: any) => ({
        label: channel.label,
        value: channel.value,
      }));
    }

    this.columns = [
      {
        Header: (
          <input
            type="checkbox"
            hidden={true}
          // checked={this.state.allChecked}
          // onChange={this.toggleAllCheckboxes}
          />
        ),
        accessor: "checkbox",
        // Cell: this.checkboxCellRenderer,
        sticky: "left",
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell, row }: any) => this.nameData(cell.value, row.original),
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
        Cell: ({ cell, row }: any) => this.mobileData(cell.value, row.original),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
        grid: "third-column",
      },
      {
        Header: "Role",
        accessor: "roleName",
        Cell: ({ cell, row }: any) => this.roleData(cell.value, row.original),
      },
      {
        Header: "Last Logged In",
        accessor: "lastLogInTime",
        Cell: ({ cell, row }: any) =>
          cell.value ? moment(cell.value).format("DD MMM hh.mm A") : '-',
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell, row }: any) => this.statusData(cell.value, row.original),
      },
      ...(this.state?.profileDetail?.role?.name === "OWNER"
        ? [{
          Header: "Actions",
          accessor: "edit",
          Cell: ({ cell, row }: any) =>
            this.renderEditColumn(cell.value, row.original),
          grid: "col-md-1",
        }]
        : [])
    ] as any;

    const MemberDatas = MemberLists;

    const sidebarStyle = {
      backgroundColor: "white",
      width: "425px", // Set the desired background color here
      right: "-1px",
    };
    const options = {
      headerAlign: "center",
      noDataText: "No records found",
      sortIndicator: false,
      hidePageListOnlyOnePage: true,
      clearSearch: false,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      sizePerPage: 10,
      paginationPosition: "bottom", // default is bottom, top and both is all available
    };
    return (
      <React.Fragment>
        <div className="member-container">
          <div>
            <div className="">
              <div className="global-table-title">
                {/* <h5>Members</h5> */}
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={this.state.tab === "1" ? "active" : ""}
                      onClick={() => {
                        this.setState({ tab: "1" });
                      }}
                    >
                      Members
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.tab === "2" ? "active" : ""}
                      onClick={() => {
                        this.setState({ tab: "2" });
                      }}
                    >
                      Teams
                    </NavLink>
                  </NavItem>
                  {this.props.userRole && (this.props.userRole.role?.name?.toLowerCase() === "owner" ||
                    this.props.userRole.role?.name?.toLowerCase() === "admin") &&
                    <NavItem>
                      <NavLink
                        className={this.state.tab === "3" ? "active" : ""}
                        onClick={() => {
                          this.setState({ tab: "3" });
                        }}
                      >
                        Roles
                      </NavLink>
                    </NavItem>
                  }
                </Nav>
              </div>
            </div>
            {this.state.tab === "1" ? (
              <div>
                <Row className="pt-3 pb-2">
                  <Col md={3}>
                    <SearchInput
                      component="members"
                      onSearchChange={this.onSearchChange}
                      placeHolder="Search Members"
                    />
                  </Col>
                  <Col></Col>
                  <Col md={2}>
                    {this.state.membersCountBasedOnType >=
                      (this.state.profileDetail?.business?.plan?.members +
                        (this.state.profileDetail?.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity ?
                          this.state.profileDetail?.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity : 0)
                      ) ||
                      this.state.profileDetail?.role?.name === "MEMBER" ? (
                      <TooltipReference
                        placement="left"
                        content={
                          this.state.profileDetail?.role?.name === "MEMBER"
                            ? "Member cannot add members"
                            : this.state.membersCountBasedOnType >= (this.state.profileDetail.business?.plan?.members +
                              (this.state.profileDetail.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity ?
                                this.state.profileDetail.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity : 0)
                            )
                              ? `You cannot add more than ${(this.state.profileDetail.business?.plan?.members +
                                (this.state.profileDetail.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity ?
                                  this.state.profileDetail.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity : 0)
                              )
                              } members in ${this.state.profileDetail?.business?.plan?.name
                              }`
                              : ""
                        }
                        tooltipId={"member"}
                      >
                        <Button
                          onClick={this.handleClickAdd}
                          variant=""
                          type="button"
                          className="sendButton mt-0 w-100"
                          disabled={
                            this.state.membersCountBasedOnType >= (this.state.profileDetail.business?.plan?.members +
                              (this.state.profileDetail.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity ?
                                this.state.profileDetail.business?.plan?.data?.find((add: any) => add.addon_code === getAddonCode('members'))?.quantity : 0)
                            ) ||
                            this.state.profileDetail?.role?.name === "MEMBER" || this.state.isInvalidAccess
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add New Member
                        </Button>
                      </TooltipReference>
                    ) : (
                      <span className={`${this.state.isInvalidAccess ? "notAllowed" : ""}`} >
                      <Button
                        onClick={this.handleClickAdd}
                        variant=""
                        type="button"
                        className="sendButton mt-0 w-100"
                        disabled={this.state.isInvalidAccess}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add New Member
                      </Button>
                      </span>
                    )}
                    {showContent && (
                      <RightSidebar
                        handleCancel={this.handleCancel}
                        title={!showEdit ? "Add Member" : "Edit Member"}
                        width="30%"
                      >
                        {!showEdit ? (
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={this.handleSubmit}
                            validateOnMount={true}
                          >
                            {({ isSubmitting, values, setFieldValue }) => (
                              console.log('is',isSubmitting),
                              <Form className="centered-form-temp">
                                <FormBootstrap.Group className="form-group">
                                  <FormBootstrap.Label className="pt-3">
                                    Name <span className="reqIcon">*</span>
                                  </FormBootstrap.Label>
                                  <Field
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    placeholder="Enter your name"
                                    className="form-control"
                                    maxLength={60}
                                    onKeyDown={this.handleKeyDown}
                                    autoComplete="nope"
                                  />
                                  <ErrorMessage
                                    name="firstname"
                                    component="div"
                                    className="text-danger"
                                  />
                                  <FormBootstrap.Label className="pt-3">
                                    Mobile number{" "}
                                    <span className="reqIcon">*</span>
                                  </FormBootstrap.Label>
                                  <div className="input-group inputBorder">
                                    <div className="input-group-prepend  ms-1 me-3">
                                      <FlagInput
                                        countryCode={code}
                                        codeChange={this.codeChange}
                                      />
                                    </div>
                                    <Field
                                      type="text"
                                      name="mobileNumber"
                                      id="mobileNumber"
                                      placeholder="Enter your mobile number"
                                      className="form-control"
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        let value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        if (value.length <= 13) {
                                          setFieldValue("mobileNumber", value);
                                        }
                                      }}
                                      // autoComplete="off"
                                      autoComplete="nope"
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="text-danger"
                                  />
                                  <FormBootstrap.Label className="pt-3">
                                    Email <span className="reqIcon">*</span>
                                  </FormBootstrap.Label>
                                  <Field
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    className="form-control"
                                    autoComplete="nope"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="text-danger"
                                  />
                                  <FormBootstrap.Label className="pt-3">
                                    Role <span className="reqIcon">*</span>
                                  </FormBootstrap.Label>
                                  <Field name="role">
                                    {({ field, form }: any) => (
                                      <Select
                                        {...field}
                                        name="role"
                                        options={roleOption.map(
                                          (role: any) => ({
                                            value: role,
                                            label: role,
                                          })
                                        )}
                                        // value={{
                                        //   value:values?.role,
                                        //   label:values?.role
                                        // }}
                                        value={roleOption.find(
                                          (option: any) =>
                                            option.value === values.role
                                        )}
                                        placeholder="Select role"
                                        onChange={(selectedOption: any) => {
                                          this.handleChange(selectedOption);
                                          setFieldValue(
                                            "role",
                                            selectedOption?.value
                                          );
                                        }}
                                        autoComplete="nope"
                                      />
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name="role"
                                    component="div"
                                    className="text-danger pt-1"
                                  />
                                  <FormBootstrap.Label className="pt-3">
                                    Channels
                                  </FormBootstrap.Label>
                                  <Field name="channels">
                                    {({ field, form }: any) => (
                                      <Select
                                        {...field}
                                        isMulti
                                        // isClearable={value.some((v) => !v.isFixed)}
                                        closeMenuOnSelect={true}
                                        name="channels"
                                        options={channelOption.map(
                                          (channel: any) => ({
                                            value: channel.value,
                                            label: channel.label,
                                          })
                                        )}
                                        onChange={(channel: any) => {
                                          this.channelChange(channel);
                                          setFieldValue(
                                            "channels",
                                            channel?.value
                                          );
                                        }}
                                        autoComplete="nope"
                                      />
                                    )}
                                  </Field>
                                </FormBootstrap.Group>
                                <div className="addCancelBtn">
                                  <Button
                                    variant=""
                                    type="button"
                                    className="cancelButton me-2"
                                    onClick={this.handleCancel}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant=""
                                    type="submit"
                                    className="sendButton"
                                    disabled={isSubmitting}
                                  >
                                    Add Member
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        ) : (
                          <>
                            {MemberEdit !== null && (
                              <EditMember
                                memberEdit={MemberEdit}
                                handleCancel={this.handleCancel}
                                fetchMembers={this.fetchMembers}
                                roleOptions={this.state.roleOptions}
                              />
                            )}
                          </>
                        )}
                      </RightSidebar>
                    )}
                  </Col>
                </Row>
                {/* </div> */}
                {this.state.isLoading ? (
                  <div className="centerItemsWithWidth">
                    <div className="spinner-border text-success" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <BizTable
                      columns={this.columns}
                      fetchData={this.fetchData}
                      // initialPageSize={50}
                      refetchData={MemberLists}
                      counts={this.state.MemberCount}
                      tableName="MEMBERS"
                    />
                  </>
                  // <BootstrapTable
                  //   data={MemberDatas}
                  //   search
                  //   pagination={MemberDatas?.length > 10}
                  //   version="4"
                  //   striped
                  //   hover
                  //   options={options as any}
                  //   containerStyle={{ textAlign: "left", marginTop: '4px' }}
                  // >
                  //   <TableHeaderColumn dataField="id" isKey hidden>
                  //     #
                  //   </TableHeaderColumn>
                  //   <TableHeaderColumn
                  //     dataField="name"
                  //     dataAlign="left"
                  //     dataFormat={this.nameData}
                  //     width="80"
                  //   >
                  //     Name
                  //   </TableHeaderColumn>
                  //   <TableHeaderColumn dataField="mobileNumber" dataAlign="left" dataFormat={this.mobileData}
                  //     width="100">
                  //     Mobile number
                  //   </TableHeaderColumn>
                  //   <TableHeaderColumn
                  //     dataField="email"
                  //     dataAlign="left"
                  //     dataFormat={this.emailData}
                  //     width="180"
                  //   >
                  //     Email
                  //   </TableHeaderColumn>
                  //   <TableHeaderColumn
                  //     dataField="roleName"
                  //     tdStyle={{ textTransform: "capitalize" }}
                  //     dataAlign="left"
                  //     width="90"
                  //   >
                  //     Role
                  //   </TableHeaderColumn>
                  //   <TableHeaderColumn
                  //     dataField="lastLogInTime"
                  //     dataAlign="left"
                  //     dataFormat={this.formatCreatedOn}
                  //     width="140"
                  //   >
                  //     Last logged in
                  //   </TableHeaderColumn>
                  //   <TableHeaderColumn
                  //     dataField="status"
                  //     dataAlign="center"
                  //     // tdStyle={{ textTransform: "lowercase" }}
                  //     dataFormat={this.statusData}
                  //     width="100"
                  //   >
                  //     Status
                  //   </TableHeaderColumn>
                  //   {this.state.profileDetail?.role.name === 'OWNER' ? <TableHeaderColumn
                  //     dataField="edit"
                  //     dataFormat={this.renderEditColumn}
                  //     dataAlign="center"
                  //     width="100"
                  //   >
                  //     Actions
                  //   </TableHeaderColumn> : null}
                  // </BootstrapTable>
                )}
              </div>
            ) : this.state.tab === "2" ? (
              <CreateGroup isInvalidAccess={this.state.isInvalidAccess} />
            ) : (this.props.userRole?.business?.plan?.name?.trim() === "Ultimate Plan" ? <Roles isInvalidAccess={this.state.isInvalidAccess} /> : <>
              <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
  <p>This feature is not available for your current plan</p>
  <Button className='signUpBtn mt-3' onClick={() => this.handleNavigation()}>Upgrade</Button>
</div>

            </>)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    businessId: state.cartreducer.business?.business?.uid,
    flagData: state.cartreducer.flagData,
    roleData: state.cartreducer.roleData,
    channelData: state.cartreducer.channelData,
    userRole: state.cartreducer.business,
    roleRules: state.cartreducer.roleRules,
  };
};

const mapDispatchToProps = {
  updateMemberData: MemberData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Members));
