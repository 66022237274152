import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';

import classes from './edge.module.css';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { deleteEdge } from '../../../store/graphSlice';
import { useContext, useEffect, useState } from 'react';
import flowContext from '../../../context/flowContext';
import { toast } from '../../../../common/alert';
import styles from "../../bots/createBot.module.scss";

export default function Edge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    animated
}: any) {
    const dispatch = useAppDispatch();
    const flowCtx = useContext(flowContext);
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        animated
    });
    
    function onEdgeClick(evt: any, id: any) {
        dispatch(deleteEdge({ id }));
        evt.stopPropagation();
        toast('success', 'Connection deleted');
    };

    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const roleRules = useAppSelector((state: any) => state.meta.roleRules);
  
    useEffect(() => {  
      if(roleRules) {
        const isInvalidAccess = roleRules.canViewBots === true && roleRules.canManageBots === false;
        setIsInvalidAccess(isInvalidAccess);
      }
    }, [roleRules]);

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} animated={true} />
            {!flowCtx.readOnly ? (
                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            fontSize: 12,
                            pointerEvents: 'all',
                        }}
                        className="nodrag nopan"
                    >
                        <button className={`${classes.edgebutton} ${isInvalidAccess ? styles.isDisabled : ""}`} onClick={(event) => onEdgeClick(event, id)}>
                            ×
                        </button>
                    </div>
                </EdgeLabelRenderer>
            ) : null}
        </>
    );
}