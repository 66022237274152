import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IEditorProps } from '../types';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList';
import { IIntegrationApp } from '../../../../entity/integration/integrationApp';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { googleSheetIcon, razorPayIcon, zohoCRMIcon } from '../../../../icons';
import styles from './googleSheets.module.scss';
import { Button, Offcanvas, Spinner, Form, FormControl, Accordion, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IntegrationConnection from './integrationConnection';
import ActionsField from '../../../../entity/integration/actionsField';
import ReactSelect from 'react-select';
import { Label } from 'reactstrap';
import SelectIntegrationAction from './selectIntegrationAction';
import bg from '../catalogue/sendProducts.module.scss';
import axios from '../../../../../utils/axios';
import classes from './shopify.module.scss';
import AddVariable from '../addVariable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { BsInfoCircleFill } from 'react-icons/bs';
import initialContext from '../useContext';
import SyncButton from '../../../../../common/syncButton';
import { toast } from '../../../../../common/alert';
import { CreateSelectVariable } from './createSelectVariable';


interface FormData {
    integrationData: {
        app: IIntegrationApp,
        scope: 'COM' | 'IN'
        connectionId: number | null
        action: IIntegrationActionListId | null
        spreadSheetId: string
        sheetName: string
        rowValues: string[]
        spreadSheetName: string
        sheetId: string
        columnValues: string[],
        successTargetId: string,
        failTargetId: string,
        emailId: string,
        fieldsLength: number,
        range: string,
        referenceRange: string
    }
}



function GoogleSheets(props: IEditorProps) {

    const [isCreateScreen, setIsCreateScreen] = useState(false);
    const [isEditScreen, setEditScreen] = useState(true);
    const [spreadSheetData, setSpreadSheetData] = useState<any>([]);
    const [spreadsheetOptions, setSpreadsheetOptions] = useState([]);
    const [sheetOptions, setSheet] = useState([]);
    const initial = useContext(initialContext);
    const [formData, setFormData] = useState<FormData>({
        integrationData: {
            app: 'GoogleSheets',
            scope: 'COM',
            connectionId: null,
            action: null,
            spreadSheetId: '',
            sheetName: '',
            rowValues: [],
            spreadSheetName: '',
            sheetId: '',
            columnValues: [],
            successTargetId: '',
            failTargetId: '',
            emailId: '',
            fieldsLength: 0,
            range: '',
            referenceRange: ''
        }
    })
    const actionClenup = () => {
        (window as any).actionFields = null;
        (window as any).fieldsInit = false;
        (window as any).selectedModule = null;
        (window as any).selectedModuleId = null;
        // zohoModuleFieldsQuery.remove()
    }
    useEffect(() => {
        init(setFormData)
        return actionClenup
    }, [])

    const { init, saveElementChanges } = useElmentEditor({
        type: 'integration/GoogleSheets',
        data: formData
    }, props)

    const schema = yup.object().shape({
        integrationData: yup.object().shape({
            connectionId: yup.string().required('Connection is required'),
            action: yup.string().required('Action is required'),
            spreadSheetId: yup.string().required('SpreadSheet is required'),
            sheetName: yup.string().required('Sheet Name is required'),
            rowValues: yup.array()
                .of(yup.string())
                .test(
                    'at-least-one-non-empty',
                    'At least one column value is required',
                    (rowValues: any) => rowValues.some((value: any) => value?.length > 0)
                ).test(
                    'match-fields-length-row',
                    function () {
                        const action = this.parent.action;
                        const fieldsLength = this.parent.fieldsLength;
                        const nonEmptyRowValues = this.parent.rowValues.filter((value: any) => value?.length > 0).length;
                        if ((action === 'addNewRow' || action === 'updateRecord' || action === 'fetchRecord') && nonEmptyRowValues < fieldsLength) {
                            return this.createError({
                                message: 'value need to be entered'
                            });
                        }
                        return true;
                    }
                ),
            columnValues: yup.array()
                .of(yup.string())
                .test(
                    'at-least-one-non-empty',
                    'At least one column name is required',
                    (columnValues: any) => columnValues.some((value: any) => value?.length > 0)
                ).test(
                    'match-fields-length-column',
                    function () {
                        const fieldsLength = this.parent.fieldsLength;
                        const action = this.parent.action;
                        const nonEmptyColumnValues = this.parent.columnValues.filter((value: any) => value?.length > 0).length;
                        if ((action === "addNewRow" || action === "updateRecord" || action === "fetchRecord") && nonEmptyColumnValues < fieldsLength) {
                            return this.createError({
                                message: 'column name need to be selected'
                            });
                        }
                        return true;
                    }
                ),
            fieldsLength: yup.string().nullable(),
            range: yup.string().nullable()
        }),
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >

            {({ handleSubmit, setFieldValue, values, errors, touched, setValues, handleChange }) => {

                const [isAccordionOpen, setIsAccordionOpen] = useState(true);
                const [spreadSheetValue, setSpreadSheetValue] = useState<any>([]);
                const [state, setState] = useState(true);
                const [selectedSheet, setSelectedSheet] = useState<any>([]);
                const [columnOptions, setColumn] = useState([]);
                const [userProfile, setUserProfile] = useState('');
                const [code, setCode] = useState<string>('91');
                const [isSynced, setIsSynced] = useState(false);
                const [fields, setFields] = useState<any>([
                    [{ id: 'column1', label: 'Column Name', required: true, type: "column" },
                    { id: 'value1', label: 'Value', required: true }]
                ]);

                const [updateFields, setUpdateFields] = useState<any>([
                    [
                        { id: 'refColumn1', label: 'Reference Column Name', required: true, type: "column" },
                        { id: 'refValue1', label: 'Reference Value', required: true },
                        { id: 'column1', label: 'Column Name', required: true, type: "column" },
                        { id: 'value1', label: 'Value', required: true, value:"" }
                    ]
                ]);

                const addFieldSetWithReference = () => {
                    const newId = `column${updateFields.length + 1}`;
                    setUpdateFields((prevFields: any) => [
                        ...prevFields,
                        [
                            { id: newId, label: 'Column Name', required: true, type: "column" },
                            { id: `value${updateFields.length + 1}`, label: 'Value', required: true }
                        ]
                    ]);
                };

                const handleUpdateFieldChange = (groupIndex: number, id: string, value: any) => {
                    setUpdateFields((prevFields: any) =>
                        prevFields.map((group: any, index: any) =>
                            index === groupIndex
                                ? group.map((field: any) =>
                                    field.id === id
                                        ? field.type === 'column'
                                            ? { ...field, value: { label: value, value: value } }
                                            : { ...field, value }
                                        : field
                                )
                                : group
                        )
                    );
                };


                // const handleUpdateFieldChangeVariable = (groupIndex: number, id: string, value: any) => {
                //     setUpdateFields((prevFields: any) =>
                //         prevFields.map((group: any, index: any) =>
                //             index === groupIndex
                //                 ? group.map((field: any) =>
                //                     field.id === id
                //                         ? field.type === 'column'
                //                             ? { ...field, value: { label: value, value: value } }
                //                             : field.id === "refValue1" ? { ...field, value } : { ...field, value: { label: value, value: value } }
                //                         : field
                //                 )
                //                 : group
                //         )
                //     );
                // };

                useEffect(() => {
                    if (values.integrationData?.spreadSheetName && values.integrationData?.spreadSheetId && spreadSheetValue?.length === 0) {
                        const value = [{
                            label: values?.integrationData?.spreadSheetName,
                            value: values?.integrationData?.spreadSheetId
                        }]
                        setSpreadSheetValue(value);
                    }
                }, [values?.integrationData?.spreadSheetName, values?.integrationData?.spreadSheetId])

                useEffect(() => {
                    if (values.integrationData?.sheetName && values.integrationData?.sheetId && selectedSheet?.length === 0) {
                        const value = [{
                            label: values?.integrationData?.sheetName,
                            value: values?.integrationData?.sheetId
                        }]
                        setSelectedSheet(value);
                    }
                }, [values?.integrationData?.sheetName, values?.integrationData?.sheetId])

                useEffect(() => {
                    if (values?.integrationData?.rowValues?.length > 0 && values?.integrationData?.columnValues?.length > 0 && values?.integrationData?.action === 'addNewRow' && initial.edit) {
                        const rowValues = values?.integrationData?.rowValues || [];
                        const columnValues = values?.integrationData?.columnValues || [];
                        const filteredRows = rowValues
                            .map((row: any, index: any) => ({ row, columnValue: columnValues[index] }))
                            .filter(({ row }) => row !== '');
                        const updatedFields = filteredRows.map(({ row, columnValue }: any, index: any) => [
                            {
                                id: `column${index + 1}`,
                                label: 'Column Name',
                                required: true,
                                type: "column",
                                value: {
                                    label: columnValue,
                                    value: columnValue
                                }
                            },
                            {
                                id: `value${index + 1}`,
                                label: 'Value',
                                required: true,
                                value: row
                            }
                        ]);

                        setFields(updatedFields);
                        initial.edit = false;
                    }
                }, [values?.integrationData?.rowValues, values?.integrationData?.columnValues]);

                useEffect(() => {
                    if (values?.integrationData?.rowValues?.length > 0 && values?.integrationData?.columnValues?.length > 0 && (values?.integrationData?.action === 'updateRecord' || values?.integrationData?.action === 'fetchRecord') && initial.edit) {
                        const rowValues = values?.integrationData?.rowValues || [];
                        const columnValues = values?.integrationData?.columnValues || [];
                        const updatedFields = [];
                        const refRowValue = rowValues[0];
                        const refColumnValue = columnValues[0];

                        for (let i = 0; i < columnValues.length; i++) {
                            const columnValue = i === 0 ? columnValues[i + 1] : columnValues[i];
                            const rowValue = i === 0 ? rowValues[i + 1] : rowValues[i];

                            const fieldGroup = i === 0 ? [{
                                id: `refColumn1`,
                                label: 'Reference Column Name',
                                required: true,
                                type: "column",
                                value: {
                                    label: refColumnValue,
                                    value: refColumnValue
                                }
                            },
                            {
                                id: `refValue1`,
                                label: 'Reference Value',
                                required: true,
                                value: refRowValue
                            },
                            {
                                id: `column${i + 1}`,
                                label: 'Column Name',
                                required: true,
                                type: "column",
                                value: {
                                    label: columnValue,
                                    value: columnValue
                                }
                            },
                            {
                                id: `value${i + 1}`,
                                label: 'Value',
                                required: true,
                                value: rowValue
                            }] : [
                                {
                                    id: `column${i + 1}`,
                                    label: 'Column Name',
                                    required: true,
                                    type: "column",
                                    value: {
                                        label: columnValue,
                                        value: columnValue
                                    }
                                },
                                {
                                    id: `value${i + 1}`,
                                    label: 'Value',
                                    required: true,
                                    value: rowValue
                                }
                            ];

                            updatedFields.push(fieldGroup);
                            if (i === 0) {
                                i += 1;
                            }
                        }
                        setUpdateFields(updatedFields);
                        initial.edit = false;
                    }
                }, [values?.integrationData?.rowValues, values?.integrationData?.columnValues]);

                useEffect(() => {
                    if (spreadsheetOptions?.length > 0 && sheetOptions?.length === 0 && values?.integrationData?.spreadSheetId) {
                        const selected = spreadsheetOptions?.find((item: any) => item?.value === values?.integrationData?.spreadSheetId)
                        setSheetOptions(selected);
                        setSpreadSheetValue(selected);
                    }
                }, [spreadSheetData, values.integrationData?.spreadSheetId])

                useEffect(() => {
                    if (spreadSheetData?.length > 0 && sheetOptions?.length > 0 && values?.integrationData?.sheetName && (columnOptions?.length === 0 || isSynced)) {
                        const selected = sheetOptions.find((item: any) => item?.label === values?.integrationData?.sheetName);
                        setColumnOptions(selected);
                        setIsSynced(false);
                    }
                }, [spreadSheetData, values?.integrationData?.sheetName, sheetOptions])
                function getColumnLetter(index: number): string {
                    let letter = '';
                    while (index >= 0) {
                        letter = String.fromCharCode((index % 26) + 65) + letter;
                        index = Math.floor(index / 26) - 1;
                    }
                    return letter;
                }

                function getRangeFromColumnValues(columnValues: string[], columnOptions: { label: string, value: string }[], isLetter: boolean): string {
                  
                    const rangeIndices = columnValues
                        .map(value => {
                            const optionIndex = columnOptions.findIndex(option => option.label === value);
                            if (optionIndex !== -1) {
                                return optionIndex;
                            }
                            return null;
                        })
                        .filter(index => index !== null)
                    const result = isLetter ? rangeIndices.map(range => { return getColumnLetter(range) }) : rangeIndices;
                    return result.join(":");
                }


                const handleSave = () => {
                    console.log("errors", errors)
                    if (values?.integrationData?.action === "addNewRow") {
                        const rowValues = columnOptions?.map((option: any) => {
                            const matchingValue = fields?.find((entry: any) => entry[0].value?.label === option.value);
                            return matchingValue ? matchingValue[1].value : "";
                        });
                        const columnValues = columnOptions?.map((option: any) => {
                            const matchingLabel = fields?.find((entry: any) => entry[0].value?.label === option.value);
                            return matchingLabel ? matchingLabel[0].value.label : "";
                        });
                        setFieldValue('integrationData.rowValues', rowValues);
                        setFieldValue('integrationData.columnValues', columnValues);
                        setFieldValue('integrationData.fieldsLength', fields?.length);
                    }

                    else if (values?.integrationData?.action === "updateRecord") {
                        let columnValues: string[] = [];
                        let rowValues: string[] = [];

                        updateFields.forEach((fieldGroup: any) => {
                            fieldGroup.forEach((field: any) => {
                                if (field.type === "column") {
                                    field?.value?.label && columnValues.push(field?.value?.label);
                                } else {
                                    field?.value && rowValues.push(field?.value);
                                }
                            });
                        });
                        setFieldValue('integrationData.rowValues', rowValues);
                        setFieldValue('integrationData.columnValues', columnValues);
                        const range = getRangeFromColumnValues(columnValues?.slice(1), columnOptions, false);
                        setFieldValue('integrationData.range', range);
                        const refRange = getRangeFromColumnValues([columnValues[0]], columnOptions, true);
                        setFieldValue('integrationData.referenceRange', refRange + ":" + refRange);
                        setFieldValue('integrationData.fieldsLength', updateFields?.length + 1);
                    }

                    else if (values?.integrationData?.action === "fetchRecord") {
                        let columnValues: string[] = [];
                        let rowValues: string[] = [];

                        updateFields.forEach((fieldGroup: any) => {
                            fieldGroup.forEach((field: any) => {
                                if (field.type === "column") {
                                    field?.value?.label && columnValues.push(field?.value?.label);
                                } else {
                                    field?.value && rowValues.push(field?.value);
                                }
                            });
                        });
                        setFieldValue('integrationData.rowValues', rowValues);
                        setFieldValue('integrationData.columnValues', columnValues);
                        const range = getRangeFromColumnValues(columnValues?.slice(1), columnOptions, true);
                        setFieldValue('integrationData.range', range);
                        const refRange = getRangeFromColumnValues([columnValues[0]], columnOptions, true);
                        setFieldValue('integrationData.referenceRange', refRange + ":" + refRange);
                        setFieldValue('integrationData.fieldsLength', updateFields?.length + 1);
                    }
                }

                const getSpreadSheetData = async (id: number) => {
                    try {
                        const response = await axios.post(`integration/GoogleSheets/getSpreadSheets/${id}`);
                        const data = response.data;
                        setSpreadSheetData(data);
                        setState(false);
                        const options = data?.map((sheet: any) => ({
                            label: sheet.spreadsheetName,
                            value: sheet.spreadsheetId
                        }));
                        setSpreadsheetOptions(options);
                        if (values?.integrationData?.spreadSheetId) {
                            const selected = options?.find((item: any) => item?.value === values?.integrationData?.spreadSheetId)
                            setSheetOptions(selected);
                        }
                        return data;
                    } catch (error) {
                        console.error(error);
                        throw error;
                    }
                }

                const getUserProfile = async (id: number) => {
                    try {
                        const response = await axios.post(`integration/GoogleSheets/getProfileInfo/${id}`);
                        const data = response.data;
                        setUserProfile(data);
                        setFieldValue('integrationData.emailId', data);
                        return data;
                    } catch (error) {
                        console.error(error);
                        throw error;
                    }
                }

                useEffect(() => {
                    setValues(formData)
                }, [formData, setValues])

                useEffect(() => {
                    values.integrationData?.connectionId && getSpreadSheetData(values?.integrationData?.connectionId);
                    values.integrationData?.connectionId && getUserProfile(values?.integrationData?.connectionId);
                }, [values.integrationData.connectionId])

                useEffect(() => {
                    if (values?.integrationData?.emailId) {
                        setUserProfile(values?.integrationData?.emailId);
                    }
                }, [values?.integrationData?.emailId])

                const addFieldSet = () => {
                    const newId = `column${fields.length + 1}`;
                    setFields((prevFields: any) => [
                        ...prevFields,
                        [{ id: newId, label: 'Column Name', required: true, type: "column" },
                        { id: `value${fields.length + 1}`, label: 'Value', required: true }
                        ]
                    ]);
                };
                const handleFieldChange = (groupIndex: number, id: string, value: any) => {
                    setFields((prevFields: any) =>
                        prevFields.map((group: any, index: any) =>
                            index === groupIndex
                                ? group.map((field: any) =>
                                    field.id === id ? field.type === 'column' ? { ...field, value: { label: value, value: value } } : { ...field, value } : field
                                )
                                : group
                        )
                    );
                };

                const onGoBackHandler = () => {
                    if (values.integrationData.action) {
                        setFieldValue('integrationData.action', null)
                        actionClenup()
                    } else if (values.integrationData.connectionId) {
                        setFieldValue('integrationData.connectionId', null)
                    } else {
                        props.onClose()
                    }
                }

                const myAction: ActionsListItem | null = useMemo(() => {
                    if (values.integrationData?.connectionId) {
                        (window as any).appConnectionId = values.integrationData?.connectionId
                    }
                    if ('action' in values.integrationData && values.integrationData?.action) {
                        return ACTION_LIST['GoogleSheets'].find(action => action.id === values.integrationData.action) || null
                    }
                    return null
                }, [values])


                const setColumnOptions = (sheet: any) => {
                    const options = spreadSheetData
                        ?.find((spreadSheetObj: any) => spreadSheetObj?.spreadsheetId === spreadSheetValue?.value)
                        ?.sheets?.find((sheets: any) => sheets?.sheetId === sheet?.value)?.columnNames?.map((column: any) => ({
                            label: column,
                            value: column,
                        }));
                    setColumn(options);
                }

                const setSheetOptions = (spreadSheet: any) => {

                    if (spreadSheetData?.length > 0) {
                        const options = spreadSheetData
                            .find((spreadSheetObj: any) => spreadSheetObj?.spreadsheetId === spreadSheet?.value)
                            ?.sheets.map((sheet: any) => ({
                                label: sheet.sheetName,
                                value: sheet.sheetId,
                            }));
                        setSheet(options);
                    }
                }

                const deleteField = (groupIndex: number) => {
                    setFields((prevFields: any) =>
                        prevFields.filter((_: any, index: number) => index !== groupIndex)
                    );
                };

                const deleteUpdateField = (groupIndex: number) => {
                    setUpdateFields((prevFields: any) =>
                        prevFields.filter((_: any, index: any) => index !== groupIndex)
                    );
                };

                const mapFieldsHandler = (field: ActionsField) => {
                    const isError = Boolean(errors.integrationData
                        && field.id in (errors.integrationData as any)
                        && Boolean((errors.integrationData as any)[field.id]))
                    const isTouched = Boolean(touched.integrationData
                        && field.id in (touched.integrationData as any)
                        && Boolean((touched.integrationData as any)[field.id]))

                    //   field.id === "draftOrder_id" ? setFieldValue('variables', [ {name:"", label:"", type:"TEXT"}]) : setFieldValue('variables', []);
                    return (
                        <>
                            {field.id === "spreadSheetId" ? (<>

                                <Form.Group
                                    key={field.id}
                                    className='my-2'
                                >
                                    <Label>{field.label}{field.required === true && <span className='required'></span>}</Label>
                                    <ReactSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                                            menu: base => ({ ...base, width: 420 })
                                        }}
                                        options={spreadsheetOptions}
                                        className="w-100"
                                        placeholder={field?.placeholder ?? ''}
                                        onChange={(selected: any) => {
                                            setSpreadSheetValue(selected);
                                            setSheetOptions(selected);
                                            setFieldValue(`integrationData.${field.id}`, selected.value);
                                            setFieldValue('integrationData.spreadSheetName', selected?.label);
                                            setSelectedSheet([]);
                                        }}
                                        value={spreadSheetValue}
                                        maxMenuHeight={120}
                                    />
                                    {!values?.integrationData?.spreadSheetId && (
                                        <ErrorMessage name={String('integrationData.' + `${field.id}`)} component="div" className='invalid-feedback d-block' />
                                    )}
                                </Form.Group>

                            </>) : field.id === "sheetName" ? (<>

                                <Form.Group
                                    key={field.id}
                                    className='my-2'
                                >
                                    <Label>{field.label}{field.required === true && <span className='required'></span>}</Label>
                                    <ReactSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                                            menu: base => ({ ...base, width: 420 })
                                        }}
                                        options={sheetOptions}
                                        className="w-100"
                                        placeholder={field?.placeholder ?? ''}
                                        onChange={(selected: any) => {
                                            setFieldValue(`integrationData.${field.id}`, selected?.label);
                                            setFieldValue('integrationData.sheetId', selected?.value);
                                            setSelectedSheet(selected);
                                            setColumnOptions(selected);
                                        }}
                                        value={selectedSheet}
                                        maxMenuHeight={120}
                                    />
                                    {!values?.integrationData?.sheetName && (
                                        <ErrorMessage name={String('integrationData.' + `${field.id}`)} component="div" className='invalid-feedback d-block' />
                                    )}
                                </Form.Group>

                            </>) : (
                                <div>
                                    {
                                        values?.integrationData?.action === "fetchRecord" &&
                                        (
                                            <>
                                                <div className='d-flex align-items-center'>
                                                    <Label className={styles.addRowLabel}>Fetch Row Value: </Label>
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={<Tooltip> Select existing column name and its value and then select the same column name for updating with new value</Tooltip>}
                                                    >
                                                        <Button size='sm' variant='default' className='ms-1'>
                                                            <BsInfoCircleFill className={styles.infoIcon} />
                                                        </Button>
                                                    </OverlayTrigger>
                                                    <div className='mb-2'>
                                                        <SyncButton
                                                            onClick={async () => {
                                                                try {
                                                                    values?.integrationData?.connectionId && getSpreadSheetData(values?.integrationData?.connectionId);
                                                                    toast("success", "SpreadSheet synced successfully");
                                                                    setIsSynced(true);
                                                                } catch (error) {
                                                                    console.error(error);
                                                                    toast("error", "SpreadSheets failed to sync");
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                {updateFields.map((group: any, groupIndex: any) => {

                                                    return (
                                                        <div key={groupIndex} className={bg.background}>
                                                            <div className={styles.iconContainer}>
                                                                {updateFields?.length > 1 && group?.length === 2 && (
                                                                    <FontAwesomeIcon
                                                                        icon={faTrashAlt}
                                                                        className={styles.trashIcon}
                                                                        onClick={() => deleteUpdateField(groupIndex)}
                                                                    />
                                                                )}
                                                            </div>
                                                            {group.map((field: any) => (
                                                                <Form.Group key={field.id} className='my-2'>
                                                                    <Label>{field.label}{field.required && <span className='required'></span>}</Label>
                                                                    {field.type === 'column' ? (
                                                                        <>
                                                                            <ReactSelect
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9800 }),
                                                                                    menu: base => ({ ...base, width: 420 })
                                                                                }}
                                                                                options={columnOptions}
                                                                                className="w-100"
                                                                                placeholder="Enter column"
                                                                                onChange={(selected: any) => handleUpdateFieldChange(groupIndex, field.id, selected?.value)}
                                                                                value={field.value}
                                                                                maxMenuHeight={120}
                                                                            />
                                                                            <>
                                                                                {!field.value && (
                                                                                    <ErrorMessage name="integrationData.columnValues" component="div" className='invalid-feedback d-block' />
                                                                                )}
                                                                            </>
                                                                        </>
                                                                    ) : field.id === "refValue1" ? (
                                                                        <>
                                                                            <FormControl
                                                                                name={field.id}
                                                                                required={field.required}
                                                                                as='input'
                                                                                value={field.value || ''}
                                                                                onChange={(e) => handleUpdateFieldChange(groupIndex, field.id, e.target.value)}
                                                                                placeholder="Enter value"
                                                                            />
                                                                            {!field.value && (
                                                                                <ErrorMessage name="integrationData.rowValues" component="div" className='invalid-feedback d-block' />
                                                                            )}
                                                                            <div className={classes.addVariable}>
                                                                                <AddVariable static fieldName='' fieldValue={field.value} handleChange={(value: string) => handleUpdateFieldChange(groupIndex, field.id, value)} />
                                                                            </div>
                                                                        </>
                                                                    ) : (<>

                                                                        <CreateSelectVariable
                                                                            placeholder="Create or Select Variable"
                                                                            name={field.id}
                                                                            value={field.value ?? ''}
                                                                            onChange={(e: any) => handleUpdateFieldChange(groupIndex, field.id, e)}
                                                                            type='TEXT'
                                                                            label={null}
                                                                        />
                                                                        {!field.value && (
                                                                                <ErrorMessage name="integrationData.rowValues" component="div" className='invalid-feedback d-block' />
                                                                        )}
                                                                    </>)}
                                                                </Form.Group>
                                                            ))}
                                                        </div>
                                                    )
                                                })}
                                                {columnOptions?.length > 0 && updateFields?.length < columnOptions?.length && (
                                                    <Button onClick={addFieldSetWithReference} className={styles.smallFont}> Add New Column +</Button>
                                                )}
                                            </>
                                        )
                                    }
                                    {
                                        values?.integrationData?.action === 'updateRecord' &&
                                        (
                                            <div>
                                                <div className='d-flex align-items-center'>
                                                    <Label className={styles.addRowLabel}>Update Row Values: </Label>
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={<Tooltip> Select existing column name and its value and then select the same column name for updating with new value</Tooltip>}
                                                    >
                                                        <Button size='sm' variant='default' className='ms-1'>
                                                            <BsInfoCircleFill className={styles.infoIcon} />
                                                        </Button>
                                                    </OverlayTrigger>
                                                    <div className='mb-2'>
                                                        <SyncButton
                                                            onClick={async () => {
                                                                try {
                                                                    values?.integrationData?.connectionId && getSpreadSheetData(values?.integrationData?.connectionId);
                                                                    toast("success", "SpreadSheet synced successfully");
                                                                    setIsSynced(true);
                                                                } catch (error) {
                                                                    console.error(error);
                                                                    toast("error", "SpreadSheets failed to sync");
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {updateFields.map((group: any, groupIndex: any) => {
                                                    return (

                                                        <div key={groupIndex} className={bg.background}>
                                                            <div className={styles.iconContainer}>
                                                                {updateFields?.length > 1 && group?.length === 2 && (
                                                                    <FontAwesomeIcon
                                                                        icon={faTrashAlt}
                                                                        className={styles.trashIcon}
                                                                        onClick={() => deleteUpdateField(groupIndex)}
                                                                    />
                                                                )}
                                                            </div>
                                                            {group.map((field: any) => (
                                                                <Form.Group key={field.id} className='my-2'>
                                                                    <Label>{field.label}{field.required && <span className='required'></span>}</Label>
                                                                    {field.type === 'column' ? (
                                                                        <>
                                                                            <ReactSelect
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9800 }),
                                                                                    menu: base => ({ ...base, width: 420 })
                                                                                }}
                                                                                options={columnOptions}
                                                                                className="w-100"
                                                                                placeholder="Enter column"
                                                                                onChange={(selected: any) => handleUpdateFieldChange(groupIndex, field.id, selected?.value)}
                                                                                value={field.value}
                                                                                maxMenuHeight={120}
                                                                            />
                                                                            <>
                                                                                {!field.value && (
                                                                                    <ErrorMessage name="integrationData.columnValues" component="div" className='invalid-feedback d-block' />
                                                                                )}
                                                                            </>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <FormControl
                                                                                name={field.id}
                                                                                required={field.required}
                                                                                as='input'
                                                                                value={field.value || ''}
                                                                                onChange={(e) => handleUpdateFieldChange(groupIndex, field.id, e.target.value)}
                                                                                placeholder="Enter value"
                                                                            />
                                                                            {!field.value && (
                                                                                <ErrorMessage name="integrationData.rowValues" component="div" className='invalid-feedback d-block' />
                                                                            )}
                                                                            <div className={classes.addVariable}>
                                                                                <AddVariable static fieldName='' fieldValue={field.value} handleChange={(value: string) => handleUpdateFieldChange(groupIndex, field.id, value)} />
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </Form.Group>
                                                            ))}
                                                        </div>
                                                    )
                                                })}
                                                {columnOptions?.length > 0 && updateFields?.length < columnOptions?.length && (
                                                    <Button onClick={addFieldSetWithReference} className={styles.smallFont}> Add New Column +</Button>
                                                )}
                                            </div>
                                        )
                                    }
                                    {values?.integrationData?.action === "addNewRow" && (
                                        <>
                                            <div className='d-flex align-items-center'>
                                                <Label className={styles.addRowLabel}>Add Row Values: </Label>
                                                <OverlayTrigger
                                                    placement='bottom'
                                                    overlay={<Tooltip> Select Column names and its value to append in the sheet.</Tooltip>}
                                                >
                                                    <Button size='sm' variant='default' className='ms-1'>
                                                        <BsInfoCircleFill className={styles.infoIcon} />
                                                    </Button>
                                                </OverlayTrigger>
                                                <div className='mb-2'>
                                                    <SyncButton
                                                        onClick={async () => {
                                                            try {
                                                                values?.integrationData?.connectionId && getSpreadSheetData(values?.integrationData?.connectionId);
                                                                toast("success", "SpreadSheet synced successfully");
                                                                setIsSynced(true);
                                                            } catch (error) {
                                                                console.error(error);
                                                                toast("error", "SpreadSheets failed to sync");
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {fields.map((group: any, groupIndex: any) => {
                                                const selectedColumns = fields.map((group: any) =>
                                                    group.find((field: any) => field.type === "column")?.value?.value
                                                );

                                                const filteredOptions = columnOptions?.filter(
                                                    (option: any) => !selectedColumns.includes(option.value)
                                                );

                                                return (
                                                    <div key={groupIndex} className={bg.background}>
                                                        <div className={styles.iconContainer}>
                                                            {fields?.length > 1 && (
                                                                <FontAwesomeIcon
                                                                    icon={faTrashAlt}
                                                                    className={styles.trashIcon}
                                                                    onClick={() => deleteField(groupIndex)}
                                                                />
                                                            )}
                                                        </div>

                                                        {group.map((field: any) => (
                                                            <Form.Group key={field.id} className='my-2'>
                                                                <Label>{field.label}{field.required && <span className='required'></span>}</Label>
                                                                {field.type === 'column' ? (
                                                                    <>
                                                                        <ReactSelect
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9800 }),
                                                                                menu: base => ({ ...base, width: 420 })
                                                                            }}
                                                                            options={filteredOptions}
                                                                            className="w-100"
                                                                            placeholder="Enter column"
                                                                            onChange={(selected: any) => handleFieldChange(groupIndex, field.id, selected?.value)}
                                                                            value={field.value}
                                                                            maxMenuHeight={120}
                                                                        />
                                                                        {!field.value && (
                                                                            <ErrorMessage name="integrationData.columnValues" component="div" className='invalid-feedback d-block' />
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FormControl
                                                                            name={field.id}
                                                                            required={field.required}
                                                                            as='input'
                                                                            value={field.value || ''}
                                                                            onChange={(e) => handleFieldChange(groupIndex, field.id, e.target.value)}
                                                                            placeholder="Enter column value"
                                                                        />
                                                                        {!field.value && (
                                                                            <ErrorMessage name="integrationData.rowValues" component="div" className='invalid-feedback d-block' />
                                                                        )}
                                                                        <div className={classes.addVariable}>
                                                                            <AddVariable static fieldName='' fieldValue={field.value} handleChange={(value: string) => handleFieldChange(groupIndex, field.id, value)} />
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <ErrorMessage name={field.id} component="div" className='invalid-feedback d-block' />
                                                            </Form.Group>
                                                        ))}
                                                    </div>
                                                );
                                            })}
                                            {columnOptions?.length > 0 && fields?.length < columnOptions?.length && (
                                                <Button onClick={addFieldSet} className={styles.smallFont}>Add New Column +</Button>
                                            )}
                                        </>
                                    )}
                                </div>)
                            }
                        </>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption
                            onHide={onGoBackHandler}
                            caption={
                                values?.integrationData?.action ? (
                                    <>
                                        {myAction?.label}
                                        <small className="d-block mt-1">{userProfile}</small>
                                    </>
                                ) : (
                                    "Google Sheets"
                                )
                            }
                            icon={<img style={{ width: 16 }}
                                alt=''
                                src={googleSheetIcon}
                            />}
                        />

                        <Offcanvas.Body>

                            {values.integrationData.connectionId ? (
                                values.integrationData.action ? (
                                    <>
                                        {myAction ?
                                            <>
                                                {myAction.fields
                                                    .filter(field => field.required)
                                                    .map(mapFieldsHandler)}
                                                {myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 ? (
                                                    <div className={styles.optionalfields}>
                                                        <Accordion defaultActiveKey={isAccordionOpen ? '0' : null} className="mt-3">
                                                            <Accordion.Item eventKey='0'>
                                                                <Accordion.Header>Optional Fields</Accordion.Header>
                                                                <Accordion.Body>
                                                                    {myAction.fields
                                                                        .filter(field => !field.required)
                                                                        .map(mapFieldsHandler)}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                                ) : null}
                                            </> : null}
                                    </>
                                ) : <SelectIntegrationAction app='GoogleSheets' />) : (
                                <IntegrationConnection app='GoogleSheets' scope="COM" onScreenChange={(screen: any) => { setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit'); }} isInvalidAccess = {props?.isInvalidAccess} />
                            )}

                        </Offcanvas.Body>

                        {(isEditScreen && !isCreateScreen) && values?.integrationData?.connectionId && (
                            <div className='editor-footer'>
                                <Button variant='outline-dark' onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button className='sendButton' type='submit' disabled={isCreateScreen || state || props?.isInvalidAccess} onClick={handleSave}>
                                    Save
                                </Button>
                            </div>
                        )}
                    </Form>
                )
            }}
        </Formik >
    )
}

export default GoogleSheets;