import React,  { useEffect, useState, useRef ,useCallback }  from 'react';
import { getAllOrders, updateOrder, filterOrder} from '../../services/orderService';
import { useSelector } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faEdit, faSpinner, faTimesCircle, faClock, faExternalLinkAlt, faChevronDown, faChevronUp, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import './order.scss';
import RightSidebar from "../../common/rightSidebar";
import { Formik, Field, ErrorMessage, Form , FieldProps, FormikProps} from 'formik';
import { Col, Row, Button, Label, FormGroup, Input } from 'reactstrap';
import Select from 'react-select';
import Avatar from 'react-avatar';
import { Link } from "react-router-dom";
import SearchBox from '../../botFlows/components/ui/searchBox';
import * as originalMoment from 'moment';
import { extendMoment } from "moment-range";
import { BsCalendar, BsFunnel, BsFunnelFill} from 'react-icons/bs';
import { Overlay, Popover } from 'react-bootstrap';
import DateRangePicker from "react-daterange-picker";
import CommonPopover from '../../common/commonPopover';
import { getAll } from '../../services/paymentService';
import * as Yup from 'yup';
import { set } from 'lodash';
import styles from '../../botFlows/components/designFlow/editor/catalogue/sendOrderDetails.module.scss';
import defaultImg from '../../assets/img/imageThumb.png';
import ValidationSettings from "../../botFlows/components/designFlow/editor/askType/validationSettings";
import * as mediaService from '../../botFlows/services/media';
import { BsFillTrashFill } from 'react-icons/bs';
import AxiosInstance from '../../utils/axios';
import { toast } from "../../common/alert";
import BizTable from '../../common/BizTable';
import SearchInput from '../../common/searchComponent';
import {dateSplit} from '../../common/commonfns';
import { ErrorImage } from '../../common/ErrorMessage';
import { MdHeight } from 'react-icons/md';
import excelIcon from "../../assets/img/excel.png"
import * as XLSX from 'xlsx';
import TooltipReference from '../../common/tooltip';


const moment = extendMoment(originalMoment);
interface SelectBtnOption {
  value: string;
  label: string;
}

enum DateFilterType {
  Past7Days = 'Past7Days',
  Past30Days = 'Past30Days',
  Past90Days = 'Past90Days',
  ThisWeek = 'ThisWeek',
  PreviousWeek = 'PreviousWeek',
  ThisMonth = 'ThisMonth',
  PreviousMonth = 'PreviousMonth',
  CustomRange = 'CustomRange',
};

 
const orderStatus = [
  {value: "order_request", label: "new"},
  {value: "pending", label: "pending"},
  {value: "processing", label: "processing"},
  // {value: "failed", label: "failed"},
  {value: "partially_shipped", label: "partially shipped"},
  {value: "shipped", label: "shipped"},
  {value: "completed", label: "completed"},
  {value: "canceled", label: "canceled"},
];

const paymentStatus = [
{value: "new_payment", label: "new"},
{value: "payment_initiated", label: "payment Initiated"},
{value: "paid", label: "paid"},
{value: "cancelled", label: "cancelled"},
{value: "failed", label: "failed"},
];

const time =[
  {value:'Minutes', label:'Minutes'},
  {value:'Hours', label:'Hours'},
]

const Order = () =>{
    const [allData, setAllData] = useState<any>([]);
    const [count, setRecordCount] = useState<any>([]);
    
    const [selectedRow, setRow] = useState<any>([]);
    const [showContent, setContent] = useState<boolean>(false);
    const [pay, setPay] = useState<boolean>(false);
    const [order, setOrder] = useState<boolean>(false);
    const [selectedStatus, setStatus] = React.useState<SelectBtnOption | null>(null);
    const [selectedpayment, setPayment] = React.useState<SelectBtnOption | null>(null);
    const [payMethod, setPayMethod] = React.useState<SelectBtnOption | null>(null);
    const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
    const datepickertarget = useRef(null);
    const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const today = moment().clone().startOf('day');
    const [dateRangeValue, setDateRangeValue] = useState(
      moment.range(today.clone().subtract(6, "days").startOf('day'), today.endOf('day'))
  );  
  const [search, setSearch] = useState("");
    const [showPop, setpop] = useState<boolean>(false);
    const [showdate, setdate] = useState<boolean>(false);

    const [filterPayment, setFilterPay] = useState<any>([]);
    const [filterStatus, setFilterStatus] = useState<any>([]);
    const [afterOrder, setAfterOrder] = useState<any>([]);
    const [afterpayment, setAfterPayment] = useState<any>([]);

    const [allpayment, setpayment] = useState<any>([]);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [bodyMsg, setBody] = useState<any>([]);
    const [headCount, setHeadCount] = useState(0);
    const [charCount, setCount] = useState(0);
    const [footerCount, setFooterCount] = useState(0);
    const [taxCount, setTax] = useState(0);
    const [shipCount, setShip] = useState(0);
    const [discountCount, setDiscount] = useState(0);
    const [nameCount, setName] = useState(0);
    const [isTaxVisible, setIsTaxVisible] = useState(false);
    const [isShippingVisible, setIsShippingVisible] = useState(false);
    const [isDiscountVisible, setIsDiscountVisible] = useState(false);
    const [isOpen, setValidateOpen] = useState(false);
    const [media, setMedia] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const roleRules = useSelector((state: any) => state.cartreducer.roleRules);
  
    useEffect(() => {  
      if(roleRules) {
        const isInvalidAccess = roleRules.canViewOrders === true && roleRules.canViewAndManageOrders === false;
        setIsInvalidAccess(isInvalidAccess);
      }
    }, [roleRules]);

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files ? e.target.files[0] : null;
      if (file) {
        await uploadFile(file);
      }
    };
  
    const uploadFile = async (file: File) => {
      const allowedSize: number = 5;
      if (file.size > allowedSize * 1024 * 1024) {
        toast("error", "File size too large!" + "Please select a file smaller than " + allowedSize + "MB.");
        return;
      }
      const formData = new FormData();
      formData.append('content', file);
      try {
        const response = await AxiosInstance.post('media/saveMessageMedia?uid=' + channel?.channelAllData?.uid, formData, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          }
        });
  
        setMedia(response.data)
  
      } catch (error) {
        console.error(error);
        toast("error", "Failed to upload!");
      }
    };
  
    const removeFileHandler = async (media: any) => {
      try {
        await mediaService.deleteMedia(media);
        setMedia(null)
      } catch (error) {
        console.error(error);
      }
    }

  const toggleTaxForm = (value: string) => {
    value === "tax" && setIsTaxVisible(!isTaxVisible);
    value === "shipping" && setIsShippingVisible(!isShippingVisible);
    value === "discount" && setIsDiscountVisible(!isDiscountVisible);
  };

    var paymentObj: SelectBtnOption[] = [];
    paymentObj = allpayment?.map((item: any) => ({
      value: item.uid,
      label: item.method ==='upi'? "UPI VPA ID" : item.method ===  "payu"? "PayU" :  "Razorpay",
  }));

console.log(filterPayment, filterStatus)
  const CheckboxGroup = ({ options, selectedValues, onChange }: any) => (
    <div>
      {options.map((option :any)=> (
        <div key={option.value} className='m-0 check'>
          <input
            type="checkbox"
            className='mr-1 mb-1'
            value={option.value}
            checked={selectedValues.includes(option.value)}
            onChange={(e) => {
              const value = e.target.value;
              if (selectedValues.includes(value)) {
                onChange(selectedValues.filter((val:any) => val !== value));
              } else {
                onChange([...selectedValues, value]);
              }
            }}
          />
          <label>{option.label.charAt(0).toUpperCase() + option.label.slice(1)}</label>
        </div>
      ))}
    </div>
  );

  
  const filter = () => {
    return(
      <Formik 
      initialValues={{
        order: '',
        payment: '',
      }} 
       onSubmit={()=>{
        filterFunc();
       }}> 
       {({ values}) => (
      <Form className='p-1 filterform'>
        <div className='d-flex m-0'>
        <p>Filter</p> 
        { (filterPayment?.length || filterStatus?.length)?  <Button type='button' className='resetbtn' onClick={()=>{
          setFilterPay([]);
          setFilterStatus([]);
        }}>Reset</Button> : ''}
        </div>
        <hr />
        <label>Order Status</label>
        <CheckboxGroup
        options={orderStatus}
        selectedValues={filterStatus}
        onChange={setFilterStatus}
      />
      <label>Payment Status</label>
      <CheckboxGroup
        options={paymentStatus}
        selectedValues={filterPayment}
        onChange={setFilterPay}
      />
      <div className='d-flex'>
        <Button type='button' className='cancelButton p-0 fw-bold' onClick={closePopover}>Cancel</Button>
        <Button type='submit' className='sendButton ml-1 p-1'>Apply</Button>
      </div>
      </Form>
      )}
      </Formik>
    )
  }

    
  // const getAllData = async () => {
  //   try {
  //       const response = await getAllOrders(channel?.channelAllData?.uid);
  //       const data = response.data;
  //       setAllData(data);
  //     } catch (error) {
  //       console.error('Error:', error); 
  //     }
  // };

  const getAllpayment = async () => {
    try {
      if(channel?.channelAllData?.uid){
        const response = await getAll(channel?.channelAllData?.uid);
        const data = response.data;
        setpayment(data);
      }
      } catch (error) {
        console.error('Error:', error); 
      }
  };
  useEffect(() => {
    getAllpayment();
}, []);

//   useEffect(() => { 
//     // getAllData();
//     filterFunc();
//     // console.log("tab",previousUrl);
//   }, [dateRangeValue, channel]);

//   useEffect(() => {
//     let timer = setTimeout(() => {
//           filterFunc();
//     }, 600);

//     return () => clearTimeout(timer);
// }, [search && search.length]);

// const dateSplit = (date: Date): string => {
//   const today = moment();
//   const targetDate = moment(date);
  
//   if (targetDate.isSame(today, "day")) {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>Today</span>";
//   } else if (targetDate.isSame(today.clone().subtract(1, "days"), "day")) {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>Yesterday</span>";
//   } else if (targetDate.isSameOrAfter(today.clone().subtract(7, "days"), "day")) {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>" + targetDate.format("dddd") + "</span>";
//   } else {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>" + targetDate.format("DD MMM") + "</span>";
//   }
// };

  const getselected =(value: any, status: string) =>{
    let obj;
    if(status === 'order'){
    obj = orderStatus.find((obj: any) => obj.value === value.toString());
    }else{
    obj = paymentStatus.find((obj: any) => obj.value === value.toString());
    }
    return obj;
  }

  const handleRowClick = (row: any) => {
   const status = getselected(row.status, 'order') || { value: '', label: '' };
    setStatus(status);
    const paystatus = getselected(row.paymentStatus, 'pay') || { value: '', label: '' };
    setPayment(paystatus);
    setHeadCount(0);
    setCount(0);
    setFooterCount(0);
    setPay(false);
    setOrder(false);
    setRow(row);
    setContent(true);
    setMedia(null);
  };

  const handleCancel = () => {
    setContent(false);
    setMedia(null)
    setIsTaxVisible(false);
    setIsShippingVisible(false);
    setIsDiscountVisible(false);
  }

  const handleStatus = (event: any) => {
    setStatus(event);
  }

  const handlePayment = (event: any)=> {
    setPayment(event);
  }

  const filterFunc = async() => {
    setAfterOrder(filterStatus);
    setAfterPayment(filterPayment);
    // fetchData(0, 20, '', 0);
    // setBeforeOrder(filterStatus);
    // setBeforePayment(filterPayment);
    // const payload = {
    //   "channel_id": channel?.value,
    //   "startDate":  moment(dateRangeValue.start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    //   "endDate": moment(dateRangeValue.end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    //   "orderStatusS": filterStatus.length > 0? filterStatus : null,
    //   "paymentStatusS": filterPayment.length > 0? filterPayment : null,
    //   "mobileNumber":search? search : null,
    // }
    // setIsLoading(true);
    // try {
    //   const response = await filterOrder(payload);
    //   const data = response.data;
    //   setAllData(data);
    //   closePopover();
    //   setIsLoading(false);
    // } catch (error) {
    //   console.error('Error:', error);
    //   closePopover(); 
    //   setIsLoading(false);
    // }
  }


  const handleSubmit = async(values: any) => {
   const payload ={
    "channel_id": channel.value,
    "reference_id":selectedRow.orderId,
    "status": selectedStatus?.value,
    "description": selectedRow.description? selectedRow.description : null,
    "paymentStatus" :selectedpayment?.value,
    "paymentConfigUid": payMethod?.value,
    "expiringDuration": values.duration? values.time.label === 'Hours'? values.duration * 3600000 : values.duration * 60 * 1000 : 5 * 60 * 1000,
    "bodyText": values.body,
    // "headerText": values.header? values.header : null,
    "footerText": values.footer? values.footer : null,
    "header": media ? {
      "headerType": "image",
    } : null,
    "mediaDto": media ? media : null,
    "tax":{
      "amount":values.taxAmt? values.taxAmt * 100 : null,
      "description":values.taxDesc? values.taxDesc : null,
      },
      "discount":{
      "amount": values.discountAmt? values.discountAmt * 100: null,
      "description":values.discountDesc? values.discountDesc : null,
      "discountProgramName": values.discountName? values.discountName : null,
      },
      "shipping":{
      "amount": values.shipAmt? values.shipAmt * 100 : null,
      "description": values.shipDec? values.shipDec : null,
      }
    }
    await updateOrder(payload);
    // getAllData();
    filterFunc();
    setContent(false)
  } 

  const contactFunc = (cell: any,row: any) => {
    const numberMasking = useSelector((state: any) => state.cartreducer.numberMasking);
    const numberMask = localStorage.getItem('numberMask') === 'true' || numberMasking;
  if(row.contact?.name){
   return(
        <div className='d-flex mb-0'>
          <span className='mt-1'><Avatar fgColor="#fff" name={row.contact?.name} round size='30' textSizeRatio={3} /></span>
        <div className='ml-1'>
          <div className='d-flex'>
          <p title={row.contact?.name} className='mb-0'>{row.contact?.name?.length > 12? row.contact?.name?.slice(0,12) : row.contact?.name }</p>
          {row?.message_id ? 
          <>{row.contact?.contactType === 'LEAD' ?
          <span className={`cursor-pointer ml-2 ${isInvalidAccess ? "notAllowed" : ""} `}><Link to={`${process.env.PUBLIC_URL}/conversations?contactUid=${row?.contact?.uid}&messageid=${row?.message_id}`}
            target="_blank"  className={`icon-hover ${isInvalidAccess ? "disabledState" : ""} `}>
            <FontAwesomeIcon icon={faExternalLinkAlt} /></Link></span> :
          <span  className={`cursor-pointer ml-2 ${isInvalidAccess ? "notAllowed" : ""} `}><Link to={`${process.env.PUBLIC_URL}/supportInbox?contactUid=${row?.contact?.uid}&messageid=${row?.message_id}`}
            target="_blank"  className={`icon-hover ${isInvalidAccess ? "disabledState" : ""} `}> 
            <FontAwesomeIcon icon={faExternalLinkAlt} /></Link></span>}</> :
            <span className="disabled-link" style={{opacity : isInvalidAccess ? "0.5" : "1"}}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </span>}
          </div>
          <p className={`${numberMask ? "numberMasking_Blur" :''} mt-0 mb-0`}>{'+' + row.contact?.countryCode + ' ' + row.contact?.mobileNumber.slice(row.contact?.countryCode?.length)}</p>
          </div>
        </div>
   )
  }else{
    return '-';
  }
  }

const rupeesToPaisa = (cell: any) =>{
    return <span className='fontchange'>{`₹${(cell/100).toFixed(2)}`}</span>;
}

const statusColor =(cell:any, row:any)=>{
  return (
    <b className={`paywidth float-left ${row.status === 'order_request'? 'new' :row.status === 'completed'? 'completed' : row.status === 'pending' || row.status === 'processing'? 'pending' :
    row.status === 'partially_shipped' || row.status === 'shipped' ? 'partial' : 'cancel'} rounded p-1 statuswidth`}>{row.status === 'order_request'?  'New' : row.status === "partially_shipped" ? "Partially Shipped" :row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}</b>
 )
}

const paymentColor = (cell:any, row: any) => {
  return (
    <div className='d-flex mt-0'>
    <span className={`pr-1`}>
    {row.paymentStatus === 'new_payment'?     
     <FontAwesomeIcon icon={faClock} className='text-secondary' />
     : row.paymentStatus === 'payment_initiated'?
     <FontAwesomeIcon icon={faSpinner} className='text-warning m-0' /> 
     : 
    row.paymentStatus === 'paid'? 
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 17 17" fill="#458d80">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33333 16.6667C12.9357 16.6667 16.6667 12.9357 16.6667 8.33333C16.6667 3.73096 12.9357 0 8.33333 0C3.73096 0 0 3.73096 0 8.33333C0 12.9357 3.73096 16.6667 8.33333 16.6667ZM11.5742 5.38709C11.8996 5.06168 12.4273 5.06166 12.7527 5.38709C13.0782 5.71252 13.0782 6.24019 12.7527 6.5656L8.0387 11.2796C7.71328 11.6051 7.18562 11.6051 6.86019 11.2796L3.91391 8.33337C3.58848 8.00794 3.58849 7.48027 3.91391 7.15486C4.23932 6.82944 4.76699 6.82943 5.09242 7.15486L7.44944 9.51188L11.5742 5.38709Z" fill="#458d80"/>
   </svg> 
   :  <FontAwesomeIcon icon={faTimesCircle} className='text-danger' />}
   </span>
   <p className="mb-0 mt-0">{row.paymentStatus === 'new_payment'?  'New' : row.paymentStatus === 'payment_initiated'? 'Payment Initiated' : row?.paymentStatus?.charAt(0).toUpperCase() + row?.paymentStatus?.slice(1)}</p>
 </div>
 )
}

const getStartDate = (subtractDays: number, unit: moment.unitOfTime.DurationConstructor = 'days') => {
  return moment().subtract(subtractDays, unit).startOf(unit);
};


const changeDateFilterHandler = (event: any) => {
  const filter = event.target.value as DateFilterType;

  switch (filter) {
      case DateFilterType.Past7Days:
          setDateRangeValue(moment.range(today.clone().subtract(6, "days").startOf('day'), today.endOf('day')));
          setShowDatePicker(false);
          break;

      case DateFilterType.Past30Days:
          setDateRangeValue(moment.range(getStartDate(30), today.endOf('day')));
          setShowDatePicker(false);
          break;

      case DateFilterType.Past90Days:
          setDateRangeValue(moment.range(getStartDate(90), today.endOf('day')));
          setShowDatePicker(false);
          break;

      case DateFilterType.ThisWeek:
          setDateRangeValue(moment.range(today.clone().startOf('week'), today.endOf('day')));
          setShowDatePicker(false);
          break;

      case DateFilterType.PreviousWeek:
          setDateRangeValue(moment.range(getStartDate(1, 'week'), getStartDate(1, 'week').endOf('week')));
          setShowDatePicker(false);
          break;

      case DateFilterType.ThisMonth:
          setDateRangeValue(moment.range(today.clone().startOf('month'), today.endOf('day')));
          setShowDatePicker(false);
          break;

      case DateFilterType.PreviousMonth:
          setDateRangeValue(moment.range(getStartDate(1, 'month'), getStartDate(1, 'month').endOf('month')));
          setShowDatePicker(false);
          break;

      case DateFilterType.CustomRange:
          setShowDatePicker(true);
          break;

      default:
          break;
  }
  
  setDateFilter(filter);
}

const closePopover = () => {
  setpop(false);
}
const openPopover = () => {
  // setFilterStatus(beforeOrder)
  // setFilterPay(beforepayment)
  setpop(true);
}

const closeDate = () => {
  setShowDatePicker(false);
}
const openDate = () => {
  setShowDatePicker(true);
}

const validation = Yup.object().shape({
  paymentobj: Yup.object().required('Payment method is required'),
  duration: Yup.number().when('time', {
    is: (time: { value: string }) => time.value === 'Minutes',
    then: Yup.number().min(5, 'Duration must be at least 5 minutes'),
  }),
  body: Yup.string().required('Message is required').max(1024, "Message must not exceed 1024 characters"),
  header: Yup.string().max(60, "Header must not exceed 60 characters"),
  footer: Yup.string().max(60, "Footer must not exceed 60 characters"),
  taxDesc: Yup.string().max(60, "Description must not exceed 60 characters"),
  shipDec: Yup.string().max(60, "Decription must not exceed 60 characters"),
  discountDesc: Yup.string().max(60, "Description must not exceed 60 characters"),
  discountName: Yup.string().max(60, "description must not exceed 60 characters"),
});
useEffect(() => {
  adjustTextareaHeight();
}, [bodyMsg]);

const adjustTextareaHeight = () => {
  const textarea = textareaRef.current;
  if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
  }
};

const fetchData = useCallback(
  async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    // const listPayload = {
    //   uid: businessId,
    //   page: searchTerm ? 0 : currentPage,
    //   limit: pageSize,
    //   search: searchTerm,
    // };

    // const response = await ContactsGetAll(listPayload as any);
   if(channel?.value){
    const payload = {
      "channel_id": channel?.value,
      "startDate":  moment(dateRangeValue.start).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      "endDate": moment(dateRangeValue.end).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      "orderStatusS": afterOrder? afterOrder : null,
      "paymentStatusS": afterpayment? afterpayment : null,
      "mobileNumber":search? search : null,
      limit: 1000,
      page: 0,
    }
    // setIsLoading(true);

    const response = await filterOrder(payload);
    if (response) {
      const data = response.data;
      console.log(response)
      setAllData(data);
      setRecordCount(response?.data?.length);
      closePopover();
      setIsLoading(false);
      return { data: response.data, totalPages: 0 };
    }else {
      return { data: [], totalPages: 0 };
    }
    } else {
      return { data: [], totalPages: 0 };
    }
  },
  [channel?.value, dateRangeValue, search, afterOrder, afterpayment]
);

const columns = [
  {
    Header: (
      <input
      type="checkbox"
      hidden={true}
      // checked={this.state.allChecked}
      // onChange={this.toggleAllCheckboxes}
    />
    ),
    accessor: "checkbox",
    // Cell: checkboxCellRenderer,
    sticky: "left",
  },
  {
    Header: "Date",
    accessor: "createdAt",
    sticky: "left",
    Cell: ({ cell, row }: any) => <p title={moment(cell.value).format("MMM YYYY h:mm A")}>{dateSplit(cell.value)}</p>,
  },
  {
    Header:'Contact',
    accessor: 'contact',
    Cell: ({ cell, row }: any) => contactFunc(cell.value, row.original),
  },
  // {
  //   Header: 'Type',
  //   accessor: 'type',
  //   Cell: ({ cell, row }: any) => <span>{row.type ? row.type : '-'}</span>,
  // },
  {
    Header: 'Type',
    accessor: 'orderChannel',
    Cell: ({ cell, row }: any) => <span>{row.channel ? row.channel.label : '-'}</span>,
  },
  {
    Header: "Total",
    accessor: "totalAmount",
    Cell: ({ cell, row }: any) => rupeesToPaisa(cell.value),
  },
  // {
  //   Header: "Products",
  //   accessor: "orderProduct",
  //   Cell: ({ row }: any) => {
  //     // Map through the orderItems to extract and display product names
  //     const productNames = row.original.orderItems
  //       ?.map((ele: any) => ele?.digitalItem?.name || ele?.catalogueProduct?.name)
  //       .filter(Boolean); // Exclude undefined or null values
  //     return (
  //       <div>
  //         {productNames && productNames.length > 0 ? (
  //           productNames.map((name: string, index: number) => (
  //             <p key={index} className="text-dark mb-0">{name}</p>
  //           ))
  //         ) : (
  //           <p className="text-muted">No products</p>
  //         )}
  //       </div>
  //     );
  //   },
  // },
  {
    Header: "Quantity Of Items",
    accessor: "orderProduct",
    Cell: ({ row }: any) => {
      // Map through orderItems to extract product names and quantities
      const productQuantities = row.original.orderItems
        ?.map((ele: any) => {
          const name = ele?.digitalItem?.name || ele?.catalogueProduct?.name;
          const quantity = ele?.quantity || 0;
          return name && quantity > 0 ? `${name} (${quantity})` : null;
        })
        .filter(Boolean); // Filter out null or undefined values
  
      return (
        <div className="mt-0">
          {productQuantities && productQuantities.length > 0 ? (
            // Join the products with <br /> for line breaks between them
            <p className="text-dark mb-0 ml-4" style={{ textAlign: 'left' }}>
              {productQuantities.map((item: any, index: number) => (
                <span key={index}>
                  {item}
                  <br />
                </span>
              ))}
            </p>
          ) : (
            <p className="text-muted">0</p>
          )}
        </div>
      );
    },
  },
  {
    Header:'Items',
    accessor: 'orderItems',
    Cell: ({ cell, row }: any) => <span><FontAwesomeIcon icon={faCartShopping} className='text-secondary mr-1 mt-0'/>{row.original?.orderItems?.length}</span>,
  },
  {
    Header: "Quantity",
    accessor: "totalQuantity",
    Cell: ({ row }: any) => {
      // Compute total quantity from orderItems
      const totalQuantity = row.original.orderItems?.reduce(
        (acc: number, ele: any) => acc + (ele?.quantity || 0),
        0
      );
  
      return (
        <div className='mt-0'>
          {totalQuantity > 0 ? (
            <p className="text-dark mb-0">{totalQuantity}</p>
          ) : (
            <p className="text-muted">0</p>
          )}
        </div>
      );
    },
  },  
  {
    Header: "Order Id",
    accessor: "orderId",
    Cell: ({ cell, row }: any) => <span>{row.original.orderId? '#'+row.original.orderId : '-'}</span>,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell, row }: any) => <p className='pl-4'>{statusColor(cell.value, row.original)}</p>,
    sticky: "left",
  },
  {
    Header: "Payment Status",
    accessor: "paymentStatus",
    Cell: ({ cell, row }: any) => paymentColor(cell.value, row.original),
  }

] as any;
const paisaToRupees = (paisa: number): string => {
  // Divide paisa by 100 to convert to rupees, and format it with two decimal places
  const rupeeAmount = (paisa / 100).toFixed(2);
  return `₹${rupeeAmount}`;
};

const mapStatusToLabel = (status: string): string => {
  if (!status) return "Unknown"; // Fallback for missing status

  switch (status) {
    case "order_request":
      return "New";
    case "completed":
      return "Completed";
    case "pending":
    case "processing":
      return "Pending";
    case "partially_shipped":
      return "Partially Shipped";
    case "shipped":
      return "Shipped";
    case "cancel":
      return "Cancelled";
    default:
      // Capitalize the first letter of the status
      return status.charAt(0).toUpperCase() + status.slice(1);
  }
};

const mapPaymentStatusToLabel = (paymentStatus: string): string => {
  if (!paymentStatus) return "Unknown";

  switch (paymentStatus) {
    case "new_payment":
      return "New";
    case "payment_initiated":
      return "Payment Initiated";
    case "paid":
      return "Paid";
    default:
      // Capitalize the first letter of the payment status
      return paymentStatus.charAt(0).toUpperCase() + paymentStatus.slice(1);
  }
};


const exportExcel = () => {
  if (allData.length === 0) {
    alert("No data available to export.");
    return;
  }

  
  const formattedData = allData.map((item: any) => ({
    Date: item.createdAt ? new Date(item.createdAt).toLocaleDateString() : "N/A",
    Contact: item.contact?.name || "N/A",
    Mobile: item.contact?.mobileNumber || "N/A",
    Type: "-",
    Total: paisaToRupees(item.totalAmount || 0),
    "Quantity Of Items": item.orderItems
      ?.map((orderItem: any) => {
        const productName = orderItem?.digitalItem?.name || orderItem?.catalogueProduct?.name || "No product";
        const quantity = orderItem?.quantity || 0;
        return `${productName} (${quantity})`; // Combine name and quantity
      })
      .join("\n") || "N/A", // New line for products
    Items: item.orderItems?.length || 0,
    Quantity: item.orderItems
      ?.reduce((total: number, orderItem: any) => total + (orderItem?.quantity || 0), 0) || 0,
    "Order Id": item.orderId || "N/A",
    Status: mapStatusToLabel(item.status) || "N/A",
    "Payment Status": mapPaymentStatusToLabel(item.paymentStatus) || "N/A",
  }));
  

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

  const columnWidths = [
    { wpx: 80 },  
    { wpx: 150 },  
    { wpx: 100 }, 
    { wpx: 70 }, 
    { wpx: 70 },
    { wpx: 300 },
    { wpx: 60 }, 
    { wpx: 60 },
    { wpx: 90 }
  ];
  
  worksheet['!cols'] = columnWidths;  
  XLSX.writeFile(workbook, "Orders.xlsx");
  
  
};

const numberMasking = useSelector((state: any) => state.cartreducer.numberMasking);
const numberMask = localStorage.getItem('numberMask') === 'true' || numberMasking;
 return(
    <div className='m-3'>
      <div className='d-flex mb-3 mt-2'>
      <h5 className='pt-2 col-3'>WhatsApp Orders</h5>
      <div className="col d-flex align-items-center">
      <TooltipReference
              placement="bottom"
              content={'Export Excel'}
              tooltipId={`excel`}
            >
        <img alt="" src={excelIcon} width={25} height={30} onClick={exportExcel}  
        style={{
          cursor: allData?.length > 0 ? 'pointer' : 'not-allowed', // Change cursor style
          opacity: allData?.length > 0 ? 1 : 0.5, // Dim the button when disabled
        }}
        />
            </TooltipReference>
      </div>
      <div className='col-3'>
      <SearchBox
        placeholder="Search By Mobile Number" 
        value={search}
        onChange={(event) => {
          const value = event.target.value.replace(/\D/g, ''); 
          setSearch(value);
      }}
         />
      </div>
                        <Input
                            className='dateFilter col-2 mr-1'
                            type="select"
                            size={1}
                            value={dateFilter}
                            style={{ fontFamily: "\"Public Sans\", sans-serif" }}
                            onChange={changeDateFilterHandler}
                        >
                            <option value={DateFilterType.Past7Days}>Past 7 days</option>
                            <option value={DateFilterType.Past30Days}>Past 30 days</option>
                            <option value={DateFilterType.Past90Days}>Past 90 days</option>
                            <option value={DateFilterType.ThisWeek}>This week</option>
                            <option value={DateFilterType.PreviousWeek}>Previous week</option>
                            <option value={DateFilterType.ThisMonth}>This month</option>
                            <option value={DateFilterType.PreviousMonth}>Previous month</option>
                            <option value={DateFilterType.CustomRange}>Custom range</option>
                        </Input>
                        <div className='form-control calenderInput col-3' id="datepickertarget" onClick={() => openDate()}>
                            <BsCalendar />
                            <span className='value ml-2'>
                                {dateRangeValue.start.format("DD/MM/YYYY")}
                                {" - "}
                                {dateRangeValue.end.format("DD/MM/YYYY")}
                            </span>
                        </div>
                        <CommonPopover isOpen={showDatePicker} target="datepickertarget" toggle={closeDate} placement='bottom' component='order1'>
                        <div>
                          <DateRangePicker
                                value={dateRangeValue}
                                onSelect={(value: any) => {
                                    setDateRangeValue(moment.range(moment(value.start).startOf('day'), moment(value.end).endOf('day')));
                                    setDateFilter(DateFilterType.CustomRange);
                                    setShowDatePicker(false);
                                }}
                                singleDateRange={true}
                                className="dateRangePicker"
                                maximumDate={today.endOf('day')}
                            />
                            </div>
                        </CommonPopover>
      <div className='col-1 mr-0'>
      <Button
        id='filterpop'
        onClick={() => openPopover()}
        className="filterBtn"
        variant=""
        >
        {paymentStatus.length > 1 || filterStatus > 1 ? (
        <BsFunnelFill size={20} />
          ) : (
        <BsFunnel size={20} />
        )}
        </Button>       
      <CommonPopover isOpen={showPop}  target="filterpop" toggle={closePopover} placement='bottom'
     component='order'>
      {filter()}
     </CommonPopover>
      </div>
      </div>
      <div className='tagTable'>

      {showContent && (
                <RightSidebar handleCancel={handleCancel} title='Order Details' width="425px">
                  <Formik
                    initialValues={{
                     order: selectedStatus?.label,
                     payment: selectedpayment?.label,
                     paymentobj: "",
                     header:  "",
                     body: "",
                     footer: "",
                     duration: 5,
                     time: time[0],
                      taxAmt: null,
                      taxDesc: "",
                      shipAmt: null,
                      shipDec: "",
                      discountAmt:null,
                    }}
                    validationSchema={selectedRow?.status === 'order_request' && (selectedStatus?.label === "pending") &&   paymentObj.length > 0?
                    validation : ""}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form className="pl-3 pr-3 mt-3 ml-1">
                        <div className='orderDetail'>
                        <Row>
                            <Col md='4'><p>Order By</p></Col>
                            <Col md='8' className='d-flex'>{selectedRow.contact?.name? <Avatar fgColor="#fff" name={selectedRow.contact?.name} round size='27' textSizeRatio={3} /> : '' }
                            <div className='ml-1 contact'>
                            <p className='m-0 text-dark'>{selectedRow.contact?.name? selectedRow.contact?.name : '-'}</p>                          
                            <p className={`${numberMask ? "numberMasking_Blur" :''} mt-0`}>{selectedRow.contact?.mobileNumber? '+' + selectedRow.contact?.countryCode + ' ' + selectedRow.contact?.mobileNumber.slice(selectedRow.contact?.countryCode?.length) : ''}</p>
                            </div></Col>
                        </Row>
                        <Row>
                            <Col md='4'><p>Order From</p></Col>
                            <Col md='8' className='d-flex'>{channel?.label? <Avatar fgColor="#fff" name={channel?.label} round size='27' textSizeRatio={3} /> : '' }
                              <div className='ml-1 contact'>
                              <p className='m-0 text-dark'>{channel?.label? channel?.label : '-' }</p>
                              <p className='mt-0'>OrderId {'  '} {selectedRow?.orderId? '#'+selectedRow?.orderId : '-'}</p>
                              </div></Col>
                        </Row>
                        <Row>
                            <Col md='4'><p>Order Date</p></Col>
                            <Col md='8'><p>{moment(selectedRow?.createdAt).format("DD MMM YYYY, h:mm A")}</p></Col>
                        </Row>
                        {/* <Row>
                            <Col md='4'><p>Cart Id</p></Col>
                            <Col md='8'><p>{selectedRow?.orderId? selectedRow?.orderId : '-'}</p></Col>
                        </Row> */}
                        <Row>
                            <Col md='4'><p>Reason</p></Col>
                            <Col md='8'><p>{selectedRow.reason? selectedRow.reason : '-'}</p></Col>
                        </Row>
                        <hr/>
                        {selectedRow.orderItems.map((ele: any, ind: any) => (
                          (ele.digitalItem? 
                            <>
                            <div key={ind} className='d-flex mt-2 orderRows'>
                            <div className='col-2'>{ele.digitalItem?.imageUrl? <img src={ele.digitalItem?.imageUrl} width={40} height={40} className='rouded'/> : selectedRow?.media? <img src={selectedRow?.media?.url} width={40} height={40} className='rouded'/> :  <img src={defaultImg} width={40} height={40} className='rouded'/>}</div>
                            <div className='mr-5 col-6'>
                            <p className='text-dark mb-0'>{ele?.digitalItem?.name}</p>
                            <p className='mb-2 text-muted small'>Quantity {ele?.quantity}</p>
                            </div>
                            <div className='float-right col-4'>
                            {/* <p className='mt- 1'><span className='fontchange'></span>{ele.digitalItem?.amount? rupeesToPaisa(ele.digitalItem?.amount) : '-'}</p> */}
                            <p className="fontchange m-0">₹{ele?.digitalItem?.saleAmount > 0?  (ele?.digitalItem?.saleAmount/100) * ele?.quantity : (ele?.digitalItem?.amount/100) * ele?.quantity}</p>
                            <p className="fontchange text-decoration-line-through">{ele?.digitalItem?.saleAmount > 0?  '₹' + ((ele?.digitalItem?.amount/100) * ele?.quantity) : ''}</p>
                            
                            </div>
                            </div>
                            </> 
                            : 
                            <>
                            <div key={ind} className='d-flex mt-2 ml-0 orderRow'>
                            <div className='col-2'>{ ele.catalogueProduct?.imageUrl? <img src={ele.catalogueProduct?.imageUrl} alt="Product"  width='40' height='40'/> : selectedRow?.media? <img src={selectedRow?.media?.url} width={40} height={40} className='rouded'/> :  <img src={defaultImg} width={40} height={40} className='rouded'/>} </div>
                            <div className='mr-5 col-6'>
                            <p className='text-dark mb-0'>{ele?.catalogueProduct?.name}</p>
                            <p className='mb-2 text-muted small'>Quantity {ele?.quantity}</p>
                            </div>
                            <div className='float-right col-4'>
                              
                            <p className='mt- 1 fontchange'>{ele?.amount?   '₹' + (ele?.amount/100) * ele?.quantity : '-'}</p>
                            {/* <p className='mt- 1'><span className='fontchange'></span>{ele.catalogueProduct?.price? ele.catalogueProduct?.price : '-'}</p> */}
                            {/* <p className="fontchange m-0">₹{ele?.catalogueProduct?.saleAmount > 0?  (ele?.catalogueProduct?.saleAmount/100) * ele?.quantity : (ele?.catalogueProduct?.amount/100) * ele?.quantity}</p>
                            <p className="fontchange text-decoration-line-through">{ele?.catalogueProduct?.saleAmount > 0?  '₹' + ((ele?.catalogueProduct?.amount/100) * ele?.quantity) : ''}</p> */}
                           
                            </div>
                            </div>
                            </> 
                          )
                        ))}
                        <hr/>
                        <Row className='mt-2'>
                          <Col md='4'><p>Tax</p></Col>
                          <Col md='7'><p className='float-right fontchange'>{selectedRow?.tax?.amount? '₹' + (selectedRow?.tax?.amount / 100) : '-'}</p></Col>
                        </Row>
                        <Row>
                          <Col md='4'><p>Discount</p></Col>
                          <Col md='7'><p className='float-right fontchange'>{selectedRow?.discount?.amount? '₹' + (selectedRow?.discount?.amount / 100) : '-'}</p></Col>
                        </Row>
                        <Row>
                          <Col md='4'><p>Shipping</p></Col>
                          <Col md='7'><p className='float-right fontchange'>{selectedRow?.shipping?.amount? '₹' + (selectedRow?.shipping?.amount / 100) : '-'}</p></Col>
                        </Row>
                        <Row>
                          <Col md='4'><p>Total</p></Col>
                          <Col md='7'><p className='float-right fontchange'>{selectedRow?.totalAmount? '₹' + (selectedRow?.totalAmount / 100) : '-'}</p></Col>
                        </Row>
                        <hr/>
                        <Row className='mt-3'>
                            <Col md='4'><p>Order Status</p></Col>
                            <Col md='8'><p>{selectedStatus?.label? selectedStatus?.label?.charAt(0).toUpperCase() + selectedStatus?.label?.slice(1) : '-'}
                            <FontAwesomeIcon icon={faEdit} onClick={()=>{
                              if((selectedpayment?.label === 'paid' || selectedRow.paymentStatus === 'paid') || (selectedStatus?.label === 'new' || selectedRow.status === 'order_request')){
                               setOrder(!order);
                              }
                            }} 
                            className={`${ (selectedpayment?.label === 'paid' || selectedRow.paymentStatus === 'paid') || (selectedStatus?.label === 'new' || selectedRow.status === 'order_request')? 'text-secondary' : 'disableblue'} ml-2`}/>
                            </p></Col>
                        </Row>
                        <div className='d-flex'>
                        {order &&<FormGroup className='pt-1 pb-1 ml-0 w-50'>
                            <Label htmlFor="order">Update Order Status</Label>
                            <Field name="order">
                            {({ field, form }: any) => {
                                const selectedIndex = orderStatus.findIndex(option => option.value === selectedRow?.status);
                           return (
                            <Select
                                // options={orderStatus}
                                options={orderStatus.map((option, index) => ({
                                  ...option,
                                  isDisabled: selectedRow?.status === 'order_request'? index !== 1 : (selectedRow?.status === 'completed' || selectedRow?.status === 'canceled')? index !== selectedIndex : 
                                  selectedRow?.status === 'processing' ?  index !== 1 && index <= selectedIndex : selectedRow?.status === 'partially_shipped'? index === 0 && index <= selectedIndex : selectedRow?.status === 'shipped'? index === 0 && index <= selectedIndex : index <= selectedIndex
                              }))}
                                value={selectedStatus}
                                onChange={(event) => {
                                setFieldValue('order', event);
                                handleStatus(event);
                            }}
                            />
                        )}}</Field>
                           </FormGroup>}
                           {selectedRow?.status === 'order_request' && (selectedStatus?.label === "pending") &&   paymentObj.length > 0 && order &&
                           
                           <FormGroup className='pb-1 pt-1 ml-2 w-50'>
                           <Label>Configure Payment<span className='required'></span></Label>
                           <Field name="paymentobj">
                           {({ field, form }: any) => (
                           <Select
                               options={paymentObj}
                              //  value={payMethod}
                               onChange={(event) => {  
                                setFieldValue('paymentobj', event);
                                setPayMethod(event);
                               }}
                           />
                       )}</Field>
                       <ErrorMessage name="paymentobj" component="div" className="text-danger" />
                        </FormGroup> 
                    }
                    </div>
                        { selectedRow?.status === 'order_request' && (selectedStatus?.label === "pending") &&  paymentObj.length > 0 && order &&
                          <>
                         <div>
                         {/* <FormGroup>
                          <Label> Header (Optional)</Label>
                          <Field type="text" name="header" id="header" placeholder="Enter header text" className="form-control" onChange={(event: any)=> {
                                      let text = event.target.value;
                                      setFieldValue('header',text )
                                      setHeadCount(text.length)}} />
                          <div className='float-right text-muted small'>{headCount} / 60</div>
                          <ErrorMessage name="header" component="div" className="text-danger" />
                         </FormGroup> */}
                         <FormGroup>
                                  <Label className="m-0">Header (optional)  <span className="small">Max file size: 5 MB</span></Label>
                                  <p className="m-0 small">File type allowed : image/jpeg,image/png</p>
                                  {!media? <Field name="file">
                                    {({ field }: any) => (
                                      <div>
                                        <Label className="uploadFiles col-3">
                                          <input
                                            type="file"
                                            className="hideFileInput"
                                            accept=".jpeg,.jpg,.png"
                                            onChange={(event) => {
                                              const file = event.target.files;
                                              field.onChange(event);
                                              onFileChange(event);
                                            }
                                            }
                                          /> Upload File
                                        </Label>
                                      </div>
                                    )}
                                  </Field> :
                                    <div>

                                      <img src={media.url} alt='img' width='80' height='80'></img>
                                      <button type='button' onClick={() => {
                                        removeFileHandler(media);
                                        setFieldValue('media', null);
                                      }} className="bg-white border-0 ml-3">
                                        <BsFillTrashFill className="bg-white text-secondary h6" />
                                      </button>
                                    </div>
                                  }
                                </FormGroup>
                         <FormGroup>
                           <Label> Message Body <span className='required'></span></Label>
                          {/* <Field type="text" name="body" id="body" placeholder="Enter message" className="form-control H-50" /> */}
                          <Field name="messageBody">
                        {({ field }: FieldProps & { form: FormikProps<any> }) => (
                          <>
                            <div>
                                <textarea
                                    ref={textareaRef}
                                    {...field}
                                    placeholder='Enter message'
                                    onChange={(event)=> {
                                      let text = event.target.value;
                                      setFieldValue('body',text )
                                      setBody(text);
                                      setCount(text.length);
                                    }}
                                    className='form-control'
                                    />
                                    </div>
                             <div className='float-right text-muted small'>{charCount} / 1024</div>
                            </>
                        )} 
                        </Field>
                          <ErrorMessage name="body" component="div" className="text-danger" />
                         </FormGroup>
                         <FormGroup>
                          <Label> Footer (optional)</Label>
                          <Field type="text" name="footer" id="footer" placeholder="Enter footer text" className="form-control"  onChange={(event: any)=> {
                                      let text = event.target.value;
                                      setFieldValue('footer',text )
                                      setFooterCount(text.length)}} />
                          <div className='float-right text-muted small'>{footerCount} / 60</div>
                          <ErrorMessage name="footer" component="div" className="text-danger" />
                         </FormGroup>
                         <FormGroup>
                          <Label>Expiring Duration</Label>
                          <div className='d-flex exduration p-1'>
                          <Field name="time">
                            {({ field, form }: any) => (
                            <Select
                                options={time}
                                value={values.time}
                                className='col-6 mr-1'
                                onChange={(event) => {  
                                setFieldValue('time', event);
                            }}
                            />
                        )}</Field>
                          <Field type="text" name="duration" id="duration" placeholder="Enter duration" className="form-control col-5"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let value = e.target.value.replace(/[^0-9.]/g, ""); 
                            value = value.replace(/^(\d*\.\d*)\..*$/, '$1'); 
                            setFieldValue("duration", value);
                            }} />
                            </div>
                          <ErrorMessage name="duration" component="div" className="text-danger" />
                         </FormGroup>
                         <div className="advancecard mb-3">
                         <ValidationSettings
                                  initiallyOpen={isOpen}
                                  onCancel={() => {
                                      setValidateOpen(false)
                                  }}
                                  caption='Advanced (optional)'
                                  component='salesinbox'
                                  > 
                                <FormGroup className="mb-3">
                                  <div>
                                    <div className={styles.dropdown}>
                                      <button type="button" className={styles.dropbtn} onClick={() => { setIsTaxVisible(!isTaxVisible) }}>
                                        Tax  <span className={styles.taxArrow}>{isTaxVisible ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</span>
                                      </button>
                                      {isTaxVisible && (
                                        <div className={styles.dropdownContent}>
                                          <FormGroup>
                                            <p className="labelSize">Tax Amount</p>
                                            <Field type='text' id='taxAmt' className='form-control' name='taxAmt' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              let value = e.target.value.replace(/[^0-9.]/g, "");
                                              value = value.replace(/^(\d*\.\d*)\..*$/, '$1');
                                              setFieldValue("taxAmt", value);
                                            }} placeholder='Enter tax amount' />
                                          </FormGroup>
                                          <FormGroup>
                                            <p className="labelSize">Tax Description</p>
                                            <Field type='text' id='taxDesc' className='form-control' name='taxDesc' onChange={(event: any) => {
                                              let text = event.target.value;
                                              setFieldValue("taxDesc", text);
                                              setTax(text.length)
                                            }} disabled={!values.taxAmt || values.taxAmt <= 0} placeholder='Enter tax description' />
                                            <div className='float-right text-muted small'>{taxCount} / 60</div>
                                            <ErrorMessage name="taxDesc" component="div" className="text-danger" />
                                          </FormGroup>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup className="mb-3">
                                  <div>
                                    <div className={styles.dropdown}>
                                      <button type="button" className={styles.dropbtn} onClick={() => toggleTaxForm("shipping")}>
                                        Shipping  <span className={styles.arrow}>{isShippingVisible ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</span>
                                      </button>
                                      {isShippingVisible && (
                                        <div className={styles.dropdownContent}>
                                          <FormGroup>
                                            <p className="labelSize">Shipping Amount</p>
                                            <Field type='text' id='shipAmt' className='form-control' name='shipAmt' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              let value = e.target.value.replace(/[^0-9.]/g, "");
                                              value = value.replace(/^(\d*\.\d*)\..*$/, '$1');
                                              setFieldValue("shipAmt", value);
                                            }} placeholder='Enter shipping amount' />
                                          </FormGroup>
                                          <FormGroup>
                                            <p className="labelSize">Shipping Description</p>
                                            <Field type='text' id='shipDec' className='form-control' name='shipDec' onChange={(event: any) => {
                                              let text = event.target.value;
                                              setFieldValue("shipDec", text);
                                              setShip(text.length)
                                            }} disabled={!values.shipAmt || values.shipAmt <= 0} placeholder='Enter shipping description' />
                                            <div className='float-right text-muted small'>{shipCount} / 60</div>
                                            <ErrorMessage name="shipDec" component="div" className="text-danger" />
                                          </FormGroup>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup className="mb-3">
                                  <div>
                                    <div className={styles.dropdown}>
                                      <button type="button" className={styles.dropbtn} onClick={() => toggleTaxForm("discount")}>
                                        Discount  <span className={styles.arrow}>{isDiscountVisible ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</span>
                                      </button>
                                      {isDiscountVisible && (
                                        <div className={styles.dropdownContent}>
                                          <FormGroup>
                                            <p className="labelSize">Discount Amount</p>
                                            <Field type='text' id='dicountAmt' className='form-control' name='discountAmt' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              let value = e.target.value.replace(/[^0-9.]/g, "");
                                              value = value.replace(/^(\d*\.\d*)\..*$/, '$1');
                                              setFieldValue("discountAmt", value);
                                            }} placeholder='Enter discount amount' />
                                          </FormGroup>
                                          <FormGroup>
                                            <p className="labelSize">Discount Description</p>
                                            <Field type='text' id='discountDesc' className='form-control' name='discountDesc' onChange={(event: any) => {
                                              let text = event.target.value;
                                              setFieldValue("discountDesc", text);
                                              setDiscount(text.length)
                                            }} disabled={!values.discountAmt || values.discountAmt <=0} placeholder='Enter discount description' />
                                            <div className='float-right text-muted small'>{discountCount} / 60</div>
                                            <ErrorMessage name="discountDesc" component="div" className="text-danger" />
                                          </FormGroup>
                                          <FormGroup>
                                            <p className="labelSize">Discount Program Name</p>
                                            <Field type='text' id='discountName' className='form-control' name='discountName' onChange={(event: any) => {
                                              let text = event.target.value;
                                              setFieldValue("discountName", text);
                                              setName(text.length)
                                            }} disabled={!values.discountAmt || values.discountAmt <= 0} placeholder='Enter discount name' />
                                            <div className='float-right text-muted small'>{nameCount} / 60</div>
                                            <ErrorMessage name="discountName" component="div" className="text-danger" />
                                          </FormGroup>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                </ValidationSettings>
                          </div>
                         </div>
                          </>                 
                                                 
                         }
                        </div>
                        {/* <hr /> */}
                        
                        {selectedRow?.status !== 'order_request'?
                        <>
                        <hr />
                        <div className='mt-3'>
                        <b>Payment Details</b>
                        <Row className='mt-3'>
                            <Col md='5'><p>Payment Status :</p></Col>
                            <Col md='7'><p>{selectedpayment?.label? selectedpayment?.label?.charAt(0).toUpperCase() + selectedpayment?.label?.slice(1): ""}<FontAwesomeIcon icon={faEdit}  
                            onClick={()=> {if(selectedRow?.status !== 'canceled' && selectedStatus?.label !== 'canceled')
                            setPay(!pay)}}  className={`ml-2 ${selectedRow?.status === 'canceled' || selectedStatus?.label === 'canceled'? 'disableblue' : 'text-secondary'}`}/></p></Col>
                        </Row>
                        {pay &&<FormGroup className='pt-1 pb-1 ml-0 w-50'>
                            <Label htmlFor="payment">Update payment status {selectedRow?.paymentStatus === 'paid'? <FontAwesomeIcon icon={faInfoCircle} className='text-muted ml-1 mb-0' title='Payment received, status cannot change'/> : ''}</Label>
                            <Field name="payment">
                            {({ field, form }: any) => (
                            <Select
                                options={paymentStatus.map((option, index) => ({
                                  ...option,
                                  isDisabled: selectedRow?.paymentStatus === 'paid'? index !== 2 : '',
                              }))}
                                value={selectedpayment}
                                onChange={(event) => {  
                                setFieldValue('payment', event);
                                handlePayment(event);
                            }}
                            />
                        )}</Field>
                          </FormGroup>}
                        <Row>
                            <Col md='5'><p>Paid Amount :</p></Col>
                            <Col md='7'><p>{selectedRow.paidAmount? rupeesToPaisa(selectedRow.paidAmount) : '-'}</p></Col>
                        </Row>
                        <Row>
                            <Col md='5'><p>Payment Method :</p></Col>
                            <Col md='7'><p>{selectedRow.paymentConfig?.method === 'upi'? "UPI VPA ID" : selectedRow.paymentConfig?.method ===  "payu"?  "PayU" : selectedRow.paymentConfig?.method === "razorpay"? "Razorpay" : '' }</p></Col>
                        </Row> 
                        <Row>
                            <Col md='5'><p>Transaction Id :</p></Col>
                            <Col md='7'>-</Col>
                        </Row> 
                        </div> </>  : ''}
                        <div className={`editor-footer p-1 ${isInvalidAccess ? "notAllowed" : "pointer"} `}>
                        <Button type='button' className='cancelButton mr-2' onClick={()=>handleCancel()} >Cancel</Button>
                        <Button type='submit' className='sendButton mr-2' disabled={ isInvalidAccess || selectedStatus?.value === selectedRow.status && selectedpayment?.value === selectedRow.paymentStatus}>Apply</Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </RightSidebar>
              )}


{isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
          <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={0}
              refetchData={allData}
              tableName='ORDER'
              handleRowClick={handleRowClick}
            />
            </>
        // <BootstrapTable data={allData} pagination={allData.length > 10 ? true : false} version="4" striped hover
        //   containerStyle={{ textAlign: 'left', cursor: 'pointer' }}
        //   // search
        //   options={{ onRowClick: handleRowClick }}
        //     >
        //   <TableHeaderColumn dataField='id' isKey hidden>#</TableHeaderColumn>
        //   <TableHeaderColumn dataAlign="center"  dataField='createdAt' dataFormat={dateSplit} width="100"> <b>Date</b></TableHeaderColumn>
        //   <TableHeaderColumn dataField='name' columnClassName="text-wrap" dataAlign='left' width="170" 
        //     dataFormat={contactFunc}
        //   ><b>Contact</b></TableHeaderColumn>
        //   <TableHeaderColumn  dataAlign="center"  width="100" dataFormat={() => channel.label}> <b>Type</b></TableHeaderColumn>
        //   <TableHeaderColumn dataField="totalAmount" dataAlign="center"  width="100"  dataFormat={rupeesToPaisa}> <b>Total</b></TableHeaderColumn>
        //   <TableHeaderColumn dataField="status" dataAlign="center"  width="100"   dataFormat={(cell, row, rowIndex) => {
        //       return (
        //         <p><FontAwesomeIcon icon={faCartShopping} className='text-secondary mr-1 mt-3'/>{row.orderItems ? row.orderItems.length : 0}</p>
        //       )
        //      }}> <b>Items</b></TableHeaderColumn>
        //   <TableHeaderColumn dataField="orderId" dataAlign="center"  width="130"
        //    dataFormat={(cell, row, rowIndex) => {
        //     return (
        //        <p className='text-dark mt-3' title={row.orderId? row.orderId : '-'}>{row.orderId ? '#'+row.orderId.slice(0,10) : '-'}</p>
        //     )
        // }}> <b>Order ID</b></TableHeaderColumn>

        //   <TableHeaderColumn dataField="status" dataAlign="left"  width="130"  dataFormat={statusColor}> <b>Order Status</b></TableHeaderColumn>

        //   <TableHeaderColumn dataField="paymentStatus" dataAlign="center"  width="140"  dataFormat={paymentColor}> <b>Payment Status</b></TableHeaderColumn>
        // </BootstrapTable>
        )}

      </div>
    </div>
 )  
}

export default Order;