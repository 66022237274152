// MapLoader.js
import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const libraries = ["places"];

const MapLoader = ({ children }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: '',
        //AIzaSyCtZ3fMsIXu8KNTEkRpD7M39AX-VRhqi9Q
        libraries,
    });

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return children;
};

export default MapLoader;
