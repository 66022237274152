import axios from '../utils/axios';
import { toast } from '../common/alert';

export const getAllOrders = (channelUid: string): Promise<any> => {
    const data ={
        "channelId" : channelUid
    }
    return new Promise((resolve, reject) => {
        axios.post('order/getAll', data).then(res => {
            resolve(res);
        }).catch((err: any) => {
            console.log("Something went wrong")
        })
    });
};

export const updateOrder = (payload: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`order/updateOrderStatus`, payload).then(res => {
            resolve(res);
            toast('success', "Updated Successfully")
        }).catch((err: any) => {
            toast("error", err.response.data.message)
            console.log("Something went wrong")
        })
    });
};

export const filterOrder = (payload: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`order/filterOrders`, payload).then(res => {
            resolve(res);
        }).catch((err: any) => {
            console.log("Something went wrong")
        })
    });
};