import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { Row, Col } from "reactstrap";
import StatusForm from "../TicketForms/StatusForm";
import TypeForm from "../TicketForms/TypeForm";
import FieldForm from "../TicketForms/FieldForm";
import { useSelector } from "react-redux";
import {
  getTicketFields,
  patchTicketField,
} from "../../../services/ticketService";
import { Item } from "../Types";
import { getItemIcons } from "../Types";
import PriorityForm from "../TicketForms/PriorityForm";
import style from "./ticketFields.module.scss";
import { initialStaticItems } from "../Types";
import { BsInfoCircle } from "react-icons/bs";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import Loader from "../../../shade/Loaders/smallLoader";

const DrogDrops: React.FC = () => {
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData?.uid
  );
  const [dynamicItems, setDynamicItems] = useState<Item[]>([]);
  const [staticItems] = useState<Item[]>(initialStaticItems);
  const [toggleForms, setToggleForms] = useState<boolean[]>([]);
  const [formDisable, setFormDisable] = useState<boolean[]>([]);
  const [dragNdrop, setDragNdrop] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [newlyAddedItemId, setNewlyAddedItemId] = useState<string | null>(null);
  const [callDb, setCallDb] = useState(channelUid ? true : false);
  const [submittedItemId, setSubmittedItemId] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [pageLoader, setPageLoader] = useState(true);
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

  useEffect(() => {  
    if(roleRules) {
      const isInvalidAccess = roleRules.canViewTicketsAndAnalytics === true && roleRules.canManageTicketsAndAnalytics === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);

  useEffect(() => {
    const hasTrueValue = toggleForms.some((value) => value === true);
    setDragNdrop(hasTrueValue);
  }, [toggleForms]);

  const getItemStyle = (
    isDragging: boolean,
    isHovering: boolean,
    isHighlighted: boolean,
    draggableStyle: any,
    itemDeleted: boolean
  ) => {
    // Determine the base background color based on isHighlighted
    let backgroundColor = "#fff"; // Default to white when not highlighted
    if (isHighlighted) {
      backgroundColor = "#fff8eb"; // Mild orange when highlighted
    }

    return {
      userSelect: "none",
      display: itemDeleted ? "none" : "block",
      padding: 10,
      margin: `0 0 8px 0`,
      backgroundColor: backgroundColor,
      border: "1px solid #e0e0e0",
      color: "black",
      borderRadius: "6px",
      boxShadow: isHovering ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "",
      transition: "background-color 1s ease-in-out", // Smooth transition for background color
      opacity: isDragging ? 0.5 : 1,
      ...draggableStyle,
    };
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === "static" &&
      destination.droppableId === "dynamic"
    ) {
      const itemToCopy = staticItems[source.index];
      const newItem = {
        ...itemToCopy,
        id: `dynamic-${itemToCopy.id}-${new Date().getTime()}`, // Ensure unique ID
      };
      const newDynamicItems = Array.from(dynamicItems);
      newDynamicItems.splice(destination.index, 0, newItem);

      const updatedItems = newDynamicItems.map((item, index) => ({
        ...item,
        position: index + 1,
      }));

      setDynamicItems(updatedItems);
      setNewlyAddedItemId(newItem.id); // Track the newly added item
      setCallDb(false);
      setToggleForms(newDynamicItems.map(() => false));
      setFormDisable(newDynamicItems.map(() => true));
      setToggleForms((prevToggleForms) => {
        const newToggleForms = [...prevToggleForms];
        newToggleForms[destination.index] = true;
        return newToggleForms;
      });
      setFormDisable((prev) => {
        const newDisableForms = [...prev];
        newDisableForms[destination.index] = false;
        return newDisableForms;
      });
    } else if (
      source.droppableId === "dynamic" &&
      destination.droppableId === "dynamic"
    ) {
      const items = Array.from(dynamicItems);
      const [relocatedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, relocatedItem);

      const updatedItems = items.map((item, index) => ({
        ...item,
        position: index + 1,
      }));

      setFormDisable(dynamicItems.map(() => false));
      setDynamicItems(updatedItems);
      patchTicketField(channelUid, updatedItems)
        .then((res) => {
          if (res === "SUCCESS") {
            setCallDb(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setCallDb(false);
    }
  };

  useEffect(() => {
    if (callDb) {
      getTicketFields(channelUid).then((res) => {
        if(res?.status === 200){
          setPageLoader(false);
        }
        
        const sortedRes = res?.data?.sort(
          (a: Item, b: Item) => a.position - b.position
        );

        // For each item with name 'Type' or 'Status', sort the 'options' array within it based on the 'position' field
        const updatedRes = sortedRes.map((item: Item) => {
          if (
            item.name.toLowerCase() === "type" ||
            item.name.toLowerCase() === "status" ||
            item.name.toLowerCase() === "priority"
          ) {
            const sortedOptions = item.options.sort(
              (a: any, b: any) => a.position - b.position
            );
            return { ...item, options: sortedOptions };
          }
          return item;
        });

        // Update state
        setDynamicItems(updatedRes);
        setFormDisable(res?.data?.map(() => false));
        setToggleForms(res?.data?.map(() => false));
      });
    }
  }, [channelUid, callDb]);

  useEffect(() => {
    const hasTrueValue = toggleForms.some((value) => value === true);
    setDragNdrop(hasTrueValue);
  }, [toggleForms]);

  const filteredItems = dynamicItems.filter(
    (item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    if (searchInput) {
      setDragNdrop(true);
    } else {
      const hasTrueValue = toggleForms.some((value) => value === true);
      setDragNdrop(hasTrueValue);
    }
  }, [searchInput, toggleForms]);

  return (
    <div className={style.dragDropsContainer}>
      {
        pageLoader ? <Loader /> :(
          <>
            <div className={style.ticketFieldHeader}>
              <h5 className="mt-2 mb-2">Ticket Fields</h5>
              <div className={style.ticketInfo}>
                <BsInfoCircle size={17} /> Custom ticket fields streamline support by
                gathering key info upfront, leading to faster solutions, better
                routing, and valuable insights into customer trends.
              </div>
            </div>

            <div className={`${isInvalidAccess ? "notAllowed" : ""}`}  >
              <DragDropContext onDragEnd={onDragEnd}>
                <Row className={`${style.dragDropItems} ${isInvalidAccess ? "disabledState" : ""} `}>
                <Col md={3} className={style.staticItems}>
                  <div className={style.staticHeader}>
                    Drag and Drop to create fields
                  </div>
                  <Droppable droppableId="static" isDropDisabled={true}>
                    {(provided: DroppableProvided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="mt-2"
                      >
                        {staticItems.map((item, index) => (
                          <Draggable
                            key={item.id.toString()}
                            draggableId={item.id.toString()}
                            index={index}
                            isDragDisabled={dragNdrop || isInvalidAccess}
                          >
                            {(
                              provided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => (
                              <>
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onMouseEnter={() =>
                                    setHoveredItem(item.id.toString())
                                  }
                                  onMouseLeave={() => setHoveredItem(null)}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    hoveredItem === item.id.toString(),
                                    false,
                                    provided.draggableProps.style,
                                    false
                                  )}
                                  className={`${style.draggableItem} ${
                                    snapshot.isDragging ? style.dragging : ""
                                  }`}
                                  id={
                                    dragNdrop
                                      ? style.ticketFieldDisable
                                      : style.ticketFieldAble
                                  }
                                >
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div>{getItemIcons(item.name.toLowerCase())}</div>
                                    <div className="ms-2">{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</div>
                                  </div>
                                </div>
                                {snapshot.isDragging && (
                                  <div
                                    style={{
                                      ...getItemStyle(false, false, false, {}, false),
                                      display: "block",
                                    }}
                                  >
                                    <div className="d-flex justify-content-start align-items-center">
                                      <div>{getItemIcons(item.name.toLowerCase())}</div>
                                      <div className="ms-2">{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
                <Col className={style.dynamicItems}>
                  <div className={style.dynamicHeader}>
                    <div className={style.dynamicHeaderSearch}>
                      <div>
                        <IoSearchOutline size={20} className="me-2" />
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Search fields"
                          value={searchInput}
                          onChange={(e: any) => setSearchInput(e.target.value)}
                          style={{
                            border: "none",
                            outline: "none",
                            boxShadow: "none",
                          }}
                        />
                      </div>
                      <div style={{ width: "30px", cursor: "pointer" }}>
                        {searchInput.length > 0 ? (
                          <IoIosClose
                            size={20}
                            className="ms-2"
                            onClick={() => setSearchInput("")}
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Droppable droppableId="dynamic">
                    {(provided: DroppableProvided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div className={style.dynamicItemList}>

                          {
                            filteredItems.length === 0 && (
                              <div className={style.noItems}>
                                No field found
                              </div>
                            )
                          }
                          {Array.isArray(filteredItems) &&
                            filteredItems.map((item, index: number) => {
                              const originalIndex = dynamicItems.findIndex(
                                (dynamicItem) => dynamicItem.id === item.id
                              );

                              return (
                                <Draggable
                                  key={item.id.toString()}
                                  draggableId={item.id.toString()}
                                  index={originalIndex}
                                  isDragDisabled={dragNdrop || isInvalidAccess}
                                >
                                  {(
                                    provided: DraggableProvided,
                                    snapshot: DraggableStateSnapshot
                                  ) => (
                                    <span className={`${isInvalidAccess ? "notAllowed" : ""}`}>
                                    <div
                                      className={`${isInvalidAccess ? "disabledState" : ""}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onMouseEnter={() =>
                                        setHoveredItem(item.id.toString())
                                      }
                                      onMouseLeave={() => setHoveredItem(null)}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        hoveredItem === item.id.toString(),
                                        submittedItemId === item.id.toString(),
                                        provided.draggableProps.style,
                                        item.deleted
                                      )}
                                    >
                                      {item.name.toLowerCase() === "status" ? (
                                        <StatusForm
                                          name="status"
                                          index={originalIndex}
                                          disable={formDisable}
                                          setDisable={setFormDisable}
                                          toggler={toggleForms}
                                          setToggler={setToggleForms}
                                          formValue={dynamicItems}
                                          setFormValue={setDynamicItems}
                                          setCallDb={setCallDb}
                                          newlyAddedItemId={newlyAddedItemId}
                                          setNewlyAddedItemId={setNewlyAddedItemId}
                                          isDefault={item.isDefault}
                                          setSubmittedItemId={setSubmittedItemId}
                                        />
                                      ) : item.name.toLowerCase() === "type" ? (
                                        <TypeForm
                                          name="type"
                                          index={originalIndex}
                                          disable={formDisable}
                                          setDisable={setFormDisable}
                                          toggler={toggleForms}
                                          setToggler={setToggleForms}
                                          formValue={dynamicItems}
                                          setFormValue={setDynamicItems}
                                          setCallDb={setCallDb}
                                          newlyAddedItemId={newlyAddedItemId}
                                          setNewlyAddedItemId={setNewlyAddedItemId}
                                          isDefault={item.isDefault}
                                          setSubmittedItemId={setSubmittedItemId}
                                        />
                                      ) : item.name.toLowerCase() === "priority" ? (
                                        <PriorityForm
                                          name="priority"
                                          index={originalIndex}
                                          disable={formDisable}
                                          setDisable={setFormDisable}
                                          toggler={toggleForms}
                                          setToggler={setToggleForms}
                                          formValue={dynamicItems}
                                          setFormValue={setDynamicItems}
                                          setCallDb={setCallDb}
                                          newlyAddedItemId={newlyAddedItemId}
                                          setNewlyAddedItemId={setNewlyAddedItemId}
                                          isDefault={item.isDefault}
                                          setSubmittedItemId={setSubmittedItemId}
                                        />
                                      ) : (
                                        <FieldForm
                                          name={item.name.toLowerCase()}
                                          index={originalIndex}
                                          disable={formDisable}
                                          setDisable={setFormDisable}
                                          toggler={toggleForms}
                                          setToggler={setToggleForms}
                                          formValue={dynamicItems}
                                          setFormValue={setDynamicItems}
                                          setCallDb={setCallDb}
                                          newlyAddedItemId={newlyAddedItemId}
                                          setNewlyAddedItemId={setNewlyAddedItemId}
                                          isDefault={item.isDefault}
                                          setSubmittedItemId={setSubmittedItemId}
                                        />
                                      )}
                                    </div>
                                    </span>
                                  )}
                                </Draggable>
                              );
                            })}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
              </Row>
            </DragDropContext>
            </div>
          </>
        )
      }
    </div>
  );
};

export default DrogDrops;
