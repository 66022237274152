import React, { useState, useEffect, SetStateAction } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormBootstrap from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SwitchButton from "./SwitchButton";
import "./contact.scss";
import FlagInput from "../../common/flagInput";
import { FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import axios from "./../../utils/axios";
import { toast, toastAlert } from "../../common/alert";
import * as Yup from "yup";
import TooltipReference from "../../common/tooltip";
import { contactCreateOnConv } from "../../services/conversationservice";
import Select from "react-select";


type addContactProps = {
  component?: string;
  fetchContacts?: (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ) => void;
  handleCancel?: () => void;
  createContact?: (val: boolean) => void;
  getSingleConversation?: (conv: any) => void;
  handleClose?: () => void;
  getConvFromContact?: () => void;
  contactType?: string
};

interface ChannelOption {
  label: string;
  value: string;
}
interface FormValues {
  firstname: string;
  email: string;
  whatsappNum: string;
  channels: ChannelOption[]
}

type AppChannel = {
  label: string;
  value: string;
};

const AddContact: React.FC<addContactProps> = ({
  fetchContacts,
  handleCancel,
  component,
  createContact,
  getSingleConversation,
  handleClose,
  getConvFromContact,
  contactType
}) => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const channelUid = useSelector((state: any) => state.cartreducer.channelUid);
  const [code, setCode] = useState("+91");
  const [whatsappOpt, setwhatsappOpt] = useState(true);
  const [emailData, getEmail] = useState(false);
  const [channelOpts, setChannelList] = useState<ChannelOption[]>([]);
  const [selectedchannel, setChannel] = useState([])

  const[appoinmentContactName,setappoinmentContactname]=useState("");
  const [appNum,setappNum]=useState("");
  const [appEmail,setappEmail]=useState("");

 
  const [appChannel, setAppChannel] = useState<AppChannel[]>([]);

  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );

  const getdata = useSelector((state: any) => state.cartreducer.flagData);

  // const mobileNumberValidator = Yup.string()
  //   .required("WhatsApp number is required")
  //   .test('is-valid-mobile', 'Invalid WhatsApp number', (value, context) => {
  //     if (!value) {
  //       return false;
  //     }
  //     const country = code ?? context?.parent?.countryCode;
  //     const codePlusRem = country.slice(1);
  //     const countryIso2Obj = getdata.find((countryId: any) => countryId.phoneCode === codePlusRem);
  //     if (countryIso2Obj) {
  //       const { iso2 } = countryIso2Obj;
  //       const isValid = isValidNumber(value, iso2);
  //       if (!isValid) {
  //         throw new Yup.ValidationError('Invalid WhatsApp number', value, context.path);
  //       }
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });



  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Name is required").trim(),
    email: Yup.string().when([], {
      is: () => emailData === true,
      then: Yup.string().matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Email is invalid"
      ),
      otherwise: Yup.string(),
    }),
    channels: Yup.array().min(1, 'At least one channel is required'),
    // email: Yup.string().email("Invalid email").required("Email is required"),
    whatsappNum: Yup.string()
      .required("WhatsApp number is required")
      .min(7, "Whatsapp number must be at least 7 numbers")
      .max(13, "Whatsapp number cannot exceed 13 characters"),
    // whatsappNum: mobileNumberValidator
  });

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    
    if (component !== "conversation" && component !== 'initiateConversation') {
      const codePlusRem = code.slice(1);
      const addContactPayload = {
        uid: businessId,
        name: values.firstname.trim(),
        mobileNumber: values.whatsappNum,
        countryCode: codePlusRem,
        email: values.email,
        optIn: whatsappOpt,
        channelList: values.channels.map(channel => channel.value),
        contactType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD'
      };
      axios
        .post("contact/create", addContactPayload)
        .then((res) => {
          // handleClose?.();
          toastAlert("top-end", "Contact created successfully", "success");
          fetchContacts?.(0, 20, "", 0);
          handleCancel?.();
          handleClose?.();
        })
        .catch((error: any) => {
          toastAlert("top-end", error.response.data.message, "error");
        });
    } else {
      const codePlusRem = code.slice(1);
      const addContactPayload = {
        uid: businessId,
        name: values.firstname.trim(),
        mobileNumber: values.whatsappNum,
        countryCode: codePlusRem,
        email: values.email,
        channelUid: channelUid.value,
        contactType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD',
        optIn: whatsappOpt
      };
      contactCreateOnConv(addContactPayload, 'addContact')
        .then((convRes) => {
          if (getSingleConversation) {
            getSingleConversation(convRes);
            if (getConvFromContact)
              getConvFromContact();
            if (handleClose) {
              handleClose();
            }
          }
        })
        .catch((error) => {
          toastAlert("top-end", error.response.data.message, "error");
        });
    }
    setSubmitting(false);
  };


  const appointmentSubmit = (values: any) => {
  
    if (values === null || values.trim() === "") {
      toastAlert("top-end", "Contact name is required", "error");
      return;
    }
    if(appNum?.length<=7){
      toastAlert("top-end", "Mobile must be atleast 7 numbers", "error");
      return;
    }
    if (appNum === null || appNum.trim() === "") {
      toastAlert("top-end", "Contact number is required", "error");
      return;
    }
    // if (appEmail === null || appEmail.trim() === "") {
    //   toastAlert("top-end", "Contact email is required", "error");
    //   return;
    // }
  
    const codePlusRem = code.slice(1);
    const addContactPayload = {
      uid: businessId,
      name: values,
      mobileNumber: appNum,
      countryCode: codePlusRem,
      email: appEmail,
      optIn: whatsappOpt,
      channelList: [channelUid.value],
      contactType: contactType === 'supportInbox' ? 'ACCOUNT' : 'LEAD',
    };
  
    axios
      .post("contact/create", addContactPayload)
      .then((res) => {
        toastAlert("top-end", "Contact created successfully", "success");
        fetchContacts?.(0, 20, "", 0);
        handleCancel?.();
        handleClose?.();
      })
      .catch((error: any) => {
        toastAlert("top-end", error.response.data.message, "error");
      });
  };
  

  const codeChange = (e: string) => {
    setCode(e);
  };

  const whatsappOptState = (check: boolean) => {
    setwhatsappOpt(check);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (value) {
      getEmail(true);
    }
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };

  const getChannelList = () => {

    const options = channelData.map((data: any) => ({
      label: data.name,
      value: data.uid,
    }));
    setChannelList(options);
    
  }

  const channelChange = (channel: any) => {
    const data = channel.map((item: any) => item.value)
    setChannel(data)
    setAppChannel(data)
  }
  useEffect(() => {
    getChannelList()
  }, [])

  const cancelButton = () => {
    if (handleCancel) {
      handleCancel()
    }
    if (handleClose) {
      handleClose()
    }
  }

  const selectedOption: ChannelOption = {
    label: channelOpts[0]?.label || "",
    value: channelOpts[0]?.value || ""
  };

  return (
    <Formik
      initialValues={{
        firstname: "",
        email: "",
        whatsappNum: "",
        channels: channelOpts?.length ? [selectedOption] : []
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => (
        <Form
          className={`centered-form-temp `}
        >
          <FormBootstrap.Group className="form-group">
            <FormBootstrap.Label className="pt-2">
              Name<span className="redstar_required"></span>
            </FormBootstrap.Label>
            <Field
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Enter your name"
              className="form-control"
              // onChange={(event: any) => {
              //   // handleChange(event);
              //   const { name, value } = event.target;
              //   // setFieldValue(name, value);
              //   setappoinmentContactname(value) 
              //  }}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              //   let value = e.target.value
              //   setappoinmentContactname(value);
              // }}             
               onKeyDown={handleKeyDown}
            />
            <ErrorMessage
              name="firstname"
              component="div"
              className="text-danger pt-1"
            />
            <FormBootstrap.Label className="pt-3">
              WhatsApp Number
              <span className="redstar_required"></span>
            </FormBootstrap.Label>
            <div className="input-group inputBorder">
              <div className="input-group-prepend  ms-1 me-3">
                <FlagInput countryCode={code} codeChange={codeChange} />
              </div>
              <Field
                type="text"
                name="whatsappNum"
                id="whatsappNum"
                placeholder="Enter your number"
                className="form-control"
                //   value={whatsappNumber ? whatsappNumber : ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let value = e.target.value.replace(/[^0-9]/g, "");

                  if (value.length <= 13) {
                    setFieldValue("whatsappNum", value);
                    setappNum(value);
                  }
                }}
              />
            </div>
            <ErrorMessage
              name="whatsappNum"
              component="div"
              className="text-danger pt-1"
            />
            <FormBootstrap.Label className="pt-3">Email</FormBootstrap.Label>
            <Field
              type="text"
              name="email"
              id="email"
              placeholder="Enter your email"
              className="form-control"
              onChange={(event: any) => {
                handleChange(event);
                const { name, value } = event.target;
                setFieldValue(name, value);
                setappEmail(value)
              }}
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-danger pt-1"
            />
          </FormBootstrap.Group>
          {component !== "conversation" && component!=="appoinment" && (
            <>
              <div className="WhatsAppOpt pt-2">
                <img
                  src={require("./../../assets/img/whatsappiconImg.png")}
                  className="whatsappImg "
                  alt="Image"
                />
                <div className="me-3">
                  <FormBootstrap.Label htmlFor="whatsappOpt">
                    WhatsApp Opt-In {"  "}
                    <TooltipReference
                      placement="bottom"
                      icon="fa fa-info-circle"
                      content="When 'Opt-in' is on, messages are sent only to those who agreed to marketing."
                      tooltipId="opt-in"
                    />
                  </FormBootstrap.Label>
                </div>
                <SwitchButton
                  whatsappOptState={whatsappOptState}
                  checked={whatsappOpt}
                />
              </div>
              <FormBootstrap.Label className="pt-3" >
                Channels <span className="required"></span>
              </FormBootstrap.Label>
              <Field name="channels">
                {({ field, form }: any) => (
                  <Select
                    {...field}
                    isMulti
                    name="channels"
                    options={channelOpts && channelOpts.map((channel: any) => ({
                      value: channel.value,
                      label: channel?.label,
                      uid: channel?.value,
                    }))}
                    onChange={(selectedOptions: ChannelOption[] | null) => {
                      const transformedOptions = selectedOptions ? selectedOptions.map(option => ({
                        label: option.label,
                        value: option.value
                      })) : [];
                      form.setFieldValue("channels", transformedOptions);
                      setAppChannel(transformedOptions);
                    }}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        width: '102%',
                      }),
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                      }),
                      multiValueRemove: (provided) => ({
                        ...provided,
                        color: 'inherit',
                        ':hover': {
                          backgroundColor: 'transparent',
                          color: '#6f6b6b',
                        }
                      })
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="channels"
                component="div"
                className="text-danger pt-1"
              />
            </>
          )}
          {component !== "conversation" &&component!=="appoinment" && (
            <div className="addCancelBtn">
              <Button
                variant=""
                type="button"
                className="cancelButton me-2"
                onClick={cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant=""
                type="submit"
                className="sendButton"
                disabled={isSubmitting}
              >
                Add Contact
              </Button>
            </div>
          )}
          {component === "conversation" && (
            <div className="startConv mt-2">
              <Button
                variant=""
                type="button"
                className="cancelButton w-25 p-1 mr-3"
                onClick={() => createContact?.(false)}
              >
                Cancel
              </Button>
              <Button variant="" type="submit" className="sendButton w-50 p-1">
                Start Conversation
              </Button>
            </div>
          )}
          {component ==="appoinment" && (
             <div className="startConv mt-2">
             {/* <Button
               variant=""
               type="button"
               className="cancelButton w-25 p-1 mr-3"
               onClick={() => {createContact?.(false)
               }}
             >
               Cancel
             </Button> */}
             <Button variant="" 
             style={{position:"relative",top:"47px",height:"35px"}}
             onClick={()=>{ 
              appointmentSubmit(values.firstname)
              
              } } className="sendButton w-50 p-1">
Add  Contact           </Button>
           </div>

          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddContact;
