import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Label,
  Button,
  Card,
  Alert,
  Collapse,
  Input,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useSelector } from "react-redux";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { processChannelLimit, planCodes } from "../../common/commonfns";
import { PiDotsThreeOutlineVerticalLight } from "react-icons/pi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ChannelDelete } from "../../services/WhatsAppchannelService";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ChannelDetails = () => {
  const [channelLmt, setChannelLmt] = useState("");
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);
  const [copyLinkTooltip, setCopyLinkTooltip] = useState("Copy Id");

  useEffect(() => {
    if (roleRules) {
      const isInvalidAccess =
        roleRules.canViewWhatsappChannels === true &&
        roleRules.canManageWhatsappChannels === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);

  const channelData = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData
  );

  const profileDetail = useSelector((state: any) => state.cartreducer.business);

  useEffect(() => {
    if (channelData) {
      const parts = channelData?.currentLimit?.split("_");
      if (parts && parts.length >= 2) {
        const channelLimit = parts[1]; // Get the second part
        setChannelLmt(channelLimit);
      }
    }
    if(channelData?.waba?.fbAccountStatus){
      getFbStyles(channelData?.waba?.fbAccountStatus)
    }
  }, [channelData]);

  const [isCopy, setIsCopied] = useState(false);
  const copyUid = () => {
    // Obtain the value to be copied from channelData.uid
    const textToCopy = channelData.uid;
    // Copy the text to the clipboard
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Clipboard write was successful
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((error) => {
        // Handle any errors that may occur while copying
        console.error("Error copying to clipboard:", error);
      });
  };

  const handleFacebookLogin = () => {
    const facebookLoginUrl = `https://business.facebook.com/settings`;
    window.open(facebookLoginUrl, "_blank");
  };

  const getStatusStyles = (status: string) => {
    let text, textColor, border;

    switch (status) {
      case "running":
        text = "Connected";
        textColor = "green";
        border = "1px solid green";
        break;

      default:
        text = "Not Connected";
        textColor = "red";
        border = "1px solid red";
    }

    return { text, textColor, border };
  };

  const getFbStyles = (status: string) => {
    let fbText, fbTextColor, fbBorder;

    switch (status) {
      case "verified":
        fbText = "Verified";
        fbTextColor = "green";
        fbBorder = "1px solid green";
        break;

      case "pending_submission":
        fbText = "Pending Submission";
        fbTextColor = "orange";
        fbBorder = "1px solid orange";
        break;

      case "pending":
        fbText = "Pending";
        fbTextColor = "orange";
        fbBorder = "1px solid orange";
        break;
      case "pending_need_more_info":
        fbText = "Pending Need More Info";
        fbTextColor = "orange";
        fbBorder = "1px solid orange";
        break;
      case "rejected":
        fbText = "Rejected ";
        fbTextColor = "red";
        fbBorder = "1px solid orange";
        break;

      default:
        fbText = "Not Verified";
        fbTextColor = "red";
        fbBorder = "1px solid red";
    }

    return { fbText, fbTextColor, fbBorder };
  };

  const getQualityStyles = (status: string) => {
    let qualityText, qualityColor;

    switch (status) {
      case "High":
        qualityText = "High";
        qualityColor = "orange";
        break;

      case "Low":
        qualityText = "Low";
        qualityColor = "green";
        break;

      case "Medium":
        qualityText = "Medium";
        qualityColor = "yellow";
        break;

      default:
        qualityText = "Not Connected";
        qualityColor = "red";
    }

    return { qualityColor, qualityText };
  };

  const { text, textColor, border } = getStatusStyles(
    channelData?.integrationStatus || ""
  );
  const { fbText, fbTextColor, fbBorder } = getFbStyles(
    channelData?.waba?.fbAccountStatus || ""
  );
  const { qualityColor, qualityText } = getQualityStyles(
    channelData?.currentQualityRating || ""
  );

  const removeProgress = async (event: any) => {
    const response = await ChannelDelete(channelData.uid);
    // const { uid } = row;
    // event.stopPropagation();
    // if (sequenceId) {
    //   const dropPayload = {
    //     memberUid: memberUid,
    //     sequenceLogsUid: uid,
    //   };
    //   const response = await dropSequence(sequenceId, dropPayload);
    //   if (response) {
    //     getLogsData();
    //   }
    // }
  };

  const copyWhatsappLinkHandler = async () => {
    try {
      await navigator.clipboard.writeText(`${channelData?.uid}`);
      setCopyLinkTooltip("✔ Copied");
    } catch (error) {
      setCopyLinkTooltip("✗ Fail to Copy");
    }
  };

  return (
    <Container className="channel-details-container">
      <Row>
        <Col>
          <div className="title-card channelDetails">
            <Label className="title">Channel Details</Label>
            {/* <span className="pi-Dots"><PiDotsThreeOutlineVerticalLight color="#666E7D"  /></span>  */}

            {/* <UncontrolledDropdown group>
              <DropdownToggle
                className="optionBorder p-1"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <FontAwesomeIcon
                  color="#666E7D"
                  fontSize={18}
                  icon={faEllipsisVertical}
                  className="px-2 pt-1"
                />
              </DropdownToggle>
              <DropdownMenu className="ctxMenu">
                <DropdownItem
                  className="ctxMenuItem"
                  onClick={(event) => {
                    removeProgress(event);
                  }}
                >
                  <FontAwesomeIcon className="mr-2" icon={faXmarkCircle} />
                  Remove from Channel
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
             */}
          </div>
          <div className="channelDetails">
            <div className="float-right cursor-pointer mr-1">
              <OverlayTrigger
                overlay={<Tooltip>{copyLinkTooltip}</Tooltip>}
                placement="bottom"
                onToggle={(isOpen) => {
                  if (!isOpen) {
                    setTimeout(() => {
                      setCopyLinkTooltip("Copy Id");
                    }, 1000);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faCopy}
                  onClick={copyWhatsappLinkHandler}
                  fontSize={14}
                />
              </OverlayTrigger>
            </div>
            <div className="ml-2">
              <span>
                <b>Id : </b>
              </span>
              <span className="title">{channelData?.uid}</span>
            </div>
          </div>
          <Card>
            <Container>
              <Row>
                <Col md="1">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2884_12148)">
                      <path
                        d="M0.423595 9.53519C0.42313 11.2156 0.8622 12.8564 1.69708 14.3026L0.34375 19.2438L5.40049 17.9179C6.79912 18.6793 8.36618 19.0783 9.95863 19.0784H9.96282C15.2198 19.0784 19.4991 14.8006 19.5013 9.54271C19.5024 6.99488 18.511 4.59907 16.71 2.79659C14.9092 0.994264 12.5142 0.00116279 9.96243 0C4.70484 0 0.425843 4.27752 0.423672 9.53519"
                        fill="url(#paint0_linear_2884_12148)"
                      />
                      <path
                        d="M0.0829457 9.87721C0.0824031 11.6181 0.537209 13.3175 1.40186 14.8155L0 19.9339L5.23806 18.5605C6.68132 19.3474 8.30628 19.7622 9.95977 19.7629H9.96403C15.4096 19.7629 19.8426 15.3312 19.845 9.88512C19.8459 7.24574 18.8189 4.7638 16.9535 2.89674C15.0878 1.02992 12.6072 0.00108527 9.96403 0C4.51752 0 0.0851163 4.43101 0.0829457 9.87721ZM3.20233 14.5575L3.00674 14.2471C2.18457 12.9398 1.75062 11.4291 1.75124 9.87783C1.75302 5.35109 5.43705 1.66822 9.96713 1.66822C12.1609 1.66915 14.2226 2.52434 15.7733 4.07597C17.324 5.62775 18.1772 7.69054 18.1767 9.8845C18.1747 14.4112 14.4905 18.0946 9.96403 18.0946H9.96078C8.4869 18.0938 7.0414 17.698 5.78078 16.95L5.48078 16.7721L2.3724 17.5871L3.20233 14.5574V14.5575Z"
                        fill="url(#paint1_linear_2884_12148)"
                      />
                      <path
                        d="M7.49452 5.74801C7.30956 5.33692 7.11491 5.32863 6.93902 5.32142C6.79499 5.31522 6.63034 5.31568 6.46584 5.31568C6.30119 5.31568 6.03367 5.37762 5.80755 5.62452C5.58119 5.87165 4.94336 6.46886 4.94336 7.68351C4.94336 8.89824 5.82809 10.0721 5.95142 10.237C6.07491 10.4016 7.65941 12.974 10.1689 13.9636C12.2544 14.786 12.6789 14.6224 13.1315 14.5812C13.5842 14.5401 14.5923 13.9841 14.7979 13.4076C15.0037 12.8312 15.0037 12.3371 14.942 12.2338C14.8803 12.131 14.7157 12.0692 14.4688 11.9458C14.2218 11.8223 13.008 11.225 12.7817 11.1426C12.5554 11.0603 12.3908 11.0192 12.2262 11.2664C12.0615 11.5132 11.5887 12.0692 11.4446 12.2338C11.3006 12.3989 11.1565 12.4194 10.9097 12.2959C10.6627 12.172 9.86755 11.9117 8.92421 11.0706C8.19026 10.4162 7.69475 9.60808 7.55072 9.36088C7.40669 9.11405 7.5353 8.98026 7.6591 8.85723C7.77003 8.74661 7.90607 8.56894 8.02964 8.42483C8.15274 8.28064 8.19382 8.17777 8.27615 8.01312C8.35855 7.84832 8.31731 7.70413 8.25568 7.58064C8.19382 7.45715 7.71406 6.23615 7.49452 5.74801Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_2884_12148"
                        x1="958.224"
                        y1="1924.38"
                        x2="958.224"
                        y2="0"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#1FAF38" />
                        <stop offset="1" stop-color="#60D669" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2884_12148"
                        x1="992.248"
                        y1="1993.39"
                        x2="992.248"
                        y2="0"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#F9F9F9" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                      <clipPath id="clip0_2884_12148">
                        <rect width="19.845" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Col>
                <Col md="10">
                  <div className="headerLabel">
                    <TooltipReference
                      placement="bottom"
                      content={channelData?.displayName}
                      tooltipId="displayName"
                    >
                      {channelData?.displayName
                        ? sliceText(channelData?.displayName, 20)
                        : "-"}
                    </TooltipReference>
                    {channelData?.oba &&(
                    <svg
                      className="ms-2"
                      width="20"
                      height="20"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 0L10.6462 1.78748L12.905 0.89128L13.6126 3.21601L16.0365 3.38859L15.6654 5.79013L17.7744 6.99731L16.398 9L17.7744 11.0027L15.6654 12.2099L16.0365 14.6114L13.6126 14.784L12.905 17.1087L10.6462 16.2125L9 18L7.35379 16.2125L5.09505 17.1087L4.38742 14.784L1.96352 14.6114L2.33463 12.2099L0.225649 11.0027L1.602 9L0.225649 6.99731L2.33463 5.79013L1.96352 3.38859L4.38742 3.21601L5.09505 0.89128L7.35379 1.78748L9 0Z"
                        fill="#007ec3"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.6646 7.05978L8.21047 12.173L5.28271 9.4282L6.0856 8.57179L8.21047 10.5639L12.8617 6.20337L13.6646 7.05978Z"
                        fill="white"
                      />
                    </svg>
                    )}
                  </div>
                  <div className="mobileNumber">
                    {(channelData.countryCode.includes("+")
                      ? channelData?.countryCode
                      : "+" + channelData?.countryCode) +
                      " " +
                      channelData?.mobileNumber.slice(
                        channelData?.countryCode?.replace(/\+/g, "")?.length
                      )}
                  </div>
                </Col>
                <Col md="1"></Col>
              </Row>
              {/* <Row >
                <Col>
                  <Label>
                    ID : {channelData?.uid}
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={copyUid}
                    >
                      <path
                        d="M12.2063 5L8.75 1.54375C8.66218 1.45582 8.54303 1.40636 8.41875 1.40625H6.875C6.41916 1.40625 5.98199 1.58733 5.65966 1.90966C5.33733 2.23199 5.15625 2.66916 5.15625 3.125V3.90625H4.375C3.91916 3.90625 3.48199 4.08733 3.15966 4.40966C2.83733 4.73199 2.65625 5.16916 2.65625 5.625V11.875C2.65625 12.3308 2.83733 12.768 3.15966 13.0903C3.48199 13.4127 3.91916 13.5938 4.375 13.5938H8.75C9.20584 13.5938 9.64301 13.4127 9.96534 13.0903C10.2877 12.768 10.4688 12.3308 10.4688 11.875V11.0938H10.625C11.0808 11.0938 11.518 10.9127 11.8403 10.5903C12.1627 10.268 12.3438 9.83084 12.3438 9.375V5.3125C12.3388 5.19475 12.2897 5.08318 12.2063 5ZM8.90625 3.00625L10.7438 4.84375H8.90625V3.00625ZM9.53125 11.875C9.53125 12.0822 9.44894 12.2809 9.30243 12.4274C9.15591 12.5739 8.9572 12.6562 8.75 12.6562H4.375C4.1678 12.6562 3.96909 12.5739 3.82257 12.4274C3.67606 12.2809 3.59375 12.0822 3.59375 11.875V5.625C3.59375 5.4178 3.67606 5.21909 3.82257 5.07257C3.96909 4.92606 4.1678 4.84375 4.375 4.84375H5.15625V9.375C5.15625 9.83084 5.33733 10.268 5.65966 10.5903C5.98199 10.9127 6.41916 11.0938 6.875 11.0938H9.53125V11.875ZM10.625 10.1562H6.875C6.6678 10.1562 6.46909 10.0739 6.32257 9.92743C6.17606 9.78091 6.09375 9.5822 6.09375 9.375V3.125C6.09375 2.9178 6.17606 2.71909 6.32257 2.57257C6.46909 2.42606 6.6678 2.34375 6.875 2.34375H7.96875V5.3125C7.97037 5.43632 8.02027 5.55461 8.10783 5.64217C8.19539 5.72973 8.31368 5.77963 8.4375 5.78125H11.4062V9.375C11.4062 9.5822 11.3239 9.78091 11.1774 9.92743C11.0309 10.0739 10.8322 10.1562 10.625 10.1562Z"
                        fill={isCopy?'#4ACA59':"#292929"}
                      />
                    </svg>
                  </Label>
                </Col>
              </Row> */}
              <div
                className={`p-1 text-center mb-1 ${
                  profileDetail.role?.name === "MEMBER" ||
                  profileDetail.business?.plan?.code === planCodes("free")
                    ? "disabled-data"
                    : ""
                }`}
                style={{ border: border, color: textColor }}
              >
                {text}
              </div>
            </Container>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mb-1 me-1"
          >
            <g clip-path="url(#clip0_2884_12163)">
              <path
                d="M20 10C20 4.47719 15.5228 0 10 0C4.47719 0 0 4.47711 0 10C0 14.9913 3.65687 19.1284 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3088 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.9498 6.5625 11.5625 7.33336 11.5625 8.12422V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3431 19.1284 20 14.9913 20 10Z"
                fill="#1877F2"
              />
              <path
                d="M13.8926 12.8906L14.3359 10H11.5625V8.12422C11.5625 7.33328 11.9499 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93047 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C8.95439 19.9595 9.4768 20.0001 10 20C10.5232 20.0002 11.0456 19.9595 11.5625 19.8785V12.8906H13.8926Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2884_12163">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <Label className="title">Facebook Business</Label>
          <Card>
            <Container>
              {/* <Row className="">
                <Col>
                  <div>
                    <Label className="h6 ps-2">Facebook</Label>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <div>
                    <span className="fbLabel">FB Business Verification</span>
                    <TooltipReference
                      placement="bottom"
                      icon={faInfoCircle}
                      content={
                        "Facebook business verification is required to raise the daily messaging limit from 50"
                      }
                      tooltipId="facebook-verification"
                    ></TooltipReference>
                  </div>
                </Col>
              </Row>
              <div
                className={`p-1 mt-2 text-center cursor-pointer mb-1 ${
                  profileDetail.role?.name === "MEMBER" ||
                  profileDetail.business?.plan?.code === planCodes("free")
                    ? "disabled-data"
                    : ""
                }`}
                onClick={() =>
                  profileDetail.role?.name === "MEMBER" ||
                  profileDetail.business?.plan?.code === planCodes("free")
                    ? ""
                    : handleFacebookLogin()
                }
                style={{ color: fbTextColor, border: fbBorder }}
              >
                {fbText}
              </div>
            </Container>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mb-1 me-1"
          >
            <path
              d="M21.6635 7.93127C21.1376 6.67625 20.3727 5.53553 19.4111 4.57268C18.4525 3.60815 17.3138 2.84129 16.0596 2.31565C14.7748 1.77455 13.3943 1.49718 12.0002 1.50002H11.9533C10.5354 1.50705 9.16427 1.7883 7.87286 2.34143C6.62944 2.87246 5.50139 3.64068 4.55177 4.60315C3.59958 5.56384 2.84335 6.70057 2.32521 7.95002C1.78735 9.24931 1.51482 10.6431 1.52365 12.0492C1.53068 13.6758 1.91974 15.2906 2.6463 16.7344V20.2969C2.6463 20.8922 3.12911 21.375 3.72208 21.375H7.2799C8.73038 22.1069 10.331 22.4921 11.9557 22.5H12.0049C13.4065 22.5 14.7635 22.2281 16.0432 21.6961C17.291 21.1767 18.4255 20.4188 19.383 19.4649C20.3486 18.5063 21.108 17.386 21.6401 16.1367C22.1908 14.843 22.4721 13.4672 22.4791 12.0469C22.4838 10.6196 22.2072 9.2344 21.6635 7.93127ZM7.32208 13.125C6.70333 13.125 6.19943 12.6211 6.19943 12C6.19943 11.3789 6.70333 10.875 7.32208 10.875C7.94083 10.875 8.44474 11.3789 8.44474 12C8.44474 12.6211 7.94318 13.125 7.32208 13.125ZM12.0002 13.125C11.3815 13.125 10.8776 12.6211 10.8776 12C10.8776 11.3789 11.3815 10.875 12.0002 10.875C12.619 10.875 13.1229 11.3789 13.1229 12C13.1229 12.6211 12.619 13.125 12.0002 13.125ZM16.6783 13.125C16.0596 13.125 15.5557 12.6211 15.5557 12C15.5557 11.3789 16.0596 10.875 16.6783 10.875C17.2971 10.875 17.801 11.3789 17.801 12C17.801 12.6211 17.2971 13.125 16.6783 13.125Z"
              fill="#FF7342"
            />
          </svg>
          <Label className="title ">Message </Label>
          <Card>
            <Container>
              {/* <Row className="">
                <Col>
                  
                  <Label className="h6 ps-2">Message</Label>
                </Col>
              </Row> */}
              <div className="d-flex mb-2">
                <div>
                  <span className="labelTextName pe-1">Quality Rating</span>
                  <TooltipReference
                    placement="bottom"
                    icon={faInfoCircle}
                    content={
                      "Your quality rating is determined by how positively recipients have responded to your messages in the last 7 days. It takes into account the quality of interactions between businesses and users"
                    }
                    tooltipId="quality-rating"
                  ></TooltipReference>
                  :
                </div>
                <div className="fbIcon">
                  <div className="high" style={{ color: qualityColor }}>
                    <span
                      style={{ backgroundColor: qualityColor }}
                      className="pt-1 pb-0"
                    ></span>{" "}
                    {qualityText}
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <span className="labelTextName pe-0">Message Limit</span>{" "}
                  <TooltipReference
                    placement="bottom"
                    icon={faInfoCircle}
                    content={
                      "The messaging limits specify the maximum number of category-specific conversations a business can start in a rolling 24-hour period"
                    }
                    tooltipId="msg-limits"
                  ></TooltipReference>{" "}
                  :
                </div>
                <div className="d-flex fbIcon">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    className="mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2884_12189)">
                      <path
                        d="M15 7.5C15 3.35789 11.6421 0 7.5 0C3.35789 0 0 3.35783 0 7.5C0 11.2434 2.74266 14.3463 6.32812 14.9089V9.66797H4.42383V7.5H6.32812V5.84766C6.32812 3.96797 7.44785 2.92969 9.16096 2.92969C9.98156 2.92969 10.8398 3.07617 10.8398 3.07617V4.92188H9.89414C8.96238 4.92188 8.67188 5.50002 8.67188 6.09316V7.5H10.752L10.4194 9.66797H8.67188V14.9089C12.2573 14.3463 15 11.2435 15 7.5Z"
                        fill="#007EC3"
                      />
                      <path
                        d="M10.4194 9.66797L10.752 7.5H8.67188V6.09316C8.67188 5.49996 8.96244 4.92188 9.89414 4.92188H10.8398V3.07617C10.8398 3.07617 9.98156 2.92969 9.16096 2.92969C7.44785 2.92969 6.32812 3.96797 6.32812 5.84766V7.5H4.42383V9.66797H6.32812V14.9089C6.71579 14.9696 7.1076 15.0001 7.5 15C7.8924 15.0001 8.28421 14.9697 8.67188 14.9089V9.66797H10.4194Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2884_12189">
                        <rect width="15" height="15" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="limit">
                    {" "}
                    {channelData?.currentLimit
                      ? processChannelLimit(
                          channelData?.currentLimit,
                          "channelDetails"
                        )
                      : "-"}
                  </span>
                </div>
              </div>
            </Container>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <svg
            width="20"
            height="20"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0L10.6462 1.78748L12.905 0.89128L13.6126 3.21601L16.0365 3.38859L15.6654 5.79013L17.7744 6.99731L16.398 9L17.7744 11.0027L15.6654 12.2099L16.0365 14.6114L13.6126 14.784L12.905 17.1087L10.6462 16.2125L9 18L7.35379 16.2125L5.09505 17.1087L4.38742 14.784L1.96352 14.6114L2.33463 12.2099L0.225649 11.0027L1.602 9L0.225649 6.99731L2.33463 5.79013L1.96352 3.38859L4.38742 3.21601L5.09505 0.89128L7.35379 1.78748L9 0Z"
              fill="#007ec3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.6646 7.05978L8.21047 12.173L5.28271 9.4282L6.0856 8.57179L8.21047 10.5639L12.8617 6.20337L13.6646 7.05978Z"
              fill="white"
            />
          </svg>
          <Label className="title">Apply Blue Tick</Label>
          <Card>
            <Container>
              {/* <Row className="">
                <Col>
                  <div>
                    <Label className="h6 ps-2">Facebook</Label>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <div>
                    <span className="fbLabel">
                      Get Verified Blue Tick on your WhatsApp
                    </span>
                    <TooltipReference
                      placement="bottom"
                      icon={faInfoCircle}
                      content={
                        "Approval for an Official Business Account on WhatsApp is not guaranteed. The review, done by the WhatsApp API Support Team, may take up to 7 days"
                      }
                      tooltipId="tasks-tooltip"
                    ></TooltipReference>
                  </div>
                </Col>
              </Row>
              <div className={`${isInvalidAccess ? "notAllowed" : ""}`}>
                <div
                  className={`text-center mt-2 upload-select ${
                    isInvalidAccess ? "disabledState" : ""
                  } ${
                    profileDetail.role?.name === "MEMBER" ||
                    profileDetail.business?.plan?.code === planCodes("free") || channelData?.oba
                      ? "disabled-data"
                      : ""
                  }`}
                  onClick={() =>
                    profileDetail.role?.name === "MEMBER" ||
                    profileDetail.business?.plan?.code === planCodes("free") || channelData?.oba
                      ? ""
                      : handleFacebookLogin()
                  }
                >
                  {channelData?.oba ?'Blue Tick Verified':'Apply for Blue Tick'}
                  
                </div>
              </div>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ChannelDetails;
