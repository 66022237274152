import { Handle, Position } from 'reactflow';

import classes from './startNode.module.css';

function StartNode() {
    return (
        <div className={classes.card}>
            <div>Start</div>
            <Handle id='b' type='source' position={Position.Right} isConnectable />
        </div>
    );
}

export default StartNode;