import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import {
  getTemplateTrackingAnalytics,
  getTemplateTrackingRecords,
  getTemplateTrackingAnalyticsCount
} from "../../services/templateService";
import BizTableReports from "../../common/BizTableReports";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { BsCalendar } from "react-icons/bs";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Input,
  PopoverBody,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Dropdown,
  Button,
  Spinner,
  OverlayTrigger,
  Overlay,
  Popover,
  Form,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import excelIcon from "../../assets/img/excel.png";
import * as XLSX from "xlsx";
import getAdjustedDateRange from "../../utils/getAdjustedDateRange";
import { dateSplit } from "../../common/commonfns";
import SyncButton from "../../common/syncButton";
import { toast } from "../../common/alert";
import Smile from "../../assets/img/svgs/Smile.svg";
import TooltipReference from "../../common/tooltip";
import { MdOutlineUnsubscribe } from "react-icons/md";

const moment = extendMoment(originalMoment);
enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}
type Props = {};
const TemplateAnalytics: React.FC<Props> = (props: Props) => {
  const [data, setData] = useState<any>([]);
  // const [isDownloading, setIsDownloading] = useState(false);
  const { templateId, templateName } = useParams();
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );
  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dashboardData,setDashboardData] = useState({
    "totalRecipients": 0,
    "totalSentCount": 0,
    "totalDeliveredCount": 0,
    "totalReadCount": 0,
    "totalFailedCount": 0,
    "totalRepliedCount": 0,
    "totalReactionCount": 0,
    "totalClicksCount": 0,
    "totalUnsubscribedCount": 0,
    "buttonMap": null
  })
  const datepickertarget = useRef(null);
  const filtertarget = useRef(null);
  const targetMember = useRef(null);
  const targetType = useRef(null);
  const currentDate = new Date();
  const today = moment().clone().startOf("day");
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );
  const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(
    dateRangeValue.start,
    dateRangeValue.end
  );
  const navigate = useNavigate();
  const tableData = useMemo(() => {
    return Object.keys(data).map((date) => ({ date, ...data[date] }));
  }, [data]);
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed (0-11)
    const year = date.getUTCFullYear();
    // Format day, month, and year as DD-MM-YYYY
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };
  const [isDownloading, setIsDownloading] = useState<Record<number, boolean>>(
    {}
  );
  const exportExcel = async (cell: any, row: any, index: number) => {
    setIsDownloading((prev: any) => ({ ...prev, [index]: true }));
    // setIsDownloading(true);
    const { date } = row;
    const Ids = {
      templateId,
      channelUid,
    };
    try {
      const response = await getTemplateTrackingRecords(Ids, date);
      if (response) {
        // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        // Apply custom headers to the worksheet
        const headers = ["Template Name", templateName, "", "", "", "", ""];

        const dateHeader = ["Date", date, "", "", "", "", ""];
        const subHeaders = [
          "Contact Name",
          "Mobile Number",
          "Status",
          "Reactions",
          "Replied",
          "Unsubscribed",
          "Clicked",
          "Error Code",
          "Error Description",
        ];
        // Add headers to the worksheet
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
        XLSX.utils.sheet_add_aoa(ws, [dateHeader], { origin: "A2" });
        XLSX.utils.sheet_add_aoa(ws, [subHeaders], { origin: "A3" });
        console.log('unSubscribed',response)
        // Convert response data to an array of arrays (if needed) and add it below the headers
        const data = response.map((item: any) => [
          item.name,
          item.mobileNumber,
          item.status,
          item.reactions ,
          item.replyMessages ,
          item.unSubscribed === "1" ? true : "",
          item.clickedUrl ,
          item.errorCode ,
          item.errorMessage ,
        ]);
        // Add the data to the worksheet starting from row 3 (below the headers)
        XLSX.utils.sheet_add_aoa(ws, data, { origin: "A4" });
        // Set worksheet columns with headers and formatting
        ws["!cols"] = [
          { width: 20 }, // Contact Name
          { width: 27 }, // Mobile Number
          { width: 12 }, // Delivery Status
          { width: 23 }, // Sent Timestamp
          { width: 15 }, // Replied Messages
          { width: 15 }, // User Reactions
          { width: 30 }, // Clicked Links
        ];
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        // Generate and save the Excel file
        XLSX.writeFile(wb, `${date}-${templateName}.xlsx`);
        // setIsDownloading(false);
        setIsDownloading((prev) => ({ ...prev, [index]: false }));
      }
    } catch (error) {
      // setIsDownloading(false);
      setIsDownloading((prev) => ({ ...prev, [index]: false }));
      console.error("Error exporting to Excel:", error);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        sticky: "left",
        Cell: ({ cell, row }: any) => (
          <TooltipReference
          placement="right"
          content={cell.value}
          tooltipId={`cell_${row.id}`}
        >
          <p title={moment(cell.value).format("MMM YYYY h:mm A")} style={{cursor:'pointer'}}>
            {dateSplit(cell.value)}
          </p>
        </TooltipReference>
        ),
      },
      {
        Header: "Sent",
        accessor: "totalSentCount",
      },
      {
        Header: "Delivered",
        accessor: "totalDeliveredCount",
      },
      {
        Header: "Read",
        accessor: "totalReadCount",
      },
      {
        Header: "Replied",
        accessor: "totalRepliedCount",
      },
      {
        Header: "Clicked",
        accessor: "totalClicksCount",
      },
      {
        Header: "Reactions",
        accessor: "totalReactionCount",
      },
      {
        Header: "Failed",
        accessor: "totalFailedCount",
      },
      {
        Header: "UnSubscribed",
        accessor: "totalUnsubscribedCount",
      },
      {
        Header: "Action",
        accessor: "edit",
        Cell: ({ cell, row, row: { index } }: any) => (
          <OverlayTrigger
            trigger={["hover", "hover"]}
            placement="bottom"
            overlay={
              <Tooltip>
                {isDownloading[index] ? "Downloading..." : "Download Excel"}
              </Tooltip>
            }
          >
            <img
              alt="Report"
              src={excelIcon}
              onClick={() => exportExcel(cell, row.original, index)}
              width={20}
              style={{
                cursor: isDownloading[index] ? "not-allowed" : "pointer",
                opacity: isDownloading[index] ? 0.5 : 1,
              }}
            />
          </OverlayTrigger>
        ),
      },
    ],
    [isDownloading]
  );

  const fetchData = useCallback(
    async (pageSize: number, currentPage: number) => {
      const startDate = formatDate(adjustedStart.valueOf());
      const endDate = formatDate(adjustedEnd.valueOf());
      const listPayload = {
        from: startDate,
        to: endDate,
        page:0,
        limit:100,
      };
      const listPayloadCounts = {
        from: startDate,
        to: endDate
      };
      const Ids = {
        templateId,
        channelUid,
      };
      const response = await getTemplateTrackingAnalytics(Ids, listPayload);
      const responseCounts = await getTemplateTrackingAnalyticsCount(Ids, listPayloadCounts);
      if(responseCounts){
        setDashboardData(responseCounts)
      }
      if (response) {
        setData(response);
        return { data: response };
      } else {
        return { data: [], totalPages: 0 };
      }
    },
    [templateId, channelUid, dateRangeValue]
  );
  const getStartDate = (
    subtractDays: number,
    unit: moment.unitOfTime.DurationConstructor = "days"
  ) => {
    return today.clone().subtract(subtractDays, unit).startOf(unit);
  };
  const changeDateFilterHandler = (event: any) => {
    const filter = event.target.value as DateFilterType;
    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(
          moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
          )
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
        setShowDatePicker(false);
        break;
      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
        setShowDatePicker(false);
        break;
      case DateFilterType.ThisWeek:
        setDateRangeValue(
          moment.range(today.clone().startOf("week"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.PreviousWeek:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "week"),
            getStartDate(1, "week").endOf("week")
          )
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.ThisMonth:
        setDateRangeValue(
          moment.range(today.clone().startOf("month"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.PreviousMonth:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        );
        setShowDatePicker(false);
        break;
      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;
      default:
        break;
    }
    // setCurrentData((item: any) => ({ ...item, startTime: adjustedStart.valueOf(),endTime: adjustedEnd.valueOf() }));
    setDateFilter(filter);
  };

  const totals = tableData?.reduce(
    (acc: any, item: any) => {
      acc.totalSentCount += item.totalSentCount;
      acc.totalDeliveredCount += item.totalDeliveredCount;
      acc.totalReadCount += item.totalReadCount;
      acc.totalFailedCount += item.totalFailedCount;
      acc.totalRepliedCount += item.totalRepliedCount;
      acc.totalReactionCount += item.totalReactionCount;
      acc.totalClicksCount += item.totalClicksCount;
      return acc;
    },
    {
      totalSentCount: 0,
      totalDeliveredCount: 0,
      totalReadCount: 0,
      totalFailedCount: 0,
      totalRepliedCount: 0,
      totalReactionCount: 0,
      totalClicksCount: 0,
    }
  );

  return (
    <div>
      <div className="d-flex h5 mt-2">
        <FaArrowLeft
          color="black"
          className="me-2"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div>{templateName} - Analytics</div>
      </div>
      <Row className="templateDashboardRow mb-2">
      <Col>
          <div className="counts">
            {/* {totals.totalSentCount + totals.totalFailedCount} */}
            {dashboardData?.totalRecipients}
            </div>
          <div>
          <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M10.9857 9.84369C10.343 8.71574 9.33949 7.83686 8.13664 7.34853C8.7348 6.89991 9.17665 6.27445 9.39959 5.56076C9.62253 4.84706 9.61527 4.08131 9.37882 3.37197C9.14238 2.66264 8.68874 2.04568 8.08217 1.60848C7.4756 1.17129 6.74684 0.936035 5.99914 0.936035C5.25143 0.936035 4.52268 1.17129 3.91611 1.60848C3.30953 2.04568 2.8559 2.66264 2.61945 3.37197C2.38301 4.08131 2.37574 4.84706 2.59868 5.56076C2.82162 6.27445 3.26347 6.89991 3.86164 7.34853C2.65879 7.83686 1.65525 8.71574 1.01257 9.84369C0.972751 9.90772 0.946209 9.97911 0.934526 10.0536C0.922844 10.1281 0.926258 10.2042 0.944568 10.2773C0.962877 10.3505 0.995706 10.4192 1.0411 10.4794C1.0865 10.5396 1.14354 10.5901 1.20884 10.6278C1.27413 10.6655 1.34634 10.6897 1.42117 10.699C1.49601 10.7083 1.57194 10.7024 1.64446 10.6817C1.71697 10.661 1.78459 10.626 1.84329 10.5787C1.902 10.5313 1.95058 10.4727 1.98617 10.4062C2.83554 8.93807 4.33554 8.06244 5.99914 8.06244C7.66273 8.06244 9.16273 8.93853 10.0121 10.4062C10.0892 10.5302 10.2115 10.6193 10.3532 10.6548C10.4948 10.6903 10.6447 10.6692 10.7712 10.5962C10.8976 10.5231 10.9907 10.4038 11.0307 10.2634C11.0707 10.1229 11.0546 9.97243 10.9857 9.84369ZM3.56164 4.49994C3.56164 4.01785 3.70459 3.54658 3.97243 3.14574C4.24027 2.74489 4.62095 2.43247 5.06635 2.24798C5.51174 2.0635 6.00184 2.01523 6.47467 2.10928C6.9475 2.20333 7.38182 2.43548 7.72271 2.77637C8.0636 3.11726 8.29575 3.55158 8.3898 4.02441C8.48385 4.49724 8.43558 4.98734 8.25109 5.43273C8.0666 5.87813 7.75418 6.25881 7.35334 6.52665C6.95249 6.79448 6.48123 6.93744 5.99914 6.93744C5.3529 6.9367 4.73334 6.67965 4.27639 6.22269C3.81943 5.76573 3.56238 5.14618 3.56164 4.49994Z"
                  fill="#888888"
                />
              </svg>&nbsp;
            Recipients
          </div>
        </Col>
        <Col>
          <div className="counts">
            {/* {totals.totalSentCount} */}
            {dashboardData?.totalSentCount}
            </div>
          <div>
            <svg
              className="me-1"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M2.0625 6.5625L4.6875 9.1875L9.9375 3.5625"
                stroke="#888888"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Sent
          </div>
        </Col>
        <Col>
          <div className="counts">
            {/* {totals.totalDeliveredCount} */}
            {dashboardData?.totalDeliveredCount}
            </div>
          <div>
            <svg
              className="me-1"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M1.3125 7.3125L3.1875 9.1875M5.8125 6.1875L7.6875 4.3125M4.3125 7.3125L6.1875 9.1875L10.6875 4.3125"
                stroke="#888888"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Delivered
          </div>
        </Col>
        <Col>
          <div className="counts">
            {/* {totals.totalReadCount} */}
            {dashboardData?.totalReadCount}
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              className="me-1"
            >
              <path
                d="M1.3125 7.3125L3.1875 9.1875M5.8125 6.1875L7.6875 4.3125M4.3125 7.3125L6.1875 9.1875L10.6875 4.3125"
                stroke="#007EC3"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Read
          </div>
        </Col>
        <Col>
          <div className="counts">
            {/* {totals.totalRepliedCount} */}
            {dashboardData?.totalRepliedCount}
            </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              className="me-1"
            >
              <path
                d="M4 2.5L1.5 5L4 7.5"
                stroke="#888888"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.5 5H5.5C8.2615 5 10.5 7.2385 10.5 10V10.5"
                stroke="#888888"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Replied
          </div>
        </Col>
        <Col>
          <div className="counts">
          {dashboardData?.totalClicksCount}
            {/* {totals.totalClicksCount} */}
            </div>
          <div>
            <svg
              className="me-1"
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              focusable="false"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="5" y="2" width="14" height="20" rx="7"></rect>
              <path d="M12 6v4"></path>
            </svg>
            Clicked
          </div>
        </Col>
        <Col>
          <div className="counts">{totals.totalReactionCount}</div>
          <div>
            <img src={Smile} alt="Smile" className="me-1"></img>
            Reactions
          </div>
        </Col>
        <Col>
          <div className="counts">
            {/* {totals.totalFailedCount} */}
          {dashboardData?.totalFailedCount}
            </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              className="me-1"
            >
              <g clipPath="url(#clip0_2402_33718)">
                <path
                  d="M8.17086 4.6609C8.21889 4.61612 8.25763 4.56232 8.28487 4.50257C8.31211 4.44281 8.32731 4.37828 8.32961 4.31265C8.33191 4.24703 8.32125 4.18159 8.29826 4.12008C8.27527 4.05856 8.24039 4.00218 8.19561 3.95415C8.15083 3.90612 8.09703 3.86738 8.03727 3.84014C7.97752 3.81291 7.91299 3.7977 7.84736 3.7954C7.78173 3.79311 7.71629 3.80376 7.65478 3.82675C7.59327 3.84974 7.53689 3.88462 7.48886 3.9294L6.02586 5.2934L4.66186 3.8299C4.57059 3.73642 4.44636 3.68238 4.31576 3.67934C4.18515 3.67629 4.05854 3.72448 3.96301 3.81361C3.86749 3.90273 3.81064 4.0257 3.80463 4.1562C3.79862 4.28671 3.84393 4.41438 3.93086 4.5119L5.29486 5.9749L3.83136 7.3389C3.78163 7.38323 3.74126 7.43705 3.71262 7.49719C3.68399 7.55734 3.66766 7.6226 3.66459 7.68915C3.66153 7.75569 3.67179 7.82218 3.69478 7.8847C3.71777 7.94722 3.75302 8.00452 3.79847 8.05323C3.84391 8.10194 3.89863 8.14108 3.95941 8.16834C4.02019 8.19561 4.08581 8.21045 4.15241 8.21201C4.219 8.21356 4.28524 8.20179 4.34723 8.17739C4.40921 8.15298 4.46569 8.11644 4.51336 8.0699L5.97636 6.7064L7.34036 8.1694C7.3844 8.22005 7.43819 8.26131 7.49852 8.29072C7.55884 8.32014 7.62448 8.33711 7.6915 8.34062C7.75853 8.34413 7.82557 8.33411 7.88864 8.31116C7.95171 8.28821 8.00952 8.25279 8.05861 8.20702C8.1077 8.16126 8.14707 8.10607 8.17439 8.04476C8.2017 7.98345 8.21639 7.91727 8.21758 7.85017C8.21877 7.78306 8.20643 7.7164 8.18131 7.65416C8.15619 7.59193 8.1188 7.53538 8.07136 7.4879L6.70786 6.0249L8.17086 4.6609Z"
                  fill="#888888"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.5 6C0.5 2.9625 2.9625 0.5 6 0.5C9.0375 0.5 11.5 2.9625 11.5 6C11.5 9.0375 9.0375 11.5 6 11.5C2.9625 11.5 0.5 9.0375 0.5 6ZM6 10.5C5.40905 10.5 4.82389 10.3836 4.27792 10.1575C3.73196 9.93131 3.23588 9.59984 2.81802 9.18198C2.40016 8.76412 2.06869 8.26804 1.84254 7.72207C1.6164 7.17611 1.5 6.59095 1.5 6C1.5 5.40905 1.6164 4.82389 1.84254 4.27792C2.06869 3.73196 2.40016 3.23588 2.81802 2.81802C3.23588 2.40016 3.73196 2.06869 4.27792 1.84254C4.82389 1.6164 5.40905 1.5 6 1.5C7.19347 1.5 8.33807 1.97411 9.18198 2.81802C10.0259 3.66193 10.5 4.80653 10.5 6C10.5 7.19347 10.0259 8.33807 9.18198 9.18198C8.33807 10.0259 7.19347 10.5 6 10.5Z"
                  fill="#888888"
                />
              </g>
              <defs>
                <clipPath id="clip0_2402_33718">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Failed
          </div>
        </Col>
        <Col>
          <div className="counts">
            {/* {totals.totalFailedCount} */}
          {dashboardData?.totalUnsubscribedCount}
            </div>
          <div className="d-flex align-items-center">
          <MdOutlineUnsubscribe color="#888888"  className="me-1"/>
            Unsubscribed
          </div>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={6}></Col>
        <Col md={1} className="d-flex justify-content-end">
          <SyncButton
            onClick={() => {
              fetchData(0, 20);
              setTimeout(() => {
                toast("success", "Template Analytics Synced Successfully");
              }, 1000);
            }}
          />
        </Col>
        <Col className="p-0">
          {" "}
          <div className="date-filter-selector">
            <Input
              className={`dateFilter-dateFilter`}
              type="select"
              size={1}
              value={dateFilter}
              style={{
                fontFamily: '"Public Sans", sans-serif',
                fontSize: "14px",
                padding: "8px 25px",
              }}
              onChange={changeDateFilterHandler}
            >
              <option value={DateFilterType.Past7Days}>Past 7 days</option>
              <option value={DateFilterType.Past30Days}>Past 30 days</option>
              <option value={DateFilterType.Past90Days}>Past 90 days</option>
              <option value={DateFilterType.ThisWeek}>This week</option>
              <option value={DateFilterType.PreviousWeek}>Previous week</option>
              <option value={DateFilterType.ThisMonth}>This month</option>
              <option value={DateFilterType.PreviousMonth}>
                Previous month
              </option>
              <option value={DateFilterType.CustomRange}>Custom range</option>
            </Input>
          </div>
        </Col>
        <Col>
          <div className="date-range-picker">
            <Overlay
              target={datepickertarget.current}
              show={showDatePicker}
              placement="bottom"
              rootClose={true}
              onHide={() => setShowDatePicker(false)}
            >
              <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                <DateRangePicker
                  value={dateRangeValue}
                  minDate={currentDate}
                  onSelect={(value: any) => {
                    setDateRangeValue(
                      moment.range(
                        moment(value.start).startOf("day"),
                        moment(value.end).endOf("day")
                      )
                    );
                    // setCurrentData((item: any) => ({ ...item, startTime: adjustedStart.valueOf(),endTime: adjustedEnd.valueOf() }));
                    setDateFilter(DateFilterType.CustomRange);
                    setShowDatePicker(false);
                    // campaignFilterQuery.refetch();
                  }}
                  maximumDate={today.endOf("day")}
                  singleDateRange={true}
                />
              </Popover>
            </Overlay>
            <div
              className={`form-control calenderInput-alignDatepicker`}
              ref={datepickertarget}
              onClick={() => {
                setShowDatePicker((current) => !current);
              }}
            >
              <BsCalendar />
              <span className="value ml-2">
                {dateRangeValue.start.format("DD/MM/YYYY")}
                {" - "}
                {dateRangeValue.end.format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <BizTableReports
        fetchData={fetchData}
        data={tableData}
        columns={columns}
        tableName="TEMPLATE_ANALYTICS"
      />
    </div>
  );
};
export default TemplateAnalytics;
