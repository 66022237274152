import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../utils/axios';
interface Props {
    component: string;
    getQuickReply: (quickres: any) => void;
}

const QuickReplies: React.FC<Props> = ({ component, getQuickReply }) => {
    const channelId = useSelector((state: any) => state.cartreducer.channelUid.value);
    const [quickRes, setQuickRes] = useState<any>([]);

    useEffect(() => {
        if (channelId) {
            const payload = {
                // params: {
                  // channelId: "876428be-fe17-3cee-b904-a78d4d514ce1",
                  channelId:channelId,
                  page: 0,
                  limit: 100,
                // }
              };
            axios.post(`canned/getAllCannedResponse`,payload)
            .then(res => {
              setQuickRes(res.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error.message);
            });
          
        }
    }, [channelId])
    return (
        <div className='quickRepScroll'>
            {quickRes?.cannedReponseList?.map((rep: any, ind: number) => {
                return (
                    <div key={ind}>
                        <div className='quickRep' onClick={component === 'conversation' ?
                            () => getQuickReply(rep) : () => { }}>
                            <div className='fontWeight'>{rep.name}</div>
                            <div>{rep.content}</div>
                        </div>
                        {ind !== quickRes.length - 1 &&
                            <hr />}
                    </div>
                )
            })}
        </div>
    );
};

export default QuickReplies;
