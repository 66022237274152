import { toast } from "../../../../../common/alert";
import axios from "../../../../../utils/axios";

export const getAllMembers = async (payload: any) => {
    try {
      const res = await axios.post('member/getMembers', payload);
      return res.data;
    }
    catch (error: any) {
      toast('error', error)
    } 
  }