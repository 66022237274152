import axios from "../utils/axios";
import { showAlert, toast, sweetalert } from "../common/alert";

export const createCompany = async (companyPayload:object) => {
  try {
    const response = await axios.post(
      `contact/company/create`,companyPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getCompanyNameByUid = async (uid: string) => {
  try {
    const response = await axios.get(`contact/getcompanyname?Uid=${uid}`);
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
}

export const getAll = async (listPayload:object) => {
  try {
    const response = await axios.post(
      `contact/company/getAll`,listPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
}

export const editCompany = async (uid:string) => {
    try {
      const response = await axios.get(
        `contact/company/${uid}`
      );
      return response.data;
    } catch (error: any) {
      toast("error", error.message);
    }
  }

  export const updateCompany = async (uid:string,payload:object) => {
    try {
      const response = await axios.patch(
        `contact/company/${uid}`,payload
      );
      return response.data;
    } catch (error: any) {
      toast("error", error.message);
    }
  }

  export const deleteCompany = async (uid:string) => {
    try {
      const response = await axios.delete(
        `contact/company/${uid}`
      );
      return response.data;
    } catch (error: any) {
      toast("error", error.message);
    }
  }
  
