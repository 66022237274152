import React, { FormEvent, useEffect, useState } from 'react'
import { Button, Container } from 'reactstrap';
import { faArrowLeft, faArrowRight, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import styles from './ImportContact.module.scss';
import excelIcon from '../../assets/img/excel.png';
import { toast } from '../../common/alert';
import { createCompany } from '../../services/companyService';

enum ImportContactPages {
    Download_Sample,
    Upload_Excel
}

function ImportCompanies() {
    const [page, setPage] = useState<ImportContactPages>(ImportContactPages.Download_Sample);
    const navigate = useNavigate();
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone();
    const businessUid = useSelector((state: any) => state.cartreducer.business?.business?.uid);
    const memberUid = useSelector((state: any) => state.cartreducer.business?.uid);
    const activeChannel = useSelector((state: any) => state.cartreducer.channelUid);
    const dataToExport = [["Company",'Website', "Description"]];

    // Upload contact
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<null | File>(null);
    const [marketOptin, setMarketOptin] = useState(true);
    const [generalAlert, setGeneralAlert] = useState<string>(''); 

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    const exportToExcel = (data: any, filename: any) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "upload");
        XLSX.writeFile(workbook, filename + ".xlsx");
      };

      const exportExcel = () => {
        exportToExcel(dataToExport, "company_data");
      };


    const onGoBackHandler = () => {
        if (page === ImportContactPages.Upload_Excel) {
            setPage(ImportContactPages.Download_Sample);
        }
         else {
            navigate("/companies");
        }
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validateExcelDataHandler = (data: any[]): boolean => {
        const contactSchema = yup.object().shape({
            name: yup.string().trim().required(),
            // email: yup.string().trim().email().notRequired(),
            email: yup.string().trim()
            .matches(emailRegex, "Email format is not valid") // Further validates email against the custom regex
            .nullable() // Allows the value to be null
            .notRequired(),            
            mobileNumber: yup.string().trim()
                .required()
                .matches(/^\d+$/, "Mobile number must be numeric")
                .min(7, 'Mobile number must be at least 7 digits')
                .max(15, 'Mobile number must be no more than 15 digits'),
            countryCode: yup.string().trim().required(),
        });

        for (let i = 0; i < data.length; i++) {
            try {
                contactSchema.validateSync(data[i]);
            } catch (error) {
                return false;
            }
        }
        return true;
    }
    const validateContact = (company: any) => {
        const errors: string[] = [];
        const { Company, Website, Description } = company;
        
        if (!Company) {
            errors.push("Company is required");
        }
        // if (!Website) {
        //     errors.push("Website is required");
        // } 
        // else if (!/^\d+$/.test(Website)) {
        //     errors.push("Website number must be numeric");
        // } 
        
        return errors;
    };

    const onUploadHandler = (event: FormEvent) => {
        setLoading(true);
        event.preventDefault();
    
        if (file) {
            if (!file || !file.name.match(/\.(xls|xlsx)$/i)) {
                toast("error", "Invalid file format");
                setLoading(false);
              } else {
                const reader = new FileReader();
                reader.onload = async (e: any) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });

                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];

                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let errors = "";

                    const company = json.map((row: any, index: number) => {
                        
                        const Company = row['Company'];
                        const Website = String(row['Website'] || '').trim();
                        const Description = String(row['Description'] || '').trim();
                        
                        const companyErrors = validateContact({ Company,Website,Description });
                        console.log('file',companyErrors,Company,Website,Description)
                        if (companyErrors.length > 0) {
                            errors += `Error in row ${index + 2}: ${companyErrors.join(', ')}\n`;
                            return null;
                        } else {
                            return {
                                Company,
                                Website,
                                Description,
                            };
                        }
                    });
                    if (errors !== "") {
                        setGeneralAlert(errors);
                        setLoading(false);
                        return;
                    }
                    
                    const createPayload ={
                        businessUid:businessUid,
                         company,
                    }

                    if (validateContact(company)) {
                        const response = await createCompany(createPayload)
                        if(response){
                            toast('success','Successfully created company');
                        }
                    }
                    else {
                        toast("error", "Invalid fields in file");
                        setLoading(false);
                    }
                };

                reader.readAsBinaryString(file);
            }
        } else {
            toast("error", "No File selected");
            setLoading(false);
        }
    }

    let pageContent = (
        <Container className={[styles.container, styles.downloadSample].join(' ')}>
            <h3>Download file sample</h3>
            {/* <p>Need to see this in action first?<br /> Download the small sample file and make changes <br /> accordingly to prepare your files for imports</p> */}
            <p>Download the sample file and make changes accordingly to import your company.</p>
                <a href="#" onClick={exportExcel}>
                <FontAwesomeIcon className={styles.icon} icon={faFileExcel} />
                <Button color='link'>
                    <FontAwesomeIcon icon={faDownload} className='mr-2' />
                    Download sample file
                </Button>
                </a>
            <div className='mt-4'>
                <Button
                    onClick={() => setPage(ImportContactPages.Upload_Excel)}
                    // color='primary'
                    className='signUpBtn'
                >
                    <FontAwesomeIcon icon={faArrowRight} className='mr-2' />
                    Next
                </Button>
            </div>
        </Container>
    );
    if (page === ImportContactPages.Upload_Excel) {
        pageContent = (
            <Container className={[styles.container, styles.uploadExcel].join(' ')}>
                <form onSubmit={onUploadHandler}>
                    {!file ? (
                        <div {...getRootProps({ className: styles.dropzone })}>
                            <input {...getInputProps()} accept=".xlsx" />
                            <div>
                                {!isDragActive ? <>Should be an Excel file with format <code>.xlsx</code></> : "Drop the file here ..."}
                                <FontAwesomeIcon className={styles.icon} icon={faUpload} />
                                <Button type='button' size='sm' className='sendButton'>Choose a file</Button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.contactFile}>
                            <div>
                                <img className={styles.icon} alt="" src={excelIcon} />
                                <span className={styles.filename}>{file.name || 'company.xlsx'}</span>
                            </div>
                            <Button
                                color='link'
                                onClick={() =>{ setFile(null);setGeneralAlert('')}}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </div>
                    )}
                   
                    <div className='mt-4'>
                        <Button
                            disabled={loading}
                            type='button'
                            onClick={() => { setPage(ImportContactPages.Download_Sample) }}
                            color='primary'
                            className='cancelButton w-25 me-2'
                        >
                            Back
                        </Button>
                        <Button
                            disabled={loading}
                            // color='primary'
                            className='w-25 sendButton'
                        >
                            {loading ? "Loading..." : "Upload"}
                        </Button>
                    </div>
                </form>
            </Container>
        );
    }

    return (
        <Container className="contact-container">
            <div className="global-table-title">
                <h5>
                    <Button
                        color='link'
                        className={styles.backBtn}
                        onClick={onGoBackHandler}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <span>Import Company</span>
                </h5>
            </div>
            {pageContent}

        </Container>
    )
}

export default ImportCompanies