import { toast } from "../common/alert";
import axios from "../utils/axios";

export const getAllSites = async (channelUid: string) => {
    try {
        const response = await axios.post(`geoFence/${channelUid}/getAll`, { page: 0, limit: 1000 });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const createUpdateSite = async (channelUid: string, payload: any, type: string, geofenceUid: string | null) => {
    try {
        var res;
        if (type === 'update')
            res = await axios.patch(`geoFence/${geofenceUid}/update`, payload)
        else
            res = await axios.post(`geoFence/${channelUid}/create`, payload)
        const data = res.data;
        return data;
    }
    catch (err: any) {
        toast('error', err.response.data.message);
    }
}

export const deleteSelectedSite = async (geofenceUid: string | null) => {
    try {
        const res = await axios.delete(`geoFence/${geofenceUid}/delete`);
        const data = res.data;
        return data;
    }
    catch (err: any) {
        toast('error', err.response.data.message);
    }
}

export const getAllReportData = async (channelUid: string, payload: any) => {
    try {
        const res = await axios.post(`geoFence/${channelUid}/geofenceReport`, payload);
        const data = res.data;
        return data;
    }
    catch (err: any) {
        return err;
    }
}