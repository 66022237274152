import React, {useEffect, useState} from "react";
import { Container, Row, Col, Label } from "reactstrap";
import { FaShop } from "react-icons/fa6";
import { BiPointer } from "react-icons/bi";
import { BiReceipt } from "react-icons/bi";
import { LuShoppingCart } from "react-icons/lu";
import SwitchButton from "../Contacts/SwitchButton";
import { CiFacebook } from "react-icons/ci";
import { Button } from "react-bootstrap";
import { updateChannelSetting,getConfig } from "../../services/WhatsAppchannelService";
import { useSelector } from "react-redux";

type Props = {
  isInvalidAccess: boolean;
};

const ChannelSettings = (Props: Props) => {
    const { isInvalidAccess } = Props;
    const [isLoading, setLoading] = useState(false);
    const channelData = useSelector((state: any) => state.cartreducer.channelUid)
    const [isMenus, setMenus] = useState({
        unreadActive:false,
        catalogueShopIconActive:false,
        catalogueCartIconActive:false
    });
    
    const whatsappOptState = async(settings:string,check: boolean) => {
        setMenus({
            ...isMenus,
          [settings]: check,
        })
        const payload = ({...isMenus, [settings]: check})
        const channelId = channelData?.value;
       const response =  await  updateChannelSetting(channelId,payload);
      };
      
    const getConfiguration = async() =>{
        setLoading(true);
        const channelId = channelData?.value
        const response = await getConfig(channelId);
        if(response){
            setMenus({
                unreadActive:response?.unreadActive,
                catalogueShopIconActive:response?.catalogueShopIconActive,
                catalogueCartIconActive:response?.catalogueCartIconActive
            })
            setLoading(false)
        }
    }
    
    useEffect(() => {
        getConfiguration();
      }, [channelData])

  return (
    
    <div>
    {isLoading ? 
    <div className="centerItemsWithWidth">
    <div className="spinner-border text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>:
  <Container>
  <Row className="p-4 d-flex align-items-center">
    <Col md={1}>
      <BiReceipt size={50} color="#666E7D" />
    </Col>
    <Col md={8}>
      <Row >
        <Col>
          <h5 className="headerone">
            <span> </span>Read receipts
          </h5>
          <div className="borderLine"></div>
        </Col>
      </Row>
      <Row>
        <Col className="p-2">
          If turned off, your customers won't know if you've read their
          message (no blue ticks).
        </Col>
      </Row>
    </Col>
    <Col className={`${isInvalidAccess ? "disabledState" : "" }`}>
    <SwitchButton
           whatsappOptState={(checked:boolean) => whatsappOptState('unreadActive', checked)} // Pass an identifier and the checked 
           checked={isMenus.unreadActive}
          />
    </Col>
  </Row>
  <Row className="p-4 d-flex align-items-center">
    <Col md={1}>
      <FaShop size="50" color="#666E7D" />
    </Col>
    <Col md={8}>
      <Row className="">
        <Col>
          <h5 className="headerone">
            {" "}
            <span></span>Enable catalogue shop icon
          </h5>
          <div className="borderLine"></div>
        </Col>
      </Row>
      <Row>
        <Col className="p-2">
          When enabled, the catalogue storefront icon will appear for your
          account in WhatsApp.
        </Col>
      </Row>
    </Col>
    <Col className={`${isInvalidAccess ? "disabledState" : "" }`}>
    <SwitchButton
            whatsappOptState={(checked:boolean) => whatsappOptState('catalogueShopIconActive', checked)}
            checked={isMenus.catalogueShopIconActive}
          />
    </Col>
  </Row>
  <Row className="p-4 d-flex align-items-center">
    <Col md={1}>
      <LuShoppingCart size={50} color="#666E7D" />
    </Col>
    <Col md={8}>
      <Row className="">
        <Col>
          <h5 className="headerone">
            <span></span>
            Enable catalogue cart icon
          </h5>
          <div className="borderLine"></div>
        </Col>
      </Row>
      <Row>
        <Col className="p-2" >
          When enabled, cart icon will appear for your account in
          WhatsApp.
        </Col>
      </Row>
    </Col>
    <Col className={`${isInvalidAccess ? "disabledState" : "" }`}>
    <SwitchButton
            whatsappOptState={(checked:boolean) => whatsappOptState('catalogueCartIconActive', checked)}
            checked={isMenus.catalogueCartIconActive}
          />
    </Col>
  </Row>
 {/* <Row className="p-4 d-flex align-items-center">
    <Col md={1} >
      <BiPointer size={50} color="#666E7D" />
    </Col>
    <Col md={8}>
      <Row className="">
        <Col>
          <h5 className="headerone">
            {" "}
            <span></span> Enable CTWA events tracking
          </h5>
          <div className="borderLine"></div>
        </Col>
      </Row>
      <Row>
        <Col className="p-2" >
          When enabled, CTWA events will be sent to your facebook page
          dataset.
        </Col>
      </Row>
    </Col>
    <Col>
    <Button className="upload-select" style={{background:'#0083ff !important'}} >
      <CiFacebook size={25} /> connect
    </Button>
    </Col>
</Row> */}
</Container>
}
    </div>
  );
};

export default ChannelSettings;
