import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import { Alert, Button, Col, Form, FormControl, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IEditorProps } from './types'
import * as flowServices from '../../../services/flows';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import { jumpToIcon } from '../../../icons';
import ReactSelect from 'react-select';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '../../../hooks';
import { SOMETHING_WENT_WRONG } from '../../../../constants/errorMessage';
import { SelectMember } from './assignConversation';
import { uuidv4 } from '../../../utils/uuid';
import BodyInput from './bodyInput';
import axios from 'axios';
import { Label } from 'reactstrap';
import JSONEditorComponent from './jsonEditor';

type MemberUid = {
    id: string,
    uid: string
}
type Session = {

    id: string,
    title: string

}

interface FormData {
    bodyText: string,
    flowData: {
        data: object | null,
        type: null | string,
        flowId: string,
        screenName: string | null,
        buttonName: string,
        memberUids: MemberUid[] // for type === 'caly_appoinment'
        sessions: Session[]  //for type==='caly_appoinment'
    },
};

type SelectMembersProps = {
    existingSelectedMemberIds: string[];

}
function SelectMembers({ existingSelectedMemberIds }: SelectMembersProps) {
    const formik = useFormikContext<FormData>();
    const businessUid = useAppSelector((state) => state.meta.businessUid);

    function handleSetValue(value: string) {
        const memberUids = formik.values.flowData.memberUids || [];
        if (!memberUids.some((uid: any) => uid.uid === value)) {
            formik.setFieldValue('flowData.memberUids', [...memberUids, { id: uuidv4(), uid: value }]);
        }
    }

    function handleRemoveValue(value: string) {
        const memberUids = formik.values.flowData.memberUids || [];
        const updatedMemberUids = memberUids.filter((uid: any) => uid.uid !== value);
        formik.setFieldValue('flowData.memberUids', updatedMemberUids);
    }
    useEffect(() => {
        if (formik.values.flowData.memberUids) {
            formik.values.flowData.memberUids.forEach((uid: any) => {
                handleSetValue(uid.uid);
            });
        }
    }, []);

    const query = useQuery({
        queryKey: ['members'],
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await axios.post('/member/getMembers', {
                    businessUid,
                    page: 0,
                    limit: 10,
                    status: ["ACTIVE"]
                });
                return response.data;

            } catch (error) {
                console.error(error);
                return [];
            }
        }
    });

    return (
        <div>
            <div style={{ display: "flex" }}>

                {formik.values.flowData.memberUids.map((uidObj: any, index: number) => {
                    if (query.data?.dataDTO) {
                        const selectedMember = query.data?.dataDTO?.find((member: any) => member.uid === uidObj.uid);
                        const displayText = selectedMember ? selectedMember.name : uidObj.uid;

                        return (
                            <div
                                key={index}
                                style={{
                                    backgroundColor: '#efefef',
                                    borderRadius: '12px',
                                    padding: '3px 8px',
                                    margin: '0 5px 5px 0',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {displayText} {/* Use the determined display text here */}
                                </span>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => handleRemoveValue(uidObj.uid)}
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                />
                            </div>
                        );
                    }
                })}

            </div>
            <Row>
                <Col xs={10}>
                    <SelectMember
                        name={existingSelectedMemberIds[0]}
                        isValid={false}
                        isInvalid={false}
                        setUid={(memberUid: string) => handleSetValue(memberUid)}
                        memberUids={formik.values.flowData.memberUids}
                    />
                </Col>
            </Row>
            {formik.touched.flowData
                && formik.touched.flowData.memberUids
                && formik.errors.flowData
                && formik.errors.flowData.memberUids
                && typeof formik.errors.flowData.memberUids === 'string' ? (
                <div className='invalid-feedback d-block'>
                    {formik.errors.flowData.memberUids}
                </div>
            ) : null}
        </div>
    )
}

function SendFlow(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        bodyText: '',
        flowData: {
            type: null,
            flowId: '',
            buttonName: 'Submit',
            memberUids: [],
            sessions: [],
            screenName: null,
            data: null
        },
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_Flow',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);
    const [jsonData, setData] = useState(null);
    const [error, setError] = useState(false);
    const channelUid = useAppSelector(state => state.meta.channelUid);
    const flowsQuery = useQuery({
        queryKey: ['channel-flows'],
        queryFn: () => flowServices.getFlowsOfChannel(channelUid || ''),
        enabled: false
    })
    useEffect(() => {
        if (channelUid && !flowsQuery.isFetchedAfterMount) {
            flowsQuery.refetch();
        }
    }, [channelUid])

    const schema = yup.object().shape({
        bodyText: yup.string().required('Body is required') .test('is-double-space', 'Body is required', value => value !== '  '),
        flowData: yup.object().shape({
            type: yup.string().nullable(),
            flowId: yup.string().required('Flow is required'),
            buttonName: yup.string().when('type', {
                is: (type: string) => type === 'caly_appointment',
                then: yup.string().required('Button Text is required for caly_appointment'),
                otherwise: yup.string().notRequired(),
            }),
            memberUids: yup.array().of(
                yup.object().shape({
                    id: yup.string().required('ID is required'),
                    uid: yup.string().required('Member UID is required'),
                })
            )
                .when('type', {
                    is: (type: string) => type === 'caly_appoinment', // Apply validation only when type is 'caly_appointment'
                    then: yup.array().required('Member UIDs are required for caly_appointment').min(1, 'At least one member is required'),
                    otherwise: yup.array().notRequired(), // When type is null or any other value, do not require memberUids
                }),
            data: yup.object().nullable()

        }),
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {

                const handleSave = () => {
                    setFieldValue('flowData.data', jsonData);
                }
                
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                useEffect(() => {
                    // Update values.flowData.type whenever it changes
                    if (values.flowData.type === 'caly_appoinment') {
                        setFieldValue('flowData.sessions', [{
                            id: '1',
                            title: 'Please select the Date first'
                        }]);
                    } else {
                        setFieldValue('flowData.sessions', []);

                    }
                }, [values.flowData.type, setFieldValue]);

                useEffect(() => {

                    if ((flowsQuery?.data?.find(f => f.id === values?.flowData?.flowId)?.firstScreen)!==null) {
                        setFieldValue('flowData.screenName', flowsQuery?.data?.find(f => f.id === values?.flowData?.flowId)?.firstScreen || '');
                    }


                }, [values.flowData.flowId, flowsQuery?.data?.find(f => f.id === values?.flowData?.flowId)?.firstScreen])

                let formContent = <Spinner />
                const flowOptions: any = []

                if (!flowsQuery.isLoading && !flowsQuery.isError) {
                    flowsQuery.data?.forEach(flow => {
                        flowOptions.push({ label: flow.name, value: flow.id });
                    });

                    formContent = (
                        <>
                            <Form.Group className='mb-3'>
                                <Form.Label> Flow <span className='required'></span></Form.Label>
                                <ReactSelect
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                        menu: base => ({ ...base, width: 370 })
                                    }}
                                    value={values.flowData.flowId && values.flowData.flowId.trim() !== '' ? (() => {
                                        const myFlow = flowsQuery.data.find(f => f.id === values.flowData.flowId);
                                        if (myFlow) {
                                            return {
                                                label: myFlow ? myFlow.name : values.flowData.flowId.trim(),
                                                value: values.flowData.flowId.trim()
                                            }
                                        }
                                        return ''
                                    })() : ''}
                                    options={flowOptions}
                                    onChange={(selected: any) => {
                                        if (selected) {
                                            const myFlow = flowsQuery.data.find(f => f.id === selected.value);
                                            setFieldValue('flowData.flowId', selected.value);
                                            // Update the type value here
                                            setFieldValue('flowData.type', myFlow?.templateName || null);
                                        }
                                    }}
                                />

                                {touched.flowData
                                    && touched.flowData.flowId
                                    && errors.flowData
                                    && errors.flowData.flowId
                                    && typeof errors.flowData.flowId === 'string' ? (
                                    <div className='invalid-feedback d-block'>
                                        {errors.flowData.flowId}
                                    </div>
                                ) : null}
                            </Form.Group>


                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='bodyText'
                                    value={values.bodyText}
                                    isInvalid={(touched.bodyText && errors.bodyText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.bodyText && !errors.bodyText}
                                    error={errors.bodyText}
                                    label='Body'
                                    required={true}
                                    limit={1024}
                                />
                                {(touched.bodyText && errors.bodyText) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.bodyText}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Screen Name </Form.Label>
                                <Form.Control
                                    placeholder='Enter the screen name'
                                    value={flowsQuery.data.find(f => f.id === values.flowData.flowId)?.firstScreen || ''}
                                    onChange={handleChange}
                                    readOnly 
                                    style={{pointerEvents: 'none'}}
                                />
                                {touched.flowData
                                    && touched.flowData.screenName
                                    && errors.flowData
                                    && errors.flowData.screenName
                                    && typeof errors.flowData.screenName === 'string' ? (
                                    <div className='invalid-feedback d-block'>
                                        {errors.flowData.screenName}
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Label>Data </Label>
                                <JSONEditorComponent setData={setData} initialValue={values.flowData?.data ?? null} setError={setError} />
                                { error && <div className='invalid-feedback d-block'> Invalid JSON Data </div>}
                            </Form.Group>

                            {values.flowData.flowId && values.flowData.flowId.trim() !== '' ? (
                                <>
                                    <Form.Group className='mb-3'  style={{ position: 'relative' }}>
                                        <Form.Label>Button Text <span className='required'></span></Form.Label>
                                        <FormControl
                                            name='flowData.buttonName'
                                            value={values.flowData.buttonName}
                                            onChange={handleChange}
                                            maxLength={20}
                                        />
                                           <div style={{
                                        position: 'absolute',
                                        backgroundAttachment:"fixed",
                                        right: 0,
                                        bottom: -20,
                                        fontSize: '.8rem',
                                        fontWeight: 400,
                                        color: '#A3A3A3',
                                        padding: '1px 3px'
                                    }}>
                                        {values.flowData.buttonName.length}/20
                                    </div>
                                        {touched.flowData
                                            && touched.flowData.buttonName
                                            && errors.flowData
                                            && errors.flowData.buttonName
                                            && typeof errors.flowData.buttonName === 'string' ? (
                                            <div className='invalid-feedback d-block'>
                                                {errors.flowData.buttonName}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                    {values.flowData.type === 'caly_appoinment' && (
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Members <span className='required'></span></Form.Label>
                                            <SelectMembers existingSelectedMemberIds={formData.flowData.memberUids.map(member => member.id)} />
                                        </Form.Group>
                                    )}
                                </>
                            ) : null}

                        </>
                    )
                } else if (flowsQuery.isError) {
                    formContent = (
                        <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption=' Send Flow' icon={<img style={{ width: 20 }} alt='' src={jumpToIcon} />} />
                        <Offcanvas.Body>
                            {formContent}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' onClick={handleSave} disabled={error}>
                                Save
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SendFlow