import Node from '../node';
import classes from './sendList.module.css';
import ListItem from './listItem';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


function SendList(props: any) {
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}
            style={{ ...props.style }}
        >{props.data.text}
        </Tooltip>
    );
    return (
        <Node {...props} label='Ask List' showRight={false} content={(
            <div>
                <span className={classes.header}>
                    {props.data.headerText ? (
                        <div style={{ cursor: 'pointer' }}>
                            {props.data.headerText.substring(0, 20)}
                            {props.data.headerText.length > 20 ? '...' : null}
                        </div>
                    ) : null}                  </span>
                {props.data.bodyText ? (
                    <div style={{ cursor: 'pointer' }}>
                        {props.data.bodyText.substring(0, 20)}
                        {props.data.bodyText.length > 20 ? '...' : null}
                    </div>
                ) : null}
                {props.data.dynamic ? (
                    <i className='d-block text-muted'>(Dynamic Options)</i>
                ) : props.data.options.map((option: any, i: number) => {
                    return (
                        <ListItem
                            key={option.id}
                            id={`${option.id}:${i + 1}`}
                            position={i + 1}
                            listText={option.optionTitle}
                            list={option.list}
                            readOnly={props.readOnly}
                        />
                    );
                })}
                {props.data.footerText ? (
                    <span className={classes.footer}>{props.data.footerText ? (
                        <div style={{ cursor: 'pointer' }}>
                            {props.data.footerText.substring(0, 20)}
                            {props.data.footerText.length > 20 ? '...' : null}
                        </div>
                    ) : null}   </span>
                ) : null}
            </div>
        )} />
    );
}

export default SendList;