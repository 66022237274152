import axios from '../../utils/axios';
import { toast } from '../../common/alert';

export const getMessages = (uid : string): Promise<any> => {
    const MessageList = {
        params: {
          channelId: uid,
        }
      };
    return new Promise((resolve, reject) => {
        axios.get('canned/getAllCannedResponse', MessageList).then(res => {
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response);
        })
    });
};

export const getAssignment = (uid : string, type: string): Promise<any> => {
    const url = type === 'salesInbox' ? `channel/${uid}/assignment`: `channel/${uid}/assignment?type=Account`;
    
    return new Promise((resolve, reject) => {
        axios.get(url).then(res => {
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response);
        })
    });
};

export const setAssignment = (uid:string, payload: any, type: string) =>{
    const url = type === 'salesInbox' ? `channel/${uid}/assignment` : `channel/${uid}/assignment?type=Account`;

    return new Promise((resolve, reject) => {
    axios.patch(url, payload).then(() => {
      toast('success', 'Assigned Successfully');
      resolve({ success: true });
    }).catch(err => {
        toast('error', err.response.data.message);
    })
})
}

export const getConfiguration = (uid : string, type: string): Promise<any> => {
    const url = type === 'salesInbox' ? `channel/${uid}/configuration` : `channel/${uid}/configuration?type=Account`
    return new Promise((resolve, reject) => {
        axios.get(url).then(res => {
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response);
        })
    });
};

export const setConfiguration = (uid:string, payload: any, type: string) =>{
    const url = type === 'salesInbox' ? `channel/${uid}/configuration` : `channel/${uid}/configuration?type=Account`;
    return new Promise((resolve, reject) => {
    axios.patch(url, payload).then(() => {
        toast('success', 'Configured Successfully');
        resolve({ success: true });
        }).catch((err: any) => {
            toast('error', err.response.data.message);
        })
    })
}