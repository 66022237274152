import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import "../../main.scss";
import {
  getConnection,
  updateConnection,
  deleteCalendlylow,
  updateConnectionDisableStatus
} from "../../../../services/integrationService";
import { useSelector } from "react-redux";
import { CalendlyPayment } from "../../assets";
import WorkFlowHeader from "../../Component/WorkFlowHeader";
import WorkFlowForm from "../../Component/WorkFlowForm";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  PopoverBody,
  Input
} from "reactstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";
import { useData } from "../../../../contexts/tempContext";
import { IoIosNotificationsOutline } from "react-icons/io";
import { MdEventAvailable } from "react-icons/md";
import { TbClockHour12 } from "react-icons/tb";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { BsTrash } from "react-icons/bs";
import LogsTable from "../../Component/LogsTable";

interface CalendlyProps {
  integrationUid?: string; // Make it optional if it can be undefined
  activeTab: string;
  connectionUid: string;
  callDb: boolean;
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkFlowEnable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isInvalidAccess: boolean;
}

interface FormValues {
  brandName: string;
  channelName: { value: string; label: string } | null;
  integrationVariables: any[];
}

const validationSchema = Yup.object({
  brandName: Yup.string().required("Brand Name is required"),
  channelName: Yup.object().nullable().required("Channel Name is required"),
});

const Calendly: React.FC<CalendlyProps> = (props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    brandName: "",
    channelName: null,
    integrationVariables: [],
  });
  const { data, setData, clearData } = useData();


  const [webhookSecretCopied, setWebhookSecretCopied] = useState(false);
  const [webhookUrlCopied, setWebhookUrlCopied] = useState(false);
  const [activeWorkflowId, setActiveWorkflowId] = useState<number | null>(null);
  const [activeWorkflowTypeId, setActiveWorkflowTypeId] = useState<number | null>(null);
  const [activeWorkflowType, setActiveWorkflowType] = useState<string | null>(
    null
  );

  const [isWorkFlow, setIsWorkFlow] = useState([]);

  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
  useEffect(() => {
    if (props.integrationUid) {
      getConnection(props.integrationUid)
        .then((data: any) => {
          setInitialValues({
            brandName: data.integrationConfigurationDTO.integrationName || "",
            channelName: data.integrationConfigurationDTO.channelDto
              ? {
                  value: data.integrationConfigurationDTO.channelDto.uid,
                  label: data.integrationConfigurationDTO.channelDto.name,
                }
              : null,
            integrationVariables: data?.integrationVariables,
          });
          props.setWorkFlowEnable(
            Object.keys(data.integrationConfigurationDTO?.channelDto)?.length >
              0 || false
          );

          setIsWorkFlow(data?.workFlows);
        })
        .catch((error) => {
          console.error("Error fetching connection data:", error);
        });
    }
  }, [props.integrationUid, props.callDb]);

  useEffect(() => {
    if (props.callDb) {
      setTimeout(() => {
        props.setCallDb(false);
      }, 1000); // set callDb to false after 2 seconds
    }
  }, [props.callDb]);

  const handleCopy = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    props.setActiveTab("Workflow");
    const data = {
      integrationConfigurationDTO: {
        channelDto: {
          uid: values.channelName?.value,
        },
        integrationName: values.brandName,
      },
    };

    try {
      const response = await updateConnection(props.connectionUid, data);
      props.setCallDb(true);
      props.setActiveTab("Workflow");
      // formikHelpers.resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const channelOptions = channelData.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));

  function toCamelCase(str: string | undefined): string {
    return (
      str?.split(" ").reduce((acc, word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return (
            acc + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          );
        }
      }, "") || ""
    );
  }

  const findWorkFlowById = (id: number) => {
    const list = CalendlyPayment.find((types: any) => types.id === id);
    const workFlow = isWorkFlow.find(
      (wf: any) => wf?.metaData?.eventDescription === list?.type
    );
    // console.log('ID',isWorkFlow,workFlowType,id)
    return workFlow || null;
  };

  const findWorkFlowByType = (id: any) => {

    // Find the list item
    const workFlow = isWorkFlow.find((types: any) => {
      return String(types.id) === String(id); // Normalize types for comparison
    });

    return workFlow || null;
  };

  const editEvent = (item: any) => {
    const type: Record<number, string> = {
      1: "Event Confirmation",
      2: "Event Reminder",
      3: "Event FollowUp",
    };
    
    const key = Object.keys(type).find(
      (key) => type[Number(key)] === activeWorkflowType // Cast the key to a number
    );
    
    if (key) {
      setActiveWorkflowId(Number(key)); // Pass the key as a number
    }
    setActiveWorkflowTypeId(item.id);
  };


  const renderPopover = (item: any) => (
    <Popover id="popover-basic" style={{ minWidth: "120px" }}>
      <Popover.Body>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={async (event) => {
            event.stopPropagation(); // Prevents the click from bubbling up
            try {
              const response = await deleteCalendlylow(item.uid);
              if(response){
                props.setCallDb(true);
              }
            } catch (error) {
              console.error("Error deleting item:", error);
            }
          }}
        >
          <BsTrash size={16} color="red" style={{ marginRight: "8px" }} />{" "}
          {/* Delete icon */}
          <span>Delete</span>
        </div>
      </Popover.Body>
    </Popover>
  );
  

  return (
    <>
      {props.activeTab === "Overview" && (
        <div className="shipRocketOverview">
          <div className="headerOverview">
            BizMagnets helps you to integrate your Official WhatsApp Business
            Account with Calendly and send automated schedule-related
            notifications to your customers.
          </div>

          <div>
            <h5 className="fw-bold mt-4 mb-4">What can I do?</h5>
          </div>

          <div className="body">
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.4524'%20height='32.4524'%20rx='16.2262'%20fill='%23DDE4FF'/%3e%3cpath%20d='M12.7812%2022.1875C9.64875%2022.1875%207.09375%2019.6325%207.09375%2016.5C7.09375%2013.3675%209.64875%2010.8125%2012.7812%2010.8125C13.14%2010.8125%2013.4375%2011.11%2013.4375%2011.4688C13.4375%2011.8275%2013.14%2012.125%2012.7812%2012.125C10.3663%2012.125%208.40625%2014.085%208.40625%2016.5C8.40625%2018.915%2010.3663%2020.875%2012.7812%2020.875C15.1962%2020.875%2017.1562%2018.915%2017.1562%2016.5C17.1562%2016.1413%2017.4537%2015.8438%2017.8125%2015.8438C18.1713%2015.8438%2018.4688%2016.1413%2018.4688%2016.5C18.4688%2019.6325%2015.9137%2022.1875%2012.7812%2022.1875Z'%20fill='%23171923'/%3e%3cpath%20d='M20%2022.4062C19.6413%2022.4062%2019.3438%2022.1088%2019.3438%2021.75C19.3438%2021.3912%2019.6413%2021.0938%2020%2021.0938C22.5288%2021.0938%2024.5938%2019.0288%2024.5938%2016.5C24.5938%2013.9712%2022.5288%2011.9062%2020%2011.9062C17.4712%2011.9062%2015.4062%2013.9712%2015.4062%2016.5C15.4062%2016.8587%2015.1087%2017.1562%2014.75%2017.1562C14.3913%2017.1562%2014.0938%2016.8587%2014.0938%2016.5C14.0938%2013.245%2016.745%2010.5938%2020%2010.5938C23.255%2010.5938%2025.9062%2013.245%2025.9062%2016.5C25.9062%2019.755%2023.255%2022.4062%2020%2022.4062Z'%20fill='%23171923'/%3e%3c/svg%3e"
                  alt=""
                />
              </div>
              <div>Send Event Confirmation Messages</div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO1SURBVHgBvVhLTBNBGP5mqaIx6hqDiaXGFvSgHmhP6kVK4oMLoTHgQQ6UKAcvUrh6oMQYE0wMePEioRzkoAHbmxIS6gk40Yt4UXYJBUI0UkhQEbrjP8sjrYXuLA+/BLYz+8/M1//xzUwZJDHGNVXFobJVpAMK4AeYCnD3+usU/ekcTKe+qAIlXsKck7JzMysDsfgxHGyiyUPUVCEJDsSJVMd5Vhyzss1LQuMzTQaMsJ3Fc8kw8gwL5fPMliQ+81n3AaS7men2PUGKPBM6x1w9kCEhCByEMZQR7z0EC5ey4rac3v9HYANKqJQ5O7N6MhsiBPtLQMDo0PhsObYi8ZVPt9rJgcXUIro7XqEv8hZ2YSAdEVW30XaIfyIMQDpkZ6Iq700kJ5PrhBbQELpvZ7h7rexh5oeZE1/4NFUCD8qMHk98woPAPRS7z+CC95JJYE6fRX+0H6fUIizjD+bwAyRqVlORL1c8PuZJOdaZ+CGBpD6FuopavHzXhSv+q5v9RSQjR19HgVgMhdXVKKqrwSy+W01HIlhYT89ORePT1bLJ+CL8HA9bW7IICBxGITA4CCwtAb29a20JcBgB8VQMcD8kMRD7gBuBypz+JfwGyAMoKQEaG/ETv2SmMyMgEpRN8OQQlwxHKXNRFSVz+mnDwkkcx0pqGRP6BE55i2VywgRZ+RQi4MUuQfsLvmEeXdEInna2SxMQII0oEzqx481pL8CgqIqscV/kDS6XX81rI0p2MPoe9sBNEikZU1EZt4N38tpcJBIXyi6hk2ylKYDrjORasypR4YWR+AieRawnF1pS5buFsflxyICqs0J4Im5luEDaZnkE2yGOoEBz0KknYYjDWB7UUBgeh1rhO3HRjLvIDZfbtflebGbTehKjH4cxRZ5oIkGThO6kExfTSCwMHJiXGSEWG6DEe9IcxvXqyoz+BcwQiUcd4Rw1tUCEtKfB9LIdwRK466/FZVqsKdxiEqujdj3tojXBWtjBYShu4QmzRBkKwnYGt1OCjsaHTQW95rlCVVNrm4A4jTvXD7+b+WbXG7vFhhfE502xIm80QFIzdg8WdmZcAbIqj85+9eLohf1FgsLoy+zIkm0PO90jWGL/oFMYAv925uwda/eCfSGSULDic25xE9tWCJN8rmwZq9G9uAIYAN1JXc3bvbdUY3EVoEdwJ2Ro8ngavO08OxO3sLOGZl4JUM6RDlqX8drPA7QVxKwWt0Uim5CmrsLhpSuCWgDHWVpMpW9LqmekCpBOeJhHh038Bbn3akTlgY4tAAAAAElFTkSuQmCC"
                  alt=""
                />
              </div>
              <div>Ask Feedback for the event</div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.4524'%20height='32.4524'%20rx='16.2262'%20fill='%23FFDDDD'/%3e%3cpath%20d='M15.5553%2010.7221V23.3388C15.555%2023.7303%2015.4166%2024.1091%2015.1644%2024.4085C14.9122%2024.708%2014.5625%2024.9088%2014.1768%2024.9756C13.7911%2025.0424%2013.3942%2024.971%2013.056%2024.7738C12.7178%2024.5767%2012.46%2024.2666%2012.328%2023.898L10.3002%2018.0893M10.3002%2018.0893C9.49859%2017.7484%208.83932%2017.142%208.43399%2016.3709C8.02867%2015.5998%207.90219%2014.7123%208.07595%2013.8587C8.24971%2013.0051%208.71305%2012.2377%209.38755%2011.6864C10.0621%2011.1352%2010.9063%2010.8339%2011.7774%2010.8335H13.5077C17.38%2010.8335%2020.7093%209.668%2022.1666%208V21.2231C20.7093%2019.5551%2017.3809%2018.3896%2013.5077%2018.3896H11.7774C11.2699%2018.3904%2010.7674%2018.2876%2010.3002%2018.0893ZM22.1666%2017.4451C22.9181%2017.4451%2023.6387%2017.1466%2024.1701%2016.6152C24.7015%2016.0838%2025%2015.3631%2025%2014.6116C25%2013.8601%2024.7015%2013.1394%2024.1701%2012.608C23.6387%2012.0766%2022.9181%2011.778%2022.1666%2011.778V17.4451Z'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/svg%3e"
                  alt=""
                />
              </div>
              <div>Send a Sequence of Follow-up Messages post event</div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.3816'%20height='32.3816'%20rx='16.1908'%20fill='%23FFF8DD'/%3e%3cpath%20d='M16%2016.6253C13.3583%2016.6253%2011.2083%2014.4753%2011.2083%2011.8337C11.2083%209.19199%2013.3583%207.04199%2016%207.04199C18.6416%207.04199%2020.7916%209.19199%2020.7916%2011.8337C20.7916%2014.4753%2018.6416%2016.6253%2016%2016.6253ZM16%208.29199C14.05%208.29199%2012.4583%209.88366%2012.4583%2011.8337C12.4583%2013.7837%2014.05%2015.3753%2016%2015.3753C17.95%2015.3753%2019.5416%2013.7837%2019.5416%2011.8337C19.5416%209.88366%2017.95%208.29199%2016%208.29199Z'%20fill='%23292D32'/%3e%3cpath%20d='M8.8418%2024.9583C8.50013%2024.9583%208.2168%2024.675%208.2168%2024.3333C8.2168%2020.775%2011.7085%2017.875%2016.0001%2017.875C16.8418%2017.875%2017.6668%2017.9834%2018.4668%2018.2084C18.8001%2018.3%2018.9918%2018.6417%2018.9001%2018.975C18.8085%2019.3083%2018.4668%2019.5%2018.1335%2019.4084C17.4501%2019.2167%2016.7335%2019.125%2016.0001%2019.125C12.4001%2019.125%209.4668%2021.4583%209.4668%2024.3333C9.4668%2024.675%209.18346%2024.9583%208.8418%2024.9583Z'%20fill='%23292D32'/%3e%3ccircle%20cx='20.5'%20cy='20.5'%20r='3'%20fill='%23FF4A4A'%20stroke='white'/%3e%3c/svg%3e"
                  alt=""
                />
              </div>
              <div>Send Event Reminder Messages</div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.3816'%20height='32.3816'%20rx='16.1908'%20fill='%23FADDFF'/%3e%3cpath%20d='M16.7849%2014.1523H20.7224'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M12.285%2014.1523L12.8475%2014.7148L14.535%2013.0273'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M16.7849%2019.4023H20.7224'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M12.2849%2019.4023L12.8474%2019.9648L14.5349%2018.2773'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M14.25%2024H18.75C22.5%2024%2024%2022.5%2024%2018.75V14.25C24%2010.5%2022.5%209%2018.75%209H14.25C10.5%209%209%2010.5%209%2014.25V18.75C9%2022.5%2010.5%2024%2014.25%2024Z'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/svg%3e"
                  alt=""
                />
              </div>
              <div>Send WhatsApp Broadcast to Targeted Customers</div>
            </div>
          </div>
        </div>
      )}

      {props.activeTab === "Configuration" && (
        <div className="shipRocket-configuration">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="brandName">
                    Brand Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="brandName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="brandName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* <div className="form-group">
                  <label htmlFor="webhookSecret">WebHook Secret</label>
                  <div className="d-flex">
                    <Field
                      type="password"
                      name="webhookSecret"
                      className="form-control"
                      disabled
                    />
                    <button
                      type="button"
                      className="copyButton"
                      onClick={() =>
                        handleCopy(
                          document.querySelector<HTMLInputElement>(
                            'input[name="webhookSecret"]'
                          )?.value || "",
                          setWebhookSecretCopied
                        )
                      }
                    >
                      {" "}
                      <svg
                        className="me-2"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                        height="1.2em"
                        width="1.2em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        ></path>
                      </svg>
                      {webhookSecretCopied ? "Copied" : "Copy"}
                    </button>
                  </div>
                </div> */}
                <div className="form-group">
                  <label htmlFor="channelName">
                    Channel Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Select
                    options={channelOptions}
                    name="channelName"
                    value={values.channelName}
                    onChange={(option) => setFieldValue("channelName", option)}
                    className="channel-select"
                  />
                  <ErrorMessage
                    name="channelName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div
                  className={`d-flex justify-content-center mt-3 ${
                    props?.isInvalidAccess ? "notAllowed" : "pointer"
                  } `}
                >
                  <button
                    type="submit"
                    className="btn btn-primary d-flex justify-content-between"
                    disabled={props?.isInvalidAccess}
                  >
                    Update Configuration
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {props.activeTab === "Workflow" && (
        <div>
          {activeWorkflowId ? (
            <WorkFlowForm
              id={activeWorkflowId}
              title={
                CalendlyPayment.find((wf) => wf.id === activeWorkflowId)
                  ?.title || ""
              }
              type={
                CalendlyPayment.find((wf) => wf.id === activeWorkflowId)
                  ?.type || ""
              }
              description={
                CalendlyPayment.find((wf) => wf.id === activeWorkflowId)
                  ?.description || ""
              }
              setActiveWorkflowId={setActiveWorkflowId}
              connectionUid={props.connectionUid}
              channelDtoUid={initialValues.channelName?.value}
              integrationName={initialValues.brandName}
              integrationVariables={initialValues.integrationVariables}
              workflowData={
                activeWorkflowType
                  ? findWorkFlowByType(activeWorkflowTypeId)
                  : findWorkFlowById(activeWorkflowId)
              }
              setCallDb={props.setCallDb}
              value={
                CalendlyPayment.find((wf) => wf.id === activeWorkflowId)
                  ?.value || ""
              }
              isInvalidAccess={props.isInvalidAccess}
            />
          ) : activeWorkflowType && isWorkFlow?.some(
            (item: any) => item?.metaData?.eventDescription === activeWorkflowType
          )? (
            <>
              {
                <div className="calendlyCompoent">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                    <button
                      className="back-btn"
                      onClick={() => {
                        setActiveWorkflowId(null);
                        // clearData();
                        setActiveWorkflowType(null);
                      }}
                    >
                      <IoArrowBackOutline />
                    </button>
                    <h5 className="ms-2 mb-0">
                    {activeWorkflowType}
                    </h5>
                    </div>
                    <button className="sendButton" onClick={()=>{
                    const type: Record<number, string> = {
                      1: "Event Confirmation",
                      2: "Event Reminder",
                      3: "Event FollowUp",
                    };
                    
                    const key = Object.keys(type).find(
                      (key) => type[Number(key)] === activeWorkflowType // Cast the key to a number
                    );
                    
                    if (key) {
                      setActiveWorkflowId(Number(key)); 
                      setActiveWorkflowTypeId(null)// Pass the key as a number
                    }
                    }}>New WorkFlow</button>
                  </div>
                  {isWorkFlow?.map((item: any) => (
                    <>
                      {item?.metaData?.eventDescription ===
                        activeWorkflowType && (
                        <>
                          <Card>
                            <CardBody>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="flow-title d-flex align-items-center">
                                  <div className="me-2 cart-icon">
                                  {activeWorkflowType === 'Event Confirmation' ?
                                 <MdEventAvailable size={30} />:activeWorkflowType === 'Event Reminder' ? 
                                 <TbClockHour12 size={30}/>: 
                                <IoIosNotificationsOutline size={30} />
                                }
                                  </div>
                                {item?.metaData?.eventName}
                                </div>
                          <div className="d-flex">
                          <div
                            className="d-flex align-items-center me-3"
                            style={{ cursor: "pointer", zIndex: 1000 }}
                            onClick={(e: any) => {
                              e.preventDefault();
                            }}
                          >
                            <div
                              className={`form-check form-switch custom-switch ${
                                props?.isInvalidAccess ? "notAllowed" : "pointer"
                              } `}
                            >
                                <Input
                                  type="checkbox"
                                  className={`form-check-input ${
                                    props?.isInvalidAccess ? "disabledState" : ""
                                  } `}
                                  id={`switch-${item.id}`}
                                  checked={!item?.disabled} // Checked when workflow is enabled
                                  onChange={(e) => {
                                    const isEnabled = e.target.checked; 
                                  
                                    // `checked` means "enabled"
                                    // Update the `isWorkFlow` state
                                    setIsWorkFlow((prevData:any) =>
                                      prevData.map((workflow:any) =>
                                        workflow.id === item.id
                                    ? { ...workflow, disabled: !isEnabled } // Toggle the `disabled` property
                                    : workflow
                                      )
                                    );
                                    // Update the backend with the new state
                                    updateConnectionDisableStatus(item.uid, !isEnabled);
                                    props.setCallDb(true);

                                  }}
                                />
                            </div>
                            </div> 
                                <div onClick={() => editEvent(item)}>
                                  <AiOutlineEdit
                                    size={20}
                                    style={{ cursor: "hover" }}
                                  />
                                </div>
                                <div className="ms-3">
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={renderPopover(item)} 
                      rootClose
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <PiDotsThreeVerticalBold size={20} />
                      </div>
                    </OverlayTrigger>
                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </>
                      )}
                    </>
                  ))}{" "}
                </div>
              }
            </>
          ) : (
            CalendlyPayment.map((el: any) => {
              return (
                <WorkFlowHeader
                  key={el.id}
                  id={el.id}
                  title={el.title}
                  type={el.type}
                  description={el.description}
                  setActiveWorkflowId={setActiveWorkflowId}
                  setActiveWorkflowType={setActiveWorkflowType}
                  workflowData={findWorkFlowById(el.id)}
                  connectionUid={props.connectionUid}
                  channelDtoUid={initialValues.channelName?.value}
                  integrationName={initialValues.brandName}
                  setCallDb={props.setCallDb}
                  isInvalidAccess={props.isInvalidAccess}
                />
              );
            })
          )}
        </div>
      )}
      {props.activeTab === "Logs" &&(
        <>
          <LogsTable
          connectionUid = {props.connectionUid}
          />
        </>
      )}
    </>
  );
};

export default Calendly;
