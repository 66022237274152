import { toast } from '../common/alert';
import axios from '../utils/axios';


export const getAllApiKey = async (businessUid: string) => {
  try {
    const response = await axios.get(`/business/${businessUid}/getAllApiKey`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

interface DeleteApiKeyRequest {
  id: number;
  apiKey: string;
}

export const deleteApiKey = async (request: DeleteApiKeyRequest) => {
  try {
    const response = await axios.delete('/business/deleteApiKey', {
      data: request
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export type ApiKeySettingType = 'send_message'

export interface IApiKeySetting { id: number, type: ApiKeySettingType, data: any }

export interface IKeyWithSetting {
  apiKey: {
    id: number,
    businessUid: string,
    name: string,
    apiKey: string
  },
  settings: IApiKeySetting[]
}

export const getSingleApp = async (businessUid: string, apiKey: string) => {
  try {
    const response = await axios.get<IKeyWithSetting>(`/business/${businessUid}/getSingleApiKey?apiKey=${apiKey}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updateApiSetting = async (requestBody: any) => {
  try {
    const response = await axios.patch('/integration/apikey/updateSetting', requestBody);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteApiSetting = async (settingId: number) => {
  try {
    const response = await axios.delete(`/integration/apikey/${settingId}/deleteSetting`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const createApiSetting = async (apiKeyId: number, type: ApiKeySettingType, settingData: any) => {
  try {
    const response = await axios.post(`/integration/apikey/enableSetting`, { key: { id: apiKeyId }, type, data: settingData });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}