import axios from '../utils/axios';
import { toast, sweetalert } from '../common/alert';

export const getAll = (channelUid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(`paymentConfig/${channelUid}/getAll`).then(res => {
            resolve(res);
        }).catch((err: any) => {
            console.log("Something went wrong");
        })
    });
};

export const createPay = (channelUid: string, payload: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post(`paymentConfig/${channelUid}/create`, payload).then(res => {
            toast('success', 'Created successfully');
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response.data.message);  
        })
    });
};

export const updatePay = (uid: string, payload: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.patch(`paymentConfig/${uid}/update`, payload).then(res => {
            resolve(res);
            toast('success', "Updated Successfully")
        }).catch((err: any) => {
            toast("error", err.response.data.message)
            console.log("Something went wrong")
        })
    });
};

export const deletePay = (uid: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        sweetalert(
            'warning', 
            'Are you sure you want to delete?', 
            () => {
        axios.delete(`paymentConfig/${uid}/delete`).then(res => {
            toast('success', 'Deleted successfully');
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response.data.message);
        })
    },
    () => {
      
    }
    );
    });
};

export const getAllHistory = async (channelUid: string, payload: any): Promise<any> => {
    try {
        const res = await axios.post(`orderPayments/${channelUid}/getAllPayments`, payload);
        return res.data;
    } catch (err) {
        console.log("Something went wrong");
    }
};