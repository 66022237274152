import React, { Component, MouseEvent } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Container, Row, Col, FormGroup, Label, Button } from "reactstrap";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import { Form as FormBootstrap } from "react-bootstrap";
import axios from "../../utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faTrashCan,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import WidgetScript from "./WidgetScript";
import { faWhatsapp, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import FlagInput from "../../common/flagInput";
import ColorPicker from "./ColorPicker";
import { Link } from "react-router-dom";
import { Tooltipa } from "../../common/Modal";
import "./widgetStyle.scss";
import { connect } from 'react-redux';
import { BusinessId } from "../../redux/actions/action";
import Modall from '../../common/modall';
import PicturesModal from './PicturesModal';

export interface IAppProps {
  businessId: { id: number } | null,
  typeofWidget:any;
  WhatsappWidgetData?:()=>void;
  setFieldValue?:any;
  values?:any;
  channelIdData?:any;
}
interface WhatsappState {
  code: string;
  backgroundColors: any;
  buttonColor: any;
  businessName: string;
  tagline: string;
  trigerMsg: string;
  radioBtn: string;
  profileUrls: string;
  files: any;
  welcomeTxt: string;
  chatScript: any;
  scriptData: any;
  mobileNumber: any | null;
  openModal: any;
  isClose: any;
  initialDatas:any;
}

interface SelectBtnOption {
  value: string;
  label: string;
}
const buttonsOptions: SelectBtnOption[] = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
];
const WidgetOptions: SelectBtnOption[] = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
];
const buttonsType: SelectBtnOption[] = [
  { value: "text", label: "ButtonText" },
  { value: "btnlabel", label: "ButtonLabel" },
];

class WhatsAppChatButton extends React.Component<
  IAppProps,
  WhatsappState
> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      code: "+91",
      backgroundColors: "#128C7E",
      buttonColor: "#25d366",
      businessName: "",
      tagline: "",
      trigerMsg: "",
      radioBtn: "right",
      profileUrls: "",
      files: "",
      welcomeTxt: "",
      chatScript: false,
      scriptData: '',
      mobileNumber: null,
      openModal: false,
      isClose: false,
      initialDatas:[],
    };
  }
  
  fetchData = async () => {
    try {
      const { businessId } = this.props;
      const response = await axios.get(`channel/getAll?uid=${businessId}`);
      if (response?.status === 200) {
        const business = response?.data?.data;
        const Channeluid = business[0].uid;
        axios
          .get(`channel/v2/${Channeluid}/widget`)
          .then((res) => {
            console.log('ESRES', res.data);
          })
          .catch((error) => {
            console.error('Error retrieving widget:', error);
          });
      }
    } catch (error) {
      console.error('Error retrieving profile:', error);
    }
  };

  handleSubmit = (values: any) => {
    this.setState({ chatScript: true })
    const data = {
      name: this.state?.businessName,
      tag: this.state?.tagline,
      logoUrl: this.state.profileUrls,
      buttonType: null,
      buttonLabel: null,
      buttonColor: this.state.buttonColor,
      themeColor: this.state.backgroundColors,
      position: null,
      options: this.state.radioBtn,
      welcomeText: this.state.welcomeTxt,
      openModals: this.state.openModal,
      isClose: this.state.isClose,
    };
    this.fetchData();
    // Rest of your code...
  };
  // componentDidMount = async()=>  {
    // const{ channelIdData } = this.props
    // const widget = await axios.get(`channel/${channelIdData}/widget`)
    // console.log('vid',widget)
    
  // }

  componentDidUpdate=async(prevProps: Readonly<IAppProps>, prevState: Readonly<WhatsappState>, snapshot?: any)=> {
    console.log(this.props.channelIdData,prevProps.channelIdData)
    // if(prevProps.channelIdData !== this.props.channelIdData ){
      const{ channelIdData } = this.props
      const widget = await axios.get(`channel/${channelIdData.value}/widget`)
      console.log('vid',widget)
    // }
  }

  codeChange = (e: string) => {
    this.setState({ code: e });
  };
  handleColorChange = (color: any) => {
    this.setState({ backgroundColors: color });
    // Perform additional actions with the color here
  };
  whatsappColor = (color: any) => {
    this.setState({ buttonColor: color });
  };
  newField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    this.setState({ [target.name]: target.value } as Pick<
      WhatsappState,
      keyof WhatsappState
    >);
  };
  profileChange = (profileUrl: any) => {
    this.setState({ profileUrls: profileUrl });
  };
  handleFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      const updatedFiles: { imgUrl: string; imgName: string; image: File }[] =
        [];

      for (let i = 0; i < files.length; i++) {
        const newFile = files[i];
        const imgUrl = URL.createObjectURL(newFile);

        updatedFiles.push({
          imgUrl: imgUrl,
          imgName: newFile.name,
          image: newFile,
        });
      }
      this.setState({ files: updatedFiles });
    }
  };
  removeFiles = () => {
    this.setState({ files: "" });
  };
  // handleScroll = () =>{
  //   const { isScrolled } = this.state;
  //   if (!isScrolled) {
  //     // Scroll logic here
  //     const elementTwo = document.querySelector('.WhtDetails') as HTMLElement;
  //     if (elementTwo) {
  //       elementTwo.classList.add('WhtDetails');
  //     }

  //     // Update state to prevent repeated scrolling
  //     this.setState({
  //       isScrolled: true
  //     });
  //   }

  // }
  handleShow = () => {
    this.setState({ openModal: true })
  }
  handleClose = () => {
    this.setState({ openModal: false })
  }

  
  public render() {
    const {
      backgroundColors,
      buttonColor,
      businessName,
      tagline,
      trigerMsg,
      radioBtn,
      profileUrls,
      files,
      welcomeTxt,
      chatScript,
      mobileNumber,
      openModal,
    } = this.state;
    const {setFieldValue,values} = this.props
    return (
      <>
        <div className="container-fluid">
          {/* <div className="row"> */}
            <div className="col-6">
            </div>
            <div className="container widgetForm">
              <div className="row">
                <div className="col-8">
                  <Label className="WhtDetails">
                    Customize Whatsapp Chat Widget
                  </Label>
                      <Form>
                        <FormGroup className="centered-form-temp">
                          <Label>
                            WhatsApp Number{" "}
                            <span className="redstar_required"></span>
                          </Label>
                          <div className="input-group inputBorder">
                            <div className="input-group-prepend ms-4">
                              <FlagInput countryCode='+91' codeChange={this.codeChange} />
                            </div>
                            <Field
                              type="text"
                              name="mobileNumber"
                              id="mobileNumber"
                              placeholder="Enter your WhatsApp Number"
                              className="form-control"
                              value={values.mobileNumber? values.mobileNumber : ''}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value.replace(/[^\d]/g, '');
                                setFieldValue('mobileNumber', e.target.value);
                                this.setState({ mobileNumber: Number(value) })
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="mobileNumber"
                            component="div"
                            className="text-danger"
                          />
                          <Row>
                            <Col>
                              <Label className="mt-4">
                                Example: 9090912345
                              </Label>
                              <br />
                              <Label className="">
                                Name of your business/brand{" "}
                                <span className="redstar_required"></span>
                              </Label>
                              <Field
                                type="text"
                                name="businessName"
                                id="businessName"
                                value={businessName}
                                onChange={
                                  (e: any) => {
                                    this.newField(e);
                                    setFieldValue('businessName', e.target.value)
                                  }
                                }
                                placeholder="Name to be displayed in Widget"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="businessName"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-4 mb-4">
                              <Label>
                                Tagline{" "}
                                <span className="redstar_required"></span>
                              </Label>
                              <Field
                                type="text"
                                name="tagline"
                                id="businessName"
                                placeholder="Your tagline inform"
                                className="form-control"
                                onChange={
                                  (e: any) => {
                                    this.newField(e);
                                    setFieldValue('tagline', e.target.value)
                                  }
                                }
                                value={tagline}
                              />
                              <ErrorMessage
                                name="tagline"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Label>Your Logo</Label>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="LogoContainer d-flex justify-content-between">
                              <Label >
                                <div className="LogoFile">Upload files</div>
                                <input
                                  type="file"
                                  className="hideFileInput"
                                  accept=".jpeg,.jpg,.png"
                                  onChange={(event) =>
                                    this.handleFileChange(event.target.files)
                                  }
                                />
                              </Label>
                              <div>
                                {files &&
                                  files?.map((file: any, index: any) => (
                                    <div
                                      key={index}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                      className="UploadImg"
                                    >
                                      <img
                                        src={file.imgUrl}
                                        width={50}
                                        height={50}
                                        alt="oldimage"
                                      />
                                      {files !== "" && (
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          className="cancelIcon"
                                          onClick={this.removeFiles}
                                        />
                                      )}
                                    </div>
                                  ))}
                              </div>
                              <Label className="mt-3">(OR)</Label>
                              <div>
                                {files === "" && profileUrls ? (
                                  <img
                                    id="target"
                                    src={require(`./../../assets/img/${profileUrls}`)}
                                    width={50}
                                    height={50}
                                    alt="oldimage"
                                  />
                                ) : null}
                              </div>
                              <Label className="">
                                <Button className="LogoFile" onClick={this.handleShow}>Click Chatbot avatar</Button>
                              </Label>
                              {this.state.openModal && (
                                <Modall isOpen={this.state.openModal} onClose={this.handleClose} title={"picturemodal"} size="md">
                                  <PicturesModal profileChange={this.profileChange} />
                                </Modall>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Label>Brand Color</Label>
                              <ColorPicker
                                onColorChange={this.handleColorChange}
                              />
                            </Col>
                            <Col>
                              <Label>Widget Position</Label>
                              <br />
                              <div>
                                <ul>
                                  {WidgetOptions.map((option) => (
                                    <li
                                      key={option.value}
                                      className="radioOption"
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name="widTextOpt"
                                          value={option.value}
                                          checked={
                                            values.widTextOpt === option.value
                                          }
                                          onChange={(e: any) => {
                                            const value = e.target.value;
                                            // this.setState({
                                            //   radioBtn: value,
                                            // });
                                            setFieldValue(
                                              "widTextOpt",
                                              option.value
                                            );
                                          }}
                                        />
                                        <span className="headerOptnLabel">
                                          {option.label}
                                        </span>
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-4">
                              <Label>
                                Welcome Text{" "}
                                <span className="redstar_required"></span>
                              </Label>
                              <FormBootstrap.Control
                                as="textarea"
                                onChange={this.newField}
                                name="welcomeTxt"
                                value={welcomeTxt}
                                placeholder="WelCome to BizMagnet"
                                rows={4}
                                cols={40}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                          </Row>
                        </FormGroup>
                        <Label className="WhtDetails">
                          Customize Whatsapp Chat Button
                        </Label>
                        <FormGroup className="centered-form-temp">
                          {/* <Row>
                            <Col>
                              <Label>BUTTON TYPE</Label>
                              <br />
                              <div>
                                <ul>
                                  {buttonsType.map((option) => (
                                    <li
                                      key={option.value}
                                      className="radioOption"
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name="btnOptions"
                                          value={option.value}
                                          checked
                                          onChange={() => {
                                            setFieldValue(
                                              "btnOptions",
                                              option.value
                                            );
                                          }}
                                        />
                                        <span className="headerOptnLabel">
                                          {option.label}
                                        </span>
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row> */}
                          <Row>
                            <Col>
                              <Label>ButtonColor</Label>
                              <ColorPicker onColorChange={this.whatsappColor} />
                            </Col>
                            <Col>
                              <Label>ButtonText</Label>
                              <div>
                                <ul>
                                  {buttonsOptions.map((options) => (
                                    <li
                                      key={options.value}
                                      className="radioOption"
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name="btnTextOpt"
                                          value={options.value}
                                          checked={
                                            values.btnTextOpt === options.value
                                          }
                                          onChange={(e: any) => {
                                            const value = e.target.value;
                                            this.setState({
                                              radioBtn: value,
                                            });
                                            setFieldValue(
                                              "btnTextOpt",
                                              options.value
                                            );
                                          }}
                                        />
                                        <span className="headerOptnLabel">
                                          {options.label}
                                        </span>
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Label>Trigger Message</Label>
                              <Field
                                type="text"
                                name="trigerMsg"
                                id="trigerMsg"
                                placeholder="Message Us"
                                className="form-control"
                                onChange={this.newField}
                                value={trigerMsg}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        {
                          (chatScript && chatScript === true) ? (<WidgetScript scriptData={this.state} />) : null
                        }
                        {/* <div className="d-flex  justify-content-end">
                          <Button
                            variant=""
                            type="submit"
                            className="signUpBtn w-50 ms-2 m-0"
                            disabled={isSubmitting}
                          >
                            Generate Widget Code
                          </Button>
                        </div> */}
                      </Form>
                    {/* )}
                  </Formik> */}
                </div>
                <div className="col-4">
                  <FormGroup className="centered-form-temp">
                    <Row className="ChatHeader" style={{ backgroundColor: backgroundColors }}>
                      <Col className="col-3 p-0">
                        {files !== "" ? (
                          files &&
                          files.map((file: any, index: any) => (
                            <img
                              key={index}
                              id={`target_${index}`}
                              src={file.imgUrl}
                              width={80}
                              height={70}
                              alt="oldimage"
                            />
                          ))
                        ) : files === "" && profileUrls ? (
                          <img
                            id="target"
                            src={require(`./../../assets/img/${profileUrls}`)}
                            width={80}
                            height={70}
                            alt="oldimage"
                          />
                        ) : null}
                      </Col>
                      <Col className="col-9">
                        <h5>
                          {businessName && businessName.length > 0
                            ? businessName.length > 15
                              ? businessName.substring(0, 15) + "..."
                              : businessName
                            : "BizMagnet"}
                        </h5>
                        <p>
                          {tagline && tagline.length > 0
                            ? tagline.length > 20
                              ? tagline.substring(0, 20) + "...."
                              : tagline
                            : "tagline cmd"}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ChatBackgroundImg">
                        <header >
                          <div className="WhatsappImgContent mw-100">
                            {welcomeTxt ? welcomeTxt : "welcome to bizmagnet"}
                          </div>
                        </header>
                        <footer className="footerChatBox">
                          <div className="sendMsg">Send a message</div>
                          <div className="poweredBy">
                            <Label>
                              Powered by{" "}
                              <Link to={`${process.env.PUBLIC_URL}/`}>
                                {" "}
                                Bizmagnet{" "}
                              </Link>
                            </Label>
                          </div>
                        </footer>
                      </Col>
                    </Row>
                    <Row>
                      {radioBtn === "left" ? (
                        <Col>
                          <div
                            className="WhatsappImgContent"
                            style={{ display: trigerMsg ? "block" : "none" }}
                          >
                            {trigerMsg}
                          </div>
                        </Col>
                      ) : null}
                      <Col>
                        <div className="WhatsAppImg">
                          <FontAwesomeIcon
                            icon={faWhatsapp}
                            style={{ backgroundColor: buttonColor }}
                            className="faWhatsapp"
                          />
                        </div>
                      </Col>
                      {radioBtn === "right" ? (
                        <Col>
                          <div
                            className="WhatsappImgContent"
                            style={{ display: trigerMsg ? "block" : "none" }}
                          >
                            {trigerMsg}
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  </FormGroup>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    businessId: state.cartreducer.business?.business?.uid,
    channelIdData : state.cartreducer.channelUid
    // Other properties you want to map from the Redux store
  };
};

export default connect(mapStateToProps)(WhatsAppChatButton);
