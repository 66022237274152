import React from "react"
import { IListSourceType } from "./conversationFilter";

export interface IFilterData {
    tags: { id: number }[];
    assignTo: string;
    channelUids: string[];
    channelIds: number[];
    assignId: number[];
    status: string[];
    unReadMessages: boolean;
    broadcastUids: string[];
    conversationType?: string,
};

export interface ConversationContextState {
    isFilterApplied: boolean;
    filters: IFilterData;
    tempFilters: Record<IListSourceType, any[]>;
    filterDataCache: Record<IListSourceType, any[]>;
    setFilter: (f: IFilterData) => void;
    setTempFilter: (f: Record<IListSourceType, any[]>) => void;
    setFilterDataCache: (f: Record<IListSourceType, any[]>) => void;
    clearFilter: () => void;
    setTags: (t: any[]) => void;
    updateConversationContact: (contact: any) => void;
};

/**
 * Context for conversation filter
 */
const conversationContext = React.createContext<ConversationContextState>({
    isFilterApplied: false,
    filters: {
        tags: [],
        assignTo: "0",
        channelUids: [],
        channelIds: [],
        assignId: [],
        status: [],
        unReadMessages: false,
        broadcastUids: []
    },
    filterDataCache: {
        tags: [],
        member: [],
        bot: [],
        channel: [],
        campaign: []
    },
    tempFilters: {
        tags: [],
        member: [],
        bot: [],
        channel: [],
        campaign: []
    },
    setFilter(_) { },
    setTempFilter(_) { },
    setFilterDataCache(_) { },
    clearFilter() { },
    setTags(_) { },
    updateConversationContact(_) { }
});

export default conversationContext;