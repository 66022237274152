import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import moment from 'moment';

const downloadExcelforDashboard = (appointments: any[], config?: any) => {
  if (!Array.isArray(appointments)) {
    console.error("Appointments is not an array");
    return;
  }
  const formatDate = (date: any) => {
    const data = new Date(date);
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit'
    } as const;
    const formattedDate = data.toLocaleString('en-US', options);
    return formattedDate;
  }

  
  const getAvailableDays = (selectedDays: boolean[]) => {
    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const availableDays: string[] = [];
    for (let i = 0; i < selectedDays.length; i++) {
      if (selectedDays[i]) {
        availableDays.push(dayNames[i]);
      }
    }
    if (availableDays.length === 0) {
      return "No days available";
    } else {
      return availableDays.join(", ");
    }
  };

  const groupByMember = (appointments: any[]) => {
    return appointments.reduce((acc: any, appointment: any) => {
      const memberName = appointment.memberName || "Unknown";
      if (!acc[memberName]) {
        acc[memberName] = [];
      }
      acc[memberName].push(appointment);
      return acc;
    }, {});
  };

  const groupedAppointments = groupByMember(appointments);
  const workbook = new ExcelJS.Workbook();

  for (const memberName in groupedAppointments) {
    const memberAppointments = groupedAppointments[memberName];
    const worksheet = workbook.addWorksheet(memberName);
    const data = memberAppointments.map((appointment: any) => ({
      Name: appointment.name,
      Description: appointment?.description,
      'Booked On': formatDate(appointment?.createdat),
      'Scheduled Date': appointment?.scheduledAt &&!appointment.emergencyMember ? appointment.scheduledAt.date:appointment?.emergencyMember ?appointment?.date:"-",
      Timeslot: appointment?.scheduledAt?.startTime && appointment?.scheduledAt?.endTime? `${moment(appointment?.scheduledAt?.startTime, "HH:mm:ss").format("h:mm a")} - ${moment(appointment?.scheduledAt?.endTime, "HH:mm:ss").format("h:mm a")}`:'-',
      Contact: appointment?.contact?.name,
'Contact Number': appointment?.contact?.mobileNumber 
  ? `+${appointment.contact.mobileNumber.slice(0, 2)} ${appointment.contact.mobileNumber.slice(2)}`
  : '-',

      Status: appointment?.status,
    }));

    worksheet.addRow([memberName]);

    worksheet.mergeCells('A1:G1');

    const headerRow = worksheet.getRow(1);
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.font = { bold: true, color: { argb: "black" }, size: 16 };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'F6FAFE' }
    };
    worksheet.addRow([
      'Name',
      'Description',
      'Booked on',
      'Scheduled Date',
      'Time Slot',
      'Contact',
      'Contact Number',
      'Status',
    ]);
    const cellRow = worksheet.getRow(2);
    cellRow.font = { bold: true };

    data.forEach((row: any) => {
      worksheet.addRow(Object.values(row));
    });

    worksheet.columns.forEach((column: any) => {
      let maxLength = 0;
      if (column) {
        column?.eachCell({ includeEmpty: true }, (cell: any) => {
          const cellValue = cell?.value?.toString() || '';
          if (cellValue.length > maxLength) {
            maxLength = cellValue.length;
          }
        });
        column.width = maxLength + 2;
      }
    });

    worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'A2' }];
  }

  workbook.xlsx.writeBuffer().then((buffer: any) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, 'appointments.xlsx');
  });
};

export default downloadExcelforDashboard;
