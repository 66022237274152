import React, { Fragment, useEffect, useState, useCallback } from "react";
import { faArrowLeft, faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, FormGroup, Label, Button } from "reactstrap";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Form as FormBootstrap } from "react-bootstrap";
import { employList, industryList } from "../../common/selectOptions";
import { showAlert } from "../../common/alert";
import {
  getWidgetData,
  updateWhatsappProfile,
} from "../../services/WhatsAppchannelService";
import { useSelector } from "react-redux";
import WidgetScript from "./WidgetScript";
import {
  faWhatsapp,
  faTwitter,
  faInstagram,  
} from "@fortawesome/free-brands-svg-icons";

import {
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import FlagInput from "../../common/flagInput";
import ColorPicker from "./ColorPicker";
import { Link } from "react-router-dom";
import { Tooltipa } from "../../common/Modal";
import "./widgetStyle.scss";
import { connect } from "react-redux";
import { BusinessId } from "../../redux/actions/action";
import Modall from "../../common/modall";
import PicturesModal from "./PicturesModal";
import { useFormikContext } from "formik";
export interface WhatsAppWidgetClasses<T> {
  component: T;
}

interface WhatsAppWidgetProps {
  WhatsappWidgetData: (widgetData: any) => void;
  values:any;
  isScriptModal:boolean;
  handleScriptClose:()=>void;
  getfileData:(file:any) => void;
}

interface SelectBtnOption {
  value: string;
  label: string;
}
const buttonsOptions: SelectBtnOption[] = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
];
const WidgetOptions: SelectBtnOption[] = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
];

export const WhatsAppWidget = (props: WhatsAppWidgetProps) => {
  const {values,isScriptModal,handleScriptClose} = props
  const [getCode, codeChange] = useState();
  const { setFieldValue } = useFormikContext();
  // const [fieldConent,setField] = useState()
  const countryCodeChange = (e: any) => {
    codeChange(e);
  };

  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const { WhatsappWidgetData,getfileData } = props;

  const [wigetDatas, get_widget] = useState([]);

  const whatsapp_widget = async () => {
    const channelId = channelData?.value;
    if (channelId) {
      const widget = await getWidgetData(channelId);
      if (widget) {
        get_widget(widget);
      }
    }
    WhatsappWidgetData(wigetDatas);
  };

  const [fieldContent, setFieldContent] = useState({
    widget_businessName: "",
    widget_tagline: "",
    code: "+91",
    backgroundColors: "#128C7E",
    buttonColor: "#25d366",
    trigerMsg: "",
    radioBtn: "right",
    profileUrls: "",
    files: "",
    btnTextOpt:'',
    welcomeTxt: "",
    chatScript: false,
    scriptData: "",
    mobileNumber: null,
    openModal: false,
    isClose: false,
  });

  const [getfile, setGetFile] = useState<File | null>(null);
  
  const [imgFiles, setImgFiles] = useState<
    { imgUrl: string; imgName: string; image: File }[]
  >([]);

  const [openModal, setOpenModal] = useState(false);
  const [profileUrls, setProfileUrls] = useState("");

  const handleShow = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const scriptClose = useCallback(() =>{
      handleScriptClose();
  },[])

  const profileChange = (profileUrl: any) => {
    setProfileUrls(profileUrl);
  };

  const handleFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      const updatedFiles: { imgUrl: string; imgName: string; image: File }[] =
        [];

      for (let i = 0; i < files.length; i++) {
        const newFile = files[i];
        const imgUrl = URL.createObjectURL(newFile);

        updatedFiles.push({
          imgUrl: imgUrl,
          imgName: newFile.name,
          image: newFile,
        });
      }
      setGetFile(file);
      getfileData(file);
      setImgFiles(updatedFiles);
    }
  };

  const removeFiles = () => {
    setGetFile(null);
    setImgFiles([]);
  };

  const newField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setFieldContent({
      ...fieldContent,
      [target.name]: target.value,
    });
  };

  const handleColorChange = (color: any) => {
    setFieldContent({
      ...fieldContent,
      backgroundColors: color,
    });
  };

  const whatsappColor = (color: any) => {
    setFieldContent({
      ...fieldContent,
      buttonColor: color,
    });
  };

  useEffect(() => {
    whatsapp_widget();
  }, [channelData]);

  useEffect(() => {
    if (wigetDatas) {
      props.WhatsappWidgetData(wigetDatas);
    }
  }, [wigetDatas]);
  return (
    <div>
      <Form>
        <FormGroup className="">
          <Row>
            <Col md="9">
              <Row>
                <Col sm='12' md='6'>
                  <Label className="">
                    Name of your business/brand{" "}
                    <span className="redstar_required"></span>
                  </Label>
                  <Field
                    type="text"
                    name="widget_businessName"
                    id="widget_businessName"
                    // value={widget_businessName}
                    onChange={(e: any) => {
                      newField(e);
                      setFieldValue("widget_businessName", e.target.value);
                    }}
                    placeholder="Name to be displayed in Widget"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="widget_businessName"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col sm='12' md='6' className="">
                  <Label>
                    tagline <span className="redstar_required"></span>
                  </Label>
                  <Field
                    type="text"
                    name="widget_tagline"
                    id="widget_businessName"
                    placeholder="Your widget_tagline inform"
                    className="form-control"
                    onChange={(e: any) => {
                      newField(e);
                      setFieldValue("widget_tagline", e.target.value);
                    }}
                    // value={widget_tagline}
                  />
                  <ErrorMessage
                    name="widget_tagline"
                    component="div"
                    className="text-danger"
                  /> 
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Your Logo</Label>
                </Col>
              </Row>
              <Row>
                <Col className=" d-flex justify-content-between">
                  <Label>
                    <div className="upload-select">Upload files</div>
                    <input
                      type="file"
                      className="hideFileInput"
                      accept=".jpeg,.jpg,.png"
                      onChange={(event) => handleFileChange(event.target.files)}
                    />
                  </Label>
                  <div>
                    {imgFiles &&
                      imgFiles?.map((file: any, index: any) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          className="UploadImg"
                        >
                          <img
                            src={file.imgUrl}
                            width={50}
                            height={50}
                            alt="oldimage"
                          />
                          {file !== "" && (
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          className="cancelIcon"
                                          onClick={removeFiles}
                                        />
                                      )}
                        </div>
                      ))}
                  </div>
                  <Label className="mt-3">(OR)</Label>
                  <div>
                    {imgFiles.length === 0 && profileUrls ? (
                      <img
                        id="target"
                        src={require(`./../../assets/img/${profileUrls}`)}
                        width={50}
                        height={50}
                        alt="oldimage"
                      />
                    ) : null}
                  </div>
                  <Label className="">
                    <Button
                      className="upload-select"
                      onClick={handleShow}
                    >
                      Click Chatbot avatar
                    </Button>
                  </Label>
                  {openModal && (
                    <Modall
                      isOpen={openModal}
                      onClose={handleClose}
                      title={"picturemodal"}
                      size="md"
                    >
                      <PicturesModal profileChange={profileChange} />
                    </Modall>
                  )}
                   {isScriptModal && (
                    <Modall
                      isOpen={isScriptModal}
                      onClose={scriptClose}
                      title={"widget script"}
                      size="md"
                    >
                      <WidgetScript scriptData={fieldContent} />
                    </Modall>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm='' md='2'>
                  <Label>Brand Color</Label>
                  <ColorPicker onColorChange={handleColorChange} />
                </Col>
                <Col sm='' md='4'>
                  <Label>Widget Position</Label>
                  <br />
                  <div>
                    <ul>
                      {WidgetOptions.map((option: any) => (
                        <li key={option.value} className="radioOption">
                          <label>
                            <Field
                              type="radio"
                              name="widTextOpt"
                              value={option.value}
                              // checked={
                              //   values.widTextOpt === option.value
                              // }
                              onChange={(e: any) => {
                                // const value = e.target.value;
                                // this.setState({
                                //   radioBtn: value,
                                // });
                                setFieldValue("widTextOpt", option.value);
                              }}
                            />
                            <span className="headerOptnLabel">
                              {option.label}
                            </span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col sm='' md='6' className="">
                  <Row>
                    <Col sm='' md='3' className="p-0" >  
                  <Label>
                    Welcome Text <span className="redstar_required"></span>
                  </Label>
                    </Col>
                    <Col  className="p-0">
                    <Field
                    as="textarea"
                    className='welcomeTxt'
                    name="welcomeTxt"
                    onChange={(e: any) => {
                      newField(e);
                      setFieldValue("welcomeTxt", e.target.value);
                    }}
                    placeholder="Welcome to BizMagnet"
                    rows={4}
                    cols={40}
                   />
                    </Col>
                  </Row>
                  {/* <FormBootstrap.Control
                    as="textarea"
                    onChange={(e: any) => {
                      newField(e);
                      setFieldValue("welcomeTxt", e.target.value);
                    }}
                    name="welcomeTxt"
                    // value={welcomeTxt}
                    placeholder="WelCome to BizMagnet"
                    rows={4}
                    cols={40}
                  /> */}
                </Col>
              </Row>
              <h5 className="WhtDetails pt-3">
                Customize Whatsapp Chat Button
              </h5>
              {/* <FormGroup className="centered-form-temp"> */}
              {/* <Row>
                            <Col>
                              <Label>BUTTON TYPE</Label>
                              <br />
                              <div>
                                <ul>
                                  {buttonsType.map((option) => (
                                    <li
                                      key={option.value}
                                      className="radioOption"
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name="btnOptions"
                                          value={option.value}
                                          checked
                                          onChange={() => {
                                            setFieldValue(
                                              "btnOptions",
                                              option.value
                                            );
                                          }}
                                        />
                                        <span className="headerOptnLabel">
                                          {option.label}
                                        </span>
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Col>
                          </Row> */}
              <Row>
                <Col sm='' md='2'>
                  <Label>ButtonColor</Label>
                  <ColorPicker onColorChange={whatsappColor} />
                </Col>
                <Col sm='' md='4'>
                  <Label>ButtonText</Label>
                  <div>
                    <ul>
                      {buttonsOptions.map((options) => (
                        <li key={options.value} className="radioOption">
                          <label>
                            <Field
                              type="radio"
                              name="btnTextOpt"
                              value={options.value}
                              checked={
                                fieldContent?.radioBtn === options.value
                              }
                              onChange={(e: any) => {
                                const value = e.target.value;
                                setFieldContent({...fieldContent,radioBtn:value});
                                setFieldValue("btnTextOpt", value);
                              }}
                            />
                            <span className="headerOptnLabel">
                              {options.label}
                            </span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col sm='' md='6'>
                  <Row>
                    <Col sm='' md='5'>
                {values.radioBtn === "left" ? (
                        <div
                          className="triger-msg-content"
                          style={{ display: values.trigerMsg ? "block" : "none" }}
                        >
                          {values.trigerMsg}
                        </div>
                    ) : null}
                    </Col>
                    <Col sm='' md='2' className="p-0">
                      <div className="WhatsAppImg">
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{ backgroundColor: fieldContent.buttonColor }}
                          className="faWhatsapp"
                        />
                      </div>
                      </Col>
                      <Col sm='' md='5'>
                    {fieldContent.radioBtn === "right" ? (
                        <div
                          className="triger-msg-content"
                          style={{ display: values.trigerMsg ? "block" : "none" }}
                        >
                          {values.trigerMsg}
                        </div>
                    ) : null}
                    </Col>
                    </Row>
                  </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Trigger Message</Label>
                  <Field
                    type="text"
                    name="trigerMsg"
                    id="trigerMsg"
                    placeholder="Message Us"
                    className="form-control"
                    onChange={(e:any)=>{
                      newField(e)
                      setFieldValue('trigerMsg',e.target.value)
                    }}
                    // value={trigerMsg}
                  />
                </Col>
              </Row>
              {/* </FormGroup> */}
            </Col>
            <Col md="3">
              <div>
                <FormGroup className="">
                  <Row
                    className="ChatHeader"
                    style={{ backgroundColor: fieldContent.backgroundColors }}
                  >
                    <Col className="col-3 p-0">
                      {imgFiles.length > 0 ? (
                        imgFiles &&
                        imgFiles.map((file: any, index: any) => (
                          <img
                            key={index}
                            id={`target_${index}`}
                            src={file.imgUrl}
                            width={80}
                            height={70}
                            alt="oldimage"
                          />
                        ))
                      ) : imgFiles.length === 0 && profileUrls ? (
                        <img
                          id="target"
                          src={require(`./../../assets/img/${profileUrls}`)}
                          width={80}
                          height={70}
                          alt="oldimage"
                        />
                      ) : null}
                    </Col>
                    <Col className="col-9">
                      <h5>
                        {values.widget_businessName &&
                        values.widget_businessName.length > 0
                          ? values.widget_businessName.length > 15
                            ? values.widget_businessName.substring(
                                0,
                                15
                              ) + "..."
                            : values.widget_businessName
                          : "BizMagnet"}
                      </h5>
                      <p>
                        {values.widget_tagline &&
                        values.widget_tagline.length > 0
                          ? values.widget_tagline.length > 20
                            ? values.widget_tagline.substring(0, 20) +
                              "...."
                            : values.widget_tagline
                          : "tagline cmd"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ChatBackgroundImg">
                      <header>
                        <div className="WhatsappImgContent mw-100">
                          {values.welcomeTxt
                            ? values.welcomeTxt
                            : "welcome to bizmagnet"}
                        </div>
                      </header>
                      <footer className="footerChatBox">
                        <div className="sendData">
                        <div className="sendMsg">Send a message 
                         </div>
                         <img
                        id="target"
                        src={require(`../../../src/assets/img/sendButton.png`)}
                        width={50}
                        height={50}
                        alt="oldimage"
                      />
                      </div>
                        <div className="poweredBy">
                          <Label>
                            Powered by{" "}
                            <Link to={`${process.env.PUBLIC_URL}/`}>
                              {" "}
                              Bizmagnet{" "}
                            </Link>
                          </Label>
                        </div>
                      </footer>
                    </Col>
                  </Row>
                  {/* <Row>
                  
                  </Row> */}
                </FormGroup>
              </div>
            </Col>
          </Row>
        </FormGroup>
      </Form>
      {/* </Container> */}
    </div>
  );
};

export default WhatsAppWidget;
