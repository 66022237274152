import React, { useState } from 'react';
import { Button, Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { saveAs } from 'file-saver';
import './WhatsappLink.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

interface modalint {
  showModal: any;
  handleClose: any;
  link: any;
}

const Modall : React.FC<modalint> = ({showModal, handleClose, link}) => {
  const [copycontent, setIscopied] = useState('Copy link');
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(link);
      console.log('Link copied to clipboard!');
      setIscopied('Copied!')
    } catch (error) {
      console.error('Failed to copy link:', error);

    }
  };
  const downloadQRCode = () => {
    const canvas = document.querySelector('canvas') as HTMLCanvasElement; // Assuming the QRCode component renders a canvas element
    const dataURL = canvas.toDataURL('image/png');
    saveAs(dataURL, 'qrcode.png');
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleClose}>
      <Button className="closebtn text-dark h4" onClick={handleClose}> x </Button>
          <Modal.Title><h5 className="text-center text-dark">Your WhatsApp link has been generated!</h5></Modal.Title>
        <Modal.Body>
        <p className="pt-2 pr-2 pl-2 lh-5 text-dark modalcon">Copy it and share it via emails, messaging, your website or even social media. Your desired users can contact you in just one click. Download the QR code to share it anywhere.</p>
        <Link to={link} className="btn btn-link pt-2 col-12 text-dark h4" target="_blank"><FontAwesomeIcon icon={faWhatsapp} className="whatsappicon"/>whatsMe/bizmagnet</Link>
        <QRCode value={link} className="qrcode" />
        </Modal.Body>
        <div className="text-right">
        <Button className="signUpBtn mb-4 mr-4 text-bold" onClick={handleCopyClick}>
          {copycontent}
        </Button>
        <Button className="signUpBtn mb-4 mr-4" onClick={downloadQRCode}>Download QR</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Modall;
