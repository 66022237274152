import React from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "reactstrap";

import * as botService from "../../services/bots";

const SelectBot: React.FC<any> = props => {
    const query = useQuery({
        queryKey: ['bots', '?q='],
        queryFn: () => botService.getBots('')
    });
    if (query.isLoading) {
        return <Spinner />
    }

    let value: any = '';

    if (props.value) {
        const botUidFound = query.data.bots.find((bot: any) => bot.uid === props.value);
        const botIdFound = query.data.bots.find((bot: any) => bot.id === props.value);
        if (botUidFound) {
            value = { value: props.value, label: botUidFound.name }
        }
        if (botIdFound) {
            value = { value: props.value, label: botIdFound.name }
        }
    }

    return (
        <>
            <Select
                options={query.data.bots.map((bot: any) => {
                    return {
                        label: bot.name,
                        value: bot.id
                    }
                })}
                value={value}
                onChange={(selected: any) => {
                    const botid = selected.value;
                    if (botid) {
                        const bot = query.data.bots.find((bot: any) => bot.id === botid);
                        if (bot)
                            props.setValue(bot.uid, bot.id);
                    }
                }}
            />
            {props.isInvalid ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {props.error}
                </div>
            ) : null}
        </>
    );
};

SelectBot.defaultProps = {
    isInvalid: false,
    isValid: false
};

export default SelectBot