import React from 'react'
import Node from '.././node';
import styles from '../node.module.scss';
import { ListGroup } from 'react-bootstrap';
import classes from '../delay.module.css';
import { Handle, Position } from 'reactflow';


import IntegrationNode from './integrationNode'

function ZohoBigin(props: any) {
    let content = (<>
        <span  className={styles.metaData}>
            {props?.data?.integrationData?.action === "createAContact" && "Create A Contact"} 
        </span>
        <ListGroup>
        <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
            <div>
                <span ></span>
                <span className={classes.caption}>On Success</span>
                <Handle
                    className={classes.dot}
                    id='integration/ZohoBigin/success'
                    type='source'
                    position={Position.Right}
                    isConnectable
                />
            </div>
        </ListGroup.Item>
        <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
            <div>
                <span className={classes.caption}>On Fail</span>
                <Handle
                    className={classes.dot}
                    id='integration/ZohoBigin/failed'
                    type='source'
                    position={Position.Right}
                    isConnectable
                />
            </div>
        </ListGroup.Item>
    </ListGroup>
</>)

return <Node {...props} label="Zoho Bigin" content={content} />
}

export default ZohoBigin