import axios from "../utils/axios";

interface DateRange {
    fromDate: string;
    toDate: string
}

export const getCtwaOverview = async (channelId: string, payload:any) => {
    if (!channelId) {
        return new Promise(() => { });
    }
    try {
        // const response = await axios.post(`campaign/${channelId}/getAllCampaigns`, {
        //     page: 0,
        //     limit: 1000,
        //     fromDate,
        //     toDate,
        //     search: search.trim() === "" ? null : search
        // });
        const response = await axios.post(`campaign/${channelId}/getAllCampaigns`, payload);
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getCtwaChart = async (channelId: string, { fromDate, toDate }: DateRange) => {
    if (!channelId) {
        return new Promise(() => { });
    }
    try {
        const response = await axios.post(`campaign/${channelId}/getAllCampaignsChart`, {
            fromDate,
            toDate
        });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}