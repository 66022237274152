import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ExpandableParagraphProps {
    text: string;
    maxLength?: number;
    tooltip?: boolean;
}

const ExpandableParagraph: React.FC<ExpandableParagraphProps> = ({ text, maxLength, tooltip }) => {
    const [expanded, setExpanded] = useState(false);
    const truncatedText = text?.slice(0, maxLength);
    const shouldTruncate = text?.length > (maxLength || 30);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            {expanded ? (
                <p>{text}</p>
            ) : (
                <p>
                    {shouldTruncate ? `${truncatedText}` : text}
                    {shouldTruncate && (
                        <OverlayTrigger
                            overlay={<Tooltip>{tooltip ? text : 'Show more'}</Tooltip>}
                            placement='bottom'
                        >
                            <span onClick={tooltip ? () => { } : toggleExpand} className='ml-1' style={{ color: 'var(--bs-link-color)', fontSize: 14, cursor: 'pointer', fontWeight: 'bold' }}>
                                ...
                            </span>
                        </OverlayTrigger>
                    )}
                </p>
            )}
            {!tooltip && expanded ? (
                <span onClick={toggleExpand} className='btn btn-link ml-1' style={{ color: 'var(--bs-link-color)', fontSize: 14, cursor: 'pointer' }}>
                    Show less...
                </span>
            ) : null}
        </div>
    );
};

ExpandableParagraph.defaultProps = {
    maxLength: 30,
    tooltip: false
};

export default ExpandableParagraph;