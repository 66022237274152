import Node from '../node';

function UpdateConversationStatus(props: any) {
    let chatStatus;
    switch (props.data.conversation.conversationStatus) {
        case "NEW":
            chatStatus = "New";
            break;
        case "SPAM":
            chatStatus = "Spam";
            break;
        case "QUALIFIED":
            chatStatus = "Qualified";
            break;
        default:
            chatStatus = "Pending";
            break;
    }
    return (
        <Node {...props} label='Update Chat Status' content={(
            <>
                Chat Status is set to {chatStatus}
            </>
        )} />
    );
}

export default UpdateConversationStatus;