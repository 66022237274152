import axios from '../utils/axios';
import { toast } from '../common/alert';

export const getAllFlows = async (flowPayload: any) => {
    try {
        const res = await axios.post('/flow/getAll', flowPayload);
        return res;
    } catch (err) {
        // toast('error', err.error);
        console.log("Something went wrong");
    }
};


export const getAllFlow = (channelUid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        const payload ={
        channelId: channelUid,
          limit: 10000,
          page: 0,
        }
        axios.post('/flow/getAll', payload).then(res => {
            resolve(res);
        }).catch((err: any) => {
            // toast('error', err.error);
            console.log("Something went wrong")
        })
    });
};


export const syncFlows = (channelUid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.patch(`/flow/sync/${channelUid}`).then(res => {
            toast('success', 'Synced successfully')
            resolve(res);
        }).catch((err: any) => {
            toast('error', 'Could not sync');
        })
    });
};