import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import "../../main.scss";
import {
  getConnection,
  updateConnection,
} from "../../../../services/integrationService";
import { useSelector } from "react-redux";
import { stripeWorkFlowTypes, workFlowTypes } from "../../assets";
import WorkFlowHeader from "../../Component/WorkFlowHeader";
import WorkFlowForm from "../../Component/WorkFlowForm";
import LogsTable from "../../Component/LogsTable";

interface ShiprocketProps {
  integrationUid?: string; // Make it optional if it can be undefined
  activeTab: string;
  connectionUid: string;
  callDb: boolean;
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkFlowEnable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isInvalidAccess: boolean;
}

interface FormValues {
  integrationName: string;
  channelName: { value: string; label: string } | null;
  // tokenKey: string;
  integrationVariables: any[];
}

const validationSchema = Yup.object({
  integrationName: Yup.string().required("Integration Name is required"),
  channelName: Yup.object().nullable().required("Channel Name is required"),
  // tokenKey: Yup.string().required("ApiKey is required"),
});

const Stripe: React.FC<ShiprocketProps> = (props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    integrationName: "",
    channelName: null,
    // tokenKey: "",
    integrationVariables: [],
  });

  const [activeWorkflowId, setActiveWorkflowId] = useState<number | null>(null);
  const [isWorkFlow, setIsWorkFlow] = useState([]);
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
  useEffect(() => {
    if (props.integrationUid) {
      getConnection(props.integrationUid)
        .then((data: any) => {
          console.log(data, "stripeDDDD");
          setInitialValues({
            integrationName:
              data.integrationConfigurationDTO.integrationName || "",
            channelName: data.integrationConfigurationDTO.channelDto
              ? {
                  value: data.integrationConfigurationDTO.channelDto.uid,
                  label: data.integrationConfigurationDTO.channelDto.name,
                }
              : null,
            // tokenKey: data.integrationConfigurationDTO.tokenKey,
            integrationVariables: data?.integrationVariables,
          });
          props.setWorkFlowEnable(
            Object.keys(data.integrationConfigurationDTO?.channelDto).length >
              0 || false
          );

          setIsWorkFlow(data.workFlows);
        })
        .catch((error) => {
          console.error("Error fetching connection data:", error);
        });
    }
  }, [props.integrationUid, props.callDb]);

  useEffect(() => {
    if (props.callDb) {
      setTimeout(() => {
        props.setCallDb(false);
      }, 1000); // set callDb to false after 2 seconds
    }
  }, [props.callDb]);

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const data = {
      integrationConfigurationDTO: {
        channelDto: {
          uid: values.channelName?.value,
        },
        integrationName: values.integrationName,
      },
    };

    try {
      const response = await updateConnection(props.connectionUid, data);
      props.setCallDb(true);
      props.setActiveTab("Workflow");
      // formikHelpers.resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const channelOptions = channelData.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));


  const findWorkFlowById = (id: number) => {
    const workFlowType = stripeWorkFlowTypes.find((types: any) => types.id === id);

    const workFlow = isWorkFlow.find(
      (wf: any) => wf.workFlowType === workFlowType?.value
    );

    return workFlow || null;
  };

  return (
    <>
      {props.activeTab === "Overview" && (
        <div className="stripeOverview">
          <div className="headerOverview">
            Integrate Stripe with your Official WhatsApp Business Number and
            share payment updates to your customers.
          </div>

          <div>
            <h5 className="fw-bold mt-4 mb-4">What can I do?</h5>
          </div>

          <div className="body">
            <div className="me-3">
              <div className="overview">
                <div className="me-2">
                  <img
                    src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.4524'%20height='32.4524'%20rx='16.2262'%20fill='%23DDE4FF'/%3e%3cpath%20d='M12.7812%2022.1875C9.64875%2022.1875%207.09375%2019.6325%207.09375%2016.5C7.09375%2013.3675%209.64875%2010.8125%2012.7812%2010.8125C13.14%2010.8125%2013.4375%2011.11%2013.4375%2011.4688C13.4375%2011.8275%2013.14%2012.125%2012.7812%2012.125C10.3663%2012.125%208.40625%2014.085%208.40625%2016.5C8.40625%2018.915%2010.3663%2020.875%2012.7812%2020.875C15.1962%2020.875%2017.1562%2018.915%2017.1562%2016.5C17.1562%2016.1413%2017.4537%2015.8438%2017.8125%2015.8438C18.1713%2015.8438%2018.4688%2016.1413%2018.4688%2016.5C18.4688%2019.6325%2015.9137%2022.1875%2012.7812%2022.1875Z'%20fill='%23171923'/%3e%3cpath%20d='M20%2022.4062C19.6413%2022.4062%2019.3438%2022.1088%2019.3438%2021.75C19.3438%2021.3912%2019.6413%2021.0938%2020%2021.0938C22.5288%2021.0938%2024.5938%2019.0288%2024.5938%2016.5C24.5938%2013.9712%2022.5288%2011.9062%2020%2011.9062C17.4712%2011.9062%2015.4062%2013.9712%2015.4062%2016.5C15.4062%2016.8587%2015.1087%2017.1562%2014.75%2017.1562C14.3913%2017.1562%2014.0938%2016.8587%2014.0938%2016.5C14.0938%2013.245%2016.745%2010.5938%2020%2010.5938C23.255%2010.5938%2025.9062%2013.245%2025.9062%2016.5C25.9062%2019.755%2023.255%2022.4062%2020%2022.4062Z'%20fill='%23171923'/%3e%3c/svg%3e"
                    alt=""
                  />
                </div>
                <div>Conversing with leads via Whatsapp</div>
              </div>
              <div className="overview">
                <div className="me-2">
                  <img
                    src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.3816'%20height='32.3816'%20rx='16.1908'%20fill='%23FFF8DD'/%3e%3cpath%20d='M16%2016.6253C13.3583%2016.6253%2011.2083%2014.4753%2011.2083%2011.8337C11.2083%209.19199%2013.3583%207.04199%2016%207.04199C18.6416%207.04199%2020.7916%209.19199%2020.7916%2011.8337C20.7916%2014.4753%2018.6416%2016.6253%2016%2016.6253ZM16%208.29199C14.05%208.29199%2012.4583%209.88366%2012.4583%2011.8337C12.4583%2013.7837%2014.05%2015.3753%2016%2015.3753C17.95%2015.3753%2019.5416%2013.7837%2019.5416%2011.8337C19.5416%209.88366%2017.95%208.29199%2016%208.29199Z'%20fill='%23292D32'/%3e%3cpath%20d='M8.8418%2024.9583C8.50013%2024.9583%208.2168%2024.675%208.2168%2024.3333C8.2168%2020.775%2011.7085%2017.875%2016.0001%2017.875C16.8418%2017.875%2017.6668%2017.9834%2018.4668%2018.2084C18.8001%2018.3%2018.9918%2018.6417%2018.9001%2018.975C18.8085%2019.3083%2018.4668%2019.5%2018.1335%2019.4084C17.4501%2019.2167%2016.7335%2019.125%2016.0001%2019.125C12.4001%2019.125%209.4668%2021.4583%209.4668%2024.3333C9.4668%2024.675%209.18346%2024.9583%208.8418%2024.9583Z'%20fill='%23292D32'/%3e%3ccircle%20cx='20.5'%20cy='20.5'%20r='3'%20fill='%23FF4A4A'%20stroke='white'/%3e%3c/svg%3e"
                    alt=""
                  />
                </div>
                <div>Send personalized messages to your new leads</div>
              </div>
            </div>
            <div>
              <div className="overview">
                <div className="me-2">
                  <img
                    src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.3816'%20height='32.3816'%20rx='16.1908'%20fill='%23FADDFF'/%3e%3cpath%20d='M16.7849%2014.1523H20.7224'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M12.285%2014.1523L12.8475%2014.7148L14.535%2013.0273'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M16.7849%2019.4023H20.7224'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M12.2849%2019.4023L12.8474%2019.9648L14.5349%2018.2773'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3cpath%20d='M14.25%2024H18.75C22.5%2024%2024%2022.5%2024%2018.75V14.25C24%2010.5%2022.5%209%2018.75%209H14.25C10.5%209%209%2010.5%209%2014.25V18.75C9%2022.5%2010.5%2024%2014.25%2024Z'%20stroke='black'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/svg%3e"
                    alt=""
                  />
                </div>
                <div>
                  Simplify order management and enhance customer satisfaction
                  effortlessly!
                </div>
              </div>
              <div className="overview">
                <div className="me-2">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO1SURBVHgBvVhLTBNBGP5mqaIx6hqDiaXGFvSgHmhP6kVK4oMLoTHgQQ6UKAcvUrh6oMQYE0wMePEioRzkoAHbmxIS6gk40Yt4UXYJBUI0UkhQEbrjP8sjrYXuLA+/BLYz+8/M1//xzUwZJDHGNVXFobJVpAMK4AeYCnD3+usU/ekcTKe+qAIlXsKck7JzMysDsfgxHGyiyUPUVCEJDsSJVMd5Vhyzss1LQuMzTQaMsJ3Fc8kw8gwL5fPMliQ+81n3AaS7men2PUGKPBM6x1w9kCEhCByEMZQR7z0EC5ey4rac3v9HYANKqJQ5O7N6MhsiBPtLQMDo0PhsObYi8ZVPt9rJgcXUIro7XqEv8hZ2YSAdEVW30XaIfyIMQDpkZ6Iq700kJ5PrhBbQELpvZ7h7rexh5oeZE1/4NFUCD8qMHk98woPAPRS7z+CC95JJYE6fRX+0H6fUIizjD+bwAyRqVlORL1c8PuZJOdaZ+CGBpD6FuopavHzXhSv+q5v9RSQjR19HgVgMhdXVKKqrwSy+W01HIlhYT89ORePT1bLJ+CL8HA9bW7IICBxGITA4CCwtAb29a20JcBgB8VQMcD8kMRD7gBuBypz+JfwGyAMoKQEaG/ETv2SmMyMgEpRN8OQQlwxHKXNRFSVz+mnDwkkcx0pqGRP6BE55i2VywgRZ+RQi4MUuQfsLvmEeXdEInna2SxMQII0oEzqx481pL8CgqIqscV/kDS6XX81rI0p2MPoe9sBNEikZU1EZt4N38tpcJBIXyi6hk2ylKYDrjORasypR4YWR+AieRawnF1pS5buFsflxyICqs0J4Im5luEDaZnkE2yGOoEBz0KknYYjDWB7UUBgeh1rhO3HRjLvIDZfbtflebGbTehKjH4cxRZ5oIkGThO6kExfTSCwMHJiXGSEWG6DEe9IcxvXqyoz+BcwQiUcd4Rw1tUCEtKfB9LIdwRK466/FZVqsKdxiEqujdj3tojXBWtjBYShu4QmzRBkKwnYGt1OCjsaHTQW95rlCVVNrm4A4jTvXD7+b+WbXG7vFhhfE502xIm80QFIzdg8WdmZcAbIqj85+9eLohf1FgsLoy+zIkm0PO90jWGL/oFMYAv925uwda/eCfSGSULDic25xE9tWCJN8rmwZq9G9uAIYAN1JXc3bvbdUY3EVoEdwJ2Ro8ngavO08OxO3sLOGZl4JUM6RDlqX8drPA7QVxKwWt0Uim5CmrsLhpSuCWgDHWVpMpW9LqmekCpBOeJhHh038Bbn3akTlgY4tAAAAAElFTkSuQmCC"
                    alt=""
                  />
                </div>
                <div>Automating conversations with leads via a bot</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.activeTab === "Configuration" && (
        <div className="shipRocket-configuration">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="integrationName">
                    Integration Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="integrationName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="integrationName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="channelName">
                    Channel Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Select
                    options={channelOptions}
                    name="channelName"
                    value={values.channelName}
                    onChange={(option) => setFieldValue("channelName", option)}
                    className="channel-select"
                  />
                  <ErrorMessage
                    name="channelName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* <div className="form-group">
                  <label htmlFor="tokenKey">
                    Stripe ApiKey
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Field type="text" name="tokenKey" className="form-control" />
                  <ErrorMessage
                    name="tokenKey"
                    component="div"
                    className="text-danger"
                  />
                </div> */}

                <div
                  className={`d-flex justify-content-center mt-3 ${
                    props?.isInvalidAccess ? "notAllowed" : "pointer"
                  } `}
                >
                  <button
                    type="submit"
                    className="btn btn-primary d-flex justify-content-between"
                    disabled={props?.isInvalidAccess}
                  >
                    Update Configuration
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {props.activeTab === "Workflow" && (
        <div>
          {activeWorkflowId ? (
            <WorkFlowForm
              id={activeWorkflowId}
              title={
                stripeWorkFlowTypes.find((wf) => wf.id === activeWorkflowId)?.title ||
                ""
              }
              description={
                stripeWorkFlowTypes.find((wf) => wf.id === activeWorkflowId)
                  ?.description || ""
              }
              value={
                stripeWorkFlowTypes.find((wf) => wf.id === activeWorkflowId)
                  ?.value || ""
              }
              setActiveWorkflowId={setActiveWorkflowId}
              connectionUid={props.connectionUid}
              channelDtoUid={initialValues.channelName?.value}
              integrationName={initialValues.integrationName}
              integrationVariables={initialValues?.integrationVariables}
              workflowData={findWorkFlowById(activeWorkflowId)}
              setCallDb={props.setCallDb}
              isInvalidAccess={props.isInvalidAccess}
            />
          ) : (
            stripeWorkFlowTypes.map((el: any) => {
              return (
                <WorkFlowHeader
                  key={el.id}
                  id={el.id}
                  title={el.title}
                  description={el.description}
                  setActiveWorkflowId={setActiveWorkflowId}
                  workflowData={findWorkFlowById(el.id)}
                  connectionUid={props.connectionUid}
                  channelDtoUid={initialValues.channelName?.value}
                  integrationName={initialValues.integrationName}
                  setCallDb={props.setCallDb}
                  isInvalidAccess={props.isInvalidAccess}
                />
              );
            })
          )}
        </div>
      )}
            {props.activeTab === "Logs" &&(
        <>
          <LogsTable
          connectionUid = {props.connectionUid}
          />
        </>
      )}
    </>
  );
};

export default Stripe;
