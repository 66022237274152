import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

interface ContactDataItem {
  contactDate: string;
  businessOnboard: number;
  userInitiated: number;
  otherSource: number;
}

const ConvChart: React.FC<{ data: ContactDataItem[] }> = ({ data }) => {
   const lineColors = ["#ff9933", "#007bff", "#21a144"]; 

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'short' }) + ' ' + date.getDate().toString().padStart(2, '0');
  };

  const CircleLegend = (props: any) => {
    const { payload } = props;
    return (
      <div className="d-flex catmargin">
        {payload.map((entry: any, index: number) => (
          <span
            key={`item-${index}`}
            style={{ display: "inline-block", marginRight: 10 }}
          >
            <svg width={10} height={10}>
              <circle cx={5} cy={5} r={5} fill={entry.color} />
            </svg>
            <span style={{ marginLeft: 5 }}>{entry.value}</span>
          </span>
        ))}
      </div>
    );
  };
  const formatYAxis = (tickItem: number) => {
    return tickItem?.toString();
  };
  const getUniqueTicks = () => {
    let maxYValue = Math.max(
        ...data.flatMap((item) => [item?.businessOnboard, item?.userInitiated, item?.otherSource])
    );
  
    const roundUpValue = (value: number) => {
        return Math.ceil(value);
    };

    maxYValue = roundUpValue(maxYValue);
    const minValue = 4;
    if (maxYValue < minValue) {
        maxYValue = minValue;
    }
    const step = Math.ceil(maxYValue / 4);
    const ticks = Array.from({ length: 5 }, (_, i) => i * step);

    return ticks;
};



  
  return (
    <div style={{ width: "max-content" }}>
      <LineChart
        width={600}
        height={320}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="contactDate" tickFormatter={formatDate} />
        <YAxis 
          tickFormatter={formatYAxis} 
          ticks={getUniqueTicks()} 
          interval={0}
          type="number"
          domain={[0, 'dataMax']}
          allowDecimals={false}     
        />
            <Tooltip
  labelFormatter={formatDate}
  formatter={(value, name, entry) => {
    if (entry && entry.dataKey) {
      let label = '';
      switch (entry.dataKey) {
        case 'businessOnboard':
          label = 'Business Initiated';
          break;
        case 'userInitiated':
          label = 'User Initiated';
          break;
        case 'otherSource':
          label = 'Other Sources';
          break;
        default:
          break;
      }
      return [value, label];
    }
    return [value];
  }}
/>

        <Legend content={<CircleLegend />} />
        <Line
          dataKey="businessOnboard"
          stroke={lineColors[0]}
          type="monotone"
          name="Business Initiated"
          dot={false} // remove dots
        />
        <Line
          dataKey="userInitiated"
          stroke={lineColors[1]}
          type="monotone"
          name="User Initiated"
          dot={false} // remove dots
        />
        <Line
          dataKey="otherSource"
          stroke={lineColors[2]}
          type="monotone"
          name="Other Sources"
          dot={false} // remove dots
        />
      </LineChart>
      <small className="d-flex catContent">Days Vs Count Of User, Business And Free Entry Conversation</small>
    </div>
  );
};

export default ConvChart;
