import { useContext, useState } from 'react';
import { QueryClient } from '@tanstack/query-core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { IEditorProps } from './types';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { setNodeData, createNode, deleteNode, completeNodeCreation } from '../../../store/graphSlice';
import { FormData as ISendTemplateFormData, ITemplateComponent } from './sendTemplate';
import { uuidv4 } from '../../../utils/uuid';
import axios from '../../../../utils/axios';
import { toast } from '../../../../common/alert';
import initialContext from './useContext';

interface UseElmentEditorOptions {
    type: string;
    data: any;
}

export function useElmentEditor(options: UseElmentEditorOptions, props: IEditorProps) {
    const MySwal = withReactContent(Swal);
    const nodes = useAppSelector((state) => state.graph.nodes);
    const channelId = useAppSelector(state => state.meta.channelUid);
    const dispatch = useAppDispatch();
    const [elementId, setElementId] = useState(props.id);
    const queryClient = new QueryClient();
    const initial = useContext(initialContext)


    function init(callback: (data: any) => void) {
        if (!props.create) {

            const selectedNode = nodes.find(node => node.id == props.id);
            if (selectedNode && selectedNode.data) {
                callback(selectedNode.data);
            }
        } else {
            const uid = uuidv4();
            
            dispatch(createNode({ uid, type: options.type, data: options.data }));
            setElementId(uid);
        }
    }

    async function saveElementChanges(data: any) {

        initial.isSave = true;
        function filterEmptyFields(obj: { [key: string]: string }): { [key: string]: string | number } | null {
            const result: { [key: string]: string | number } = {};

            for (const key in obj) {
                if (obj[key] !== '') {
                    result[key] = isNaN(Number(obj[key])) ? obj[key] : Number(obj[key]);
                }
            }
            return Object.keys(result).length === 0 ? null : result;
        }

        if (options.type === 'delay') {
            let newData = { ...data };
            if (!newData.delayOperation) {
                newData.delayOperation = {};
            }
            if (!('completeId' in newData.delayOperation))
                newData.delayOperation.completeId = null;
            if (!('interruptId' in newData.delayOperation))
                newData.delayOperation.interruptId = null;
            data = newData;
        }

        if (options.type === 'api_call') {
            let newData = { ...data };
            if (!newData.apiData) {
                newData.apiData = {};
            }
            if (!('successTargetId' in newData.apiData))
                newData.apiData.successTargetId = null;
            if (!('failTargetId' in newData.apiData))
                newData.apiData.failTargetId = null;
            data = newData;
        }

        if (options.type === 'integration/Woocommerce') {
            let newData = { ...data };
            if (!newData.apiData) {
                newData.apiData = {};
            }
            if (!('successTargetId' in newData.apiData))
                newData.apiData.successTargetId = null;
            if (!('failTargetId' in newData.apiData))
                newData.apiData.failTargetId = null;
            data = newData;
        }
         
        if (options.type === 'send_OrderDetails') {
            let newData = { ...data };
            if (!newData) {
                newData = {};
            }
            if (!('successTargetId' in newData))
                newData.successTargetId = null;
            if (!('failTargetId' in newData))
                newData.failTargetId = null;
            data = newData;
        }

        // if (options.type === 'integration/Shopify') {
        //     let newData = { ...data };
        //     if (!newData) {
        //         newData = {};
        //     }
        //     if (!('successTargetId' in newData?.integrationData))
        //         newData.successTargetId = null;
        //     if (!('failTargetId' in newData?.integrationData))
        //         newData.failTargetId = null;
        //     data = newData;
        // }

        if (options.type === 'appointment_confirmation') {
            let newData = { ...data };
            if (!newData) {
                newData = {};
            }
            if (!('successTargetId' in newData))
                newData.successTargetId = null;
            if (!('failTargetId' in newData))
                newData.failTargetId = null;
            data = newData;
        }

        // if (options.type === 'ticketIt') {
        //     let newData = { ...data };
        //     if (!newData) {
        //         newData = {};
        //     }
        //     if (!('createTicket' in newData.ticketFlow))
        //         newData.ticketFlow.createTicket = null;
        //     if (!('updateTicket' in newData.ticketFlow))
        //         newData.ticketFlow.updateTicket = null;
        //     data = newData;
        // }

        if (options.type === 'ask_address') {
            let newData = { ...data };
            if ('validation' in data && data.validation) {
                newData.validation = filterEmptyFields(data.validation);
            }
            if ('storageVariable' in data && data.storageVariable) {
                newData.storageVariable = newData.storageVariable.name.trim() === '' ? null : newData.storageVariable;
            }

            if (newData.addressParam && newData.addressParam.name.trim() === '') {
                newData.addressParam = null;
            }
           
        
            data = newData;
            console.log(data);
        }



        // Ask element types
        else if (options.type.search('ask_type/') === 0) {
            let newData = { ...data };
            if ('validation' in data && data.validation) {
                newData.validation = filterEmptyFields(data.validation);
            }
            if ('storageVariable' in data && data.storageVariable) {
                newData.storageVariable = newData.storageVariable.name.trim() === '' ? null : newData.storageVariable;
            }
           
            data = newData;
        }


        // Send template
        else if (options.type === 'send_wa_template' || options.type === "send_wa_templateV2") {
            let newData: ISendTemplateFormData = JSON.parse(JSON.stringify(data));
            if (newData.template) {
                const templateList = await queryClient.ensureQueryData({
                    queryKey: ['templates'],
                    queryFn: async () => {
                        try {
                            const response = await axios.request({
                                method: 'POST',
                                url: '/template/getAllTemplates',
                                data: JSON.stringify({
                                    channelId,
                                    page: 0,
                                    limit: 10,
                                })
                            });
                            const templates = response.data.templates.filter((template: any) => template.templateStatus === 'APPROVED');
                            return templates;
                        } catch (error) {
                            console.error(error);
                            return [];
                        }
                    }
                });

                const template = templateList.find((template: any) => template.id === newData.template?.id);

                newData.template.components = newData.template.components.map((cmp, i) => {
                    switch (cmp.type) {
                        case 'HEADER':
                            if (cmp.example && cmp.example?.header_handle?.length > 0) {
                                return {
                                    ...cmp,
                                    example: {
                                        header_handle: cmp?.example?.header_handle.map((mediaUrl, j) => {
                                            let template = templateList.find((template: any) => template.id === newData.template?.id);
                                            return 'media' in newData && newData?.media ? (newData?.media as any).url : template?.media ? template?.media?.url : mediaUrl;
                                            // return mediaUrl.trim() === '' ? (template ? template.components[i].example.header_handle[j] : '') : mediaUrl;
                                        })
                                    }
                                }
                            }
                            return cmp;
                        case 'BODY':
                            if (cmp.example && cmp.example.body_text?.length > 0) {
                                return {
                                    ...cmp,
                                    example: {
                                        body_text: cmp.example.body_text
                                            // .map((variableValue, j) => {
                                            //     let template = templateList.find((template: any) => template.id === newData.template?.id);
                                            //     return [variableValue.trim() === '' ? (template ? template.components[i].example.body_text[j] : '') : variableValue];
                                            // })
                                            .map((variableValue, j) => {
                                                return variableValue.map((value, k) => {
                                                    if (value.trim() === '') {
                                                        return template.components[i].example.body_text[j][k];
                                                    }
                                                    return value.trim();
                                                });
                                            })
                                    }
                                }
                            }
                            return cmp;
                        case 'BUTTONS':
                            if (cmp.buttons?.length > 0) {
                                return {
                                    ...cmp,
                                    buttons: cmp.buttons.map((button, j) => {
                                        if (button.type === 'URL' && button.example) {
                                            return {
                                                ...button,
                                                example: button.example.map((variableValue, k) => {
                                                    return variableValue.trim() === '' ? template.components[i].buttons[j].example[k] : variableValue.trim();
                                                })
                                            };
                                        }
                                        return button;
                                    })

                                }
                            }
                            return cmp;
                        default:
                            return cmp;
                    }
                }) as ITemplateComponent[];
            }
            data = newData;
        }

        dispatch(setNodeData({ data, id: elementId, create: props.create }));

        if (props.create) {
            toast("success", "Card created");
            dispatch(completeNodeCreation({ uid: elementId }));
        }
        else if(initial.duplicate === true) {
            toast("success", "Card created");
        }
        else {
            toast("success", "Card updated");
        }
        props.onClose();
    }

    async function deleteElement() {
        const { isConfirmed } = await MySwal.fire({
            title: 'Are you sure',
            text: 'You want to delete this element?',
            confirmButtonText: 'Yes',
            showDenyButton: true
        })

        if (isConfirmed) {
            dispatch(deleteNode({ id: elementId }));
            props.onClose();
        }
    }

    return { init, saveElementChanges, deleteElement };
}