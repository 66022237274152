import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface ProgressBarProps {
    totalMilliseconds: number;
    width: number;
    height: number;
    color: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ totalMilliseconds, width, height, color }) => {
    const hours = Math.floor(totalMilliseconds / (60 * 60 * 1000));
    const totalSeconds = totalMilliseconds / 1000;
    let percentage;
    if (totalMilliseconds === 24 * 60 * 60 * 1000) {
        percentage = 100;
    } else if (hours === 23) {
        percentage = 95;
    } else {
        percentage = (totalSeconds / 86400) * 100;
    }
    const graphStyle = {
        width: `${width}px`,
        height: `${height}px`,
    };
    const circularProgressbarStyles = {
        path: {
            stroke: color,
        },
        trail: {
            stroke: '#f0f0f0',
        },
    };
    return (
        <div style={graphStyle}>
            <CircularProgressbar value={percentage} text={`${hours}h`} styles={circularProgressbarStyles} />
        </div>
    );
};

export default ProgressBar;
