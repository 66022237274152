import { ListGroup } from 'react-bootstrap';

import Node from '../node';
import OptionItem from './optionItem';

function AskButtonOption(props: any) {
    return (
        <div>
            <Node {...props} label='Ask button option' content={(
                <div>
                    {props.data.questionText}
                    <ListGroup as='ol'>
                        {props.data.options.map((option: any) => {
                            return (
                                <OptionItem key={option.id} optionText={option.optionText} />
                            );
                        })}
                    </ListGroup>
                </div>
            )} />
        </div>
    );
}

export default AskButtonOption;