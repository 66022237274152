import { FaPlus } from 'react-icons/fa';
import Select, { components } from 'react-select';
import { Position } from 'reactflow';

export const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        // zIndex: 1000,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
        caretColor: 'transparent',
        height:"40px"
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? 'black' : provided.color,
    }),
    menu: (provided: any) => ({
        ...provided,
        width: "95%",
        
      }),
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,  // Ensures the portal menu renders on top
    }),
};

export const catalogStyles = {
  control: (provided:any, state:any) => ({
    ...provided,
    backgroundColor: "transparent",
    caretColor: 'transparent',
    borderColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none', 
      borderColor: 'transparent',
    },
  }),
  option: (provided:any, state:any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#EBF7FD' : 'transparent', // Background color for selected option
  }),
  singleValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#EBF7FD', // Background color for the selected item in the input
    color: '#000', // Text color for the selected item
    padding: '5px', // Optional: Adjust padding for better appearance
    borderRadius: '4px', // Optional: Rounded corners
  }),
  menuList: (provided:any) => ({
    ...provided,
    zIndex: 9999, // Increase z-index for the menu list to ensure it appears on top
    maxHeight: '200px',
    overflowY: 'auto',
    backgroundColor: '#EBF7FD', // Background color for selected option

    '::-webkit-scrollbar': {
      width: '0px', 
      height: '0px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent', 
    },
  }),
  menuPortal: (provided:any) => ({
    ...provided,
    zIndex: 9999987654567890876545678,
    backgroundColor:'#EBF7FD',
  }),
  menu: (provided:any) => ({
    ...provided,
    width: '95%',
    backgroundColor: '#EBF7FD', // Background color for selected option
    zIndex: 99999999999999, 

  }),
  dropdownIndicator: (provided:any, state:any) => ({
    ...provided,
    padding: 0, 
    marginRight: '1000px', 
    svg: {
      marginLeft: 0, 
    },
  }),
  multiValue: (provided:any) => ({
    ...provided,
    maxWidth: 'none',
    width: '100%',
    backgroundColor: '#EBF7FD', 
  }),
  multiValueRemove: (provided:any, state:any) => ({
    ...provided,
    backgroundColor: "transparent", 
    // borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000', 
    cursor: 'pointer',
    Position:"relative",
    marginBottom:"60px",
    position:"relative",
    left:"10px",
    // marginLeft:"50px",
    '&:hover': {
      backgroundColor: "#9CBAEA", 
      // color: '#000', // Change the icon color on hover if needed
      // transform: 'translateY(-2px)', // Move the icon a little up on hover
    },
  }),
  multiValueLabel: (provided:any) => ({
    ...provided,
    whiteSpace: 'normal',
    width: 'auto',
    // backgroundColor: '#EBF7FD', // Background color for selected option

  }),
  input: (provided:any) => ({
    ...provided,
    color: 'transparent',
    // backgroundColor: '#EBF7FD', 
  }),
};

export const customSelectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        // zIndex: 1000,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
        caretColor: 'transparent'
    }),
    
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#FBFBFB' : provided.backgroundColor,
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? 'black' : provided.color,
    }),
    menu: (provided: any) => ({
        ...provided,
        width: "100%",
      }),
};