import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Card, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Alert, Button, FormGroup, Label } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEdit } from '@fortawesome/free-regular-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'

import * as apiService from '../../services/Apiservice'
import styles from './ApiKeySettings.module.scss'
import Loader from '../../shade/Loaders/Loaders'
import { SOMETHING_WENT_WRONG } from '../../constants/errorMessage'
import ReactSelect from 'react-select'
import TemplateCard from '../Template/TemplateCard'
import RightSidebar from '../../common/rightSidebar'
import Modall from '../../common/modall'
import TemplateList from '../Template/templateList'
import { toast } from '../../common/alert'
import { showAlertYesNo } from '../../common/alertYesNo'

const API_KEY_SETTINGS: { id: apiService.ApiKeySettingType, title: string, description: string }[] = [
    {
        id: 'send_message',
        title: 'Send Message',
        description: 'Send Whatsapp template to a contact.'
    }
]

type SettingsFormProps = {
    create: boolean,
    setting: null | apiService.IApiKeySetting,
    onCancel: () => any,
    onCreate: () => any,
    settingType?: apiService.ApiKeySettingType,
    apiKey: apiService.IKeyWithSetting
}

const SettingsForm = (props: SettingsFormProps) => {
    interface FormData {
        id: number,
        type: apiService.ApiKeySettingType,
        data: {
            channelId: string,
            // template: any
        }
    }

    const [pickTempalte, setPickTemplate] = useState(false)
    const channels = useSelector((state: any) => state.cartreducer.channelData)

    const schema = yup.object().shape({
        data: yup.object().shape({
            channelId: yup.string().required('Channel is required'),
            // template: yup.object().required('Template is required')
        }),
    })

    const onSubmitHandler = async (formData: FormData) => {
        if (!props.create && props.setting) {
            try {
                await apiService.updateApiSetting({
                    id: props.setting.id,
                    type: props.setting.type,
                    data: formData.data
                })
                toast('success', 'API Setting updated')
                props.onCreate()
            } catch (error) {
                toast('error', 'API Setting failed to update')
            }
        } else if (props.settingType) {
            try {
                await apiService.createApiSetting(props.apiKey.apiKey.id, props.settingType, formData.data)
                toast('success', 'API Setting enabled')
                props.onCreate()
            } catch (error) {
                toast('error', 'API Setting failed to enable')
            }
        }
    }

    return (
        <div className='px-4'>
            <Formik
                validationSchema={schema}
                onSubmit={onSubmitHandler}
                initialValues={{
                    id: props.setting ? props.setting.id : -1,
                    type: props.setting ? props.setting.type : 'send_message',
                    data: props.setting ? props.setting.data : {
                        channelId: '',
                        template: null
                    }
                } as FormData}
            >
                {({ handleSubmit, values, touched, errors, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modall
                                isOpen={pickTempalte}
                                onClose={() => setPickTemplate(false)}
                                size='xxl'
                                title='Select Template'
                            >
                                <TemplateList
                                    component='SEQUENCE'
                                    templateModal={pickTempalte}
                                    handleClose={() => setPickTemplate(false)}
                                    handleSelect={(temp) => {
                                        setFieldValue('data.template', temp)
                                        setPickTemplate(false)
                                        return false;
                                    }}
                                    setSelectTemplateId={(templateId: string) => ""}
                                    setRemainderTemplateId={(templateId: string) => ""}
                                />
                            </Modall>
                            <FormGroup className='mt-2'>
                                <Label>Channel <span className='required'></span></Label>
                                <ReactSelect
                                    styles={{ container: (baseStyles) => ({ ...baseStyles, maxWidth: 350 }) }}
                                    value={(() => {
                                        const channel = channels.find((c: any) => c.uid === values.data.channelId)
                                        if (channel) {
                                            return {
                                                label: channel.displayName,
                                                value: channel.uid
                                            }
                                        }
                                        return ''
                                    })()}
                                    options={channels.map((channel: any) => {
                                        return {
                                            label: channel.displayName,
                                            value: channel.uid
                                        }
                                    })}
                                    onChange={(selected: any) => {
                                        console.log('selected', selected);
                                        if (selected) {
                                            setFieldValue('data.channelId', selected.value)
                                        }
                                    }}
                                />
                                {touched.data?.channelId && errors.data?.channelId ? (
                                    <div className='invalid-feedback d-block'>
                                        {String(errors.data?.channelId)}
                                    </div>
                                ) : null}
                            </FormGroup>
                            {/* <FormGroup className='mt-2'>
                                <Label>Template <span className='required'></span></Label>
                                <Button
                                    onClick={() => setPickTemplate(true)}
                                    color='primary'
                                    className='d-block mt-2 mb-3'
                                >
                                    {values.data.template === null ? 'Select' : 'Change'} Template
                                </Button>
                                {values.data.template ? (
                                    <div style={{ maxWidth: 350 }}>
                                        <TemplateCard
                                            readOnly
                                            template={values.data.template}
                                        />
                                    </div>
                                ) : null}
                                {touched.data?.template && errors.data?.template ? (
                                    <div className='invalid-feedback d-block'>
                                        Template is required
                                    </div>
                                ) : null}
                            </FormGroup> */}
                            <FormGroup className='mt-2'>
                                <Button
                                    className='signUpBtn submitButton mb-2 pt-2'
                                    type='submit'
                                >
                                    {props.create ? 'Enable' : 'Update'}
                                </Button>
                                <Button
                                    type='button'
                                    className='cancelButton ml-2'
                                    onClick={props.onCancel}
                                >
                                    Cancel
                                </Button>
                            </FormGroup>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

type Props = {}

function ApiKeySettings({ }: Props) {
    const params = useParams()
    const [copiedToClipboard, setCopiedToClipboard] = useState(false)
    const businessUid = useSelector(
        (state: any) => state.cartreducer.business?.business?.uid
    )
    const appQuery = useQuery({
        queryKey: ['integration-app', String(params.apiKey)],
        queryFn: () => apiService.getSingleApp(businessUid, String(params.apiKey))
    })
    const [editSetting, setEditSetting] = useState<null | apiService.IApiKeySetting>(null)
    const [enableSetting, setEnableSetting] = useState<null | apiService.ApiKeySettingType>(null)

    const deleteSettingHandler = async (apiSetting: apiService.IApiKeySetting) => {
        const { isConfirmed } = await showAlertYesNo('question', 'Are you sure you want to disable this?')
        if (isConfirmed) {
            try {
                await apiService.deleteApiSetting(apiSetting.id)
                toast('success', 'Setting disabled')
                appQuery.refetch()
            } catch (error) {
                toast('success', 'Setting failed to disable')
            }
        }
    }

    let pageContent = <Loader />

    if (!appQuery.isLoading && appQuery.isError) {
        pageContent = <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
    } else if (appQuery.isSuccess) {
        pageContent = (
            <>
                <Breadcrumb className='mt-2'>
                    <Breadcrumb.Item><Link to='/ApiKey/'>API Keys</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{appQuery.data.apiKey.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Container>
                    <div className={styles.jumbo}>
                        <h3>{appQuery.data.apiKey.name}</h3>
                        <p>Your API Key is</p>
                        <div className={styles.copyToClickboard}>
                            <span className={styles.token}>
                                {appQuery.data.apiKey.apiKey}
                            </span>
                            <OverlayTrigger
                                overlay={<Tooltip>{copiedToClipboard ? 'Copied!' : 'Copy'}</Tooltip>}
                                placement='bottom'
                            >
                                <button
                                    className={styles.copyBtn}
                                    onClick={() => {
                                        setCopiedToClipboard(true)
                                        setTimeout(() => {
                                            setCopiedToClipboard(false)
                                        }, 1000)
                                    }}
                                >
                                    <FontAwesomeIcon icon={copiedToClipboard ? faCheck : faCopy} />
                                </button>
                            </OverlayTrigger>
                        </div>
                        <sub className='text-muted'>Use this key to interact with our APIs.</sub>
                    </div>
                    <main className='mt-2'>
                        <h5>Settings</h5>
                        <Row className='mt-3'>
                            {API_KEY_SETTINGS.map(apiSetting => {
                                const setting = appQuery.data.settings.find(setting => setting.type === apiSetting.id)
                                return (
                                    <Col md='4' key={apiSetting.id}>
                                        <Card className={styles.settingCard}>
                                            <Card.Body className={styles.content}>
                                                <div
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        if (setting)
                                                            setEditSetting(setting)
                                                        else
                                                            setEnableSetting(apiSetting.id)
                                                    }}
                                                >
                                                    <Card.Title className={styles.settingCard__title}>
                                                        {apiSetting.title}
                                                        {setting ? <FontAwesomeIcon icon={faEdit} className={styles.icon} /> : null}
                                                    </Card.Title>
                                                    <Card.Subtitle className={styles.settingCard__subtitle}>{apiSetting.description}</Card.Subtitle>
                                                </div>
                                                <Form.Check
                                                    type='switch'
                                                    checked={Boolean(setting)}
                                                    onClick={() => {
                                                        if (setting)
                                                            deleteSettingHandler(setting)
                                                        else
                                                            setEnableSetting(apiSetting.id)
                                                    }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>

                    </main>
                </Container>
            </>
        )
    }

    return (
        <>
            {pageContent}
            {/* Edit setting popover */}
            {editSetting !== null ? (
                <RightSidebar
                    handleCancel={() => setEditSetting(null)}
                    title={(() => {
                        if (editSetting) {
                            const setting = API_KEY_SETTINGS.find(s => s.id === editSetting.type);
                            if (setting)
                                return 'Update ' + setting.title
                        }
                        return '-'
                    })()}
                    width='35%'
                >
                    {appQuery.data ? (
                        <SettingsForm
                            create={false}
                            setting={editSetting}
                            onCreate={() => setEditSetting(null)}
                            onCancel={() => setEditSetting(null)}
                            apiKey={appQuery.data}
                        />
                    ) : null}
                </RightSidebar>
            ) : null}
            {/* Create setting popover */}
            {enableSetting !== null ? (
                <RightSidebar
                    handleCancel={() => setEnableSetting(null)}
                    title='Enable Setting'
                    width='35%'
                >
                    {appQuery.data ? (
                        <SettingsForm
                            create
                            setting={null}
                            onCreate={() => {
                                setEnableSetting(null)
                                appQuery.refetch()
                            }}
                            onCancel={() => setEnableSetting(null)}
                            settingType={enableSetting}
                            apiKey={appQuery.data}
                        />
                    ) : null}
                </RightSidebar>
            ) : null}
        </>
    )
}

export default ApiKeySettings