import { FaTags } from 'react-icons/fa';
import Node from '../node';
import styles from '../node.module.scss';
import { OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';

function removeConversationTag(props: any) {
    let actionStatus;
    switch (props.data.actionStatus) {
        case "append":
            actionStatus = "Append tags";
            break;
        case "replace":
            actionStatus = "Replace tags";
            break;
        case "remove":
            actionStatus = "Remove all tags";
            break;
        default:
            actionStatus = "Add tags if Empty";
            break;
    }

    const tags = props?.data?.contact?.dynamicValue ? props?.data?.contact?.dynamicTags[0] : props.data.contact.tags.map((tag: any) => `${tag.name}, `).join('').slice(0, -2);

    const renderTooltip = (props: React.JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip id="name-tooltip" {...props}>
            {tags}
        </Tooltip>
    );

    return (

        <Node {...props} label="Removed Tags" content={(
            <>
                {props.data.contact.tags.length > 0 ? (
                    <div className={styles.node}>
                        <FaTags size={8} className='mr-2' />
                        <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip}
                        >
                            <div className={styles.name}>
                                {props.data.contact.tags.map((tag: any) => `${tag.name}, `).join('').slice(0, -2)}
                            </div>
                        </OverlayTrigger>
                    </div>
                ) : props?.data?.contact?.dynamicTags && props?.data?.contact?.dynamicValue === true ?
                    <div className={styles.node}>
                        <FaTags size={8} className='mr-2' />
                        <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip}
                        >
                            <div className={styles.name}>
                                {props?.data?.contact?.dynamicTags[0]}
                            </div>
                        </OverlayTrigger>

                    </div>
                    : null}
            </>
        )} />
    );
}

export default removeConversationTag;