import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface ChangeComponentProps {
  route: string;
}

export const ChangeComponent: React.FC<ChangeComponentProps> = ({ route }) => {
  console.log('${process.env.PUBLIC_URL}/${route}',`${process.env.PUBLIC_URL}/${route}`)
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`${process.env.PUBLIC_URL}/${route}`);
  }, [navigate,route]);

  return null;
};

export default ChangeComponent;
