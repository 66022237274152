import React, { useState, useEffect, ReactNode } from 'react';
import { Card, Form } from 'react-bootstrap';
import styles from './validationSettings.module.css';
import { ValueSource } from 'react-avatar';

interface ExpandBoxProps {
    children: ReactNode;
    initiallyOpen?: boolean;
    onCancel?: () => void;
    caption?: string;
    setFieldValue?: any;
    component?:any;
}

const ValidationSettings: React.FC<ExpandBoxProps> = ({ children, initiallyOpen, onCancel, caption, setFieldValue, component }) => {
    const [isExpanded, setExpanded] = useState(initiallyOpen);
    useEffect(() => {
        if (!isExpanded) {
            setExpanded(initiallyOpen);
        }
    }, [initiallyOpen]);

    function toggleExpanded() {
        if (isExpanded && onCancel) {
            onCancel();
        }
        setExpanded(!isExpanded);
        if(setFieldValue)
        setFieldValue("isSameFlow", !isExpanded);
    }
    return (
        <Card className={`${styles.expandBox} ${isExpanded ? styles.expanded : ''} ${component === 'salesinbox' ? 'mb-0' : 'mb-3'}`}>
            <Card.Header className={styles.header} onClick={toggleExpanded}>
                <span className={styles.title}>{caption}</span>
                <Form.Check
                    type='switch'
                    color='primary'
                    checked={isExpanded}
                    onChange={toggleExpanded}
                />
            </Card.Header>
            {isExpanded && <Card.Body>{children}</Card.Body>}
        </Card>
    );
}

ValidationSettings.defaultProps = {
    caption: 'Validation Settings',
    initiallyOpen: false,
    onCancel: () => { },
};

export default ValidationSettings;
