import { useEffect, useState } from 'react';
import { Offcanvas, Button, Alert } from 'react-bootstrap';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';

interface FormData { };

function UnassignConversationEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({});
    const { init, saveElementChanges } = useElmentEditor({
        type: 'actions/unAssign_converation',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);
    return (
        <div>
            <EditorCaption onHide={props.onClose} caption='Unassign Conversation' icon={<></>} />
            <Offcanvas.Body>
                <Alert>
                    <i className="fa fa-info-circle" style={{ marginRight: 6 }} />
                    The conversation will be moved to the Unassigned Tab
                </Alert>
            </Offcanvas.Body>
            <div className="editor-footer">
                <Button variant='outline-dark' onClick={props.onClose}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={() => saveElementChanges({})}>
                    Save
                </Button>
            </div>
        </div>
    );
}

export default UnassignConversationEditor;