import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SLA from './index'
import Policies from './EditPolicies'

function RootSLA() {
    return (
        <Routes>
            <Route path='/' element={<SLA />} />
            <Route
                    path={`/edit`}
                    element={<Policies />}
                  />
        </Routes>
    )
}

export default RootSLA