import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardFooter, Row, Col } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./policy.module.scss";
import { Label } from "reactstrap";
import { getSLA } from "../../../services/ticketService";
import { useSelector } from "react-redux";
import { sliceText } from "../../../common/sliceText";
import TooltipReference from "../../../common/tooltip";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsJournalText, BsInfoCircle } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";

const Policies: React.FC = () => {
  const navigate = useNavigate();
  const [policyData, setPolicyData] = useState<any>([]);
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );
  useEffect(() => {
    const fetchData = async () => {
      if (channelUid) {
        const response = await getSLA(channelUid);
        if (response) {
          setPolicyData(response);
        }
      }
    };
    fetchData();
  }, [channelUid]);

  return (
    <div className={styles.slaContainer}>
      <h5>SLA Policies</h5>
      <div className={styles.slaInfo}>
        <BsInfoCircle size={17} /> Set up targets for agents to guarantee timely
        responses and resolutions customers.
      </div>
      <Card
        onClick={() => navigate("edit")}
        className={`w-75 mt-3 ms-5 ${styles.card}`}
      >
        <CardHeader className={styles.cardHeader}>
          <Label>{policyData?.name}</Label>
        </CardHeader>
        <CardBody className={styles.cardbody}>
          <Row className="gap-2">
            <Col md={1} className={` ${styles.clipboardIcon} p-0`}>
              <BsJournalText size="70%" />
            </Col>
            <Col className="ps-1 mt-3" md={10}>
              <div>
                {policyData?.description?.length > 350 ? (
                  <TooltipReference
                    placement="right"
                    content={policyData?.description}
                    tooltipId="description"
                  >
                    {sliceText(policyData?.description, 350)}
                  </TooltipReference>
                ) : (
                  //   <div className={styles.scrollBars}>
                  //   {policyData?.description}
                  // </div>
                  policyData?.description
                )}
              </div>
            </Col>
            <Col>
              <MdOutlineModeEdit className={styles.editIcon} size={25} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* Add your policy content here */}
    </div>
  );
};

export default Policies;
