import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, Button, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';

import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import { openAiIcon } from '../../../icons';
import { useAppSelector } from '../../../hooks';
import axios from '../../../../utils/axios';
import { SOMETHING_WENT_WRONG } from '../../../../constants/errorMessage';

export interface IChatModelItem {
    id: number;
    uid: string;
    name: string;
    systemContant: string;
};

interface FormData {
    gptData: {
        openAiUid: string;
    }
};

function OpenAi(props: IEditorProps) {
    const businessUid = useAppSelector(state => state.meta.businessUid);
    const openAiListQuery = useQuery({
        queryKey: ['open-ai'],
        queryFn: () => {
            return axios.post<IChatModelItem[]>("openai/getAll", {
                businessUid,
                limit: 10,
                page: 0
            })
        }
    })
    const [formData, setFormData] = useState<FormData>({
        gptData: {
            openAiUid: ''
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'open_ai',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        gptData: yup.object().shape({
            openAiUid: yup.string().required('GPT is required'),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, setValues, setFieldValue, errors, touched, values }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);


                let formInput = <Spinner />

                if (openAiListQuery.isError) {
                    formInput = (
                        <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
                    )
                } else if (!openAiListQuery.isLoading && openAiListQuery.data) {
                    formInput = (
                        <>
                            <Form.Group className='mb-3'>
                                <Form.Label>GPT <span className='required'></span></Form.Label>                                                                                                                                                                                                     
                                <Select
                                    value={values.gptData.openAiUid ? {
                                        value: values.gptData.openAiUid,
                                        label: openAiListQuery.data.data.find(item => item.uid === values.gptData.openAiUid)?.name,
                                        description: openAiListQuery.data.data.find(item => item.uid === values.gptData.openAiUid)?.systemContant,
                                    } : null}
                                    options={openAiListQuery.data.data.map((item: IChatModelItem) => ({
                                        label: item.name,
                                        description: item.systemContant,
                                        value: item.uid
                                    }))}
                                    onChange={selected => {
                                        if (selected) {
                                            setFieldValue('gptData.openAiUid', selected.value);
                                        }
                                    }}
                                />
                                {(touched.gptData?.openAiUid && errors.gptData?.openAiUid) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.gptData?.openAiUid}
                                    </div>
                                ) : null}
                            </Form.Group>
                        </>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Open AI' icon={<img alt='' src={openAiIcon} style={{ width: 37 }} />} />
                        <Offcanvas.Body>
                            {formInput}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default OpenAi;