import React, { useEffect, useState } from "react";
import { Container, Row, Col, Label } from "reactstrap";
import { Field, ErrorMessage, Form } from "formik";
import Select from "react-select";
import { Form as FormBootstrap } from "react-bootstrap";
import "./WhatsAppChannel.scss";
import ChannelDetails from "./ChanelDetails";
import { getComplianceProfile } from "../../services/WhatsAppchannelService";
import { useSelector } from "react-redux";
import { useFormikContext } from 'formik';
import { BusinessTypes } from '../../common/category'
// import { customStyles } from "../../common/react-select-style";

interface complianceProps {
  ComplainceInfoData: (complainceData: any) => void;
  values: any;
}


export function ComplainceInfo(props: complianceProps) {
  const { values } = props
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading,setLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const { setFieldValue } = useFormikContext();
  const channelData = useSelector((state: any) => state.cartreducer.channelUid)
  const channelAllData = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData
  );
  const [complainceData, get_complaince] = useState({
    businessName: '',
    businessType: "",
    customerCareEmail: "",
    customerCareMobilenumber: "",
    customerCareLandline: "",
    grievanceOfficerName: "",
    grievanceOfficeEmail: "",
    grievanceOfficeMobilenumber: "",
    grievanceOfficeLandline: "",
  })

  const compliance_profile = async () => {
    setLoading(true);
    const channelId = channelData?.value;
    if (channelId) {
      const compliance = await getComplianceProfile(channelId);
      if (compliance) {
        get_complaince(compliance);
        setLoading(false);
      }
    }
    props.ComplainceInfoData(complainceData);
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      // width: '80px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      color: 'var(--primary-gray, #324054)',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: 'var(--primary-gray, #324054)',
      '&:hover': {
        color: 'var(--primary-gray, #324054)',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 1,
      width: '240px',
      marginLeft: '-2px',
    }),
  };


  useEffect(() => {
    compliance_profile();
  }, [channelData])

  useEffect(() => {
    if (complainceData) {
      props.ComplainceInfoData(complainceData)
    }
  }, [complainceData]);

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };

  return (
    <Form>
      {isLoading ? 
    <div className="centerItemsWithWidth">
    <div className="spinner-border text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>:
      <div className="compliance-profile-container">
        <Row>
          <div className="personal-details commonComplain">
            <Container>
              <Row>
                <Col sm="" md="" lg="" xl="" className="p-0">
                  <h4>Personal Details</h4>
                </Col>
              </Row>
              <Row>
                <Col sm="" md="2" lg="2" xl="2" className="col-field">
                  <Label>
                    Name <span className="redstar_required"></span>{" "}
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="">
                  <Field
                    placeholder="Enter your name"
                    type="text"
                    name="whatsAppProfilename"
                    maxLength={60}
                    className="form-control"
                    onChange={(event: any) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <ErrorMessage
                    className="text-danger"
                    name="whatsAppProfilename"
                    component="div"
                  />
                </Col>
                { windowWidth < 480 && (    
                  <Col sm='' md='6'>
                  <Label className="content-explain">A Business legal name</Label>
                </Col>
                )}  
                <Col sm="" md="2" lg="2" xl="2" className="col-field">
                  <Label>
                    Business Type
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="">
                  {/* <Field
                    placeholder="Enter profile name"
                    type="text"
                    name="businessType"
                    maxLength={60}
                    className="form-control"
                      onChange={(event: any) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                      }}
                    //   onKeyDown={handleKeyDown}
                  /> */}

                  <Field
                    name="businessType"
                  >
                    {({ field, form }: any) => (
                      <Select
                        {...field}
                        name="businessType"
                        options={
                          BusinessTypes?.map((businessType: any) => ({
                            value: businessType.label,
                            label: businessType.label,
                          })) ?? []
                        }
                        className=''
                        styles={customStyles}
                        value={{ label: values.businessType, value: values.businessType }}
                        onChange={(selectedOption: any) => {
                          // countryBusiness(selectedOption.label);
                          setFieldValue(
                            "businessType",
                            selectedOption.value
                          );
                        }}
                      />
                    )}
                  </Field>
                  {/* <ErrorMessage
                    className="text-danger"
                    name="businessType"
                    component="div"
                  /> */}
                </Col>
              </Row>
              { windowWidth > 480 && (              
              <Row className="p-0">
                <Col sm='' md='6'>
                  <Label className="content-explain">A Business legal name</Label>
                </Col>
              </Row>
              )}
            </Container>
          </div>
          <div className="customer-care-details commonComplain">
            <Container>
              <Row>
                <Col sm="" md="" lg="" xl="" className="col-field">
                  <h4>Customer Care Details</h4>
                </Col>
              </Row>

              <Row>
                <Col sm="" md="2" lg="2" xl="2" className="col-field">
                  <Label>
                    Email <span className="redstar_required"></span>{" "}
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="">
                  <Field
                    placeholder="Enter your email"
                    type="text"
                    name="customerEmail"
                    maxLength={60}
                    className="form-control"
                    onChange={(event: any) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <ErrorMessage
                    className="text-danger"
                    name="customerEmail"
                    component="div"
                  />
                </Col>
                {(windowWidth < 480 &&
                  <Col sm='12' md='6' id="email" className="content-explain">
                    <Label className="content-explain d-block">An email customer can use to reach the business</Label>
                  </Col>
                )}
                <Col sm="" md="2" lg="2" xl="2" className="col-field">
                  <Label>
                    Mobile No
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="channel-mobile-field">
                  <Field type='text' name='countryCode' value='+91' id="mobileNo" className='channel-country-code' disabled />
                  <Field
                    placeholder="Enter your mobile number"
                    type="text"
                    name="customerMobile"
                    maxLength={10}
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      const { name } = e.target;
                      setFieldValue(name, value);
                    }}
                  //   onKeyDown={handleKeyDown}
                  />
                  {/* <ErrorMessage
                    className="text-danger"
                    name="customerMobile"
                    component="div"
                  /> */}
                </Col>
              </Row>
              
                <Row className="p-0 align-items-start">
                {windowWidth > 480 && (
                  <Col sm='12' md='6' id="email" className="content-explain">
                    <Label className="content-explain d-block">An email customer can use to reach the business</Label>
                  </Col> )}
                  <Col sm='12' md='6' className="content-explain">
                    <Label id="mobile" className="content-explain d-block">A mobile phone number customers can use to reach the business</Label>
                  </Col>
                </Row>
           
              <Row>
                <Col sm="" md="2" lg="2" xl="2" className=" col-field">
                  <Label>
                    Landline No
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="channel-mobile-field">
                  <Field type='text' name='countryCode' value='+91' id="mobileNo" className='channel-country-code' disabled />
                  <Field
                    placeholder="Enter your landline no"
                    type="text"
                    name="customerLandline"
                    maxLength={10}
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      const { name } = e.target;
                      setFieldValue(name, value);
                    }}
                  //   onKeyDown={handleKeyDown}
                  />
                  {/* <ErrorMessage
                    className="text-danger"
                    name="customerLandline"
                    component="div"
                  /> */}
                </Col>
              </Row>
              <Row className="p-0">
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">A landline number customers can use to reach the business</Label>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="grievance-officer-details commonComplain">
            <Container>
              <Row>
                <Col sm="" md="" lg="" xl="" className="p-0">
                  <h4>Grievance Officer Details</h4>
                </Col>
              </Row>
              <Row>
                <Col sm="" md="2" lg="2" xl="2" className="col-field">
                  <Label>
                    Name<span className="redstar_required"></span>{" "}
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="">
                  <Field
                    placeholder="Enter your name"
                    type="text"
                    name="grievanceName"
                    maxLength={60}
                    className="form-control"
                    onChange={(event: any) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  <ErrorMessage
                    className="text-danger"
                    name="grievanceName"
                    component="div"
                  />
                </Col>
                {windowWidth < 480 && (
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">The name of the business grievance officer</Label>
                </Col> )}
                <Col sm="" md="2" lg="2" xl="2" className="col-field">
                  <Label>
                    Mobile No
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="channel-mobile-field">
                  <Field type='text' name='countryCode' value='+91' id="mobileNo" className='channel-country-code' disabled />
                  <Field
                    placeholder="Enter your mobile number"
                    type="text"
                    name="grievanceMobile"
                    maxLength={10}
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      const { name } = e.target;
                      setFieldValue(name, value);
                    }}
                  //   onKeyDown={handleKeyDown}
                  />
                  {/* <ErrorMessage
                    className="text-danger"
                    name="grievanceMobile"
                    component="div"
                  /> */}
                </Col>
                { windowWidth < 480 && (
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">A mobile phone number customers can use to reach the business</Label>
                </Col> )}
              </Row>
                {windowWidth > 480 && (
              <Row className="p-0">
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">The name of the business grievance officer</Label>
                </Col> 
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">A landline number customers can use to reach the business</Label>
                </Col>
              </Row>
                )}
              <Row>
                <Col sm="" md="2" lg="2" xl="2" className=" col-field">
                  <Label>
                    Email
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="">
                  <Field
                    placeholder="Enter your email"
                    type="text"
                    name="grievanceEmail"
                    maxLength={60}
                    className="form-control"
                    onChange={(event: any) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                  //   onKeyDown={handleKeyDown}
                  />
                  {/* <ErrorMessage
                    className="text-danger"
                    name="grievanceEmail"
                    component="div"
                  /> */}
                </Col>
                {windowWidth < 480 && (
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">An email customer can use to reach the business</Label>
                </Col>
                )}
                <Col sm="" md="2" lg="2" xl="2" className=" col-field">
                  <Label>
                    Landline No
                    &nbsp;&nbsp;:
                  </Label>
                </Col>
                <Col sm="" md="4" lg="4" xl="4" className="channel-mobile-field">
                  <Field type='text' name='countryCode' id="mobileNo" value='+91' className='channel-country-code' disabled />
                  <Field
                    placeholder="Enter your landline no"
                    type="text"
                    name="grivanceLandline"
                    maxLength={10}
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      const { name } = e.target;
                      setFieldValue(name, value);
                    }}
                  //   onKeyDown={handleKeyDown}
                  />
                  {/* <ErrorMessage
                    className="text-danger"
                    name="grivanceLandline"
                    component="div"
                  /> */}
                </Col>
                {windowWidth < 480 && (
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">A landline number customers can use to reach the business</Label>
                </Col>
                )}
              </Row>
              { windowWidth > 480 && (
              <Row className="p-0">
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">A mobile phone number customers can use to reach the business</Label>
                </Col> 
                <Col sm='' md='6' className="content-explain">
                  <Label className="content-explain">An email customer can use to reach the business</Label>
                </Col>
              </Row>
              )}
            </Container>

          </div>
        </Row>
      </div>
  }
    </Form>
  );
}

export default ComplainceInfo;
