import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

interface Props{
  ticketStatusAndPriority:any;
}


const BarCharts = (props:Props)=>{
  const {ticketStatusAndPriority} = props;
  const DateFormater =(data:any)=>{
    const createdAt = data?.createdAt
    return moment(createdAt).format('DD MMM');
  }


  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={ticketStatusAndPriority}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={DateFormater} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="urgent" name='Urgent' fill="#ff5959" barSize={10}/>
        <Bar dataKey="high" name="High" fill="#ffd012" barSize={10}/>
        <Bar dataKey="medium"name="Medium" fill="#4da1ff" barSize={10} />
        <Bar dataKey="low" name="Low"fill="#a0d76a" barSize={10}/>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarCharts;
