import React, { useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

import styles from './listPageHeader.module.css';

interface SearchBoxProps {
    placeholder?: string;
    id?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    inputRef?: React.Ref<HTMLInputElement>;
};

const SearchBox: React.FC<SearchBoxProps> = props => {
    const defaultInputRef = useRef<HTMLInputElement | null>(null);
    return (
        <InputGroup className={styles.inputgrp}>
            <InputGroup.Text className={styles.searchicon}>
                <BsSearch />
            </InputGroup.Text>
            <Form.Control
                id={props.id}
                value={props.value}
                onClick={props.onClick}
                className={styles.searchinput}
                placeholder={props.placeholder}
                onChange={props.onChange}
                ref={props.inputRef || defaultInputRef}
            />
        </InputGroup>
    );
};

SearchBox.defaultProps = {
    placeholder: 'Search...',
    onClick: () => { },
    inputRef: null,
    id: "seach-input"
};

export default SearchBox;