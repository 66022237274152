import React from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import './facebook.scss';

interface FacebookOnboardingProps {

}

const FacebookOnboarding: React.FC<FacebookOnboardingProps> = () => {
    const handleResponse = (data: any) => {
        console.log(data);
    };

    const handleError = (error: any) => {
        console.error(error);
    };

    return (
        <FacebookProvider appId="642875634705410">
            <div className='fbButton float-right'>
                <LoginButton
                    scope="email"
                    onError={handleError}
                    onSuccess={handleResponse}
                >
                    Onboarding via Facebook
                </LoginButton>
            </div>
        </FacebookProvider>
    );
};

export default FacebookOnboarding;
