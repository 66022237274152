import React, { useState, useEffect, useCallback } from 'react';
import { metaGetAll, metaProduct, metaSync } from '../../services/catalogueService';
import { useSelector } from 'react-redux';
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import { Button } from 'reactstrap';
import './Catalogue.scss';
import BizTable from '../../common/BizTable';
import SearchInput from "../../common/searchComponent";
// import { ErrorImage } from '../../common/ErrorMessage';
import TooltipReference from "../../common/tooltip";

export const ProductLog = () => {
    const [product, setProduct] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const channel :any = useSelector ((state: any) => state.cartreducer.channelUid);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const [counts,setCount] = useState<number>(0);
 
const  columns = [
    {
      Header: 'Image',
      accessor: 'imageUrl',
      Cell: ({ cell, row }: any) => <img src={row?.original?.media?.url} alt="Product"  width='40' height='40'/>
    },
    {
      Header: 'Retailer Id',
      accessor: 'retailerId'
    },
    {
      Header: 'Title',
      accessor: 'name'
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ cell, row }: any) => <p title={cell?.value}>{cell?.value?.length > 60? cell?.value?.slice(0,59)+'...' : cell?.value}</p>
    },
    {
      Header: 'Brand',
      accessor: 'brand'
    },
    {
      Header: 'Availability',
      accessor: 'availability'
    },
    // {
    //   Header: 'Collections',
    //   accessor: 'productSets',
    //   Cell: ({ cell, row }: any) => <div className='d-flex'><p className='collectionBtn mr-1'>{row.values?.productSets?.data[0]?.name} </p><p className='collectionBtn w-25'>{row.values?.productSets?.data?.length -1 + '+'}</p></div>
    // },
    {
      Header: 'Collections',
      accessor: 'productSets',
      Cell: ({ cell, row }: any) => {
        const productSetsData = row.values?.productSets?.data;
        const length = productSetsData?.length;
        const names = productSetsData?.map((item: any) => item.name).join(', ');
    
        return (
          <div className='d-flex'>
            <p className='collectionBtn mr-1'>{productSetsData[0]?.name}</p>
            {length > 1 && (
              <TooltipReference
              placement="bottom"
              tooltipId={`id`}
              content={names}
            >
            <p className='collectionBtn'>{`${length - 1}+`}</p>
            </TooltipReference>            
            )}
          </div>
        );
      }
    },    
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ cell, row }: any) => <p className='nofont mt-2'>{cell.value ? cell.value : '-'}</p>
    }
  ] as any;
  

    const fetchData = useCallback(
      async (
        pageIndex: number,
        pageSize: number,
        searchText: string,
        currentPage: number
      ): Promise<{ data: any; totalPages: number }> => {
        const listPayload = {
          channelId: channel?.value,
          page: searchTerm ? 0 : currentPage,
          limit: pageSize,
          search: searchTerm,
        };
  
        const response = await  metaProduct(listPayload);
         // const response = await ContactsGetAll(listPayload as any);
        // const response = await  metaGetAll(channel?.value);
        if (response) {
          const {catalogueProduct ,count} = response.data;
          const countData = searchTerm ? catalogueProduct?.length : count
          setProduct(catalogueProduct);
          setCount(countData)
          setIsLoading(false);  
          return { data: catalogueProduct, totalPages: 100 };
        } else {
          return { data: [], totalPages: 0 };
        }
      },
      [channel?.value,searchTerm]
    );

    const onSearchChange = (searchTerm: string) => {
      const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
      setSearchTerm(searchTermCheck);
    };
  
    // const getProduct = async () => {
    //     setIsLoading(true);
    //     try {
    //       const response = await  metaProduct(channel?.value);
    //       const data = response.data;
    //       setProduct(data);
    //       setIsLoading(false);
    //     } catch (error) {
    //       console.error('Error:', error); 
    //       setIsLoading(false);
    //     }
    // };

    // useEffect(() => {   
    //     getProduct();
    // }, []);

    return(
        <div>
             {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
          <div className='row pt-3 pb-2'>
            <div className='col-md-3'>
          <SearchInput
          component="community"
          onSearchChange={onSearchChange}
          placeHolder={"Search Products"}
        />
        </div>
        </div>
          <BizTable
          columns={columns}
          fetchData={fetchData}
          counts={counts}
          refetchData={product}
          tableName='PRODUCTLOG'
        />
        </>
            // <BootstrapTable data={product}
            // search
            // pagination={product?.length > 10}
            // version="4"
            // striped
            // hover
            // containerStyle={{ textAlign: "left" }}>
            //     <TableHeaderColumn dataField="id" isKey hidden> # </TableHeaderColumn>
            //     <TableHeaderColumn dataField="imageUrl" dataFormat={(cell, row) => <img src={cell} alt="Product"  width='40' height='40'/>} ><b>Image</b></TableHeaderColumn>
            //     <TableHeaderColumn  dataField="retailerId" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Retailer Id</b></TableHeaderColumn>
            //     <TableHeaderColumn  dataField="name" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Title</b></TableHeaderColumn>
            //     <TableHeaderColumn  dataField="description" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Description</b></TableHeaderColumn>
            //     <TableHeaderColumn  dataField="brand"  columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Brand</b></TableHeaderColumn>
            //     <TableHeaderColumn  dataField="availability" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Availability</b></TableHeaderColumn>
            //     <TableHeaderColumn  dataField="price" columnClassName="text-wrap" dataFormat={(cell, row) => cell ? cell : '-'}><b>Price</b></TableHeaderColumn>
            // </BootstrapTable>
        )}
        </div>
    )
}
