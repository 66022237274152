import { FaTags } from 'react-icons/fa';
import {Tooltip, OverlayTrigger } from 'react-bootstrap';
import Node from '../node';

function UpdateContactFields(props: any) {
    const renderTooltip = (props :any) => (
        <Tooltip id="button-tooltip" {...props}
        style={{...props.style }}
        >{props.data.text}
        </Tooltip>
    );
    let content = null;
    if (props.data.contactUpdateType === "update_contactName") {
        content = (
            <>
                Contact Name will be replaced with {props.data.contact.name? (
            <OverlayTrigger
            placement="bottom-end"
            overlay={renderTooltip({ data: { text: props.data.contact.name} })}
        >
            <div style={{ cursor: 'pointer' }}>
                "{props.data.contact.name.substring(0, 20)}
                {props.data.contact.name.length > 20 ? '...' : null}"
            </div>
        </OverlayTrigger>
        ) : null}
            </>
        );
    }

    if (props.data.contactUpdateType === "update_contactEmail") {
        content = (
            <>
                Contact Email will be replaced with {props.data.contact.email? (
            <OverlayTrigger
            placement="bottom-end"
            overlay={renderTooltip({ data: { text: props.data.contact.email} })}
        >
            <div style={{ cursor: 'pointer' }}>
                "{props.data.contact.email.substring(0, 20)}
                {props.data.contact.email.length > 20 ? '...' : null}"
            </div>
        </OverlayTrigger>
        ) : null}
            </>
        );
    }

    if (props.data.contactUpdateType === "update_contactOwner") {
        content = (
            <>
                Contact Owner is updated
            </>
        );
    }

    if (props.data.contactUpdateType === "update_contactMarketOptIn") {
        content = (
            <>
                Contact Marketing OptIn is set to {props.data.contact.optState ? "Yes" : "No"}
            </>
        );
    }

    if (props.data.contactUpdateType === "update_contactTags") {
        content = (
            <>
                <div>
                    Contact Tags will be {props.data.actionStatus === 'addIfEmpty' ? "Added if empty" : props.data.actionStatus.charAt(0).toUpperCase() + props.data.actionStatus.slice(1) + "ed"}
                </div>
                {props.data.contact.tags.length > 0 ? (
                    <div>
                        <FaTags size={8} className='mr-2' />
                        {props.data.contact.tags.map((tag: any) => `${tag.name}, `).join('').slice(0, -2)}
                    </div>
                ) : null}
            </>
        );
    }

    return (
        <Node {...props} label='Update Contact Fields' content={content} />
    );
}

export default UpdateContactFields;