import React from "react";

export interface ConversationSendMessageContextState {
    sendMessage: (msg?:any) => void
    setIsSelectingTemplate: (val: boolean) => void
};

const conversationSendMessageContext = React.createContext<ConversationSendMessageContextState>({
    sendMessage() { },
    setIsSelectingTemplate(_) { }
});

export default conversationSendMessageContext;