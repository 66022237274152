import { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import * as botService from '../services/bots';
import CreateBot from '../components/bots/createBot';
import BotsList from '../components/bots/botsList';
import ListPageHeader from '../components/ui/listPageHeader';
import ErrorFallback from '../components/ui/errorFallback';
import Loader from '../../shade/Loaders/smallLoader';
import { useSelector } from "react-redux";
import initialContext from '../components/designFlow/editor/useContext';
import { useAppSelector } from '../hooks';
import { TabContent, TabPane } from 'reactstrap';
import BotLibrary from './botLibrary';

function BotsPage() {
    const [showCreate, setShowCreate] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const initial = useContext(initialContext)
    const business: any = useSelector((state: any) => state.meta.profile);
    const channelUid: string = useSelector((state: any) => state.meta.channelUid);
    const [botCount, setBotCount] = useState(0);
    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const roleRules = useAppSelector((state: any) => state.meta.roleRules);
    const [activeTab, setactiveTab] = useState('myBots');
    const [libraryBots, setlibraryBots] = useState([]);

    useEffect(() => {
        if (roleRules) {
            const isInvalidAccess = roleRules.canViewBots === true && roleRules.canManageBots === false;
            setIsInvalidAccess(isInvalidAccess);
        }
    }, [roleRules]);


    const query = useQuery({
        queryKey: ['bots'],
        queryFn: () => botService.getBots()
    });
    const [botSearch, setBotSearch] = useState(null);
    const [botList, setBotList] = useState([]);

    const searchBot = (searchTerm: any) => {
        const searchTermCheck = searchTerm.length > 0 ? searchTerm.toLowerCase() : null;

        if (query.data) {
            const sort = query?.data?.bots?.filter((bot: any) => {
                return bot.name.toLowerCase().includes(searchTermCheck);
                //  ||
                //    contact.mobileNumber.toString().toLowerCase().includes(searchTermCheck);
            });

            setBotSearch(searchTermCheck);
            setBotList(sort);
            setSearchQuery(sort);
            // this.setState({ searchExcel: searchTermCheck, ContactLists: sort });
        } else {
            setBotSearch(null);
            setBotList(query.data.bots)
            setSearchQuery(query.data.bots);
            // this.setState({ searchExcel: null, ContactLists: excelContactList });
        }

    }
    useEffect(() => {
        if (searchInput) {
            const timer = setTimeout(() => {
                searchBot(searchInput);
            }, 500);
            return () => clearTimeout(timer);
        }
        if (query.data) {
            setBotList(query.data.bots);
            setBotCount(query.data.count);
        }
    }, [searchInput, query.data]);

    useEffect(() => {
        query.refetch()
    }, [channelUid])

    useEffect(() => {
        if (botList?.length === 0) {
            initial.isLoading = true;
        }
        else {
            initial.isLoading = false;
        }
    }, [botList]);

    useEffect(() => {
        getLibraryBots();
    }, [])

    const getLibraryBots = async () => {
        const bots = await botService.getBotsForBotLibrary();
        if (bots)
            setlibraryBots(bots);
        else
            setlibraryBots([]);
    }

    if (query.isLoading) {
        return (
            <Loader />
        )
    } else if (query.isError) {
        return (
            <Container>
                <ErrorFallback />
            </Container>
        )
    }

    const handleTabChange = (tab: string) => {
        setactiveTab(tab);
    }

    return (
        <Container>
            {query.isFetched ? (
                <>
                    <CreateBot
                        show={showCreate}
                        onHide={() => setShowCreate(false)}
                        onCreate={() => query.refetch()}
                    />
                    <ListPageHeader
                        title='My Bots'
                        createLabel='Create New Bot'
                        placeholder='Search Bot'
                        onCreate={() => setShowCreate(true)}
                        // action={<Link to='#'>ChatBot Library</Link>}
                        value={searchInput}
                        onChange={(e: any) => setSearchInput(e.target.value)}
                        business={business?.business}
                        data={query.data.bots}
                        botCount={botCount}
                        isInvalidAccess={isInvalidAccess}
                        activeTab={activeTab}
                        handleTabChange={handleTabChange}
                    />
                    <TabContent activeTab={activeTab} className="custom-tab-content">
                        <TabPane tabId="myBots">
                            <BotsList
                                bots={botList}
                                refetch={query.refetch}
                                isLoading={initial.isLoading}
                                isInvalidAccess={isInvalidAccess}
                            />
                        </TabPane>
                        <TabPane tabId="botLibrary">
                            <BotLibrary bots={libraryBots} allBots={botList} refetch={query.refetch} isInvalidAccess={isInvalidAccess} />
                        </TabPane>
                    </TabContent>
                </>
            ) : null}
        </Container>
    );
}

export default BotsPage;