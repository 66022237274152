import { useEffect, useRef, useState } from "react";
import * as appointmentService from "../../../services/appointmentService";
import { extendMoment } from "moment-range";
import * as originalMoment from "moment";
import getAdjustedDateRange from "../../../utils/getAdjustedDateRange";
import { useSelector } from "react-redux";
import { Container, Input, Spinner } from "reactstrap";
import styles from "./Dashboard.module.scss";
import {
  faCancel,
  faCheck,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BsCalendar } from "react-icons/bs";
import { Overlay, Popover } from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import downloadExcelforDashboard from "./ExcelforDashboard";
import downloadExcel from "../downloadExcel";
import excelIcon from "../../../assets/img/excel.png";
import { ErrorImage } from "../../../common/ErrorMessage";
import { PieChart, Pie, Sector, Cell } from "recharts";

enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}

const moment = extendMoment(originalMoment);

const Dashboard = () => {
  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const today = moment().clone().startOf("day");
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );
  const [isValueSelected, setIsValueSelected] = useState(true);
  const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(
    dateRangeValue.start,
    dateRangeValue.end
  );

  const datepickerTarget = useRef<HTMLDivElement | null>(null);
  const userProfile = useSelector((state: any) => state.cartreducer.business);
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const businessName = useSelector(
    (state: any) => state?.cartreducer?.channelUid.label
  );
  const datepickertarget = useRef(null);
  const [member, setmember] = useState("all");
  const [memberName, setmemberName] = useState(userProfile?.name);
  const [allMember, setAllMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentsTotal, setAppointmentsTotal] = useState([
    {
      id: 1,
      label: "Total",
      icon: "",
      value: 0,
    },
    {
      id: 2,
      label: "Pending",
      icon: faWarning,
      value: 0,
    },
    {
      id: 3,
      label: "Completed",
      icon: faCheck,
      value: 0,
    },
    {
      id: 4,
      label: "Cancelled",
      icon: faCancel,
      value: 0,
    },
  ]);

  const [isPiChartHovering, setisPiChartHovering] = useState(false);
  const [piChartHover, setPiChartHover] = useState(0); // Add pie chart hover state

  // Pie chart data and configuration
  const piChartData = [
    { name: "Pending", value: appointmentsTotal[1].value },
    { name: "Cancelled", value: appointmentsTotal[3].value },
    { name: "Completed", value: appointmentsTotal[2].value },
  ];

  const piChartColours = ["#FB8C00", "#dc3545", "#28a745"];

  const changeDateFilterHandler = (event: any) => {
    const getStartDate = (
      subtractDays: number,
      unit: moment.unitOfTime.DurationConstructor = "days"
    ) => {
      return today.clone().subtract(subtractDays, unit).startOf(unit);
    };

    const filter = event.target.value as DateFilterType;

    let newDateRange;
    switch (filter) {
      case DateFilterType.Past7Days:
        newDateRange = moment.range(
          today.clone().subtract(7, "days").startOf("day"),
          today.endOf("day")
        );
        break;

      case DateFilterType.Past30Days:
        newDateRange = moment.range(getStartDate(30), today.endOf("day"));
        break;

      case DateFilterType.Past90Days:
        newDateRange = moment.range(getStartDate(90), today.endOf("day"));
        break;

      case DateFilterType.ThisWeek:
        newDateRange = moment.range(
          today.clone().startOf("week"),
          today.endOf("day")
        );
        break;

      case DateFilterType.PreviousWeek:
        newDateRange = moment.range(
          getStartDate(1, "week"),
          getStartDate(1, "week").endOf("week")
        );
        break;

      case DateFilterType.ThisMonth:
        newDateRange = moment.range(
          today.clone().startOf("month"),
          today.endOf("day")
        );
        break;

      case DateFilterType.PreviousMonth:
        newDateRange = moment.range(
          getStartDate(1, "month"),
          getStartDate(1, "month").endOf("month")
        );
        break;

      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        return;

      default:
        return;
    }

    setDateRangeValue(newDateRange); // Update date range
    setDateFilter(filter); // Update filter type
  };

  // Fetch new data when the date range changes
  useEffect(() => {
    if (member === "all") {
      setIsValueSelected(true);
    } else {
      getDataBasedOnMember(member);
    }
  }, [dateRangeValue, member]);

  useEffect(() => {
    // this effect is for the dashboard option "All"
    if (isValueSelected) {
      setIsLoading(true);
      appointmentService
        .getAppointmentAnalyticsBasedonBusiness(
          businessUid,
          adjustedStart.format("YYYY-MM-DD"),
          adjustedEnd.format("YYYY-MM-DD"),
          "business"
        )
        .then((res: any) => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
          const updatedAppointmentsTotal = appointmentsTotal.map(
            (appointment) => {
              switch (appointment.label) {
                case "Total":
                  return { ...appointment, value: res.appointmentCount };
                case "Pending":
                  return {
                    ...appointment,
                    value: res.totalPendingAppointments,
                  };
                case "Completed":
                  return {
                    ...appointment,
                    value: res.totalCompletedAppointments,
                  };
                case "Cancelled":
                  return {
                    ...appointment,
                    value: res.totalCancelledAppointments,
                  };
                default:
                  return appointment;
              }
            }
          );

          setAppointmentsTotal(updatedAppointmentsTotal);
        });
    }
  }, [isValueSelected]);

  useEffect(() => {
    if (isValueSelected) {
      setTimeout(() => {
        setIsValueSelected(false);
      }, 1000);
    }
  }, [isValueSelected]);

  const getDataBasedOnMember = (memUid: string) => {
    setIsLoading(true);
    appointmentService
      .getAppointmentAnalytics(
        memUid,
        adjustedStart.format("YYYY-MM-DD"),
        adjustedEnd.format("YYYY-MM-DD")
      )
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        const updatedAppointmentsTotal = appointmentsTotal.map(
          (appointment) => {
            switch (appointment.label) {
              case "Total":
                return { ...appointment, value: res.appointmentCount };
              case "Pending":
                return {
                  ...appointment,
                  value: res.totalPendingAppointments,
                };
              case "Completed":
                return {
                  ...appointment,
                  value: res.totalCompletedAppointments,
                };
              case "Cancelled":
                return {
                  ...appointment,
                  value: res.totalCancelledAppointments,
                };
              default:
                return appointment;
            }
          }
        );

        setAppointmentsTotal(updatedAppointmentsTotal);
      });
  };

  const changeMemberFilter = (event: any) => {
    const target = event.target.value;
    if (target === "all") {
      setmember("all");
      setIsValueSelected(true);
    } else {
      setmember(target);
      setmemberName(event.target.options[event.target.selectedIndex].text);
    }
  };

  const handleDownload = async () => {
    const appointmentBasedonBusiness =
      await appointmentService.getAppointmentBasedonBusiness(
        businessUid,
        adjustedStart.format("YYYY-MM-DD"),
        adjustedEnd.format("YYYY-MM-DD")
      );

    downloadExcelforDashboard(
      appointmentBasedonBusiness,
      userProfile.name
    );
  };

  const handleMemberDownload = async () => {
    const appointmentBasedonBusiness =
      await appointmentService.getAppointmentBasedonBusiness(
        businessUid,
        adjustedStart.format("YYYY-MM-DD"),
        adjustedEnd.format("YYYY-MM-DD")
      );
    downloadExcel(
      appointmentBasedonBusiness.filter(
        (data: any) => data.memberUid === member
      ),
      memberName
    );
  };

  useEffect(() => {
    appointmentService.getAllMemberConfiguration(businessUid).then((res) => {
      setAllMember(res);
    });
  }, [businessUid]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datepickerTarget.current &&
        !datepickerTarget.current.contains(event.target as Node)
      ) {
        setShowDatePicker(false);
      }
    };
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]);

  const totalValue = appointmentsTotal.reduce((acc, obj) => acc + obj.value, 0);

  return (
    <Container>
      <h3 className={styles.caption}>{businessName}-Appointments</h3>

      <div className={styles.analyticsPanel}>
        <div className={styles.numbers}>
          {appointmentsTotal.map((el) => {
            return (
              <div className={styles.numberItem} key={el.label}>
                <div className={styles.numberCount}>
                  {isLoading ? (
                    <>
                      <Spinner color="success">Loading...</Spinner>
                    </>
                  ) : (
                    el.value
                  )}
                </div>
                <div className={styles.numberLabel}>
                  <div className={el.icon ? "me-2" : ""}>
                    {<FontAwesomeIcon icon={el.icon as IconProp} />}
                  </div>
                  <div>{el.label}</div>
                </div>
              </div>
            );
          })}
        </div>

        {(userProfile.role.type !== "MEMBER" && totalValue > 0) && (
          <img
            src={excelIcon}
            alt=""
            className="me-3"
            onClick={member === "all" ? handleDownload : handleMemberDownload}
            style={{ cursor: "pointer" }}
            title="Download excel"
            width={30}
            height={30}
          />
        )}

        <div className="d-flex">
          <div className={styles.daterange}>
            {userProfile.role.type !== "MEMBER" && (
              <Input
                className={styles.dateFilter}
                type="select"
                size={1}
                onChange={changeMemberFilter}
              >
                <option value="all">All</option>
                {allMember?.map((item: any, index: any) => (
                  <option key={index} value={item.memberUid}>
                    {item?.memberName.substring(0, 17)}
                  </option>
                ))}
              </Input>
            )}

            <Input
              className={styles.dateFilter}
              type="select"
              size={1}
              value={dateFilter}
              onChange={changeDateFilterHandler}
            >
              <option value={DateFilterType.Past7Days}>Past 7 days</option>
              <option value={DateFilterType.Past30Days}>Past 30 days</option>
              <option value={DateFilterType.Past90Days}>Past 90 days</option>
              <option value={DateFilterType.ThisWeek}>This week</option>
              <option value={DateFilterType.PreviousWeek}>Previous week</option>
              <option value={DateFilterType.ThisMonth}>This month</option>
              <option value={DateFilterType.PreviousMonth}>
                Previous month
              </option>
              <option value={DateFilterType.CustomRange}>Custom range</option>
            </Input>
          </div>

          <div
            className={"form-control " + styles.calenderInput}
            ref={datepickertarget}
            onClick={() => setShowDatePicker(!showDatePicker)}
            style={{ width: "18rem" }}
          >
            <BsCalendar />
            <span className="value ml-2">
              {dateRangeValue.start.format("DD/MM/YYYY")}
              {" - "}
              {dateRangeValue.end.format("DD/MM/YYYY")}
            </span>
          </div>
          <Overlay
            target={datepickertarget.current}
            show={showDatePicker}
            placement="bottom"
            ref={datepickerTarget}
          >
            <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
              <DateRangePicker
                value={dateRangeValue}
                onSelect={(value: any) => {
                  const startDate = moment(value.start).isAfter(moment())
                    ? moment()
                    : moment(value.start);
                  const endDate = moment(value.end).isAfter(moment())
                    ? moment()
                    : moment(value.end);

                  setDateFilter(DateFilterType.CustomRange);

                  if (member === "all") {
                    setmember("all");
                    setDateRangeValue(
                      moment.range(
                        startDate.startOf("day"),
                        endDate.endOf("day")
                      )
                    );
                    // setIsValueSelected(true);
                  } else {
                    setmember(member);
                    setDateRangeValue(
                      moment.range(
                        startDate.startOf("day"),
                        endDate.endOf("day")
                      )
                    );
                  }

                  setShowDatePicker(false);
                }}
                singleDateRange={true}
                maximumDate={moment()} // This disables future dates
              />
            </Popover>
          </Overlay>
        </div>
      </div>

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center w-90"
          style={{ height: "300px" }}
        >
          <Spinner color="success">Loading...</Spinner>
        </div>
      ) : (
        <>
          {totalValue > 0 ? (
            <div className="d-flex justify-content-center align-items-center w-90">
              <PieChart width={420} height={400} style={{ width: "700px" }}>
                <Pie
                  data={piChartData}
                  cx={200}
                  cy={200}
                  innerRadius={70}
                  outerRadius={100}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  activeIndex={piChartHover}
                  onMouseEnter={(_, index) => {
                    setPiChartHover(index);
                    setisPiChartHovering(true);
                  }}
                  onMouseOut={() => {
                    setPiChartHover(0);
                    setisPiChartHovering(false);
                  }}
                  activeShape={(props: any) => {
                    const RADIAN = Math.PI / 180;
                    const {
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      startAngle,
                      endAngle,
                      fill,
                      percent,
                      payload,
                      value,
                    } = props;

                    const sin = Math.sin(-RADIAN * midAngle);
                    const cos = Math.cos(-RADIAN * midAngle);
                    const sx = cx + (outerRadius + 10) * cos;
                    const sy = cy + (outerRadius + 10) * sin;
                    const mx = cx + (outerRadius + 30) * cos;
                    const my = cy + (outerRadius + 30) * sin;
                    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                    const ey = my;
                    const textAnchor = cos >= 0 ? "start" : "end";

                    return (
                      <g>
                        <text
                          x={cx}
                          y={cy}
                          dy={8}
                          fontSize={"35"}
                          fontWeight="600"
                          textAnchor="middle"
                          fill="#00b598"
                        >
                          {appointmentsTotal[0].value}
                        </text>
                        <text
                          x={cx}
                          y={cy + 25}
                          dy={8}
                          fontSize={"16"}
                          fontWeight="400"
                          textAnchor="middle"
                          fill="#959595"
                        >
                          Total
                        </text>
                        <Sector
                          cx={cx}
                          cy={cy}
                          innerRadius={innerRadius}
                          outerRadius={outerRadius}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          fill={fill}
                        />
                        {isPiChartHovering ? (
                          <>
                            <Sector
                              cx={cx}
                              cy={cy}
                              startAngle={startAngle}
                              endAngle={endAngle}
                              innerRadius={outerRadius + 6}
                              outerRadius={outerRadius + 10}
                              fill={fill}
                            />
                            <path
                              d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                              stroke={fill}
                              fill="none"
                            />
                            <circle
                              cx={ex}
                              cy={ey}
                              r={2}
                              fill={fill}
                              stroke="none"
                            />
                            <text
                              x={ex + (cos >= 0 ? 1 : -1) * 12}
                              y={ey}
                              textAnchor={textAnchor}
                              fill="#333"
                              fontSize={14}
                            >
                              {payload.name}
                            </text>
                            <text
                              x={ex + (cos >= 0 ? 1 : -1) * 12}
                              y={ey}
                              dy={18}
                              textAnchor={textAnchor}
                              fill="#999"
                              fontSize={12}
                            >
                              {`(${(percent * 100).toFixed(2)}%)`}
                            </text>
                          </>
                        ) : null}
                      </g>
                    );
                  }}
                >
                  {piChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={piChartColours[index % piChartColours.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
          ) : (
            <ErrorImage />
          )}
        </>
      )}
    </Container>
  );
};

export default Dashboard;
