import React, { useState } from 'react';


interface ContextFunctions {
    setConversation(contactUid: string, conversation: any[]): void;
    clearConversation(): void;
}

interface SelectedContextData {
    selected: true;
    contactUid: string;
    conversation: any[];
}

interface UnselectedContextData {
    selected: false;
    contactUid: null;
    conversation: null;
}

type OpenSalesContextData = SelectedContextData | UnselectedContextData;
type OpenSalesFullContextData = (SelectedContextData | UnselectedContextData) & ContextFunctions;

export const openSalesConversationContext = React.createContext<OpenSalesFullContextData>({
    selected: false,
    conversation: null,
    contactUid: null,
    setConversation() { },
    clearConversation() { },
});

const OpenSalesConversationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [data, setData] = useState<OpenSalesContextData>({
        selected: false,
        contactUid: null,
        conversation: null
    });

    const setConversationHandler = (contactUid: string, conversation: any[]) => {
        setData({
            selected: true,
            contactUid,
            conversation
        });
    };

    const clearConversationHandler = () => {
        setData({
            selected: false,
            contactUid: null,
            conversation: null
        });
    }

    return (
        <openSalesConversationContext.Provider value={{
            ...data,
            setConversation: setConversationHandler,
            clearConversation: clearConversationHandler
        }}>
            {children}
        </openSalesConversationContext.Provider>
    );
};

export default OpenSalesConversationContextProvider;