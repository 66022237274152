import axios from "../utils/axios";
import { showAlert, toast, sweetalert } from "../common/alert";

export const getAllSequence = async (channelUid:string) => {
  try {

    const response = await axios.get(
      `sequence/${channelUid}/getAllSequence`
    );
    return response.data;
  } catch (error: any) {
    
  }
};


export const getOverallSequenceFlow = async (sequenceUpdate:any,channelUid:string) => {
  try {
    const response = await axios.post(
      `/sequence/${channelUid}/getOverallSequence`,sequenceUpdate
    );

    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const createWholeSequence = async (createPayload:any,channelUid:string) => {
  try {
    const response = await axios.post(
      `/sequence/${channelUid}/createWholeSequence`,createPayload
    );
    if(response){
      toast("success", "Sequence created successfully");
      return response.data;
    }
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const updateWholeSequence = async (updatePayload:any,channelUid:string) => {
  try {
    const response = await axios.post(
      `/sequence/${channelUid}/updateWholeSequence`,updatePayload
    );
    if(response){
      toast("success", "Sequence updated successfully");
      return response.data;
    }

  } catch (error: any) {
    toast("error", error.message);
  }
};

export const deleteSequenceFlow = async (sequenceFlowUid:string) => {
  try {
    const response = await axios.delete(
      `/sequence/${sequenceFlowUid}/deleteSequenceFlow`
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};


export const deleteSequence = async (sequenceUid:string) => {
  try {
    const response = await axios.delete(
      `/sequence/${sequenceUid}/deleteSequence`
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};


export const getSequenceLogs = async (sequenceUid:string,logsPayload:object) => {
  try {
    const response = await axios.post(
      `/sequence/${sequenceUid}/getSequenceLogs`,logsPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getSequenceLogsDashboard = async(sequenceUid:string,logsPayload:object)=>{
  try{
    const response = await axios.post(`/sequence/${sequenceUid}/getSequenceDashBord`,logsPayload)
    return response.data;
  }
  catch(error:any){

  }
}

export const getSequenceLogsV2 = async (sequenceUid:string,logsPayload:object) => {
  try {
    const response = await axios.post(
      `/sequence/${sequenceUid}/getSequenceLogsv2`,logsPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};


export const dropSequence = async (sequenceUid:string,dropPayload:any) => {
  try {
    const response = await axios.post(
      `/sequence/${sequenceUid}/dropSequence`,dropPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const updateSequenceStatus = async (updatePayload:any,channelUid:string) => {
  try {
    const response = await axios.post(
      `/sequence/${channelUid}/updateSequence`,updatePayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getAllBot = async (botPayload:any) => {
  try {
    const response = await axios.post(
      `bot/getAll`,botPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};