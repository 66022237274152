import Node from '../node';

function AddNotes(props: any) {
  

  const markdownToHTML = (markdownText: any) => {

    let htmlText = markdownText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    htmlText = htmlText.replace(/_(.*?)_/g, '<em>$1</em>');
    htmlText = htmlText.replace(/~(.*?)~/g, '<s>$1</s>');
    return htmlText;
  };

  let convertedMessage = markdownToHTML(props.data.text);

  return (
    <Node {...props} label='Add Notes' content={convertedMessage ? (
      <div style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: convertedMessage.substring(0, 50) + (convertedMessage.length > 50 ? '...' : '') }} />
    ) : null} />
  );
}

export default AddNotes;