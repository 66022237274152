import axios from "../utils/axios";
import { showAlert, toast } from "../common/alert";
import Swal from "sweetalert2";

export const showBusinessProfile = async (updatedBusinessData: any) => {
  try {
    const response = await axios.get(
      `business/account/${updatedBusinessData.uid}`
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const setBusinessProfileData = async (updatedBusinessData: any) => {
  try {
    const response = await axios.patch(
      `business/account/${updatedBusinessData.uid}`,
      updatedBusinessData
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const profilePicturePath = async (profileMedia: any, fileData: any) => {
  try {
    const formData = new FormData();
    formData.append("file", fileData); 
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };


    const mediaPayload = {
      businessUid: profileMedia[0],
      profile: profileMedia[1],
      file: fileData,
      ...(profileMedia[2] && {
        memberUid: profileMedia[2],
      }),
    };
    
    const response = await axios.post(
      `media/saveMediaBasedOnRole`,
      mediaPayload,
      config
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const memberUpdate = async (businessMedia: any) => {
  try {
    const memberDatas = await axios.patch(
      `member/${businessMedia.uid}/update`,
      businessMedia
    );
    return memberDatas.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const getProfileBusiness = async (businessUid: any) => {
  try {
    const member = await axios.get(`member/${businessUid}`);
    return member.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};
