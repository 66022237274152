import React, { createContext, useState, ReactNode } from "react";

interface UploadContextProps {
    filesDragDrop: File[];
    caption: string;
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    setCaption: React.Dispatch<React.SetStateAction<string>>;
    clearUploadData: () => void;
}

export const UploadContext = createContext<UploadContextProps | undefined>(undefined);

export const UploadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [filesDragDrop, setFiles] = useState<File[]>([]);
    const [caption, setCaption] = useState("");

    const clearUploadData = () => {
        setFiles([]);
        setCaption("");
    };

    return (
        <UploadContext.Provider
            value={{
                filesDragDrop,
                setFiles,
                caption,
                setCaption,
                clearUploadData,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};
