import Node from '../node';

function UnassignConversation(props: any) {
    return (
        <Node {...props} label='Unassign Conversation' content={(
            <>
                <i className="fa fa-info-circle" style={{ marginRight: 6 }} />
                The conversation will be moved to the Unassigned Tab
            </>
        )} />
    );
}

export default UnassignConversation;