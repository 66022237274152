import Node from '../node';

function ResolveConversation(props: any) {
    return (
        <Node {...props} label='Resolve Conversation' content={(
            <>
                <i className="fa fa-info-circle" style={{ marginRight: 6 }} />
                The conversation will be marked as resolved
            </>
        )} />
    );
}

export default ResolveConversation;