import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Input,
  PopoverBody,
  Label,
} from "reactstrap";
import "./Analytics.scss";
import { BsCalendar } from "react-icons/bs";
import { Overlay, Popover } from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import { PiInfoLight } from "react-icons/pi";
import TooltipReference from "../../common/tooltip";
import MyBarChart from "./MyBarChart";
import { getAnalyticsDashboard } from "../../services/AnalyticsService";
import { useSelector } from "react-redux";
import ContactChart from "./ContactChart";
import ConvChart from "./ConversationChart";
import StatusChart from "./MessageStatus";
import noData from "../../assets/img/noData.png";

const moment = extendMoment(originalMoment);

enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}

// Define the desired order of keys and their display names
const keyDisplayNameMap: Record<string, string> = {
  // totalConversationCount: "Total Conversations",
  openConversationCount: "New Conversations",
  pendingConversationCount: "Pending Conversations",
  qualifiedConversationCount: "Qualified Conversations",
  // componentTemplateMessageCount: "Whatsapp Template msgs",
};

const countColor = [
  "#ff9933",
  "#4aa6fc",
  "#3364fa",
  "#21a144",
  "#21a144",
  "#21a144",
  "#3364fa",
  "#4aa6fc",
  "#3364fa",
  "#4aa6fc",
  "#4aa6fc",
  "#4aa6fc",
];
interface CardInfoProps {
  title: string;
  dataKey: any;
  index: number;
  tooltipContent: string;
  tooltipId: string;
  // This ensures the dataKey is a valid key of DashboardData
}
const tooltipReference = [
  "Count of New Conversations",
  "Count of Pending Conversations",
  "Count of Qualified Conversations",
  "Count of qualified conversations",
  // "Count of WA Template Messages initiated",
];

const AnalyticsDashboard = () => {
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );
  const currentDate = new Date();
  const today = moment().clone().startOf("day");
  const [backendData, setBackendData] = useState<any>({});
  // console.log(backendData, 'benddata');

  const datepickertarget = useRef(null);

  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );
  const [cardCountLoader, setCardCountLoader] = useState(false);

  const getStartDate = (
    subtractDays: number,
    unit: moment.unitOfTime.DurationConstructor = "days"
  ) => {
    return today.clone().subtract(subtractDays, unit).startOf(unit);
  };

  const changeDateFilterHandler = (event: any) => {
    const filter = event.target.value as DateFilterType;

    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(
          moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisWeek:
        setDateRangeValue(
          moment.range(today.clone().startOf("week"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousWeek:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "week"),
            getStartDate(1, "week").endOf("week")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisMonth:
        setDateRangeValue(
          moment.range(today.clone().startOf("month"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousMonth:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;

      default:
        break;
    }
    setDateFilter(filter);
  };

  const dashBoard = async () => {
    setCardCountLoader(true);
    setIsLoading(true);
    const dashBoardResponse = await getAnalyticsDashboard(
      dateRangeValue.start.valueOf(),
      dateRangeValue.end.valueOf(),
      channelUid
    );
    setBackendData(dashBoardResponse);
    setIsLoading(false);
    setCardCountLoader(false);
  };

  useEffect(() => {
    dashBoard();
  }, [dateFilter, dateRangeValue, channelUid]);

  // console.log(backendData, 'panther');

  // Filter status list to contain only the desired keys
  const desiredOrder: Array<keyof typeof backendData.statusList> = [
    // "totalConversationCount",
    "openConversationCount",
    "pendingConversationCount",
    "qualifiedConversationCount",
    // "componentTemplateMessageCount",
  ];

  const filteredStatusList: Record<string, number> = Object.fromEntries(
    desiredOrder.map((key: any) => [
      keyDisplayNameMap[key],
      backendData?.statusList?.[key] || 0,
    ])
  );

  const filteredMemberBackendData = {
    ...backendData,
    // reportsList: backendData?.reportsList?.filter(
    //   (report: any) => report.type === 2
    // ),
  };

  const { templateCounts, serviceCount } = filteredMemberBackendData;

  const CardInfo = ({
    title,
    dataKey,
    index,
    tooltipContent,
    tooltipId,
  }: CardInfoProps) => (
    <Col>
      <Card>
        <CardBody className="text-center">
          <div className="d-flex justify-content-center">
            <div className="me-1">{title}</div>
            <TooltipReference
              placement="bottom"
              content={tooltipContent}
              tooltipId={tooltipId}
            >
              <PiInfoLight size={15} />
            </TooltipReference>
          </div>

          {cardCountLoader ? (
            <div>
              <div className="spinner-border text-success" role="status" style={{height: '1.5rem', width: '1.5rem'}}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="fs-2 fw-bold" style={{ color: countColor[index] }}>
              {dataKey === "serviceCount"
                ? serviceCount
                : templateCounts?.[0][dataKey] ?? 0}
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );

  const DashboardCard = ({
    title,
    count,
    color,
    tooltipContent,
    tooltipId,
  }: any) => (
    <Col>
      {" "}
      {/* Adjust `md` size as needed */}
      <Card className="conversation-status-card">
        <CardBody className="text-center ps-0 pe-0 pb-4">
          <div className="d-flex justify-content-center">
            <div className="me-1">{title}</div>
            <div>
              <TooltipReference
                placement="bottom"
                content={tooltipContent}
                tooltipId={tooltipId}
              >
                <PiInfoLight size={15} />
              </TooltipReference>
            </div>
          </div>
          {cardCountLoader ? (
            <div>
              <div className="spinner-border text-success" role="status" style={{height: '1.5rem', width: '1.5rem'}}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="conversation-count">
              <h2 className="fw-bold m-0" style={{ color: color }}>
                {count}
              </h2>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );

  return (
    <div className="analytics-dashboard">
      <h5 className="pt-2 pb-2">Analytics - Dashboard</h5>
      <div className="date-filter">
        <div className="date-filter-selector">
          <Input
            className={`dateFilter-dateFilter`}
            type="select"
            size={1}
            value={dateFilter}
            style={{
              fontFamily: '"Public Sans", sans-serif',
              fontSize: "14px",
              padding: "8px 25px",
            }}
            onChange={changeDateFilterHandler}
          >
            <option value={DateFilterType.Past7Days}>Past 7 days</option>
            <option value={DateFilterType.Past30Days}>Past 30 days</option>
            <option value={DateFilterType.Past90Days}>Past 90 days</option>
            <option value={DateFilterType.ThisWeek}>This week</option>
            <option value={DateFilterType.PreviousWeek}>Previous week</option>
            <option value={DateFilterType.ThisMonth}>This month</option>
            <option value={DateFilterType.PreviousMonth}>Previous month</option>
            <option value={DateFilterType.CustomRange}>Custom range</option>
          </Input>
        </div>

        <div className="date-range-picker">
          <Overlay
            target={datepickertarget.current}
            show={showDatePicker}
            placement="bottom"
            rootClose={true}
            onHide={() => setShowDatePicker(false)}
          >
            <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
              <DateRangePicker
                value={dateRangeValue}
                minDate={currentDate}
                onSelect={(value: any) => {
                  setDateRangeValue(
                    moment.range(
                      moment(value.start).startOf("day"),
                      moment(value.end).endOf("day")
                    )
                  );
                  setDateFilter(DateFilterType.CustomRange);
                  setShowDatePicker(false);
                  // campaignFilterQuery.refetch();
                }}
                maximumDate={today.endOf("day")}
                singleDateRange={true}
              />
            </Popover>
          </Overlay>
          <div
            className={`form-control calenderInput-alignDatepicker`}
            ref={datepickertarget}
            onClick={() => setShowDatePicker((current) => !current)}
          >
            <BsCalendar />
            <span className="value ml-2">
              {dateRangeValue.start.format("DD/MM/YYYY")}
              {" - "}
              {dateRangeValue.end.format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
      </div>

      <div className="conversation-dashboard">
        <Row className="d-flex">
          {Object.entries(filteredStatusList).map(([key, value], index) => {
            return (
              <DashboardCard
                key={key}
                title={key}
                count={value}
                color={countColor[index]}
                tooltipContent={tooltipReference[index]}
                tooltipId={`conversation${index}`}
              />
            );
          })}

          <DashboardCard
            title={"Active Conversations"}
            count={filteredMemberBackendData?.activeConversations}
            color={countColor[2]}
            tooltipContent={"Open conversations within 24 hours"}
            tooltipId={`activeConversation`}
          />
          <DashboardCard
            title={"Business Initiated"}
            // count={
            //   filteredMemberBackendData?.contactCreation?.[0]
            //     .businesscreation || 0
            // }
            count={
              filteredMemberBackendData?.contactCreation?.length > 0
                ? filteredMemberBackendData?.contactCreation[0]
                    ?.businesscreation
                : 0
            }
            color={countColor[1]}
            tooltipContent={
              "Conversation initiated by the business (Authentication, Marketing, Utility)."
            }
            tooltipId={`businessCreation`}
          />
        </Row>
        {/* <div className="d-flex"> */}
        {/* <div className="dashboard-count">
          {Object.entries(filteredStatusList).map(([key, value], index) => (
            <div className="conversation-status-card" key={key}>
              <div className="conversation-header">
                <div className="me-1">{key}</div>
                <div>
                  <TooltipReference
                    placement="bottom"
                    content={tooltipReference[index]}
                    tooltipId={`conversation${index}`}
                  >
                    <PiInfoLight size={15} />
                  </TooltipReference>
                </div>
              </div>

              <div className="conversation-count">
                <h2
                  className="fw-bold m-0"
                  style={{ color: countColor[index] }}
                >
                  {value}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div className="dashboard-count ml-3">
            <div className="conversation-status-card">
              <div className="conversation-header">
                <div className="me-1">Whatsapp Template msgs</div>
                <div>
                  <TooltipReference
                    placement="bottom"
                    content={'Count of WA Template Messages initiated'}
                    tooltipId={`conversation`}
                  >
                    <PiInfoLight size={15} />
                  </TooltipReference>
                </div>
              </div>

              <div className="conversation-count">
                <h2
                  className="fw-bold  text-secondary"
                  
                >
                {filteredMemberBackendData?.templateCounts? filteredMemberBackendData?.templateCounts[0]?.totalTemplateCount : 0}
                </h2>
              </div>
            </div>
        </div> */}
        {/* </div> */}
        <Row>
          {/* <Col> */}
          <DashboardCard
            title="WhatsApp Template msgs"
            count={
              filteredMemberBackendData?.templateCounts?.[0]
                ?.totalTemplateCount || 0
            }
            color={countColor[2]}
            tooltipContent="Count of WA Template Messages initiated"
            tooltipId="conversation"
          />
          {/* <CardInfo
            title="Total Messages"
            dataKey="totalTemplateCount"
            index={3}
          /> */}
          <CardInfo
            title="Marketing"
            tooltipContent="Count of Marketing messages initiated"
            tooltipId="Marketing"
            dataKey="marketingCounts"
            index={2}
          />
          <CardInfo
            title="Authentication"
            tooltipContent="Count of Authentication messages initiated"
            tooltipId="AuthenticationWA"
            dataKey="authenticationCounts"
            index={1}
          />
          <CardInfo
            title="Utility"
            tooltipContent="Count of Utility messages initiated"
            tooltipId="UtilityAuth"
            dataKey="utilityCounts"
            index={0}
          />
          <CardInfo
            title="User Initiated"
            tooltipContent="Count of Conversations initiated by users and responded by the business"
            tooltipId="utilityCounts"
            dataKey="serviceCount"
            index={4}
          />
          {/* </Col> */}
        </Row>
      </div>
      {isLoading ? (
        <div className="centerItemsWithWidth">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex">
            <div>
              <b className="ml-5 h6">
                New Contacts{" "}
                <span>
                  {" "}
                  <TooltipReference
                    placement="bottom"
                    content={"Number of Contacts added on selected date wise"}
                    tooltipId="day"
                  >
                    <PiInfoLight size={15} />
                  </TooltipReference>
                </span>
              </b>
              {filteredMemberBackendData?.contactDetailsList?.length ? (
                <ContactChart
                  data={filteredMemberBackendData?.contactDetailsList}
                />
              ) : (
                <div className="nodatadiv">
                  <img src={noData} alt="nodata" width={200} height={200}></img>
                  <p className="text-center h6 text-muted">No Data Found</p>
                </div>
              )}
            </div>
            <div>
              <b className="ml-5 h6">
                WhatsApp Conversation By Category{" "}
                <span>
                  {" "}
                  <TooltipReference
                    placement="bottom"
                    content={
                      "Conversation initiated by Business, user, media on selected date wise"
                    }
                    tooltipId="convcat"
                  >
                    <PiInfoLight size={15} />
                  </TooltipReference>
                </span>
              </b>
              {filteredMemberBackendData?.contactInformations?.length ? (
                <ConvChart
                  data={filteredMemberBackendData?.contactInformations}
                />
              ) : (
                <div className="nodatadiv">
                  <img src={noData} alt="nodata" width={200} height={200}></img>
                  <p className="text-center h6 text-muted">No Data Found</p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 d-flex">
            <div>
              <b className="ml-5 h6">
                WhatsApp Message Status{" "}
                <span>
                  {" "}
                  <TooltipReference
                    placement="bottom"
                    content={"WhatsApp outgoing messages by different statuses"}
                    tooltipId="message"
                  >
                    <PiInfoLight size={15} />
                  </TooltipReference>
                </span>
              </b>
              <br />
              {filteredMemberBackendData?.messagesStatusList?.length ? (
                <StatusChart
                  data={filteredMemberBackendData?.messagesStatusList}
                />
              ) : (
                <div className="nodatadiv">
                  <img src={noData} alt="nodata" width={200} height={200}></img>
                  <p className="text-center h6 text-muted">No Data Found</p>
                </div>
              )}
            </div>
            <div className="Conversation status">
              <b className="ml-5 h6">
                Conversation Status{" "}
                <span>
                  {" "}
                  <TooltipReference
                    placement="bottom"
                    content={
                      "Bot and Member wise resolved conversation on selected date wise"
                    }
                    tooltipId="status"
                  >
                    <PiInfoLight size={15} />
                  </TooltipReference>
                </span>
              </b>
              {filteredMemberBackendData?.reportsList?.length ? (
                <MyBarChart data={filteredMemberBackendData?.reportsList} />
              ) : (
                <div className="nodatadiv">
                  <img src={noData} alt="nodata" width={200} height={200}></img>
                  <p className="text-center h6 text-muted">No Data Found</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AnalyticsDashboard;
