import React, { useEffect, useMemo, useState } from 'react';
import { IEditorProps } from '../types';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList';
import { IIntegrationApp } from '../../../../entity/integration/integrationApp';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { sangamIcon } from '../../../../icons';
import { Button, Offcanvas, Spinner, Form, FormControl, Accordion, InputGroup, FormCheck } from 'react-bootstrap';
import IntegrationConnection from './integrationConnection';
import ActionsField from '../../../../entity/integration/actionsField';
import ReactSelect from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import SelectIntegrationAction from './selectIntegrationAction';
import { CreateSelectVariable } from './createSelectVariable';
import FlagInput from '../../../../../common/flagInput';
import store from '../../../../../redux/store/store';
import { Provider } from 'react-redux';
import classes from './shopify.module.scss';
import AddVariable from '../addVariable';
import axios from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';
import bg from '../catalogue/sendProducts.module.scss';
import { SelectVariable } from './selectVariable';


type Payload = Record<string, string> | null
type QueryParam = {
    uid: string,
    field: string,
    condition: 'equals' | 'starts_with',
    value: string,
}
type QueryParamKey = 'field' | 'condition' | 'value';

type Variables = {
    label: string,
    name: string,
    type: 'TEXT'
}

interface FormData {
    integrationData: {
        app: IIntegrationApp,
        scope: 'COM' | 'IN'
        connectionId: number | null
        action: IIntegrationActionListId | null
        optionalFields: null
        firstName: string,
        lastName: string,
        designation: string, 
        department: string, 
        otherSource: string, 
        nextFollowupDate: string, 
        lostReason: string, 
        nextFollowupType: string,
    },
    isSameFlow: boolean
}



function SangamCRM(props: IEditorProps) {

    const [isCreateScreen, setIsCreateScreen] = useState(false);
    const [isEditScreen, setEditScreen] = useState(true);
    const [isOrderBased, setIsOrderBased] = useState(true);
    const [formData, setFormData] = useState<FormData>({
        integrationData: {
            app: 'SangamCRM',
            scope: 'COM',
            connectionId: null,
            action: null,
            optionalFields: null,
            firstName: '',
            lastName: '',
            designation: '',
            department: '',
            otherSource: '',
            nextFollowupDate: '',
            lostReason: '',
            nextFollowupType: ''
        },
        isSameFlow: false
    })
    const actionClenup = () => {
        (window as any).actionFields = null;
        (window as any).fieldsInit = false;
        (window as any).selectedModule = null;
        (window as any).selectedModuleId = null;
        // zohoModuleFieldsQuery.remove()
    }
    useEffect(() => {
        init(setFormData)
        return actionClenup
    }, [])

    const { init, saveElementChanges } = useElmentEditor({
        type: 'integration/SangamCRM',
        data: formData
    }, props)

    const schema = yup.object().shape({
        integrationData: yup.object().shape({
            connectionId: yup.string().required('Connection is required'),
            action: yup.string().required('Action is required'),
            optionalFields: yup.object().nullable(),
            firstName: yup.string().required("First name is required"),
            lastName: yup.string().required("Last name is required"),
            designation: yup.string().required("Designation is required"),
            department: yup.string().required("Department is required"),
            otherSource: yup.string().required("Other source is required"),
            nextFollowupDate: yup.string().required("Next follow-up date is required"),
            lostReason: yup.string().required("Lost reason is required"),
            nextFollowupType: yup.string().required("Next follow-up type is required"),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >

            {({ handleSubmit, setFieldValue, values, errors, touched, setValues, handleChange }) => {

                const [code, setCode] = useState<string>('91');
                const onGoBackHandler = () => {
                    if (values.integrationData.action) {
                        setFieldValue('integrationData', {
                            app: 'SangamCRM',
                            scope: 'COM',
                            connectionId: values?.integrationData?.connectionId,
                            action: null,
                            payload: null,
                            query: [],
                            recordId: '',
                            name: '',
                            phone: '',
                            financialStatus: '',
                            orderId: '',
                            tags: ''
                        })
                        actionClenup()
                    } else if (values.integrationData.connectionId) {
                        setFieldValue('integrationData.connectionId', null)
                    } else {
                        props.onClose()
                    }
                }

                const myAction: ActionsListItem | null = useMemo(() => {
                    if (values.integrationData.connectionId) {
                        (window as any).appConnectionId = values.integrationData.connectionId
                    }
                    if ('action' in values.integrationData && values.integrationData.action) {
                        return ACTION_LIST['SangamCRM'].find(action => action.id === values.integrationData.action) || null
                    }
                    return null
                }, [values])

                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues])

                const mapFieldsHandler = (field: ActionsField) => {

                    const isError = Boolean(errors.integrationData
                        && field.id in (errors.integrationData as any)
                        && Boolean((errors.integrationData as any)[field.id]))
                    const isTouched = Boolean(touched.integrationData
                        && field.id in (touched.integrationData as any)
                        && Boolean((touched.integrationData as any)[field.id]))

                    return (
                        <>
                            {field && (
                                <>
                                    <FormGroup className="mb-3">
                                        <Label>{field.label}<span className='required'></span></Label>
                                        <FormControl
                                            name={`integrationData.${field.id}`}
                                            as='input'
                                            value={(values.integrationData as any)[field.id] || ''}
                                            onChange={handleChange}
                                            placeholder={field.placeholder || ''}
                                            required={true}
                                        />
                                        <ErrorMessage name={`integrationData.${field.id}`} component="div" className='invalid-feedback d-block' />
                                    </FormGroup>
                                </>
                            )}
                        </>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption
                            onHide={onGoBackHandler}
                            caption="Sangam CRM"
                            icon={<img style={{ width: 28 }}
                                alt=''
                                src={sangamIcon}
                            />}
                        />
                        <Offcanvas.Body>

                            {myAction ? <h5 className='mb-3'>{myAction.label}</h5> : null}
                            {values.integrationData.connectionId ? (
                                values.integrationData.action ? (
                                    <>
                                        {myAction ?
                                            <>
                                                {myAction.fields
                                                    .filter(field => field.required)
                                                    .map(mapFieldsHandler)}
                                                {myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 && (
                                                        <>
                                                            <FormCheck
                                                                name='isSameFlow'
                                                                type='switch'
                                                                id='fields-shopify-switch'
                                                                label="Optional Fields"
                                                                checked={values.isSameFlow}
                                                                onChange={event => {
                                                                    setFieldValue('isSameFlow', event.target.checked);
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {values?.isSameFlow && myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 ? (
                                                    <div className={bg.background}>
                                                        {myAction.fields
                                                            .filter(field => !field.required)
                                                            .map(mapFieldsHandler)}
                                                    </div>
                                                ) : null}
                                            </> : null}
                                    </>
                                ) : <SelectIntegrationAction app='SangamCRM' isOrder={isOrderBased} />) : (
                                <IntegrationConnection app='SangamCRM' scope="COM" onScreenChange={(screen: any) => { setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit'); }} isInvalidAccess = {props?.isInvalidAccess} />
                            )}

                        </Offcanvas.Body>

                        {(isEditScreen && !isCreateScreen) && values?.integrationData?.connectionId && (
                            <div className='editor-footer'>
                                <Button variant='outline-dark' onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button className='sendButton' type='submit' disabled={isCreateScreen || props?.isInvalidAccess}>
                                    Save
                                </Button>
                            </div>)}
                    </Form>
                )
            }}
        </Formik >
    )
}

export default SangamCRM;