import React from 'react';
import moment from 'moment';
import {
  ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

// Assuming TicketData is properly defined elsewhere
interface TicketData {
  createdAt: string;
  openCount: number;
  closedCount: number;
}

interface Props {
  ticketStatusAndPriority?: TicketData[];
}

const ScatterCharts = ({ ticketStatusAndPriority = [] }: Props) => {
  // Use a Map to combine counts by date
  const countsByDate = new Map();

  ticketStatusAndPriority.forEach(item => {
    const formattedDate = moment(item.createdAt).format('DD MMM');
    if (!countsByDate.has(formattedDate)) {
      countsByDate.set(formattedDate, {
        date: formattedDate,
        openCount: 0,
        closedCount: 0
      });
    }
    countsByDate.get(formattedDate).openCount += item.openCount;
    countsByDate.get(formattedDate).closedCount += item.closedCount;
  });

  const formattedData = Array.from(countsByDate.values());

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ScatterChart
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <CartesianGrid />
        <XAxis type="category" dataKey="date" name="Date" />
        <YAxis type="number" name="Count" />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        <Scatter name="Open" data={formattedData} fill="#007EC3" dataKey="openCount" />
        <Scatter name="Closed" data={formattedData} fill="#6EC77C" dataKey="closedCount" />
      </ScatterChart>
    </ResponsiveContainer>
  );
}

export default ScatterCharts;
