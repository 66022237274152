import React from 'react'
import { Spinner } from 'react-bootstrap'

import Node from '../node'
import { ACTION_LIST } from '../../../../entity/integration/actionList'
import { IIntegrationApp } from '../../../../entity/integration/integrationApp'

type Props = {
    label: string
    props: any
}

function IntegrationNode({ label, props }: Props) {
    const myAction = ACTION_LIST[props.data.integrationData.app as IIntegrationApp].find(action => action.id === props.data.integrationData.action)
    return (
        <Node {...props} label={label} content={myAction ? (
            <>
                <img src={myAction.icon} width={15} className='mr-1' />
               {myAction.label}
            </>
        ) : <Spinner />} />
    )
}

export default IntegrationNode