import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { ListGroup } from 'react-bootstrap';
import Node from '../node';
import styles from './askKeyword.module.css';
import listStyles from '../sendList/listItem.module.css';
import { uuidv4 } from '../../../../utils/uuid';

const AskKeyword: React.FC<any> = props => {
    const [showFullText, setShowFullText] = useState(false);
    return (
        <Node {...props} label='Ask Keyword' showRight={false} content={(
            <div>
 {props.data.text ? (
                        
                        <div style={{ cursor: 'pointer' }}>
                            {props.data.text.substring(0, 20)}
                            {props.data.text.length > 20 ? '...' : null}
                        </div>
                    ) : null}                {props.data.dynamic ? (
                    <i className='d-block text-muted'>(Dynamic Options)</i>
                ) : (
                    <ListGroup as='ol' className={listStyles.listitem}>
                        {props.data.options.map((option: any, i: number) => {
                            return (
                                <ListGroup.Item as='li' className={[styles.item, listStyles.item].join(" ")} key={uuidv4()}>
{option.title ? (
                        <div style={{ cursor: 'pointer' }}>
                            {option.title.substring(0, 17)}
                            {option.title.length > 17 ? '...' : null}
                        </div>
                    ) : null}                                         {!props.readOnly ? (
                                        <Handle id={`ask_keyword_options/${i + 1}`} type='source' position={Position.Right} isConnectable />
                                    ) : null}
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                )}
                {props.data.footerText ? (
                    <span className={styles.footer}>{props.data.footerText}</span>
                ) : null}
            </div>
        )} />
    );
}

export default AskKeyword;