import React, { useState, useEffect } from "react";
import {
  Button,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import style from "../TicketFields/ticketFields.module.scss";
import { useSelector } from "react-redux";
import { Item, getItemIcons } from "../Types";
import * as Yup from "yup";
import { AiOutlineDelete } from "react-icons/ai";
import { RxDragHandleDots2 } from "react-icons/rx";
import { CgMathPlus } from "react-icons/cg";
import { FaTrash } from "react-icons/fa";
import {
  patchTicketField,
  deleteTicketField,
  deleteTicketOption,
} from "../../../services/ticketService";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

interface Props {
  name: string;
  index: number;
  disable: boolean[];
  setDisable: (value: boolean[]) => void;
  toggler: boolean[];
  setToggler: (value: boolean[]) => void;
  formValue: Item[];
  setFormValue: (value: ((prevItems: Item[]) => Item[]) | Item[]) => void;
  setCallDb: (value: boolean) => void;
  newlyAddedItemId: string | null;
  setNewlyAddedItemId: (value: string | null) => void;
  isDefault: boolean;
  setSubmittedItemId: (value: string | null) => void;
}

const FieldForm: React.FC<Props> = (props) => {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // State for delete modal
  const [choices, setChoices] = useState(
    props?.formValue[props.index]?.options?.filter(
      (choice: any) => !choice.deleted
    ) || []
  );

  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData?.uid
  );

  useEffect(() => {
    setChoices(
      props.formValue[props.index].options?.filter(
        (choice: any) => !choice.deleted
      ) || []
    );
  }, [props.formValue[props.index].options]);

  const handleClose = () => {
    const componentToggler = [...props.toggler];
    componentToggler[props.index] = false;
    props.setToggler(componentToggler);

    const formDisablerArr = [...props.disable];
    const allFalseArr = formDisablerArr.map(() => false);
    props.setDisable(allFalseArr);

    // If the item is newly added, remove it from the dynamic list
    if (props.formValue[props.index].id === props.newlyAddedItemId) {
      const updatedItems = props.formValue.filter(
        (item, idx) => idx !== props.index
      );
      props.setFormValue(updatedItems);
      props.setNewlyAddedItemId(null); // Reset the newly added item ID
    }
  };

  const validationSchema = Yup.object().shape({
    label: Yup.string()
      .required("Label is required")
      .test("unique-label", "Duplicate label not allowed", function (value) {
        const labels = props.formValue.map((item) => item.label);
        const labelIndex = labels.indexOf(value || "");
        return labelIndex === -1 || labelIndex === props.index;
      }),
    choices: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required("Choice label is required"),
        })
      )
      .test("unique", "Duplicate not allowed", function (choices) {
        if (!choices) {
          return true;
        }
        const labels = choices.map((choice) => choice.label);
        const hasDuplicate = labels.some(
          (label, idx) => labels.indexOf(label) !== idx
        );
        if (hasDuplicate) {
          return this.createError({
            path: `choices[${labels.lastIndexOf(
              labels.find((label, idx) => labels.indexOf(label) !== idx)
            )}].label`,
            message: "Duplicate not allowed",
          });
        }
        return true;
      }),
  });

  const handleDelete = (fieldId: number | string, field: any) => {
    const updatedItems = props.formValue.filter(
      (item, idx) => idx !== props.index
    );
    props.setFormValue(updatedItems);
    setDeleteModalOpen(false);

    if (typeof fieldId === "number") {
      return deleteTicketField(channelUid, field)
        .then((res) => {
          props.setCallDb(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      props.setDisable(props.disable.map(() => false));
      props.setToggler(props.toggler.map(() => false));
    }
  };

  const onDragEnd = (
    result: DropResult,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (!result.destination) return;

    const items = Array.from(choices);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const reorderedChoices = items.map((item, index) => ({
      ...item,
      position: index + 1,
      updatedAt: new Date().toISOString(),
    }));

    setChoices(reorderedChoices);
    setFieldValue("choices", reorderedChoices);
  };

  const handleAddChoice = (
    setFieldValue: (field: string, value: any) => void
  ) => {
    const highestPosition = choices.reduce((maxPos, choice) => {
      return choice.position > maxPos ? choice.position : maxPos;
    }, 0);

    const newItem = {
      id: `${new Date().getTime()}`, // temporary id as string
      label: "",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null,
      deleted: false,
      isDefault: false,
      position: highestPosition + 1,
      slaPolicy: false,
    };

    const newChoices = [...choices, newItem];
    setChoices(newChoices);
    setFieldValue("choices", newChoices);
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const newChoices = [...choices];
    newChoices[index] = { ...newChoices[index], label: event.target.value };
    setChoices(newChoices);
    setFieldValue("choices", newChoices);
  };

  const handleRemoveChoice = async (
    index: number,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const newChoices = [...choices];
    if (typeof newChoices[index].id === "string") {
      setChoices(newChoices.filter((_, idx) => idx !== index));
      setFieldValue(
        "choices",
        newChoices.filter((_, idx) => idx !== index)
      );
    } else {
      await deleteTicketOption(channelUid, newChoices[index]);
      newChoices[index].deleted = true;
      setChoices(newChoices.filter((choice) => !choice.deleted));
      setFieldValue(
        "choices",
        newChoices.filter((choice) => !choice.deleted)
      );
    }
  };

  return (
    <div
      className={style.fieldForm}
      onMouseEnter={() => setHoverIndex(props.index)}
      onMouseLeave={() => setHoverIndex(null)}
    >
      {!props.toggler[props.index] ? (
        <>
          <div
            id={
              props.disable[props.index]
                ? style.fieldDisable
                : style.fieldEnable
            }
            onClick={() => {
              const componentToggler = [...props.toggler];
              componentToggler[props.index] = true;
              props.setToggler(componentToggler);

              const formDisablerArr = [...props.disable];
              const allTrueArr = formDisablerArr.map(() => true);
              allTrueArr[props.index] = false;
              props.setDisable(allTrueArr);

              props.setCallDb(false);
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <div className="me-2">
                  <RxDragHandleDots2
                    size={20}
                    opacity={hoverIndex === props.index ? 1 : 0.3}
                  />
                </div>
                <div>{getItemIcons(props.name)}</div>
                <div className="ms-2">{props.name.charAt(0).toUpperCase() + props.name.slice(1)}</div>

                {!props.isDefault && (
                  <span className={style.headerLabel}>
                    - {props.formValue[props.index].label}
                  </span>
                )}
              </div>
              {!props.isDefault && hoverIndex === props.index && (
                <div className={style.hoverDelete}>
                  <AiOutlineDelete
                    size={17}
                    onClick={(e) => {
                      e.stopPropagation(); // Stop the event from bubbling up to the parent div
                      setDeleteModalOpen(true);
                    }}
                  />
                </div>
              )}
              {props.isDefault && (
                <div className={style.defaultField}>
                  <div className="text-muted">Default</div>
                </div>
              )}
            </div>
            <Modal
              isOpen={deleteModalOpen}
              toggle={() => setDeleteModalOpen(!deleteModalOpen)}
            >
              <ModalBody>
                <h6 className="mt-2 mb-3">Confirm Delete</h6>

                <div className={style.modalText}>
                  Are you sure you want to delete this field?
                </div>
              </ModalBody>
              <ModalFooter className="p-1">
                <button
                  className={style.cancelbutton}
                  onClick={() => setDeleteModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className={style.discardButton}
                  onClick={() => {
                    handleDelete(
                      props.formValue[props.index].id,
                      props.formValue[props.index]
                    );
                  }}
                >
                  Delete
                </button>
              </ModalFooter>
            </Modal>
          </div>
        </>
      ) : (
        <Formik
          initialValues={{
            label: props.formValue[props.index].label,
            choices: props.name === "dropdown" ? choices : [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const payload = props.formValue.map((item, idx) => {
              if (idx === props.index) {
                const payLoadId = typeof item.id === "string" ? 0 : item.id;

                let formattedType = props.name;
                formattedType = formattedType.replace(/^./, (match) =>
                  match.toLowerCase()
                );

                const now = new Date();
                const isoString = now.toISOString();

                return {
                  ...item,
                  id: payLoadId,
                  name: props.name,
                  label: values.label,
                  position: item.position,
                  type: formattedType,
                  isDefault: item.isDefault,
                  deletedAt: null,
                  createdAt: item.createdAt,
                  updatedAt: isoString,
                  options: values.choices || [],
                  deleted: item.deleted,
                  dropDown: props.name === "dropdown",
                };
              }
              return item;
            });

            patchTicketField(channelUid, payload)
              .then((res) => {
                if (res === "SUCCESS") {
                  props.setCallDb(true);
                  props.setSubmittedItemId(
                    props.formValue[props.index].id.toString()
                  );
                  setTimeout(() => {
                    props.setSubmittedItemId(null);
                  }, 1000);
                }
              })
              .catch((err) => {
                console.log(err);
              });

            handleClose();
          }}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            setFieldValue,
            initialValues,
          }) => {
            useEffect(() => {
              const hasChanges =
                JSON.stringify(values) !== JSON.stringify(initialValues);
              setUnsavedChanges(hasChanges);
            }, [values, initialValues]);

            const handleCancelClick = () => {
              if (unsavedChanges) {
                setModalOpen(true);
              } else {
                handleClose();
                props.setCallDb(false);
              }
            };

            return (
              <Form>
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div className="d-flex justify-content-start align-items-center">
                    <div>{getItemIcons(props.name)}</div>
                    <div className="ms-2">{props.name.charAt(0).toUpperCase() + props.name.slice(1)}</div>
                  </div>
                  {!props.isDefault && (
                    <div className={style.hoverDelete}>
                      <AiOutlineDelete
                        size={17}
                        onClick={() => setDeleteModalOpen(true)}
                      />
                    </div>
                  )}
                  {props.isDefault && (
                    <div className={style.defaultField}>
                      <div className="text-muted">Default</div>
                    </div>
                  )}
                </div>
                <hr />
                <CardBody className={style.cardBody}>
                  <div
                    className="d-flex flex-column mt-2"
                    style={{ width: "50%" }}
                  >
                    <label htmlFor={`label`}>Label</label>
                    <Field
                      name={`label`}
                      type="text"
                      disabled={props.isDefault}
                    />
                    <ErrorMessage
                      className="text-danger pt-1"
                      name="label"
                      component="div"
                    />
                  </div>
                  {props.name === "dropdown" && (
                    <>
                      <Label className="mt-4">DROPDOWN CHOICES</Label>
                      <DragDropContext
                        onDragEnd={(result) => onDragEnd(result, setFieldValue)}
                      >
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {values.choices
                                .filter((choice) => !choice.deleted)
                                .sort((a, b) => a.position - b.position)
                                .map((choice, index: number) => (
                                  <Draggable
                                    key={choice.id}
                                    draggableId={choice.id.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Row className="mb-2">
                                          <Col
                                            xs="1"
                                            className="d-flex align-items-center justify-content-center"
                                          >
                                            <RxDragHandleDots2 size={20} />
                                          </Col>
                                          <Col xs="6">
                                            <Input
                                              type="text"
                                              name={`choices.${index}.label`}
                                              value={choice.label}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  index,
                                                  e,
                                                  setFieldValue
                                                )
                                              }
                                            />
                                            <ErrorMessage
                                              className="text-danger pt-1"
                                              name={`choices.${index}.label`}
                                              component="div"
                                            />
                                          </Col>
                                          <Col
                                            xs="5"
                                            className="d-flex align-items-center justify-content-start"
                                            onClick={() =>
                                              handleRemoveChoice(
                                                index,
                                                setFieldValue
                                              )
                                            }
                                          >
                                            <FaTrash />
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Row className="mt-1">
                        <Col xs={1}></Col>
                        <Col className="d-flex justify-content-start align-items-center">
                          <div
                            className={style.addIcon}
                            onClick={() => handleAddChoice(setFieldValue)}
                          >
                            <CgMathPlus size={16} />
                          </div>
                          <div
                            className="ms-1"
                            onClick={() => handleAddChoice(setFieldValue)}
                          >
                            Add choice
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
                <div className="d-flex justify-content-end align-items-center mt-3">
                  <div className={style.cardFooter}>
                    <button
                      type="button"
                      className={style.cancelbutton}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                    <Button
                      type="submit"
                      className="sendButton"
                      disabled={isSubmitting}
                    >
                      Save field
                    </Button>
                  </div>
                </div>

                <Modal
                  isOpen={modalOpen}
                  toggle={() => setModalOpen(!modalOpen)}
                >
                  <ModalBody>
                    <h6 className="mt-2 mb-3">Unsaved changes</h6>

                    <div className={style.modalText}>
                      Your data will be lost. Are you sure you want to continue?
                    </div>
                  </ModalBody>
                  <ModalFooter className="p-1">
                    <button
                      className={style.cancelbutton}
                      onClick={() => setModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={style.discardButton}
                      onClick={() => {
                        setModalOpen(false);
                        handleClose();
                        props.setCallDb(false);
                      }}
                    >
                      Discard
                    </button>
                  </ModalFooter>
                </Modal>

                <Modal
                  isOpen={deleteModalOpen}
                  toggle={() => setDeleteModalOpen(!deleteModalOpen)}
                >
                  <ModalBody>
                    <h6 className="mt-2 mb-3">Confirm Delete</h6>

                    <div className={style.modalText}>
                      Are you sure you want to delete this field?
                    </div>
                  </ModalBody>
                  <ModalFooter className="p-1">
                    <button
                      className={style.cancelbutton}
                      onClick={() => setDeleteModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={style.discardButton}
                      onClick={() => {
                        handleDelete(
                          props.formValue[props.index].id,
                          props.formValue[props.index]
                        );
                      }}
                    >
                      Delete
                    </button>
                  </ModalFooter>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default FieldForm;
