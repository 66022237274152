import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BizTable from '../../common/BizTable'; // Assuming BizTable is in this location
import { useSelector } from 'react-redux';
import { getAllTemplates } from '../../services/templateService';
import TooltipReference from '../../common/tooltip';
import { sliceText } from '../../common/sliceText';
interface Template {
    name: string;
    components: any[];
    language: string;
    createdAt: string;
    templateStatus: string;
    id: string;
}
interface TemplatesTableProps {
    templatesList: Template[];
    formatActions: (row: any) => any;
    searchTerm: string | null;
    status: string;
    category: string;
    renderBodyMessage: (text: string, data: any) => string;
    syncLoad: boolean;
}

const TemplatesTable: React.FC<TemplatesTableProps> = ({
    formatActions,
    searchTerm, status, category, renderBodyMessage, syncLoad
}) => {
    const [templatesList, setTemplatesList] = useState<Template[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const channelUid = useSelector((state: any) => state.cartreducer.channelUid)

    const showBodyMessage = (row: any) => {
        const text = row.original.components[0]?.format
            ? renderBodyMessage(row.original.components[1]?.text, row)
            : renderBodyMessage(row.original.components[0]?.text, row);

        if (!text) return '';

        return (
            <span>
                <TooltipReference
                    placement="right"
                    content={text}
                    tooltipId={`tooltip-${row.original.id}`}
                >
                    {sliceText(text, 60)}
                </TooltipReference>
            </span>
        );
    };

    const columns = [
        {
            Header: (
                <input
                    type="checkbox"
                    hidden={true}
                />
            ),
            accessor: "checkbox",
            sticky: "left",
        },
        {
            Header: "Name",
            accessor: "showName",
            Cell: ({ cell, row }: any) => <TooltipReference tooltipId={`tname-${row.id}`} content={cell.value} placement='right'>
                <span>{sliceText(cell.value, 17)}</span>
            </TooltipReference>
        },
        {
            Header: "Message",
            accessor: "bodyMessage",
            Cell: ({ cell, row }: any) => showBodyMessage(row),
        },
        {
            Header: "Language",
            accessor: "language",
            Cell: ({ cell }: any) => cell.value?.toUpperCase(),
        },
        {
            Header: "Created On",
            accessor: "createdAt",
            Cell: ({ cell }: any) => <div className='ml-3'>{moment(cell.value).format("DD MMM")}</div>,
        },
        {
            Header: "Category",
            accessor: "category",
        },
        {
            Header: "Header",
            accessor: "header",
            Cell: ({ cell, row }: any) => <span>{row.original.components[0]?.format || 'NONE'}</span>,
        },
        {
            Header: "Buttons",
            accessor: "buttons",
            Cell: ({ cell, row }: any) =>
                <span>
                    {row.original.components[row.original.components.length - 1]?.buttons?.length
                        ? row.original.components[row.original.components.length - 1].buttons[0].type === "PHONE_NUMBER"
                            ? "Phone Number"
                            : row.original.components[row.original.components.length - 1].buttons[0].type === "URL"
                                ? "Url"
                                : row.original.components[row.original.components.length - 1].buttons[0].type === "QUICK_REPLY"
                                    ? "Quick Reply" : row.original.components[row.original.components.length - 1].buttons[0].type === "COPY_CODE" ?
                                        'Copy Code' : row.original.components[row.original.components.length - 1].buttons[0].type === "FLOW" ?
                                            'Flow' : row.original.components[row.original.components.length - 1].buttons[0].type === "OTP" ? 'Otp' :
                                                row.original.components[row.original.components.length - 1].buttons[0].type === "CATALOG" ? 'Catalogue'
                                                    : "None"
                        : "None"}
                </span>
        },
        {
            Header: "Status",
            accessor: "templateStatus",
            Cell: ({ cell, row }: any) => (
                <span
                    className={`p-1 fontWeight ${cell.value === "APPROVED"
                        ? "approvedStatus"
                        : cell.value === "ERROR"
                            ? "rejectedStatus"
                            : cell.value === "REJECTED"
                                ? "rejectedStatus"
                                : cell.value === "PENDING"
                                    ? "pendingStatus"
                                    : cell.value === "IN_APPEAL"
                                        ? "appealStatus"
                                        : cell.value === "PENDING_DELETION"
                                            ? "pendingdelStatus"
                                            : cell.value === "DELETED"
                                                ? "deletedStatus"
                                                : cell.value === "DISABLED"
                                                    ? "disableStatus"
                                                    : cell.value === "PAUSED"
                                                        ? "pauseStatus"
                                                        : cell.value === "LIMIT_EXCEEDED"
                                                            ? "limitStatus"
                                                            : cell.value === "SUBMIT"
                                                                ? "submitStatus"
                                                                : cell.value === "DRAFT"
                                                                    ? "draftStatus"
                                                                    : "text-dark"}`}
                >
                    {cell.value}{" "}
                    {cell.value === "REJECTED" && (
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="rejectedStatus"
                            title={row.original.rejected_reason}
                        />
                    )}
                </span>
            ),
        },
        {
            Header: "Actions",
            accessor: "templateActions",
            Cell: ({ cell, row }: any) => formatActions(row),
        },
    ];

    const getTemplateData = useCallback(async (pageIndex: number,
        pageSize: number,
        searchText: string | null,
        currentPage: number) => {
        const payload = {
            "channelId": channelUid?.value,
            "page": searchTerm || category || status || syncLoad ? 0 : currentPage,
            "limit": pageSize,
            "search": searchTerm,
            "types": status && status !== 'all' ? [status.toUpperCase()] : [],
            "category": category && category !== 'all' ? [category.toUpperCase()] : []
        }
        const response = await getAllTemplates(payload);

        if (response && response?.templates) {
            setTemplatesList(response.templates);
            setTotalCount(response.count);
            return { data: response.templates, totalPages: response.count };
        }
        else {
            setTemplatesList([]);
            setTotalCount(0);
            return { data: [], totalPages: 0 };
        }
    }, [searchTerm, category, status, syncLoad]);

    return (
        <div>
            <BizTable
                columns={columns}
                fetchData={getTemplateData}
                counts={totalCount}
                refetchData={templatesList}
                tableName="TEMPLATES"
            />
        </div>
    );
};

export default TemplatesTable;
