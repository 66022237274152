import React from 'react'
import Node from '.././node';
import IntegrationNode from './integrationNode'
import { ListGroup } from 'react-bootstrap';
import { Handle, Position } from 'reactflow';
import classes from '../delay.module.css';

function Shopify(props: any) {

    let content = (
        <ListGroup>
            <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
                <div>
                    <span ></span>
                    <span className={classes.caption}>On Success</span>
                    <Handle
                        className={classes.dot}
                        id='integration/Shopify/success'
                        type='source'
                        position={Position.Right}
                        isConnectable
                    />
                </div>
            </ListGroup.Item>
            <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
                <div>
                    <span className={classes.caption}>On Fail</span>
                    <Handle
                        className={classes.dot}
                        id='integration/Shopify/failed'
                        type='source'
                        position={Position.Right}
                        isConnectable
                    />
                </div>
            </ListGroup.Item>
        </ListGroup>
    )
    return (
        props?.data?.integrationData?.action === "createOrder" ||    props?.data?.integrationData?.action === "createDraftOrder"
         ? (
            <Node {...props} label={ props?.data?.integrationData?.action === "createOrder" ? "Shopify - Create Order" : "Shopify - Draft Order"} content={content} />
         ) :
        (
           <IntegrationNode label='Shopify' props={props} />
        )
    )
}

export default Shopify;