import { ListGroup } from 'react-bootstrap';
import { Handle, Position } from 'reactflow';

import classes from './optionItem.module.css';
import { sliceText } from '../../../../common/sliceText';

interface Props {
    id: string;
    optionText: string;
    readOnly?: boolean;
    isOption?: boolean;
};

function OptionItem(props: Props) {
    return (
        <ListGroup.Item as='li' className={classes.item}>
            {sliceText(props.optionText, 20)}
            {!props.readOnly && props.isOption ? (
                <Handle id={props.id} type='source' position={Position.Right} isConnectable />
            ) : null}
        </ListGroup.Item>
    );
}

export default OptionItem;