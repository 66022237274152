import { Handle, Position } from 'reactflow';
import { ListGroup } from 'react-bootstrap';

import Node from './node';
import classes from './delay.module.css';
import { delayIcon } from '../../../icons';

function formatDuration(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let parts = [];

    if (hours > 0) {
        parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }
    if (minutes > 0) {
        parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    }
    if (seconds > 0) {
        parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
    }

    let result = parts.join(", ");
    const lastComma = result.lastIndexOf(",");
    if (lastComma !== -1) {
        result = result.substring(0, lastComma) + ' and' + result.substring(lastComma + 1);
    }

    return result || '0 seconds';
}

function Delay(props: any) {
    return (
        <Node {...props} label='Delay' showRight={false} content={(
            <div>
                <img width={16} alt='' src={delayIcon} />
                <span>{formatDuration(props.data.delay)}</span>
                <ListGroup>
                    <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
                        <div>
                            <span className={classes.caption}>On Complete</span>
                            {!props.readOnly ? (
                                <Handle
                                    className={classes.dot}
                                    id='delay/complete'
                                    type='source'
                                    position={Position.Right}
                                    isConnectable
                                />
                            ) : null}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
                        <div>
                            <span className={classes.caption}>On Interrupt</span>
                            {!props.readOnly ? (
                                <Handle
                                    className={classes.dot}
                                    id='delay/interrupt'
                                    type='source'
                                    position={Position.Right}
                                    isConnectable
                                />
                            ) : null}
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        )} />
    )
}

export default Delay;