import React from 'react'
import { useFormikContext } from 'formik'

import styles from './selectIntegrationAction.module.scss'
import { IIntegrationApp } from '../../../../entity/integration/integrationApp'
import { ACTION_LIST } from '../../../../entity/integration/actionList'
import { BsInfoCircleFill } from 'react-icons/bs'

type Props = {
    app: IIntegrationApp,
    isOrder?: boolean
}


function SelectIntegrationAction({ app, isOrder }: Props) {
    const formik = useFormikContext()
    return (
        <ul className={styles.actionList}>
            {ACTION_LIST[app].map(actionItem => (
                <li
                    className={(!isOrder && ((String(actionItem.id)==="createOrder") || String(actionItem.id)==="createDraftOrder")) ? `${styles.actionItem} ${styles.disabled}` : styles.actionItem}
                    key={actionItem.id}
                    onClick={() => {
                        formik.setFieldValue('integrationData.action', actionItem.id)
                    }}
                >
                    <img src={actionItem.icon} className={styles.icon} />
                    <div className={styles.details}>
                        <span className={styles.title}>{actionItem.label}</span>
                        <span className={styles.description}>{actionItem.description}</span>
                        {!isOrder && (actionItem.id === "createOrder" || actionItem?.id === "createDraftOrder") && <div className="d-flex align-items-center"> <p className={styles.customHint}> <BsInfoCircleFill className={styles.infoIcon}/> Applicable only in order based flow </p> </div>}
                    </div>
                </li>
            ))}
        </ul>

    )
}

export default SelectIntegrationAction