import React from "react";

const SidebarToggleContext = React.createContext<{
    isSidebarToggled: boolean;
    setSidebarToggled: React.Dispatch<React.SetStateAction<boolean>>;
}>({
    isSidebarToggled: false,
    setSidebarToggled() { }
});

export default SidebarToggleContext;