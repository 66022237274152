import { ListGroup } from 'react-bootstrap';

import classes from './optionItem.module.css';

interface Props {
    optionText: string;
};

function OptionItem(props: Props) {
    return (
        <ListGroup.Item as='li' className={classes.item}>
            {props.optionText}
        </ListGroup.Item>
    );
}

export default OptionItem;