import React, { useState, useEffect, ReactNode } from 'react';
import { Card, Form } from 'react-bootstrap';
import styles from './validationSettings.module.css';

interface ExpandBoxProps {
    children: ReactNode;
    initiallyOpen?: boolean;
    onCancel?: () => void;
    caption?: string;
}

const SaveAddressSettings: React.FC<ExpandBoxProps> = ({ children, initiallyOpen, onCancel, caption }) => {
    const [isExpanded, setExpanded] = useState(initiallyOpen);
    useEffect(() => {
        if (!isExpanded) {
            setExpanded(initiallyOpen);
        }
    }, [initiallyOpen]);

    function toggleExpanded() {
        if (isExpanded && onCancel) {
            onCancel();
        }
        setExpanded(!isExpanded);
    }

    return (
        <Card className={`${styles.expandBox} ${isExpanded ? styles.expanded : ''} mb-3`}>
            <Card.Header className={styles.header} onClick={toggleExpanded}>
                <span className={styles.title}>{caption}</span>
                <Form.Check
                    type='switch'
                    color='primary'
                    checked={isExpanded}
                    onChange={toggleExpanded}
                />
            </Card.Header>
            {isExpanded && <Card.Body>{children}</Card.Body>}
        </Card>
    );
}

SaveAddressSettings.defaultProps = {
    caption: 'Save Address Settings',
    initiallyOpen: false,
    onCancel: () => { }
};

export default SaveAddressSettings;
