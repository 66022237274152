import React from 'react';

interface Type {
   duplicate: boolean;
   edit: boolean;
   advanced: boolean;
   isSave: boolean;
   addProduct: boolean;
   quantity: number;
   id:number ;
   create: boolean;
   isLoading: boolean;
   isBanner: boolean;
}

const initialContext = React.createContext<Type>({
   duplicate: false,
   edit: false,
   advanced: false,
   isSave: false,
   addProduct: false,
   quantity: 1,
   id: 0,
   create: false,
   isLoading: false,
   isBanner: true
});

export default initialContext;