import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './flagInput.scss';
import { useSelector } from 'react-redux';

interface FlagInputProps {
  countryCode?: string;
  codeChange: (code: string) => void;
  countryList?:any;
}

const FlagInput: React.FC<FlagInputProps> = (props) => {
  const [value, setValue] = useState<string>('');
  const [defaultCountry, setDefaultCountry] = useState<string>('IN');
  const {countryList} = props;
  let getdata;
  if(countryList){
    getdata = countryList
  }
  else{
    getdata = useSelector((state: any) => state.cartreducer.flagData);

  }

  if (Object.keys(getdata).length) {
    var country = getdata?.find((item: any) => item.phoneCode === (props.countryCode?.slice(1)));
  }
  useEffect(() => {
    if (country) {
      setDefaultCountry(country.iso2);
    }
  }, [country]);

  const getCode = (e: string | undefined) => {
    const code = e || '';
    setValue(code);
    props.codeChange(code);
    document.getElementById('code')?.setAttribute('disabled', 'true');
  };

  return (
    <PhoneInput
      international
      countryCallingCodeEditable={false}
      defaultCountry={defaultCountry}
      id="code"
      value={value}
      onChange={(e: string) => getCode(e)}
      autoComplete="off"
    />
  );
};

export default FlagInput;
