import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormikContext } from 'formik'

import styles from './optionsInput.module.css'
import { FormData as SendReplyButtonsFormData } from './sendReplyButtons'
import { FormData as SendListFormData } from './listEditor'
import VariableInputLabel from './variableInputLabel'
import { SelectVariable } from './setVariable'

type FormData = SendReplyButtonsFormData | SendListFormData

type Props = {
    children: React.ReactNode,
}

function DynamicOptionType({ children }: Props) {
    const { values, errors, touched, handleChange, setFieldValue } = useFormikContext<FormData>()

    function getIsTouched(key: string): boolean {
        try {
            return Boolean(touched.dynamicFields
                && key in (touched.dynamicFields as any)
                && Boolean((touched.dynamicFields as any)[key]))
        } catch (error) {
            return true
        }

    }

    function getIsError(key: string): boolean {
        try {
        return Boolean(errors.dynamicFields
            && key in (errors.dynamicFields as any)
            && Boolean((errors.dynamicFields as any)[key]))
        } catch (error) {
            return true
        }
    }

    function getError(key: string) {
        if (getIsError(key)) return (errors.dynamicFields as any)[key]
        return null
    }

    return (
        <>
            <Form.Group className='mb-3'>
                <Form.Label>Options Type <span className='required'></span></Form.Label>
                <div>
                    <Form.Check
                        inline
                        label='Static Options'
                        name='dynamic'
                        type='radio'
                        checked={!values.dynamic}
                        onChange={event => setFieldValue('dynamic', !event.target.checked)}
                    />
                    <Form.Check
                        inline
                        label='Dynamic Options'
                        name='dynamic'
                        type='radio'
                        checked={values.dynamic}
                        onChange={event => setFieldValue('dynamic', event.target.checked)}
                    />
                </div>
                <Form.Control.Feedback type='invalid'>
                    {errors.dynamic}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>Options <span className='required'></span></Form.Label>
                {values.dynamic ? (
                    <div className={styles.optionBox}>
                        <VariableInputLabel
                            label='Variable To Iterate'
                            required
                            error={getIsError('iterateVariable') ? (
                                <div className='invalid-feedback d-block'>
                                    {(errors.dynamicFields as any).iterateVariable.name}
                                </div>
                            ) : null}>
                            <SelectVariable
                                placeholder='Create or Select variable'
                                name='dynamicFields.iterateVariable'
                                value={String(values.dynamicFields?.iterateVariable.name)}
                                onChange={handleChange}
                                type='ARRAY'
                            />
                        </VariableInputLabel>
                        <Form.Group className='my-3'>
                            <Form.Label>Path to ID <span className='required'></span></Form.Label>
                            <Form.Control
                                name='dynamicFields.idPath'
                                value={values.dynamicFields?.idPath}
                                placeholder='Enter path expression'
                                isInvalid={getIsTouched('idPath') && getIsError('idPath')}
                                onChange={handleChange}
                                isValid={getIsTouched('idPath') && !getIsError('idPath')}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {getError('idPath')}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='my-3'>
                            <Form.Label>Path to Title <span className='required'></span></Form.Label>
                            <Form.Control
                                name='dynamicFields.titlePath'
                                value={values.dynamicFields?.titlePath}
                                placeholder='Enter path expression'
                                isInvalid={getIsTouched('titlePath') && getIsError('titlePath')}
                                onChange={handleChange}
                                isValid={getIsTouched('titlePath') && !getIsError('titlePath')}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {getError('titlePath')}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {values.dynamicFields
                            && 'descriptionPath' in values.dynamicFields ? (
                            <Form.Group className='my-3'>
                                <Form.Label>Path to Description <span className='text-muted'>(Optional)</span></Form.Label>
                                <Form.Control
                                    name='dynamicFields.descriptionPath'
                                    value={values.dynamicFields?.descriptionPath}
                                    placeholder='Enter path expression'
                                    isInvalid={getIsTouched('descriptionPath') && getIsError('descriptionPath')}
                                    onChange={handleChange}
                                    isValid={getIsTouched('descriptionPath') && !getIsError('descriptionPath')}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {getError('descriptionPath')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        ) : null}
                    </div>
                ) : children}
            </Form.Group>
        </>
    )
}

export default DynamicOptionType