import Node from './node';
import {Tooltip, OverlayTrigger } from 'react-bootstrap';


function SetVariable(props: any) {
    const renderTooltip = (props :any) => (
        <Tooltip id="button-tooltip" {...props}
        style={{...props.style }}
        >{props.data.text}
        </Tooltip>
    );
    return (
        <div>
            <Node {...props} label='Set Variables' content={(
                <>
                    {props.data.variables.map((variable: any) => {
                        return <div key={variable.name}>{variable.name} = {variable.value? (
                            <OverlayTrigger
                            placement="bottom-end"
                            overlay={renderTooltip({ data: { text: variable.value} })}
                        >
                            <div style={{ cursor: 'pointer' }}>
                                {variable.value.substring(0, 20)}
                                {variable.value > 20 ? '...' : null}
                            </div>
                        </OverlayTrigger>
                        ) : null}</div>
                    })}
                </>
            )} />
        </div>
    );
}

export default SetVariable;