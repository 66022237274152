import * as Yup from "yup";

export const validationSchema = Yup.object({
    integrationName: Yup.string().required("Integration Name is required"),
    userEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    userPassword: Yup.string().required("Password is required"),
  });

 export  const shopifyValidationSchema = Yup.object({
    myShopifyShop: Yup.string().required("Shop Name is required"),
  });

  export const webEngageValidationSchema = Yup.object({
    webEngage: Yup.string().required("Integration is required"),
  });

 export  const calendlyValidationSchema = Yup.object({
    connectionName: Yup.string().required("Integration is required"),
  });

  export const wooCommerceCartValidationSchema = Yup.object().shape({
    brandName: Yup.string().required("Brand Name is required"),
    connection: Yup.string().required("Connection is required"),
    newConnectionName: Yup.string().when("connection", {
      is: "newConnection",
      then: Yup.string().required("New Connection Name is required"),
    }),
  });

 export  const stripeValidationSchema = Yup.object({
    integrationName: Yup.string().required("Integration is required"),
    tokenKey: Yup.string().required("Integration is required"),
  });

  export const cashFreeValidationSchema = Yup.object({
    integrationName: Yup.string().required("Integration is required"),
  });

  export const validationSchemaBrandName = Yup.object({
    brandName: Yup.string().required("Brand Name is required"),
  });
 export  const zohoValidationSchema = Yup.object({
    connectionName: Yup.string().required("Connection Name is required"),
  });
 export  const validationSchemaWoocomerce = Yup.object({
    ConnectionName: Yup.string().required("Connection name is required"),
    shopUrl: Yup.string().required("Shop Url is required"),
  });
