import React, { useState, useEffect } from 'react';
import { Form, Button, ListGroup, Row, Col } from 'react-bootstrap';

type OptionType = { id: string; label: string; value: string };

type TypeListProps = {
  typeList: { type: string; options: OptionType[] }[];
  selectedItems: { id: string; label: string }[]; // Adjust to store both id and label
  onApply: (selected: { id: string; label: string }[]) => void; // Adjust callback type
  onCancel: () => void;
};

const TypeList: React.FC<TypeListProps> = ({ typeList, selectedItems, onApply, onCancel }) => {
  const type = typeList.find(item => item.type === 'type');
  const [selectedOptions, setSelectedOptions] = useState<Map<string, string>>(new Map(
    selectedItems.map(item => [item.id, item.label]) // Initialize with id and label
  ));
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([]);

useEffect(() => {
  if (type?.options?.length) {
    setFilteredOptions(
      type.options.filter((option:any) =>
        !option.deleted && // Exclude deleted options
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }
}, [searchTerm, type]);


  const toggleOption = (id: string, label: string) => {
    setSelectedOptions(prevSelected => {
      const newSelected = new Map(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.set(id, label);
      }
      return newSelected;
    });
  };

  const handleClear = () => {
    setSelectedOptions(new Map());
    onApply([])
  };

  const handleApply = () => {
    onApply(Array.from(selectedOptions).map(([id, label]) => ({ id, label })));
  };

  return (
    <ListGroup variant="flush">
      <ListGroup.Item style={{ position: 'sticky', top: '0px', zIndex: 100000 }}>
        <Form.Control
          autoFocus
          className="w-auto mb-2"
          placeholder="Search Type"
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
          size="sm"
        />
      </ListGroup.Item>

      <Row style={{ position: 'sticky', zIndex: 100000, top: '50px',background:'white' }}>
        <Col>
          Selected: {selectedOptions.size}
        </Col>
        <Col>
        {selectedOptions.size > 0 &&(
          <Button onClick={handleClear} className="p-0 w-100 sendButton">
            Clear
          </Button>
        )}
        </Col>
      </Row>
      <Row><Col>
        {filteredOptions?.length === 0 && <div className='text-center' style={{color:'#7d7d7d'}}>Type Not Found</div>}
      </Col></Row>
      <ListGroup.Item>
        {filteredOptions.map(item => (
          <Form.Check
            key={item.id}
            type="checkbox"
            label={item.label}
            checked={selectedOptions.has(item.id)}
            onChange={() => toggleOption(item.id, item.label)}
          />
        ))}
      </ListGroup.Item>

      <ListGroup.Item className="d-flex" style={{ position: 'sticky', bottom: '0px' }}>
        <Button size="sm" className="cancelButton w-50 p-1" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="sm" className="applyButton p-1 w-50 ml-2" onClick={handleApply}
        disabled={selectedOptions?.size === 0}
        >
          Apply
        </Button>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default TypeList;
