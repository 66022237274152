import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
} from "react-bootstrap";
// import  {showAlertYesNo}  from "../../common/alertYesNo";
import * as XLSX from "xlsx";
import { Field } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Container, Label } from "reactstrap";
import "./Campaign.scss";

interface MyData {
  id: number;
  name: string;
}
const initialValues = {
  communityname: "",
};

const validationSchema = Yup.object({
  communityname: Yup.string().required("Community name is required"),
  // Add more validation rules for other form fields if needed
});
export interface UploadContactProps {
  getExcelData?: (data: any, excelfileName: string) => void;
  communitySelect: (Select: any) => void;
  selectedDataExcelCommunity: any;
  isChangeExcelStatus?: boolean;
  excelFileName?: string;
  removeExcelData: () => void;
  excelContactList:any;
  uploadExcelData:()=>void;
  // checkedData?:(data:any)=>void;
  // check:any;
}

export function UploadContact(props: UploadContactProps) {
  const [dataExcel, setData] = useState<MyData[]>([]);

  const [headerTable, setheaderTable] = useState([]);
  const [fileName, setFileName] = useState("");
  const dataToExport = [["Name",'Email', "Mobile Number", "Country Code",'Tags']];
  const [validName, setValidName] = useState(false);
  const [updateExcel, updateExcelsheet] = useState(false);
  const [excelAlerts, setAlerts] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [checkValidation,setValidation] = useState(true);


  const handleDragEnter = (e:any) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragOver = (e:any) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (e:any) => {
    e.preventDefault();
    setDragActive(false);
    const targetValue = e.dataTransfer.files;
    const filesArray = Array.from(targetValue);
    if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
      filesArray.map((item:any)=>{
        validExcel(item,'DRAG_EXCEL')
      })
    }
  };
  
  const handleChange = (e:any) => {
    if (e.target.files && e.target.files[0]) {
      importExcel(e.target.files);
    }
  };


  // const [validNumber,setValidNumber] = useState('')
  useEffect(() => {
    if (props.excelFileName) {
      setFileName(props.excelFileName);
    }
    if (props?.excelContactList?.length > 0) {
      const transformedData = props.excelContactList.map((item:any) => [
        item.name,
        item.email,
        item.mobileNumber,
        item.countryCode,
        item.tags
      ]);
      updateExcelsheet(true);
      setData(transformedData);
    }
    
  }, [props.excelFileName,props.excelContactList]);

  const exportToExcel = (data: any, filename: any) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // const getData = () =>{
    // }  

    // Set protection properties for the worksheet
    // const protectionOptions = {
    //   password: "mk",
    //   selectUnlockedCells: true, // Allow selection of unlocked cells
    //   selectLockedCells: false, // Disallow selection of locked cells
    //   formatCells: true, // Enable sheet protection
    // };
    // worksheet["!protect"] = protectionOptions;

    // Set the values of cells A1 and B1
    // worksheet["A2"] = { v: "name", t: "s", s: { locked: true } }; // Cell A1 is unlocked
    // worksheet["B2"] = { v: "mobilenumber", t: "s", s: { locked: true } }; // Cell B1 is unlocked
    // Set individual cell styles for locked cells in columns A and B
    // const lockedCells = [
    //   { c: 0, r: 0 }, // A1
    //   { c: 0, r: 1 }, // A2
    //   { c: 1, r: 0 }, // B1
    //   { c: 1, r: 1 }, // B2
    //   // ... add more cells if needed
    // ];

    // lockedCells.forEach((cell) => {
    //   const cellAddress = XLSX.utils.encode_cell(cell);
    //   const cellStyle = worksheet[cellAddress] || { t: "z" };
    //   cellStyle.s = { locked: true /* additional styles if needed */ };
    //   worksheet[cellAddress] = cellStyle;
    // });

// const headerCellRange = XLSX.utils.decode_range(worksheet['!ref'] as any);
//   headerCellRange.s = { c: 0, r: 0 }; 
//   headerCellRange.e = { c: 2, r: 0 }; 

// // Set cell protection properties
//     worksheet['!protect'] = {
//       selectLockedCells: false, // Users can't select locked cells
//       selectUnlockedCells: true, // Users can select unlocked cells
//     };

    XLSX.utils.book_append_sheet(workbook, worksheet, "upload");
    XLSX.writeFile(workbook, filename + ".xlsx");
  };
  const exportExcel = () => {
    exportToExcel(dataToExport, "Campaign_data_format");
  };

  const validExcel = (file:any,excelType:string) =>{
    if (!file || !file.name.match(/\.(xls|xlsx)$/i)) {
      setAlerts("Please select a valid Excel file.");
    } else {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "array",cellDates: true });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true, dateNF: 'mm/dd/yyyy' });
        // Assuming jsonData is an array of arrays (e.g., [['header1', 'header2'], ['row1value1', 'row1value2'], ...])
        let isValid = true;
        let fieldName = "";
        let lineNumber = 2;
 
        const [tableHead, ...tableHeadrem] = jsonData;
        setheaderTable(tableHead as []);
        setData(tableHeadrem as []);

        const [, ...restOfData] = jsonData;

        restOfData.map((row: any) => {
          const mobileNumberRegex = /^\d+$/;
          const [name,email, mobilenumber, country] = row;
          if(name === undefined && mobilenumber === undefined && country === undefined){
            return
          }
          else{
          if(row.length > 5){
            fieldName = "Please upload a valid file";
            isValid = false;
          }
          else{        
          switch (true) {
            case !name && !mobilenumber:
              fieldName = "Fill name & mobile number in row number "+lineNumber;
              isValid = false;
              break;
            case !mobilenumber && !country:
              fieldName = "Fill mobile number & country code in row number "+lineNumber;
              isValid = false;
              break;
            case !country && !name:
              fieldName = "Fill country code & name in row number "+lineNumber;
              isValid = false;
              break;
            case !name:
              fieldName = "Fill name in row number "+lineNumber;
              isValid = false;
              break;
            case !mobilenumber:
              fieldName = "Fill mobile number in row number "+lineNumber;
              isValid = false;
              break;
            case !mobileNumberRegex.test(mobilenumber):
              fieldName = "Please enter a valid mobile number in row number "+lineNumber;
              isValid = false;
              break;
            case mobilenumber.toString().length < 7:
                fieldName = "Mobile number should contain atleast 7 digits in row number "+lineNumber;
                isValid = false;
                break;
            case mobilenumber.toString().length > 15:
              fieldName = "Mobile number should not exceed 15 digits in row number "+lineNumber;
              isValid = false;
              break;
            case !country:
              fieldName = "Please enter a valid country code row number in row number "+lineNumber;
              isValid = false;
              break;
            case !mobileNumberRegex.test(country):
              fieldName = "Invalid country code in row number "+lineNumber;
              isValid = false;
              break;
            case country.toString().length >= 4:
              fieldName = "Country code should not exceed 4 digits in row number "+lineNumber;
              isValid = false;
              break;
            default:
              // Handle any other cases here
              break;
          }
        }  
        }
        lineNumber++;
        });
        

        if (!isValid) {
          setAlerts(`${fieldName}`);
          setValidation(false);
        } else if (tableHeadrem.length === 0) {
          setAlerts("Please upload a valid file");
          setValidation(false);
        } else {
          setAlerts("");
          setValidation(true);
          const { name } = file;
          if (name) {
            setFileName(name);
          }
          props.uploadExcelData()
          updateExcelsheet(true);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  const importExcel = (e: any) => {
    const file = e.target.files[0];
    if(file){
      validExcel(file,'EXCEL_SINGLE')
    }    
    e.target.value = null;
  };

  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const removeExcel = () => {
    props.removeExcelData();
    updateExcelsheet(false);
    setFileName("");
    setAlerts('')
    setData([]);
  };

  const excelData = () => {
    if (props.getExcelData) {
      const tableData = dataExcel.map((row: any, index: number) => {
        const name = row[0];
        const country = row[3];
        const mobileNumber = row[2];
        const tags = row[4]?.length > 0 ? row[4] : null; 
      
        let tagsArray;

        if (typeof tags === 'string') {
          tagsArray = tags.split(',');
        } else {
          tagsArray = tags;
        }
                
        if (name === undefined || country === undefined || mobileNumber === undefined  ) {
          return null; // Skip this row
        }
      
        return {
          id: index + 1,
          name: name,
          mobileNumber: mobileNumber,
          countryCode: country,
          tags:tagsArray
        };
      }).filter((rowData) => rowData !== null); // Remove rows where any of the required fields is undefined
      
      // Now you have filtered tableData without null rows, and you can safely access the 'id' property if it exists.
      
      props.getExcelData(tableData, fileName);
    }
    
    props.communitySelect("newCommunity");
  };

  return (
    <div className="UploadContactContainer">
      {/* {props.selectedDataExcelCommunity.length === 0 &&( */}
      <Container>
        {updateExcel || props?.isChangeExcelStatus ? (
          <Fragment>
            <div className="selected-file row">
              <Label className="h6 col-7">
                {typeof fileName === "string" &&
                (fileName as string)?.length > 15
                  ? (fileName as string).substring(0, 15) + "..."
                  : fileName}
              </Label>
              <Label htmlFor="actual-btn" className="uploadIcon ms-2 col-2">
                <FontAwesomeIcon icon={faCloudUpload} />
              </Label>
              {/* {props?.isChangeExcelStatus&& */}
              <Label className="deleteIcon ms-2 col-2">
                <FontAwesomeIcon icon={faTrashCan} onClick={removeExcel} />
              </Label>
              <Field
                type="file"
                name="file"
                id="actual-btn"
                accept=".xls, .xlsx"
                onChange={importExcel}
                className="form-control"
                style={{ display: "none" }}
              />
            </div>
              <span className="text-danger">{excelAlerts}</span>
          </Fragment>
        ) : (
          <>
            <div className="uploadExcel"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            >
              <p>Upload Contacts from Excel</p>
              <div className="drag">Drag & drop your files here </div>
              <div className="drag">or</div>
              <Label className="upload-files" htmlFor="actual-btn">
                Upload a file{"   "}
                <FontAwesomeIcon icon={faCloudUpload} />
              </Label>
              <Field
                type="file"
                name="file"
                multiple={true}
                accept=".xls, .xlsx"
                id="actual-btn"
                onChange={importExcel}
                className="form-control"
                style={{ display: "none" }}
              />
              {/* <div>
                {dataExcel.length === 0 && fileName && (fileName as string).length > 0 || validName === true ? 
                <span className="text-danger">Columns in excel cannot be empty</span> : null}
              </div> */}
              <span className="text-danger">{excelAlerts}</span>
              <p className="xlsx mt-2">Accepted file type: .XLS, .XLSX </p>
            </div>
            <p className="sampleDowncon">
              Please download sample file format to upload contacts.{" "}
              <a href="#" onClick={exportExcel}>
                Download Sample
              </a>
            </p>
          </>
        )}
        <div>
          {(dataExcel?.length > 0 && updateExcel && checkValidation )  &&(
            <Button
              type="button"
              className="sendButton float-right"
              // style={{ backgroundColor: "#007EC3" }}
              disabled={dataExcel?.length > 0 ? false : true}
              onClick={excelData}
            >
              Next
            </Button>
          )}
        </div>
      </Container>
      {/* )} */}
    </div>
  );
}

export default UploadContact;
