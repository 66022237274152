
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, FormGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faArrowLeft, faExclamation, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchInput from "../../common/searchComponent";
import BizTable from "../../common/BizTable";
import { ErrorImage } from "../../common/ErrorMessage";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { useSelector } from "react-redux";
import { FailedMessagesGetAll, SetRetryMessages } from "../../services/AnalyticsService";
import moment from "moment";
import Modall from "../../common/modall";
import FailedMessagesForm from "./FailedMessagesForm";
import { toast } from "../../common/alert";
import { Button } from "reactstrap";
import { LuFilter } from "react-icons/lu";
//import FailedMessageFilter from "./FailedMessageFilter";
import "./Analytics.scss";
import FailedMessageFilter from "./FailedMessageFilter";


const FailedMessages = () => {

    const [allData, setAlldata] = useState<any>([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [isClearSelected, setClearSelected] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [viewRetryMessages, setViewRetryMessages] = useState(false);

    const channelData = useSelector((state: any) => state.cartreducer.channelUid);
    const channelId = channelData?.channelAllData?.id;
    const selectedTableData = useSelector((state: any) => state.tableReducer.selectedUids);

    const [selectedCount, setSelectedCount] = useState(0);
    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const roleRules = useSelector((state: any) => state.cartreducer.roleRules);
    const [initialPages, setInitialPage] = useState<any>(false);
    const [isCustomFilter, setCustomFilter] = useState(false);
    const [filterData, setFilterData] = useState<any>();
    let filterPayload = useRef<any>(null);
    const business = useSelector((state: any) => state.cartreducer.business);
    const accountExpiry = moment(business?.plan?.expireAt).diff(moment(), "days") < 0 ? true : false;

    useEffect(() => {
        if (roleRules) {
            const isInvalidAccess = roleRules.canViewAnalyticsAndReport === true && roleRules.canManageAnalyticsAndReport === false;
            setIsInvalidAccess(isInvalidAccess);
        }
    }, [roleRules]);

    useEffect(() => {
        const initialSelected = Object.values(selectedTableData).flat();
        setSelectedRows(initialSelected);
        setSelectedCount(initialSelected.length);
    }, [selectedTableData])

    useEffect(() => {
        const failedMessagesElements = document.querySelectorAll<HTMLElement>('.FAILED_MESSAGES');
        failedMessagesElements.forEach((element) => {
            // For example, change overflow to hidden on an event
            element.style.overflowX = 'hidden';
        });
    }, [])


    const onSearchChange = (value: string) => {
        setSearchTerm(value);
    }

    const nameData = (cell: any, row: any) => {
        return (
            <div>
                {cell.length > 25 ? (
                    <TooltipReference
                        placement="bottom"
                        content={cell}
                        tooltipId={`nameData_${row.id}`}
                    >
                        {sliceText(cell, 25)}
                    </TooltipReference>
                ) : (
                    <span>{sliceText(cell, 25)}</span>
                )}
            </div>
        );
    };

    const moduleData = (cell: any, row: any) => {

        let module = "";

        switch (cell) {
            case 0:
                module = "Not Assigned";
                break;
            case 1:
                module = "Bot";
                break;
            case 2:
                module = "Member";
                break;
            case 3:
                module = "Team";
                break;
            case 4:
                module = "Campaign";
                break;
            case 5:
                module = "Sequence";
                break;
            case 6:
                module = "Appointments";
                break;
            case 7:
                module = "Integration";
                break;
            case 8:
                module = "Dev APIs";
                break;
        }


        return (
            <div>
                {module}
            </div>
        );
    };

    const mobileData = (cell: any, row: any) => {
        return (
            <div>
                <span>{cell?.substring(0, 1) === "+" ? (cell?.substring(0, 3) + " " + cell?.substring(3, cell?.length)) : ("+" + cell?.substring(0, 2) + " " + cell?.substring(2, cell?.length))}</span>
            </div>
        );
    };


    const columns = [
        {
            id: "selection",
            Header: ({
                getToggleAllRowsSelectedProps,
            }: {
                getToggleAllRowsSelectedProps: any;
            }) => (isInvalidAccess || accountExpiry ? <></> :
                <div>
                    <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
                </div>
            ),
            Cell: ({ row }: { row: any }) => (isInvalidAccess || accountExpiry ? <></> :
                <div>
                    <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                </div>
            ),
            accessor: "checkbox",
        },
        {
            Header: "Template Name",
            accessor: "templateName",
            sticky: "left",
            Cell: ({ cell, row }: any) => nameData(cell.value, row.original),
        },
        {
            Header: "Contact Name",
            accessor: "contactName",
            Cell: ({ cell, row }: any) => nameData(cell.value, row.original)
        },
        {
            Header: "Mobile Number",
            accessor: "mobileNumber",
            Cell: ({ cell, row }: any) => mobileData(cell.value, row.original)
        },
        {
            Header: "Sender Module",
            accessor: "assignTo",
            Cell: ({ cell, row }: any) => moduleData(cell.value, row.original)
        },
        {
            Header: "Date & Time",
            accessor: "createdAt",
            Cell: ({ cell, row }: any) => moment(cell.value).format("DD MMM hh.mm A")
        }
    ] as any;

    const fetchData = useCallback(
        async (
            pageIndex: number,
            pageSize: number,
            searchText: string,
            currentPage: number
        ): Promise<{ data: any; totalPages: number }> => {

            const listPayload = {
                page: searchTerm ? 0 : currentPage,
                limit: pageSize,
                search: searchTerm,
                channelId: channelId,
                startDate: filterPayload ? filterPayload.current?.startDate : null,
                endDate: filterPayload ? filterPayload.current?.endDate : null,
            };
            const response = await FailedMessagesGetAll(listPayload as any);
            if (response && response?.data?.failedMessages?.length > 0) {
                setAlldata(response.data?.failedMessages);
                searchTerm ? setRowCount(response?.data?.failedMessages?.length) : setRowCount(response.data?.count);
                return { data: response.data?.failedMessages, totalPages: response?.data?.count };
            }
            else {
                setRowCount(0);
                setAlldata([]);
                return { data: [], totalPages: 0 };
            }
        }, [channelId, searchTerm]
    );



    const clearSelection = () => {
        setClearSelected(true);
        const checkboxes = document.querySelectorAll<HTMLInputElement>(
            'input[type="checkbox"]'
        );
        checkboxes?.forEach((checkbox) => {
            if (checkbox.checked) {
                checkbox.click();
            }
            setTimeout(() => {
                setClearSelected(false);
            }, 3000);
        })
    };

    const setDuration = async (value: any) => {
        const messageIds = selectedRows.map((item: any) => item.messageId);
        const payload = {
            time: value === 30 ? 30000 : value,
            messageIds: messageIds
        }
        const response = await SetRetryMessages(payload);
        const checkboxes = document.querySelectorAll<HTMLInputElement>(
            'input[type="checkbox"]'
        );
        checkboxes?.forEach((checkbox) => {
            if (checkbox.checked) {
                checkbox.click();
            }
        });
        if (response?.data === "SUCCESS") {
            toast("success", "Messages Sent Successfully");
            fetchData(0, 20, '', 0);
        }
        else {
            toast('error', 'Messages Failed to Sent');
        }
        setViewRetryMessages(false);
    }


    const filterFunction = (filterData: any, filter: any, filterPayloads: any) => {
        if (!filter) {
            setInitialPage(true);
        }
        setFilterData(filter);
        filterPayload.current = filterPayloads;
        fetchData(0, 20, '', 0);
    }
    return (

        <Container>
            <Row className="align-items-center">
                <Col xs="auto">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                        <Button color="link" className="backBtn">
                            <FontAwesomeIcon icon={faArrowLeft} color="black" />
                        </Button>
                    </Link>
                </Col>
                <Col className="p-0" >
                    <h5 className="pt-2">
                        Failed Messages
                        <span className="ml-2">
                            <TooltipReference
                                placement="right"
                                icon={faInfoCircle}
                                content="You can retry the 131049 error code messages here."
                                tooltipId="template"
                            ></TooltipReference>
                        </ span>
                    </h5>
                </Col>
            </Row>

            <Row className="pt-2 pb-2 align-items-center">
                <Col md="4" className="p-0 d-flex align-items-center">
                    <div className="ms-2">
                        <SearchInput
                            onSearchChange={onSearchChange}
                            component="failedMessages"
                            placeHolder="Search Messages"
                        />
                    </ div>
                    <div
                        className={`${isCustomFilter ? "filterIcon-active" : ""} filterIcon`}
                        id="contact"
                        style={{ padding: "9px" }}
                        onClick={() => {
                            setCustomFilter(!isCustomFilter);
                            if (!isCustomFilter) {
                                const failedMessagesElements = document.querySelectorAll<HTMLElement>('.FAILED_MESSAGES');
                                failedMessagesElements.forEach((element) => {
                                    element.style.overflow = 'auto';
                                });
                            }
                            else {
                                const failedMessagesElements = document.querySelectorAll<HTMLElement>('.FAILED_MESSAGES');
                                failedMessagesElements.forEach((element) => {
                                    element.style.overflowX = 'hidden';
                                });
                            }
                        }} // Fixed syntax here
                    >
                        <LuFilter
                            color="#666E7D"
                            size={20}
                            fill={isCustomFilter ? "#666E7D" : "none"}
                        />
                    </div>
                </Col>

                <Col className="text-end">
                    <div className="actionBars">
                        {selectedRows?.length > 0 && (
                            <>
                                <Button
                                    variant=""
                                    type="button"
                                    className="sendButton p-2 mb-2"
                                    onClick={clearSelection}
                                >
                                    Clear
                                </Button>
                                <Button
                                    variant=""
                                    type="button"
                                    className="sendButton p-2 mb-2"
                                    onClick={() => { setViewRetryMessages(true); }}
                                >
                                    Retry Messages
                                </Button>
                            </>
                        )}
                    </div>
                </Col>
            </Row>

            <div className="d-flex justify-content-between">
                <div className="pb-1">
                    Selected:{" "}
                    <span className="selectedColor">
                        {selectedCount} { }
                        of {rowCount} Messages{" "}
                    </span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#5a5a5e", }} />
                    <b> 131049</b> - This message was not delivered to help maintain a healthy engagement ecosystem. (Meta chose not to deliver).
                </div>
            </div>

            {viewRetryMessages && (
                <>
                    <Modall
                        isOpen={viewRetryMessages}
                        onClose={() => { setViewRetryMessages(false); }}
                        title={"Retry Messages"}
                        size="md"
                        component='FAILED_MESSAGES'
                    >
                        <div className="retryMessages">
                            <FailedMessagesForm
                                onChange={setDuration}
                                onClose={() => { setViewRetryMessages(false); }}
                            />
                        </div>
                    </Modall>
                </>
            )}

            <Row className="fixedPosition">
                {isCustomFilter && (
                    <Col
                        md={2}
                        className={`contact-filter-container   p-1  ${false ? "move-left" : ""}`}
                    >
                        <FailedMessageFilter
                            contactsList={[]}
                            fetchData={() => { fetchData(0, 20, '', 0); }}
                            pageSize={rowCount}
                            filterFunction={filterFunction}
                            filterData={filterData}
                        />
                    </Col>
                )}
                <Col className={`${isCustomFilter ? "p-0 col-md-10" : ""}`} >
                    <BizTable
                        columns={columns}
                        fetchData={fetchData}
                        counts={rowCount}
                        refetchData={allData}
                        tableName='FAILED_MESSAGES'
                        isClear={isClearSelected}
                        initialPages={initialPages}
                        setInitialPages={setInitialPage}
                        searchTerm={searchTerm ?? ''}
                    />
                </Col>
            </Row>
        </Container>
    )
}


export default FailedMessages;
