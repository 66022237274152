import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Alert } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import { Container, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import DateRangePicker from "react-daterange-picker";
import moment, * as originalMoment from 'moment';
import { extendMoment } from "moment-range";
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCalendar, faDownload } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Avatar from 'react-avatar';
import { useMediaQuery } from "@uidotdev/usehooks";
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import styles from './CTWA.module.scss';
import SyncButton from '../../common/syncButton';
import * as campaignServices from '../../services/campaignService';
import * as ctwaServices from '../../services/CTWA';
import getAdjustedDateRange from '../../utils/getAdjustedDateRange';
import Loader from '../../shade/Loaders/smallLoader';
import NoDataFallback from '../../common/noDataFallback';
import { getConvBasedOnContact } from '../../services/conversationservice';
import { openSalesConversationContext } from '../../contexts/openSalesConversation';
import SearchBox from '../../botFlows/components/ui/searchBox';
import ExpandableParagraph from '../../common/expandableParagraph';
import { SOMETHING_WENT_WRONG } from '../../constants/errorMessage';
import { ErrorImage } from "../../common/ErrorMessage";
import BizTable from "../../common/BizTable";
import { processMobileNumber } from "../../common/commonfns";
import SearchInput from "../../common/searchComponent";
import { toast } from "../../common/alert";
import { Button as BootstrapButton } from 'react-bootstrap';


const momentRange = extendMoment(originalMoment);

enum DateFilterType {
    Custom,
    past7Days,
    ThisMonth,
    Last3Months
};

function extractDomainFromUrl(url: string): string | null {
    const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+).*$/;

    const match = url.match(domainRegex);

    if (match && match[1]) {
        return match[1];
    } else {
        return null;
    }
}

const formatDate = (date: Date, row: any) => {
    const today = moment();
    const targetDate = moment(date);

    if (targetDate.isSame(today, "day")) {
        return "Today";
    } else if (targetDate.isSame(today.clone().subtract(1, "days"), "day")) {
        return "Yesterday";
    } else if (targetDate.isSameOrAfter(today.clone().subtract(7, "days"), "day")) {
        return targetDate.format("dddd");
    } else {
        return targetDate.format("DD MMM");
    }
};


const CTWA: React.FC = () => {
    const [searchTerm, setSearchInput] = useState<string | null>();
    // const [searchQuery, setSearchQuery] = useState('');
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setSearchQuery(searchInput);
    //     }, 500);
    //     return () => clearTimeout(timer);

    // }, [searchInput]);
    const isLowResolution = useMediaQuery("(max-width: 1366px)");
    // useEffect(() => {
    //     const originalBackgroundColor = document.body.style.backgroundColor;
    //     document.body.style.backgroundColor = '#F6F6F6';
    //     return () => {
    //         document.body.style.backgroundColor = originalBackgroundColor;
    //     };
    // }, []);
    const today = momentRange();
    const navigate = useNavigate();
    const [isPiChartHovering, setisPiChartHovering] = useState(false);
    const [piChartHover, setPiChartHover] = useState(0);
    const [dateRangeValue, setDateRangeValue] = useState(momentRange.range(today.clone().subtract(7, "days"), today.clone().endOf('day'))); // default: 6 days before present day
    const [filterType, setFilterType] = useState<DateFilterType>(DateFilterType.past7Days);
    const channelUid = useSelector(
        (state: any) => state.cartreducer.channelUid?.value
    );
    const businessId = useSelector(
        (state: any) => state.cartreducer.business?.business?.id
    );
    const profielDetail = useSelector(
        (state: any) => state.cartreducer.business
    );
    const numberMasking = useSelector((state: any) => state.cartreducer.numberMasking);
    const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(dateRangeValue.start, dateRangeValue.end);
    const adjustedDateRange = {
        fromDate: adjustedStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        toDate: adjustedEnd.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    };

    const previousDateRange = useRef<{ fromDate: string; toDate: string } | null>(null);

    // const ctwaOverviewQuery = useQuery({
    //     queryKey: ['ctwa-overview', channelUid, JSON.stringify(adjustedDateRange), `?q=${searchQuery.trim()}`],
    //     queryFn: () => ctwaServices.getCtwaOverview(channelUid, searchQuery, adjustedDateRange)
    // });
    const [ctwaOverviewQuery, setCtwaOverviewQuery] = useState([]);

    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

    useEffect(() => {
        if (roleRules) {
            const isInvalidAccess = roleRules.canViewCTWAs === true && roleRules.canManageCTWAs === false;
            setIsInvalidAccess(isInvalidAccess);
        }
    }, [roleRules]);

    const [count, setCount] = useState(0);
    const columns = [
        {
            Header: (
                <input
                    type="checkbox"
                    hidden={true}
                // checked={this.state.allChecked}
                // onChange={this.toggleAllCheckboxes}
                />
            ),
            accessor: "checkbox",
            // Cell: this.checkboxCellRenderer,
            sticky: "left",
        },
        {
            Header: "Date",
            accessor: "createdAt",
            Cell: ({ cell, row }: any) => formatDate(cell.value, row.original),
            sticky: "left",
        },
        {
            Header: "Contact  Name",
            accessor: "contact",
            Cell: ({ cell, row }: any) => (
                <>
                    <Avatar fgColor="#fff" name={row.original.contact.name} round size='25' textSizeRatio={3.5} />
                    <span style={{ marginLeft: '5px', color: '#2E3967' }}>
                        {row.original.contact.name}
                    </span>
                </>
            )
        },
        {
            Header: "Phone Number",
            accessor: "mobileNumber",
            Cell: ({ cell, row }: any) => (
                <>
                    <a className={`${numberMask ? "numberMasking_Blur" : ''} mt-0`} href='javascript:void(0)' onClick={() => openInboxHandler(row.original.contact.uid)}>
                        {/* {row.original.contact.mobileNumber?.replace(/^(\d{2})(\d{10})$/, '+$1 $2') */}
                        {processMobileNumber(row.original.contact.countryCode, row.original.contact.mobileNumber)
                        }
                    </a>
                </>
            )
        },
        {
            Header: "Source Type",
            accessor: "sourceType",
        },
        {
            Header: "Source  Content",
            accessor: "body",
            Cell: ({ cell, row }: any) => (
                cell.value ? <ExpandableParagraph text={cell.value} /> : ""
            )
        },
        {
            Header: "Source URL",
            accessor: "sourceUrl",
            Cell: ({ cell, row }: any) => (
                <>
                    <a href={cell.value} target='_blank'>
                        {extractDomainFromUrl(cell.value) === "instagram.com" && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 30 31" fill="none" className="mr-1">
                                <path d="M22.5 2.19043H7.5C4.3934 2.19043 1.875 4.70883 1.875 7.81543V22.8154C1.875 25.922 4.3934 28.4404 7.5 28.4404H22.5C25.6066 28.4404 28.125 25.922 28.125 22.8154V7.81543C28.125 4.70883 25.6066 2.19043 22.5 2.19043Z" fill="url(#paint0_radial_5507_20893)" />
                                <path d="M22.5 2.19043H7.5C4.3934 2.19043 1.875 4.70883 1.875 7.81543V22.8154C1.875 25.922 4.3934 28.4404 7.5 28.4404H22.5C25.6066 28.4404 28.125 25.922 28.125 22.8154V7.81543C28.125 4.70883 25.6066 2.19043 22.5 2.19043Z" fill="url(#paint1_radial_5507_20893)" />
                                <path d="M22.5 2.19043H7.5C4.3934 2.19043 1.875 4.70883 1.875 7.81543V22.8154C1.875 25.922 4.3934 28.4404 7.5 28.4404H22.5C25.6066 28.4404 28.125 25.922 28.125 22.8154V7.81543C28.125 4.70883 25.6066 2.19043 22.5 2.19043Z" fill="url(#paint2_radial_5507_20893)" />
                                <path d="M21.5625 10.1592C21.5625 10.9358 20.9329 11.5654 20.1562 11.5654C19.3796 11.5654 18.75 10.9358 18.75 10.1592C18.75 9.38253 19.3796 8.75293 20.1562 8.75293C20.9329 8.75293 21.5625 9.38253 21.5625 10.1592Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 20.0029C17.5888 20.0029 19.6875 17.9042 19.6875 15.3154C19.6875 12.7266 17.5888 10.6279 15 10.6279C12.4112 10.6279 10.3125 12.7266 10.3125 15.3154C10.3125 17.9042 12.4112 20.0029 15 20.0029ZM15 18.1279C16.5533 18.1279 17.8125 16.8688 17.8125 15.3154C17.8125 13.7621 16.5533 12.5029 15 12.5029C13.4467 12.5029 12.1875 13.7621 12.1875 15.3154C12.1875 16.8688 13.4467 18.1279 15 18.1279Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.625 14.9404C5.625 11.7901 5.625 10.215 6.23809 9.01174C6.77738 7.95332 7.63789 7.0928 8.69631 6.55352C9.89953 5.94043 11.4747 5.94043 14.625 5.94043H15.375C18.5253 5.94043 20.1005 5.94043 21.3037 6.55352C22.3621 7.0928 23.2226 7.95332 23.7619 9.01174C24.375 10.215 24.375 11.7901 24.375 14.9404V15.6904C24.375 18.8407 24.375 20.4159 23.7619 21.6191C23.2226 22.6775 22.3621 23.5381 21.3037 24.0773C20.1005 24.6904 18.5253 24.6904 15.375 24.6904H14.625C11.4747 24.6904 9.89953 24.6904 8.69631 24.0773C7.63789 23.5381 6.77738 22.6775 6.23809 21.6191C5.625 20.4159 5.625 18.8407 5.625 15.6904V14.9404ZM14.625 7.81543H15.375C16.9811 7.81543 18.0728 7.81689 18.9168 7.88584C19.7389 7.953 20.1593 8.07473 20.4525 8.22415C21.1581 8.58368 21.7317 9.15736 22.0912 9.86293C22.2407 10.1562 22.3625 10.5766 22.4296 11.3986C22.4985 12.2426 22.5 13.3343 22.5 14.9404V15.6904C22.5 17.2966 22.4985 18.3883 22.4296 19.2322C22.3625 20.0543 22.2407 20.4747 22.0912 20.7679C21.7317 21.4735 21.1581 22.0471 20.4525 22.4067C20.1593 22.5561 19.7389 22.6779 18.9168 22.745C18.0728 22.8139 16.9811 22.8154 15.375 22.8154H14.625C13.0189 22.8154 11.9272 22.8139 11.0832 22.745C10.2611 22.6779 9.84075 22.5561 9.5475 22.4067C8.84193 22.0471 8.26825 21.4735 7.90872 20.7679C7.7593 20.4747 7.63757 20.0543 7.57041 19.2322C7.50146 18.3883 7.5 17.2966 7.5 15.6904V14.9404C7.5 13.3343 7.50146 12.2426 7.57041 11.3986C7.63757 10.5766 7.7593 10.1562 7.90872 9.86293C8.26825 9.15736 8.84193 8.58368 9.5475 8.22415C9.84075 8.07473 10.2611 7.953 11.0832 7.88584C11.9272 7.81689 13.0189 7.81543 14.625 7.81543Z" fill="white" />
                                <defs>
                                    <radialGradient id="paint0_radial_5507_20893" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.25 21.8779) rotate(-55.3758) scale(23.9246)">
                                        <stop stop-color="#B13589" />
                                        <stop offset="0.79309" stop-color="#C62F94" />
                                        <stop offset="1" stop-color="#8A3AC8" />
                                    </radialGradient>
                                    <radialGradient id="paint1_radial_5507_20893" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.3125 29.3779) rotate(-65.1363) scale(21.1821)">
                                        <stop stop-color="#E0E8B7" />
                                        <stop offset="0.444662" stop-color="#FB8A2E" />
                                        <stop offset="0.71474" stop-color="#E2425C" />
                                        <stop offset="1" stop-color="#E2425C" stop-opacity="0" />
                                    </radialGradient>
                                    <radialGradient id="paint2_radial_5507_20893" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.46875 3.12793) rotate(-8.1301) scale(36.4602 7.79846)">
                                        <stop offset="0.156701" stop-color="#406ADC" />
                                        <stop offset="0.467799" stop-color="#6A45BE" />
                                        <stop offset="1" stop-color="#6A45BE" stop-opacity="0" />
                                    </radialGradient>
                                </defs>
                            </svg>
                        )}
                        {(extractDomainFromUrl(cell.value) === "fb.me" || extractDomainFromUrl(cell.value) === "facebook.com") && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 26 26" fill="none" className='mr-1'>
                                <path d="M12.9619 0.590332C6.22174 0.590332 0.707031 6.20283 0.707031 13.1154C0.707031 19.3654 5.19232 24.5529 11.0502 25.4904V16.7404H7.93742V13.1154H11.0502V10.3528C11.0502 7.21533 12.8761 5.49033 15.6825 5.49033C17.0183 5.49033 18.4154 5.72783 18.4154 5.72783V8.81533H16.8712C15.3516 8.81533 14.8737 9.77783 14.8737 10.7654V13.1154H18.2806L17.7291 16.7404H14.8737V25.4904C17.7614 25.0251 20.3911 23.5222 22.2878 21.2529C24.1845 18.9836 25.2235 16.0974 25.2168 13.1154C25.2168 6.20283 19.7021 0.590332 12.9619 0.590332Z" fill="#4267B2" />
                            </svg>
                        )}
                        {extractDomainFromUrl(cell.value)}
                    </a>
                </>
            )
        },
    ] as any;

    // const prevDataRef = useRef(adjustedDateRange);

    // useEffect(() => {
    //   // Compare the current data with the previous data
    //   if (JSON.stringify(prevDataRef.current) !== JSON.stringify(adjustedDateRange)) {
    //     fetchData(0,20,'',0);
    //   }
    //   // Update the ref to the current data
    //   prevDataRef.current = adjustedDateRange;
    // }, [adjustedStart,adjustedEnd]);

    // const [subscriptionHistoryQuery,setSubscriptionHistoryQuery] = useState<any>([]);
    // const [count,setCount] = useState(0)
    console.log('adjustedStart,adjustedEnd', adjustedStart, adjustedEnd)
    const fetchData = useCallback(async (
        pageIndex: number,
        pageSize: number,
        searchText: string,
        currentPage: number
    ): Promise<{ data: any; totalPages: number }> => {
        // const newDateRange = {
        //     fromDate: adjustedStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        //     toDate: adjustedEnd.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        //   };

        //   // Check if the date range has changed
        //   if (previousDateRange.current?.fromDate === newDateRange.fromDate &&
        //       previousDateRange.current?.toDate === newDateRange.toDate) {
        //     console.log('Date range has not changed, skipping API call.');
        //     return { data: [], totalPages: 0 };
        //   }

        //   // Update previous date range
        //   previousDateRange.current = newDateRange;

        const payload = {
            page: currentPage,
            limit: pageSize,
            search: searchTerm,
            fromDate: adjustedStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            toDate: adjustedEnd.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        }
        const response = await ctwaServices.getCtwaOverview(channelUid, payload);
        // if(searchTerm){
        //     const ctwaChartQuery = useQuery({
        //         queryKey: ['ctwa-chart', channelUid, JSON.stringify(adjustedDateRange)],
        //         queryFn: () => ctwaServices.getCtwaChart(channelUid, adjustedDateRange)
        //     })
        // }    
        const { campaignReferrals, count } = response;
        setCount(count)
        if (campaignReferrals?.length > 0) {
            const updatedCampaignReferrals = campaignReferrals.map((item: any) => ({
                ...item,
                id: item.messageId, // Assuming you want to use 'messageId' as 'id'
            }));
            setCtwaOverviewQuery(updatedCampaignReferrals)

            return { data: campaignReferrals, totalPages: 100 }
        }
        else {
            setCtwaOverviewQuery([])
            return { data: [], totalPages: 0 }
        }
    }, [channelUid, searchTerm, dateRangeValue]);



    const ctwaChartQuery = useQuery({
        queryKey: ['ctwa-chart', channelUid, JSON.stringify(adjustedDateRange)],
        queryFn: () => ctwaServices.getCtwaChart(channelUid, adjustedDateRange)
    });
    const totalCreditsQuery = useQuery({
        queryKey: ['total-credits', businessId],
        queryFn: () => campaignServices.getTotalCredits(businessId)
    });
    const { clearConversation, setConversation } = useContext(openSalesConversationContext);
    useEffect(clearConversation, []);
    let isCampaignCreationEnabled = false;
    if (totalCreditsQuery.isLoading || totalCreditsQuery.isError) {
        isCampaignCreationEnabled = true;
    }
    else if ((totalCreditsQuery.data as { totalCredits: number }).totalCredits > 1) {
        isCampaignCreationEnabled = true;
    }

    const changeDateFilterHandler = (filter: DateFilterType) => {
        switch (filter) {
            case DateFilterType.past7Days:
                setDateRangeValue(momentRange.range(momentRange().clone().subtract(7, "days"), today.clone().endOf('day')));
                break;

            case DateFilterType.ThisMonth:
                setDateRangeValue(momentRange.range(momentRange().startOf('month'), today.endOf('day')));
                break;

            case DateFilterType.Last3Months:
                setDateRangeValue(momentRange.range(momentRange().subtract(3, 'months'), today.endOf('day')));
                break;

            default:
                break;
        }

        setFilterType(filter);
    }


    // Open contact in sales inbox
    const openInboxHandler = async (contactUid: string) => {
        try {
            const conversations = await getConvBasedOnContact(contactUid);
            setConversation(contactUid, conversations);
            navigate(`${process.env.PUBLIC_URL}/conversations/`, { state: { data: conversations } });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to open conversation'
            });
        }
    };

    const exportDataHandler = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        const headerRow = worksheet.getRow(1);
        headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF7342' } };
        headerRow.alignment = { horizontal: 'center' };

        worksheet.columns = [
            { header: "Date", width: 24, style: { numFmt: 'dd mmm yyyy hh:mm AM/PM' } },
            { header: "Contact Name", width: 22 },
            { header: "Contact Mobile Number", width: 27 },
            { header: "Source Type", width: 15 },
            { header: "Source URL", width: 20 },
        ];
        worksheet.views = [
            { state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'A2' }
        ];

        // ctwaOverviewQuery.data.campaignReferrals
        ctwaOverviewQuery?.map((campaignData: any) => ([
            moment(campaignData.createdAt).format('DD MMM YYYY hh:mm A'),
            campaignData.contact.name,
            campaignData.contact.mobileNumber,
            campaignData.sourceType,
            campaignData.sourceUrl
        ]))
            .forEach((row: any) => {
                worksheet.addRow(row);
            });

        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, `CTWA-Report.xlsx`);
        });
    }

    if (channelUid)
        if (
            // ctwaOverviewQuery?.isLoading ||
            ctwaChartQuery.isLoading) {
            return <Loader />;
        }

    if (
        // ctwaOverviewQuery.isError ||
        ctwaChartQuery.isError) {
        return (
            <Container>
                <Alert color="danger">{SOMETHING_WENT_WRONG}</Alert>
            </Container>
        )
    }

    if (!channelUid) {
        return (
            <main className={styles.CTWA}>
                <div className={styles.header}>
                    <h1 className={styles.caption}>Click to WhatsApp Overview</h1>
                    <div className={styles.buttons}>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={(
                            <Popover id="popover-basic" style={{ maxWidth: '350px' }}>
                                <DateRangePicker
                                    value={dateRangeValue}
                                    onSelect={(value: any) => {
                                        setDateRangeValue(value);
                                        setFilterType(DateFilterType.Custom);
                                    }}
                                    singleDateRange={true}
                                />
                            </Popover>
                        )}>
                            <div className={['form-control', styles.calenderInput].join(' ')}>
                                <FontAwesomeIcon icon={faCalendar} className='mr-2' />
                                <span className='value'>
                                    {dateRangeValue.start.format("DD/MM/YYYY")}
                                    {" - "}
                                    {dateRangeValue.end.format("DD/MM/YYYY")}
                                </span>
                            </div>
                        </OverlayTrigger>
                        <SyncButton onClick={() => {
                            // ctwaOverviewQuery.refetch();
                            fetchData(0, 20, '', 0);
                            ctwaChartQuery.refetch();
                        }} />
                        <OverlayTrigger
                            placement="bottom"
                            overlay={!isCampaignCreationEnabled ? (
                                <Tooltip
                                    placement="bottom"
                                    id="no-coins"
                                >
                                    {!profielDetail?.business?.freeTrial ? "You don't have enough credits" :
                                        "To use this feature, upgrade your plan. It's not included in your trial"}
                                </Tooltip>
                            ) : <></>}
                        >
                            {/* <span style={{ cursor: !isCampaignCreationEnabled ? 'not-allowed' : 'pointer', pointerEvents: "none" }}>
                                <a href="https://adsmanager.facebook.com/" target='_blank'> */}
                            <BootstrapButton color='primary' className='signUpBtn mt-0 p-2' disabled={!isCampaignCreationEnabled || isInvalidAccess}>
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-2' />
                                Create  Campaign
                            </ BootstrapButton>
                            {/* </a>
                            </span> */}
                        </OverlayTrigger>
                    </div>
                </div>
                <Alert color='danger' className='mt-2'>
                    No Updates Available
                </Alert>
            </main>
        )
    }

    const piChartData = [
        { name: 'Instagram', value: (ctwaChartQuery.data.igCount) },
        { name: 'Facebook', value: (ctwaChartQuery.data.fbPostCount) },
        { name: 'Facebook Ads', value: (ctwaChartQuery.data.fbAdCount) },
        { name: 'Google', value: (ctwaChartQuery.data.googleCount) },
        { name: 'Others', value: (ctwaChartQuery.data.othersCount) },
    ];
    const piChartColours = ['#C168EB', '#A6C3FF', '#4267B2', '#CBCBCB', '#007bff'];
    const numberMask = localStorage.getItem('numberMask') === 'true' || numberMasking;
    const onSearchChange = (searchTerm: string) => {
        // const numericValue = searchTerm.replace(/[^0-9]/g, '');
        const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
        setSearchInput(searchTermCheck);
    };


    return (
        <main className={styles.CTWA}>
            <div className={styles.header}>
                <h1 className={styles.caption}>Click to WhatsApp Overview</h1>
                <div className={styles.buttons}>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={(
                        <Popover id="popover-basic" style={{ maxWidth: '350px' }}>
                            <DateRangePicker
                                value={dateRangeValue}
                                onSelect={(value: any) => {
                                    setDateRangeValue(value);
                                    setFilterType(DateFilterType.Custom);
                                }}
                                singleDateRange={true}
                                maximumDate={today.endOf("day")}
                            />
                        </Popover>
                    )}>
                        <div className={['form-control', styles.calenderInput].join(' ')}>
                            <FontAwesomeIcon icon={faCalendar} className='mr-2' />
                            <span className='value'>
                                {dateRangeValue.start.format("DD/MM/YYYY")}
                                {" - "}
                                {dateRangeValue.end.format("DD/MM/YYYY")}
                            </span>
                        </div>
                    </OverlayTrigger>
                    <SyncButton onClick={() => {
                        // ctwaOverviewQuery.refetch();
                        fetchData(0, 20, '', 0);
                        ctwaChartQuery.refetch();
                        setTimeout(() => {
                            toast("success", "CTWA synced successfully");
                        }, 1000)
                    }} />
                    <OverlayTrigger
                        placement="bottom"
                        overlay={!isCampaignCreationEnabled ? (
                            <Tooltip
                                placement="bottom"
                                id="no-coins"
                            >
                                {!profielDetail?.business?.freeTrial ? "You don't have enough credits" :
                                    "To use this feature, upgrade your plan. It's not included in your trial"}

                            </Tooltip>
                        ) : <></>}
                    >
                        <span style={{cursor: isInvalidAccess ? "not-allowed" : "pointer"}} >
                        <div style={{opacity: isInvalidAccess ? 0.5 : 1, pointerEvents: isInvalidAccess ? "none" : "all" }} >
                            <span style={{ cursor: !isCampaignCreationEnabled ? 'not-allowed' : 'pointer' }}>
                                <a href="https://adsmanager.facebook.com/" target='_blank'>
                                    <Button color='primary' className='signUpBtn mt-0 p-2' disabled={!isCampaignCreationEnabled}>
                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='mr-2' />
                                        Create  Campaign
                                    </Button>
                                </a>
                            </span>
                        </div>
                        </span>
                    </OverlayTrigger>
                </div>
            </div>
            <Row className={styles.stats}>
                <Col >
                    <div className={[styles.statsCard, styles.card].join(' ')}>
                        <img src={`${process.env.PUBLIC_URL}/images/ctwa/instagram.svg`} alt='' />
                        <div>
                            <span className={styles.count}>{ctwaChartQuery.data.igCount}</span>
                            <span className={styles.label}>Instagram</span>
                        </div>
                    </div>
                </Col>
                <Col >
                    <div className={[styles.statsCard, styles.card].join(' ')}>
                        <img src={`${process.env.PUBLIC_URL}/images/ctwa/facebook.svg`} alt='' />
                        <div>
                            <span className={styles.count}>{ctwaChartQuery.data.fbPostCount}</span>
                            <span className={styles.label}>Facebook Post</span>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className={[styles.statsCard, styles.card].join(' ')}>
                        <img src={`${process.env.PUBLIC_URL}/images/ctwa/fbads.svg`} alt='' />
                        <div>
                            <span className={styles.count}>{ctwaChartQuery.data.fbAdCount}</span>
                            <span className={styles.label}>Facebook Ads</span>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className={[styles.statsCard, styles.card].join(' ')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#5D8CA6" className="bi bi-google me-2" viewBox="0 0 16 16"> <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" /> </svg>
                        {/* <img src={`${process.env.PUBLIC_URL}/images/ctwa/instagram.svg`} alt='' /> */}
                        <div>
                            <span className={styles.count}>{ctwaChartQuery.data.googleCount}</span>
                            <span className={styles.label}>Google</span>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className={[styles.statsCard, styles.card].join(' ')}>
                        <img src={`${process.env.PUBLIC_URL}/images/ctwa/others.svg`} alt='' />
                        <div>
                            <span className={styles.count}>{ctwaChartQuery.data.othersCount}</span>
                            <span className={styles.label}>Others</span>
                        </div>
                    </div>
                </Col>
            </Row>
            {ctwaChartQuery.data.totalCount > 0 ? (
                <Row className='mt-4'>
                    <Col className={['card', styles.card, styles.graphSection].join(' ')} xs='8'>
                        <div className={styles.filters}>
                            <div className={styles.selectedDuration}>
                                <Button
                                    size='sm'
                                    outline={filterType !== DateFilterType.past7Days}
                                    color={filterType === DateFilterType.past7Days ? 'primary' : 'dark'}
                                    onClick={() => changeDateFilterHandler(DateFilterType.past7Days)}
                                >
                                    Past 7 days
                                </Button>
                                <Button
                                    size='sm'
                                    outline={filterType !== DateFilterType.ThisMonth}
                                    color={filterType === DateFilterType.ThisMonth ? 'primary' : 'dark'}
                                    onClick={() => changeDateFilterHandler(DateFilterType.ThisMonth)}
                                >
                                    This month
                                </Button>
                                <Button
                                    size='sm'
                                    outline={filterType !== DateFilterType.Last3Months}
                                    color={filterType === DateFilterType.Last3Months ? 'primary' : 'dark'}
                                    onClick={() => changeDateFilterHandler(DateFilterType.Last3Months)}
                                >
                                    Last 3 months
                                </Button>
                            </div>
                        </div>
                        <ResponsiveContainer width='100%' height={275} className={styles.graph}>
                            <BarChart
                                width={500}
                                height={300}
                                data={ctwaChartQuery.data.dayWiseData.map((item: any) => {
                                    return {
                                        name: moment(item.formattedDate, "YYYY-MM-DD").format("MMM DD"),
                                        "FB Ads": item.fbAd,
                                        "FB Post": item.fbPost,
                                        "Instagram": item.igCount,
                                        "Google": item.googleCount,
                                        "Others": item.others
                                    }
                                })}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend align='right' />
                                <Bar maxBarSize={40} dataKey="Others" stackId="a" fill="#CBCBCB" />
                                <Bar maxBarSize={40} dataKey="Instagram" stackId="a" fill="#C168EB" />
                                <Bar maxBarSize={40} dataKey="FB Post" stackId="a" fill="#A6C3FF" />
                                <Bar maxBarSize={40} dataKey="FB Ads" stackId="a" fill="#4267B2" />
                                <Bar maxBarSize={40} dataKey="Google" stackId="a" fill="#007bff" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col className={['card', styles.card].join(' ')}>
                        <PieChart width={isLowResolution ? 320 : 420} height={isLowResolution ? 300 : 400}>
                            <Pie
                                data={piChartData}
                                style={{ zIndex: 1000 }}
                                cx={isLowResolution ? 155 : 200}
                                cy={isLowResolution ? 155 : 200}
                                innerRadius={isLowResolution ? 45 : 70}
                                outerRadius={isLowResolution ? 75 : 100}
                                fill="#8884d8"
                                paddingAngle={isLowResolution ? 3 : 5}
                                dataKey="value"
                                activeIndex={piChartHover}
                                onMouseEnter={(_, index) => {
                                    setPiChartHover(index);
                                    setisPiChartHovering(true);
                                }}
                                onMouseOut={() => {
                                    setPiChartHover(0);
                                    setisPiChartHovering(false)
                                }}
                                activeShape={(props: any) => {
                                    const RADIAN = Math.PI / 180;
                                    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, payload, value } = props;

                                    const sin = Math.sin(-RADIAN * midAngle);
                                    const cos = Math.cos(-RADIAN * midAngle);
                                    const sx = cx + (outerRadius + 10) * cos;
                                    const sy = cy + (outerRadius + 10) * sin;
                                    const mx = cx + (outerRadius + 30) * cos;
                                    const my = cy + (outerRadius + 30) * sin;
                                    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                                    const ey = my;
                                    const textAnchor = cos >= 0 ? 'start' : 'end';


                                    return (
                                        <g>
                                            <text x={cx} y={cy - (isLowResolution ? 4 : 0)} dy={8} font-size={isLowResolution ? "24" : "35"} font-weight="600" textAnchor="middle" fill="#00b598">
                                                {ctwaChartQuery.data.totalCount}
                                            </text>
                                            <text x={cx} y={cy + (isLowResolution ? 16 : 25)} dy={8} font-size={isLowResolution ? "12" : "16"} font-weight="400" textAnchor="middle" fill="#959595">
                                                Total
                                            </text>
                                            <Sector
                                                cx={cx}
                                                cy={cy}
                                                innerRadius={innerRadius}
                                                outerRadius={outerRadius}
                                                startAngle={startAngle}
                                                endAngle={endAngle}
                                                fill={fill}
                                            />
                                            {isPiChartHovering ? (
                                                <>
                                                    <Sector
                                                        cx={cx}
                                                        cy={cy}
                                                        startAngle={startAngle}
                                                        endAngle={endAngle}
                                                        innerRadius={outerRadius + 6}
                                                        outerRadius={outerRadius + 10}
                                                        fill={fill}
                                                    />
                                                    <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                                                    <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                                                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" font-size={isLowResolution ? 10 : 14}>{payload.name}</text>
                                                    <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" font-size={isLowResolution ? 10 : 12}>
                                                        {`(${(percent * 100).toFixed(2)}%)`}
                                                    </text>
                                                </>
                                            ) : null}
                                        </g>
                                    );
                                }}
                            >
                                {piChartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={piChartColours[index % piChartColours.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </Col>
                </Row>
            ) : null}
            <section className={[styles.datatable, 'mt-4'].join(' ')}>
                <div className='d-flex justify-content-between mb-3'>
                    <div>
                        <h1 className={styles.caption}>Ad Performance</h1>
                        <span className='dimmed'>Measure the performance and impact of your Click-to-WhatsApp ads here.</span>
                    </div>
                    {/* {
                    // ctwaOverviewQuery.data.campaignReferrals.length
                    ctwaOverviewQuery?.length > 0 ? ( */}
                    <div className={styles.tableAction}>
                        <Button color='light' className={styles.downloadBtn}
                            disabled={ctwaOverviewQuery?.length > 0 ? false : true}
                            onClick={exportDataHandler}>
                            <FontAwesomeIcon icon={faDownload} className='mr-2' />
                            Export
                        </Button>
                        <div className={styles.searchArea}>
                            {/* <SearchBox
                                value={searchInput}
                                onChange={(e: any) => {
                              
                                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                    const searchTermCheck = numericValue.length > 0 ? numericValue : null;
                                    // Allow only numeric values
                                    setSearchInput(searchTermCheck);
                                }}
                                placeholder='Search by Phone Number'
                            /> */}
                            <SearchInput component="ctwa" onSearchChange={onSearchChange}
                                placeHolder="Search by Phone Number"
                            />
                        </div>
                    </div>
                    {/* ) : null} */}
                </div>
                {/* 
                // <BootstrapTable
                //     data={ctwaOverviewQuery.data.campaignReferrals}
                //     search={false}
                //     pagination={ctwaOverviewQuery.data.campaignReferrals.length > 10}
                //     version="4"
                //     striped
                //     hover
                //     containerStyle={{ textAlign: "left", marginBottom: 80 }}
                //     options={{
                //         headerAlign: "center",
                //         noDataText: <NoDataFallback message='No Contacts Found' />,
                //         sortIndicator: true,
                //         hidePageListOnlyOnePage: true,
                //         clearSearch: false,
                //         alwaysShowAllBtns: true,
                //         withFirstAndLast: true,
                //         sizePerPage: 10,
                //         paginationPosition: "bottom",
                //     } as any}
                // >
                //     <TableHeaderColumn dataField="messageId" isKey hidden>
                //         #
                //     </TableHeaderColumn>
                //     <TableHeaderColumn
                //         dataField="createdAt"
                //         dataFormat={date => formatDate(date)}
                //     >
                //         Date
                //     </TableHeaderColumn>
                //     <TableHeaderColumn
                //         dataField="contact"
                //         dataFormat={contact => (
                //             <>
                //                 <Avatar fgColor="#fff" name={contact.name} round size='25' textSizeRatio={3.5} />
                //                 <span style={{ marginLeft: '5px', color: '#2E3967' }}>
                //                     {contact.name}
                //                 </span>
                //             </>
                //         )}
                //     >
                //         Contact  Name
                //     </TableHeaderColumn>
                //     <TableHeaderColumn
                //         dataField="contact"
                //         dataFormat={contact => (
                //             <a className={`${numberMask ? "numberMasking_Blur" :''} mt-0`} href='javascript:void(0)' onClick={() => openInboxHandler(contact.uid)}>
                //                 {contact.mobileNumber?.replace(/^(\d{2})(\d{10})$/, '+$1 $2')}
                //             </a>
                //         )}
                //     >
                //         Phone Number
                //     </TableHeaderColumn>
                //     <TableHeaderColumn
                //         dataField="sourceType"
                //     >
                //         Source Type
                //     </TableHeaderColumn>
                //     <TableHeaderColumn
                //         dataField="body"
                //         dataFormat={content => content ? <ExpandableParagraph text={content} /> : ""}
                //     >
                //         Source  Content
                //     </TableHeaderColumn>
                //     <TableHeaderColumn
                //         dataField="sourceUrl"
                //         dataFormat={url => (
                //             <a href={url} target='_blank'>
                //                 {extractDomainFromUrl(url) === "instagram.com" && (
                //                     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 30 31" fill="none" className="mr-1">
                //                         <path d="M22.5 2.19043H7.5C4.3934 2.19043 1.875 4.70883 1.875 7.81543V22.8154C1.875 25.922 4.3934 28.4404 7.5 28.4404H22.5C25.6066 28.4404 28.125 25.922 28.125 22.8154V7.81543C28.125 4.70883 25.6066 2.19043 22.5 2.19043Z" fill="url(#paint0_radial_5507_20893)" />
                //                         <path d="M22.5 2.19043H7.5C4.3934 2.19043 1.875 4.70883 1.875 7.81543V22.8154C1.875 25.922 4.3934 28.4404 7.5 28.4404H22.5C25.6066 28.4404 28.125 25.922 28.125 22.8154V7.81543C28.125 4.70883 25.6066 2.19043 22.5 2.19043Z" fill="url(#paint1_radial_5507_20893)" />
                //                         <path d="M22.5 2.19043H7.5C4.3934 2.19043 1.875 4.70883 1.875 7.81543V22.8154C1.875 25.922 4.3934 28.4404 7.5 28.4404H22.5C25.6066 28.4404 28.125 25.922 28.125 22.8154V7.81543C28.125 4.70883 25.6066 2.19043 22.5 2.19043Z" fill="url(#paint2_radial_5507_20893)" />
                //                         <path d="M21.5625 10.1592C21.5625 10.9358 20.9329 11.5654 20.1562 11.5654C19.3796 11.5654 18.75 10.9358 18.75 10.1592C18.75 9.38253 19.3796 8.75293 20.1562 8.75293C20.9329 8.75293 21.5625 9.38253 21.5625 10.1592Z" fill="white" />
                //                         <path fill-rule="evenodd" clip-rule="evenodd" d="M15 20.0029C17.5888 20.0029 19.6875 17.9042 19.6875 15.3154C19.6875 12.7266 17.5888 10.6279 15 10.6279C12.4112 10.6279 10.3125 12.7266 10.3125 15.3154C10.3125 17.9042 12.4112 20.0029 15 20.0029ZM15 18.1279C16.5533 18.1279 17.8125 16.8688 17.8125 15.3154C17.8125 13.7621 16.5533 12.5029 15 12.5029C13.4467 12.5029 12.1875 13.7621 12.1875 15.3154C12.1875 16.8688 13.4467 18.1279 15 18.1279Z" fill="white" />
                //                         <path fill-rule="evenodd" clip-rule="evenodd" d="M5.625 14.9404C5.625 11.7901 5.625 10.215 6.23809 9.01174C6.77738 7.95332 7.63789 7.0928 8.69631 6.55352C9.89953 5.94043 11.4747 5.94043 14.625 5.94043H15.375C18.5253 5.94043 20.1005 5.94043 21.3037 6.55352C22.3621 7.0928 23.2226 7.95332 23.7619 9.01174C24.375 10.215 24.375 11.7901 24.375 14.9404V15.6904C24.375 18.8407 24.375 20.4159 23.7619 21.6191C23.2226 22.6775 22.3621 23.5381 21.3037 24.0773C20.1005 24.6904 18.5253 24.6904 15.375 24.6904H14.625C11.4747 24.6904 9.89953 24.6904 8.69631 24.0773C7.63789 23.5381 6.77738 22.6775 6.23809 21.6191C5.625 20.4159 5.625 18.8407 5.625 15.6904V14.9404ZM14.625 7.81543H15.375C16.9811 7.81543 18.0728 7.81689 18.9168 7.88584C19.7389 7.953 20.1593 8.07473 20.4525 8.22415C21.1581 8.58368 21.7317 9.15736 22.0912 9.86293C22.2407 10.1562 22.3625 10.5766 22.4296 11.3986C22.4985 12.2426 22.5 13.3343 22.5 14.9404V15.6904C22.5 17.2966 22.4985 18.3883 22.4296 19.2322C22.3625 20.0543 22.2407 20.4747 22.0912 20.7679C21.7317 21.4735 21.1581 22.0471 20.4525 22.4067C20.1593 22.5561 19.7389 22.6779 18.9168 22.745C18.0728 22.8139 16.9811 22.8154 15.375 22.8154H14.625C13.0189 22.8154 11.9272 22.8139 11.0832 22.745C10.2611 22.6779 9.84075 22.5561 9.5475 22.4067C8.84193 22.0471 8.26825 21.4735 7.90872 20.7679C7.7593 20.4747 7.63757 20.0543 7.57041 19.2322C7.50146 18.3883 7.5 17.2966 7.5 15.6904V14.9404C7.5 13.3343 7.50146 12.2426 7.57041 11.3986C7.63757 10.5766 7.7593 10.1562 7.90872 9.86293C8.26825 9.15736 8.84193 8.58368 9.5475 8.22415C9.84075 8.07473 10.2611 7.953 11.0832 7.88584C11.9272 7.81689 13.0189 7.81543 14.625 7.81543Z" fill="white" />
                //                         <defs>
                //                             <radialGradient id="paint0_radial_5507_20893" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.25 21.8779) rotate(-55.3758) scale(23.9246)">
                //                                 <stop stop-color="#B13589" />
                //                                 <stop offset="0.79309" stop-color="#C62F94" />
                //                                 <stop offset="1" stop-color="#8A3AC8" />
                //                             </radialGradient>
                //                             <radialGradient id="paint1_radial_5507_20893" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.3125 29.3779) rotate(-65.1363) scale(21.1821)">
                //                                 <stop stop-color="#E0E8B7" />
                //                                 <stop offset="0.444662" stop-color="#FB8A2E" />
                //                                 <stop offset="0.71474" stop-color="#E2425C" />
                //                                 <stop offset="1" stop-color="#E2425C" stop-opacity="0" />
                //                             </radialGradient>
                //                             <radialGradient id="paint2_radial_5507_20893" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.46875 3.12793) rotate(-8.1301) scale(36.4602 7.79846)">
                //                                 <stop offset="0.156701" stop-color="#406ADC" />
                //                                 <stop offset="0.467799" stop-color="#6A45BE" />
                //                                 <stop offset="1" stop-color="#6A45BE" stop-opacity="0" />
                //                             </radialGradient>
                //                         </defs>
                //                     </svg>
                //                 )}
                //                 {(extractDomainFromUrl(url) === "fb.me" || extractDomainFromUrl(url) === "facebook.com") && (
                //                     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 26 26" fill="none" className='mr-1'>
                //                         <path d="M12.9619 0.590332C6.22174 0.590332 0.707031 6.20283 0.707031 13.1154C0.707031 19.3654 5.19232 24.5529 11.0502 25.4904V16.7404H7.93742V13.1154H11.0502V10.3528C11.0502 7.21533 12.8761 5.49033 15.6825 5.49033C17.0183 5.49033 18.4154 5.72783 18.4154 5.72783V8.81533H16.8712C15.3516 8.81533 14.8737 9.77783 14.8737 10.7654V13.1154H18.2806L17.7291 16.7404H14.8737V25.4904C17.7614 25.0251 20.3911 23.5222 22.2878 21.2529C24.1845 18.9836 25.2235 16.0974 25.2168 13.1154C25.2168 6.20283 19.7021 0.590332 12.9619 0.590332Z" fill="#4267B2" />
                //                     </svg>
                //                 )}
                //                 {extractDomainFromUrl(url)}
                //             </a>
                //         )}
                //     >
                //         Source URL
                //     </TableHeaderColumn>
                // </BootstrapTable> */}

                <BizTable
                    columns={columns}
                    fetchData={fetchData}
                    counts={count}
                    refetchData={ctwaOverviewQuery}
                    tableName='CTWA'
                />

            </section>
        </main>
    );
};

export default CTWA;
