import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Container, Form, FormFeedback, FormGroup, Label } from 'reactstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import DatePicker from 'react-date-picker'
import ReactSelect from 'react-select'

import styles from './RescheduleForm.module.scss'
import * as appointmentService from '../../services/appointmentService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IgnoreDate, generateTimeSlots } from './AppointmentForm'
import { toast } from '../../common/alert'
import { GetTimeSlotInterface, getTimeSlots } from '../../services/appointmentService'
import { useSelector } from 'react-redux'

interface FormData {
    rescheduleData: {
        date: string,
        timeslot: { startTime: string, endTime: string },
        rescheduledBy: 'MEMBER'
    }
}

type ErrorProps = {
    message: string
}

function Error({ message }: ErrorProps) {
    return (
        <div className='invalid-feedback' style={{ display: 'block' }}>{message}</div>
    )
}

type RescheduleFormProps = {
    onCancel: () => void,
    onSubmit: () => void,
    create?: boolean,
    data?: any,
    calendar: any,
    holidayList: undefined | appointmentService.IHolidayList
}

function RescheduleForm({
    data,
    onSubmit,
    onCancel,
    create,
    calendar,
    holidayList
}: RescheduleFormProps) {

    interface SelectTimeSlot{
        startTime:string;
        endTime:string;
    }
    
    
    const [timeslotdata,setTimeSlotData]=useState<SelectTimeSlot[]>([])
    const timeref=useRef<SelectTimeSlot[]>([])
     
  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const channelId = channelData?.value;

    const timeSlot = useMemo(() => {
        if (calendar) {
            return generateTimeSlots(calendar)
        }
        return null
    }, [calendar])
    const getFalseDays = (startDate: string, endDate: string, daysOfWeek: boolean[]): string[] => {
        const falseDays: string[] = [];
        const start = new Date(startDate);
        const end = new Date(endDate);
    
        let currentDate = new Date(start);
        while (currentDate <= end) {
            const dayOfWeek = (currentDate.getDay() + 6) % 7; 
            if (!daysOfWeek[dayOfWeek]) {
                falseDays.push(currentDate.toISOString().split('T')[0]);
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        return falseDays;
    };
    
    const ignoreDates = useMemo(() => {
        const dates: IgnoreDate[] = [];
        if (holidayList)
            holidayList.listItem.forEach(item => dates.push({
                dateStr: item.date,
                strict: false
            }));
        calendar?.unavailableDate?.forEach((dateStr: string) => dates.push({
            dateStr,
            strict: true
        }));
        const { startDate, endDate } = calendar?.dateRange;
        if (calendar?.availableHour && calendar?.availableHour?.length > 0) {
            const combinedAvailableDays = Array(7).fill(false);
    
            calendar?.availableHour?.forEach((availableHour: any) => {
                availableHour?.day.forEach((day: boolean, index: number) => {
                    combinedAvailableDays[index] = combinedAvailableDays[index] || day;
                });
            });
             
            const falseDays = getFalseDays(startDate, endDate, combinedAvailableDays);
    
            falseDays.forEach(dateStr => dates.push({
                dateStr,
                strict: true
            }));
        }
        return dates;
    
    }, [holidayList, calendar]);

    const schema = yup.object().shape({
        rescheduleData: yup.object().shape({
            date: yup.string().required('Date is required'),
            timeslot: yup.object().shape({
                startTime: yup.string().required('Start Time is required'),
                endTime: yup.string().required('End Time is required'),
            }).nullable() // Allow null or undefined to be processed
            .required('Time slot is required'), // Custom message for null or undefined
        }),
    })

    const today = useMemo(() => moment().startOf('day'), []);
    const parsedIgnoreDates = useMemo(() => {
        return ignoreDates.map(ignoreDateObj => ({
            ...ignoreDateObj,
            parsedDate: moment(ignoreDateObj.dateStr, 'YYYY-MM-DD')
        }));
    }, [ignoreDates]);
    const isDateIgnored = (date:any) => {
        return parsedIgnoreDates.some(ignoreDateObj => {
            const isStrictMatch = ignoreDateObj.strict && date.isSame(ignoreDateObj.parsedDate, 'day');
            const isNonStrictMatch = !ignoreDateObj.strict && date.month() === ignoreDateObj.parsedDate.month() && date.date() === ignoreDateObj.parsedDate.date();
            return isStrictMatch || isNonStrictMatch;
        });
    };

    const isTileDisabled = (tile:any) => {
        if (tile.view !== 'month') return false;
        
        const tileDate = moment(tile.date);
        const isToday = tileDate.isSame(today, 'day');
        const isTodayIgnored = parsedIgnoreDates.some(dateObj => {
            return dateObj.strict 
                ? today.isSame(dateObj.parsedDate, 'day') 
                : (today.month() === dateObj.parsedDate.month() && today.date() === dateObj.parsedDate.date());
        });

        if (isToday && !isTodayIgnored) return false;
        return tileDate.isBefore(today) || isDateIgnored(tileDate);
    };

    const fetchData = async (selectedDate:string) => {
        const requestData: GetTimeSlotInterface = {
          date: selectedDate,
          memberUids: [calendar?.memberUid.toString()]
        };
        try {
          const data = await getTimeSlots(requestData);
          const gettingData:SelectTimeSlot[]=data.map((item:any)=>({
            startTime: moment(item.startTime, ['hh:mm A', 'HH:mm:ss']).format('HH:mm:ss'),
            endTime: moment(item.endTime, ['hh:mm A', 'HH:mm:ss']).format('HH:mm:ss')
          }))
        //   setValuedata(gettingData[0]) 
          setTimeSlotData(gettingData);
          timeref.current=gettingData;
          
          return gettingData[0]

        } catch (err) {
        } finally {
        }
      };

      useEffect(() => {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 1); 
        const formattedDate = futureDate.toISOString().split('T')[0];
        fetchData(data.original.date);
      }, []);



    const onSubmitHandler = async (values: FormData) => {
        

        const transformedData = {
            ...values,
            appointmentUid: data.original.uid,
            rescheduleData: {
                ...values.rescheduleData,
                rescheduledBy: 'MEMBER'
            }
        }
        try {
            await appointmentService.rescheduleAppointment(transformedData, channelId)
            toast('success', 'Appointment rescheduled successfully')
            onSubmit()
            onCancel()
        } catch (error) {
            toast('error', 'message' in (error as any).response.data ? (error as any).response.data.message : 'Appointment reschedule failed')
        }
    }

    return (
        <Container>
            <Formik
                validationSchema={schema}
                onSubmit={onSubmitHandler}
                initialValues={{
                    rescheduleData: {
                        date: data?.original?.date ? data?.original?.date:    moment(data.date).add(2, 'days').format('YYYY-MM-DD'),
                        timeslot: {
                            startTime: data?.original?.scheduledAt?.startTime,
                            endTime: data?.original?.scheduledAt?.endTime
                        }
                    }
                } as FormData}
            >
                {({ handleSubmit, values, touched, errors, setFieldValue }) => {

                    useEffect(() => {
                        if(values.rescheduleData.date) {
                            fetchData(values.rescheduleData.date);
                        }
                    }, [values.rescheduleData.date])

                    return (
                        <Form onSubmit={handleSubmit}>

                            <FormGroup className='mt-2'>
                                <Label>Appointment Date <span className='required'></span></Label>
                                <div className={['form-control', styles.calenderInput].join(' ')}>
                                    <FontAwesomeIcon icon={faCalendar} className='mr-2' />
                                    <DatePicker
                                        dayPlaceholder='DD'
                                        monthPlaceholder='MM'
                                        yearPlaceholder='YYYY'
                                        onChange={date => {
                                            if (date instanceof Date) {
                                                setFieldValue(
                                                    'rescheduleData.date',
                                                    moment(date).format('YYYY-MM-DD')
                                                )
                                            } else {
                                                setFieldValue('rescheduleData.date', '')
                                            }
                                             setFieldValue('rescheduleData.timeslot', null);
                                        }}
                                        tileDisabled={isTileDisabled}
                                        // tileDisabled={tile => {
                                        //     return tile.view === 'month' && ignoreDates.some(ignoreDateObj => {
                                        //         const a = moment(tile.date);
                                        //         const b = moment(ignoreDateObj.dateStr, 'YYYY-MM-DD');
                                        //         if (ignoreDateObj.strict && a.format('YYYY-MM-DD') === ignoreDateObj.dateStr) {
                                        //             return true;
                                        //         } else if (!ignoreDateObj.strict && (a.month() === b.month() && a.date() === b.date())) {
                                        //             return true;
                                        //         }
                                        //         return a.isBefore(moment());
                                        //     });
                                        // }}
                                        calendarIcon={false}
                                        minDate={moment().isBefore(moment(calendar.dateRange.startDate)) ? new Date() : new Date(calendar.dateRange.startDate)}
                                        maxDate={calendar.dateRange.endDate ? new Date(calendar.dateRange.endDate) : undefined}
                                        value={values?.rescheduleData?.date.trim() !== ''
                                            ? new Date(values.rescheduleData.date)
                                            : null}
                                    />
                                </div>
                                {touched.rescheduleData && Boolean(touched.rescheduleData.date && errors.rescheduleData && errors.rescheduleData.date)
                                    && typeof (errors.rescheduleData as any).date === 'string' ? (
                                    <Error message={(errors.rescheduleData as any).date} />
                                ) : null}
                            </FormGroup>
                            {timeSlot &&!data?.original?.emergencyMember ? (
                                <FormGroup className='mt-2'>
                                    <Label>Timeslot <span className='required'></span></Label>
                                    <ReactSelect
                                        styles={{
                                            menuList: (baseStyles, state) => ({
                                                ...baseStyles,
                                                maxHeight: 180
                                            })
                                        }}
                                        value={(() => {
                                            const slot = timeSlot.find(slot => JSON.stringify(slot) === JSON.stringify(values.rescheduleData.timeslot))
                                            if (slot) {
                                                return ({
                                                    value: JSON.stringify(slot),
                                                    label: `${moment(slot.startTime, 'HH:mm:ss').format('h:mm a')} to ${moment(slot.endTime, 'HH:mm:ss').format('h:mm a')}`
                                                })
                                            }
                                            return null
                                        })()}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue('rescheduleData.timeslot', JSON.parse(selectedOption.value))
                                        }}
                                        options={timeslotdata.map(slot => ({
                                            value: JSON.stringify(slot),
                                            label: `${moment(slot.startTime, 'HH:mm:ss').format('h:mm a')} to ${moment(slot.endTime, 'HH:mm:ss').format('h:mm a')}`
                                        }))}
                                    />
                                    {/* <Input
                                        type='select'
                                        name='rescheduleData.timeslot'
                                        value={JSON.stringify(values.rescheduleData?.timeslot)}
                                        onChange={(event) => {
                                            const value = JSON.parse(event.target.value)
                                            setFieldValue('rescheduleData.timeslot', value)
                                        }}
                                        valid={touched.rescheduleData?.timeslot && !errors.rescheduleData?.timeslot}
                                        invalid={Boolean(touched.rescheduleData?.timeslot && errors.rescheduleData?.timeslot)}
                                    >
                                        {timeSlot.map(slot => (
                                            <option
                                                key={JSON.stringify(slot)}
                                                value={JSON.stringify(slot)}
                                            >
                                                {moment(slot.startTime, 'HH:mm:ss').format('h:mm a')} to {moment(slot.endTime, 'HH:mm:ss').format('h:mm a')}
                                            </option>
                                        ))}
                                    </Input> */}
                                    {Boolean(errors.rescheduleData?.timeslot) ? (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            Timeslot is required
                                        </div>
                                    ) : null}
                                </FormGroup>
                            ) : null}
                            <FormGroup className='mt-2'>
                                <Button
                                    className='signUpBtn submitButton mb-2 pt-2'
                                    type='submit'
                                >
                                    {create ? 'Create' : 'Save'}
                                </Button>
                                <Button
                                    type='button'
                                    className='cancelButton ml-2'
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </FormGroup>
                        </Form>
                    )
                }}
            </Formik>
        </Container>
    )
}

export default RescheduleForm