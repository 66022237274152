import React from 'react'

const Loader = () => {
    return(
        <div id="global-loader">
            <div className="loader-img">
                <div className="spinner-border text-success" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div> 
            {/* <img src={require("../../assets/img/loader.svg").default} className="loader-img" alt="Loader"/> */}
        </div>
    )
}

export default Loader;