import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTicket } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import BizTable from '../../common/BizTable';
import { useSelector } from 'react-redux';
import { getAllTickets, getTicketFields } from '../../services/ticketService';
import Avatar from 'react-avatar';
import Loader from '../../shade/Loaders/smallLoader';

type ticketListProps = {};

type TicketField = {
    name: string;
    label: string;
    position: number;
    deleted: boolean;
    dropDown: boolean;
    type: string;
    options: any[];
    isDefault: boolean;
};

const TicketingList: React.FC<ticketListProps> = ({ }) => {

    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [headerColumns, setHeaderColumns] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [showFilter, setShowFilter] = useState(false);

    const channeluid = useSelector(
        (state: any) => state.cartreducer.channelUid.value
    );
    const createTicketPage = (e: any) => {
        navigate("createTicket");
    };

    const getTickets = useCallback(async (pageIndex: number,
        pageSize: number,
        searchText: string,
        currentPage: number): Promise<{ data: any; totalPages: number }> => {
        const payload = {
            channelUid: channeluid,
            limit: pageSize,
            page: currentPage
        }
        var cols: any[] = [];
        var filteredFields: any[] = [];
        const fields = await getTicketFields(channeluid);
        if (fields?.data?.length) {
            fields?.data?.sort((a: TicketField, b: TicketField) => a.position - b.position);
            filteredFields = fields?.data?.filter((field: TicketField) => !field.deleted);
            cols.push({
                Header: 'Ticket Id',
                accessor: 'id',
                Cell: (cell: any) => <div>
                    <span><FontAwesomeIcon icon={faTicket} color='#aba1a1' /></span>
                    <span className='ml-1 showTicket'>{cell.value}</span>
                </div>
            }, {
                Header: 'Contact',
                accessor: 'contact',
                Cell: ({ cell, row }: any) =>
                    <div>
                        <span> <Avatar
                            name={
                                row.original?.contact?.name
                            }
                            round
                            size="27"
                            textSizeRatio={2.5}
                        /></span>
                        <span className='ml-1'>{row.original?.contact?.name}</span>
                    </div>
            })
        }
        const ticketdata = await getAllTickets(payload);

        if (ticketdata) {
            ticketdata.data.map((t: any, tIndex: number) => {
                filteredFields.forEach((d: any, ind: number) => {
                    if (d.isDefault) {
                        if (tIndex === 0) {
                            cols.push({ Header: `${d.label}` })
                        }
                        t.fields.map((f: any, index: number) => {
                            if (f.label === d.label) {
                                var labelIndex = cols.findIndex(item => item.Header === f.label)
                                if (!cols[labelIndex]) {
                                    cols[labelIndex] = {};
                                }
                                cols[labelIndex].accessor = `${f.value}`;
                                cols[labelIndex].Cell = (cell: any) => {
                                    const field = cell.row.original.fields.find((f: any) => f.label === d.label);
                                    return <span
                                        className={f.label.includes('Subject') ?
                                            'ml-1 showTicket' : f.label.includes('Priority') ? 'fontWeight' : ''
                                        }>
                                        {field ? f.label === 'Team' ? f.team.name :
                                            f.label === 'Member' ? f.member.name : field.value : '-'}</span>;
                                };
                            }
                        })
                    }
                })
            })
            setTickets(ticketdata.data);
            setTotalCount(ticketdata.count);
            setHeaderColumns(cols);
            setLoading(false);
            return { data: ticketdata.data, totalPages: ticketdata.count };
        }
        else {
            setTickets([]);
            setHeaderColumns([]);
            setTotalCount(0);
            setLoading(false);
            return { data: [], totalPages: 0 };
        }
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [channeluid])

    const handleCancel = () => {
        setShowFilter(!showFilter);
    };

    const getColorBasedOnPriority = (priority: string): string | undefined => {
        switch (priority) {
            case 'Low':
                return 'green';
            case 'Medium':
                return 'yellow';
            case 'High':
                return 'blue';
            case 'Urgent':
                return 'red';
            default:
                return 'green';
        }
    }

    return (
        <>
            {loading ?
                (<Loader />) :
                <Row>
                    <Col md="12">
                        <div className="breadcrumb-header">
                            <h5>Ticketing</h5>
                        </div>
                        {/* <div
                            className={`${showFilter ? 'filterIcon-active' : ''} filterIcon ticketFilter`}
                            onClick={() => setShowFilter(!showFilter)}>
                            <LuFilter color="#666E7D" size={20} fill={showFilter ? '#666E7D' : 'none'} />
                        </div>
                        {showFilter && (
                            <RightSidebar handleCancel={handleCancel} title="Filters" width="25vw">
                                <div>hii</div>
                            </RightSidebar>
                        )} */}
                        <div className="global-top-table-button">
                            <Button
                                variant=""
                                type="button"
                                className="sendButton mt-0 w-100"
                                onClick={createTicketPage}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                <span className='ml-2'>Create New Ticket</span>
                            </Button>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='mt-3'>
                            <BizTable
                                columns={headerColumns}
                                fetchData={getTickets}
                                counts={totalCount}
                                refetchData={tickets}
                                tableName='TICKETSLIST'
                            />
                        </div>
                    </Col>
                </Row>
            }
        </>
    );
};

export default TicketingList;
