import { Moment } from 'moment';

type DateRange = {
    start: Moment;
    end: Moment;
}

/**
 * Adjusts the given date range values.
 * If the start and end dates are the same, it returns the start of the day for the start and the end of the day for the end.
 * Otherwise, it returns the original values.
 *
 * @param start - The start of the date range.
 * @param end - The end of the date range.
 * @returns The adjusted date range values.
 */
const getAdjustedDateRange = (start: Moment, end: Moment): DateRange => {
    if (start.isSame(end, 'day')) {
        return {
            start: start.clone().startOf('day'),
            end: end.clone().endOf('day')
        };
    }
    return { start, end };
}

export default getAdjustedDateRange;