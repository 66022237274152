import Node from './node';
import styles from './node.module.scss';

function SendText(props: any) {

    const markdownToHTML = (markdownText: any) => {
        // Replace **bold** with <strong>bold</strong>
        let htmlText = markdownText.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
        // Replace _italic_ with <em>italic</em>
        htmlText = htmlText.replace(/_(.*?)_/g, '<em>$1</em>');
        // Replace ~strikethrough~ with <s>strikethrough</s>
        htmlText = htmlText.replace(/~(.*?)~/g, '<s>$1</s>');
    
        return htmlText;
    };

    let convertedMessage = markdownToHTML(props.data.message);

    return (
        <Node {...props} label='Send Text' content={convertedMessage ? (
          <div className={styles.node} dangerouslySetInnerHTML={{ __html: convertedMessage.substring(0, 50) + (convertedMessage.length > 50 ? '...' : '') }} />
        ) : null} />
      );
}

export default SendText;