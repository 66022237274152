
import Node from '../node'
import React from 'react'
import OptionItem from '../optionItem';
import { ListGroup } from 'reactstrap';
import classes from '../sendButtonOption.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function SendProduct(props: any) {


    return (
        <Node {...props} label='Send Products'
            content={
                <>
                    {props.data?.ownFlowOrder === true ? (
                        <>
                            <div>Products</div>
                            <ListGroup as='ol' className={classes.list}>
                                {props.data?.catalogueData?.sections[0] && props.data.catalogueData?.sections[0]?.products?.map((option: any, i: number) => {
                                    return (
                                        <>
                                            {i < 3 &&
                                                (<OptionItem
                                                    key={option?.id}
                                                    id={`send_product'/${i + 1}`}
                                                    optionText={option?.name}
                                                    readOnly={props.readOnly}
                                                    isOption={false}
                                                />)}
                                                
                                                
                                            { i === 3 &&  (<>

                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 200 }}
                                                        overlay={<Tooltip id="button-tooltip">{props.data.catalogueData?.sections[0]?.products?.map((option: any, i: number) => {return option?.name}).join(", ")}</Tooltip>}
                                                    >
                                                        <span style={{fontSize: "13px", textAlign: "center"}}>
                                                          ...
                                                        </span>
                                                    </OverlayTrigger>

                                                </>)
                                            }
                                        </>
                                    );
                                })}
                            </ListGroup>
                        </>
                    ) : (
                        <>
                            <div>Collection</div>
                            <OptionItem
                                key={props.data.catalogueData.catalogueCollection?.id}
                                id={`send_collection`}
                                optionText={props.data.catalogueData.catalogueCollection?.name}
                                readOnly={props.readOnly}
                                isOption={false}
                            />
                        </>
                    )}
                </>
            } />
    )
}

export default SendProduct;