import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import styles from './fullScreenPopover.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

type Props = {
    children: React.ReactNode,
    open: boolean,
    caption?: string
    onClose: () => void
}

function FullScreenPopover({
    children,
    open,
    onClose,
    caption
}: Props) {
    const modelRef = useRef(null)
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (open && event.key === 'Escape') {
                onClose();
            }
        };

        if (open) {
            window.addEventListener('keydown', handleKeyPress);
        } else {
            window.removeEventListener('keydown', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [open, onClose]);

    return (
        <CSSTransition
            in={open}
            timeout={300}
            nodeRef={modelRef}
            unmountOnExit
            mountOnEnter
            classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive
            }}
        >
            <div
                ref={modelRef}
                className={styles.fullScreenPopover}>
                {caption ? (
                    <>
                        <div className={styles.header}>
                            <button onClick={onClose}>
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                            <span className={styles.caption}>
                                {caption}
                            </span>
                        </div>
                        <div style={{ height: 60 }}></div>
                    </>
                ) : null}
                {children}
            </div>
        </CSSTransition>
    )
}

export default FullScreenPopover