import React ,{useEffect, useState} from 'react';
import { useTable } from 'react-table';
import { Table } from "react-bootstrap";
import {
  Col,
  Row,
  // Pagination,
} from "react-bootstrap";
import "./table.scss";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Loader from "../shade/Loaders/smallLoader";
import { ErrorImage } from "./ErrorMessage";

interface BizTableReportsProps {
  data: any[];
  columns: any[];
  fetchData:(currentPage: number,pageSize:number) => Promise<{ data: {}}>;
  tableName:string;
}

const BizTableReports: React.FC<BizTableReportsProps> = ({ data, columns,fetchData,tableName }) => {
  const [isDataNotFound, setIsDataNotFound] = useState(false);
  const [isLoading,setLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const fetchDataCallback =async()=>{
    if(fetchData){
      const response = await fetchData(
        pageSize,
        currentPage
      );
      console.log('response',Object.keys(response?.data)?.length)
      if (Object.keys(response?.data)?.length > 0) {
        setLoading(false);
        setIsDataNotFound(false);
      }
      else if (Object.keys(response?.data)?.length === 0) {
        setLoading(false);
        setIsDataNotFound(true);
      }

    }
  }

  useEffect(() => {
    fetchDataCallback()
  }, [fetchData]);

  useEffect(() => {
    // data.length === 0 ?
    //   setIsDataNotFound(true) : setIsDataNotFound(false)
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div className='tableContainer'>
    <Table {...getTableProps()}  className="scrollTable">
      <tbody {...getTableBodyProps()} className={`scrollTable tableBody ${tableName}`}>
      <div id="table-header">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} style={{ border: "none" }}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </div>
      {isLoading ? (
                <Loader />
              ) : (<>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
        </>)}
      </tbody>
    </Table>
    {isDataNotFound &&(
      <ErrorImage />
     )} 
    </div>
  );
};

export default BizTableReports;
