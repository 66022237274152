import React from 'react'
import Node from '.././node';
import { ListGroup } from 'react-bootstrap';
import classes from '../delay.module.css';
import { Handle, Position } from 'reactflow';
import styles from '../node.module.scss';

function GoogleSheets(props: any) {

    const columnName =  props?.data?.integrationData?.columnValues?.find((value: string) => value.trim() !== "");
    const columnValue = props?.data?.integrationData?.rowValues?.find((value: string) => value.trim() !== "");

    let content = (
        <> 
        <div className={styles.sheetsNode}>
            <span className={styles.sheetType}>{props.data?.integrationData?.action==="addNewRow" && "Add New Row"}{props?.data?.integrationData?.action === "updateRecord" && "Update Row"}{props?.data?.integrationData?.action === "fetchRecord" && "Fetch Row"}</span>
            <span className='mt-1 d-block'>Column Name: <b>{columnName?.length > 10 ? columnValue.substring(0,9) + '...' : columnName}</b></span>
            <span> Variable: {columnValue?.length > 17 ? <span  className={styles.smallFont}>{ columnValue.substring(0,16) + '...' }</span> : <span className={styles.smallFont}>{columnValue}</span>}</span>
        </div>
        <ListGroup>
            <ListGroup.Item className={[classes.endpoint, classes.success].join(' ')}>
                <div>
                    <span ></span>
                    <span className={classes.caption}>On Success</span>
                    <Handle
                        className={classes.dot}
                        id='integration/GoogleSheets/success'
                        type='source'
                        position={Position.Right}
                        isConnectable
                    />
                </div>
            </ListGroup.Item>
            <ListGroup.Item className={[classes.endpoint, classes.failed].join(' ')}>
                <div>
                    <span className={classes.caption}>On Fail</span>
                    <Handle
                        className={classes.dot}
                        id='integration/GoogleSheets/failed'
                        type='source'
                        position={Position.Right}
                        isConnectable
                    />
                </div>
            </ListGroup.Item>
        </ListGroup>
        </>
    )
    
    return  <Node {...props} label="Google Sheets" content={content} />
}

export default GoogleSheets