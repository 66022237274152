import axios from "../utils/axios";
import { showAlert, toast, sweetalert } from "../common/alert";

export const getAnalyticsDashboard = async (
  fromTime: number,
  toTime: number,
  channelUid: string
) => {
  try {
    const params = {
      startTime: fromTime,
      endTime: toTime,
      channelUid: channelUid
    };
  
    const response = await axios.get('/analytics/dashboard/summary', { params });
  
    return response.data
  } catch (error: any) {
    console.log(error, "error");
    toast("error", error.message);
  }
};

export const getConversationCharges = async (
  fromTime: number,
  toTime: number,
  channelUid: string
) => {
  try {
    const data = {
      fromTimeUTC: fromTime,
      toTimeUTC: toTime,
      channelId: channelUid, // 'channelId' instead of 'channelUid' to match your backend
    };
  
    const response = await axios.post('/payment/getConversationCharges', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  
    return response.data;
  } catch (error: any) {
    console.error(error, "error");
    toast("error", error.message);
  }
};

export const getConversationData = async (
  startTime: number,
  endTime: number,
  channelUid: string
) => {
  try {
    const response = await axios.get('/analytics/conversationData', {
      params: {
        startTime,
        endTime,
        channelUid,
      },
      // headers: {
      //   'Accept': 'application/json, text/plain, */*',
      //   'Accept-Language': 'en-US,en;q=0.8',
      //   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmVGVueUtTbVdLZXJhSWlBa0pXOGxIS0JaaUkyIiwidWlkIjoiZlRlbnlLU21XS2VyYUlpQWtKVzhsSEtCWmlJMiIsInJvbGUiOiJPV05FUiIsIm5hbWUiOiJhcnVua3VtYXIwbSIsImlkIjoxMDA4LCJtYXNraW5nRW5hYmxlZCI6dHJ1ZSwiYnVzaW5lc3NfaWQiOjEwMDgsImlhdCI6MTcyODg4MDgwMSwiZXhwIjoxNzI5NzQ0ODAxfQ.0LWDXI2DHEQTV7teNCdzfTS18oGVbQWdMpreDsDiceI',
      //   'Cache-Control': 'no-cache',
      //   'Connection': 'keep-alive',
      //   'Origin': 'https://bizmagnet-stage.vamosys.com',
      //   'Pragma': 'no-cache',
      //   'Referer': 'https://bizmagnet-stage.vamosys.com/',
      //   'Sec-Fetch-Dest': 'empty',
      //   'Sec-Fetch-Mode': 'cors',
      //   'Sec-Fetch-Site': 'same-site',
      //   'Sec-GPC': '1',
      //   'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36',
      //   'sec-ch-ua': '"Brave";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
      //   'sec-ch-ua-mobile': '?0',
      //   'sec-ch-ua-platform': '"Windows"',
      // },
    });

    return response.data;
  } catch (error: any) {
    console.error(error, 'error');
    toast('error', error.message);
  }
};

export const FailedMessagesGetAll = (ContactList: any) => {
  try {
    const response = axios.post("failedMessage/getAll", ContactList);
    return response;
  } catch (error: any) {
    toast("error", error.response.data.message);
  }
};


export const SetRetryMessages = (payload: any) => {
  try {
    const response = axios.post("failedMessage/setRetryMessages", payload);
    return response;
  } catch (error: any) {
    toast("error", error.response.data.message);
  }
};
