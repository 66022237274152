import { Button, Form, Offcanvas } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import styles from "./createFlow.module.scss";
import * as flowService from '../../services/flows';
import KeywordInput from '../ui/keywordInput';
import { MdArrowBack } from 'react-icons/md';
import { uuidv4 } from '../../utils/uuid';
import Swal from 'sweetalert2';
import ReactSelect from 'react-select';
import { useEffect, useState } from 'react';
import { toast } from '../../../common/alert';

interface Props {
    id?: string;
    show: boolean;
    onHide: () => void;
    onCreate?: () => void;
    botId: string;
    editData?: any
    showDelete?: () => void;
    flow?: any;
    isOrder?: boolean;
};

const CreateFlow: React.FC<Props> = (props: Props) => {
    const [isMessageBased, setIsMessageBased] = useState(true);
    useEffect(() => {
        if (props.editData?.flowType === "order") {
            setIsMessageBased(false)
        }
    }, [props.editData?.flowType])

    async function onSubmitHandler({ nameInput, welcomeTrigger, flowInstances, setFieldValue, flowType, error }: any) {
        if (flowType === 'order') {
            flowInstances = null;
        }

        if (welcomeTrigger && (!props.id || (props.id && !props.editData.welcomeTrigger))) {
            const allFlows = await flowService.getFlows(props.botId);
            const activeWelcomeFlow = allFlows.find((flow: any) => flow.welcomeTrigger && flow.id !== props.id)

            if (activeWelcomeFlow) {
                const result = await Swal.fire({
                    title: 'Are you sure?',
                    text: "Setting this flow as the welcome trigger will disable the existing welcome trigger. Do you want to proceed?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    customClass: {
                        container: 'custom-sweetalert'
                    },
                    didOpen: () => {
                        const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
                        if (confirmButton) {
                            confirmButton.style.backgroundImage = 'linear-gradient(to right, #00B598, #458d80)';
                            confirmButton.style.border = 'none';
                            confirmButton.style.color = 'white';
                        }
                    }

                });
                if (result.isConfirmed) {
                    await flowService.updateFlow({
                        ...activeWelcomeFlow,
                        welcomeTrigger: false,
                    });
                } else {
                    setFieldValue('welcomeTrigger', false);
                    return;
                }
            }
        }
        if (props.id) {
            await flowService.updateFlow({
                ...props.flow,
                flowName: nameInput,
                welcomeTrigger,
                flowType: flowType,
                flowInstances: flowInstances?.map((instances: any) => {
                    return {
                        ...instances,

                    }
                }),
                error: error
            });
            toast('success', "Flow updated successfully")
        } else {
            await flowService.createFlow(props.botId, {
                nameInput,
                flowType: flowType,
                welcomeTrigger,
                flowInstances: flowInstances?.map((instances: any) => {
                    return {
                        ...instances,
                        uid: undefined
                    }
                }),
                error: error
            });
            toast('success', "Flow created successfully")
        }
        props.onHide();
        if (props.onCreate) props.onCreate();
    }
    const instanceSchema = yup.object().shape({
        keyword: yup.string().when('$flowType', {
            is: (value: any) => value !== 'order',
            then: yup.string().trim().required('Keyword is required'),
            otherwise: yup.string(),
        }),
        caseMatch: yup.boolean(),
        regexMatch: yup.boolean(),
    });

    const schema = yup.object().shape({
        nameInput: yup.string().trim().required('Flow Name is required'),
        flowType: yup.string().required('Flow Type is required'),
        flowInstances: yup.array().of(instanceSchema).min(1, 'At least one instance is required'),
        error: yup.string().when('flowType', {
            is: 'order',
            then: yup.string().required('Error message is required when Flow Type is "order"'),
            otherwise: yup.string().nullable()
        })
    });

    const flowTypeOptions = [{ value: 'Whatsapp', label: 'Message based' }, { value: 'order', label: 'Order based' }]
    return (
        <Offcanvas style={{ width: '30vw' }} show={props.show} onHide={props.onHide} placement='end'>
            <Offcanvas.Header>
                <Offcanvas.Title>
                    <Button variant='default' onClick={props.onHide}>
                        <MdArrowBack />
                    </Button>{' '}
                    {props.id ? 'Update' : 'Add New'} Flow
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Formik
                validationSchema={schema}
                onSubmit={onSubmitHandler}
                initialValues={{
                    nameInput: props.editData ? props.editData.nameInput : '',
                    welcomeTrigger: props.editData ? props.editData.welcomeTrigger : '',
                    flowType: props.editData ? flowTypeOptions.find(option => option.value === props.editData.flowType)?.value : flowTypeOptions[0]?.value,
                    flowInstances: props.editData ? props.editData.flowInstances.map((flowInstance: any) => {
                        return {
                            ...flowInstance,
                            uid: uuidv4()
                        }
                    }) : [
                        {
                            uid: uuidv4(),
                            keyword: '',
                            payload: '',
                            caseMatch: false,
                            regexMatch: false,
                        }
                    ],
                    error: ""
                }}
            >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => {
        
                    const handleSave = async () => {
                        if (values?.flowType === 'order') {
                            const allFlows = await flowService.getFlows(props.botId);
                            const isExist = allFlows?.some((flow: any) => flow?.flowInstances?.length === 0);
                            if(!isExist) {
                                setFieldValue('error', 'error');
                            }
                            if(isExist) {
                                toast('error', "Order Based Flow Already Exists")
                            }
                        }
                    }

                    useEffect(() => {
                        if (String(values?.flowType) === 'Whatsapp') {
                            setIsMessageBased(true)
                            values?.flowInstances?.length === 0 && setFieldValue('flowInstances', [
                                {
                                    uid: uuidv4(),
                                    keyword: '',
                                    payload: '',
                                    caseMatch: false,
                                    regexMatch: false,
                                }
                            ])
                        }
                        if (String(values?.flowType) === 'order') {
                            setIsMessageBased(false)
                        }
                    }, [values.flowType])
                    return (
                        <Offcanvas.Body className={styles.createFlow}>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Flow Name <span className='required'></span></Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='nameInput'
                                        value={values.nameInput}
                                        isInvalid={(touched.nameInput && errors.nameInput) ? true : false}
                                        onChange={handleChange}
                                        isValid={touched.nameInput && !errors.nameInput}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.nameInput as any}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Flow Type <span className='required'></span></Form.Label>
                                    <ReactSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                                            menu: base => ({ ...base, width: 400 }),
                                        }}
                                        name="flowType"
                                        options={flowTypeOptions}
                                        value={flowTypeOptions.find(option => option.value === (values.flowType))}
                                        onChange={(selectedOption: any) => setFieldValue('flowType', selectedOption.value)}
                                    />
                                </Form.Group>

                                {isMessageBased && (
                                    <>
                                        <Form.Group className='mb-3 '>
                                            <Form.Check
                                                type="switch"
                                                label="Welcome Trigger"
                                                name="welcomeTrigger"
                                                checked={values.welcomeTrigger}
                                                onChange={(e) => setFieldValue('welcomeTrigger', e.target.checked)}
                                                isInvalid={!!errors.welcomeTrigger}
                                                feedback={errors.welcomeTrigger as any}
                                                id="welcomeTriggerCheckbox"
                                                style={{ transform: 'scale(1.3)', fontSize: '12px', transformOrigin: 'left' }}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <KeywordInput
                                                value={values.flowInstances}
                                                onChange={handleChange}
                                                isInvalid={(touched.flowInstances && errors.flowInstances) ? true : false}
                                                isValid={(touched.flowInstances && !errors.flowInstances) ? true : false}
                                                error={errors.flowInstances}
                                                touched={touched.flowInstances}
                                            />
                                        </Form.Group>
                                    </>
                                )}

                                <div style={{ marginTop: 12, textAlign: 'right' }}>
                                    <Button variant='outline-dark' onClick={props.onHide} className='mr-3 mt-1'>
                                        Cancel
                                    </Button>
                                    <Button type='submit' className='signUpBtn mb-2' onClick={handleSave}>{props.editData ? 'Update Flow' : 'Add Flow'}</Button>
                                </div>
                            </Form>
                        </Offcanvas.Body>
                    )
                }}
            </Formik>
        </Offcanvas>
    );
}

CreateFlow.defaultProps = {
    onCreate: () => { }
}

export default CreateFlow;