import React, { useState, useEffect } from 'react';
import './autodesign.scss';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import Select from "react-select";
import ChatInbox from './chatInbox';
import { useSelector } from 'react-redux';
import { getAssignment,setAssignment } from './apiService';
import * as Yup from 'yup';
import { getBots } from '../../botFlows/services/bots';
interface SelectBtnOption {
  value: string;
  label: string;
}

const Assignment = () => {
  const [selectedTab, setSelectedTab] = useState('tab1');
  const [isAssign, setAssign] = useState(false);
  const [isReassign, setReassign] = useState(false);
  const [assign, setAssignee] = React.useState<SelectBtnOption | null>(null);
  const [member, setmember] = React.useState<SelectBtnOption | null>(null);
  const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
  const profileDetail: any = useSelector((state: any) => state.cartreducer.business);
  let members: any = useSelector((state: any) => state.cartreducer.mappedMemberData);
  const [allData, setAlldata] = useState<any>([]);
  const [supportData, setSupportData] = useState<any>([]);
  const [botData, setBotData] = useState<any>([]);
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);
  const tabs = [
    { id: 'tab1', label: 'Chat Inbox Assignment' },
    { id: 'tab2', label: 'Chat Inbox Configuration' },
    // { id: 'tab3', label: 'Support Inbox Assignment' },  // Additional tabs can be added here
    // { id: 'tab4', label: 'Support Inbox Configuration' },  // Additional tabs can be added here
  ];

  useEffect(() => {
    if (roleRules) {
      const isInvalidAccess = roleRules.canViewAutomation === true && roleRules.canManageAutomation === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);



  const getAllData = async (channel: any) => {
    try {
      const response = await getAssignment(channel.value, 'salesInbox');
      const supportResponse = await getAssignment(channel.value, 'supportInbox');
      const data = response.data;
      setAlldata(data);
      setSupportData(supportResponse.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getBotList = async () => {
    try {
      const response = await getBots('');
      setBotData(response.bots);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (channel && channel.value) {
      getAllData(channel);
      getBotList();
    }
  }, [channel, selectedTab]);

  const memberAssignee = [
    { value: 'Bots', label: 'Bots' },
    { value: 'Members', label: 'Members' },
  ];

  const selectOptions: SelectBtnOption[] = [];
  var botOptions: SelectBtnOption[] = [];

  members.forEach((label: any, value: string) => {
    selectOptions.push({ value: String(value), label });
  });

  botOptions = botData.map((item: any) => ({
    value: item.id.toString(),
    label: item.name,
  }));


  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
    if (name === 'yes') {
      setAssign(checked);
      setReassign(!checked);
    } else if (name === 'no') {
      setReassign(checked);
      setAssign(!checked);
    }
  };
  const handleTabChange = (event: any) => {
    setSelectedTab(event);
  };
  const handleAssignee = (selectedOption: SelectBtnOption | null) => {
    setAssignee(selectedOption)
  };
  const handlemember = (selectedOption: SelectBtnOption | null) => {
    setmember(selectedOption)
  };
  const getMember = (value: any, assignId: number) => {
    let memberId;
    if (assignId === 1) {
      memberId = botOptions.find((obj: any) => obj.value === value.toString());
    } else {
      memberId = selectOptions.find((obj: any) => obj.value === value.toString());
    }
    return memberId;
  }
  const validationSchema = Yup.object().shape({
    assignee: Yup.object()
      .when('isnewChat', {
        is: true,
        then: Yup.object().required('Assignee is required'),
        otherwise: Yup.object(),
      }),
  });


  const handleSubmit = (values: any) => {

    const payload = {
      id: selectedTab === "tab1" ? allData.id : supportData.id,
      uid: selectedTab === "tab1" ? allData.uid : supportData.uid,
      assignTo: values.assign.label === 'Bots' ? 1 : 2,
      assignId: values.assignee.value,
      assignChat: values.isAssign,
      assignNewChat: values.isnewChat,
      memberList: []
    }

    if(selectedTab === 'tab1'){
      if(allData.length === 0){
        delete payload.id;
        delete payload.uid;
      }
      setAssignment(channel.value, payload, 'salesInbox')
    }else{
      if(supportData.length === 0){
        delete payload.id;
        delete payload.uid;
      }
      setAssignment(channel.value, payload, 'supportInbox')
    }
    // if(selectedTab === 'tab1'){
    //   allData.assignTo = values.assign.label === 'Bots' ? 1 : 2;
    //   allData.assignId = values.assignee.value;
    //   allData.assignChat = values.isAssign;
    //   // allData.reAssignChat = values.isReassign; // this is commented before
    //   allData.assignNewChat = values.isnewChat;
    //   allData.memberList = [];
    //   setAssignment(channel.value, allData)
    // }else if(selectedTab === 'tab3'){
    //   console.log("one")
    //   supportData.assignTo = values.assign.label === 'Bots' ? 1 : 2;
    //   supportData.assignId = values.assignee.value;
    //   supportData.assignChat = values.isAssign;
    //   // supportData.reAssignChat = values.isReassign;
    //   supportData.assignNewChat = values.isnewChat;
    //   supportData.memberList = [];
    //   setAssignmentSupport(channel.value, supportData)
    // }
  };

  return (
    <div className='ml-3' style={{height: '99vh'}}>
      <p className='mt-2 mb-4 auto'>Automation</p>

      <div className="tabbed-radio-buttons d-flex">
      {/* Map through the tabs array */}
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={`${selectedTab === tab.id ? 'box' : 'notselect'} text-center`}
          id={tab.id}
          onClick={() => handleTabChange(tab.id)}
        >
          <label htmlFor={tab.id} className="mr-3 cursor-pointer">
            {tab.label}
          </label>

          {selectedTab === tab.id ? (
            <span className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                <ellipse cx="7.4061" cy="6.5" rx="6.5311" ry="6.5" fill="#E4E4E4" />
                <path d="M5 7L6.5 8.5L9 5" stroke="#29caaf" stroke-width="1.5" fill="none"/>
              </svg>
            </span>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 17 17" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33333 16.6667C12.9357 16.6667 16.6667 12.9357 16.6667 8.33333C16.6667 3.73096 12.9357 0 8.33333 0C3.73096 0 0 3.73096 0 8.33333C0 12.9357 3.73096 16.6667 8.33333 16.6667ZM11.5742 5.38709C11.8996 5.06168 12.4273 5.06166 12.7527 5.38709C13.0782 5.71252 13.0782 6.24019 12.7527 6.5656L8.0387 11.2796C7.71328 11.6051 7.18562 11.6051 6.86019 11.2796L3.91391 8.33337C3.58848 8.00794 3.58849 7.48027 3.91391 7.15486C4.23932 6.82944 4.76699 6.82943 5.09242 7.15486L7.44944 9.51188L11.5742 5.38709Z"
                fill="#fff"
              />
            </svg>
          )}
        </div>
      ))}
    </div>

      {(selectedTab === 'tab1'|| selectedTab === 'tab3') ?
        <>
          <Formik
            initialValues={{
              selectedTab, isAssign: selectedTab === 'tab1' ? allData.assignChat : supportData.assignChat,
              isReassign: selectedTab === 'tab1' ? allData.reAssignChat : supportData.reAssignChat,
              isnewChat: selectedTab === 'tab1' ? allData.assignNewChat : supportData.assignNewChat,
              assign: selectedTab === 'tab1' ? (allData.assignTo === 1 ? memberAssignee[0] : memberAssignee[1]) : (supportData.assignTo === 1 ? memberAssignee[0] : memberAssignee[1]),
              assignee: selectedTab === 'tab1' ? (allData.assignId && allData.assignTo ? getMember(allData.assignId, allData.assignTo) : '') : (supportData.assignId && supportData.assignTo ? getMember(supportData.assignId, supportData.assignTo) : ''),
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div>
                  <h6 className='mt-3 labelcolor'>
                    {`${selectedTab === 'tab1' ? 'Chat ' : 'Support '}`}
                     Inbox Assignment
                  </h6>
                </div>
                <div>
                  <label>
                    <Field type="checkbox" name="isAssign" checked={values.isAssign} className="mt-4 mr-2 cursor-pointer"
                      onChange={() => { setFieldValue('isAssign', !values.isAssign); }} />
                    Assign chat to the member assigned in the contact.
                  </label>
                  {/* <br/>
            <label>
            <Field type="checkbox" name="isReassign" checked={values.isReassign} className="mt-4 mr-2 cursor-pointer"
              onChange={() => { setFieldValue('isReassign', !values.isReassign); }}/>
               Re-assign the chat to the same member if any contact send message again.
              </label> */}
                  <br />
                  <label>
                    <Field type="checkbox" name="isnewChat" checked={values.isnewChat} className="mt-4 mr-2 cursor-pointer"
                      onChange={() => {
                        setFieldValue('isnewChat', !values.isnewChat);
                        setFieldValue('assignee', '');
                      }} />
                    Assign new chat.
                  </label>
                </div>
                {values.isnewChat ?
                  <>
                    <div className='mt-2'>
                      <label className='labelstyle'>Select Assignee</label>
                      <Row>
                        <Field name="assign" >
                          {({ field, form }: any) => (
                            <Col sm={6} xl={12} >
                              <Select
                                styles={{
                                  menuPortal: base => ({ ...base, zIndex: 9800 }),
                                  menu: base => ({
                                    ...base,
                                    width: window.innerWidth <= 320 ? 250 : (window.innerWidth <= 380 ? 305 : (window.innerWidth <= 430 ? 355 : (window.innerWidth <= 780 ? 325 : 385)))
                                  })
                                }}
                                options={memberAssignee}
                                className="mt-1  col-xl-4"
                                value={values.assign}
                                onChange={(event) => {
                                  setFieldValue('assign', event);
                                  setFieldValue('assignee', '');
                                  handleAssignee(event)
                                }}
                              />
                            </Col>
                          )}</Field>
                      </Row>
                    </div>
                    <div className='mt-2'>
                      <label className='labelstyle'>Assignee</label>
                      <Row>
                        <Field name="assignee">
                          {({ field, form }: any) => (
                            <Col sm={6} xl={12}>
                              <Select
                                styles={{
                                  menuPortal: base => ({ ...base, zIndex: 9800 }),
                                  menu: base => ({
                                    ...base,
                                    width: window.innerWidth <= 320 ? 250 : (window.innerWidth <= 380 ? 305 : (window.innerWidth <= 430 ? 355 : (window.innerWidth <= 780 ? 325 : 385)))
                                  })
                                }}
                                options={(values.assign.label === 'Bots') ? botOptions : selectOptions}
                                className="mt-1 col-xl-4"
                                value={values.assignee}
                                onChange={(event: any) => {
                                  setFieldValue('assignee', event);
                                  handlemember(event)
                                }}
                              />
                            </Col>)}</Field>
                      </Row>
                      <ErrorMessage name="assignee" component="div" className="text-danger ml-3" />
                    </div></> : ''}

                {/* <Button type="button" className='cancelBtn mr-2 mt-5 col-1'>Cancel</Button> */}
                <Row className={`${isInvalidAccess ? "notAllowed" : ""}`} >
                  <Button type="submit" className='signUpBtn ms-3 mt-4 col-md-1 col-xl-1' disabled={(!values.isAssign && !values.isnewChat) || profileDetail.role?.name === 'MEMBER' || isInvalidAccess}>Apply</Button>
                </ Row>
              </Form>
            )}
          </Formik>
        </> :
        <> <ChatInbox isInvalidAccess={isInvalidAccess} selectedTab={selectedTab}/></>
      }
    </div>
  );
};

export default Assignment;
