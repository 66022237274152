import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup, Label, Button } from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";
import { updateQr, createQr } from "../../services/dashboardService";
import { toastAlert } from "../../common/alert";
import "./welcome.scss";
import { FaArrowCircleLeft } from "react-icons/fa";

interface PropsType {
  values: any;
  channels: any;
  isopen: any;
  fetchQRDataHandler: () => Promise<void>;
  currentLink: any;
  removeLink: () => Promise<void>;
  isOutSideLink: boolean;
}
interface SelectOption {
  value: string;
  label: string;
}

const QrCode: React.FC<PropsType> = ({
  values,
  channels,
  isopen,
  fetchQRDataHandler,
  currentLink,
  removeLink,
  isOutSideLink,
}) => {
  const [channelName, setChannel] = React.useState<SelectOption | null>(null);

  const initialValues = {
    name: currentLink?.name,
    message: currentLink?.content,
    link: currentLink?.name,
  };

  const validationSchema = Yup.object({
    message: Yup.string().required("Message is required"),
    link: Yup.string().required("Link name is required"),
  });

  const handleSubmit = (value: any) => {
    if (values.length > 0) {
      currentLink.content = value.message;
      currentLink.name = value.link;
      if (channels.value) {
        updateQr(channels.value, currentLink)
          .then((data) => {
            console.log("Response data:", data);
            isopen(false);
            toastAlert("top-end", `Link edited successfully`, "success");
            fetchQRDataHandler();
            removeLink();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } else {
      const payload = {
        name: value.link,
        content: value.message,
      };
      createQr(channels.value, payload)
        .then((data) => {
          console.log("Response data:", data);
          isopen(false);
          removeLink();
          toastAlert("top-end", `Created successfully`, "success");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault(); // Prevent space key from having its default behavior
    }
  };

  const channelOptions: SelectOption[] = [
    { value: channels.value, label: channels.label },
  ];

  const handleChange = (selectedOption: SelectOption | null) => {
    setChannel(selectedOption);
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      outline: "none",
      color: "var(--primary-gray, #324054)",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  const linkName = currentLink.link.split(`/${currentLink?.name}`);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={true}
      >
         {({ values }) => (
        <Form className="formwidth">
          <FormGroup></FormGroup>
          <FormGroup>
            <Label className="text-dark">Message</Label>
            <Field name="message">
              {({ field }: any) => (
                <div>
                  <textarea
                    {...field}
                    placeholder="Send your WhatsApp message for enquiries"
                    className="textarea form-control pb-5 pt-2"
                    onKeyDown={handleKeyDown}
                    maxLength={50}
                  />
                </div>
              )}
            </Field>
            <span className="charCounts">
              <span className="d-flex">
            <i>To add</i> 🙂 <i>press</i><div className="win">⊞ Win</div> + <div className="win">.</div>
              </span>
              <div>
              {values?.message?.length || 0}/50
              </div>
            </span>
            <ErrorMessage
              name="message"
              component="div"
              className="text-danger pt-1"
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-dark">Customize your link</Label>
            <br />
            <div className="d-flex">
              <span className="h6 mt-2 mr-2">{linkName}</span>
              <Field
                name="link"
                placeholder="Enter link name"
                className="form-control textarea ml-1"
                onKeyDown={handleKeyDown}
              />
            </div>
            <ErrorMessage
              name="link"
              component="div"
              className="text-danger linkerror"
            />
          </FormGroup>
          <div className="d-flex justify-content-end">
            {!isOutSideLink && (
              <Button
                onClick={removeLink}
                className="cancelButton p-1 w-25 me-2"
              >
                Cancel
              </Button>
            )}
            <Button
              variant=""
              type="submit"
              className="sendButton p-1 w-25 me-2"
            >
              Save
            </Button>
          </div>
        </Form>
         )}
      </Formik>
    </div>
  );
};

export default QrCode;
