import { ListGroup } from 'react-bootstrap';
import { Handle, Position } from 'reactflow';

import classes from './listItem.module.css';

interface Props {
    id: string;
    listText: string;
    list: any[];
    position: number;
    readOnly: boolean;
};

function ListItem(props: Props) {
    return (
        <div className={classes.listitem}>
            <span className={classes.title}>{props.listText}</span>
            <ListGroup>
                {props.list.map((listItem, index) => {
                    return (
                        <ListGroup.Item key={`listitme-${props.id}-${index}`} className={classes.item}>
                            <span className={classes.itemtitle}>{listItem.title}</span>
                            {/* <span className={classes.itemdesc}>{listItem.description}</span> */}
                            {!props.readOnly ? (
                                <Handle id={`send_list/${props.position}/${index + 1}`} type='source' position={Position.Right} isConnectable />
                            ) : null}
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </div>
    );
}

export default ListItem;