import React, { useState,useEffect  } from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './common.scss'
import {
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface DateProps{
    UtcMilliSeconds:(milliSecond:any)=>void;
    UTCformat:string;
}


function DateTime(props:DateProps) {
  const {UTCformat} = props
  const [value, setDateTime] = useState<Date | null>(new Date());

  const handleDateChange = (date: Date | null) => {
      if (date) {
        const dateTimeString = date.toUTCString();
        const dateTime = new Date(dateTimeString);
        const utcMilliseconds = Date.UTC(
            dateTime.getUTCFullYear(),
            dateTime.getUTCMonth(),
            dateTime.getUTCDate(),
            dateTime.getUTCHours(),
            dateTime.getUTCMinutes(),
            dateTime.getUTCSeconds(),
            dateTime.getUTCMilliseconds()
          );
          props.UtcMilliSeconds(utcMilliseconds)
          setDateTime(date);
    }
  };

  useEffect(() => {
    // Create a new date by adding 30 minutes to the current time
  if (UTCformat) {
    const date = new Date(UTCformat);
    setDateTime(date);
  }
  else{
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + 30 * 60000); // 30 minutes in milliseconds
    setDateTime(newDate);  
    
    const dateTimeString = newDate.toUTCString();
    const dateTime = new Date(dateTimeString);
    const utcMilliseconds = Date.UTC(
        dateTime.getUTCFullYear(),
        dateTime.getUTCMonth(),
        dateTime.getUTCDate(),
        dateTime.getUTCHours(),
        dateTime.getUTCMinutes(),
        dateTime.getUTCSeconds(),
        dateTime.getUTCMilliseconds()
      );
  props.UtcMilliSeconds(utcMilliseconds)
  }
  }, [
    UTCformat
  ]);

  const handleClear = () => {
      props.UtcMilliSeconds(null)
      setDateTime(null); // Clear the value
  };
  
  const currentDate = new Date();
  return (
    <div className='DateContainer'>
      <DateTimePicker onChange={handleDateChange}
       minDate={currentDate}
       className='DateTimePicker'
       clearIcon={null}
       calendarIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
       //    autoFocus={true}
       value={value} />
    </div>
  );
}

export default DateTime;
