import axios from "../utils/axios";
import { showAlert, toast } from '../common/alert';
import Swal from 'sweetalert2';
import { error } from "console";

export const createChannel = async (payload: any) => {
    try {
        const res = await axios.post('channel/create', payload);
        return res.data;
    }
    catch (error: any) {
        return error;
    }
};

export const qrgenerator = (uid: string) => {
    return axios.get(`channel/${uid}/channelLinkList`).then(res => {
        return res.data;
    }).catch(err => {
        console.log(err);
    })
}

export const updateQr = (uid: string, payload: any) => {
    return new Promise((resolve, reject) => {
        axios.patch(`channel/${uid}/channelLink`, payload).then((res) => {
            if (res) {
                resolve({ success: true });
            }
        }).catch(err => {
            toast('error', err.response.data.message);
        })
    })
}

export const createQr = async (uid: string, payload: any) => {
    try {
      const response = await axios.post(`channel/${uid}/channelLink`, payload);
      console.log(response);
      return response.data; // Return the response data for further use if needed
    } catch (err: any) {
      const {message} = err?.response?.data;
      toast('error', message); // Display error message
      throw err; // Re-throw the error to handle it in the calling function
    }
  };

export const getCredit = (businessId: any, channelUid?: string) => {
    return axios.get(`payment/${businessId}/getTotalCredits?channelUid=${channelUid}`).then((res) => {
        return res.data;
    }).catch(err => {
        toast('error', err.response.data.message);
    })
}