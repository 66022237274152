import { toast } from "../common/alert";
import axios from "../utils/axios";

export interface IApp {
    id: number,
    name: string,
    settingEnabled: number
}


export const getAllApps = async (businessId: number) => {
    try {
        const response = await axios.post<IApp[]>('integration/apikey/getList', {
            businessId
        });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

interface IKeyWithSetting {
    apiKey: {
        id: number,
        name: string,
        token: string
    },
    settings: []
}

export const getSingleApp = async (appid: number) => {
    try {
        const response = await axios.post<IKeyWithSetting>('integration/apikey/getKeyWithSetting', {
            id: appid
        });
        const data = response.data;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const createConnectionBasedOnTypes = async (businessUid: string, data: any) => {
    try {
        const response = await axios.post(`/integration/${businessUid}/create`, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          toast('success', 'Connected Successfully');
        return response.data;
    } catch (error:any) {
        console.error(error);
        toast("error", error.response.data.message);
        throw error;
    }
}

export const deletIntegrationBasedOnIntegrationUid = async (integrationUid: string | number | undefined) => {
    try {
        const response = await axios.delete(`integration/${integrationUid}/deleteIntegration`);
        toast('success', 'Connection Deleted Successfully');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


export const getAllIntegration = async (businessUid: string) => {
    try {
        const response = await axios.get(`/integration/${businessUid}/getAll`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getConnection = async (connectionUid: string) => {
    try {
        const response = await axios.get(`/integration/${connectionUid}/getConnection`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const updateConnection = async (connectionUid: string, data: any) => {
    
    try {
      const response = await axios.post(`/integration/${connectionUid}/updateConnection`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      toast('success', 'Connection updated successfully')
      return response.data;
    } catch (error:any) {
      toast('error', error?.response?.data?.message)
      throw error;
    }
  }

  export const enableWebHookCatcher = async (connectionUid: string) => {
    try {
      const response = await axios.post(`/integration/${connectionUid}/enableWebhookCatcher`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      toast('success', 'Webhook catcher enabled successfully')
      return response.data;
    } catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }

  export const updateConnectionDisableStatus = async (workFlowUid: string, status: boolean) => {
    try {
      const response = await axios.post(
        `/integration/${workFlowUid}/changeDisableStatus?status=${status}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      toast('success', 'Connection updated successfully')
      return response.data;
    } catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }

  export const getConnectionBasedOnZohoBooks = async(app: string, businessId: number, connectionName: string) => {
    try {
      const response = await axios.post(
        `/integration/getConnectionsBasedOnApp`,
        {
          app,
          businessId,
          name: connectionName,  // Update this to match the cURL key "name"
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      return response.data; // Return the response data
    } catch (error: any) {
      console.error(error);
      toast('error', error.message); // Assuming you are using toast for notifications
      throw error;
    }
  };

  export const deleteZohoConnection = async(connectionUid:string | number | undefined) => {
    try{
      const response = axios.delete(`/integration/connection/${connectionUid}/deleteConnection`);
      toast('success', 'Connection Deleted Successfully');
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }
  
  export const connectShopifyShop = async(shopName: string) => {
    try {
      const response = axios.post(`/integration/shopify/connection?shop=${shopName}.myshopify.com`);
      return response;
    } catch (error: any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }

  export const razorPayIntegration = async(businessUid:string | number | undefined,payload:any) => {
    try{
      const response = await axios.post(`integration/${businessUid}/create`,payload);
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }

  export const wooCommerceIntegration = async(businessUid:string | number | undefined,payload:any) => {
    try{
      const response = await axios.post(`integration/${businessUid}/create`,payload);
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }

  export const webEngageIntegration = async(businessUid:string | number | undefined,payload:any) => {
    try{
      const response = await axios.post(`integration/${businessUid}/create`,payload);
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }

  export const deleteWebHookWorkFlow = async(workFlowUid:string | number | undefined) => {
    try{
      const response = axios.delete(`/integration/${workFlowUid}/deleteWorkFlow`);
      toast('success', 'Webhook WorkFlow Deleted Successfully');
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }

  export const calendlyIntegration = async(businessUid:string | number | undefined,payload:any) => {
    try{
      const response = await axios.post(`integration/${businessUid}/create`,payload);
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }


  export const calendlyEventCollection = async(uid:string) => {
    try{
      const payload = {uid:uid}
      const response = await axios.post(`integration/calendly/events`,payload);
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }
  
  export const deleteCalendlylow = async(workFlowUid:string | number | undefined) => {
    try{
      const response = await axios.delete(`/integration/${workFlowUid}/deleteWorkFlow`);
      toast('success', 'Calendly WorkFlow Deleted Successfully');
      return response;
    }catch (error:any) {
      console.error(error);
      toast('error', error.message)
      throw error;
    }
  }


export const getIntegrationLogs = async(connectionUid:string | number | undefined) => {
    try{
      const response = await axios.post(`/integration/getIntegrationLogs`,{connectionUid});
      return response;
    }catch (error:any) {
      if(error?.response?.status){
        return error?.response?.status
      }
      toast('error', error.message)
      throw error;
    }
  }

  