function scanForVariables(value: string): string[] {
    const regex = /\{\{([^}]+)\}\}/g;
    const matches = [];
    let match;

    while ((match = regex.exec(value)) !== null) {
        matches.push(match[1]);
    }

    return matches;
}

export default scanForVariables;