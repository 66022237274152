import React from 'react';
import { Card } from 'react-bootstrap';
import { FaFileImage, FaFileAudio, FaFileVideo, FaFileAlt } from 'react-icons/fa';

import styles from './filePlaceholder.module.css';
import excelIcon from '../../../../../assets/img/excel.png';
import pdfIcon from '../../../../../assets/img/pdf.png';

interface Props {
    mediaType: 'image' | 'audio' | 'video' | 'document';
    fileName: null | string;
    url: string;
    size?: 'large' | 'normal' | 'small' | 'medium';
}

const FilePlaceholder: React.FC<Props> = ({ mediaType, fileName, url, size }) => {
    const renderMediaPreview = () => {
        switch (mediaType) {
            case 'image':
                return (
                    <img src={url} alt={fileName || 'Image preview'} className={styles.mediaPreview} />
                );
            case 'audio':
                return <FaFileAudio className={styles.mediaIcon} />;
            case 'video':
                return (
                    <video controls src={url} style={{ width: '100%' }}></video>
                );
            case 'document':
                if (/\.(xls|xlsx)$/i.test(url) || (fileName && /\.(xls|xlsx)$/i.test(fileName))) {
                    return <img src={excelIcon} alt='' style={{ width: size === 'normal' ? 20 : 35 }} />;
                }
                else if (/\.(pdf)$/i.test(url) || (fileName && /\.(pdf)$/i.test(fileName))) {
                    return <img src={pdfIcon} alt='' style={{ width: size === 'normal' ? 20 : 35 }} />
                }
                return <FaFileAlt className={styles.mediaIcon} />;
            default:
                return null;
        }
    };
    const classList = [styles.filePlaceholder];
    if (size === 'large') classList.push(styles.large);
    if (size === 'small') classList.push(styles.small);
    if (size === 'medium') classList.push(styles.medium);

    return (
        <Card className={classList.join(' ')} style={{ overflow: 'hidden' }}>
            <Card.Body className={styles.mediaContainer} style={{ overflow: 'hidden' }}>
                {renderMediaPreview()}
            </Card.Body>
            {fileName ? (
                <Card.Footer className={styles.mediaFooter} style={{ overflow: 'hidden' }}>
                    <div className={styles.nameflex}>
                        {mediaType === 'image' && <FaFileImage className={styles.mediaIcon} />}
                        {/* {mediaType === 'audio' && <FaFileAudio className={styles.mediaIcon} />} */}
                        {mediaType === 'video' && <FaFileVideo className={styles.mediaIcon} />}
                        <div className={styles.filename}> {fileName}</div>
                    </div>
                </Card.Footer>
            ) : null}
        </Card>
    );
}

FilePlaceholder.defaultProps = {
    size: 'normal'
};

export default FilePlaceholder;
