import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  CreateCommunity,
  ContactsGetAll,
} from "../../services/campaignService";
import { getTag } from "../Tag/tagApi";
import { useSelector } from "react-redux";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { useNavigate, useLocation } from "react-router-dom";
import Exist from "./Exist";
import {
  faLock,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  confirmAlert,
  toast,
} from "./../../common/alert";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Button, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { Label, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import "./contact.scss";
import { ContactIcon } from "./ContactIcon";
import Modall from "../../common/modall";
import Select from "react-select";
import AddContact from "./addContacts";
import SearchInput from "../../common/searchComponent";
import BizTable from "../../common/BizTable";
import { useDispatch } from "react-redux";
import CommonPopover from "../../common/commonPopover";
import moment from "moment";

const validationSchema = Yup.object().shape({
  newGroup: Yup.string().required("Community name is required"),
});

type Props = {};

const Community = (props: Props) => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const business = useSelector((state: any) => state.cartreducer.business);
  const selectedTableData = useSelector(
    (state: any) => state.tableReducer.selectedUids
  );

  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  // const initialSelected = selectedTableData["COMMUNITY"] || [];
  const totalcount = Object.values(selectedTableData).reduce((acc, arr: any) => acc + arr?.length, 0);
  const initialSelected = Object.values(selectedTableData).flat();
  const [getContactData, setContactData] = useState([]);
  const [getContactCount, setContactCount] = useState(0);
  const currentPage = useRef(0);
  const paginationLimit = useRef(10);
  const dispatch = useDispatch();

  const [searchPgNo, setsearchPgNo] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);
  const numberMasking = useSelector((state: any) => state.cartreducer.numberMasking);
  const [communityPopoverOpen, setCommunityPopoverOpen] = useState(false);
  const [isAccountExpired, setIsAccountExpired] = useState(false);

  useEffect(() => {
    if (roleRules) {
      const isInvalidAccess = (roleRules.canViewContacts === true && roleRules.canManageContactsAndDeleteContactList === false);
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);

  useEffect(() => {
    const expired = moment(business?.business?.plan?.expireAt).diff(moment(), "days") < 0;
    setIsAccountExpired(expired);
    const button = document.getElementById('createComButton');
    if (button && expired) {
      setCommunityPopoverOpen(true);
    }
    else {
      setCommunityPopoverOpen(false);
    }
  }, [business]);

  const searchContacts = async () => { };
  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  };

  // const [currentPage, setCurrentPage] = useState(1);
  // const [paginationLimit, setPaginationLimit] = useState(10);

  // const getAllContacts = async () => {
  //   const current = currentPage.current;
  //   const limit = paginationLimit.current;
  //   const page = current > 0 ? current - 1 : 0; // Adjusting for zero-based index if needed
  //   const ContactList = {
  //     uid: businessId,
  //     limit: limit,
  //     page: searchTerm ? 0 : page,
  //     search: searchTerm,
  //   };
  //   const response = await ContactsGetAll(ContactList as any);
  //   if (response) {
  //     setContactData(response.data.list);
  //     searchTerm != null
  //       ? setContactCount(response.data.list.length)
  //       : setContactCount(response.data.count);
  //   }
  // };

  const [isOpenModal, setOpenModal] = useState(false);
  const totalPages = Math.ceil(getContactCount / paginationLimit.current);

  const handleShow = () => {
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true' || numberMasking;
    if (!numberMask) {
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const pageChange = (page: number, sizePerPage: number) => {
    if (sizePerPage !== paginationLimit.current) {
      paginationLimit.current = sizePerPage;
      currentPage.current = 0; // Typically, when page size changes, you reset to page 1
    } else if (page !== currentPage.current) {
      currentPage.current = page;
    }
    // getAllContacts();
  };

  const options = {
    headerAlign: "center",
    noDataText: "No records found",
    sortIndicator: true,
    hidePageListOnlyOnePage: true,
    clearSearch: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    sizePerPage: paginationLimit.current,
    onPageChange: pageChange,
    pagination: false,
    sizePerPageList: [10, 25, 50, 100, { text: "All", value: getContactCount }],
    paginationPosition: "bottom",
  };

  const tagsData = (cell: any, row: any) => {
    let title;
    if (!cell || cell.length === 0) {
      return <span className="ms-3">-</span>;
    } else {
      const tagNames = cell.map((tag: any) => tag.name);
      title = tagNames.join(", ");
    }

    return (
      <div className="d-flex">
        <div className="d-flex justify-content-end p-0">
          {/* {cell}
           */}

          {cell &&
            cell.slice(0, 1).map((item: any) => (
              <span key={item} className="ms-1 tag-list-view">
                {Array.isArray(item)
                  ? item.join(", ")
                  : typeof item === "string"
                    ? item
                    : item && item.name
                      ? sliceText(item.name, 10)
                      : "-"}
              </span>
            ))}
        </div>
        <div className="d-flex justify-content-start tag-count">
          {cell.length > 1 && (
            <TooltipReference
              placement="bottom"
              content={title}
              tooltipId={`tagData_${row.id}`}
            >
              +{cell.length - 1}
            </TooltipReference>
          )}
        </div>
      </div>
    );
  };

  const [selectedUids, setSelectedUids] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  // const handleRowSelect = (row: any, isSelected: any) => {
  //   if (isSelected) {
  //     setSelectedUids((prevSelectedUids: any) => [
  //       ...prevSelectedUids,
  //       row.uid,
  //     ]);
  //     setSelectedRows((prevSelectedRows: any) => [...prevSelectedRows, row]);
  //   } else {
  //     setSelectedUids((prevSelectedUids: any) =>
  //       prevSelectedUids.filter((uid: string) => uid !== row.uid)
  //     );
  //     setSelectedRows((prevSelectedRows: any) =>
  //       prevSelectedRows.filter((selectedRow: any) => selectedRow !== row)
  //     );
  //   }
  // };

  // const handleRowSelectAll = (isSelected: any, rows: any) => {
  //   if (isSelected) {
  //     setSelectedUids(rows.map((row: any) => row.uid));
  //     setSelectedRows(rows);
  //     // setCheckboxSelected(rows.map(row => row.id));
  //   } else {
  //     setSelectedUids([]);
  //     setSelectedRows([]);
  //   }
  //   return true;
  // };

  // const selectRowProp: SelectRow = {
  //   mode: "checkbox",
  //   // clickToSelect: false,
  //   clickToSelectAndEditCell: false,
  //   clickToExpand: false,
  //   // selected: checkboxSelected,
  //   onSelect: handleRowSelect,
  //   onSelectAll: handleRowSelectAll,
  // };

  // const history = useHistory();
  const navigate = useNavigate();

  const location = useLocation();

  const navigateTo = (tab: any) => {
    if (tab === 2 && (selectedUids.length > 0 || searchTerm || (getTagData as any)?.tagAll)) {
      confirmAlert(
        "warning",
        "Your changes will be lost. Are you sure you want to continue?",
        async () => {
          clearSelect();
          navigate(`?tab=${tab}`);
        },
        () => { }
      );
    } else {
      navigate(`?tab=${tab}`);
    }
  };

  const newNavigate = () => {
    setTimeout(() => {
      const button = document.getElementById('createComButton');
      if (button && isAccountExpired) {
        setCommunityPopoverOpen(true);
      }
    }, 100);
    navigateTo(1)
  }

  const existNavigate = () => {
    if (isAccountExpired)
      setCommunityPopoverOpen(false);
    navigateTo(2);
  }

  // Determine the current tab based on the query string
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");

  useEffect(() => {
    const tabUpdate = tab ? tab.replace(/\/$/, "") : null;

    if (tabUpdate === "1" || searchTerm === null) {
      getTags();
      fetchData(0, 20, '', 0);
      // dispatch({ type: "TABLE_RESET" });
      // getAllContacts();
    }
  }, [tab, searchTerm]);

  const clearSelect = () => {
    setSelectedUids([]);
    setSelectedRows([]);
    setSearchTerm(null);
    setTag([]);
    dispatch({ type: "TABLE_RESET" });
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    const community = values.newGroup;
    const status = "MANUAL";
    const channelId = channelData?.value;
    if (selectedRows.length <= 0) {
      toast("error", "Atleast one contact should be selected");
    } else {
      const response = await CreateCommunity(
        community,
        selectedRows,
        status,
        channelId,
        businessId
      );
      if (response) {
        resetForm();
        clearSelect();
        setSelectedUids([]);
        setSelectedRows([]);
      }
    }
  };
  const initialValues = {
    newGroup: "",
  };
  // const scrollContainerRef = useRef<any>(null);
  // useEffect(() => {
  //   setTimeout(() => {
  //     scrollContainerRef.current.scrollTop =
  //       scrollContainerRef.current?.scrollHeight;
  //   }, 500);
  // }, [getContactCount]);

  // useEffect(() => {
  //   const dropdown = document.querySelector('.react-bs-table-sizePerPage-dropdown');

  //   if (dropdown) {
  //     const observer = new MutationObserver((mutations) => {
  //       mutations.forEach((mutation) => {
  //         if (mutation.attributeName === 'class') {
  //           const classList = (mutation.target as Element).classList;
  //           if (classList.contains('show')) {
  //             console.log('Dropdown is shown');
  //             setTimeout(() => {
  //               if (getContactCount && scrollContainerRef.current) {
  //                 console.log('Current Ref:', scrollContainerRef.current);
  //                 scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
  //               } else {
  //                 console.log('Ref is not attached to a DOM element');
  //               }
  //             }, 1000);
  //           } else {
  //             console.log('Dropdown is hidden');
  //             // Execute your code when the dropdown is hidden
  //           }
  //         }
  //       });
  //     });

  //     observer.observe(dropdown, { attributes: true });

  //     return () => observer.disconnect();
  //   }
  // }, [getContactCount]);
  let isSearch = true;
  const [select, setSelect] = useState(false);
  const [tagsList, setTagList] = useState([]);
  const [getTagData, setTag] = useState([]);
  const [getContactTags, setContactTags] = useState([]);
  const getTags = async () => {
    const payload = {
      businessUid: businessId,
      limit: 1000,
      page: 0,
      search: null,
    };

    const tagsData = await getTag(payload, "contact");
    const tags = tagsData?.data?.tagsDto?.map((tag: any) => ({
      label: tag.name,
      value: tag.name,
      tagAll: tag,
    }));
    setTagList(tags);
  };


  const tagContacts = async (tagList: any) => {
    const ContactList = {
      uid: businessId,
      tags: [tagList],
      limit: getContactCount,
      page: 0,
    };
    const response = await ContactsGetAll(ContactList as any);
    const list = response?.data?.list;
    if (response) {
      setContactCount(list.length);
      setContactData(list);
      setContactTags(list);
    }
  };

  const tagsChange = async (Tag: any) => {
    if (Tag !== null) {
      setTag(Tag);
      const searchTag = Tag?.tagAll;
      tagContacts(searchTag);
      setSelect(true);
    } else {
      setTag([]);
      setSelect(false);
      isSearch = false;
      fetchData(0, 20, "", 0);
      // fetchContacts();
      // getAllContacts();
    }
  };

  const mobileData = (cell: any, rowData: any) => {
    const { countryCode } = rowData;
    const countryCodeString = countryCode?.toString(); // Ensure countryCode is a string
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true' || numberMasking;
    return (
      <div className={`${numberMask ? "numberMasking_Blur" : ''}`}>
        {countryCodeString?.length > 0
          ? `+${countryCodeString} ${cell.substring(countryCodeString.length)}`
          : cell}
      </div>
    );
  };
  const selectedData = useSelector(
    (state: any) => state.tableReducer.selectedUids
  );

  useEffect(() => {
    if (initialSelected) {
      setSelectedUids(initialSelected);
      setSelectedRows(initialSelected);
    }
  }, [selectedTableData]);

  const TableList = useSelector((state: any) => state.tableReducer.tableData);
  const communityData = TableList["COMMUNITY"];
  const [states, setStates] = useState(0);

  useEffect(() => {
    setStates(1);
  }, [])

  const fetchData = useCallback(
    async (
      pageIndex: number,
      pageSize: number,
      searchText: string,
      currentPage: number
    ): Promise<{ data: any; totalPages: number }> => {
      // const currentPageData = communityData && communityData[currentPage];
      // if (communityData && currentPageData?.length > 0 && !searchTerm) {
      //   setContactData(currentPageData);
      //   return { data: currentPageData, totalPages: getContactCount };
      // }
      //  else if (communityData && currentPageData?.length === 0 && !searchTerm) {
      //   return { data: getContactData, totalPages: getContactCount };
      // }
      //  else {
      if (select && isSearch && getTagData) {
        if (getContactTags?.length > 20) {
          if (getContactTags?.length < pageSize * currentPage) {
            currentPage = 0;
          }
          const startIndex = currentPage * pageSize;
          const endIndex = startIndex + pageSize;

          const currentPageData = getContactTags.slice(startIndex, endIndex);
          setContactData(currentPageData);
        }
        else {
          setContactData(getContactTags);
        }
        return { data: getContactData, totalPages: totalPages };
      }


      if (states === 1) {
        setStates(0);
        return { data: [], totalPages: 0 };
      }

      const listPayload = {
        uid: businessId,
        page: searchTerm ? 0 : currentPage,
        limit: pageSize,
        search: searchTerm,
      };
      const response = await ContactsGetAll(listPayload as any);
      if (response) {
        setContactData(response.data.list);
        searchTerm != null
          ? setContactCount(response.data.list.length)
          : setContactCount(response.data.count);

        return { data: response.data.list, totalPages: totalPages };
      }
      else {
        return { data: [], totalPages: 0 };
      }
    },
    [businessId, paginationLimit, searchTerm, totalPages, isSearch, select, getTagData]
  );

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault(); // Prevent space key from having its default behavior
    }
  };

  // const checkboxCellRenderer = ({ row }: any) => {
  //   const { uid } = row.original;
  //   const isSelected = state.selectedRowsUid?.has(uid);

  //   const handleChange = () => {
  //     toggleRowCheckbox(row);
  //   };

  //   return (
  //     <input
  //       type="checkbox"
  //       checked={isSelected}
  //       onChange={handleChange}
  //     />
  //   );
  // };

  // const checkboxCellRenderer = ({ row }: any) => {
  // return (
  //   <input
  //     type="checkbox"
  //     checked={selectedUids.includes(row.original.uid)}
  //     onChange={() => handleRowSelect(row.original, !selectedUids.includes(row.original.uid))}
  //   />
  // );
  // }

  const columns = [
    // {
    //   Header: (
    //     <input
    //     type="checkbox"
    //     // checked={this.state.allChecked}
    //     // onChange={this.toggleAllCheckboxes}
    //   />
    //   ),
    //   accessor: "checkbox",
    //   Cell: checkboxCellRenderer,
    //   sticky: "left",
    // },
    {
      id: "selection",
      Header: ({
        getToggleAllRowsSelectedProps,
      }: {
        getToggleAllRowsSelectedProps: any;
      }) => (
        isInvalidAccess ? <></> :
          <div>
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
      ),
      Cell: ({ row }: { row: any }) => (
        isInvalidAccess ? <></> :
          <div>
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
      ),
      accessor: "checkbox",
    },
    {
      Header: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
      Cell: ({ cell, row }: any) => mobileData(cell.value, row.original),
    },
    // {
    //   Header: "Website URL",
    //   accessor: "webSite",
    //   // Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
    //   grid: "third-column",
    // },
    {
      Header: "Tags",
      accessor: "tags",
      Cell: ({ cell, row }: any) => tagsData(cell.value, row.original),
    },
    {
      Header: "Email",
      accessor: "email",
      // Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
    },
    // {
    //   Header: "Actions",
    //   accessor: "uid",
    //   Cell: ({ cell, row }: any) => actionData(cell.value, row.original),
    // },
  ] as any;
  const numberMaskString = localStorage.getItem('numberMask');
  const numberMask = numberMaskString === 'true' || numberMasking;
  const style = {
    background: numberMask ? '#e9e9e9' : '',
    cursor: numberMask ? 'not-allowed' : '',
  };

  const toggleCommunityPopover = () => {
    if (isAccountExpired)
      setCommunityPopoverOpen(!communityPopoverOpen)
  }

  const noop = (values: any) => { };

  return (
    <div className="Community_Container">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={tab === "2" ? "" : "active"}
            onClick={newNavigate}
          // onClick={() => { this.toggle("1", ''); }}
          >
            New Community
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tab === "2" ? "active" : ""}
            onClick={existNavigate}
          >
            Existing Community
          </NavLink>
        </NavItem>
      </Nav>
      {tab === "2" ? (
        <>
          <Exist isInvalidAccess={isInvalidAccess} />
        </>
      ) : (
        <>
          {/* <TabContent> */}
          <TabPane>
            <Formik
              initialValues={initialValues}
              //validationSchema={isAccountExpired ? null : validationSchema}
              validationSchema={validationSchema}
              // onSubmit={isAccountExpired ? noop : handleSubmit}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Row className="">
                  <Label className="community-name  pt-3">Community Name</Label>
                  <Form className="">
                    <Row className="pb-2">
                      <Col md="4" lg="4" xl="4" className="community-name">
                        <Field
                          type="text"
                          name="newGroup"
                          className="form-control "
                          placeholder="Enter your Community name"
                          onKeyDown={handleKeyDown}
                          maxLength={60}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = e.target.value;
                            // this.setState({ newGroup: value });
                            setFieldValue("newGroup", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          className="text-danger"
                          name="newGroup"
                          component="div"
                        />
                      </Col>
                      <Col md="2" lg="2"></Col>
                      <Col md="6" className="">
                        <>
                          <div className={`d-flex justify-content-md-end ${isInvalidAccess ? "notAllowed" : ""} `}>
                            {/* <div className="me-2 optionBorder">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20 5H4L10.4 12.3578V17.4444L13.6 19V12.3578L20 5Z" stroke="#666E7D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>                              
                            </div> */}
                            <div
                              className="border rounded p-1 me-2"
                              onClick={handleShow}
                              style={style}
                            >
                              <ContactIcon />
                            </div>
                            {/* <div className="w-25 mt-2 mr-2"> */}
                            <Select
                              className="w-25 me-2"
                              value={getTagData}
                              onChange={tagsChange}
                              options={tagsList as any}
                              isClearable={true}
                              placeholder="Select Tag"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              isDisabled={isInvalidAccess}
                            />
                            {/* </div> */}
                            <span className={`${isInvalidAccess ?
                              "notAllowed" : ''}`} >
                              <Button
                                variant=""
                                type="submit"
                                className="sendButton w-auto"
                                disabled={isInvalidAccess}
                                id="createComButton"
                              >
                                Create Community
                              </Button>
                            </span>
                            {/* <span className={`${isInvalidAccess ?
                              "notAllowed" : isAccountExpired ? 'disableCreateComBtn' : ''}`} >
                              <Button
                                variant=""
                                type="submit"
                                className="sendButton w-auto"
                                disabled={isInvalidAccess}
                                id="createComButton"
                              >
                                Create Community
                              </Button>
                              <CommonPopover
                                isOpen={communityPopoverOpen}
                                target="createComButton"
                                toggle={toggleCommunityPopover}
                                placement="bottom"
                                component="createCommunity"
                              >
                                <div>
                                  <span><FontAwesomeIcon icon={faLock} className="mr-2" /></span>
                                  <span>Your account is temporarily disabled due to pending bills.</span>
                                </div>
                                <div className="text-center">
                                  <span><Button className="subBtn mt-3 p-2" onClick={() => {
                                    navigate('/subscription')
                                  }}>Pay Now</Button>
                                  </span>
                                  <span>
                                    <Button className="cancelButton mt-3 ml-2" onClick={toggleCommunityPopover} style={{ width: '32%' }}>
                                      Cancel
                                    </Button>
                                  </span>
                                </div>
                              </CommonPopover>
                            </ span> */}
                            {selectedUids.length > 0 && (
                              <Button
                                variant=""
                                type="button"
                                onClick={clearSelect}
                                className="cancelButton ms-2 w-25 "
                              >
                                Clear Selection
                              </Button>
                            )}
                          </div>
                        </>

                        <div>
                          {isOpenModal && (
                            <Modall
                              isOpen={isOpenModal}
                              onClose={handleClose}
                              title="Add Contact"
                              size="md"
                              component="conversation"
                            >
                              <div>
                                <div className="d-flex justify-content-between">
                                  <h5 className="ps-3">Add Contact</h5>
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      onClick={handleClose}
                                    />
                                  </div>
                                </div>
                              </div>
                              <AddContact
                                fetchContacts={fetchData}
                                handleClose={handleClose}
                              />
                            </Modall>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="ms-2">
                      <Col md="8">
                        <div>
                          Selected :{" "}
                          <span style={{ color: "#007EC3" }}>
                            {selectedUids.length} of {getContactCount} Contacts{" "}
                          </span>
                        </div>
                      </Col>
                      <Col className="pe-0 mb-2">
                        <SearchInput
                          component="community"
                          onSearchChange={onSearchChange}
                          placeHolder={"Search Contacts"}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Row>
              )}
            </Formik>
            {/* <BootstrapTable
              data={getContactData}
              selectRow={selectRowProp}
              // search
              pagination
              version="4"
              striped
              hover
              options={options as any}
              remote
              fetchInfo={{
                dataTotalSize: paginationLimit.current * totalPages,
              }}
              containerStyle={{ textAlign: "left" }}
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                hidden
              ></TableHeaderColumn>
              <TableHeaderColumn dataField="name" dataAlign="left" width="25%">
                Name
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="mobileNumber"
                dataAlign="left"
                width="25%"
                  dataFormat={mobileData}
              >
                Mobile number
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="tags"
                dataAlign="left"
                width="25%"
                dataFormat={tagsData}
              >
                Tags
              </TableHeaderColumn>

              <TableHeaderColumn dataField="email" dataAlign="left">
                Email
              </TableHeaderColumn>
            </BootstrapTable>  */}
            <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={getContactCount}
              refetchData={getContactData}
              tableName="COMMUNITY"
              searchTerm={searchTerm ?? ''}
            // onChangeSelection={onChangeSelection}
            />
          </TabPane>
        </>
      )}
    </div>
  );
};

export default Community;
