import React, { useEffect, useMemo, useState, useRef } from "react";
import { Formik, Form } from "formik";
import Select from "react-select";
import { Button, Row, Col, Popover } from "react-bootstrap";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { IsOptState, SourceMedia, ContactTypes } from "../../common/category";
import { Input, Label } from "reactstrap";
import {
  contactFilters,
  companyContactFilters,
} from "../../services/contactMemberService";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MdOutlineDateRange, MdOutlineViewDay } from "react-icons/md";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Overlay
} from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import { BsPerson } from "react-icons/bs";
import { DateFilterType } from "../Campaign/CampaignList";

const moment = extendMoment(originalMoment);

interface Props {
  contactsList: any;
  filterFunction: (filterData: any, filter: any, filterPayloads: any) => void;
  filterData: any;
  fetchData: () => void;
  pageSize: any;
}
// Define the Yup schema
const validationSchema = Yup.object({
  selectFilter: Yup.string().required("Sequence is required"),
  filterData: Yup.object().nullable().required("Select is required"),
});

const today = moment().clone().startOf("day");

const DateFilter = {
  tags: [],
  optState: null,
  // channelId: {},
  channelUids: [],
  source: [],
  dateRangeValue: moment.range(
    today.clone().subtract(7, "days").startOf("day"),
    today.endOf("day")
  ),
  conversationType: null,
};

const FailedMessageFilter = (Props: Props) => {
  const {
    contactsList,
    filterFunction,
    fetchData,
    filterData,
    pageSize
  } = Props;
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const [filter, setFilter] = useState<any>(DateFilter);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [channelOpts, setChannelOpts] = useState([])
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );

  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const { start, end } = filter?.dateRangeValue as any;
  const offsetInMinutes = start?.utcOffset();

  const startDateFormatted = start ? moment(start).add(offsetInMinutes, 'minutes').toISOString() : null;
  const endDateFormatted = end ? moment(end).add(offsetInMinutes + 1439, 'minutes').toISOString() : null;

  useMemo(() => {
    if (filterData) {
      setFilter(filterData);
    }
  }, [filterData]);

  useEffect(() => {
    getChannels();
  }, [])

  const { source, tags, optState, channelId } = filter;

  const initialValues = {
    tags: filter.tags ?? [],
    optState: filter.optState ?? "",
    source: filter.source ?? "",
    startDate: filter.dateRangeValue.start ?? "",
    endDate: filter.dateRangeValue.end ?? "",
    // channelId: filter.channelId ?? ""
    channelUids: filter.channelUids,
    conversationType: filter.conversationType ?? null,
  };

  const resetData = async (setFieldValue: any) => {
    filterFunction([], null, null);
    setFilter(DateFilter);
    setDateFilter(DateFilterType.Past7Days);
    fetchData();
  };
  const hasValues = Object.values(initialValues).some((value) => {
    if (typeof value === 'boolean') {
      return true;
    } else if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length > 0;
    } else {
      return !!value;
    }
  });



  const datepickerTarget = useRef<HTMLDivElement | null>(null);
  const datepickertarget = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datepickerTarget.current && !datepickerTarget.current?.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };
    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  const currentDate = new Date();

  const formattedEndDate = end?.format("DD/MM/YYYY");

  const startDate = moment(start);
  const formattedStartDate = startDate.format('DD/MM/YYYY');

  const getChannels = () => {
    let options;
    if (channelData?.length > 0) {
      options = channelData.map((channelId: any) => ({
        label: channelId.name,
        value: channelId.uid,
      }));
    }
    setChannelOpts(options)
  }

  const getStartDate = (
    subtractDays: number,
    unit: moment.unitOfTime.DurationConstructor = "days"
  ) => {
    return today.clone().subtract(subtractDays, unit).startOf(unit);
  };


  const changeDateFilterHandler = (event: any) => {
    const filter = event.target.value as DateFilterType;
    let dateRange = null;

    switch (filter) {
      case DateFilterType.Past7Days:
        dateRange = moment.range(
          today.clone().subtract(7, "days").startOf("day"),
          today.endOf("day")
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.Past30Days:
        dateRange = moment.range(getStartDate(30), today.endOf("day"));
        setShowDatePicker(false);
        break;

      case DateFilterType.Past90Days:
        dateRange = moment.range(getStartDate(90), today.endOf("day"));
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisWeek:
        dateRange = moment.range(today.clone().startOf("week"), today.endOf("day"));
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousWeek:
        dateRange = moment.range(
          getStartDate(1, "week"),
          getStartDate(1, "week").endOf("week")
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisMonth:
        dateRange = moment.range(today.clone().startOf("month"), today.endOf("day"));
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousMonth:
        dateRange =
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        setShowDatePicker(false);
        break;

      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;

      default:
        break;
    }
    setDateFilter(filter);
    if(dateRange){
      setFilter((prevFilter: any) => ({ ...prevFilter, dateRangeValue: dateRange }));
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={async (values, actions) => {

          const channelUids = filter?.channelUids?.map((item: any) => item.value)
          const filterPayload = {
            uid: businessUid,
            source: filter.source.map((source: any) => source.value) ?? "",
            optState: filter.optState,
            tags: filter.tags.map((tag: any) => tag.tagAll) ?? "",
            startDate: startDateFormatted,
            endDate: endDateFormatted,
            conversationType: filter?.conversationType ?? null,
            // channelId: filter?.channelId?.uid,
            channelUids: channelUids,
            page: 0,
            limit: pageSize ?? 20
            //tagname: name ?? '',
          };

          filterFunction([], filter, filterPayload);

          actions.setSubmitting(false);
        }}
      // enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, handleSubmit, values }) => (
          <Form>
            <div style={{ background: "none" }} className="">
              <Row className="d-flex align-items-center">
                <Col className="p-0" md={8}>
                  <Label className="pt-2 ps-4">Filter</Label>
                </Col>
                {filterData && (
                  <Col className="p-0">
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => resetData(setFieldValue)}
                    >
                      Reset
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
            <div
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}

              //     style={{
              //       background: 'none',
              //       overflowY: showScroll ? 'scroll' : 'hidden',
              //       // scrollbarWidth: showScroll ? 'thin' : undefined,
              //       transition: 'overflow 0.5s ease',
              //     }}
              className="filter-form"
            >

              <Row>
                <Col md={1}>
                  <MdOutlineViewDay size={20} />
                </Col>
                <Col>
                  <Label>Days</Label>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mb-3">
                <Input
                  className="dateFilter"
                  type="select"
                  size={1}
                  value={dateFilter}
                  style={{ fontFamily: '"Public Sans", sans-serif', width: "95%"}}
                  onChange={changeDateFilterHandler}
                >
                  <option value={DateFilterType.Past7Days}>
                    Past 7 days
                  </option>
                  <option value={DateFilterType.Past30Days}>
                    Past 30 days
                  </option>
                  <option value={DateFilterType.Past90Days}>
                    Past 90 days
                  </option>
                  <option value={DateFilterType.ThisWeek}>This week</option>
                  <option value={DateFilterType.PreviousWeek}>
                    Previous week
                  </option>
                  <option value={DateFilterType.ThisMonth}>
                    This month
                  </option>
                  <option value={DateFilterType.PreviousMonth}>
                    Previous month
                  </option>
                  <option value={DateFilterType.CustomRange}>
                    Custom range
                  </option>
                </Input>

              </Row>

              <>
                <Row>
                  <Col md={1}>
                    <MdOutlineDateRange size={20} />
                  </Col>
                  <Col>
                    <Label>Date</Label>
                  </Col>
                </Row>

                <Row>
                  <Col className="p-1 ps-2 pe-1">
                    <Overlay
                      target={datepickertarget.current}
                      show={showDatePicker}
                      placement="top"
                      ref={datepickerTarget}
                    >
                      <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                        <DateRangePicker
                          value={filter.dateRangeValue}
                          maximumDate={today.endOf("day")}
                          minDate={currentDate}
                          onSelect={(value: any) => {
                            const currentDate = moment();
                            let start = moment(value.start).startOf("day");
                            let end = moment(value.end).startOf("day"); // Use startOf instead of endOf

                            if (start.isAfter(currentDate, 'day')) {
                              start = currentDate.clone().startOf('day');
                            }
                            if (end.isAfter(currentDate, 'day')) {
                              end = currentDate.clone().startOf('day');
                            }

                            const newMomentRange = moment.range(start, end);
                            setDateFilter(DateFilterType.CustomRange);

                            setFilter({
                              ...filter,
                              dateRangeValue: newMomentRange,
                            } as any);
                            setShowDatePicker(false);
                          }}
                          singleDateRange={true}
                        />

                      </Popover>
                    </Overlay>
                    <div
                      ref={datepickertarget}
                      className=""
                      onClick={() => setShowDatePicker((current) => !current)}
                    >
                      {start && end ? (
                        <div className="p-2"
                          style={{
                            border: "2px solid #E2E2E2",
                            borderRadius: "4px",
                            width: '97%',
                          }}
                        >
                          {`${start?.format("DD/MM/YYYY")} - ${end?.format("DD/MM/YYYY")}`}
                        </div>
                      ) : (
                        <div
                          className="p-2"
                          style={{
                            color: "rgb(128 130 132)",
                            border: "2px solid #E2E2E2",
                            borderRadius: "4px",
                            width: '97%',
                            fontSize: "12px"
                          }}>
                          DD/MM/YYYY-DD/MM/YYYY
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </>

            </div>
            {hasValues && (
              <footer>
                <div className="d-flex pt-2 footer-in-filter">
                  <Button
                    className="cancelButton p-1 me-2 fw-bold"
                    onClick={() => resetData(setFieldValue)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className={`sendButton w-50 p-0 ${isSubmitting && ('d-flex justify-content-evenly align-items-center')}`}
                    disabled={isSubmitting}
                  >
                    Apply {isSubmitting && (<div className="loadingVia" style={{ width: '20px', height: '20px' }}></div>)}
                  </Button>
                </div>
              </footer>
            )}
          </Form>
        )}
      </Formik>
      {/* {isLoading ? <p>Loading...</p> : filteredContacts.length === 0 ? <p>No results found.</p> : <ul />} */}
    </div>
  );
};

export default FailedMessageFilter;
