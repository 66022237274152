import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  CardBody,
  Container,
  Tooltip,
  UncontrolledTooltip,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  PopoverBody,
  Popover,
  FormGroup,
  Label,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarMinus,
  faClock,
  faEdit,
  faTrashAlt,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { useQuery } from "@tanstack/react-query";
import excel from "../../assets/img/excel.png";
import { FcCalendar } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Avatar from "react-avatar";
import { FcOvertime } from "react-icons/fc";
import {
  faChevronLeft,
  faChevronRight,
  faClockRotateLeft,
  faList,
  faRefresh,
  faSortAlphaAsc,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDesc,
  faSortNumericDown,
  faSortNumericUp,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { LuTimer } from "react-icons/lu";
import styles from "./Appointments.module.scss";
import * as appointmentService from "../../services/appointmentService";
import SyncButton from "../../common/syncButton";
import NoDataFallback from "../../common/noDataFallback";
import RightSidebar from "../../common/rightSidebar";
import MemberCalenderForm from "./MemberCalenderForm";
import Loader from "../../shade/Loaders/smallLoader";
import AppointmentForm from "./AppointmentForm";
import { showAlertYesNo } from "../../common/alertYesNo";
import { sweetalert, toast } from "../../common/alert";
import RescheduleForm from "./RescheduleForm";
import FullScreenPopover from "../../common/fullScreenPopover";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";
import axios from "../../utils/axios";

import { Card, Col, Row, CardHeader, CardText } from "reactstrap";
import { OverlayTrigger, Stack } from "react-bootstrap";
import { current } from "@reduxjs/toolkit";
import noData from "../../assets/img/noData.png";
import BizTable from "../../common/BizTable";
import SearchInput from "../../common/searchComponent";
import downloadExcel from "./downloadExcel";
import Select from "react-select";
import { BsCalendarMinus } from "react-icons/bs";
import { Tooltip as Tool } from "react-bootstrap";
import { setBulkDeletionForAppointments } from "../../services/appointmentService";
import Modall from "../../common/modall";

type CalenderViewProps = {
  appoinments: appointmentService.IAppointment[];
  setEdit: (appointment: any) => any;
};

const CalenderView = (props: CalenderViewProps) => {
  return (
    <div className={styles.calenderView}>
      <FullCalendar
        plugins={[dayGridPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={false}
        selectable={false}
        selectMirror={false}
        dayMaxEvents={false}
        weekends={true}
        events={(info, successCallback, failureCallback) => {
          const events = props.appoinments.map((appointment) => {
            if (appointment.emergencyMember) {
              return {
                id: appointment.id.toString(),
                title: appointment.name,
                start: new Date(appointment.date).toISOString(),
                allDay: true, // hide the default time
              };
            } else {
              const startDate = new Date(
                appointment?.scheduledAt?.date +
                "T" +
                appointment?.scheduledAt?.startTime
              ).toISOString();

              return {
                id: appointment.id.toString(),
                title: appointment.name,
                start: startDate,
              };
            }
          });
          successCallback(events);
        }}
        eventClick={(event: any) => {
          const appointmentId = parseInt(event.event._def.publicId);
          const appointment = props.appoinments.find(
            (a) => a.id === appointmentId
          );
          if (appointment) {
            props.setEdit(appointment);
          }
        }}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
          hour12: true,
        }}
      />
    </div>
  );
};

type Props = {};

function Appointments({ }: Props) {
  const [calenderView, setCalenderView] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [isClearSelected, setClearSelected] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch()

  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const role = useSelector(
    (state: any) => state.cartreducer.business?.role?.type
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

  useEffect(() => {
    if (roleRules) {
      const isInvalidAccess = roleRules.canViewAppointmentsConfigurationAndAppoitmentsBooked === true && roleRules.canManageAppointmentsConfigurationAndAppoitmentsBooked === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);


  const selectedTableData = useSelector((state: any) => state.tableReducer.selectedUids);

  useEffect(() => {
    const initialSelected = Object.values(selectedTableData).flat();
    setSelectedRows(initialSelected);
    setSelectedCount(initialSelected.length);
  }, [selectedTableData])

  const statusOptions = [
    {
      label: "Pending",
      value: "PENDING"
    },
    {
      label: "Completed",
      value: "COMPLETED"
    },
    {
      label: "Cancelled",
      value: "CANCELLED"
    }
  ]

  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const channelId = channelData?.value;

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const name = useSelector((state: any) => state.cartreducer.business?.name);
  // const [memName, setMemName] = useState(name);
  const [memName, setMemName] = useState("");

  const [showCreateAppointment, setShowCreateAppointment] = useState(false);

  const [showCreateCalendar, setShowCreateCalendar] = useState(false);
  const [showMemberCalender, setShowMemberCalender] = useState(false);
  const [showEditMemberCalender, setShowEditMemberCalender] = useState(false);
  const memberId = useSelector((state: any) => state.cartreducer.business?.id);
  let memberRef = useRef(0);
  let memberIdRef = useRef(0);
  const [memId, setMemId] = useState(memberId);
  const [showEditCalendar, setShowEditCalendar] = useState(false);
  const [showEditAppointment, setShowEditAppointment] = useState<null | any>(
    null
  );

  const [showReschedule, setShowReschedule] = useState<null | any>(null);
  const [memberAppointmentsQuery, setMemberAppointmentsQuery] =
    useState<any>(null);

  const [count, setCount] = useState(0);
  const memberUid = useSelector(
    (state: any) => state.cartreducer.business?.uid
  );

  const [open, setOpen] = useState("1");

  const toggleAccordion = (id: string) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const [memUid, setMemUid] = useState(memberUid);

  const limit = 1000;
  memberRef.current = memUid;
  memberIdRef.current = memId;
  const query = useQuery({
    queryKey: ["members"],
    queryFn: async () => {
      try {
        if (!businessUid) {
          return new Promise(() => { });
        }
        const response = await axios.post("/member/getMembers", {
          businessUid,
          page: 0,
          limit: limit,
          status: ["ACTIVE"],
        });

        return response.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    enabled: limit > 0,
  });
  const getAllMemberConfigration = useQuery({
    queryKey: ["get-memberconfig", businessUid],
    queryFn: () => appointmentService.getAllMemberConfiguration(businessUid),
    retry: false,
  });
  const [Index, setIndex] = useState(0);

  const getData = getAllMemberConfigration?.data?.find(
    (data: any) => data.memberUid === memUid
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex + 1) % Math.ceil(filteredAppointments.length / 1)
    );
    setIndex(
      (prevIndex) =>
        (prevIndex + 1) % Math.ceil(getAllMemberConfigration?.data?.length / 3)
    );
  };

  const prev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? Math.ceil(filteredAppointments.length / 3) - 1
        : prevIndex - 1
    );
  };

  const [appoinment, setAppointments] = useState([]);

  const getItemsForCurrentIndex = () => {
    const startIndex = currentIndex * 1;
    return filteredAppointments?.slice(startIndex, startIndex + 1);
  };

  const getitemsforCard = () => {
    const startIndex = Index * 3;
    return getAllMemberConfigration.data?.slice(startIndex, startIndex + 3);
  };

  useEffect(() => {
    const items = getitemsforCard();
    if (items && items.length > 0) {
      setMemId(items[0].memberId)
      // if(role ==='OWNER'){
      //   setMemUid(items[0].memberUid);
      // }
      if(role ==='OWNER'){
        setMemUid(items[0].memberUid);
      }
      setMemName(items[0].memberName);
    }
  }, [getAllMemberConfigration.data, Index]);

  const getItemsforCalender = () => {
    const startIndex = Index * 1;
    return getAllMemberConfigration.data?.slice(startIndex, startIndex + 1);
  };

  const memberCalenderQuery = useQuery({
    queryKey: ["calender-config", memUid],
    queryFn: () => appointmentService.getMemberCalender(memUid),
    retry: false,
  });
  const [data, setData] = useState(undefined); // Initialize state with undefined
  const { data: queryData, refetch } = memberCalenderQuery;

  useEffect(() => {
    if (queryData) {
      setData(queryData);
    }
  }, [queryData]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [config, setCurrentConfig] = useState("");

  const [nextIndex, setNextIndex] = useState(1);

  const nextData = () => {
    setIndex((prevIndex) =>
      prevIndex === totalConfigs - 1 ? 0 : prevIndex + 1
    );
  };
  const memberConfigMap = new Map(
    getAllMemberConfigration.data?.map((config: any) => [
      config.id.toString(),
      config.memberId,
    ])
  );
  const configValues = Array.from(memberConfigMap.values()).map((val: any) =>
    val.toString()
  );
  const memberConfiguration = getAllMemberConfigration?.data?.filter(
    (data: any) => data.memberUid !== memberUid
  );

  const ownerCard = getAllMemberConfigration?.data?.find(
    (data: any) => data.memberUid === memberUid
  );

  const filteredAppointments = query?.data?.dataDTO?.filter(
    (appointment: any) =>
      // (appointment.role.type === "MEMBER" || appointment.role.type === "OWNER" || appointment.role.type) 
    appointment.role?.type
    && !configValues.includes(appointment.id.toString())
  );

  const currentTime = moment().toISOString();

  const totalConfigs = memberConfiguration?.length;

  const totalMember = totalConfigs + filteredAppointments?.length;

  const totalLength =
    getitemsforCard()?.length + getItemsForCurrentIndex()?.length;

  const prevData = () => {
    setIndex((prevIndex) =>
      prevIndex === 0
        ? Math.ceil(getAllMemberConfigration.data?.length / 3) - 1
        : prevIndex - 1
    );
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? Math.ceil(filteredAppointments?.length / 2) - 1
        : prevIndex - 1
    );
  };
  interface SelectOption {
    value: string;
    label: string;
  }
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const sortoptions = [
    { value: "date", label: "Sort by Date" },
    { value: "name", label: "Sort by Name" },
  ];
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 150, // Adjust the width as needed
      zIndex: 9999999999999,
    }),
    // Add more styles as needed
  };

  const filter = useRef("");

  const [filterState, setFilterState] = useState("");
  const [isSync, setSync] = useState(false);
  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  };

  const getAvailableDays = (selectedDays: boolean[]) => {
    if (selectedDays.length !== 7) {
      toast("error", "Something went Wrong!");
    }
    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const availableDays: string[] = [];
    for (let i = 0; i < selectedDays.length; i++) {
      if (selectedDays[i]) {
        availableDays.push(dayNames[i]);
      }
    }
    if (availableDays.length === 0) {
      return "No days available";
    } else {
      return availableDays.join(" ,");
    }
  };

  const [popoverOpen, setPopoverOpen] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleMouseEnter = (id: string) => {
    setPopoverOpen((prev) => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = (id: string) => {
    setPopoverOpen((prev) => ({ ...prev, [id]: false }));
  };

  // const memberAppointmentsQuery = useQuery({
  //   queryKey: ["member-appointments", memUid],
  //   queryFn: () => appointmentService.getAppointment(memUid),
  // });
  const holidayQuery = useQuery({
    queryKey: [
      "holiday-list",
      memberCalenderQuery.data?.dateRange.startDate +
      "-" +
      memberCalenderQuery.data?.dateRange.endDate,
    ],
    queryFn: () =>
      appointmentService.getSingleHolidayList({
        id: memberCalenderQuery.data?.holidayList,
        dateRange: memberCalenderQuery.data?.dateRange,
      }),
    enabled:
      memberCalenderQuery.isSuccess &&
      memberCalenderQuery.data &&
      memberCalenderQuery.data.holidayList > 0,
  });

  useEffect(() => {
    if (
      memberCalenderQuery.isSuccess &&
      !holidayQuery.isFetched &&
      memberCalenderQuery.data.holidayList > 0
    ) {
      holidayQuery.refetch();
    }
  }, [memberCalenderQuery]);

  // Change bgcolor
  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = "#F4F4F4";
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  const contactData = (cell: any, row: any) => {
    return (
      <>
        {cell.value ? (
          <>
            <Avatar
              fgColor="#fff"
              name={cell.value.name}
              round
              size="25"
              textSizeRatio={3.5}
            />
            <span
              style={{ marginLeft: "5px", color: "#2E3967", marginTop: "3px" }}
            >
              {cell.value.name}
            </span>
          </>
        ) : (
          <span className="text-muted">(No Contact)</span>
        )}
      </>
    );
  };

  const contactNum = (cell: any, row: any) => {
    const value = cell.value || "";
    const countryCode = value.slice(0, 2);
    const number = value.slice(2);
    const numberMasking = useSelector((state: any) => state.cartreducer.numberMasking);
    const numberMask = localStorage.getItem("numberMask") === "true" || numberMasking;

    return (
      <>
        {value ? (
          <>
            <span
              className={`${numberMask ? "numberMasking_Blur" : ""}`}
              style={
                numberMask
                  ? {}
                  : { marginLeft: "1px", color: "#2E3967", marginTop: "3px" }
              }
            >
              {`+${countryCode} ${number}`}
            </span>
          </>
        ) : (
          <span className="text-muted">(No Number)</span>
        )}
      </>
    );
  };

  const handleFilterChange = (selectedFilter: any) => {
    filter.current = selectedFilter;
    setFilterState(selectedFilter);

    fetchData(0, 20, "", 0, false, filter.current);
  };
  const handleDownload = async () => {
    const response = await fetchDataforExcel(
      0,
      1000,
      "",
      0,
      false,
      filter.current
    );
    downloadExcel(response.data, memName, data);
  };

  const formatDate = (date: any) => {
    const data = new Date(date);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    } as const;
    const formattedDate = data.toLocaleString("en-US", options);
    return formattedDate;
  };

  const checkBoxHandler = (value: any, row: any) => {

  }

  const submitStatus = async () => {
    if (selectedStatus.value) {
      setUpdateStatus(false);
      const uids = selectedRows?.map((row: any) => row.uid);
      const payload = {
        type: "UPDATE",
        uids: uids,
        channelId: channelId,
        appointmentStatus: selectedStatus.value
      }
      const response = await setBulkDeletionForAppointments(payload);
      if (response) {
        fetchData(0, 20, "", currentPage);
        clearSelection();
        setSelectedRows([]);
        dispatch({ type: 'TABLE_RESET' }); 
        toast("success", "Appointment status updated successfully");
      }
    }
  }

  const columns = [
    {
      id: "selection",
      Header: ({
        getToggleAllRowsSelectedProps,
      }: {
        getToggleAllRowsSelectedProps: any;
      }) => (
        <div>
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }: { row: any }) => (
        <div>
          <input type="checkbox" {...row.getToggleRowSelectedProps()} />
        </div>
      ),
      accessor: "checkbox",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell, row }: any) => nameData(cell.value, row.original),
    },

    {
      Header: "Booked On",
      accessor: "createdtime",
      Cell: ({ cell, row }: any) => (
        <div style={{ fontSize: "10px" }}>
          {formatDate(row?.original?.createdat)}
        </div>
      ),
    },

    {
      Header: "Scheduled Date",
      accessor: "scheduled",
      Cell: ({ cell, row }: any) => {
        const scheduledAt = row?.original?.scheduledAt;
        const date = row?.original?.date;
        const emergencyMember = row?.original?.emergencyMember;

        if (scheduledAt && !emergencyMember) {
          return moment(scheduledAt.date).format("Do MMM YYYY");
        } else if (emergencyMember) {
          return moment(date).format("Do MMM YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      Header: "Timeslot",
      accessor: "scheduledAt",
      Cell: ({ cell, row }: any) =>
        cell.value ? (
          <span style={{ fontSize: "10px" }}>
            {moment(cell.value.startTime, "HH:mm:ss").format("h:mm a")} to{" "}
            {moment(cell.value.endTime, "HH:mm:ss").format("h:mm a")}
          </span>
        ) : (
          <span style={{ fontSize: "10px" }}>-</span>
        ),
    },
    {
      Header: "Contact",
      accessor: "contact",
      Cell: ({ cell, row }: any) => contactData(cell, row),
    },
    {
      Header: "Number",
      accessor: "contact.mobileNumber",
      Cell: ({ cell, row }: any) => contactNum(cell, row),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell, row }: any) =>
        cell.value ? (
          <h5>
            {cell.value === "PENDING" ? (
              <Badge color="warning">Pending</Badge>
            ) : cell.value === "COMPLETED" ? (
              <Badge color="success">Completed</Badge>
            ) : cell.value === "CANCELLED" ? (
              <Badge color="danger">Cancelled</Badge>
            ) : (
              <Badge>{cell.value}</Badge>
            )}
          </h5>
        ) : (
          "-"
        ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ cell, row }: any) => (
        <div className="d-flex">
          <Button
            id={"edit-appointment-" + row.id}
            color="white"
            onClick={() => setShowEditAppointment(row)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <UncontrolledTooltip
            placeholder="bottom"
            target={"edit-appointment-" + row.id}
          >
            Edit Appointment
          </UncontrolledTooltip>

          {
            row.values.status !== "COMPLETED" &&
              row.values.status !== "CANCELLED" &&
              row.values.status !== "CLOSE" &&
              !row?.original?.emergencyMember && !isInvalidAccess ? (
              <>
                <Button
                  id={"reschedule-" + row.id}
                  color="white"
                  onClick={() => setShowReschedule(row)}
                >
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                </Button>
                <UncontrolledTooltip
                  placeholder="bottom"
                  target={"reschedule-" + row.id}
                >
                  Reschedule
                </UncontrolledTooltip>
              </>
            ) : (
              <span className={`${isInvalidAccess ? "notAllowed" : ""}`}>
                <Button
                  id={"reschedule-" + row.id}
                  color="white"
                  className={`${isInvalidAccess ? "disabledState" : ""}`}
                // onClick={() =>null}
                >
                  <FontAwesomeIcon icon={faClockRotateLeft} color="grey" />
                </Button>
                <UncontrolledTooltip
                  placeholder="bottom"
                  target={"reschedule-" + row.id}
                >
                  Reschedule not enabled
                </UncontrolledTooltip>
              </span>
            ) // <FontAwesomeIcon icon={faClockRotateLeft} size="" />
          }
          <span className={`${isInvalidAccess ? "notAllowed" : ""}`}>
            <Button
              id={"delete-appointment-" + row.id}
              color="white"
              className={`text-danger ${isInvalidAccess ? "disabledState" : ""}`}
              onClick={() => deleteAppoinmentHandler(row)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </ span>
          {/* <UncontrolledTooltip
            placeholder="bottom"
            target={"delete-appointment-" + row.id}
          >
            Delete Appointment
          </UncontrolledTooltip> */}
        </div>
      ),
    },
  ] as any;

  const fetchData = useCallback(
    async (
      pageIndex: number,
      pageSize: number,
      searchText: string,
      currentPage: number,
      issync?: boolean,
      queryTerm?: string
    ): Promise<{ data: any; totalPages: number }> => {
      const payload = {
        page: currentPage,
        limit: pageSize,
        memberId: memberRef.current,
        search: searchTerm,
        query: filter.current,
      };
      const response = await appointmentService.getAppointmentV2(payload);
      setAppointments(response.data.calyAppoint);
      setCurrentPage(currentPage);

      const { calyAppoint, count } = response.data;
      if (response) {
        setMemberAppointmentsQuery(calyAppoint);
        const countData = searchTerm ? calyAppoint.length : count;
        setCount(countData);
        return { data: calyAppoint, totalPages: count };
      } else {
        return { data: [], totalPages: 0 };
      }
    },
    [memberRef.current, searchTerm]
  );
  const fetchDataforExcel = useCallback(
    async (
      pageIndex: number,
      pageSize: number,
      searchText: string,
      currentPage: number,
      issync?: boolean,
      queryTerm?: string
    ): Promise<{ data: any; totalPages: number }> => {
      const payload = {
        page: currentPage,
        limit: pageSize,
        memberId: memberRef.current,
        search: searchTerm,
        query: filter.current,
      };
      const response = await appointmentService.getAppointmentV2(payload);
      setAppointments(response.data.calyAppoint);

      const { calyAppoint, count } = response.data;
      if (response) {
        const countData = searchTerm ? calyAppoint.length : count;
        setCount(countData);
        return { data: calyAppoint, totalPages: count };
      } else {
        return { data: [], totalPages: 0 };
      }
    },
    [memberRef.current, searchTerm]
  );

  const nameData = (cell: any, row: any) => {
    return (
      <div>
        {row?.name?.length > 13 ? (
          <TooltipReference
            placement="right"
            content={row?.name}
            tooltipId={`nameData_${row.id}`}
          >
            {sliceText(row?.name, 14)}
          </TooltipReference>
        ) : (
          <span>{sliceText(row?.name, 14)}</span>
        )}
      </div>
    );
  };

  const resetCalenderHandler = async () => {
    const alertResponse = await showAlertYesNo(
      "question",
      "Are you sure you want to reset your calendar ?"
    );
    if (alertResponse.isConfirmed) {
      try {
        await appointmentService.deleteMemberCalender(memberUid);
        toast("success", "Calendar has been successfully reset");
        memberCalenderQuery.refetch();
      } catch (error) {
        toast("error", "Calender failed to reset");
      }
    }
  };

  const clearSelection = () => {
    setClearSelected(true);
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    checkboxes?.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.click();
      }
      setTimeout(() => {
        setClearSelected(false);
      }, 3000);
    })
  };

  const handleDeleteSelected = () => {
    sweetalert(
      "warning",
      " Are you sure you want to delete this appointments?",
      async () => {
        if (selectedRows) {
          const uids = selectedRows.map((row: any) => row.uid);
          const payload = {
            type: "DELETE",
            uids: uids,
            channelId: channelId
          }
          const response = await setBulkDeletionForAppointments(payload);
          if (response) {
            // const contactList = await contactGetALL(businessId);
            fetchData(0, 20, "", currentPage);
            dispatch({type: "RESET_DATA"});
            toast("success", "Appointment deleted successfully");
          }
        }
      },
      () => {
        clearSelection();
      }
    );
  };

  const updateBulkStatus = () => {
    setUpdateStatus(true);
    setSelectedStatus(null);
  }

  const [viewdata, setShowData] = useState(true);
  const resetAllHandler = async () => {
    const alertResponse = await showAlertYesNo(
      "question",
      "Are you sure you want to reset your calendar ?"
    );
    if (alertResponse.isConfirmed) {
      try {
        await appointmentService.deleteMemberCalender(
          memberRef.current.toString()
        );
        toast("success", "Calendar has been successfully reset");
        setShowData(false);
        getAllMemberConfigration.refetch();
        query.refetch();
      } catch (error) {
        toast("error", "Calender failed to reset");
      }
    }
  };

  const deleteAppoinmentHandler = async (appoinment: any) => {
    const alertResponse = await showAlertYesNo(
      "question",
      "Are you sure you want to delete this appointment?"
    );
    if (alertResponse.isConfirmed) {
      try {
        await appointmentService.deleteAppointment(
          appoinment?.original.uid,
          channelId
        );
        toast("success", "Appointment Deleted Successfully");
        // memberAppointmentsQuery.refetch()
        fetchData(0, 20, "", 0);
      } catch (error) {
        toast("error", "Appointment failed to delete");
      }
    }
  };

  let userCalendar = <Alert color="error"></Alert>;

  if (memberCalenderQuery.isFetched && memberCalenderQuery.isError) {
    userCalendar = (
      <div>
        {/* <h5 className="mt-2 ml-2">Appointments</h5> */}
        <div className={styles.myCalender}>
          <h5 className="mt-2 ml-2 align-self-start">Appointments</h5>
          <br />
          <img
            className={styles.img}
            src={`${process.env.PUBLIC_URL}/images/appointments/caly-setup.png`}
            alt="Setup Calendar"
          />
          <div className={styles.content}>
            <h3>Set up My Calendar</h3>
            <p>
              Set up your appointment calendar with preferred time slots and
              available dates in just a few clicks.
            </p>
            <Button
              // color='primary'
              className="signUpBtn"
              onClick={() => setShowCreateCalendar(true)}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (
    memberCalenderQuery.isFetched &&
    memberCalenderQuery.data &&
    role !== "OWNER"
  ) {
    userCalendar = (
      <div className={styles.myCalender}>
        <div className={styles.content}>
          <div className="d-flex">
            <h3>My Appointment Calendar</h3>
            <div className={styles.actions}>
              <Button
                id="editCalenderTooltip"
                size="sm"
                color="white"
                onClick={() => setShowEditCalendar(true)}
                className="ml-2"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <UncontrolledTooltip
                placeholder="bottom"
                target="editCalenderTooltip"
              >
                Edit calender
              </UncontrolledTooltip>
              <span className={`${isInvalidAccess ? "notAllowed" : ""}`}>
                <Button
                  id="resetCalenderTooltip"
                  size="sm"
                  color={`white ${isInvalidAccess ? "disabledState" : ""} `}
                  onClick={resetCalenderHandler}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </Button>
                <UncontrolledTooltip
                  placeholder="bottom"
                  target="resetCalenderTooltip"
                >
                  Reset calender
                </UncontrolledTooltip>
              </span>
            </div>
          </div>
          <ul>
            <li>
              <FontAwesomeIcon icon={faCalendar} className="mr-2" />
              {memberCalenderQuery.data.dateRange.endDate ? (
                <>
                  {moment(memberCalenderQuery.data.dateRange.startDate).format(
                    "D MMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(memberCalenderQuery.data.dateRange.endDate).format(
                    "D MMM YYYY"
                  )}
                </>
              ) : (
                <>
                  From{" "}
                  {moment(memberCalenderQuery.data.dateRange.startDate).format(
                    "D MMM YYYY"
                  )}
                </>
              )}
            </li>
            <li>
              <FontAwesomeIcon icon={faClock} className="mr-2" />
              {memberCalenderQuery.data.availableHour
                .map(
                  (val: any) =>
                    moment(val.startTime, "HH:mm:ss").format("h:mm a") +
                    " to " +
                    moment(val.endTime, "HH:mm:ss").format("h:mm a") +
                    "(" +
                    getAvailableDays(val?.day) +
                    ")"
                )
                .join("; ")}
            </li>
            {memberCalenderQuery.data.unavailableDate.length > 0 ? (
              <li>
                <FontAwesomeIcon icon={faCalendarMinus} className="mr-2" />
                {memberCalenderQuery.data.unavailableDate.length <= 4 ? (
                  <>
                    Unavailable days:{" "}
                    {memberCalenderQuery.data.unavailableDate
                      .map((date: string) =>
                        moment(date, "YYYY-MM-DD").format("D MMM YYYY")
                      )
                      .join(", ")}
                  </>
                ) : (
                  memberCalenderQuery.data.unavailableDate.length +
                  " unavailable days"
                )}
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    );
  } else if (
    getAllMemberConfigration.isFetched &&
    getAllMemberConfigration.data &&
    role === "OWNER"
  ) {
    userCalendar = (
      <div className={styles.appointmentHeader}>
        <Accordion open={open} toggle={toggleAccordion}>
          <AccordionItem>
            <AccordionHeader
              targetId="1"
              cssModule={{ "accordion-header": styles.customAccordionHeader }}
            >
              <h5>Appointments</h5>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <div className={styles.appointmentsCalendar}>
                <div>
                  {getAllMemberConfigration?.data.length > 3 && (
                    <Button
                      className="prev-Data"
                      style={{ backgroundColor: "#56B29C", color: "white" }}
                      size="sm"
                      onClick={prevData}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                  )}
                </div>
                <div>
                  <Row className="align-items-center">
                    <Col
                      md={
                        getitemsforCard().length === 1
                          ? 6
                          : getitemsforCard().length === 2
                            ? 9
                            : 9
                      }
                      style={{ minWidth: "14rem" }}
                    >
                      <div className="d-flex align-items-center">
                        <Stack
                          direction="horizontal"
                          className="h-100 justify-content-center align-items-center"
                          // style={{position:"relative"}}
                          gap={3}
                        >
                          {getitemsforCard().map(
                            (currentConfig: any, index: number) => {
                              const availableHours =
                                currentConfig?.availableHour || [];

                              const formattedHours = availableHours.map(
                                (val: any) => ({
                                  timeRange: `${moment(
                                    val.startTime,
                                    "HH:mm:ss"
                                  ).format("h:mm a")} to ${moment(
                                    val.endTime,
                                    "HH:mm:ss"
                                  ).format("h:mm a")}`,
                                  day: `(${getAvailableDays(val?.day)})`,
                                })
                              );

                              const cardId = `hoursPopover-${index}`;
                              return (
                                <Card
                                  key={currentConfig.id}
                                  className={styles.appointmentCard}
                                  onClick={() => {
                                    setSearchTerm(null);
                                    setMemUid(currentConfig?.memberUid);
                                    setShowData(true);
                                    setData(currentConfig);
                                    setCurrentConfig(currentConfig);
                                    setMemId(currentConfig?.memberId);
                                    setMemName(currentConfig?.memberName);
                                    memberRef.current =
                                      currentConfig?.memberUid;
                                    fetchData(0, 20, "", 0);
                                  }}
                                  style={{
                                    minWidth: "14em",
                                    width: "17rem",
                                    position: "relative",
                                    border:
                                      memUid === currentConfig?.memberUid
                                        ? "3px solid #B5E2BC"
                                        : "",
                                    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                    left: "10px",
                                    height: "13rem",
                                    cursor: "pointer",
                                    backgroundColor: "green",
                                    // color: currentConfig.memberUid === memUid ? "#458D80" : "",
                                  }}
                                >
                                  <CardHeader className="p-0">
                                    <div
                                      className={styles.appointmentCardHeader}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="me-2">
                                          <Avatar
                                            fgColor="#fff"
                                            name={currentConfig?.memberName}
                                            round
                                            size="25"
                                            textSizeRatio={3.5}
                                          />
                                        </div>
                                        <div>
                                          <TooltipReference
                                            placement="left"
                                            tooltipId={`name${currentConfig?.id}`}
                                            content={currentConfig?.memberName}
                                          >
                                            {currentConfig?.memberName?.length <=
                                              20
                                              ? currentConfig?.memberName
                                              : currentConfig?.memberName?.substring(
                                                0,
                                                20
                                              ) +
                                              (currentConfig.memberName
                                                ?.length > 20
                                                ? "..."
                                                : "")}
                                          </TooltipReference>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <Button
                                            id="editCalenderTooltip"
                                            size="sm"
                                            color="white"
                                            onClick={() => {
                                              memberRef.current =
                                                currentConfig?.memberUid;
                                              setShowEditMemberCalender(true);
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                            <Tooltip
                                              placement="right"
                                              isOpen={tooltipOpen}
                                              target="editCalenderTooltip"
                                              toggle={toggle}
                                            >
                                              Edit
                                            </Tooltip>
                                          </Button>
                                        </div>
                                        <div>
                                          <Button
                                            id="resetCalenderTooltip"
                                            size="sm"
                                            color="white"
                                            onClick={() => {
                                              // setMemUid(currentConfig?.memberUid);
                                              // memberRef.current = currentConfig?.memberUid;
                                              resetAllHandler();
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faRefresh} />
                                          </Button>
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="resetCalenderTooltip"
                                          >
                                            Reset calendar
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                    </div>
                                  </CardHeader>
                                  <CardBody className="p-0">
                                    <div className={styles.appointmentCardBody}>
                                      <div className={styles.availableDates}>
                                        <div className="me-1" title="Available Dates">
                                          <FcCalendar size={20} />
                                        </div>
                                        <div>
                                          {currentConfig?.dateRange.endDate ? (
                                            <>
                                              {moment(
                                                currentConfig?.dateRange
                                                  .startDate
                                              ).format("D MMM YYYY")}{" "}
                                              -{" "}
                                              {moment(
                                                currentConfig?.dateRange.endDate
                                              ).format("D MMM YYYY")}
                                            </>
                                          ) : (
                                            <>
                                              From{" "}
                                              {moment(
                                                currentConfig?.dateRange
                                                  .startDate
                                              ).format("D MMM YYYY")}
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        key={index}
                                        className={styles.availableTime}
                                      >
                                        <div className="me-1" title="Available Hours">
                                          <FcOvertime size={20} />
                                        </div>
                                        <div style={{
                                          width: '85%'
                                        }}>
                                          {/* Show only up to 2 available hours */}
                                          {formattedHours
                                            .slice(0, 2)
                                            .map((hour: any, idx: number) => (
                                              <div key={idx}>
                                                <div>{hour.timeRange}</div>
                                                <div>{hour.day}</div>
                                              </div>
                                            ))}

                                          {/* If there are more than 2 entries, show '...' and popover */}
                                          {availableHours.length > 2 && (
                                            <>
                                              <div className="d-flex justify-content-end align-items-center">
                                                <span
                                                  id={cardId}
                                                  style={{
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  onMouseEnter={() =>
                                                    handleMouseEnter(cardId)
                                                  }
                                                  onMouseLeave={() =>
                                                    handleMouseLeave(cardId)
                                                  }
                                                >
                                                  ... {" "}
                                                  <span
                                                    style={{
                                                      color: "#00b598",
                                                      marginLeft: "5px",
                                                      cursor: "pointer",
                                                    }}
                                                    id={cardId}
                                                    onMouseEnter={() =>
                                                      handleMouseEnter(cardId)
                                                    }
                                                    onMouseLeave={() =>
                                                      handleMouseLeave(cardId)
                                                    }
                                                  >
                                                    more
                                                  </span>
                                                </span>
                                                <Popover
                                                  isOpen={
                                                    popoverOpen[cardId] || false
                                                  }
                                                  target={cardId}
                                                  placement="top-start" // Top left positioning
                                                  toggle={() => { }}
                                                >
                                                  <PopoverBody>
                                                    {formattedHours.map(
                                                      (
                                                        hour: any,
                                                        idx: number
                                                      ) => (
                                                        <div key={idx} style={{
                                                          fontSize: "12px",
                                                          display: "flex",
                                                          color: "#545454",
                                                        }}>
                                                          <div className="me-1">
                                                            <LuTimer size={12} color='#2f855a' />
                                                          </div>

                                                          <div>
                                                            <div>
                                                              {hour.timeRange}
                                                            </div>
                                                            <div>{hour.day}</div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </PopoverBody>
                                                </Popover>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      {currentConfig?.unavailableDate.length >
                                        0 ? (
                                        <div
                                          className={styles.unavailableDates}
                                        >
                                          <div className="me-1 ms-1">
                                            <BsCalendarMinus size={12} />
                                          </div>
                                          <div>
                                            <div>
                                              {currentConfig?.unavailableDate
                                                .length <= 4 && (
                                                  <> Unavailable days: </>
                                                )}
                                            </div>
                                            <div>
                                              {currentConfig?.unavailableDate
                                                .length <= 4 ? (
                                                <>
                                                  {currentConfig?.unavailableDate
                                                    .map((date: string) =>
                                                      moment(
                                                        date,
                                                        "YYYY-MM-DD"
                                                      ).format("D MMM YYYY")
                                                    )
                                                    .join(", ")}
                                                  {/* {currentConfig?.unavailableDate.map((date: string) => (
                                                  <div key={date}>
                                                    {moment(date, "YYYY-MM-DD").format("D MMM YYYY")}
                                                  </div>
                                                ))} */}
                                                </>
                                              ) : (
                                                currentConfig.unavailableDate
                                                  .length + " unavailable days"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={styles.noUnavailableDates}
                                        >
                                          <div className="d-flex align-items-center">
                                            <div className="me-1 ms-1">
                                              <BsCalendarMinus size={12} />
                                            </div>

                                            <div>Unavailable days: </div>
                                          </div>

                                          <div
                                            style={{
                                              opacity: 0.6,
                                              marginLeft: "2rem",
                                            }}
                                          >
                                            ** No dates found **
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </CardBody>
                                </Card>
                              );
                            }
                          )}
                        </Stack>
                      </div>
                    </Col>

                    {role === "OWNER" && (
                      <Col
                        md={
                          getitemsforCard().length === 1
                            ? 6
                            : getitemsforCard().length == 2
                              ? 3
                              : 3
                        }
                        style={{
                          minWidth: "12em",
                          position: "relative",
                          padding: "0px",
                          left:
                            getitemsforCard()?.length === 3 ? "80px" : "0px",
                        }}
                      >
                        <div className="carousel">
                          <div
                            className="carousel-inner"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Stack
                              direction="horizontal"
                              className="h-100 justify-content-center align-items-center"
                              style={{ position: "relative" }}
                              gap={1}
                            >
                              {getItemsForCurrentIndex()?.map(
                                (appointment: any) => (
                                  <Card
                                    key={appointment.id}
                                    onClick={() => {
                                      setMemId(appointment?.id);
                                      setShowMemberCalender(true);
                                    }}
                                    className={styles.appointmentCard}
                                    style={{
                                      width: "12rem",
                                      minWidth: "10rem",
                                      height: "10rem",
                                      position: "relative",
                                      left:
                                        getitemsforCard()?.length === 1
                                          ? "1.5rem"
                                          : getitemsforCard()?.length === 2
                                            ? "10px"
                                            : "-30px",
                                      cursor: "pointer",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    <CardBody>
                                      <CardText
                                        style={{ fontSize: "13px" }}
                                        className="custom-card-title"
                                      >
                                        <Avatar
                                          fgColor="#fff"
                                          name={appointment?.name}
                                          round
                                          size="25"
                                          textSizeRatio={3.5}
                                        />
                                        &nbsp;{" "}
                                        <>{appointment.name.substring(0, 15)}</>
                                      </CardText>
                                      <CardText>
                                        <TooltipReference
                                          placement="left"
                                          tooltipId={`email${appointment?.id}`}
                                          content={appointment.email}
                                        >
                                          <span style={{ fontSize: "10px" }}>
                                            {appointment.email.length <= 17
                                              ? appointment.email
                                              : appointment.email.substring(
                                                0,
                                                17
                                              ) +
                                              (appointment.email.length > 17
                                                ? "..."
                                                : "")}
                                          </span>
                                        </TooltipReference>
                                      </CardText>
                                      <Button
                                        className="getStartedBtn"
                                        onClick={() => {
                                          setMemId(appointment?.id);
                                          setShowMemberCalender(true);
                                        }}
                                      >
                                        Get Started
                                      </Button>
                                    </CardBody>
                                  </Card>
                                )
                              )}
                            </Stack>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
                <div>
                  {getAllMemberConfigration?.data.length > 3 &&
                    getitemsforCard()?.length > 1 && (
                      <Button
                        style={{
                          backgroundColor: "#56B29C",
                          color: "white",
                          position: "relative",
                          height: "30px",
                          width: "30px",
                          // left:
                          //   totalLength == 3 || totalLength === 2
                          //     ? "765px"
                          //     : "980px",
                          // // top: "-100px",
                        }}
                        size="sm"
                        onClick={next}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    )}
                </div>
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }

  // let appointmentList = (
  //   <div className={styles.datatable}>
  //     <BootstrapTable
  //       data={memberAppointmentsQuery.data as any}
  //       search={false}
  //       pagination
  //       version='4'
  //       striped
  //       hover
  //       containerStyle={{ textAlign: 'left', marginBottom: 80 }}
  //       options={{
  //         headerAlign: 'center',
  //         noDataText: (
  //           <div className='my-4'>
  //             <NoDataFallback message='No Appointments Found' />
  //           </div>
  //         ),
  //         sortIndicator: true,
  //         hidePageListOnlyOnePage: true,
  //         clearSearch: false,
  //         alwaysShowAllBtns: true,
  //         withFirstAndLast: true,
  //         sizePerPage: 10,
  //         paginationPosition: 'bottom',
  //       } as any}
  //     >
  //       <TableHeaderColumn dataField='id' isKey hidden>
  //         #
  //       </TableHeaderColumn>
  //       <TableHeaderColumn
  //         dataAlign="center"
  //         dataFormat={nameData}
  //       >
  //         Name
  //       </TableHeaderColumn>
  //       <TableHeaderColumn
  //         dataAlign="center"
  //         dataFormat={(_, val) => moment(val.scheduledAt.date).format('Do MMM YYYY')}
  //       >
  //         Scheduled Date
  //       </TableHeaderColumn>
  //       <TableHeaderColumn
  //         dataAlign="center"
  //         dataField='scheduledAt'
  //         dataFormat={val => (
  //           <>
  //             {moment(val.startTime, 'HH:mm:ss').format('h:mm a')}
  //             {' '}to {' '}
  //             {moment(val.endTime, 'HH:mm:ss').format('h:mm a')}
  //           </>
  //         )}
  //       >
  //         Timeslot
  //       </TableHeaderColumn>
  //       <TableHeaderColumn
  //         dataAlign='center'
  //         dataField='contact'
  //         dataFormat={val => val ? (
  //           <>
  //             <Avatar fgColor="#fff" name={val.name} round size='25' textSizeRatio={2.5} />
  //             <span className='ml-1'>{val.name}</span>
  //           </>
  //         ) : <span className='text-muted'>(No Contact)</span>}
  //       >
  //         Contact
  //       </TableHeaderColumn>
  //       <TableHeaderColumn
  //         dataAlign="center"
  //         dataFormat={(_, val) => (
  //           <h5>
  //             {val.status === 'PENDING' ? (
  //               <Badge color='warning'>Pending</Badge>
  //             ) : val.status === 'COMPLETED' ? (
  //               <Badge color='success'>Completed</Badge>
  //             ) : val.status === 'CANCELLED' ? (
  //               <Badge color='danger'>Cancelled</Badge>
  //             ) : (
  //               <Badge>{val.status}</Badge>
  //             )}
  //           </h5>
  //         )}
  //       >
  //         Status
  //       </TableHeaderColumn>
  //       <TableHeaderColumn
  //         dataAlign="center"
  //         dataFormat={(_, appointment) => (
  //           <div>
  //             <Button
  //               id={'edit-appointment-' + appointment.id}
  //               color='white'
  //               onClick={() => setShowEditAppointment(appointment)}
  //             >
  //               <FontAwesomeIcon icon={faEdit} />
  //             </Button>
  //             <UncontrolledTooltip
  //               placeholder='bottom'
  //               target={'edit-appointment-' + appointment.id}
  //             >
  //               Edit Appointment
  //             </UncontrolledTooltip>

  //             {appointment.status !== 'COMPLETED' && appointment.status !== 'CANCELLED' && appointment.status !== 'CLOSE' ? (
  //               <>
  //                 <Button
  //                   id={'reschedule-' + appointment.id}
  //                   color='white'
  //                   onClick={() => setShowReschedule(appointment)}
  //                 >
  //                   <FontAwesomeIcon icon={faClockRotateLeft} />
  //                 </Button>
  //                 <UncontrolledTooltip
  //                   placeholder='bottom'
  //                   target={'reschedule-' + appointment.id}
  //                 >
  //                   Reschedule
  //                 </UncontrolledTooltip>
  //               </>
  //             ) : null}

  //             <Button
  //               id={'delete-appointment-' + appointment.id}
  //               color='white'
  //               className='text-danger'
  //               onClick={() => deleteAppoinmentHandler(appointment)}
  //             >
  //               <FontAwesomeIcon icon={faTrashAlt} />
  //             </Button>
  //             <UncontrolledTooltip
  //               placeholder='bottom'
  //               target={'delete-appointment-' + appointment.id}
  //             >
  //               Delete Appointment
  //             </UncontrolledTooltip>
  //           </div>
  //         )}
  //       >
  //         Action
  //       </TableHeaderColumn>
  //     </BootstrapTable>
  //   </div>
  // )
  let appointmentList = (
    <div className={styles.datatable}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* Left: Selected */}
        <div>
          Selected:{" "}
          <span style={{ color: "#007EC3" }}>
            {/* testing */}
            {selectedCount} {/* production */}
            of {count} Appointments{" "}
          </span>
        </div>

        {selectedRows?.length > 0 && (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant=""
              type="button"
              className="sendButton p-2 mb-2"
              style={{ minWidth: "120px" }}
              onClick={clearSelection}
            >
              Clear
            </Button>
            <Button
              variant=""
              type="button"
              className="sendButton p-2 mb-2"
              style={{ minWidth: "120px" }}
              onClick={updateBulkStatus}
            >
              Update Status
            </Button>
            <Button
              variant=""
              type="button"
              className="sendButton p-2 mb-2"
              style={{ minWidth: "120px" }}
              onClick={handleDeleteSelected}
            >
              <FontAwesomeIcon icon={faTrashCan} className="me-1" />
              Delete ({selectedRows?.length})
            </Button>
          </div>)}
      </div>

      {updateStatus && (
        <>
          <Modall
            isOpen={updateStatus}
            onClose={() => { setUpdateStatus(false) }}
            title={"Update Bulk Status"}
            size="md"
            component='ADDTAG_CONTACT'
          >
            <>
              <FormGroup className="mt-3 d-flex flex-column align-items-center">
                <Label>Select Status</Label>
                <Select
                  placeholder="Select Status"
                  options={statusOptions}
                  value={selectedStatus}
                  onChange={(selected: any) => {
                    setSelectedStatus(selected);
                  }}
                  styles={{ container: (provided) => ({ ...provided, width: '300px' }) }}
                />
                <Button
                  type="submit"
                  className="sendButton mt-3"
                  onClick={submitStatus}
                >
                  Save
                </Button>
              </FormGroup>
            </>
          </Modall>
        </>
      )}

      <BizTable
        columns={columns}
        fetchData={fetchData}
        counts={count}
        refetchData={memberAppointmentsQuery}
        tableName="APPOINTMENTS"
        isScrolling={setOpen}
        isClear={isClearSelected}
      />
    </div>
  );

  if (calenderView && memberAppointmentsQuery) {
    appointmentList = (
      <CalenderView
        appoinments={memberAppointmentsQuery}
        setEdit={(appointment) => setShowEditAppointment(appointment)}
      />
    );
  }

  return (
    <>
      {/* {memberCalenderQuery?.isLoading || memberAppointmentsQuery?.isLoading  ? (
        <Loader />
      ) : null} */}
      <FullScreenPopover
        open={showCreateCalendar}
        onClose={() => setShowCreateCalendar(false)}
        caption="Set up My Appointment Calendar"
      >
        <MemberCalenderForm
          create
          memId={memId}
          onSubmit={() => {
            memberCalenderQuery.refetch();
            if (
              holidayQuery.isFetched &&
              memberCalenderQuery.data.holidayList > 0
            )
              holidayQuery.refetch();
          }}
          onCancel={() => setShowCreateCalendar(false)}
          isInvalidAccess={isInvalidAccess}
        />
      </FullScreenPopover>
      <FullScreenPopover
        open={showMemberCalender}
        onClose={() => setShowMemberCalender(false)}
        caption="Set up My Appointment Calendar"
      >
        <MemberCalenderForm
          create
          memId={memId}
          onSubmit={() => {
            getAllMemberConfigration.refetch();

            if (
              holidayQuery.isFetched &&
              memberCalenderQuery.data.holidayList > 0
            )
              holidayQuery.refetch();
          }}
          onCancel={() => setShowMemberCalender(false)}
          isInvalidAccess={isInvalidAccess}
        />
      </FullScreenPopover>
      <FullScreenPopover
        open={showEditCalendar}
        onClose={() => setShowEditCalendar(false)}
        caption="Edit My Appointment Calendar"
      >
        <MemberCalenderForm
          data={memberCalenderQuery.data}
          memUid={memberRef.current}
          onSubmit={() => memberCalenderQuery.refetch()}
          onCancel={() => setShowEditCalendar(false)}
          isInvalidAccess={isInvalidAccess}
        />
      </FullScreenPopover>
      <FullScreenPopover
        open={showEditMemberCalender}
        onClose={() => setShowEditMemberCalender(false)}
        caption={`Edit My Appointment Calendar (${getData ? getData?.memberName : ""
          })`}
      >
        <MemberCalenderForm
          data={getData ? getData : {}}
          memUid={memberRef.current}
          onSubmit={() => {
            getAllMemberConfigration.refetch();
            memberCalenderQuery.refetch();

            holidayQuery.refetch();
          }}
          onCancel={() => setShowEditMemberCalender(false)}
          isInvalidAccess={isInvalidAccess}
        />
      </FullScreenPopover>
      {showCreateAppointment && memberCalenderQuery.isFetched ? (
        <RightSidebar
          handleCancel={() => setShowCreateAppointment(false)}
          title="Create Appointment"
          width="30%"
        >
          <AppointmentForm
            create
            memId={memId}
            memUId={memUid}
            onSubmit={() => {
              // memberAppointmentsQuery.refetch()
              fetchData(0, 20, "", 0);
              if (calenderView) {
                setCalenderView(false);
              }
            }}
            onCancel={() => setShowCreateAppointment(false)}
            calendar={role === "OWNER" ? getData : memberCalenderQuery.data}
            holidayList={holidayQuery.data}
            isInvalidAccess={isInvalidAccess}
          />
        </RightSidebar>
      ) : null}
      {Boolean(showEditAppointment) && memberCalenderQuery.isFetched ? (
        <RightSidebar
          handleCancel={() => setShowEditAppointment(null)}
          title="Edit Appointment"
          width="30%"
        >
          <AppointmentForm
            memId={memId}
            memUId={memUid}
            data={showEditAppointment}
            onSubmit={
              () => fetchData(0, 20, "", 0)
              // memberAppointmentsQuery.refetch()
            }
            onCancel={() => setShowEditAppointment(null)}
            calendar={role === "OWNER" ? getData : memberCalenderQuery.data}
            holidayList={holidayQuery.data}
            isInvalidAccess={isInvalidAccess}
          />
        </RightSidebar>
      ) : null}
      {Boolean(showReschedule) && memberCalenderQuery.isFetched ? (
        <RightSidebar
          handleCancel={() => setShowReschedule(null)}
          title="Reschedule Appointment"
          width="30%"
        >
          <RescheduleForm
            data={showReschedule}
            onSubmit={
              () => fetchData(0, 20, "", 0)
              // memberAppointmentsQuery.refetch()
            }
            onCancel={() => setShowReschedule(null)}
            calendar={role === "OWNER" ? getData : memberCalenderQuery.data}
            holidayList={holidayQuery.data}
          />
        </RightSidebar>
      ) : null}
      <Container>
        {userCalendar}
        <div className={styles.appointments}>
          <div className={styles.header}>
            {!calenderView && role === "OWNER" ? (
              <h5 className="col-md-3">
                Scheduled Appointments <br />
                {memName?.length > 0 && <>({memName}) </>}
              </h5>
            ) : (
              <div></div>
            )}
            {!calenderView && role !== "OWNER" ? (
              <h3>My Appointments</h3>
            ) : (
              <div></div>
            )}

            {!calenderView && (
              <div className="col-md-3" id={styles.searchAppointment}>
                <SearchInput
                  component="company"
                  onSearchChange={onSearchChange}
                  placeHolder="Search Appointments"
                />
              </div>
            )}
            {!calenderView && (
              <>
                <img
                  src={excel}
                  alt=""
                  className="me-3"
                  onClick={handleDownload}
                  style={{ cursor: "pointer" }}
                  title="Download excel"
                  width={30}
                  height={30}
                />
                <Button
                  title="Sort by Date"
                  style={{
                    backgroundColor:
                      filter.current === "date" || filter.current === "dateDESC"
                        ? "#458D80"
                        : "",
                  }}
                  color="link"
                  active={
                    filter.current === "date" || filter.current === "dateDESC"
                  }
                  onClick={() =>
                    filter.current === "date"
                      ? handleFilterChange("dateDESC")
                      : handleFilterChange("date")
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      filter.current === "dateDESC"
                        ? faSortNumericUp
                        : faSortNumericDown
                    }
                    style={{
                      color:
                        filter.current === "date" ||
                          filter.current === "dateDESC"
                          ? "#FFFFFF"
                          : "#56B29C",
                      fontSize: "20px",
                    }}
                  />
                </Button>
                <Button
                  title="Sort by Name"
                  style={{
                    backgroundColor:
                      filter.current === "name" || filter.current === "nameDESC"
                        ? "#458D80"
                        : "",
                  }}
                  className="me-2"
                  color="link"
                  active={
                    filter.current === "name" || filter.current === "nameDESC"
                  }
                  onClick={() =>
                    filter.current === "name"
                      ? handleFilterChange("nameDESC")
                      : handleFilterChange("name")
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      filter.current === "nameDESC"
                        ? faSortAlphaUp
                        : faSortAlphaDown
                    }
                    style={{
                      color:
                        filter.current === "name" ||
                          filter.current === "nameDESC"
                          ? "#FFFFFF"
                          : "#56B29C",
                      fontSize: "20px",
                    }}
                  />
                </Button>
              </>
            )}
            <div className={styles.actions}>
              <ButtonGroup className={styles.calendarSwitch}>
                <Button
                  color="link"
                  active={calenderView}
                  onClick={() => setCalenderView(true)}
                >
                  <FontAwesomeIcon icon={faTable} />
                </Button>
                <Button
                  color="link"
                  active={!calenderView}
                  onClick={() => setCalenderView(false)}
                >
                  <FontAwesomeIcon icon={faList} />
                </Button>
              </ButtonGroup>
              {memberCalenderQuery.isFetched &&
                !memberCalenderQuery.isError &&
                viewdata ? (
                isInvalidAccess ? (
                  <OverlayTrigger
                    overlay={<Tool>You don't have permission</Tool>}
                    placement="bottom"
                  >
                    <div className="notAllowed">
                      <Button
                        disabled={isInvalidAccess}
                        className="mr-2 signUpBtn mb-1 col-md-auto"
                        onClick={() => {
                          holidayQuery.refetch();
                          setShowCreateAppointment(true);
                        }}
                      >
                        Book Appointment
                      </Button>
                    </div>
                  </ OverlayTrigger>) : (
                  <Button className="mr-2 signUpBtn mb-1 col-md-8"
                    onClick={() => {
                      holidayQuery.refetch();
                      setShowCreateAppointment(true);
                    }}>Book Appointment</Button>
                )
              ) : null}
              <SyncButton
                onClick={
                  () => {
                    fetchData(0, 20, "", 0, true);
                    setTimeout(() => {
                      toast("success", "Appointment synced successfully");
                    }, 1000);

                    setSync(true);
                  }
                  // memberAppointmentsQuery.refetch()
                }
              />
            </div>
          </div>
          {memberAppointmentsQuery?.data?.length !== 0 && memName?.length > 0 ? (
            appointmentList
          ) : (
            <div style={{ width: "90vw" }}>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <div>
                  <img src={noData} alt="nodata" width={200} height={200} />
                  <p className="text-center h6 text-muted">No Data Found</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default Appointments;
