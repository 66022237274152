import React, {useCallback, useState} from 'react'
import BizTable from '../../../common/BizTable'
import { getIntegrationLogs } from '../../../services/integrationService'
import moment from "moment";
import TooltipReference from "../../../common/tooltip";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {
    connectionUid:string;
}

const dateFormatter = (createdAt: any) => {
  return moment(createdAt).format("DD MMM YYYY, hh:mm A"); // Includes time in 12-hour format with AM/PM
};

const LogsTable = (props: Props) => {
    const{connectionUid} = props;
    const [LogsData,setLogsData] = useState<any>([])
    const [count,setCount] = useState(0)
    const columns = [
        {
            Header: (
              <input
              type="checkbox"
              hidden={true}
            />
            ),
            accessor: "checkbox",
            sticky: "left",
          },
        {
          Header: "Date",
          accessor: "createdAt",
          Cell: ({ cell, row }: any) => dateFormatter(row?.original?.logs?.createdAt),
        },
        {
          Header: "WorkFlow Name",
          accessor: "workFlow",
          Cell: ({ cell, row }: any) => row?.original?.logs?.workFlow,
        },
        {
          Header: "Customer Phone",
          accessor: "mobileNumber",
          Cell: ({ cell, row }: any) => row?.original?.logs?.mobileNumber ?? <div className="text-center">-</div>,
        },
        {
            Header: "Customer Name",
            accessor:"customerName",
            Cell: ({ cell, row }: any) => row?.original?.logs?.customerName ?? <div className="text-center">-</div>,

        },
        {
            Header: "Status",
            accessor:"status",
            Cell: ({ cell, row }: any) =><div className='d-flex justify-content-center align-items-center'> <div className={`badgeCon ${row?.original?.logs?.status?.toLowerCase()}`}>{row?.original?.logs?.status} 
            </div> <div className='ms-2'>{row?.original?.logs?.status === "ERROR" && 
                                  <TooltipReference
                                  placement="bottom"
                                  content={row?.original?.logs?.errorReason}
                                  tooltipId={row?.original?.id}
                                  icon={faInfoCircle}
                                ></TooltipReference>
            }</div></div>,
        
        }
      ] as any;
      
  const fetchData = useCallback(
    async (
      pageIndex: number,
      pageSize: number,
      searchText: string,
      currentPage: number
    ): Promise<{ data: any; totalPages: number }> => {
    
      const response = await getIntegrationLogs(connectionUid);
      if(response === 404){
        setCount(0);
      }
      if (response?.data) {
        const {data} =response
        const logs = data?.integrationLogs.flatMap((item: any) => item.logs);
        setLogsData(data?.integrationLogs);
        setCount(data.count)
        // searchTerm != null
        //   ? setContactCount(response.data.list.length)
        //   : setContactCount(response.data.count);

        return { data: data?.integrationLogs, totalPages: 0 };
      }
      else {
        return { data: [], totalPages: 0 };
      }
    },
    [connectionUid]
  );

  return (
    <div>
        <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={count}
              refetchData={LogsData}
              tableName="INTEGRATIONS"
            //   searchTerm={searchTerm ?? ''}
            // onChangeSelection={onChangeSelection}
            />
    </div>
  )
}

export default LogsTable