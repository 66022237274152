import axios from "axios";

const authAxiosInstance = axios.create({
     baseURL: window.location.hostname === "app.bizmagnets.ai" ? "https://api.bizmagnets.ai/api" : "https://biz.vamosys.com/api",
    // baseURL: window.location.hostname === "app.bizmagnets.ai" ? "https://api.bizmagnets.ai/api" : "http://biz.vamosys.com/api",
    //  baseURL: "http://183.83.189.151:9912/api",
    headers: {
        'Content-Type': 'application/json',
    }
});

export default authAxiosInstance;
