import React, { createContext, useContext, useState } from 'react';

interface DataContextProps {
    SequenceData: Record<string, any> | any;
    setIsSequenceData: React.Dispatch<React.SetStateAction<Record<string, any> | any>>;
    clearData: () => void;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const useSequenceData = () => {
    const context = useContext(DataContext);
    if (!context) throw new Error('useData must be used within a DataProvider');
    return context;
};

export const SequenceContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [SequenceData, setIsSequenceData] = useState<Record<string, any> | any>({});
    const clearData = () => setIsSequenceData({});

    return (
        <DataContext.Provider value={{ SequenceData, setIsSequenceData, clearData }}>
            {children}
        </DataContext.Provider>
    );
};