import { BsImages } from 'react-icons/bs';
import { MdOutlineMusicVideo, MdOutlineVideocam } from 'react-icons/md';
import { IoMdDocument } from "react-icons/io";

import Node from '../node';
import removeVariables from '../../../../utils/removeVariables';
import excelIcon from '../../../../../assets/img/excel.png';
import pdfIcon from '../../../../../assets/img/pdf.png';


function SendMedia(props: any) {
    const url = props.data.media ? props.data.media.url : removeVariables(props.data.mediaUrl).trim();
    console.log(props.data);

    return (
        <Node {...props} label='Send Media' content={(
            <div>
                <span className='mr-1'>
                    {props.data.mediaType === 'image' ? <BsImages /> : null}
                    {props.data.mediaType === 'video' ? <MdOutlineVideocam /> : null}
                    {props.data.mediaType === 'audio' ? <MdOutlineMusicVideo /> : null}
                    {props.data.mediaType === 'document' ? (
                        (
                            /\.(xls|xlsx)$/i.test(url) ||
                            props.data.media && (
                                props.data.media.mediaType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                props.data.media.mediaType === "application/vnd.ms-excel" ||
                                props.data.media.mediaType === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
                                props.data.media.mediaType === "application/vnd.ms-excel.sheet.binary.macroEnabled.12" ||
                                props.data.media.mediaType === "application/vnd.openxmlformats-officedocument.spreadsheetml.template" ||
                                props.data.media.mediaType === "application/vnd.ms-excel.template.macroEnabled.12"
                            )
                        ) ? (
                            <img src={excelIcon} alt='' style={{ width: 8 }} />
                        ) : (
                            /\.(pdf)$/i.test(url) ||
                            props.data.media && props.data.media.mediaType === "application/pdf"
                        ) ? (
                            <img src={pdfIcon} alt='' style={{ width: 8 }} />
                        ) : <IoMdDocument />
                    ) : null}
                </span>
                {props.data.media ? props.data.media.fileName : props.data.mediaType}
            </div>
        )} />
    )
}

export default SendMedia;