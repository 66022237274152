import React from 'react';
import { Row, Col, InputGroup, FormControl, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Field, FormikErrors, useFormikContext } from 'formik';
import { MdAdd } from 'react-icons/md';
import { PiMinusCircleFill } from 'react-icons/pi';

import styles from './optionsInput.module.css';
import { uuidv4 } from '../../../../utils/uuid';
import { Form } from 'react-router-dom';


export interface IOptionListItem {
    id: string | null;
    uid: string
    title: string;
    description: string;
}

export interface IListOption {
    id: string;
    optionTitle: string;
    order: number;
    list: IOptionListItem[];
}

interface Props {
    options: IListOption[];
    error: any; // Type: string | string[] | FormikErrors<IListOption>[] | undefined
    isInvalid: boolean;
    isValid: boolean;
    touched: any // Type: FormikTouched<IListOption>[] | undefined;
}

function isFormikErrorsOfIOption(error: any): error is FormikErrors<IListOption> {
    return error && typeof error.optionTitle === 'string';
}

const OptionsInput: React.FC<Props> = ({ options, touched, error }) => {
    const formik = useFormikContext();
    const handleOptionTextChange = (index: number, text: string) => {
        const updatedOptions = [...options];
        updatedOptions[index] = { ...updatedOptions[index], optionTitle: text };
        formik.setFieldValue('options', updatedOptions);
    };

    const handleDelete = (index: number) => {
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        formik.setFieldValue('options', updatedOptions);
    };

    const handleAddOption = () => {
        const id = uuidv4();
        const updatedOptions = [
            ...options, {
                id,
                order: options.length + 1,
                optionTitle: '',
                list: [{
                    title: '',
                    description: '',
                    id: null,
                    uid: uuidv4()
                }]
            }
        ];
        formik.setFieldValue('options', updatedOptions);
    };

    const handleAddList = (id: string) => {
        const updatedOptions: IListOption[] = JSON.parse(JSON.stringify(options));
        const index = updatedOptions.findIndex(option => option.id === id);
        if (index >= 0) {
            updatedOptions[index].list.push({
                title: '',
                description: '',
                uid: uuidv4(),
                id: null
            });
        }
        formik.setFieldValue('options', updatedOptions);
    }

    const setListItem = (id: string, index: number, value: string, key: 'title' | 'description') => {
        const updatedOptions: IListOption[] = JSON.parse(JSON.stringify(options));
        const arrayIndex = updatedOptions.findIndex(option => option.id === id);
        if (index >= 0) {
            updatedOptions[arrayIndex].list[index][key] = value;
        }
        formik.setFieldValue('options', updatedOptions);

    }

    const handleDeleteItem = (id: string, index: number) => {
        const updatedOptions: IListOption[] = JSON.parse(JSON.stringify(options));
        const arrayIndex = updatedOptions.findIndex(option => option.id === id);
        if (index >= 0) {
            updatedOptions[arrayIndex].list.splice(index, 1);
        }
        formik.setFieldValue('options', updatedOptions);
    }

    const MAX_OPTIONS = 10;

    function getItemsCount(): number {
        return options.reduce((accumulator, option) => {
            return accumulator + option.list.length;
        }, 0);
    }

    return (
        <div>
            {options.map((question, parentIndex) => {
                const isError: boolean = (error && isFormikErrorsOfIOption(error[parentIndex]) && error[parentIndex].optionTitle) ? true : false;
                const isTouched: boolean = (touched && touched[parentIndex] && touched[parentIndex].optionTitle) ? true : false;
                return (
                    <div className={styles.optionBox} key={question.id} >
                        <Row className={styles.questionRow}>
                            <Col xs={1}>
                                <Button disabled={options.length < 2 && parentIndex < 1} variant='default' size='sm' className='minusRemoveBtn' onClick={() => handleDelete(parentIndex)}>
                                    <PiMinusCircleFill />
                                </Button>
                            </Col>
                            <Col xs={10} className={styles.idCol}>
                                <InputGroup>
                                    <FormControl
                                        placeholder='Enter your option title'
                                        name={`options[${parentIndex}].optionTitle`}
                                        value={question.optionTitle}
                                        isInvalid={isError && isTouched && options?.length > 1}
                                        isValid={!isError && isTouched}
                                        onChange={(e) => handleOptionTextChange(parentIndex, e.target.value)}
                                        maxLength={24}
                                    />

                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        bottom: -20,
                                        fontSize: '.8rem',
                                        fontWeight: 400,
                                        color: '#A3A3A3',
                                        padding: '1px 3px'
                                    }}>
                                        {question.optionTitle.length}/24
                                    </div>

                                    {(error && isError && isTouched && options?.length > 1) && (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {error[parentIndex].optionTitle}
                                        </div>
                                    )}
                                </InputGroup>
                            </Col>
                        </Row>
                        {question.list.map((listItem, index) => {
                            const isListItemError = error && error[parentIndex] && error[parentIndex].list && error[parentIndex].list[index];
                            const isListItemTouched = touched && touched[parentIndex] && touched[parentIndex].list && touched[parentIndex].list[index];
                            const isTitleError: boolean = (isListItemError && error[parentIndex].list[index].title) ? true : false;
                            const isTitleTouched: boolean = (isListItemTouched && touched[parentIndex].list[index].title) ? true : false;
                            const isDescError: boolean = (isListItemError && error[parentIndex].list[index].description) ? true : false;
                            const isDescTouched: boolean = (isListItemTouched && touched[parentIndex].list[index].description) ? true : false;
                            return (
                                <div key={listItem.uid} style={{ marginTop: '5px' }}>
                                    <Row className={[styles.questionRow, "ml-3 mt-2"].join(" ")}>
                                        <Col xs={1}>
                                            <Button
                                                disabled={question.list.length < 2 && index < 1}
                                                variant='default' size='sm'
                                                className='minusRemoveBtn'
                                                onClick={() => handleDeleteItem(question.id, index)}
                                            >
                                                <PiMinusCircleFill />
                                            </Button>
                                        </Col>
                                        <Col>
                                            <div className={styles.idCol}>
                                                List Item #{index + 1} <span className="required"></span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={1}></Col>
                                        <Col>
                                            <div className={styles.fieldWrapper}>
                                                <FormControl
                                                    className={styles.text}
                                                    as='input'
                                                    placeholder='Enter the text'
                                                    value={listItem.title}
                                                    name={`options[${parentIndex}].list[${index}].title`}
                                                    onChange={(e) => setListItem(question.id, index, e.target.value, 'title')}
                                                    style={{ marginBottom: '5px' }}
                                                    isInvalid={isTitleError && isTitleTouched}
                                                    isValid={!isTitleError && isTitleTouched}
                                                    maxLength={24}
                                                />
                                                {(error && isTitleError && isTitleTouched) && (
                                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                                        {error[parentIndex].list[index].title}
                                                    </div>
                                                )}
                                                <div className={styles.description}>
                                                    {listItem.title.length}/24
                                                </div>
                                            </div>

                                            <div className={styles.fieldWrapper}>
                                                <FormControl
                                                    className={styles.text}
                                                    as='input'
                                                    placeholder='Enter the description'
                                                    name={`options[${parentIndex}].list[${index}].description`}
                                                    value={listItem.description}
                                                    onChange={(e) => setListItem(question.id, index, e.target.value, 'description')}
                                                    isInvalid={isDescError && isDescTouched}
                                                    isValid={!isDescError && isDescTouched}
                                                    maxLength={72}
                                                />
                                                {(error && isDescError && isDescTouched) && (
                                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                                        {error[parentIndex].list[index].description}
                                                    </div>
                                                )}
                                                <div className={styles.description}>
                                                    {listItem.description.length}/72
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>
                            );
                        })}
                        {getItemsCount() < MAX_OPTIONS ? (
                            <Button className="ml-4 mt-2 sendButton" size='sm' onClick={() => handleAddList(question.id)}>+ Add list item</Button>
                        ) : null}
                    </div>
                );
            })}
            {getItemsCount() < MAX_OPTIONS ? (
                <OverlayTrigger
                    overlay={<Tooltip>Add New Option</Tooltip>}
                    placement="bottom"
                >
                    <Button onClick={handleAddOption} size='sm' className='addButton ml-2'><MdAdd /></Button>
                </OverlayTrigger>
            ) : null}
            {(touched && error && typeof error === 'string') ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {error}
                </div>
            ) : null}
        </div>
    );
};

export default OptionsInput;
