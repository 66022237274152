import React, { useEffect, useMemo, useState } from 'react';
import { IEditorProps } from '../types';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList';
import { IIntegrationApp } from '../../../../entity/integration/integrationApp';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { shopifyIcon } from '../../../../icons';
import { Button, Offcanvas, Spinner, Form, FormControl, Accordion, InputGroup, FormCheck } from 'react-bootstrap';
import IntegrationConnection from './integrationConnection';
import ActionsField from '../../../../entity/integration/actionsField';
import ReactSelect from 'react-select';
import { Label } from 'reactstrap';
import SelectIntegrationAction from './selectIntegrationAction';
import { CreateSelectVariable } from './createSelectVariable';
import FlagInput from '../../../../../common/flagInput';
import store from '../../../../../redux/store/store';
import { Provider } from 'react-redux';
import classes from './shopify.module.scss';
import AddVariable from '../addVariable';
import axios from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';
import bg from '../catalogue/sendProducts.module.scss';
import { SelectVariable } from './selectVariable';


type Payload = Record<string, string> | null
type QueryParam = {
    uid: string,
    field: string,
    condition: 'equals' | 'starts_with',
    value: string,
}
type QueryParamKey = 'field' | 'condition' | 'value';

type Variables = {
    label: string,
    name: string,
    type: 'TEXT'
}

interface FormData {
    integrationData: {
        app: IIntegrationApp,
        scope: 'COM' | 'IN'
        connectionId: number | null
        action: IIntegrationActionListId | null
        payload: Payload,
        query: QueryParam[],
        recordId: string,
        name: string,
        phone: string,
        financialStatus: string,
        orderId: string,
        tags: string,
        draftOrderId: string,
        paymentPending: boolean,
        shopUrl: string,
        optionalFields: {
            [tags: string]: string | null;
        },
        successTargetId: string,
        failTargetId: string
    },
    variables: Variables[],
    storageVariable: {
        name: string
    } | null,
    isSameFlow: boolean
}



function Shopify(props: IEditorProps) {

    const [isCreateScreen, setIsCreateScreen] = useState(false);
    const [isEditScreen, setEditScreen] = useState(true);
    const [isOrderBased, setIsOrderBased] = useState(true);
    const [formData, setFormData] = useState<FormData>({
        integrationData: {
            app: 'Shopify',
            scope: 'COM',
            connectionId: null,
            action: null,
            payload: null,
            query: [],
            recordId: '',
            name: '',
            phone: '',
            financialStatus: '',
            orderId: '',
            tags: '',
            draftOrderId: '',
            paymentPending: false,
            shopUrl: "",
            optionalFields: { tags: '' },
            successTargetId: '',
            failTargetId: ''
        },
        variables: [],
        storageVariable: { name: '' },
        isSameFlow: false
    })
    const actionClenup = () => {
        (window as any).actionFields = null;
        (window as any).fieldsInit = false;
        (window as any).selectedModule = null;
        (window as any).selectedModuleId = null;
        // zohoModuleFieldsQuery.remove()
    }
    useEffect(() => {
        init(setFormData)
        return actionClenup
    }, [])

    const { init, saveElementChanges } = useElmentEditor({
        type: 'integration/Shopify',
        data: formData
    }, props)

    const schema = yup.object().shape({
        integrationData: yup.object().shape({
            connectionId: yup.string().required('Connection is required'),
            action: yup.string().required('Action is required'),
            name: yup.string().when('action', {
                is: (action: string) => action === 'createDraftOrder',
                then: yup.string().required('Name is required'),
                otherwise: yup.string().nullable()
            }),
            phone: yup.string().when('action', {
                is: (action: string) => action === 'createDraftOrder',
                then: yup.string().required('Phone number is required'),
                otherwise: yup.string().nullable()
            }),
            financialStatus: yup.string().nullable(),
            orderId: yup.string().when('action', {
                is: 'replaceOrderTag',
                then: yup.string().required('Order ID is required'),
                otherwise: yup.string().nullable()
            }),
            tags: yup.string().when('action', {
                is: 'replaceOrderTag',
                then: yup.string().required('Tags is required'),
                otherwise: yup.string().nullable()
            }),
            draftOrderId: yup.string().when('action', {
                is: 'completeDraftOrder',
                then: yup.string().required('Draft Order ID is required'),
                otherwise: yup.string().nullable()
            }),
            paymentPending: yup.string().nullable(),
            shopUrl: yup.string(),
            optionalFields: yup.object().nullable()
        }),
        variables: yup.array().when('integrationData.action', {
            is: (action: string) => action === 'createDraftOrder',
            then: yup.array().of(
                yup.object().shape({
                    label: yup.string(),
                    name: yup.string().required('Please create or select variable'),
                })
            ),
            otherwise: yup.array().nullable()
        }),
        storageVariable: yup.object().when('integrationData.action', {
            is: (action: string) => action === 'completeDraftOrder',
            then: yup.object().shape({
                name: yup.string().required('Please create or select variable')
            }),
            otherwise: yup.object().nullable()
        })
    });

    const { flowId } = useParams<{ flowId: string }>();

    const loadFlow = async (flowId: string) => {
        try {
            const response = await axios.get(`bot/flow/${flowId}/getElements`);
            const order = response.data.flowType === 'order';
            setIsOrderBased(order);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    useEffect(() => {
        if (flowId) {
            loadFlow(flowId);
        }
    }, [flowId])

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >

            {({ handleSubmit, setFieldValue, values, errors, touched, setValues, handleChange }) => {

                const [code, setCode] = useState<string>('91');
                const handleSave = () => {
                    if (values?.storageVariable?.name === "" && values?.integrationData?.action !== "completeDraftOrder") {
                        setFieldValue('storageVariable', null);
                    }
                    const phone = values?.integrationData?.phone?.trim();
                    if (phone && phone.length > 0 && phone[0] !== '{' && Object.keys(errors)?.length === 0) {
                        const validCode = code[0] === "+" ? code.substring(1,code.length) : code;
                        setFieldValue('integrationData.phone', validCode + values.integrationData.phone);
                    }
                    setFieldValue('integrationData.shopUrl', "https://62f6d6-ea.myshopify.com");
                }

                useEffect(() => {
                    if (values.variables?.length === 0 && values.integrationData.action === "createDraftOrder") {
                        setFieldValue('variables', [{ name: '' }, { name: '' }])
                    }
                }, [values.integrationData.action])

                useEffect(() => {
                    setValues(formData)
                    if (formData?.integrationData?.phone?.length > 10 && formData?.integrationData?.phone?.trim()[0] !== '{') {
                        const codeLength = formData?.integrationData?.phone?.length - 10;
                        setFieldValue('integrationData.phone', formData.integrationData.phone.substring(codeLength, formData?.integrationData?.phone?.length));
                        const code = formData?.integrationData?.phone?.substring(0,codeLength);
                        setCode(code);
                    }
                }, [formData, setValues])

                const onGoBackHandler = () => {
                    if (values.integrationData.action) {
                        setFieldValue('integrationData', {
                            app: 'Shopify',
                            scope: 'COM',
                            connectionId: values?.integrationData?.connectionId,
                            action: null,
                            payload: null,
                            query: [],
                            recordId: '',
                            name: '',
                            phone: '',
                            financialStatus: '',
                            orderId: '',
                            tags: '',
                            shopUrl: values?.integrationData?.shopUrl
                        })
                        actionClenup()
                    } else if (values.integrationData.connectionId) {
                        setFieldValue('integrationData.connectionId', null)
                    } else {
                        props.onClose()
                    }
                }

                const myAction: ActionsListItem | null = useMemo(() => {
                    if (values.integrationData.connectionId) {
                        (window as any).appConnectionId = values.integrationData.connectionId
                    }
                    if ('action' in values.integrationData && values.integrationData.action) {
                        return ACTION_LIST['Shopify'].find(action => action.id === values.integrationData.action) || null
                    }
                    return null
                }, [values])

                const mapFieldsHandler = (field: ActionsField) => {

                    const isError = Boolean(errors.integrationData
                        && field.id in (errors.integrationData as any)
                        && Boolean((errors.integrationData as any)[field.id]))
                    const isTouched = Boolean(touched.integrationData
                        && field.id in (touched.integrationData as any)
                        && Boolean((touched.integrationData as any)[field.id]))

                    //   field.id === "draftOrder_id" ? setFieldValue('variables', [ {name:"", label:"", type:"TEXT"}]) : setFieldValue('variables', []);
                    return (
                        <>
                            {field.id === "financialStatus" || field.id === "paymentPending" ? (<>
                                <Form.Group className='mb-3'>
                                    <Form.Label>{field.label}{field.required === true && field.id !== 'financialStatus' && <span className='required'></span>}</Form.Label>
                                    <ReactSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                                            menu: base => ({ ...base, width: 370 })
                                        }}
                                        options={field.dropdownOption ?? []}
                                        className="w-100"
                                        placeholder="Select Status"
                                        onChange={(selected: any) => {
                                            field.id === "financialStatus" ? setFieldValue(`integrationData.${field.id}`, selected.value) :
                                                setFieldValue(`integrationData.${field.id}`, selected.value)
                                        }}
                                        value={field.dropdownOption?.find((item: any) => item.value === (values.integrationData as any)[field.id])}
                                        maxMenuHeight={120}
                                    />
                                </Form.Group>
                            </>) : (field.id === "draftOrder_id" || field.id === "draftOrder_invoiceUrl" ? (<>
                                <Form.Group className='mb-3'>
                                    <CreateSelectVariable
                                        placeholder={field.placeholder ?? ''}
                                        name={String('variables[' + field.index + ']')}
                                        value={values?.variables[field.index ?? 0]?.name}
                                        onChange={handleChange}
                                        type='TEXT'
                                        label={field.id}
                                        header={field.label}
                                        required={true}
                                        selectedValues={values?.variables}
                                    />
                                    <ErrorMessage name={String('variables[' + field.index + '].name')} component="div" className='invalid-feedback d-block' />
                                </Form.Group>
                            </>) : field.id === 'completeOrderId' ? (<>
                                <Form.Group className='mb-3'>
                                    <CreateSelectVariable
                                        placeholder={field.placeholder ?? ''}
                                        name='storageVariable'
                                        value={values?.storageVariable?.name ?? ''}
                                        onChange={handleChange}
                                        type='TEXT'
                                        label={null}
                                        header={field.label}
                                        required={true}
                                    />
                                    <ErrorMessage name='storageVariable.name' component="div" className='invalid-feedback d-block' />
                                </Form.Group>
                            </>) : (values.integrationData.action === 'createOrder' || values.integrationData.action === 'createDraftOrder') && field.required === false ? (<>
                                <Form.Group
                                    key={field.id}
                                    className='my-2 mb-3'
                                >
                                    <Label>{field.label}</Label>
                                    <FormControl
                                        name={`integrationData.optionalFields.${field.id}`}
                                        required={field.required}
                                        as='input'
                                        value={values.integrationData?.optionalFields?.[field?.id] || ''}
                                        onChange={handleChange}
                                        placeholder={field.placeholder || ''}
                                        type={(field.id==="discount_amount" || field.id === "discount_value") ? "number" : "text"}
                                    />
                                    <div className={classes.addVariable}>
                                        <AddVariable static product fieldName={`integrationData.optionalFields.${field.id}`} fieldValue={values.integrationData?.optionalFields?.[field?.id] || ''} limit={1024} />
                                    </div>
                                </Form.Group>
                            </>) : (values.integrationData.action === 'createOrder' || values.integrationData.action === 'createDraftOrder') && field.id === 'phone' ? (<>
                                <Form.Group
                                    key={field.id}
                                    className='my-2 mb-3'
                                >
                                    <Provider store={store}>
                                        <Label>{field.label}{field.required === true && <span className='required'></span>}</Label>
                                        <InputGroup>
                                            <InputGroup.Text className={classes.inputGroup}>
                                                <FlagInput countryCode={`+${code}`} codeChange={setCode} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                name={'integrationData.' + field.id}
                                                value={values.integrationData ? (values.integrationData as any)[field.id] : ''}
                                                isInvalid={isTouched && isError}
                                                isValid={isTouched && !isError}
                                                onChange={handleChange}
                                                placeholder={field?.placeholder ?? ''}
                                                required={field.required as boolean}
                                            />
                                        </InputGroup>
                                    </Provider>
                                    <ErrorMessage name={String('integrationData.' + field.id)} component="div" className='invalid-feedback d-block' />
                                    <div className={classes.addVariable}>
                                        <AddVariable static product fieldName={'integrationData.' + field.id} fieldValue={values.integrationData ? (values.integrationData as any)[field.id] : ''} limit={1024} />
                                    </div>
                                </Form.Group>
                            </>) :  field.id === 'orderId' || field.id === 'draftOrderId' ? (<Form.Group key={field.id} className='mb-3'>
                                    <SelectVariable
                                        placeholder={field.placeholder ?? ''}
                                        name={`integrationData.${field.id}`}
                                        value={(values.integrationData as any)[field.id]}
                                        onChange={handleChange}
                                        header={field.label}
                                        required={true}
                                    />
                                    <ErrorMessage name={String('integrationData.' + field.id)} component="div" className='invalid-feedback d-block' />
                            </Form.Group>) : (
                                <Form.Group
                                    key={field.id}
                                    className='my-2 mb-3'
                                >
                                    <Label>{field.label}{field.required === true && <span className='required'></span>}</Label>
                                    {values?.integrationData?.action === "createOrder" && <><br></br> <small>{`Hints: This is Whatsapp Catalogue Order`}</small></>}
                                    <FormControl
                                        name={'integrationData.' + field.id}
                                        required={field.required as boolean}
                                        as='input'
                                        value={values.integrationData && values?.integrationData?.action !== "createOrder" ? (values.integrationData as any)[field.id] : '{{intent.payload}}'}
                                        isInvalid={isTouched && isError}
                                        isValid={isTouched && !isError}
                                        onChange={handleChange}
                                        placeholder={field?.placeholder ?? ''}
                                        disabled={values?.integrationData?.action === "createOrder"}
                                    />
                                    {values?.integrationData?.action !== "createOrder" && (
                                        <div className={classes.addVariable}>
                                            <AddVariable static product fieldName={'integrationData.' + field.id} fieldValue={values.integrationData ? (values.integrationData as any)[field.id] : ''} limit={1024} singleValue={true}/>
                                        </div>
                                    )}
                                    <ErrorMessage name={String('integrationData.' + field.id)} component="div" className='invalid-feedback d-block' />
                                </Form.Group>))
                            }
                        </>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption
                            onHide={onGoBackHandler}
                            caption={
                                values?.integrationData?.connectionId ? (
                                    <>
                                        Shopify
                                        <small className="d-block mt-1">{values?.integrationData?.shopUrl}</small>
                                    </>
                                ) : (
                                    "Shopify"
                                )
                            }
                            icon={<img style={{ width: 28 }}
                                alt=''
                                src={shopifyIcon}
                            />}
                        />
                        <Offcanvas.Body>

                            {myAction ? <h5 className='mb-3'>{myAction.label}</h5> : null}
                            {values.integrationData.connectionId ? (
                                values.integrationData.action ? (
                                    <>
                                        {myAction ?
                                            <>
                                                {myAction.fields
                                                    .filter(field => field.required)
                                                    .map(mapFieldsHandler)}
                                                {myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 && (
                                                        <>
                                                            <FormCheck
                                                                name='isSameFlow'
                                                                type='switch'
                                                                id='fields-shopify-switch'
                                                                label="Optional Fields"
                                                                checked={values.isSameFlow}
                                                                onChange={event => {
                                                                    setFieldValue('isSameFlow', event.target.checked);
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {values?.isSameFlow && myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 ? (
                                                    <div className={bg.background}>
                                                        {myAction.fields
                                                            .filter(field => !field.required)
                                                            .map(mapFieldsHandler)}
                                                    </div>
                                                ) : null}
                                            </> : null}
                                    </>
                                ) : <SelectIntegrationAction app='Shopify' isOrder={isOrderBased} />) : (
                                <IntegrationConnection app='Shopify' scope="COM" onScreenChange={(screen: any) => { setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit'); }} isInvalidAccess = {props?.isInvalidAccess} />
                            )}

                        </Offcanvas.Body>

                        {(isEditScreen && !isCreateScreen) && values?.integrationData?.connectionId && (
                            <div className='editor-footer'>
                                <Button variant='outline-dark' onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button className='sendButton' type='submit' onClick={handleSave} disabled={isCreateScreen || props?.isInvalidAccess}>
                                    Save
                                </Button>
                            </div>)}
                    </Form>
                )
            }}
        </Formik >
    )
}

export default Shopify;