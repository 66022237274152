import React, { useState, useRef, useEffect, useContext } from "react";
import { Label, Row, Col, Button } from "reactstrap";
import "./uploadFiles.scss";
import { toast } from "./alert";
import ConversationFile from "../components/Conversations/conversationFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import NewLineInput from "./newlineInput";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { UploadContext } from "../contexts/uploadFileContext";
import TooltipReference from "./tooltip";
interface FileProps {
    mediaType: "image" | "video" | "doc";
    closeUploadModal: () => void;
}

const AttachFiles: React.FC<FileProps> = ({ mediaType, closeUploadModal }) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [imgCaption, setImgCaption] = useState("");
    const context = useContext(UploadContext);
    const [loadingFile, setLoadingFile] = useState(false);

    if (!context) {
        throw new Error("UploadContext must be used within an UploadProvider");
    }

    const { setFiles, setCaption } = context;

    const validMimeTypes = {
        image: ["image/jpeg", "image/png", "image/gif"],
        video: ["video/mp4", "video/avi", "video/mkv", "video/webm"],
        doc: [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
    };

    const handleFileUpload = (files: FileList) => {
        const fileArray = Array.from(files);

        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

        const invalidFiles = fileArray.filter(
            (file) => !validMimeTypes[mediaType].includes(file.type)
        );

        if (invalidFiles.length > 0) {
            toast(
                "error",
                "Invalid file type. Please upload files of the correct media type."
            );
            return;
        }

        const sizeLimit = mediaType === "image" ? 2 * 1024 * 1024 : mediaType === 'video' ? 16 * 1024 * 1024 : 100 * 1024 * 1024;

        const oversizedFiles = fileArray.filter((file) => file.size > sizeLimit);

        if (oversizedFiles.length > 0) {
            toast(
                "error",
                `Some files exceed the size limit of ${mediaType === "image" ? "2 MB" : mediaType === 'video' ? "16 MB" : "100 MB"
                }. Please upload smaller files.`
            );
            return;
        }

        if (uploadedFiles.length + fileArray.length > 5) {
            toast("error", "Maximum file upload size exceeded");
            return;
        }

        setUploadedFiles((prev) => [...prev, ...fileArray]);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        handleFileUpload(files);
    };

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            handleFileUpload(files);
        }
    };

    const handleAddFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            console.error("File input ref is not set.");
        }
    };

    const handleFileRemove = (index: number) => {
        setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const renderFilePreview = (file: File, index: number) => {
        const fileType = file.type.split("/")[0];
        const objectUrl = URL.createObjectURL(file);

        return (
            <div className="file-preview-wrapper">
                {fileType === "image" && (
                    <img
                        src={objectUrl}
                        alt={file.name}
                        className="uploadImage"
                    />
                )}
                {fileType === "video" && (
                    <video
                        src={objectUrl}
                        className="uploadImage"
                        controls
                    ></video>
                )}
                {mediaType === "doc" && (
                    <div className="uploadImage">
                        <div className="centerItems h-75 cursor-pointer">
                            <TooltipReference placement="top" tooltipId={`uploadFile-${index}`}
                                content={file.name}>
                                <FontAwesomeIcon
                                    icon={faFile}
                                    fontSize={24}
                                    onClick={() => window.open(objectUrl, "_blank")}
                                />
                            </TooltipReference>
                        </div>
                        <div className="uploadDocs">Doc</div>
                    </div>
                )}
                <FontAwesomeIcon
                    icon={faClose}
                    className="remove-icon"
                    onClick={() => handleFileRemove(index)}
                />
            </div>
        );
    };

    function convertFilesize(filesizeBytes: number): string {
        if (filesizeBytes < 1024) {
            return `${filesizeBytes} bytes`;
        } else if (filesizeBytes < 1024 * 1024) {
            return `${(filesizeBytes / 1024).toFixed(1)} kB`;
        } else if (filesizeBytes < 1024 * 1024 * 1024) {
            return `${(filesizeBytes / (1024 * 1024)).toFixed(1)} MB`;
        } else {
            return `${(filesizeBytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
        }
    }

    return (
        <>
            <div
                className="dragDropFiles"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {uploadedFiles.length ? (
                    <Row className="uploaded-files">
                        {uploadedFiles.map((file, index) => (
                            <Col
                                md="2"
                                key={index}
                                className={`mt-2 ${index === 0 ? '' : 'ml-3'}`}
                            >
                                {renderFilePreview(file, index)}
                            </Col>
                        ))}
                        {uploadedFiles.length < 5 && (
                            <Col md="2" onClick={handleAddFileClick} className="mt-2 ml-3">
                                <div className="uploadImage cursor-pointer centerItems">+</div>
                            </Col>
                        )}
                    </Row>
                ) : (
                    <>
                        <h6 className="text-center">Drag and Drop your files here</h6>
                        <h6 className="text-center pt-3 dragDropTextColor">( OR )</h6>
                        <div className="text-center mt-3">
                            <Button className="sendButton" onClick={handleAddFileClick}>
                                Choose File
                            </Button>
                        </div>
                    </>
                )}
                <h6 className="pt-3 dragDropTextColor">
                    You can upload a maximum of 5 files, with each image up to 2 MB, video upto 16 MB and document upto 100 MB
                </h6>
            </div>
            {uploadedFiles?.length ?
                <>
                    <NewLineInput
                        value={imgCaption}
                        placeholder="Enter message"
                        className="mt-3"
                        setValue={setImgCaption}
                    />
                    <div className="mt-3 text-right">
                        <Button className="sendButton w-25" onClick={() => {
                            setFiles(uploadedFiles);
                            setCaption(imgCaption);
                            setLoadingFile(true);
                            setTimeout(() => {
                                setLoadingFile(false);
                                closeUploadModal();
                            }, 2000)
                        }
                        }>
                            <div className='d-flex'><div className="m-auto">
                                {loadingFile ? 'Wait' : 'Send'}
                            </div>
                                {loadingFile && (<div className="loadingVia"></div>)}</div>
                        </Button>
                        <Button className="cancelButton ml-2 w-25" onClick={() => {
                            setUploadedFiles([]);
                            setImgCaption('');
                        }}>Cancel</Button>
                    </div>
                </> : ''}
            <input
                type="file"
                multiple
                onChange={handleFileInput}
                ref={fileInputRef}
                style={{ display: "none" }}
                accept={validMimeTypes[mediaType].join(",")}
            />
        </>
    );
};

export default AttachFiles;
