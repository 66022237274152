import React, { useState, useRef, CSSProperties  } from "react";
import { faArrowLeft, faGlassCheers,faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, FormGroup, Label, Button, InputGroup } from "reactstrap";
import { Formik, Field, ErrorMessage, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import "../Profile/profile.scss"
import {
  showBusinessProfile,
  profilePicturePath,
  memberUpdate,
  setBusinessProfileData,
} from "../../services/profileService";
import { useDispatch, useSelector } from "react-redux";
import { employList, industryList } from "../../common/selectOptions";
import FlagInput from "../../common/flagInput";
import { toast } from "../../common/alert";
import { auth } from "../../Firebase/firebase";
import { useNavigate } from 'react-router-dom';
import "./changepassword.scss";
import firebase from "firebase/compat/app";

interface FormValues {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }
  const inputGroupIcon: CSSProperties = {
    position: 'absolute',
    zIndex: 10,
    cursor: 'pointer',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  };
  const initialValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string().min(8, 'Password must be at least 8 characters').required('New Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'New password and Confirm New password must match').required('Confirm Password is required'),
  });
  
  const ChangePassword: React.FC = () => {

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const profileData = useSelector((state: any) => state.cartreducer.business);

    let navigate = useNavigate();
  const GoHome =()=>{
    let path = profileData && profileData?.business?.freeTrial ? `${process.env.PUBLIC_URL}/welcome/` : 
    profileData?.role?.name?.toLowerCase() === "owner" ||  profileData?.role?.name?.toLowerCase() === "admin" ? 
      `${process.env.PUBLIC_URL}/dashboard/` :   `${process.env.PUBLIC_URL}/conversations/`
    navigate(path);
  }
    const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      const user:any = auth.currentUser;
      if (!user) {
        toast('error','No user logged in');
        setSubmitting(false);
        return;
      }
  
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            values.oldPassword
          );
          await user.reauthenticateWithCredential(credential);
          await user.updatePassword(values.newPassword);
          toast('success', 'Password updated successfully');
      } catch (error:any) {
        console.log('err',error);
        switch (error.code) {
            case 'auth/wrong-password':
                toast('error', 'The current password is incorrect');
                break;
            case 'auth/requires-recent-login':
                toast('error', 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.');
                break;
            case 'auth/too-many-requests':
                toast('error',' Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'); 
                break;   
            default:
                toast('error', `Error updating password: ${error.message}`);
                break;
        }
        //   console.log('kjlfdsajl',error);
        // if (error instanceof Error) { 
        //     toast('error',`Error updating password: ${error.message}`);
        // } else {
        //   toast('error','An unknown error occurred');
        // }
      } finally {
        setSubmitting(false);
      }
    };
    return (
      <Container>
        {/* Existing markup... */}
        <div className="mt-3 page-heading ml-1">
        <h4>
          <FontAwesomeIcon icon={faArrowLeft}  onClick={GoHome} /> Change Password
        </h4>
        </div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="full-height">
              <Row className="profile-container">
                <Col md="6">
                <div className="form-group position-relative">
            <Label className="mt-4">Current Password<span className="redstar_required"></span></Label>
            <InputGroup>
            <Field name="oldPassword" type={showOldPassword ? "text" : "password"} className="form-control mt-2" />
            <FontAwesomeIcon
              icon={showOldPassword ? faEye : faEyeSlash}
              onClick={() => setShowOldPassword(!showOldPassword)}
              style={inputGroupIcon}
            />
            </InputGroup>
            <ErrorMessage name="oldPassword" component="div" className="text-danger" />
          </div>
                  <div className="formInput">
                    <Label className="mt-4">New Password<span className="redstar_required"></span></Label>
                    <InputGroup>
                    <Field  name="newPassword"   type={showNewPassword? "text" :"password"} className="form-control mt-2" />
                    <FontAwesomeIcon
              icon={showNewPassword ? faEye : faEyeSlash}
              onClick={() => setShowNewPassword(!showNewPassword)}
              style={inputGroupIcon}
            />
            </InputGroup>
                    <ErrorMessage name="newPassword" component="div" className="text-danger" />
                  </div>
                  <div className="formInput">
                    <Label className="mt-4">Confirm New Password<span className="redstar_required"></span></Label>
                    <InputGroup>
                    <Field   name="confirmPassword"   type={showConfirmPassword? "text" :"password"} className="form-control mt-2" />
                    <FontAwesomeIcon
              icon={showConfirmPassword ? faEye : faEyeSlash}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={inputGroupIcon}
            />
            </InputGroup>
                    <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                  </div>
              <Button
              type="button"
              className="cancelButton me-2 mt-4 text-dark"
              onClick={GoHome}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" className="sendButton mt-4" disabled={isSubmitting}>
                Submit
              </Button>
                </Col>
              </Row>
               
            </Form>
          )}
        </Formik>
      </Container>
    );
  };
  
  export default ChangePassword;