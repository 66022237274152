import React, { useEffect, useRef, useCallback } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import './common.scss';

type Placement =
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end";

interface popoverProps {
    isOpen: boolean,
    target: string,
    toggle: () => void,
    title?: string,
    children: React.ReactNode,
    component?: string,
    placement: Placement
}

const CommonPopover: React.FC<popoverProps> = ({ isOpen, target, toggle, title, children, component, placement }) => {
    const popoverRef = useRef<HTMLDivElement | null>(null);
    const setPopoverRef = useCallback((node: HTMLDivElement | null) => {
        popoverRef.current = node;
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
                toggle();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popoverRef]);

    const handlePopoverClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (component === 'conversationFilter')
            event.stopPropagation();
    }, []);

    return (
        <Popover placement={placement} isOpen={isOpen} target={target} toggle={toggle} innerRef={component === 'conversationFilter' ?
            setPopoverRef : ''}
            onClick={handlePopoverClick} trigger="legacy" style={{ width: component === 'conversationFilter' ? '225px' : component === 'order'? '180px' :'' }}>
            {component !== 'conversation' && <PopoverHeader>{title}</PopoverHeader>}
            <PopoverBody>{children}</PopoverBody>
        </Popover>
    );
};

export default CommonPopover;
