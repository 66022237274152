import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button, FormCheck } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import ValidationSettings from './validationSettings';
import { isValidRegex } from './shared';
import EditorCaption from '../editorCaption';
import BodyInput from '../bodyInput';
import VariableInputLabel from '../variableInputLabel';
import { SelectVariable } from '../setVariable';
import AdvancedSettings from './advancedSettings';
import { askLocation } from '../../../../icons'

interface FormData {
    text: string;
    errorMessage: string;
    endFlow: boolean;
    attempt: number;
    validation: { minimum: string, maximum: string, regex: string };
    storageVariable: { name: string, type: 'TEXT' },
    currentLocation: boolean;
    fieldForce: boolean;
};

const AskLocationButtonEditor: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [formData, setFormData] = useState<FormData>({
        text: '',
        attempt: 1,
        endFlow: true,
        errorMessage: "", 
        validation: {
            minimum: '',
            maximum: '',
            regex: ''
        },
        storageVariable: {
            name: '',
            type: 'TEXT'
        },
        currentLocation: false,
        fieldForce: false
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'ask_location',
        data: formData
    }, props);
    useEffect(() => {
        init((data: any) => {
            let newData = { ...data };
            let newValidation = {
                minimum: '',
                maximum: '',
                regex: ''
            };
            if (newData.validation) {
                if ('minimum' in newData.validation && newData.validation.minimum) {
                    newValidation.minimum = `${newData.validation.minimum}`;
                }
                if ('maximum' in newData.validation && newData.validation.maximum) {
                    newValidation.maximum = `${newData.validation.maximum}`;
                }
                if ('regex' in newData.validation && newData.validation.regex) {
                    newValidation.regex = newData.validation.regex;
                }
            }
            if (!newData.storageVariable) {
                newData.storageVariable = {
                    name: '',
                    type: 'TEXT'
                };
            }
            newData.validation = newValidation;
            setFormData(newData);
        });
    }, []);

    const validationSchema = yup.object().shape({
        minimum: yup.string().matches(/^$|^[0-9]+$/, 'Minimum should be a number')
            .test('minimum-less-than-maximum', 'Minimum should be less than maximum', function (value) {
                const maximumValue = this.parent.maximum;
                if (!value || !maximumValue) return true;
                return parseInt(value, 10) < parseInt(maximumValue, 10);
            }),
        maximum: yup.string().matches(/^$|^[0-9]+$/, 'Maximum should be a number')
            .test('maximum-greater-than-minimum', 'Maximum should be greater than minimum', function (value) {
                const minimumValue = this.parent.minimum;
                if (!value || !minimumValue) return true;
                return parseInt(value, 10) > parseInt(minimumValue, 10);
            }),
        regex: yup.string().test({
            name: 'isValidRegex',
            test(value: string | undefined): boolean {
                if (value) {
                    if (value === '') return true;
                    return isValidRegex(value);
                }
                return true;
            },
            message: 'Regex is invalid'
        }),
    });
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        text: yup.string().required('Question Text is a required field').test('is-double-space', 'Question Text is a required field', value => value !== '  '),
        errorMessage: yup.string().notRequired(),
        attempt: yup.number().min(0, 'Attempt should be minimum 0').required('Attempt is a required'),
        validation: validationSchema,
        endFlow: yup.boolean().required("Action on Invalid Response required"),
        storageVariable: storageVariableSchema
    });

    type ValidationObject = {
        minimum: string;
        maximum: string;
        regex: string;
    };
    function isValidation(validation?: ValidationObject | null): boolean {
        if (!validation) {
            return false;
        }
        return Object.values(validation).some(value => value !== '');
    }


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Ask Location' icon={<img style={{ width: 16 }} alt='' src={askLocation} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='text'
                                    value={values.text}
                                    isInvalid={(touched.text && errors.text) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.text && !errors.text}
                                    label={<>Question Text<span className="required"></span></>}
                                    placeholder='What do you think?'
                                    error={errors.text}
                                    limit={1024}
                                />
                                {(touched.text && errors.text) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.text}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Current Location</Form.Label>
                                <FormCheck
                                    name='currentLocation'
                                    type='switch'
                                    id='current-location-switch'
                                    label='Enable Current Location'
                                    checked={values.currentLocation}
                                    onChange={event => {
                                        setFieldValue('currentLocation', event.target.checked);
                                      }
                                    }
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Field Force</Form.Label>
                                <FormCheck
                                    name='fieldForce'
                                    type='switch'
                                    id='field-location-switch'
                                    label='Enable Field Force'
                                    checked={values.fieldForce}
                                    onChange={event => {
                                        setFieldValue('fieldForce', event.target.checked);
                                      }
                                    }
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <AdvancedSettings />
                                <ValidationSettings
                                    initiallyOpen={isValidation(values.validation)}
                                    onCancel={() => {
                                        setFieldValue('validation', {
                                            minimum: '',
                                            maximum: '',
                                            regex: ''
                                        });
                                    }}
                                >
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Minimum characters</Form.Label>
                                        <Form.Control
                                            name='validation.minimum'
                                            type='number'
                                            value={values.validation.minimum}
                                            isInvalid={touched.validation?.minimum && errors.validation?.minimum ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.validation?.minimum && !errors.validation?.minimum}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.validation?.minimum}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Maximum characters</Form.Label>
                                        <Form.Control
                                            name='validation.maximum'
                                            type='number'
                                            value={values.validation.maximum}
                                            isInvalid={touched.validation?.maximum && errors.validation?.maximum ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.validation?.maximum && !errors.validation?.maximum}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.validation?.maximum}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Regex</Form.Label>
                                        <Form.Control
                                            name='validation.regex'
                                            value={values.validation.regex}
                                            isInvalid={touched.validation?.regex && errors.validation?.regex ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.validation?.regex && !errors.validation?.regex}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.validation?.regex}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </ValidationSettings>
                            </Form.Group>
                            <VariableInputLabel
                                error={(
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.storageVariable?.name}
                                    </Form.Control.Feedback>
                                )}>
                                <SelectVariable
                                    placeholder="Create or Select variable"
                                    name="storageVariable"
                                    value={values.storageVariable.name}
                                    onChange={handleChange}
                                    type="TEXT"
                                />
                            </VariableInputLabel>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AskLocationButtonEditor;